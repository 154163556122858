export const validacaoSenha = (
    senhaParcial: string,
    setTamanhoSenha: React.Dispatch<React.SetStateAction<boolean>>,
    setCaracterEspecial: React.Dispatch<React.SetStateAction<boolean>>,
    setLetraMaiuscula: React.Dispatch<React.SetStateAction<boolean>>,
    setLetraMinuscula: React.Dispatch<React.SetStateAction<boolean>>,
    setNumeros: React.Dispatch<React.SetStateAction<boolean>>,

) => {
    const existeNumero = "\\d"; // ta certo
    const exiteLetraMinuscula = "[a-z]"; //ta certo
    const existeLetraMaiuscula = "[A-Z]"; //ta certo
    const existeCaractereEspecial = "[@!#$%^&*()/\\\\]"//ta certo
    const tamanhoSenha = 7;

    if (senhaParcial.length >= tamanhoSenha)
        setTamanhoSenha(true);
    else
        setTamanhoSenha(false);
    if (senhaParcial.match(existeCaractereEspecial))
        setCaracterEspecial(true)
    else
        setCaracterEspecial(false);
    if (senhaParcial.match(existeLetraMaiuscula))
        setLetraMaiuscula(true)
    else
        setLetraMaiuscula(false);
    if (senhaParcial.match(exiteLetraMinuscula))
        setLetraMinuscula(true)
    else
        setLetraMinuscula(false);
    if (senhaParcial.match(existeNumero))
        setNumeros(true)
    else
        setNumeros(false);

}