import { tipoOrdenacao } from "../../../../../../../@types/ordenacao";
import { HeaderTemplate } from "../../../../../@Templates/Header";
import IconeOrdenacao from "../../IconeOrdenacao";
import { IconeTooltip } from "../../IconeTooltip";

interface HeaderProps {
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<React.SetStateAction<string>>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  relatorio?: boolean;
}

export const Header = ({
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  tipoOrdenacao,
  setTipoOrdenacao,
  relatorio
}: HeaderProps) => {



  const HeaderInfluenciador = () => {
    const tituloColuna = 'Influenciador'

    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <IconeOrdenacao
              tituloColuna={tituloColuna}
              nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
              setnomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              setTipoOrdenacao={setTipoOrdenacao}
              tipoOrdenacao={tipoOrdenacao}
            />
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderAlcance = () => {
    const tituloColuna = 'Alcance'
    const tooltip = 'Total das métricas de alcance do Influenciador.\n-Para o formato TikTok é considerado a métrica Espectadores'

    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <IconeTooltip tooltip={tooltip} />
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderEngajamento = () => {
    const tituloColuna = 'Engajamento'
    const tooltip = 'Total de engajamento de todos os formatos da campanha.'

    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <>
              <IconeTooltip tooltip={tooltip} />
              <IconeOrdenacao
                tituloColuna={tituloColuna}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setnomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                tipoOrdenacao={tipoOrdenacao}
              />
            </>
          }
        </HeaderTemplate>

      </div>
    )
  }

  const HeaderTaxaEngajamento = () => {
    const tituloColuna = 'Taxa Engajamento'
    const tooltip = 'Percentual do total de Engajamento em relação ao total de Alcance.\nÉ considerado o total de Espectadores para os formatos TikTok e YouTube.\nPara Twitter é considerado Impressões.'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <>
              <IconeTooltip tooltip={tooltip} />
              <IconeOrdenacao
                tituloOrdenacao="TaxaDeEngajamento"
                tituloColuna={tituloColuna}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao.toLowerCase()}
                setnomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                tipoOrdenacao={tipoOrdenacao}
              />
            </>
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderComparativoCampanha = () => {
    const tituloColuna = 'Comparativo Campanha'
    const tooltip = 'Diferença percentual entre a taxa de engajamento desse influenciador\nnessa campanha e a taxa de engajamento geral da campanha.'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <IconeTooltip tooltip={tooltip} />
          }
        </HeaderTemplate>
      </div>
    )
  }
  const HeaderCPE = () => {
    const tituloColuna = 'CPE'
    const tooltip = 'Custo por Engajamento:\nValor Investido no Influenciador / Total de engajamento em todos os formatos'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <IconeTooltip tooltip={tooltip} />
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderCPM = () => {
    const tituloColuna = 'CPM'
    const tooltip = 'Custo por Mil Impressões:\n(Valor Investido no Influenciador / Total de de Impressões do Influenciador) * 1000'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <>
              <IconeTooltip tooltip={tooltip} />
              <IconeOrdenacao
                tituloColuna={tituloColuna}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setnomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                tipoOrdenacao={tipoOrdenacao}
              />
            </>
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderValorContrato = () => {
    const tituloColuna = 'Valor do Contrato'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
          {!relatorio &&
            <IconeOrdenacao
              tituloOrdenacao="ValorContrato"
              tituloColuna={tituloColuna}
              nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
              setnomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              setTipoOrdenacao={setTipoOrdenacao}
              tipoOrdenacao={tipoOrdenacao}
            />
          }
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderEntregas = () => {
    const tituloColuna = 'Entregas'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
        </HeaderTemplate>
      </div>
    )
  }

  const HeaderAcoes = () => {
    const tituloColuna = 'Ações'
    return (
      <div>
        <HeaderTemplate>
          {tituloColuna}
        </HeaderTemplate>
      </div>
    )
  }


  return {
    HeaderInfluenciador,
    HeaderAlcance,
    HeaderEngajamento,
    HeaderTaxaEngajamento,
    HeaderComparativoCampanha,
    HeaderCPE,
    HeaderCPM,
    HeaderValorContrato,
    HeaderEntregas,
    HeaderAcoes
  }
}