import { Dropdown } from "primereact/dropdown";

import styles from "./styles.module.css";


interface SelectStatusCampanhaProps {
  statusCampanha: number | undefined;
  setStatusCampanha: React.Dispatch<number | undefined>;
}

export const SelectStatusCampanha = ({
  setStatusCampanha,
  statusCampanha,
}: SelectStatusCampanhaProps) => {
  const statusOptions = [
    {
      nome: "Ativo",
      value: 1,
    },
    {
      nome: "Encerrado",
      value: 0,
    },
  ];
  return (
    <div className={styles.conteudoSelectStatus}>
      <Dropdown
        options={statusOptions}
        value={statusCampanha}
        onChange={(e) => {
          setStatusCampanha(e.target.value);
        }}
        placeholder={"Selecione um status"}
        optionValue="value"
        optionLabel="nome"
        style={{
          width: "100%",
          color: '#fff',
          outline: 'none',
          boxShadow: 'none',
          fontFamily: 'Exo 2',
          height: '3.5rem',
        }}
        panelStyle={{
          fontSize: '1.2rem',
        }}
      />
    </div>
  );
};
