import React from "react";

import { ErroType } from "../../../@types/erro";
import {
  usuarioNetcosProps,
  getUsuarioNetcosTodos,
  getUsuarioNetcosTodosPorNome,
} from "../../../services/usuarioNetcos";
import { limpaLocalStorage, obterTokens } from "../../../utils/LocalStorage";

interface FunctionListarUsuarioNetcosProps {
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: string;
  nomePropriedadeOrdenacao: string;
  nomeUsuarioNetcosPesquisa: string;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setListaDeUsuariosNetcos: React.Dispatch<usuarioNetcosProps[]>;
  setAbrirModalDeslogar: React.Dispatch<React.SetStateAction<boolean>>;
  setUsuariosNetcosTotais: React.Dispatch<number>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionListarUsuarioNetcos = ({
  numeroPagina,
  tamanhoPagina,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  nomeUsuarioNetcosPesquisa,
  setErro,
  setCarregando,
  setListaDeUsuariosNetcos,
  setAbrirModalDeslogar,
  setUsuariosNetcosTotais,
  setCarregandoPesquisa,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionListarUsuarioNetcosProps) => {
  function listarTodosUsuariosNetcos() {
    setCarregando(true);
    setErro({ ativo: false, mensagem: "" });

    const { token, tokenRenovacao } = obterTokens();

    getUsuarioNetcosTodos(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeUsuariosNetcos(res.data.usuarios);
        setUsuariosNetcosTotais(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioNetcosTodos(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaDeUsuariosNetcos(res.data.usuarios);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de usuários NetCos.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de usuários NetCos.",
          });
          setCarregando(false);
        }
      });
  }

  const pesquisaUsuarioNetcosPorNome = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioNetcosTodosPorNome(
      token,
      nomeUsuarioNetcosPesquisa,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeUsuariosNetcos(res.data.usuarios);
        setUsuariosNetcosTotais(res.data.totalRegistros);
        setCarregandoPesquisa(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioNetcosTodosPorNome(
              token,
              nomeUsuarioNetcosPesquisa,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaDeUsuariosNetcos(res.data.usuarios);
                setUsuariosNetcosTotais(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de usuários NetCos.",
                });
              });
          }, setCarregandoPesquisa);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de usuários NetCos.",
          });
          setCarregandoPesquisa(false);
        }
      })
      .finally(() => {});
  };

  return { listarTodosUsuariosNetcos, pesquisaUsuarioNetcosPorNome };
};
