import { Input } from "../../../Input";

import styles from "../styles.module.css";
import { InputMetrica } from "./inputs/InputMetrica";

interface TikTokProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  salvos: number;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  views: number;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokHora: number;
  setTempoReproducaoTiktokHora: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokMinuto: number;
  setTempoReproducaoTiktokMinuto: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokSegundo: number;
  setTempoReproducaoTiktokSegundo: React.Dispatch<React.SetStateAction<number>>;
  espectadores: number;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
}

export const TikTok = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  salvos,
  setSalvos,
  views,
  setViews,
  tempoReproducaoTiktokHora,
  setTempoReproducaoTiktokHora,
  tempoReproducaoTiktokMinuto,
  setTempoReproducaoTiktokMinuto,
  tempoReproducaoTiktokSegundo,
  setTempoReproducaoTiktokSegundo,
  espectadores,
  setEspectadores,
  compartilhamentos,
  setCompartilhamentos

}: TikTokProps) => {
  const listaMetricas = [
    { nome: "Views", metrica: { valor: views, dispatch: setViews } },
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Compartilhamentos", metrica: { valor: compartilhamentos, dispatch: setCompartilhamentos } },
    { nome: "Salvos", metrica: { valor: salvos, dispatch: setSalvos } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}

      <>
        <div className={styles.labelTempo}>Tempo de Reprodução</div>
        <div className="p-inputgroup">
          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Horas"
            value={
              tempoReproducaoTiktokHora !== 0
                ? tempoReproducaoTiktokHora
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoTiktokHora(e.target.valueAsNumber);
            }}
          />

          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Minutos"
            value={
              tempoReproducaoTiktokMinuto !== 0
                ? tempoReproducaoTiktokMinuto
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoTiktokMinuto(e.target.valueAsNumber);
            }}
          />

          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Segundos"
            value={
              tempoReproducaoTiktokSegundo !== 0
                ? tempoReproducaoTiktokSegundo
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoTiktokSegundo(e.target.valueAsNumber);
            }}
          />
        </div>
      </>

      <InputMetrica
        nomeMetrica="Espectadores"
        metrica={espectadores}
        setMetrica={setEspectadores}
        disabled={aprovar || desabilitarCampos}
      />
    </>
  )
}