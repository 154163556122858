import { AxiosResponse } from "axios";
import { tipoOrdenacao } from "../@types/ordenacao";
import { api } from "./api";

export interface marcaProps {
  nome: string;
  logotipo: string;
}

export interface getMarcaProps {
  id: string;
  nome: string;
  urlLogotipo: string;
  totalUsuarios: number;
  totalCampanhas: number;
  ativo: boolean;
}



export interface getMarcaResponseProps {
  codigo: number;
  totalRegistros: number;
  marcas: getMarcaProps[];
}

export const postMarca = (token: string, marca: marcaProps) => {
  const url = "/Marca";

  return api.post(url, marca, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getMarcaTodas = (
  token: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: tipoOrdenacao,
  nomePropriedadeOrdenacao: string,
): Promise<AxiosResponse<getMarcaResponseProps, any>> => {
  const url = `Marca/Todas?NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getMarcaTodasPorNome = (
  token: string,
  nomeMarcaPesquisa: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
): Promise<AxiosResponse<getMarcaResponseProps, any>> => {
  const url = `/Marca/Todas/Nome?Nome=${nomeMarcaPesquisa}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&PropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getMarcaTodasPorStatus = (
  token: string,
  status: boolean,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
): Promise<AxiosResponse<getMarcaResponseProps, any>> => {
  const url = `/Marca/Todas/Status?Status=${status}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putMarca = (
  token: string,
  id: string,
  nome: string,
  logotipo: string | null,
  ativo: boolean
) => {
  const url = "/Marca";

  const marca = {
    id,
    nome,
    logotipo,
    ativo
  };

  return api.put(
    url,
    marca,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};


interface putMarcaAgenciaProps {
  token: string;
  idMarca: string;
  idAgencia: string;
}
export const putMarcaAgencia = ({
  token,
  idMarca,
  idAgencia
}: putMarcaAgenciaProps) => {
  const url = `Marca/VincularAgencia`;

  return api.put(
    url,
    {
      idMarca,
      idAgencia,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
