//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { Influenciador } from "../../../../services/publicacao";
import { tarefaProps } from "../../../../services/tarefas";
import { format } from "../../../../utils/MascaraNumero/format";
import { somaMetrica } from "../../../../utils/somaMetrica";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioTwitter } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import mascaraImagem from "../../../../assets/img/MascaraImagensRelatorio.png";

export async function gerarPaginaTwitter(
  tarefa: tarefaProps,
  listaDePdfs: any,
  taxaEngajamentoCampanha: number,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        Engajamento: {
          type: "text",
          position: {
            x: 259.56,
            y: 41.89,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Comentarios: {
          type: "text",
          position: {
            x: 259.77,
            y: 53.59,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Favoritos: {
          type: "text",
          position: {
            x: 259.45,
            y: 65.62,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Retweets: {
          type: "text",
          position: {
            x: 259.67,
            y: 78.06,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Impressoes: {
          type: "text",
          position: {
            x: 259.87,
            y: 89.5,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Respostas: {
          type: "text",
          position: {
            x: 259.93,
            y: 101.73,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Views: {
          type: "text",
          position: {
            x: 259.67,
            y: 113.7,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TaxaEngajamento: {
          type: "text",
          position: {
            x: 282.43,
            y: 163.76,
          },
          width: 20,
          height: 7,
          alignment: "left",
          fontSize: 13,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        ImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        MascaraImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        NomeInflu: {
          type: "text",
          position: {
            x: 63.97,
            y: 68.26,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TipoInflu: {
          type: "text",
          position: {
            x: 64.18,
            y: 76.94,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoInflu: {
          type: "text",
          position: {
            x: 64.39,
            y: 100.7,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoCampanha: {
          type: "text",
          position: {
            x: 94.5,
            y: 178.17,
          },
          width: 25.73,
          height: 6.99,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        CliquesLink: {
          type: "text",
          position: {
            x: 260.15,
            y: 126.93,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        CliquesHashtag: {
          type: "text",
          position: {
            x: 260.36,
            y: 138.78,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        DetailExpands: {
          type: "text",
          position: {
            x: 260.31,
            y: 151.43,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
    ],
    basePdf: base64RelatorioTwitter,
  };

  var imageInfluencer = !tarefa.influenciador.urlAvatar
    ? ""
    : await converterImagemParaBase64(
      tarefa.influenciador.urlAvatar,
      setErro,
      setCarregando
    );

  if (imageInfluencer !== "AxiosError") {
    let totalImpressoesTwitter: number = 0;
    let totalEngajamentoTwitter: number = 0;
    let totalComentariosTwitter: number = 0;
    let totalRetweetsTwitter: number = 0;
    let totalFavoritosTwitter: number = 0;
    let totalRespostasTwitter: number = 0;
    let totalViewsTwitter: number = 0;
    let totalCliquesLinkTwitter: number = 0;
    let totalCliquesHashtagsTwitter: number = 0;
    let totalDetailExpandsTwitter: number = 0;

    tarefa.publicacoes.map((publicacao) => {
      if (publicacao.tipo === "PublicacaoTwitter") {
        if (publicacao.status === "Aprovado") {
          totalImpressoesTwitter = somaMetrica(
            totalImpressoesTwitter,
            publicacao.impressoes
          );

          totalEngajamentoTwitter = somaMetrica(
            totalEngajamentoTwitter,
            publicacao.engajamento
          );

          totalComentariosTwitter = somaMetrica(
            totalComentariosTwitter,
            publicacao.comentarios
          );

          totalRetweetsTwitter = somaMetrica(
            totalRetweetsTwitter,
            publicacao.retweets
          );

          totalRespostasTwitter = somaMetrica(
            totalRespostasTwitter,
            publicacao.respostas
          );

          totalViewsTwitter = somaMetrica(totalViewsTwitter, publicacao.views);

          totalFavoritosTwitter = somaMetrica(
            totalFavoritosTwitter,
            publicacao.favoritos
          );

          totalCliquesLinkTwitter = somaMetrica(
            totalCliquesLinkTwitter,
            publicacao.cliquesLink
          );

          totalCliquesHashtagsTwitter = somaMetrica(
            totalCliquesHashtagsTwitter,
            publicacao.cliquesHashtag
          );

          totalDetailExpandsTwitter = somaMetrica(
            totalDetailExpandsTwitter,
            publicacao.detailExpands
          );
        }
      }
    });

    const inputs = [
      {
        Engajamento: totalEngajamentoTwitter == 0 ? '-' : format("#.###.##0,#0", totalEngajamentoTwitter).replace(
          ",00",
          ""
        ),
        Comentarios: totalComentariosTwitter == 0 ? '-' : format("#.###.##0,#0", totalComentariosTwitter).replace(
          ",00",
          ""
        ),
        Favoritos: totalFavoritosTwitter == 0 ? '-' : format("#.###.##0,#0", totalFavoritosTwitter).replace(
          ",00",
          ""
        ),
        Retweets: totalRetweetsTwitter == 0 ? '-' : format("#.###.##0,#0", totalRetweetsTwitter).replace(
          ",00",
          ""
        ),
        Impressoes: totalImpressoesTwitter == 0 ? '-' : format("#.###.##0,#0", totalImpressoesTwitter).replace(
          ",00",
          ""
        ),
        Respostas: totalRespostasTwitter == 0 ? '-' : format("#.###.##0,#0", totalRespostasTwitter).replace(
          ",00",
          ""
        ),
        Views: totalViewsTwitter == 0 ? '-' : format("#.###.##0,#0", totalViewsTwitter).replace(",00", ""),
        TaxaEngajamento: tarefa.twitterTaxaEngajamento == 0 ? '-' : format("#.###.##0,#0%", tarefa.twitterTaxaEngajamento),
        ImagemInflu: !imageInfluencer ? "" : imageInfluencer,
        MascaraImagemInflu: mascaraImagem,
        NomeInflu: tarefa.influenciador.nome.toString(),
        TipoInflu: tarefa.influenciador.perfil.toString(),
        EngajamentoInflu:
          tarefa.influenciador.engajamentoHistorico <= 0 ? "-" :
            "Taxa de engajamento histórico: " +
            format(
              "#.###.##0,#0%",
              tarefa.influenciador.engajamentoHistorico
            ).toString(),
        EngajamentoCampanha: taxaEngajamentoCampanha == 0 ? '-' : format(
          "#.###.##0,#0%",
          taxaEngajamentoCampanha
        ).toString(),
        CliquesLink: totalCliquesLinkTwitter == 0 ? '-' : format("#.###.##0,#0", totalCliquesLinkTwitter).replace(
          ",00",
          ""
        ),
        CliquesHashtag: totalCliquesHashtagsTwitter == 0 ? '-' : format(
          "#.###.##0,#0",
          totalCliquesHashtagsTwitter
        ).replace(",00", ""),
        DetailExpands: totalDetailExpandsTwitter == 0 ? '-' : format(
          "#.###.##0,#0",
          totalDetailExpandsTwitter
        ).replace(",00", ""),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob8 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob8);
    });
  } else {
    return "AxiosError";
  }
}
