import { useState } from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';

import styles from './styles.module.css';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { BotaoAtualizarMarcaLista } from '../../Botoes/BotaoAtualizarMarcaLista';
import { BotaoColunaAcoes } from '../../Botoes/BotaoColunaAcoes';
import { listaAgenciaProps } from '../../../services/agencia';

export interface CardAgenciaProps {
  agencia: listaAgenciaProps;
  setRecarregar: () => void;
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  setNomeAgencia: React.Dispatch<React.SetStateAction<string>>;
  setLogotipoAgencia: React.Dispatch<React.SetStateAction<string>>;
  setModalAbertoUsuarioAgencia: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;

  index: number;
}

export const CardAgencia = ({ agencia, setRecarregar, setIdAgencia, setNomeAgencia, setLogotipoAgencia, setModalAbertoUsuarioAgencia, setModalAbertoDesativacao, index }: CardAgenciaProps) => {

  const [abrirCardAgencia, setAbrirCardAgencia] = useState<boolean>(false);

  return (
    <>
      <div
        className={styles.cardMarca}
      >
        <header className={styles.headerCardMarca} onClick={() => setAbrirCardAgencia(!abrirCardAgencia)}
        >
          <p>{agencia.nome}</p>
          {agencia.ativo ? (
            <div
              className={`${styles.status} ${styles.statusAtivo}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--verde-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          ) : (

            <div className={`${styles.status} ${styles.statusEncerrado}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--cinza-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          )}
        </header>
        <body className={abrirCardAgencia ? styles.bodyCardMarcaAberto : styles.bodyCardMarcaFechado}>

          <div className={styles.detalhesCard}>
            <div className={styles.itemCard}>
              <p>Número de Campanhas:</p>{agencia.totalCampanhas}
            </div>

            <div className={styles.itemCard}>
              <p>Número de Usuários:</p>{agencia.totalUsuarios}
            </div>

            <div className={styles.campoAcoes}>
              <BotaoAtualizarMarcaLista
                idMarca={agencia.id}
                nomeMarca={agencia.nome}
                logotipo={agencia.urlLogotipo}
                setRecarregar={setRecarregar}
                status={agencia.ativo}
              />
              <BotaoColunaAcoes
                status={!agencia.ativo ? "Removido" : ""}
                icone={"pi-user-plus"}
                titulo={"Adicionar Usuário"}
                onClick={() => {
                  setModalAbertoUsuarioAgencia(true);
                }}
              />
              <BotaoColunaAcoes
                status={!agencia.ativo ? "Removido" : ""}
                icone={"pi-trash"}
                titulo={"Remover Marca"}
                onClick={() => {
                  setIdAgencia(agencia.id);
                  setNomeAgencia(agencia.nome);
                  setLogotipoAgencia(agencia.urlLogotipo);
                  setModalAbertoDesativacao(true);
                }}
              />
            </div>
          </div>

        </body>
        <div
          className={abrirCardAgencia ? styles.footerCard : styles.footerCardFechado}
          onClick={() => setAbrirCardAgencia(!abrirCardAgencia)}
        >
          <div
            className={styles.botaoSetinha}
          >
            {
              !abrirCardAgencia ?
                <MdExpandMore />
                :
                <MdExpandLess />
            }
          </div>
        </div>

      </div>
    </>
  )
}