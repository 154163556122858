import React, { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesAtualizarUsuarioAgencia } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Input } from "../../Input";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { ModalTeste } from "../ModalTeste";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";

interface ModalAtualizarUsuarioAgenciaProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  id: string;
  setRecarregar: () => void;
  imagemAvatarUsuario: string;
  nomeUsuario: string;
  telefoneUsuario: string;
  agenciaUsuario: string;
  statusUsuario: string;
}

export const ModalAtualizarUsuarioAgencia = ({
  modalAberto,
  setModalAberto,
  id,
  nomeUsuario,
  telefoneUsuario,
  agenciaUsuario,
  imagemAvatarUsuario,
  setRecarregar,
  statusUsuario,
}: ModalAtualizarUsuarioAgenciaProps) => {
  const [nome, setNome] = useState<string>(nomeUsuario);
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [telefone, setTelefone] = useState<number>(parseInt(telefoneUsuario));
  const [idAgencia, setIdAgencia] = useState<string>(agenciaUsuario);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] =
    useState<string>("");

  const [setAbrirModalDeslogar] = useState<boolean>(false);

  useEffect(() => {
    MascaraTelefone(
      telefoneUsuario.toString(),
      setTelefone,
      setMascaraNumeroWhatsapp
    );
  }, []);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { atualizarUsuarioAgencia } =
    FuncoesAtualizarUsuarioAgencia({
      id,
      nome,
      setNome,
      telefone,
      setTelefone,
      imagemAvatar: imagemAvatar ? imagemAvatar : null,
      setImagemAvatar,
      idAgencia,
      setIdAgencia,
      setCarregando,
      setModalAberto,
      adicionarAListaEsperaRenovacaoToken,
      MascaraNumeroWhatsapp,
      setMascaraNumeroWhatsapp,
      setRecarregar,
      status: statusUsuario,
    });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Atualizar Usuário Agência"}
    >
      <div
        className={styles.conteudoModalAtualizarUsuarioAgencia}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <BotaoUploadImagem
            imagemAnterior={imagemAvatarUsuario}
            setImg={setImagemAvatar}
          />
          <div className={styles.containerDados}>
            <div className={styles.containerInputs}>
              <Input
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
              {nome === '' && (
                <small className={styles.mensagemValidacao}>
                  O campo nome é obrigatório.
                </small>
              )}
              <PhoneInput
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
              />
            </div>
          </div>
        </body>

        {carregando ? (
          <Carregando />
        ) : (
          <div className={styles.containerBotoesModal}>
            <Button
              type="button"
              importancia="terciario"
              title="Cancelar"
              onClick={() => setModalAberto(false)}
            />
            <Button
              importancia="primario"
              title="Confirmar"
              onClick={() => atualizarUsuarioAgencia()}
            />
          </div>
        )}
      </div>
    </ModalTeste>
  );
};
