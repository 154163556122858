import { AnimacaoNumero } from "../../../../utils/AnimacaoNumero";
import { BsQuestionCircle } from "react-icons/bs";

import styles from "./styles.module.css";

interface CampoBigNumbers {
  impressoes?: number;
  taxaEngajamento?: number;
  engajamentoTotal?: number;
  custoPorMilImpressoes?: number;
  custoEngajamento?: number;
  redeSocial?: boolean;
  tooltipTxEngajamento?: string;
  tooltipImpressoes?: string;
}

export const CampoBigNumbers = ({
  impressoes,
  taxaEngajamento,
  engajamentoTotal,
  custoPorMilImpressoes,
  custoEngajamento,
  redeSocial,
  tooltipTxEngajamento,
  tooltipImpressoes,
}: CampoBigNumbers) => {

  return (
    <div className={`${styles.campoBigNumbers} ${redeSocial && styles.campoBigNumbersRedeSocial}`}>
      <div className={styles.campoIndividualBigNumber}>
        <p className={styles.tituloBigNumber} title={tooltipImpressoes ? tooltipImpressoes : "Total de impressões por formato da Campanha."}>
          Impressões   <BsQuestionCircle
            style={{
              color: "var(--cinza-600)",
              fontSize: "1.2rem",
              marginBottom: "-2px"
            }}
          />
        </p>

        <p className={styles.conteudoBigNumber}><AnimacaoNumero inteiro numero={impressoes ? impressoes : 0} /></p>
      </div>

      <div className={styles.campoIndividualBigNumber}>
        <p className={styles.tituloBigNumber} title="Total de engajamento de todos os formatos da campanha">
          Engajamento   <BsQuestionCircle
            style={{
              color: "var(--cinza-600)",
              fontSize: "1.2rem",
              marginBottom: "-2px"
            }}
          />
        </p>
        <p className={styles.conteudoBigNumber}><AnimacaoNumero inteiro numero={engajamentoTotal ? engajamentoTotal : 0} /></p>
      </div>

      <div className={styles.campoIndividualBigNumber}>
        <p className={styles.tituloBigNumber} title={tooltipTxEngajamento ? tooltipTxEngajamento : "Média da Taxa de Engajamento dos Influenciadores da Campanha"}>
          Taxa de Engajamento   <BsQuestionCircle
            style={{
              color: "var(--cinza-600)",
              fontSize: "1.2rem",
              marginBottom: "-2px"
            }}
          />
        </p>
        <p className={styles.conteudoBigNumber}><AnimacaoNumero numero={taxaEngajamento ? taxaEngajamento : 0} />%</p>
      </div>


      {!redeSocial &&
        <>
          <div className={styles.campoIndividualBigNumber}>
            <p className={styles.tituloBigNumber} title="(Valor Investido na Campanha / Total de de Impressões da Campanha) * 1000">
              CPM   <BsQuestionCircle
                style={{
                  color: "var(--cinza-600)",
                  fontSize: "1.2rem",
                  marginBottom: "-1px"
                }}
              />
            </p>
            <p className={styles.conteudoBigNumber}>
              R$ <AnimacaoNumero numero={custoPorMilImpressoes ? custoPorMilImpressoes : 0} /></p>
          </div>

          <div className={styles.campoIndividualBigNumber}>
            <p className={styles.tituloBigNumber} title="Valor Investido na Campanha / Total de engajamento em todos os formatos da campanha">
              CPE   <BsQuestionCircle
                style={{
                  color: "var(--cinza-600)",
                  fontSize: "1.2rem",
                  marginBottom: "-1px"
                }}
              />
            </p>
            <p className={styles.conteudoBigNumber}>R$ <AnimacaoNumero numero={custoEngajamento ? custoEngajamento : 0} /></p>
          </div>
        </>
      }

    </div>
  );
};