import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesVisaoGeralInfluenciador } from "./functions";

import { CampoInformacoes } from "../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { GraficoVisaoGeral } from "../Graficos/GraficoVisaoGeral";
import { ErroType } from "../../@types/erro";
import { PublicacoesProps } from "../../services/publicacao";
import { Carregando } from "../Carregando";
import { TabelaMetricasPorInfluenciador } from "../Tabelas/TabelaMetricasPorInfluenciador";

import { ProgressBar } from "primereact/progressbar";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";

interface VisaoGeralInfluenciadorProps {
  idCampanha: string;
  idInfluenciador: string;
  taxaDeEngajamentoIgFeed: number;
  taxaDeEngajamentoIgReels: number;
  taxaDeEngajamentoIgStories: number;
  taxaDeEngajamentoTwitter: number;
  taxaDeEngajamentoTikTok: number;
  taxaDeEngajamentoYoutubeVideo: number;
  taxaDeEngajamentoYoutubeShort: number;
  setTabViewIndiceAtivo: React.Dispatch<React.SetStateAction<number>>;
  setTabViewConteudosEMetricasIndice: React.Dispatch<
    React.SetStateAction<number>
  >;
  setSessaoDestino: React.Dispatch<React.SetStateAction<string>>;
}

export const VisaoGeralInfluenciador = ({
  idCampanha,
  idInfluenciador,
  taxaDeEngajamentoIgFeed,
  taxaDeEngajamentoIgReels,
  taxaDeEngajamentoIgStories,
  taxaDeEngajamentoTikTok,
  taxaDeEngajamentoTwitter,
  taxaDeEngajamentoYoutubeShort,
  taxaDeEngajamentoYoutubeVideo,
  setTabViewIndiceAtivo,
  setTabViewConteudosEMetricasIndice,
  setSessaoDestino,
}: VisaoGeralInfluenciadorProps) => {
  const [carregando, setCarregando] = useState<boolean>(true);
  const [error, setError] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(true);

  const [publicacoes, setPublicacoes] = useState<PublicacoesProps[]>([]);
  const [entregues, setEntregues] = useState<number>(0);
  const [contratadas, setContratadas] = useState<number>(0);

  let percentualEntregues = ((entregues * 100) / contratadas).toFixed(2);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obtemDadosGrafico } = FuncoesVisaoGeralInfluenciador({
    idCampanha,
    idInfluenciador,
    setCarregando,
    setError,
    setContratadas,
    setEntregues,
    setPublicacoes,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obtemDadosGrafico();
  }, [recarregar]);

  return (
    <>
      {carregando ? (
        <Carregando botao />
      ) : error.ativo ? (
        <div className={styles.containerErro}>
          <h1>Ocorreu um erro carregando o gráfico, tente novamente.</h1>
          <button
            onClick={() => setRecarregar(!recarregar)}
            className={styles.botaoIconeColunaAcoes}
          >
            <i
              className={"pi pi-refresh"}
              style={{ fontSize: "3rem" }}
              title={"Recarregar gráfico"}
            />
          </button>
        </div>
      ) : (
        <>
          <CampoInformacoes
            titulo={
              <div className={styles.titulo}>
                Visão Geral
                <div className={styles.campoProgresso}>
                  <p className={styles.progressoTitulo}>
                    Progresso ({percentualEntregues}%)
                  </p>
                  <ProgressBar value={percentualEntregues} />
                  <p className={styles.progressoInfo}>
                    {entregues}/{contratadas} publicações entregues
                  </p>
                </div>
              </div>
            }
          >
            <GraficoVisaoGeral publicacoes={publicacoes} />
          </CampoInformacoes>
          {entregues > 0 && (
            <CampoInformacoes titulo={"Métricas Gerais"}>
              <TabelaMetricasPorInfluenciador
                publicacoes={publicacoes}
                taxaDeEngajamentoIgFeed={taxaDeEngajamentoIgFeed}
                taxaDeEngajamentoIgReels={taxaDeEngajamentoIgReels}
                taxaDeEngajamentoIgStories={taxaDeEngajamentoIgStories}
                taxaDeEngajamentoTikTok={taxaDeEngajamentoTikTok}
                taxaDeEngajamentoTwitter={taxaDeEngajamentoTwitter}
                taxaDeEngajamentoYoutubeShort={taxaDeEngajamentoYoutubeShort}
                taxaDeEngajamentoYoutubeVideo={taxaDeEngajamentoYoutubeVideo}
                setTabViewIndiceAtivo={setTabViewIndiceAtivo}
                setTabViewConteudosEMetricasIndice={
                  setTabViewConteudosEMetricasIndice
                }
                setSessaoDestino={setSessaoDestino}
              />
            </CampoInformacoes>
          )}
        </>
      )}
    </>
  );
};
