import React, { SetStateAction } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { tipoOrdenacao } from "../../@types/ordenacao";
import { FunctionsTituloColuna } from "./functions";
import styles from "./styles.module.css";

export interface TituloColunaProps {
  tituloColuna: string;
  title?: string;
  hasToolTip?: boolean;
  tituloOrdenacao?: string;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao?: React.Dispatch<tipoOrdenacao>;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao?: React.Dispatch<string>;
  iconOrdenacao: number[];
  colunaIndex: number;
  fontSize?: string;
}

export const TituloColuna = ({
  tituloColuna,
  title,
  hasToolTip,
  tituloOrdenacao,
  colunaIndex,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setTipoOrdenacao,
  iconOrdenacao,
  fontSize,
}: TituloColunaProps) => {
  const { handleOrdenacao } = FunctionsTituloColuna({
    iconOrdenacao,
    nomePropriedadeOrdenacao,
    setNomePropriedadeOrdenacao,
    setTipoOrdenacao,
    tipoOrdenacao,
    tituloColuna,
  });

  return (
    <th
      className={styles.headerTituloColunas}
      style={{ fontSize: fontSize ? fontSize : "1rem" }}
    >
      <div
        className={styles.tituloColuna}
        onClick={() => {
          iconOrdenacao.includes(colunaIndex) &&
            handleOrdenacao(tituloOrdenacao ? tituloOrdenacao : tituloColuna);
        }}
        title={title ? title : tituloColuna}
      >
        <p style={{ display: "flex", alignItems: "center" }}>
          {tituloColuna}
          {hasToolTip &&
            <BsQuestionCircle
              style={{
                color: "var(--cinza-600)",
                fontSize: "11px",
                marginTop: "3px",
                marginLeft: "4px",
                minWidth: "1.4rem",
              }}
            />
          }
        </p>
        {iconOrdenacao.includes(colunaIndex) ? (
          <i
            className={
              tipoOrdenacao === "Descending" &&
                nomePropriedadeOrdenacao === tituloOrdenacao
                ? "pi pi-sort-amount-up-alt"
                : "pi pi-sort-amount-down-alt"
            }
            style={
              nomePropriedadeOrdenacao === tituloOrdenacao
                ? {
                  fontSize: "1.4rem",
                  paddingLeft: "0.5rem",
                  color: "var(--roxo-500)",
                  cursor: "pointer",
                }
                : {
                  fontSize: "1.4rem",
                  paddingLeft: "0.5rem",
                  cursor: "pointer",
                }
            }
            title={"Ordenar por " + tituloColuna}
          />
        ) : null}
      </div>
    </th>
  );
};
