import { useContext } from "react";

import { putUsuarioAgencia } from "../../../services/usuarioAgencia";

import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { MostrarToastContext } from "../../../context/MostrarToast";

export interface FuncoesAtualizarUsuarioAgenciaProps {
  id: string;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  imagemAvatar: string | null;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  idAgencia: string;
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAberto: React.Dispatch<boolean>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  status: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesAtualizarUsuarioAgencia = ({
  id,
  nome,
  setNome,
  telefone,
  setTelefone,
  imagemAvatar,
  setImagemAvatar,
  idAgencia,
  setIdAgencia,
  setCarregando,
  setModalAberto,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setRecarregar,
  status,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesAtualizarUsuarioAgenciaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = (mensagem: string) => {
    mostrarToast(
      "success",
      "Atualização realizada",
      mensagem
    );

    LimparStates([
      setNome,
      setImagemAvatar,
      setIdAgencia,
      setMascaraNumeroWhatsapp,
    ]);
    setRecarregar();
    setModalAberto(false);
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      mensagem
    );
  };

  const erroPreenchimento = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Preencha todos os campos e tente novamente."
    );
  };
  function atualizarUsuarioAgencia() {
    if (nome.trim()) {
      const { token, tokenRenovacao } = obterTokens();

      setCarregando(true);

      putUsuarioAgencia(
        token,
        id,
        nome,
        imagemAvatar,
        MascaraNumeroWhatsapp,
        idAgencia,
        status
      )
        .then((res) => {
          sucessoRequisicao(res.data.mensagem);
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await putUsuarioAgencia(
                token,
                id,
                nome,
                imagemAvatar,
                MascaraNumeroWhatsapp,
                idAgencia,
                status
              )
                .then((res2) => {
                  sucessoRequisicao(res2.data.mensagem);
                })
                .catch((err2) => {
                  erroRequisicao(
                    err2.response.data.mensagem
                      ? err2.response.data.mensagem
                      : err2.response.data.message);
                });
            }, setCarregando);
          } else {
            erroRequisicao(
              err.response.data.mensagem
                ? err.response.data.mensagem
                : err.response.data.message);
            setCarregando(false);
          }
        });
    } else {
      erroPreenchimento();
    }
  }

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  // function fazMascaraTelefone(telefone: string) {
  //   MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  // }

  return {
    atualizarUsuarioAgencia,
    fazConverteImgPara64,
  };
};
