import { BsRecordCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DateFormatter } from "../../../utils/DataFormatter";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { PublicacoesProps } from "../../../services/publicacao";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import styles from "./styles.module.css";
import { tipoPublicacaoProps } from "../../../services/campanha";

export interface CardPublicacaoProps {
  publicacao: PublicacoesProps;
  mostrarComentario: (event: any, publicacao: PublicacoesProps) => void;
  dataInicioCampanha?: string;
  setMostrarModalEditarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoSelecionada: React.Dispatch<
    React.SetStateAction<PublicacoesProps>
  >;
  setMostrarModalAprovarTarefa: React.Dispatch<React.SetStateAction<boolean>>;

  setMostrarsetModalExclusaoDePublicacao: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setPublicacaoId: React.Dispatch<React.SetStateAction<string>>;
  setCampanhaId: React.Dispatch<React.SetStateAction<string>>;
  idCampanha?: string;

  index: number;
}

export const CardPublicacao = ({
  publicacao,
  mostrarComentario,
  dataInicioCampanha,
  setMostrarModalEditarTarefa,
  setPublicacaoSelecionada,
  setMostrarModalAprovarTarefa,
  setMostrarsetModalExclusaoDePublicacao,
  setPublicacaoId,
  setCampanhaId,
  idCampanha,
  index,
}: CardPublicacaoProps) => {
  // const [abrirCardCampanha, setAbrirCardCampanha] = useState<boolean>(false);
  const hoje = new Date().toISOString();
  const credenciais = obterCredenciais().credenciais;
  const perfilUsuario = credenciais.perfil[0];

  const navigate = useNavigate();

  const handleNomePublicacao = (publicacaoTipo: tipoPublicacaoProps) => {
    switch (publicacaoTipo) {
      case "PublicacaoInstagramFeed":
        return "Instagram Feed"
      case "PublicacaoInstagramReels":
        return "Instagram Reels"
      case "PublicacaoInstagramStories":
        return "Instagram Stories"
      case "PublicacaoTikTok":
        return "TikTok"
      case "PublicacaoTwitter":
        return "Twitter"
      case "PublicacaoYouTube":
        return "YouTube Video"
      case "PublicacaoYouTubeShorts":
        return "YouTube Shorts"
    }
  }

  return (
    <>
      <div className={styles.cardCampanha}>
        <header className={styles.headerCardCampanha}>
          <p>
            {publicacao.numeroIdentificadorDePublicacao} - {handleNomePublicacao(publicacao.tipo)}
          </p>
          <div className={`${styles.status} ${styles[publicacao.status]}`}>
            {publicacao.status === "Pendente" && (
              <>
                <BsRecordCircleFill
                  style={{
                    color: "#df9307",
                    fontSize: "1rem",
                  }}
                />
              </>
            )}

            {publicacao.status === "EmAnalise" && (
              <>
                <BsRecordCircleFill
                  style={{
                    color: "var(--roxo-500)",
                    fontSize: "1rem",
                  }}
                />
              </>
            )}
            {publicacao.status === "Ajustes" && (
              <>
                <BsRecordCircleFill
                  style={{
                    color: "#9f0707",
                    fontSize: "1rem",
                  }}
                />
              </>
            )}
            {publicacao.status === "Aprovado" && (
              <>
                <BsRecordCircleFill
                  style={{
                    color: "#539b57",
                    fontSize: "1rem",
                  }}
                />
              </>
            )}
          </div>
        </header>
        <body className={styles.bodyCardCampanhaAberto}>
          <div className={styles.detalhesCard}>
            <div className={styles.itemCard}>
              <p>Status: </p>
              <div className={`${styles.status} ${styles[publicacao.status]}`}>
                {publicacao.status === "Pendente" && <>Pendente</>}

                {publicacao.status === "EmAnalise" && <>Em Análise</>}
                {publicacao.status === "Ajustes" && <>Ajustes</>}
                {publicacao.status === "Aprovado" && <>Aprovado</>}
              </div>
            </div>

            <div className={styles.itemCard}>
              <p>Data da Publicação:</p>
              {publicacao.dataFinalizacao &&
                DateFormatter(publicacao.dataFinalizacao) === "01/01/0001"
                ? "Aguardando informações"
                : DateFormatter(publicacao.dataFinalizacao)}
            </div>

            {publicacao.status === "Ajustes" && (
              <div className={`${styles.itemCard} ${styles.ajustes}`}>
                <p>Ajustes</p>
                {publicacao.comentariosNetcos}
              </div>
            )}
          </div>
        </body>

        <div className={styles.footerCard}>
          {(perfilUsuario === "Administrador" ||
            perfilUsuario === "UsuarioNetcos") && (
              <BotaoColunaAcoes
                status={
                  publicacao.status === "Pendente" ||
                    publicacao.status === "Aprovado"
                    ? "Removido"
                    : ""
                }
                icone={"pi-check-circle"}
                titulo={"Aprovar / Rejeitar"}
                onClick={() => {
                  setPublicacaoSelecionada(publicacao);
                  setMostrarModalAprovarTarefa(true);
                }}
              />
            )}

          <BotaoColunaAcoes
            icone={"pi-pencil"}
            titulo={"Adicionar Métricas"}
            onClick={() => {
              setMostrarModalEditarTarefa(true);
              setPublicacaoSelecionada(publicacao);
            }}
            status={
              perfilUsuario === "Influenciador"
                ? publicacao.status === "Aprovado"
                  ? "Removido"
                  : ""
                : ""
            }
          ></BotaoColunaAcoes>

          {(perfilUsuario === "Administrador" ||
            perfilUsuario === "UsuarioNetcos") && (
              <BotaoColunaAcoes
                icone={"pi-trash"}
                titulo={"Excluir Publicação 1"}
                onClick={() => {
                  setMostrarsetModalExclusaoDePublicacao &&
                    setMostrarsetModalExclusaoDePublicacao(true);
                  setPublicacaoId(publicacao.externalId);
                  setCampanhaId(idCampanha!);
                }}
                status={"Aprovado" || "Pendente"}
              />
            )}
        </div>
      </div>
    </>
  );
};
