import React, { useEffect, useRef, useState } from "react";
import { FunctionsRedefinicaoSenha } from "./functions";
import { CriteriosSenha } from "../../utils/CriteriosSenha";

import styles from "./styles.module.css";
import logo from "../../assets/img/netcos-logo.png";

import { Input } from "../../components/Input";
import { Button } from "../../components/Botoes/Button";
import { Carregando } from "../../components/Carregando";

import { Toast } from "primereact/toast";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export const RedefinirSenha = () => {
  const parametrosUrl = new URLSearchParams(window.location.search);

  const email = parametrosUrl.get("Email") || "";
  const token = parametrosUrl.get("Token") || "";

  const [senha, setSenha] = useState<string>("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState<string>("");

  const [erroConfirmacaoSenha, setErroConfirmacaoSenha] = useState<boolean>(false);
  const [erro, setErro] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);

  const myToast = useRef<any>(null);


  const { redefinirSenha } = FunctionsRedefinicaoSenha({
    email,
    senha,
    confirmacaoSenha,
    setCarregando,
    setErro,
    setSucesso,
    token,
    myToast,
    setErroConfirmacaoSenha
  });

  return (
    <main className={styles.paginaRedefinicao}>

      <form className={styles.formRedefinicao} onSubmit={(e) => (e.preventDefault(), redefinirSenha)}>
        <header>
          <div id={styles.containerLogo}>
            <img src={logo} alt="Logo NetCos" className={styles.logoLogin} />
            <p>NetCos</p>
          </div>

          <h1>Redefinição de Senha</h1>
          <p>Digite e confirme uma nova senha</p>
        </header>

        <div className={styles.containerInputs}>

          <Input
            type={"password"}
            required
            value={senha}
            placeholder="Nova Senha"
            onChange={(e) => setSenha(e.target.value)}
            senha={true}
          />

          <Input
            type={"password"}
            required
            value={confirmacaoSenha}
            placeholder="Confirme sua Nova Senha"
            onChange={(e) => setConfirmacaoSenha(e.target.value)}
            senha={true}
          />
        </div>
        <CriteriosSenha senha={senha} />
        {erroConfirmacaoSenha &&
          <p className={styles.erroSenhaConfirmacao}>
            A senha e a confirmação devem ser iguais.
          </p>
        }

        <div className={styles.containerBotao}>

          {carregando ? (
            <Carregando />
          ) : (
            <Button importancia="primario" type="submit" title="Confirmar" onClick={redefinirSenha} icon={<i className="pi pi-arrow-right" style={{ fontSize: '1.5rem', padding: '0 0 0 1rem' }}></i>} />
          )}
        </div>

        {/* {erro && ""} */}
        <Toast ref={myToast} className="custom-toast" />
      </form>
    </main >
  );
};
