import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";

import { useLocation } from "react-router-dom";

import { RenovarTokenContext } from "../RenovarTokenContext";
import { getAgenciaTodas, listaAgenciaProps } from "../../services/agencia";

import { obterTokens } from "../../utils/LocalStorage";

import { tipoOrdenacao } from "../../@types/ordenacao";
import { ErroType } from "../../@types/erro";

interface Props {
  children?: ReactNode;
}

interface AgenciaContextProps {
  listaDeAgencias: listaAgenciaProps[];
  recarregarListaDeAgencias: () => void;
  carregandoListaDeAgencias: boolean;
  erroListaDeAgencia: ErroType;
  mudarTipoOrdenacao: (tipoDeOrdenacaoListaAgencias: tipoOrdenacao) => void;
  tipoDeOrdenacao: tipoOrdenacao;
  mudarNomeDaPropriedadeDeOrdenacao: (propriedadeDeOrdenacao: string) => void;
  nomeDaPropriedadeDeOrdenacao: string;
}

export const AgenciaContext = createContext<AgenciaContextProps>({
  listaDeAgencias: [],
  recarregarListaDeAgencias: () => {},
  carregandoListaDeAgencias: false,
  erroListaDeAgencia: { ativo: false, mensagem: "" },
  mudarTipoOrdenacao: () => {},
  tipoDeOrdenacao: "Ascending",
  mudarNomeDaPropriedadeDeOrdenacao: () => {},
  nomeDaPropriedadeDeOrdenacao: "nome",
});

export const AgenciaProvider = ({ children }: Props) => {
  const [listaDeAgencias, setListaDeAgencias] = useState<listaAgenciaProps[]>(
    []
  );
  const [tipoDeOrdenacao, setTipoDeOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomeDaPropriedadeDeOrdenacao, setNomeDaPropriedadeDeOrdenacao] =
    useState<string>("nome");
  const [carregandoListaDeAgencias, setCarregandoListaDeAgencias] =
    useState<boolean>(false);
  const [recarregarRequisicao, setRecarregarRequisicao] =
    useState<boolean>(false);
  const [erroListaDeAgencia, setErroListaDeAgencia] = useState<ErroType>({
    ativo: false,
    mensagem: "",
  });

  let location = useLocation();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  function recarregarListaDeAgencias() {
    setRecarregarRequisicao((state) => !state);
  }

  function mudarTipoOrdenacao(tipoOrdenacao: tipoOrdenacao) {
    setTipoDeOrdenacao(tipoOrdenacao);
  }

  function mudarNomeDaPropriedadeDeOrdenacao(
    nomeDaPropriedadeDeOrdenacao: string
  ) {
    setNomeDaPropriedadeDeOrdenacao(nomeDaPropriedadeDeOrdenacao);
  }

  function obterListaDeAgencias() {
    setCarregandoListaDeAgencias(true);
    setErroListaDeAgencia({
      ativo: false,
      mensagem: "",
    });

    const { token, tokenRenovacao } = obterTokens();

    getAgenciaTodas(
      token,
      1,
      99999,
      tipoDeOrdenacao,
      nomeDaPropriedadeDeOrdenacao
    )
      .then((resposta) => {
        setListaDeAgencias(resposta.data.agencias);
        setCarregandoListaDeAgencias(false);
      })
      .catch((erro) => {
        if (erro.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getAgenciaTodas(
              token,
              1,
              99999,
              tipoDeOrdenacao,
              nomeDaPropriedadeDeOrdenacao
            )
              .then((resposta) => {
                setListaDeAgencias(resposta.data.agencias);
              })
              .catch(() => {
                setErroListaDeAgencia({
                  ativo: true,
                  mensagem:
                    "Ocorreu um erro listando as agências, tente novamente.",
                });
              });
          }, setCarregandoListaDeAgencias);
        } else {
          setErroListaDeAgencia({
            ativo: true,
            mensagem: "Ocorreu um erro listando as agências, tente novamente.",
          });
          setCarregandoListaDeAgencias(false);
        }
      });
  }

  useEffect(() => {
    const { token, tokenRenovacao } = obterTokens();

    if (
      token &&
      tokenRenovacao &&
      !carregandoListaDeAgencias &&
      location.pathname !== "/"
    ) {
      obterListaDeAgencias();
    }
  }, [recarregarRequisicao, nomeDaPropriedadeDeOrdenacao, tipoDeOrdenacao]);

  return (
    <AgenciaContext.Provider
      value={{
        carregandoListaDeAgencias,
        erroListaDeAgencia,
        listaDeAgencias,
        mudarNomeDaPropriedadeDeOrdenacao,
        nomeDaPropriedadeDeOrdenacao,
        mudarTipoOrdenacao,
        tipoDeOrdenacao,
        recarregarListaDeAgencias,
      }}
    >
      {children}
    </AgenciaContext.Provider>
  );
};
