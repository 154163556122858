import React from "react";

import './styles.css'

export interface textareaProps {
    rows?: number,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    maxWidth?: number,
    minWidth?: number,
    maxHeight?: number,
    minHeight?: number,
    placeholder?: string,

}

export const Textarea = ({ onChange, rows, value, maxWidth, placeholder, minHeight, maxHeight, minWidth, ...outros }: textareaProps) => (
    <textarea
        placeholder={placeholder}
        className="textarea"
        style={{
            maxWidth: maxWidth,
            minWidth: minWidth,
            maxHeight: maxHeight,
            minHeight: minHeight,
        }}
        rows={rows} value={value}
        onChange={onChange}
        {...outros}></ textarea>
)