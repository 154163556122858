import { useState } from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { mascaraBRLExibir } from '../../../utils/MascaraBRL';
import { DateFormatter } from "../../../utils/DataFormatter";


import styles from './styles.module.css';
import { obterCredenciais } from '../../../utils/LocalStorage';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { campanha } from '../../../services/campanha';

export interface CardCampanhaProps {
  campanha: campanha;
  onClick?: any;

  index: number;
}

export const CardCampanha = ({ campanha, onClick, index }: CardCampanhaProps) => {

  const [abrirCardCampanha, setAbrirCardCampanha] = useState<boolean>(false);
  const hoje = new Date().toISOString();
  const credenciais = obterCredenciais().credenciais;
  const perfilUsuario = credenciais.perfil[0];


  const navigate = useNavigate();

  return (
    <>
      <div
        className={styles.cardCampanha}
        onClick={() => setAbrirCardCampanha(!abrirCardCampanha)}
      >
        <header className={styles.headerCardCampanha}
        >
          <p>{campanha.nome}</p>
          {campanha.dataFim < hoje ? (
            <div className={`${styles.status} ${styles.statusEncerrado}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--cinza-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          ) : (
            <div
              className={`${styles.status} ${styles.statusAtivo}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--verde-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          )}

        </header>
        <body className={abrirCardCampanha ? styles.bodyCardCampanhaAberto : styles.bodyCardCampanhaFechado}>

          <div className={styles.detalhesCard}>

            {(perfilUsuario !== "UsuarioAgencia") && (

              <div className={styles.itemCard}>
                <p>Agência:</p>{campanha.agencia.nome}
              </div>
            )}

            {(perfilUsuario !== "UsuarioMarca") && (
              <div className={styles.itemCard}>
                <p>Marca: </p>
                {campanha.marcas.map(marca => {
                  return <div key={marca.id}>
                    {marca.nome}
                  </div>
                })}
              </div>
            )}

            <div className={styles.itemCard}>
              <p>Período:</p> {DateFormatter(campanha.dataInicio)} até {DateFormatter(campanha.dataFim)}
            </div>

            {(perfilUsuario != "Influenciador") && (

              <div className={styles.itemCard}>
                <p>Relatório: </p>
                {
                  campanha.dataCriacaoRelatorio === "0001-01-01T00:00:00" ? "Não gerado" : DateFormatter(campanha.dataCriacaoRelatorio)
                }
              </div>
            )}

            {(perfilUsuario != "Influenciador") && (
              <div className={styles.itemCard}>
                <p>Taxa de Engajamento: </p>{campanha.mediaTaxaEngajamento}%
              </div>
            )}

            {(perfilUsuario != "Influenciador") && (
              <div className={styles.itemCard}>
                <p>Custo por Engajamento: </p> R$ {mascaraBRLExibir(campanha.custoEngajamento.toFixed(2))}
              </div>
            )}
          </div>

          <div
            className={styles.botaoDetalhes}
            onClick={onClick}
          >
            Ver Detalhes
          </div>

        </body>
        <div className={abrirCardCampanha ? styles.footerCard : styles.footerCardFechado}>
          <div
            className={styles.botaoSetinha}
          >
            {
              !abrirCardCampanha ?
                <MdExpandMore />
                :
                <MdExpandLess />
            }
          </div>
        </div>

      </div>
    </>
  )
}