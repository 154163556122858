import { AxiosResponse } from "axios";
import { api } from "./api";

type perfils =
  | "Administrador"
  | "UsuarioNetcos"
  | "Influenciador"
  | "UsuarioMarca"
  | "UsuarioAgencia"
  | "";

interface postUsuarioLoginResposta {
  data: {
    codigo: number;
    mensagem: string;
    camposAdicionais: {
      tokenAcesso: string;
      expiraEm: string;
      tokenRenovacao: string;
    };
  };
}

interface getRenovarTokenResposta {
  data: {
    codigo: number;
    mensagem: string;
    tokenAcesso: string;
    expiraEm: string;
    tokenRenovacao: string;
  };
}
interface getUsuarioCredenciaisResposta {
  data: {
    codigo: number;
    resultado: {
      credenciais: credenciais;
    };
  };
}

export interface credenciais {
  id: string;
  userName: string;
  nome: string;
  celular: string;
  perfil: perfils[];
  fotoUsuario: null;
  urlAvatar: string | null;
  termosDeUsoAceitos: boolean;
}

export const postUsuarioLogin = (
  email: string,
  senha: string
): Promise<postUsuarioLoginResposta> => {
  const url = "/Usuario/Login";

  const dadosLogin = {
    email,
    senha,
  };

  return api.post(url, { dadosLogin });
};

//TODO: Verificar a url quando Rafael subir as mudanças // Trocar o nome para getUsuarioCredenciaisPorEmail
export const getUsuarioCredencias = (
  email: string,
  token: string
  ): Promise<getUsuarioCredenciaisResposta> => {
    const url = `/Usuario/Credenciais?Email=${email}`;
    
    return api.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  };
  
//TODO: Verificar a url quando Rafael subir as mudanças
export const getUsuarioCredenciasPorUsername = (
  nomeUsuario: string,
  token: string
): Promise<getUsuarioCredenciaisResposta> => {
  const url = `/Usuario/Credenciais?nomeUsuario=${nomeUsuario}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postSenhaRedefinir = (
  email: string,
  token: string,
  novaSenha: string
) => {
  const url = "/Usuario/Senha/Redefinir";

  const data = {
    email,
    token,
    novaSenha,
  };

  return api.post(url, data);
};

export const getEmailConfirmacao = (email: string, token: string) => {
  const url = `/Usuario/Email/Confirmacao?Email=${email}&Token=${encodeURIComponent(
    token
  )}`;

  return api.get(url);
};

export const getEmailConfirmacaoReenviar = (email: string, tipo: string, senha: string) => {
  const url = `/Usuario/Email/Confirmacao/Reenviar?Destinatario=${email}&TipoEmail=${tipo}&Senha=${senha}`;

  return api.get(url);
};

export const getSenhaSolicitarRedefinicao = (email: string) => {
  const url = `/Usuario/Senha/SolicitarRedefinicao?Email=${email}`;

  return api.get(url);
};



export const getRenovarToken = (
  TokenAcesso: string,
  TokenRenovacao: string
): Promise<getRenovarTokenResposta> => {
  const url = `/Usuario/RenovarTokens?TokenAcesso=${encodeURIComponent(
    TokenAcesso
  )}&TokenRenovacao=${encodeURIComponent(TokenRenovacao)}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + TokenAcesso,
    }
  });
};

export const putUsuarioSenhaAlterar = (
  token: string,
  idCredenciais: string,
  senhaAtual: string,
  novaSenha: string
) => {

  const url = `/Usuario/Senha/Alterar`

  return api.put(url, {
    idCredenciais,
    senhaAtual,
    novaSenha
  }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export interface putUsuarioProps {
  dadosUsuario: dadosUsuarioProps
}

export interface dadosUsuarioProps {
  id: string,
  nome: string,
  imagemAvatar: string | null,
  telefone: string,
  status: string,
  termosDeUsoAceitos: boolean
}
export interface dadosUsuarioProps2 {
  id: string,
  nome: string,
  imagemAvatar: string | null,
  telefone: number,
  termosDeUsoAceitos: boolean
}

export const putUsuarioTermosDeAceite = (token: string, credenciais: credenciais): Promise<AxiosResponse<any, any>> => {
  const url = `/Usuario`

  let dadosUsuario: dadosUsuarioProps = {
    id: credenciais.id,
    nome: credenciais.nome,
    imagemAvatar: null,
    status: 'Ativo',
    telefone: credenciais.celular,
    termosDeUsoAceitos: true
  }

  return api.put(url, { dadosUsuario }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const putUsuarioDesativacao = (token: string, credenciais: credenciais): Promise<AxiosResponse<any, any>> => {
  const url = `/Usuario`

  let dadosUsuario: dadosUsuarioProps = {
    id: credenciais.id,
    nome: credenciais.nome,
    imagemAvatar: null,
    status: 'Removido',
    telefone: credenciais.celular,
    termosDeUsoAceitos: false
  }

  return api.put(url, { dadosUsuario }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}
export const putUsuarioReativacao = (token: string, credenciais: dadosUsuarioProps): Promise<AxiosResponse<any, any>> => {
  const url = `/Usuario`

  let dadosUsuario: dadosUsuarioProps = {
    id: credenciais.id,
    nome: credenciais.nome,
    imagemAvatar: null,
    status: 'Ativo',
    telefone: credenciais.telefone,
    termosDeUsoAceitos: true
  }

  return api.put(url, { dadosUsuario }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const putUsuario = (
  token: string,
  credenciais: credenciais,
  nome: string,
  imagemAvatar: string | null,
  telefone: string,
): Promise<AxiosResponse<any, any>> => {
  const url = `/Usuario`

  const dadosUsuario = {
    id: credenciais.id,
    nome,
    imagemAvatar,
    telefone,
    termosDeUsoAceitos: credenciais.termosDeUsoAceitos
  }

  return api.put(url, { dadosUsuario }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}


export const putUsuarioRemoverDadosUsuarioPendentes = () => {

  const url = `/Usuario/RemoverDadosUsuariosPendentes`

  return api.put(url, {
    dadosUsuario: {}
  }
  )
}

export const editarEmailDoUsuario = (
  token: string,
  idCredenciais: string,
  email: string
) => {
  const url = "Usuario/Credenciais/Atualizar";

  return api.put(url, {
    token,
    idCredenciais,
    email,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};


