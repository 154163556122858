import { useContext } from "react";

import { AxiosResponse } from "axios";
import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { FunctionsTabelaListarCampanha } from "../../../components/Tabelas/TabelaListarCampanhas/functions";
import {
  campanha,
  getCampanha,
  getCampanhaTodasAgencia,
  getCampanhaTodasMarca,
  getCampanhaTodasNome,
  respostaGetCampanha,
  getCampanhaTodasPeriodo,
  getCampanhaporStatus,
  getCampanhaTodasPorAgenciaPorNome,
  getCampanhaTodasPorMarcasPorNome,
} from "../../../services/campanha";
import { obterTokens } from "../../../utils/LocalStorage";
import XLSX, { ColInfo } from "sheetjs-style";
import saveAs from "file-saver";
import { DateFormatter } from "../../../utils/DataFormatter";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FuncaoListarCampanhaProps {
  idMarca: string;
  idAgencia: string;
  nomePesquisa: string;
  numeroDePagina: number;
  tamanhoDePagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setTamanhoDePagina: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  setListaDeCampanhas: React.Dispatch<campanha[]>;
  setTotalCampanhas: React.Dispatch<number>;
  listaDeCampanhas: campanha[];
  dataInicial: string;
  dataFinal: string;
  status: number | undefined;
  setRecarregar: React.Dispatch<boolean>;
  recarregar: boolean;
  carregando: boolean;
  totalCampanhas: number;
  tamanhoPagina: number;
  setTamanhoPagina: React.Dispatch<React.SetStateAction<number>>;
  setCarregandoXLS: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncaoListarCampanha = ({
  dataInicial,
  dataFinal,
  idAgencia,
  idMarca,
  nomePesquisa,
  numeroDePagina,
  tamanhoDePagina,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  setTamanhoDePagina,
  setErro,
  setCarregando,
  setListaDeCampanhas,
  setTotalCampanhas,
  status,
  setRecarregar,
  recarregar,
  carregando,
  totalCampanhas,
  tamanhoPagina,
  setTamanhoPagina,
  setCarregandoXLS,
  adicionarAListaEsperaRenovacaoToken,
}: FuncaoListarCampanhaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = (res: AxiosResponse<respostaGetCampanha, any>) => {
    setListaDeCampanhas(res.data.campanhas);
    setTotalCampanhas(res.data.totalRegistros);
  };

  const erroRequisicao = () => {
    setErro({
      ativo: true,
      mensagem: "Erro ao carregar a lista de Campanhas.",
    });
  };

  const obterCampanhas = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });

    setCarregando(true);

    const { token } = obterTokens();

    getCampanha(
      token,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch(async (err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanha(
              token,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorNome = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasNome(
      token,
      nomePesquisa,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasNome(
              token,
              nomePesquisa,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorMarca = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasMarca(
      token,
      idMarca,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getCampanhaTodasMarca(
              token,
              idMarca,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorAgencia = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasAgencia(
      token,
      idAgencia,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getCampanhaTodasAgencia(
              token,
              idAgencia,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          });
        } else {
          erroRequisicao();
        }
      });
  };

  const obterCampanhasPorAgenciaPorNome = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token } = obterTokens();

    getCampanhaTodasPorAgenciaPorNome(
      token,
      nomePesquisa,
      idAgencia,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasPorAgenciaPorNome(
              token,
              nomePesquisa,
              idAgencia,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorMarcaPorNome = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorMarcasPorNome(
      token,
      nomePesquisa,
      idMarca,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasPorMarcasPorNome(
              token,
              nomePesquisa,
              idMarca,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorPeriodo = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPeriodo(
      token,
      dataInicial,
      dataFinal,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasPeriodo(
              token,
              dataInicial,
              dataFinal,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorStatus = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaporStatus(
      token,
      status!,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaporStatus(
              token,
              status!,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasParaImprecao = () => {
    const { criaPDFListaDeCampanhas } = FunctionsTabelaListarCampanha({
      setRecarregar: () => setRecarregar(!recarregar),
      carregando,
      setCarregando,
      totalCampanhas,
      tamanhoPagina,
      setTamanhoPagina,
      adicionarAListaEsperaRenovacaoToken,
    });

    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanha(
      token,
      numeroDePagina,
      999999999,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then(sucessoRequisicao)
      .then(() => setTimeout(criaPDFListaDeCampanhas, 300))
      .then(() => {
        setTamanhoDePagina(5);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanha(
              token,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                getCampanha(
                  token,
                  1,
                  res.data.totalRegistros,
                  tipoOrdenacao,
                  nomePropriedadeOrdenacao
                )
                  .then(sucessoRequisicao)
                  .then(() => setTimeout(criaPDFListaDeCampanhas, 300));
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasParaGerarXls = () => {
    setCarregandoXLS(true);
    setErro({
      ativo: false,
      mensagem: "",
    });

    const { token, tokenRenovacao } = obterTokens();

    getCampanha(
      token,
      numeroDePagina,
      1000000,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        gerarExcelDeListaDeCampanhas(res.data.campanhas);
        setCarregandoXLS(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanha(
              token,
              numeroDePagina,
              10000000,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => gerarExcelDeListaDeCampanhas(res.data.campanhas))
              .catch(() => {
                mostrarToast("error", "Erro", "Não foi possível gerar XLS.");
              });
          }, setCarregandoXLS);
        } else {
          mostrarToast("error", "Erro", "Não foi possível gerar XLS.");
          setCarregandoXLS(false);
        }
      });
  };

  async function gerarExcelDeListaDeCampanhas(array: campanha[]) {
    const tipoArquivo =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const extensaoArquivo = ".xlsx";

    const arrayDeCampanhas = await mapearCampanhasParaBenchmarking(array);

    const dadosParaTabela = XLSX.utils.json_to_sheet(arrayDeCampanhas);
    tamanhoColunaAutomatico(dadosParaTabela);
    const tabela = { Sheets: { data: dadosParaTabela }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(tabela, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: tipoArquivo });
    saveAs(
      data,
      "Tabela Benchmarking " +
        DateFormatter(Date.now()).replace("/", "-").replace("_", "-") +
        extensaoArquivo
    );
  }

  async function mapearCampanhasParaBenchmarking(array: campanha[]) {
    const arrayDeCampanhasMapeado: any[] = [];

    array.forEach((item) => {
      arrayDeCampanhasMapeado.push({
        Nome: item.nome,
        "Data Inicio": DateFormatter(item.dataInicio),
        "Data Fim": DateFormatter(item.dataFim),
        "Data do Relatorio":
          DateFormatter(item.dataCriacaoRelatorio) == "01/01/0001"
            ? "Não gerado"
            : DateFormatter(item.dataCriacaoRelatorio),
        Feed: item.instagramFeedTaxaEngajamento.toFixed(2),
        Reels: item.instagramReelsTaxaEngajamento.toFixed(2),
        Stories: item.instagramStoriesTaxaEngajamento.toFixed(2),
        Tiktok: item.tiktokTaxaEngajamento.toFixed(2),
        Twitter: item.twitterTaxaEngajamento.toFixed(2),
        "Youtube Video": item.youtubeVideoTaxaEngajamento.toFixed(2),
        "Youtube Shorts": item.youtubeShortsTaxaEngajamento.toFixed(2),
      });
    });

    return arrayDeCampanhasMapeado;
  }

  function tamanhoColunaAutomatico(tabela: XLSX.WorkSheet) {
    const [letraInicial, letraFinal] = tabela["!ref"]
      ?.replace(/\d/, "")
      .split(":")!;
    let numRegexp = new RegExp(/\d+$/g);
    let inicio = letraInicial.charCodeAt(0),
      final = letraFinal.charCodeAt(0) + 1,
      colunas = +numRegexp.exec(letraFinal)![0];
    let alcance: number[] = [];

    for (let i = inicio; i < final; i++) {
      alcance.push(i);
    }

    let tamanhoMaximoObjeto: ColInfo[] = [];

    alcance.forEach((c) => {
      const celula = String.fromCharCode(c);
      let tamanhoMaximoCelula = 0;
      for (let y = 1; y <= colunas; y++) {
        let tamanhoCelula = tabela[`${celula}${y}`].v.length + 1;
        if (tamanhoCelula > tamanhoMaximoCelula) {
          tamanhoMaximoCelula = tamanhoCelula;
        }
      }

      tamanhoMaximoObjeto.push({ width: tamanhoMaximoCelula });
    });

    tabela["!cols"] = tamanhoMaximoObjeto;
  }

  return {
    obterCampanhas,
    obterCampanhasPorAgencia,
    obterCampanhasPorAgenciaPorNome,
    obterCampanhasPorMarca,
    obterCampanhasPorMarcaPorNome,
    obterCampanhasPorNome,
    obterCampanhasPorPeriodo,
    obterCampanhasPorStatus,
    obterCampanhasParaImprecao,
    obterCampanhasParaGerarXls,
  };
};
