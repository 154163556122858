import { useContext, useState } from "react";

import { ErroType } from "../../../@types/erro";
import { CredenciaisContext } from "../../../context/CredenciaisContext";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TituloColuna } from "../../TituloColuna";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { DateFormatter } from "../../../utils/DataFormatter";
import { PublicacoesProps } from "../../../services/publicacao";
import { ModalExclusaoDePublicacao } from "../../Modais/ModalExclusaoDePublicacao";
import { CardPublicacao } from "../../Cards/CardPublicacao";

import styles from "./styles.module.css";

import { BsInfoCircle, BsRecordCircleFill } from "react-icons/bs";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useParams } from "react-router-dom";

export interface TabelaListarPublicacoesDeTarefaProps {
  tituloTabela?: any;
  tituloColuna: TitulosTabelasType[];
  listaDePublicacoes: PublicacoesProps[];
  carregando: boolean;
  // paginacao: any;
  tipoOrdenacao: tipoOrdenacao;
  erro: ErroType;
  // seletorDeQuantidade: any;
  nomePropriedadeOrdenacao: string;
  totalDePublicacoes: number;
  tamanhoDePagina: number;
  setNomePropriedadeOrdenacao: React.Dispatch<
    React.SetStateAction<"Id" | "DataFinalizacao" | "Status" | string>
  >;
  setMostrarModalEditarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setRecarregar: () => void;
  setPublicacaoSelecionada: React.Dispatch<
    React.SetStateAction<PublicacoesProps>
  >;
  setMostrarModalAprovarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  dataInicioCampanha: string | undefined;
  dataRelatorio?: string | undefined;
}

export const TabelaListarPublicacoesDeTarefa = ({
  tituloTabela,
  tituloColuna,
  listaDePublicacoes,
  carregando,
  tipoOrdenacao,
  // seletorDeQuantidade,
  erro,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setMostrarModalEditarTarefa,
  setTipoOrdenacao,
  setRecarregar,
  setPublicacaoSelecionada,
  setMostrarModalAprovarTarefa,
  dataInicioCampanha,
  dataRelatorio,
}: // paginacao,
TabelaListarPublicacoesDeTarefaProps) => {
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];
  const [
    mostrarsetModalExclusaoDePublicacao,
    setMostrarsetModalExclusaoDePublicacao,
  ] = useState<boolean>(false);
  const [publicacaoId, setPublicacaoId] = useState<string>("");
  const [campanhaId, setCampanhaId] = useState<string>("");
  const { idCampanha } = useParams();
  const mostrarComentario = (event: any, publicacao: PublicacoesProps) => {
    confirmPopup({
      target: event.currentTarget,
      message: `${
        publicacao.comentariosNetcos
          ? publicacao.comentariosNetcos
          : "Nenhum comentário feito"
      }`,
      rejectClassName: `${styles.botaoRejeitarComentario}`,
      acceptClassName: `${styles.botaoAceitarComentario}`,
      style: {
        fontSize: "1.6rem",
        maxWidth: "30vw",
        minWidth: "15vw",
      },
      footer: "",
    });
  };

  return (
    <>
      <table id="tabelaListarMarcas" className={styles.containerTabela}>
        <thead>
          <tr>
            <th className={styles.headerTabela}>
              <div className={styles.tituloTabela}>
                <h1>{tituloTabela}</h1>
              </div>
            </th>
          </tr>

          <tr className={styles.tituloColuna}>
            {tituloColuna.map((titulo) => {
              return (
                <TituloColuna
                  key={tituloColuna.indexOf(titulo)}
                  colunaIndex={tituloColuna.indexOf(titulo)}
                  tituloColuna={titulo.nome}
                  tituloOrdenacao={titulo.ordenacao}
                  iconOrdenacao={[]}
                  tipoOrdenacao={tipoOrdenacao}
                  setTipoOrdenacao={setTipoOrdenacao}
                  nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                  setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                />
              );
            })}
          </tr>
        </thead>

        <tbody id="corpoTabelaListarMarcas" className={styles.corpoTabela}>
          {carregando ? (
            <Carregando
              listaUsuarios={listaDePublicacoes.length}
              spinner={true}
            />
          ) : (
            <>
              {erro.ativo ? (
                <ErroTabela erro={erro} setRecarregar={setRecarregar} />
              ) : (
                <>
                  {listaDePublicacoes && listaDePublicacoes.length === 0 ? (
                    <tr>
                      <td style={{ justifyContent: "center" }}>
                        Nenhum resultado a ser exibido.
                      </td>
                    </tr>
                  ) : (
                    listaDePublicacoes.map((publicacao, index) => {
                      return (
                        <tr>
                          <td className={styles.colunaNome}>
                            {publicacao.tipo &&
                            publicacao.tipo === "PublicacaoYouTube"
                              ? publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                "Youtube Video"
                              : publicacao.tipo === "PublicacaoInstagramFeed"
                              ? publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                "Instagram Feed"
                              : publicacao.tipo === "PublicacaoInstagramReels"
                              ? publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                "Instagram Reels"
                              : publicacao.tipo === "PublicacaoInstagramStories"
                              ? publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                "Instagram Stories"
                              : publicacao.tipo === "PublicacaoYouTubeShorts"
                              ? publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                "Youtube Shorts"
                              : publicacao.numeroIdentificadorDePublicacao +
                                " - " +
                                publicacao.tipo.substring(10)}
                          </td>
                          <td>
                            {publicacao.dataFinalizacao ===
                            "0001-01-01T00:00:00"
                              ? "Aguardando informações"
                              : DateFormatter(publicacao.dataFinalizacao)}
                          </td>

                          <td>
                            <div
                              className={`${styles.status} ${
                                styles[publicacao.status]
                              }`}
                            >
                              {publicacao.status === "Pendente" && (
                                <>
                                  <BsRecordCircleFill
                                    style={{
                                      color: "#df9307",
                                      fontSize: "1rem",
                                    }}
                                  />
                                  Pendente
                                </>
                              )}

                              {publicacao.status === "EmAnalise" && (
                                <>
                                  <BsRecordCircleFill
                                    style={{
                                      color: "var(--roxo-500)",
                                      fontSize: "1rem",
                                    }}
                                  />
                                  Em Análise
                                </>
                              )}
                              {publicacao.status === "Ajustes" && (
                                <>
                                  <BsRecordCircleFill
                                    style={{
                                      color: "#9f0707",
                                      fontSize: "1rem",
                                    }}
                                  />
                                  <div
                                    onClick={(e) =>
                                      mostrarComentario(e, publicacao)
                                    }
                                    title="Ver ajustes necessários"
                                  >
                                    <ConfirmPopup />
                                    <p>Ajustes</p>
                                    <BsInfoCircle size={"1.2rem"} />
                                  </div>
                                </>
                              )}
                              {publicacao.status === "Aprovado" && (
                                <>
                                  <BsRecordCircleFill
                                    style={{
                                      color: "#539b57",
                                      fontSize: "1rem",
                                    }}
                                  />
                                  Aprovado
                                </>
                              )}
                            </div>
                          </td>

                          <td className="colunaBotoesAcoes">
                            {perfil === "Influenciador" ? (
                              <>
                                <BotaoColunaAcoes
                                  icone={"pi-pencil"}
                                  titulo={
                                    dataRelatorio === "0001-01-01T00:00:00"
                                      ? "Adicionar Métricas"
                                      : "Campanha já fechada"
                                  }
                                  onClick={() => {
                                    publicacao.status !== "Aprovado" &&
                                      setMostrarModalEditarTarefa(true);
                                    setPublicacaoSelecionada(publicacao);
                                  }}
                                  status={
                                    publicacao.status === "Aprovado" ||
                                    dataRelatorio !== "0001-01-01T00:00:00"
                                      ? "Removido"
                                      : ""
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {/* <BotaoColunaAcoes
                                  status={
                                    publicacao.status === "Pendente" ||
                                    publicacao.status === "Aprovado"
                                      ? "Removido"
                                      : ""
                                  }
                                  icone={"pi-check-circle"}
                                  titulo={"Aprovar / Rejeitar"}
                                  onClick={() => {
                                    setPublicacaoSelecionada(publicacao);
                                    setMostrarModalAprovarTarefa(true);
                                  }}
                                /> */}
                                <BotaoColunaAcoes
                                  icone={"pi-pencil"}
                                  titulo={"Editar / Aprovar / Rejeitar"}
                                  onClick={() => {
                                    setMostrarModalEditarTarefa(true);
                                    setPublicacaoSelecionada(publicacao);
                                  }}
                                  status={"Aprovado" || "Pendente"}
                                />
                                <BotaoColunaAcoes
                                  icone={"pi-trash"}
                                  titulo={"Excluir Publicação"}
                                  onClick={() => {
                                    setMostrarsetModalExclusaoDePublicacao &&
                                      setMostrarsetModalExclusaoDePublicacao(
                                        true
                                      );
                                    setPublicacaoId(publicacao.externalId);
                                    setCampanhaId(idCampanha!);
                                  }}
                                  status={"Aprovado" || "Pendente"}
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </>
          )}
        </tbody>

        <div className={styles.listaCardsPublicacoes}>
          {carregando ? (
            <Carregando listaUsuarios={listaDePublicacoes.length} spinner />
          ) : (
            <>
              {erro.ativo ? (
                <ErroTabela erro={erro} recarregar={setRecarregar} />
              ) : (
                <>
                  {listaDePublicacoes.length === 0 ? (
                    <p className={styles.campoVazio}>
                      Nenhuma publicação cadastrada
                    </p>
                  ) : (
                    listaDePublicacoes.map((publicacao, index) => {
                      return (
                        <CardPublicacao
                          publicacao={publicacao}
                          index={index}
                          mostrarComentario={mostrarComentario}
                          dataInicioCampanha={dataInicioCampanha}
                          setMostrarModalEditarTarefa={
                            setMostrarModalEditarTarefa
                          }
                          setPublicacaoSelecionada={setPublicacaoSelecionada}
                          setMostrarModalAprovarTarefa={
                            setMostrarModalAprovarTarefa
                          }
                          setCampanhaId={setCampanhaId}
                          setMostrarsetModalExclusaoDePublicacao={
                            setMostrarsetModalExclusaoDePublicacao
                          }
                          setPublicacaoId={setPublicacaoId}
                          idCampanha={idCampanha}
                        />
                      );
                    })
                  )}
                </>
              )}
            </>
          )}
        </div>
        <tfoot className={styles.footerTabela}>
          {/* <div className={styles.containerItensFooter}>
            <div className={styles.containerSelectNumPorPagina}>
              {seletorDeQuantidade}
            </div>
            {paginacao}
          </div> */}
        </tfoot>
        {mostrarsetModalExclusaoDePublicacao && (
          <ModalExclusaoDePublicacao
            modalAberto={mostrarsetModalExclusaoDePublicacao}
            setModalAberto={setMostrarsetModalExclusaoDePublicacao}
            idCampanha={campanhaId}
            idPublicacao={publicacaoId}
            setRecarregar={setRecarregar}
          />
        )}
      </table>
    </>
  );
};
