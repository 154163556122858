import { Input } from "../../../Input";

import styles from "../styles.module.css";
import { InputMetrica } from "./inputs/InputMetrica";

interface YoutubeVideoProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  dislikes: number;
  setDislikes: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoHora: number;
  setTempoReproducaoHora: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoMinuto: number;
  setTempoReproducaoMinuto: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoSegundo: number;
  setTempoReproducaoSegundo: React.Dispatch<React.SetStateAction<number>>;
  views: number;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  espectadores: number;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
}

export const YoutubeVideo = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  impressoes,
  setImpressoes,
  dislikes,
  setDislikes,
  views,
  setViews,
  tempoReproducaoHora,
  setTempoReproducaoHora,
  tempoReproducaoMinuto,
  setTempoReproducaoMinuto,
  tempoReproducaoSegundo,
  setTempoReproducaoSegundo,
  espectadores,
  setEspectadores

}: YoutubeVideoProps) => {
  const listaMetricas = [
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Impressoes", metrica: { valor: impressoes, dispatch: setImpressoes } },
    { nome: "Dislikes", metrica: { valor: dislikes, dispatch: setDislikes } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}

      <>
        <div className={styles.labelTempo}>Tempo de Reprodução</div>
        <div className="p-inputgroup">
          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Horas"
            value={
              tempoReproducaoHora !== 0
                ? tempoReproducaoHora
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoHora(e.target.valueAsNumber);
            }}
          />

          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Minutos"
            value={
              tempoReproducaoMinuto !== 0
                ? tempoReproducaoMinuto
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoMinuto(e.target.valueAsNumber);
            }}
          />

          <Input
            type="number"
            min={0}
            required
            disabled={aprovar}
            placeholder="Segundos"
            value={
              tempoReproducaoSegundo !== 0
                ? tempoReproducaoSegundo
                : undefined
            }
            onChange={(e) => {
              setTempoReproducaoSegundo(e.target.valueAsNumber);
            }}
          />
        </div>
      </>


      <InputMetrica
        nomeMetrica="Views"
        metrica={views}
        setMetrica={setViews}
        disabled={aprovar || desabilitarCampos}
      />

      <InputMetrica
        nomeMetrica="Espectadores"
        metrica={espectadores}
        setMetrica={setEspectadores}
        disabled={aprovar || desabilitarCampos}
      />
    </>
  )
}