import { useContext } from "react";

import { credenciais, putUsuarioTermosDeAceite } from "../../services/usuario";
import { obterTokens } from "../../utils/LocalStorage";
import { CredenciaisContext } from "../../context/CredenciaisContext";
import { getDocumentosLegais } from "../../services/documentosLegais";
import { NavigateFunction } from "react-router-dom";

export interface FunctionAceiteTermosProps {
  setTermosDeUsoURL: React.Dispatch<React.SetStateAction<string>>;
  setPoliticaDePrivacidadeURL: React.Dispatch<React.SetStateAction<string>>;
  setPoliticaDeConteudoURL: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  myToast: React.MutableRefObject<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionAceiteTermos = ({
  setPoliticaDeConteudoURL,
  setPoliticaDePrivacidadeURL,
  setTermosDeUsoURL,
  setCarregando,
  navigate,
  adicionarAListaEsperaRenovacaoToken,
  myToast,
}: FunctionAceiteTermosProps) => {
  const { atualizarCredenciais, credenciais } = useContext(CredenciaisContext);

  function pegaDocumentosLegais() {
    getDocumentosLegais("TermosDeUso", 1, 1, "Descending", "dataCriacao").then(
      (res) => {
        setTermosDeUsoURL(res.data.documentos[0].urlArquivo);
      }
    );
    getDocumentosLegais(
      "PoliticaDeConteudo",
      1,
      1,
      "Descending",
      "dataCriacao"
    ).then((res) => {
      setPoliticaDeConteudoURL(res.data.documentos[0].urlArquivo);
    });
    getDocumentosLegais(
      "PoliticaDePrivacidade",
      1,
      1,
      "Descending",
      "dataCriacao"
    ).then((res) => {
      setPoliticaDePrivacidadeURL(res.data.documentos[0].urlArquivo);
    });
  }

  function editaTermosUsuario() {
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    let novaCredenciais: credenciais = {
      celular: credenciais.celular,
      fotoUsuario: credenciais.fotoUsuario,
      id: credenciais.id,
      nome: credenciais.nome,
      perfil: credenciais.perfil,
      termosDeUsoAceitos: true,
      urlAvatar: credenciais.urlAvatar,
      userName: credenciais.userName,
    };

    putUsuarioTermosDeAceite(token, novaCredenciais)
      .then(() => {
        atualizarCredenciais(novaCredenciais);
        setCarregando(false);
        navigate("/home");
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putUsuarioTermosDeAceite(token, novaCredenciais).then(() => {
              atualizarCredenciais(novaCredenciais);
              navigate("/home");
            });
          }, setCarregando);
        } else {
          showToast(
            "error",
            "Cadastro não realizado",
            "Não foi possível cadastrar a campanha."
          );
          setCarregando(false);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    pegaDocumentosLegais,
    editaTermosUsuario,
  };
};
