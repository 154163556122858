import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { basicDataProps, FuncoesGraficoRepercussao } from "./functions";
import { ModalDeslogar } from "../../Modais/ModalDeslogar";
import { Prints } from "../../../services/publicacao";

import styles from "./styles.module.css";
import MascaraGrafico from "../../../utils/MascaraRotulosGraficos";

interface GraficoRepercussaoProps {
  printSentimento: Prints[];
}

export const GraficoRepercussao = ({
  printSentimento,
}: GraficoRepercussaoProps) => {
  let larguraPagina = window.innerWidth;

  const [basicData, setBasicData] = useState<basicDataProps>({
    labels: [],
    datasets: [],
  });

  ChartJS.register(ChartDataLabels);

  useEffect(() => {
    setaDados(printSentimento);
  }, []);

  const { setaDados } = FuncoesGraficoRepercussao({
    setBasicData,
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        display: true,
        position: larguraPagina <= 768 ? "top" : "right",
        labels: {
          font: "Exo 2, sans-serif",
        },
      },
      datalabels: {
        color: "#fff",
        // display: function (context: any) {
        //     return context.dataset.data[context.dataIndex] > 0;
        // },
        font: {
          size: 14,
          weight: "bold",
        },
        formatter: function (value: number) {
          return (
            MascaraGrafico(
              parseInt(((value * 100) / printSentimento.length).toFixed(0))
            ) + "%"
          );
        },
      },
    },
  });

  return (
    <>
      <div className={styles.containerGrafico}>
        <Chart type="doughnut" data={basicData} options={lightOptions} />
      </div>
    </>
  );
};
