import { ErroType } from "../../../@types/erro";
import {
  getUsuarioMarcaProps,
  getUsuariosMarca,
  usuarioMarcaProps,
  getUsuarioMarcaTodosNome,
  getUsuarioMarcaTodosMarca,
  getUsuarioMarcaTodosStatus,
} from "../../../services/usuarioMarca";
import { limpaLocalStorage, obterTokens } from "../../../utils/LocalStorage";
import { AxiosResponse } from "axios";

export interface FunctionsListarUsuariosMarcaProps {
  setListaUsuarios: React.Dispatch<usuarioMarcaProps[]>;
  setErro: React.Dispatch<ErroType>;
  setCarregando: React.Dispatch<boolean>;
  numeroPagina: number;
  tamanhoPagina: number;
  setUsuariosTotais: React.Dispatch<number>;
  tipoOrdenacao: string;
  propriedadeOrdenacao: string;
  nome: string;
  idMarcaFiltro: string;
  statusFiltro: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsListarUsuariosMarca = ({
  setListaUsuarios,
  setErro,
  setCarregando,
  numeroPagina,
  tamanhoPagina,
  setUsuariosTotais,
  tipoOrdenacao,
  propriedadeOrdenacao,
  nome,
  idMarcaFiltro,
  statusFiltro,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsListarUsuariosMarcaProps) => {
  function sucessoRequisicao({
    data,
  }: AxiosResponse<getUsuarioMarcaProps | any>) {
    console.log(data);
    setListaUsuarios(data.resultado ? data.resultado.usuarios : data.usuarios);
    setUsuariosTotais(
      data.resultado ? data.resultado.totalRegistros : data.totalRegistros
    );
    setCarregando(false);
  }

  function erroRequisicao() {
    setErro({
      ativo: true,
      mensagem: "Erro ao carregar a lista de Usuários Marca.",
    });
  }

  async function obterUsuarios() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);
    getUsuariosMarca(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao
    )
      .then((res) => sucessoRequisicao(res))
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuariosMarca(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch((err) => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  async function obterUsuariosPorNome() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    await getUsuarioMarcaTodosNome(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      nome
    )
      .then((res) => sucessoRequisicao(res))
      .catch(async (err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioMarcaTodosNome(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              nome
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  async function obterUsuariosPorMarca() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    await getUsuarioMarcaTodosMarca(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      idMarcaFiltro
    )
      .then((res) => sucessoRequisicao(res))
      .catch(async (err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioMarcaTodosNome(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              idMarcaFiltro
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  async function obterUsuariosPorStatus() {
    setErro({ ativo: false, mensagem: "" });
    const { token } = obterTokens();
    setCarregando(true);

    await getUsuarioMarcaTodosStatus(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      statusFiltro
    )
      .then((res) => sucessoRequisicao(res))
      .catch(async (err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioMarcaTodosStatus(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              statusFiltro
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      })
      .finally(() => setCarregando(false));
  }

  return {
    obterUsuarios,
    obterUsuariosPorNome,
    obterUsuariosPorMarca,
    obterUsuariosPorStatus,
  };
};
