import { useEffect, useState } from "react"
import { Chart } from "primereact/chart"
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ErroType } from "../../../@types/erro"
import { campanha } from "../../../services/campanha"
import { DadosLateraisInstagram } from "./DadosLateraisInstagram"
import { DadosLateraisTikTok } from "./DadosLateraisTikTok"
import { DadosLateraisTwitter } from "./DadosLateraisTwitter"
import { DadosLateraisYouTube } from "./DadosLateraisYouTube"
import { basicDataProps, FuncoesGraficoResultadosGeraisPorRedeSocial, redesSociaisProps } from "./functions"

import styles from './styles.module.css';


interface GraficoResultadosGeraisPorRedeSocialProps {
  campanha: campanha;
  tipoRedeSocial: redesSociaisProps;
}

export const GraficoResultadosGeraisPorRedeSocial = ({
  campanha,
  tipoRedeSocial,
}: GraficoResultadosGeraisPorRedeSocialProps) => {

  const [carregando, setCarregando] = useState<boolean>(true);
  const [desconectar, setDesconectar] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(true);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  const [dadosGraficoResultadosGerais, setDadosGraficoResultadosGerais] = useState<basicDataProps>({
    labels: [],
    datasets: []
  });

  ChartJS.register(ChartDataLabels);

  // IG
  const [existeInstagramFeed, setExisteInstagramFeed] = useState<boolean>(false);
  const [existeInstagramReels, setExisteInstagramReels] = useState<boolean>(false);
  const [existeInstagramStories, setExisteInstagramStories] = useState<boolean>(false);
  const [alcanceIG, setAlcanceIG] = useState<number>(0);
  const [comentariosIG, setComentariosIG] = useState<number>(0);
  const [likesIG, setLikesIG] = useState<number>(0);
  const [impressoesIG, setImpressoesIG] = useState<number>(0);
  const [salvosIG, setSalvosIG] = useState<number>(0);
  const [compartilhamentosIG, setCompartilhamentosIG] = useState<number>(0);
  const [visitasPerfilIG, setVisitasPerfilIG] = useState<number>(0);
  const [respostasIG, setRespostasIG] = useState<number>(0);
  const [cliquesLinkIG, setCliquesLinkIG] = useState<number>(0);
  const [cliquesStickersIG, setCliquesStickersIG] = useState<number>(0);


  // TikTok
  const [viewsTotaisTikTok, setViewsTotaisTikTok] = useState<number>(0);
  const [tempoReproducaoTikTok, setTempoReproducaoTikTok] = useState<number>(0);
  const [comentariosTikTok, setComentariosTikTok] = useState<number>(0);
  const [likesTikTok, setLikesTikTok] = useState<number>(0);
  const [compartilhamentosTikTok, setCompartilhamentosTikTok] = useState<number>(0);
  const [salvosTikTok, setSalvosTikTok] = useState<number>(0);
  const [espectadoresTikTok, setEspectadoresTikTok] = useState<number>(0);


  // Twitter
  const [impressoesTwitter, setImpressoesTwitter] = useState<number>(0);
  const [engajamentoTwitter, setEngajamentoTwitter] = useState<number>(0);
  const [comentariosTwitter, setComentariosTwitter] = useState<number>(0);
  const [retweets, setRetweets] = useState<number>(0);
  const [favoritosTwitter, setFavoritosTwitter] = useState<number>(0);
  const [respostasTwitter, setRespostasTwitter] = useState<number>(0);
  const [viewsTwitter, setViewsTwitter] = useState<number>(0);
  const [cliquesLinkTwitter, setCliquesLinkTwitter] = useState<number>(0);
  const [cliquesHashtagTwitter, setCliquesHashtagTwitter] = useState<number>(0);
  const [detailExpandsTwitter, setDetailExpandsTwitter] = useState<number>(0);

  // YouTube
  const [existeYouTubeVideo, setExisteYouTubeVideo] = useState<boolean>(false);
  const [existeYouTubeShorts, setExisteYouTubeShorts] = useState<boolean>(false);
  const [viewsTotaisYouTube, setViewsTotaisYouTube] = useState<number>(0);
  const [tempoReproducaoYouTube, setTempoReproducaoYouTube] = useState<number>(0);
  const [comentariosYouTube, setComentariosYouTube] = useState<number>(0);
  const [likesYouTube, setLikesYouTube] = useState<number>(0);
  const [dislikesYouTube, setDislikesYouTube] = useState<number>(0);
  const [impressoesYouTube, setImpressoesYouTube] = useState<number>(0);
  const [espectadoresYouTube, setEspectadoresYouTube] = useState<number>(0);
  const [taxaAprovacaoYouTube, setTaxaAprovacaoYouTube] = useState<number>(0);

  useEffect(() => {
    filtraDadosPorRedeSocial(campanha, tipoRedeSocial);
  }, [recarregar]);

  const { filtraDadosPorRedeSocial, getLightTheme } = FuncoesGraficoResultadosGeraisPorRedeSocial({
    setCarregando,
    setErro,
    setDesconectar,
    tipoRedeSocial,
    setExisteInstagramFeed,
    setExisteInstagramReels,
    setExisteInstagramStories,
    setExisteYouTubeVideo,
    setExisteYouTubeShorts,
    setDadosGraficoResultadosGerais,
    setAlcanceIG,
    setComentariosIG,
    setLikesIG,
    setImpressoesIG,
    setSalvosIG,
    setCompartilhamentosIG,
    setVisitasPerfilIG,
    setRespostasIG,
    setCliquesLinkIG,
    setCliquesStickersIG,
    setViewsTotaisTikTok,
    setTempoReproducaoTikTok,
    setComentariosTikTok,
    setLikesTikTok,
    setCompartilhamentosTikTok,
    setSalvosTikTok,
    setEspectadoresTikTok,
    setEngajamentoTwitter,
    setFavoritosTwitter,
    setComentariosTwitter,
    setRetweets,
    setImpressoesTwitter,
    setRespostasTwitter,
    setViewsTwitter,
    setCliquesLinkTwitter,
    setCliquesHashtagTwitter,
    setDetailExpandsTwitter,
    setViewsTotaisYouTube,
    setTempoReproducaoYouTube,
    setComentariosYouTube,
    setLikesYouTube,
    setDislikesYouTube,
    setImpressoesYouTube,
    setEspectadoresYouTube,
    setTaxaAprovacaoYouTube
  });

  const { basicOptions } = getLightTheme();

  return (
    <div className={styles.campoResultadosGerais}>
      <div>
        <Chart
          id="divGraficoResultadosGeraisPorRedeSocial"
          type="bar"
          data={dadosGraficoResultadosGerais}
          options={basicOptions}
        />
      </div>

      {
        tipoRedeSocial === "Instagram" &&
        <DadosLateraisInstagram
          existeInstagramFeed={existeInstagramFeed}
          existeInstagramReels={existeInstagramReels}
          existeInstagramStories={existeInstagramStories}
          alcance={alcanceIG}
          comentarios={comentariosIG}
          likes={likesIG}
          impressoes={campanha.instagramTotalImpressoes}
          salvos={salvosIG}
          compartilhamentos={compartilhamentosIG}
          visitasPerfil={visitasPerfilIG}
          respostas={respostasIG}
          cliquesLink={cliquesLinkIG}
          cliquesStickers={cliquesStickersIG}
        />
      }
      {
        tipoRedeSocial === "TikTok" &&
        <DadosLateraisTikTok
          viewsTotais={viewsTotaisTikTok}
          tempoReproducao={tempoReproducaoTikTok}
          comentarios={comentariosTikTok}
          likes={likesTikTok}
          compartilhamentos={compartilhamentosTikTok}
          salvos={salvosTikTok}
          espectadores={espectadoresTikTok}
        />
      }
      {
        tipoRedeSocial === "Twitter" &&
        <DadosLateraisTwitter
          impressoes={impressoesTwitter}
          engajamento={engajamentoTwitter}
          comentarios={comentariosTwitter}
          retweets={retweets}
          favoritos={favoritosTwitter}
          respostas={respostasTwitter}
          views={viewsTwitter}
          cliquesLink={cliquesLinkTwitter}
          cliquesHashtag={cliquesHashtagTwitter}
          detailExpands={detailExpandsTwitter}
        />
      }
      {
        tipoRedeSocial === "YouTube" &&
        <DadosLateraisYouTube
          existeYouTubeVideo={existeYouTubeVideo}
          existeYouTubeShorts={existeYouTubeShorts}
          viewsTotais={viewsTotaisYouTube}
          tempoReproducao={tempoReproducaoYouTube}
          comentarios={comentariosYouTube}
          likes={likesYouTube}
          dislikes={dislikesYouTube}
          impressoes={impressoesYouTube}
          espectadores={espectadoresYouTube}
          taxaAprovacao={taxaAprovacaoYouTube}
        />
      }
    </div>
  )
}