import { useEffect, useRef, useState, useContext } from "react";

import styles from "./styles.module.css";

import { Toast } from "primereact/toast";
import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { FuncaoListarCampanha } from "./functions";
import { TabelaListarCampanhas } from "../../../components/Tabelas/TabelaListarCampanhas";
import { tipoOrdenacao } from "../../../@types/ordenacao";

import { DesconectarContext } from "../../../context/DesconectarContext";
import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListarTodasCampanhas = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);
  const [numeroDePagina, setNumeroDePagina] = useState<number>(0);
  const [tamanhoDePagina, setTamanhoDePagina] = useState<number>(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");
  const [carregandoXlS, setCarregandoXLS] = useState<boolean>(false);

  const [nomePesquisa, setNomePesquisa] = useState<string>("");
  const [idMarca, setIdMarca] = useState<string>("");
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [statusCampanha, setStatusCampanha] = useState<number>();

  const [listaDeCampanhas, setListaDeCampanhas] = useState<campanha[]>([]);
  const [totalCampanhas, setTotalCampanhas] = useState<number>(0);
  const [datas, setDatas] = useState<Date | Date[] | undefined>([]);

  const [filtroPorData, setfiltroPorData] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { setDesconectar } = useContext(DesconectarContext);
  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterCampanhas,
    obterCampanhasPorAgencia,
    obterCampanhasPorAgenciaPorNome,
    obterCampanhasPorMarca,
    obterCampanhasPorMarcaPorNome,
    obterCampanhasPorNome,
    obterCampanhasPorPeriodo,
    obterCampanhasPorStatus,
    obterCampanhasParaImprecao,
    obterCampanhasParaGerarXls,
  } = FuncaoListarCampanha({
    dataInicial: datas
      ? Array.isArray(datas)
        ? datas.length !== 0
          ? datas[0].toISOString()
          : ""
        : ""
      : "",
    dataFinal: datas
      ? Array.isArray(datas)
        ? datas[1]
          ? datas[1].toISOString()
          : ""
        : ""
      : "",
    nomePesquisa,
    idAgencia,
    idMarca,
    numeroDePagina: numeroDePagina + 1,
    tamanhoDePagina,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    setCarregando,
    setCarregandoPesquisa,
    setErro,
    setListaDeCampanhas,
    setTamanhoDePagina,
    setTotalCampanhas,
    listaDeCampanhas,
    status: statusCampanha,
    carregando,
    recarregar,
    setRecarregar,
    setTamanhoPagina: setTamanhoDePagina,
    tamanhoPagina: tamanhoDePagina,
    totalCampanhas,
    setCarregandoXLS,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    if (filtroPorPeriodo) {
      setfiltroPorData(!filtroPorData);
    }
  }, [datas]);


  function useEsperarValorFinal<T>(valor: T, delay: number): T {
    const [valorFinal, setValorFinal] = useState(valor);

    useEffect(() => {
      const handler = setTimeout(() => {
        setValorFinal(valor);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [valor, delay]);

    return valorFinal;
  }

  const nomeFinalDigitado = useEsperarValorFinal(nomePesquisa, 500);

  useEffect(() => {
    const pesquisaPorNome = nomePesquisa !== "" && nomePesquisa !== undefined;
    const filtroPorAgencia = idAgencia !== "" && idAgencia !== undefined;
    const filtroPorMarca = idMarca !== "" && idMarca !== undefined;
    const filtroPorStatus =
      statusCampanha !== null && statusCampanha !== undefined;
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    pesquisaPorNome
      ? filtroPorAgencia
        ? obterCampanhasPorAgenciaPorNome()
        : filtroPorMarca
          ? obterCampanhasPorMarcaPorNome()
          : obterCampanhasPorNome()
      : filtroPorAgencia
        ? obterCampanhasPorAgencia()
        : filtroPorMarca
          ? obterCampanhasPorMarca()
          : filtroPorPeriodo
            ? obterCampanhasPorPeriodo()
            : filtroPorStatus
              ? obterCampanhasPorStatus()
              : obterCampanhas();
  }, [
    tamanhoDePagina,
    tipoOrdenacao,
    numeroDePagina,
    recarregar,
    nomeFinalDigitado,
    idAgencia,
    idMarca,
    filtroPorData,
    statusCampanha,
  ]);

  const colunas = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Agência", ordenacao: "agencia" },
    { nome: "Marca", ordenacao: "marca" },
    { nome: "Data Início", ordenacao: "dataInicio" },
    { nome: "Data Fim", ordenacao: "dataFim" },
    { nome: "Data do Relatório", ordenacao: "dataCriacaoRelatorio" },
    { nome: "Taxa de Engajamento", ordenacao: "mediaTaxaEngajamento" },
    { nome: "Custo por Engajamento", ordenacao: "custoEngajamento" },
    { nome: "Status", ordenacao: "status" },
    // { nome: "Ações", ordenacao: "status" },
  ];

  return (
    <>
      <div className={styles.tabelaCampanhas}>
        <TabelaListarCampanhas
          carregandoXlS={carregandoXlS}
          criaPDFListaDeCampanhas={obterCampanhasParaImprecao}
          gerarXLSXListaDeCampanhas={obterCampanhasParaGerarXls}
          tamanhoPagina={tamanhoDePagina}
          setTamanhoPagina={setTamanhoDePagina}
          totalCampanhas={totalCampanhas}
          setStatusCampanha={setStatusCampanha}
          statusCampanha={statusCampanha}
          datas={datas}
          setDatas={setDatas}
          idAgenciaFiltro={idAgencia}
          setIdAgenciaFiltro={setIdAgencia}
          idMarcaFiltro={idMarca}
          setIdMarcaFiltro={setIdMarca}
          carregando={carregando}
          setCarregando={setCarregando}
          setDesconectar={setDesconectar}
          setErro={setErro}
          carregandoPesquisa={carregandoPesquisa}
          myToast={myToast}
          tipoOrdenacao={tipoOrdenacao}
          setTipoOrdenacao={setTipoOrdenacao}
          nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
          setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
          setNomeCampanhaPesquisa={setNomePesquisa}
          setRecarregar={() => setRecarregar(!recarregar)}
          erro={erro}
          tituloTabela={"Campanha"}
          tituloColuna={colunas}
          listaDeCampanhas={listaDeCampanhas}
        // paginacao={
        //   <Paginator
        //     first={paginatorFirst}
        //     rows={tamanhoDePagina}
        //     totalRecords={totalCampanhas}
        //     onPageChange={(e) => {
        //       setNumeroDePagina(e.page);
        //       setPaginatorFirst(e.first);
        //     }}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e: any) => setTamanhoDePagina(e.target.value)}
        //     totalLista={totalCampanhas}
        //     nomeLista={"campanhas"}
        //   />
        // }
        />
      </div>

      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
