import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";

import { Button } from "../../components/Botoes/Button";
import { obterCredenciais } from "../../utils/LocalStorage";

import logoNetcos from "../../assets/img/Netcos.png";
import { BiHomeAlt } from "react-icons/bi";

export const PaginaNaoEncontrada = () => {
  const credenciais = obterCredenciais().credenciais;
  const navigate = useNavigate();

  return (
    <div className={styles.paginaNaoEncontrada}>
      <div className={styles.container}>
        <img
          className={styles.logoNetcos}
          src={logoNetcos}
          alt="Logo da NetCos"
        />

        <div className={styles.containerMensagem}>
          <h1 className={styles.erro}>Erro</h1>
          <h1 className={styles.codigoErro}>404</h1>
          <h1 className={styles.tituloNaoEncontrada}>Página não encontrada.</h1>
        </div>
        <h1 className={styles.mensagem}>
          Infelizmente, a página solicitada não existe ou não está acessível.
          Clique no botão abaixo para voltar à página inicial.
        </h1>
        <Button
          importancia="secundario"
          title="Voltar"
          onClick={() =>
            credenciais.perfil[0] !== "" ? navigate("/home") : navigate("/")
          }
          iconLeft={<BiHomeAlt />}
          style={{
            fontSize: "2rem",
          }}
        />
      </div>
    </div>
  );
};
