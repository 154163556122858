import { useRef, useState } from "react";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";

import 'primeicons/primeicons.css';
import { Avatar } from "primereact/avatar";
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import styles from "./styles.module.css"
import { ModalTeste } from "../../Modais/ModalTeste";
import { Button } from "../Button";
import { FunctionsBotaoUploadImagem } from "./functions";

export interface BotaoUploadImagemProps {
  setImg: React.Dispatch<React.SetStateAction<string>>;
  imagemAnterior?: string;
  nomeParaLabel?: string;
}

export const BotaoUploadImagem = ({ setImg, imagemAnterior, nomeParaLabel }: BotaoUploadImagemProps) => {
  const [imagem, setImagem] = useState<string>(imagemAnterior ? imagemAnterior : "");
  const [cropShow, setCropShow] = useState<boolean>(false);
  const [completedCrop, setCompletedCrop] = useState<Crop>()
  const previewImgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50
  });

  const { enviarImagemRecortada } = FunctionsBotaoUploadImagem({ setImg, setImagem, previewImgRef, completedCrop });

  return (
    <>
      {!cropShow &&
        <div className={styles.container}>
          <Avatar
            className={styles.avatar}
            label={nomeParaLabel && nomeParaLabel.charAt(0)}
            image={imagem}
            title="Editar Perfil"
            shape="circle"
            size="xlarge"
            icon={"pi pi-user"}
            style={{
              color: 'black',
              fontSize: '5rem',
              textTransform: 'uppercase',
              borderRadius: '50%'
            }}
          />
          <label className={styles.labelInput} htmlFor={styles.fileUpload}>
            <i className="pi pi-camera" style={{ 'fontSize': '1.6rem', 'color': 'var(--branco)' }}></i>
            <input
              id={styles.fileUpload}
              type="file"
              accept="image/*"
              onChange={(e) => (converteImgPara64(e, setImagem), converteImgPara64(e, setImg), setCropShow(true))}
            />
          </label>
        </div>
      }
      <ModalTeste
        modalAberto={cropShow}
        setModalAberto={setCropShow}
        titulo={"Recortar Imagem"}
        naoFecharModalAoClicarFora={true}
      >
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Button
            importancia="primario"
            title="Confirmar corte"
            onClick={() => {
              setCropShow(!cropShow);
              enviarImagemRecortada();
            }}
          />
        </div>
        <ReactCrop crop={crop} circularCrop={true} aspect={1} onChange={c => setCrop(c)} onComplete={c => setCompletedCrop(c)}>
          <img ref={previewImgRef} src={imagem} style={{ maxWidth: '50rem', maxHeight: '50rem' }} />
        </ReactCrop>
      </ModalTeste>
    </>
  );
};
