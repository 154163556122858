import { BsQuestionCircle } from 'react-icons/bs';

interface IconeTooltipProps {
  tooltip: string;
}

export const IconeTooltip = ({ tooltip } : IconeTooltipProps) => {
  return (
    <div title={tooltip}>
      <BsQuestionCircle
        style={{
          color: "var(--cinza-600)",
          fontSize: "11px",
          minWidth: "1.4rem",
          cursor: 'help'
        }}
      />
    </div>
  );
};