import { useEffect, useState } from "react";
import { validacaoSenha } from "./functions";
import "./styles.css";

interface criteriosSenhaProps {
    senha: string;
}

export const CriteriosSenha = ({senha}:criteriosSenhaProps) => {

    const [tamanhoSenha, setTamanhoSenha] = useState(false);
    const [caractereEspecial, setCaracterEspecial] = useState(false);
    const [letraMaiuscula, setLetraMaiuscula] = useState(false);
    const [letraMinuscula, setLetraMinuscula] = useState(false);
    const [numeros, setNumeros] = useState(false);

    useEffect(()=>{
        validacaoSenha(senha,setTamanhoSenha,setCaracterEspecial,setLetraMaiuscula,setLetraMinuscula,setNumeros)
    },[senha])


    return (
        <div style={{ alignItems: 'flex-start', paddingTop: '1rem', paddingBottom: '1rem' }}>
            <p className="criterios-senha_erro-senha-titulo">Para sua segurança, a senha precisa ter: </p>
            <p className={caractereEspecial ? "criterios-senha_erro-senha-verificado" : "criterios-senha_erro-senha-nao-verificado"}>- Caracteres especiais;</p>
            <p className={numeros ? "criterios-senha_erro-senha-verificado" : "criterios-senha_erro-senha-nao-verificado"}>- Números;</p>
            <p className={letraMaiuscula ? "criterios-senha_erro-senha-verificado" : "criterios-senha_erro-senha-nao-verificado"}>- Letras maiúsculas;</p>
            <p className={letraMinuscula ? "criterios-senha_erro-senha-verificado" : "criterios-senha_erro-senha-nao-verificado"}>- Letras minúsculas;</p>
            <p className={tamanhoSenha ? "criterios-senha_erro-senha-verificado" : "criterios-senha_erro-senha-nao-verificado"}>- No mínimo 7 caracteres;</p>
        </div>
    )
}