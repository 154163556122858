import React, { useContext } from "react";
import styles from "./styles.module.css";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";

import { Toast } from "primereact/toast";
import { useState } from "react";

import { Input } from "../../Input";
import { FuncoesCaixaFlutuanteAtualizarAgencia } from "./functions";

import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface CaixaFlutuanteAtualizarAgenciaProps {
  caixaAbertaAgencia: boolean;
  setCaixaAbertaAgencia: React.Dispatch<boolean>;
  id: string;
  nomeAgenciaAntiga: string;
  logotipoAntigo: string;
  setRecarregar: () => void;
  myToast: React.MutableRefObject<any>;
}

export const CaixaFlutuanteAtualizarAgencia = ({
  caixaAbertaAgencia,
  setCaixaAbertaAgencia,
  id,
  nomeAgenciaAntiga,
  logotipoAntigo,
  setRecarregar,
  myToast,
}: CaixaFlutuanteAtualizarAgenciaProps) => {
  const [nome, setNome] = useState<string>(nomeAgenciaAntiga);
  const [logotipo, setLogotipo] = useState<string>("");
  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { atualizarAgencia } = FuncoesCaixaFlutuanteAtualizarAgencia({
    nome,
    setNome,
    id,
    logotipo: logotipo ? logotipo : null,
    setLogotipo,
    myToast,
    setCarregando,
    setRecarregar,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <div className={styles.conteudoCompletoCaixa}>
      <div
        className={styles.backgroundCaixaFlutuanteAtualizarAgencia}
        onClick={() => setCaixaAbertaAgencia(false)}
      ></div>
      <div className={styles.conteudoCaixaFlutuanteAtualizarAgencia}>
        <BotaoUploadImagem
          setImg={setLogotipo}
          imagemAnterior={logotipoAntigo}
          nomeParaLabel={nome}
        />

        <div className={styles.containerInputs}>
          <Input
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </div>

        {carregando ? (
          <Carregando />
        ) : (
          <div className={styles.containerBotoesModal}>
            <Button
              importancia="secundario"
              title="Confirmar"
              onClick={atualizarAgencia}
            />
            <Button
              type="button"
              importancia="terciario"
              title="Cancelar"
              onClick={() => setCaixaAbertaAgencia(!caixaAbertaAgencia)}
            />
          </div>
        )}

        <Toast ref={myToast} className="custom-toast" />
      </div>
    </div>
  );
};
