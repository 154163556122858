import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../components/Botoes/Button";
import styles from "./styles.module.css";
import { BarraLateral } from "./BarraLateral";
import { VisaoGeral } from "./VisãoGeral";
import { PorRedeSocial } from "./PorRedeSocial";
import { PorInfluenciador } from "./PorInfluenciador";
import { ComparativoInfluenciadores } from "./ComparativoInfluenciadores";
import { Benchmarking } from "./Benchmarking";
import { InfluenciadoresCampanha } from "./InfluenciadoresCampanha";
import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { useParams } from "react-router-dom";
import { funcoesCampanhaDetalhes } from "./functions";
import { DateFormatter } from "../../../utils/DataFormatter";
import { ModalEditarTarefa } from "../../../components/Modais/ModalEditarTarefa";
import { PublicacoesProps } from "../../../services/publicacao";
import { Carregando } from "../../../components/Carregando";

import { Toast } from "primereact/toast";
import { FiCalendar } from "react-icons/fi";
import { BsGraphUp } from "react-icons/bs";

import { ModalEditarCampanha } from "../../../components/Modais/ModalEditarCampanha";
import { ModalEnviarRelatorio } from "../../../components/Modais/ModalEnviarRelatorio";
import { ModalConfirmacao } from "../../../components/Modais/ModalConfirmacao";

import { funcoesGeracaoDeRelatorio } from "./GeracaoDeRelatorio/functions";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import BarraDeProgresso from "../../../components/BarraDeProgresso/BarraDeProgresso";

export const DetalhesCampanha = () => {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [carregandoGeracaoRelatorio, setCarregandoGeracaoRelatorio] =
    useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [carregandoFechar, setCarregandoFechar] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [progressoUpload, setProgressoUpload] = useState<number>(0);
  const [recarregarPorInfluenciador, setRecarregarPorInfluenciador] =
    useState<boolean>(false);
  const [mostrarModalEditarTarefa, setMostrarModalEditarTarefa] =
    useState<boolean>(false);
  const [mostrarModalEditarCampanha, setMostrarModalEditarCampanha] =
    useState<boolean>(false);
  const [mostrarModalAprovarTarefa, setMostrarModalAprovarTarefa] =
    useState<boolean>(false);
  const [mostrarModalEnviarRelatorio, setMostrarModalEnviarRelatorio] =
    useState<boolean>(false);

  const [indiceBarraLateral, setIndiceBarraLateral] = useState(0);
  const [campanha, setCampanha] = useState<campanha>();
  const [idInfluenciadorSelecionado, setIdInfluenciadorSelecionado] =
    useState<string>("");
  const [nomeInfluenciadorSelecionado, setNomeInfluenciadorSelecionado] =
    useState<string>("");
  const [avatarInfluenciadorSelecionado, setAvatarInfluenciadorSelecionado] =
    useState<string>("");

  const [instagramPresente, setInstagramPresente] = useState<boolean>(false);
  const [tiktokPresente, setTiktokPresente] = useState<boolean>(false);
  const [twitterPresente, setTwitterPresente] = useState<boolean>(false);
  const [youtubePresente, setYoutubePresente] = useState<boolean>(false);

  const [
    modalConfirmacaoFinalizacaoCampanhaAberto,
    setModalConfirmacaoFinalizacaoCampanhaAberto,
  ] = useState<boolean>(false);

  const [publicacaoSelecionada, setPublicacaoSelecionada] =
    useState<PublicacoesProps>({
      status: "Pendente",
      dataFinalizacao: new Date().toISOString(),
      externalId: "",
      tipo: "PublicacaoInstagramFeed",
    });

  const hoje = new Date().toISOString();

  const myToast = useRef<any>(null);

  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterCampanha, fecharCampanha } = funcoesCampanhaDetalhes({
    setErro,
    setCarregando,
    setCampanha,
    idCampanha: idCampanha!,

    setCarregandoFechar,
    myToast,
    recarregar,
    setRecarregar,
    setInstagramPresente,
    setTiktokPresente,
    setTwitterPresente,
    setYoutubePresente,
    setModalConfirmacaoFinalizacaoCampanhaAberto,
    adicionarAListaEsperaRenovacaoToken,
  });

  const { gerarRelatorio } = funcoesGeracaoDeRelatorio({
    setErro,
    setCarregando,
    setCarregandoGeracaoRelatorio,
    setProgressoUpload,
    listaDeTarefas: campanha?.tarefas,
    campanha: campanha!,
    myToast,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterCampanha();
  }, [recarregar, recarregarPorInfluenciador]);

  useEffect(() => {
    setRecarregar(!recarregar);
  }, [indiceBarraLateral]);

  return (
    <>
      <div className={styles.containerPagina}>
        <div className={styles.containerBarraLateral}>
          <BarraLateral
            indice={indiceBarraLateral}
            setIndice={setIndiceBarraLateral}
          />
        </div>
        {carregando || carregandoGeracaoRelatorio ? (
          <>
            <div className={styles.containerExplicacaoGeracaoRelatorio}>
              <Carregando />
              {carregandoGeracaoRelatorio && (
                <>
                  <p>
                    Aguarde, o relatório está sendo gerado e o download começará{" "}
                    <b>automaticamente</b> após o término.
                  </p>
                  <p>
                    Como o relatório envolve as imagens e cálculos da campanha
                    inteira, pode demorar até <b>alguns minutos</b> para ser
                    finalizado, aguarde.
                  </p>
                  <div style={{ width: "50%", marginTop: "2rem" }}>
                    <BarraDeProgresso progresso={progressoUpload} />
                  </div>
                </>
              )}
            </div>
          </>
        ) : erro.ativo ? (
          <div className={styles.containerErro}>
            <h1>{erro.mensagem}</h1>
            <button
              onClick={() => setRecarregar(!recarregar)}
              className={styles.botaoIconeColunaAcoes}
            >
              <i
                className={"pi pi-refresh"}
                style={{ fontSize: "3rem" }}
                title={"Recarregar gráfico"}
              />
            </button>
          </div>
        ) : (
          campanha && (
            <div className={styles.informacoesPagina}>
              <div className={styles.headerCompleto}>
                <div className={styles.headerCampanha}>
                  <div className={styles.campoLogotipo}>
                    {campanha.marcas[0].urlLogotipo ? (
                      <img
                        className={styles.logotipo}
                        src={campanha.marcas[0].urlLogotipo}
                        alt=""
                      />
                    ) : (
                      <p>{campanha.marcas[0].nome?.charAt(0)}</p>
                    )}
                  </div>

                  <div
                    className={
                      campanha.dataCriacaoRelatorio === "0001-01-01T00:00:00"
                        ? styles.campoInformacoesCampanha
                        : styles.campoInformacoesCampanhaRelatorio
                    }
                  >
                    <p className={styles.tituloCampanha}>
                      {campanha.nome}
                      <Button
                        importancia="secundario"
                        iconLeft={
                          <i
                            className="pi pi-pencil"
                            style={{ fontSize: "1.6rem", marginBottom: "0px" }}
                            title="Editar Campanha"
                            onClick={() => {
                              setMostrarModalEditarCampanha(true);
                            }}
                          />
                        }
                      />
                    </p>
                    <div className={styles.campoMarcaPeriodo}>
                      <p className={styles.nomeMarca}>
                        {campanha.marcas[0].nome}
                      </p>
                      <div className={styles.campoDatasCampanha}>
                        <div className={styles.dataInidividual}>
                          <FiCalendar />
                          <span>Período da Campanha:</span>{" "}
                          {DateFormatter(campanha.dataInicio)} -{" "}
                          {DateFormatter(campanha.dataFim)}
                        </div>
                        {campanha.dataCriacaoRelatorio !==
                          "0001-01-01T00:00:00" && (
                          <div className={styles.campoDatasCampanha}>
                            <div className={styles.dataInidividual}>
                              Data do Relatório:{" "}
                              {DateFormatter(campanha.dataCriacaoRelatorio)}
                            </div>
                            <div className={styles.dataInidividual}>
                              Relatório Disponível até{" "}
                              {DateFormatter(
                                new Date(
                                  campanha.dataCriacaoRelatorio
                                ).setMonth(
                                  new Date(
                                    campanha.dataCriacaoRelatorio
                                  ).getMonth() + 6
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.campoBotoes}>
                    {campanha.dataCriacaoRelatorio === "0001-01-01T00:00:00" ? (
                      <div className={styles.campoBotaoRelatorio}>
                        {carregandoFechar ? (
                          <Carregando botao />
                        ) : (
                          <Button
                            importancia="secundario"
                            title={"Finalizar Campanha"}
                            tooltip={
                              campanha.dataFim > hoje
                                ? "Finalizar campanha estará disponível apenas após o fim da campanha"
                                : "Finalizar Campanha"
                            }
                            onClick={() =>
                              setModalConfirmacaoFinalizacaoCampanhaAberto(true)
                            }
                            iconLeft={<BsGraphUp />}
                            disabled={campanha.dataFim > hoje ? true : false}
                          />
                        )}
                      </div>
                    ) : (
                      <div className={styles.botoesCompartilhamento}>
                        <Button
                          importancia="secundario"
                          onClick={() => gerarRelatorio(true)}
                          iconLeft={
                            <i
                              className="pi pi-download"
                              style={{ fontSize: "1.8rem" }}
                              title="Baixar relatório"
                            />
                          }
                        />

                        <Button
                          importancia="secundario"
                          onClick={() => setMostrarModalEnviarRelatorio(true)}
                          iconLeft={
                            <i
                              className="pi pi-send"
                              style={{ fontSize: "1.8rem" }}
                              title="Enviar relatório"
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.containerResultados}>
                {/*------------ Itens da Barra Lateral ------------- */}

                {indiceBarraLateral === 0 && (
                  <VisaoGeral
                    campanha={campanha}
                    recarregar={() => setRecarregar(!recarregar)}
                  />
                )}
                {indiceBarraLateral === 1 && (
                  <PorRedeSocial
                    carregandoCampanha={carregando}
                    campanha={campanha}
                    instagramPresente={instagramPresente}
                    tiktokPresente={tiktokPresente}
                    twitterPresente={twitterPresente}
                    youtubePresente={youtubePresente}
                  />
                )}
                {indiceBarraLateral === 2 && <ComparativoInfluenciadores />}
                {indiceBarraLateral === 3 && <Benchmarking />}
                {indiceBarraLateral === 4 && (
                  <InfluenciadoresCampanha
                    taxaEngajamento={campanha.mediaTaxaEngajamento}
                    setIdInfluenciadorSelecionado={
                      setIdInfluenciadorSelecionado
                    }
                    setIndiceBarraLateral={setIndiceBarraLateral}
                    setNomeInfluenciadorSelecionado={
                      setNomeInfluenciadorSelecionado
                    }
                    setAvatarInfluenciadorSelecionado={
                      setAvatarInfluenciadorSelecionado
                    }
                    idCampanha={idCampanha ? idCampanha : ""}
                  />
                )}

                {/*------------ Outras Renderizações Condicionais ------------- */}

                {indiceBarraLateral === 5 && (
                  <PorInfluenciador
                    publicacaoSelecionada={publicacaoSelecionada}
                    setPublicacaoSelecionada={setPublicacaoSelecionada}
                    idInfluenciadorSelecionado={idInfluenciadorSelecionado}
                    nomeInfluenciadorSelecionado={nomeInfluenciadorSelecionado}
                    avatarInfluenciadorSelecionado={
                      avatarInfluenciadorSelecionado
                    }
                    idCampanha={idCampanha ? idCampanha : ""}
                    mostrarModalEditarTarefa={mostrarModalEditarTarefa}
                    setMostrarModalEditarTarefa={setMostrarModalEditarTarefa}
                    setIndiceBarraLateral={setIndiceBarraLateral}
                    setMostrarModalAprovarTarefa={setMostrarModalAprovarTarefa}
                    dataInicioCampanha={campanha.dataInicio}
                    tarefa={campanha.tarefas.find(
                      (tarefa) =>
                        tarefa.influenciador.idCredenciais ===
                        idInfluenciadorSelecionado
                    )}
                  />
                )}
                {/* 
                {mostrarModalEditarTarefa && (
                  <ModalEditarTarefa
                    publicacaoSelecionada={publicacaoSelecionada}
                    modalAberto={mostrarModalEditarTarefa}
                    setModalAberto={setMostrarModalEditarTarefa}
                    setRecarregar={setRecarregarPorInfluenciador}
                  />
                )} */}

                {mostrarModalEditarCampanha && (
                  <ModalEditarCampanha
                    nomeAtualCampanha={campanha.nome}
                    dataInicioAtual={campanha.dataInicio}
                    dataFimAtual={campanha.dataFim}
                    idCampanha={idCampanha ? idCampanha : ""}
                    modalAberto={mostrarModalEditarCampanha}
                    recarregar={recarregarPorInfluenciador}
                    setModalAberto={setMostrarModalEditarCampanha}
                    setRecarregar={setRecarregarPorInfluenciador}
                  />
                )}

                {/* {mostrarModalAprovarTarefa && (
                  <ModalEditarTarefa
                    publicacaoSelecionada={publicacaoSelecionada}
                    modalAberto={mostrarModalAprovarTarefa}
                    setModalAberto={setMostrarModalAprovarTarefa}
                    aprovar={true}
                    setRecarregar={setRecarregarPorInfluenciador}
                  />
                )} */}
                {mostrarModalEnviarRelatorio && (
                  <ModalEnviarRelatorio
                    modalAberto={mostrarModalAprovarTarefa}
                    setModalAberto={setMostrarModalAprovarTarefa}
                    gerarRelatorio={gerarRelatorio}
                  />
                )}
              </div>
            </div>
          )
        )}
        {mostrarModalEnviarRelatorio && (
          <ModalEnviarRelatorio
            modalAberto={mostrarModalEnviarRelatorio}
            setModalAberto={setMostrarModalEnviarRelatorio}
            gerarRelatorio={gerarRelatorio}
          />
        )}

        {modalConfirmacaoFinalizacaoCampanhaAberto && (
          <ModalConfirmacao
            nomeCampanha={campanha?.nome!}
            carregando={carregandoFechar}
            onClickConfirm={fecharCampanha}
            modalAberto={modalConfirmacaoFinalizacaoCampanhaAberto}
            setModalAberto={setModalConfirmacaoFinalizacaoCampanhaAberto}
          />
        )}
        <Toast ref={myToast} className="custom-toast" />
      </div>
    </>
  );
};
