import { ButtonHTMLAttributes, useState } from "react";
import styles from "./styles.module.css";

import { CaixaFlutuanteFiltros } from "../../CaixasFlutuantes/CaixaFlutuanteFiltros";

export interface BotaoFiltroProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

export const BotaoFiltro = ({ children }: BotaoFiltroProps) => {
  const [caixaFlutaunteAberta, setCaixaFlutaunteAberta] =
    useState<boolean>(false);
  return (
    <div>
      <button
        className={styles.botaoFiltro}
        type="submit"
        onClick={(e) => {
          setCaixaFlutaunteAberta(!caixaFlutaunteAberta);
        }}
      >
        {
          <i
            className="pi pi-filter"
            style={{ fontSize: "1.5rem" }}
            title="Filtrar"
          />
        }
      </button>
      {caixaFlutaunteAberta && (
        <CaixaFlutuanteFiltros setCaixaAberta={setCaixaFlutaunteAberta}>
          {children}
        </CaixaFlutuanteFiltros>
      )}
    </div>
  );
};
