import { useContext, useState } from "react";

import styles from "./styles.module.css";
import { FuncoesCadastroCampanha } from "./functions";

import { Input } from "../../Input";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { cadastroTarefasProps } from "../../../services/campanha";
import { ModalTeste } from "../ModalTeste";
import { LimparStates } from "../../../utils/LimparStates";
import { SelectAgencia } from "../../../components/Select/SelectAgencia";
import { SelectMarca } from "../../../components/Select/SelectMarca";
import { TabelaListarTarefas } from "../../Tabelas/TabelaListarTarefas";

import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface ModalCadastroDeCampanhaProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: () => void;
}

export const ModalCadastroDeCampanha = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
}: ModalCadastroDeCampanhaProps) => {
  const [numeroPagina] = useState<number>(1);
  const tamanhoPagina = 10;

  //Campanha
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [idMarcas, setIdMarcas] = useState<string[]>([]);
  const [nome, setNome] = useState<string>("");
  const [datas, setDatas] = useState<Date | Date[] | undefined>([]);

  const [tarefas, setTarefas] = useState<cadastroTarefasProps[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } = useContext(RenovarTokenContext);

  const { vinculaMarcaEAgenciaEConfimaCampanha } = FuncoesCadastroCampanha({
    setRecarregar,
    idAgencia,
    setIdAgencia,
    idMarcas,
    setIdMarcas,
    nome,
    setNome,
    dataInicio: datas
      ? Array.isArray(datas)
        ? datas.length !== 0
          ? datas[0].toISOString()
          : ""
        : ""
      : "",
    dataFim: datas
      ? Array.isArray(datas)
        ? datas[1]
          ? datas[1].toISOString()
          : ""
        : ""
      : "",
    setDatas,
    tarefas,
    setTarefas,
    numeroPagina,
    tamanhoPagina,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    setModalAberto,
  });

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpa",
  });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo="Cadastro de Campanha"
    >
      <div className={styles.conteudoModalCadastroCampanha}>
        <header>
          <div className={styles.containerInputs}>
            <Input
              type="text"
              value={nome}
              placeholder={"Nome da Campanha"}
              required
              onChange={(e) => setNome(e.target.value)}
            />

            <Calendar
              className={styles.calendario}
              dateFormat="dd/mm/yy"
              locale="pt-br"
              placeholder="Período da Campanha"
              selectionMode="range"
              value={datas}
              required
              onChange={(e) => setDatas(e.value)}
              showIcon
              style={{
                outline: "none",
                boxShadow: "none",
              }}
            />

            <SelectAgencia setIdAgencia={setIdAgencia} idAgencia={idAgencia} />

            <SelectMarca setIdMarcas={setIdMarcas} idMarcas={idMarcas} />
          </div>
        </header>

        <body>
          <TabelaListarTarefas tarefas={tarefas} setTarefas={setTarefas} />
        </body>

        <footer>
          <div className={styles.containerBotoesModal}>
            <Button
              onClick={() => {
                LimparStates([setIdAgencia, setNome, setTarefas]);
                setIdMarcas([]);
                setDatas([]);
              }}
              importancia="terciario"
              className="botao-limpar"
            >
              Limpar
            </Button>
            {carregando ? (
              <Carregando botao />
            ) : (
              <Button
                onClick={vinculaMarcaEAgenciaEConfimaCampanha}
                importancia="primario"
                title="Cadastrar"
              />
            )}
          </div>
        </footer>
      </div>
    </ModalTeste>
  );
};
