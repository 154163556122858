import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";

import { DesconectarContext } from "../DesconectarContext";
import { RenovarTokenContext } from "../RenovarTokenContext";
import { getMarcaProps, getMarcaTodas } from "../../services/marca";

import { obterTokens } from "../../utils/LocalStorage";

import { tipoOrdenacao } from "../../@types/ordenacao";
import { ErroType } from "../../@types/erro";
import { useLocation } from "react-router-dom";

interface Props {
  children?: ReactNode;
}

interface MarcaContextProps {
  listaDeMarcas: getMarcaProps[];
  recarregarListaDeMarcas: () => void;
  carregandoListaDeMarcas: boolean;
  erroListaDeMarcas: ErroType;
  mudarTipoOrdenacao: (tipoDeOrdenacaoListaMarcas: tipoOrdenacao) => void;
  tipoDeOrdenacao: tipoOrdenacao;
  mudarNomeDaPropriedadeDeOrdenacao: (propriedadeDeOrdenacao: string) => void;
  nomeDaPropriedadeDeOrdenacao: string;
}

export const MarcaContext = createContext<MarcaContextProps>({
  listaDeMarcas: [],
  recarregarListaDeMarcas: () => {},
  carregandoListaDeMarcas: false,
  erroListaDeMarcas: { ativo: false, mensagem: "" },
  mudarTipoOrdenacao: () => {},
  tipoDeOrdenacao: "Ascending",
  mudarNomeDaPropriedadeDeOrdenacao: () => {},
  nomeDaPropriedadeDeOrdenacao: "",
});

export const MarcaProvider = ({ children }: Props) => {
  const [listaDeMarcas, setListaDeMarcas] = useState<getMarcaProps[]>([]);
  const [tipoDeOrdenacao, setTipoDeOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomeDaPropriedadeDeOrdenacao, setNomeDaPropriedadeDeOrdenacao] =
    useState<string>("nome");
  const [carregandoListaDeMarcas, setCarregandoListaDeMarcas] =
    useState<boolean>(false);
  const [recarregarRequisicao, setRecarregarRequisicao] =
    useState<boolean>(false);
  const [erroListaDeMarcas, setErroListaDeMarcas] = useState<ErroType>({
    ativo: false,
    mensagem: "",
  });

  let location = useLocation();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  function recarregarListaDeMarcas() {
    setRecarregarRequisicao((state) => !state);
  }

  function mudarTipoOrdenacao(tipoOrdenacao: tipoOrdenacao) {
    setTipoDeOrdenacao(tipoOrdenacao);
  }

  function mudarNomeDaPropriedadeDeOrdenacao(
    nomeDaPropriedadeDeOrdenacao: string
  ) {
    setNomeDaPropriedadeDeOrdenacao(nomeDaPropriedadeDeOrdenacao);
  }

  function obterListaDeMarcas() {
    setCarregandoListaDeMarcas(true);
    setErroListaDeMarcas({
      ativo: false,
      mensagem: "",
    });

    const { token } = obterTokens();

    getMarcaTodas(
      token,
      1,
      99999,
      tipoDeOrdenacao,
      nomeDaPropriedadeDeOrdenacao
    )
      .then((resposta) => {
        setListaDeMarcas(resposta.data.marcas);
        setCarregandoListaDeMarcas(false);
      })
      .catch((erro) => {
        if (erro.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getMarcaTodas(
              token,
              1,
              99999,
              tipoDeOrdenacao,
              nomeDaPropriedadeDeOrdenacao
            )
              .then((resposta) => {
                setListaDeMarcas(resposta.data.marcas);
              })
              .catch(() => {
                setErroListaDeMarcas({
                  ativo: true,
                  mensagem:
                    "Ocorreu um erro listando as marcas, tente novamente.",
                });
              });
          }, setCarregandoListaDeMarcas);
        } else {
          setCarregandoListaDeMarcas(false);
          setErroListaDeMarcas({
            ativo: true,
            mensagem: "Ocorreu um erro listando as marcas, tente novamente.",
          });
        }
      });
  }

  useEffect(() => {
    const { token, tokenRenovacao } = obterTokens();
    if (
      token &&
      tokenRenovacao &&
      !carregandoListaDeMarcas &&
      location.pathname !== "/"
    ) {
      obterListaDeMarcas();
    }
  }, [recarregarRequisicao, nomeDaPropriedadeDeOrdenacao, tipoDeOrdenacao]);

  return (
    <MarcaContext.Provider
      value={{
        carregandoListaDeMarcas,
        erroListaDeMarcas,
        listaDeMarcas,
        mudarNomeDaPropriedadeDeOrdenacao,
        nomeDaPropriedadeDeOrdenacao,
        mudarTipoOrdenacao,
        tipoDeOrdenacao,
        recarregarListaDeMarcas,
      }}
    >
      {children}
    </MarcaContext.Provider>
  );
};
