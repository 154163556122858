import { BiSticker } from 'react-icons/bi';
import { CgProfile } from "react-icons/cg";
import { format } from '../../../../utils/MascaraNumero/format';
import styles from './styles.module.css';

interface DadosLateraisInstagramProps {
  alcance: number;
  comentarios: number;
  likes: number;
  impressoes: number;
  salvos: number;
  compartilhamentos: number;
  visitasPerfil: number;
  respostas: number;
  cliquesLink: number;
  cliquesStickers: number;
  existeInstagramFeed: boolean;
  existeInstagramReels: boolean;
  existeInstagramStories: boolean;
}

export const DadosLateraisInstagram = ({
  alcance,
  comentarios,
  likes,
  impressoes,
  salvos,
  compartilhamentos,
  visitasPerfil,
  respostas,
  cliquesLink,
  cliquesStickers,
  existeInstagramFeed,
  existeInstagramReels,
  existeInstagramStories
}: DadosLateraisInstagramProps) => {
  
  
  return (
    <div className={styles.dadosResultados}>

      {(existeInstagramFeed || existeInstagramReels || existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-users"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Alcance Médio</h1>
            <p>{alcance ? format("#.###.##0,#0", alcance).replace(",00", "") : 0}</p>
          </div>
        </div>
      }

      {(existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-link"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Cliques no Link</h1>
            <p>{format("#.###.##0,#0", cliquesLink).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <BiSticker
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          />
          <div>
            <h1>Cliques nos Stickers</h1>
            <p>{format("#.###.##0,#0", cliquesStickers).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramFeed || existeInstagramReels) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-comments"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Comentários</h1>
            <p>{format("#.###.##0,#0", comentarios).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramFeed || existeInstagramReels || existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-share-alt"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Compartilhamentos</h1>
            <p>{format("#.###.##0,#0", compartilhamentos).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramFeed || existeInstagramReels || existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-user-plus"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Impressões</h1>
            <p>{format("#.###.##0,#0", impressoes).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramFeed || existeInstagramReels) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-thumbs-up"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Likes</h1>
            <p>{format("#.###.##0,#0", likes).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramFeed || existeInstagramReels) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-bookmark"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Salvos</h1>
            <p>{format("#.###.##0,#0", salvos).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-reply"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Respostas</h1>
            <p>{format("#.###.##0,#0", respostas).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeInstagramStories) &&
        <div className={styles.dadoResultado}>
          <CgProfile
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          />
          <div>
            <h1>Visitas ao Perfil</h1>
            <p>{format("#.###.##0,#0", visitasPerfil).replace(",00", "")}</p>
          </div>
        </div>
      }

    </div>
  )

}