import { TabelaBenchmarking } from '../../../../components/Tabelas/TabelaBenchmarking';
import { CampoInformacoes } from '../CampoInformacoes';
import styles from './styles.module.css';

export const Benchmarking = () => {
  return (
    <div className={styles.pagina}>
      <CampoInformacoes titulo='Macroinfluenciadores'>
        <div className={styles.campoBenchmarking}>
          <TabelaBenchmarking tipoInfluenciador='MacroInfluenciador' />
        </div>
      </CampoInformacoes>

      <CampoInformacoes titulo='Microinfluenciadores'>
        <div className={styles.campoBenchmarking}>
          <TabelaBenchmarking tipoInfluenciador='MicroInfluenciador' />
        </div>
      </CampoInformacoes>
    </div>
  )
}