import styles from "./styles.module.css";

import { FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

interface CampoRedesSociaisProps {
  instagram: number;
  instagramExiste: boolean;
  tiktok: number;
  tiktokExiste: boolean;
  youtube: number;
  youtubeExiste: boolean;
  twitter: number;
  twitterExiste: boolean;
}

export const CampoRedesSociais = ({
  instagram,
  tiktok,
  twitter,
  youtube,
  instagramExiste,
  tiktokExiste,
  twitterExiste,
  youtubeExiste,
}: CampoRedesSociaisProps) => {
  return (
    <div className={styles.containerRedesSociais}>
      <div
        className={`${styles.campoRedeSocial} ${
          !instagramExiste && styles.escondido
        }`}
      >
        <div
          className={`${styles.campoIconeRedeSocial} ${styles.campoIconeInstagram}`}
        >
          <AiFillInstagram
            className={`${styles.iconeRedeSocial} ${styles.iconeInstagram}`}
          />
        </div>
        <div className={styles.campoInformacoesRedeSocial}>
          <p className={`${styles.tituloRedeSocial} ${styles.tituloInstagram}`}>
            Instagram
          </p>
          <p className={styles.postagensRedeSocial}>{instagram} postagens</p>
        </div>
      </div>

      <div
        className={`${styles.campoRedeSocial} ${
          !tiktokExiste && styles.escondido
        }`}
      >
        <div
          className={`${styles.campoIconeRedeSocial} ${styles.campoIconeTikTok}`}
        >
          <FaTiktok
            className={`${styles.iconeRedeSocial} ${styles.iconeTikTok} ${styles.iconeTikTokVermelho}`}
          />
          <FaTiktok
            className={`${styles.iconeRedeSocial} ${styles.iconeTikTok} ${styles.iconeTikTokAzul}`}
          />
          <FaTiktok
            className={`${styles.iconeRedeSocial} ${styles.iconeTikTok} ${styles.iconeTikTokPreto}`}
          />
        </div>
        <div className={styles.campoInformacoesRedeSocial}>
          <p className={`${styles.tituloRedeSocial} ${styles.tituloTikTok}`}>
            TikTok
          </p>
          <p className={styles.postagensRedeSocial}>{tiktok} postagens</p>
        </div>
      </div>

      <div
        className={`${styles.campoRedeSocial} ${
          !twitterExiste && styles.escondido
        }`}
      >
        <div
          className={`${styles.campoIconeRedeSocial} ${styles.campoIconeTwitter}`}
        >
          <FaTwitter
            className={`${styles.iconeRedeSocial} ${styles.iconeTwitter}`}
          />
        </div>
        <div className={styles.campoInformacoesRedeSocial}>
          <p className={`${styles.tituloRedeSocial} ${styles.tituloTwitter}`}>
            Twitter
          </p>
          <p className={styles.postagensRedeSocial}>{twitter} postagens</p>
        </div>
      </div>

      <div
        className={`${styles.campoRedeSocial} ${
          !youtubeExiste && styles.escondido
        }`}
      >
        <div
          className={`${styles.campoIconeRedeSocial} ${styles.campoIconeYouTube}`}
        >
          <FaYoutube
            className={`${styles.iconeRedeSocial} ${styles.iconeYoutube}`}
          />
        </div>
        <div className={styles.campoInformacoesRedeSocial}>
          <p className={`${styles.tituloRedeSocial} ${styles.tituloYouTube}`}>
            YouTube
          </p>
          <p className={styles.postagensRedeSocial}>{youtube} postagens</p>
        </div>
      </div>
    </div>
  );
};
