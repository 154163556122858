import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesModalTarefas } from "../../../../components/Tabelas/TabelaListarTarefasIncluidas/functions";
import { tarefaProps } from "../../../../services/tarefas";
import { CampoInformacoes } from "../CampoInformacoes";
import { cadastroTarefasProps, campanha } from "../../../../services/campanha";
import { ModalAcrescentarTarefaNaCampanha } from "../../../../components/Modais/ModalAcrescentarTarefaNaCampanha";
import { BotaoBusca } from "../../../../components/Botoes/BotaoBusca";
import { BotaoImprimir } from "../../../../components/Botoes/BotaoImprimir";

import { tipoOrdenacao } from "../../../../@types/ordenacao";
import { ErroType } from "../../../../@types/erro";
import { format } from "../../../../utils/MascaraNumero/format";

import { RenovarTokenContext } from "../../../../context/RenovarTokenContext";
import { TabelaListarTarefasIncluidas } from "../../../../components/Tabelas/TabelaListarTarefasIncluidas/v2";

export interface InfluenciadoresCampanhaProps {
  idCampanha: string;
  setIndiceBarraLateral: React.Dispatch<React.SetStateAction<number>>;
  setIdInfluenciadorSelecionado: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciadorSelecionado: React.Dispatch<React.SetStateAction<string>>;
  setAvatarInfluenciadorSelecionado: React.Dispatch<
    React.SetStateAction<string>
  >;
  taxaEngajamento: number;
}

export const InfluenciadoresCampanha = ({
  idCampanha,
  setIndiceBarraLateral,
  setIdInfluenciadorSelecionado,
  setNomeInfluenciadorSelecionado,
  setAvatarInfluenciadorSelecionado,
  taxaEngajamento,
}: InfluenciadoresCampanhaProps) => {
  // ------------ Consts de Ordenação -------------

  const [tamanhoDePagina, setTamanhoDePagina] = useState<number>(99999);
  const [numeroDePagina, setNumeroDePagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");

  // ------------ Consts Gerais -------------

  const [tituloCaixa, setTituloCaixa] = useState<any>();
  const [carregando, setCarregando] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [idTarefa, setIdTarefa] = useState<string>("");
  const [listaDeMetricas, setListaDeMetricas] = useState<number>(0);

  // ------------ Lista de Tarefas -------------

  const [listaDeTarefas, setListaDeTarefas] = useState<tarefaProps[]>([]);
  const [totalDeTarefas, setTotalDeTarefas] = useState<number>(0);
  const [tarefa, setTarefa] = useState<cadastroTarefasProps[]>([]);

  // ------------ Pesquisa por Nome -------------
  const [nome, setNome] = useState<string>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterTarefasCampanha,
    obterTarefasCampanhaPorNome,
    criaPDFListaInfluenciadoresCampanha,
  } = FuncoesModalTarefas({
    idCampanha,
    listaDeTarefas,
    setListaDeTarefas,
    setTotalDeTarefas,
    setCarregando,
    setErro,
    nomePropriedadeOrdenacao,
    numeroPagina: numeroDePagina + 1,
    tamanhoPagina: tamanhoDePagina,
    tipoOrdenacao,
    nome,
    idTarefa,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    nome ? obterTarefasCampanhaPorNome() : obterTarefasCampanha();
  }, [
    idCampanha,
    recarregar,
    numeroDePagina,
    tamanhoDePagina,
    nomePropriedadeOrdenacao,
    tipoOrdenacao,
    nome,
  ]);

  return (
    <div className={styles.pagina}>
      <CampoInformacoes
        titulo={
          <div className={styles.cabecalho}>
            {tituloCaixa ? tituloCaixa : "Influenciadores da Campanha"}
            <div className={styles.campoIndividualBigNumber}>
              <p className={styles.tituloBigNumber} title="Taxa de engajamento">
                Taxa de Engajamento da Campanha:
              </p>
              <p className={styles.conteudoBigNumber}>
                {format("#.###.##0,#0%", taxaEngajamento)}
              </p>
            </div>
            <div className={styles.containerBotoes}>
              <BotaoBusca
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaInfluenciadoresCampanha} />
            </div>
          </div>
        }
      >
        <div className={styles.containerModalEBigNumber}>
          <ModalAcrescentarTarefaNaCampanha
            tarefa={[]}
            setTarefa={setTarefa}
            importancia="secundario"
            recarregar={() => setRecarregar(!recarregar)}
          />
        </div>

        <TabelaListarTarefasIncluidas
          idCampanha={idCampanha}
          listaDeTarefas={listaDeTarefas}
          nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
          setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
          tipoOrdenacao={tipoOrdenacao}
          setTipoOrdenacao={setTipoOrdenacao}
          taxaEngajamentoCampanha={taxaEngajamento}
          setRecarregar={setRecarregar}
          setAvatarInfluenciadorSelecionado={setAvatarInfluenciadorSelecionado}
          setIdInfluenciadorSelecionado={setIdInfluenciadorSelecionado}
          setNomeInfluenciadorSelecionado={setNomeInfluenciadorSelecionado}
          setTituloCaixa={setTituloCaixa}
          setIndiceBarraLateral={setIndiceBarraLateral}
          carregando={carregando}
        />
      </CampoInformacoes>
    </div>
  );
};
