import { TabView, TabPanel } from 'primereact/tabview';
import { CampoBigNumbers } from '../CampoBigNumbers';
import { CampoInformacoes } from '../CampoInformacoes';
import styles from './styles.module.css';

export const ComparativoInfluenciadores = () => {
  return (
    <div id={styles.containerRedesSociais}>
      <TabView>
        <TabPanel header="Comparativo">
          {/* <CampoBigNumbers /> */}

          <CampoInformacoes titulo="Comparativo dos Influenciadores">
          </CampoInformacoes>

          <CampoInformacoes
            titulo="Listagem de conteúdos entregues"
            subtitulo='Análise geral da campanha, incluindo todas as redes sociais'
          >
          </CampoInformacoes>

        </TabPanel>
        <TabPanel header="Instagram">
          {/* <CampoBigNumbers /> */}

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores - Feed"
          >
          </CampoInformacoes>

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores - Stories"
          >
          </CampoInformacoes>

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores - Reels"
          >
          </CampoInformacoes>

        </TabPanel>

        <TabPanel header="TikTok">
          {/* <CampoBigNumbers /> */}

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores"
          >
          </CampoInformacoes>
        </TabPanel>

        <TabPanel header="Twitter">
          {/* <CampoBigNumbers /> */}

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores"
          >
          </CampoInformacoes>
        </TabPanel>

        <TabPanel header="YouTube">
          {/* <CampoBigNumbers /> */}

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores - Videos"
          >
          </CampoInformacoes>

          <CampoInformacoes
            titulo="Comparativo dos Influenciadores - Shorts"
          >
          </CampoInformacoes>
        </TabPanel>
      </TabView>
    </div >
  )
}