export function mascaraUSD(valor: string) {
    //Primeiro, conferindo se o valor � positivo ou negativo
    let numeroNegativo =false;
    if (valor?.startsWith("-"))
        numeroNegativo = true;

    //Remover o lixo da string e deixar s� o n�mero
    var removerLixo = valor?.replace(/[\D]+/g, '');

    //Corrigir o valor primeiro para formato real
    var valorCorrigido = removerLixo + '';
    valorCorrigido = valorCorrigido?.replace(/([0-9]{2})$/g, ",$1");

    if (valorCorrigido == 'NaN')
        valorCorrigido = "0.00";

    //Se o valor for zerado (sem esse tratamento, vai s� "0")
    if (valorCorrigido == "0")
        valorCorrigido = "0,00"

    //Se o valor for menor que 1
    if (valorCorrigido.startsWith(","))
        valorCorrigido = "0" + valorCorrigido;


    // if (valorCorrigido.length > 8)
    //     valorCorrigido = valorCorrigido?.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2$3,");

    // if (valorCorrigido.length > 6)
    //     valorCorrigido = valorCorrigido?.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    //Transformar o valor mascarado em formato Real para formato USD

    valorCorrigido = valorCorrigido?.replace(/\./g, ' ')?.replace(/,/g, '.');

    //Finalmente, se o valor for negativo no inicio antes dos replaces
    //agora ele � adicionado no in�cio da string
    if (numeroNegativo)
        valorCorrigido = "-" + valorCorrigido;

    return valorCorrigido;
}