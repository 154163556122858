//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { Influenciador } from "../../../../services/publicacao";
import { tarefaProps } from "../../../../services/tarefas";
import { converteSegundoParaTempoCompleto } from "../../../../utils/ConversorTempo";
import { format } from "../../../../utils/MascaraNumero/format";
import { somaMetrica } from "../../../../utils/somaMetrica";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioYoutubeVideo } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import mascaraImagem from "../../../../assets/img/MascaraImagensRelatorio.png";

export async function gerarPaginaYoutubeVideo(
  tarefa: tarefaProps,
  listaDePdfs: any,
  taxaEngajamentoCampanha: number,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        ViewsTotais: {
          type: "text",
          position: {
            x: 259.56,
            y: 47.89,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TempoReproducao: {
          type: "text",
          position: {
            x: 259.77,
            y: 61.59,
          },
          width: 50,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Comentarios: {
          type: "text",
          position: {
            x: 259.45,
            y: 76.62,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Likes: {
          type: "text",
          position: {
            x: 259.67,
            y: 90.06,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Deslikes: {
          type: "text",
          position: {
            x: 259.87,
            y: 103.5,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Impressoes: {
          type: "text",
          position: {
            x: 259.93,
            y: 117.73,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Espectadores: {
          type: "text",
          position: {
            x: 259.67,
            y: 131.7,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TaxaEngajamento: {
          type: "text",
          position: {
            x: 282.43,
            y: 146.76,
          },
          width: 20,
          height: 7,
          alignment: "left",
          fontSize: 13,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        ImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        MascaraImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        NomeInflu: {
          type: "text",
          position: {
            x: 63.97,
            y: 68.26,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TipoInflu: {
          type: "text",
          position: {
            x: 64.18,
            y: 76.94,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoInflu: {
          type: "text",
          position: {
            x: 64.39,
            y: 100.7,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoCampanha: {
          type: "text",
          position: {
            x: 94.5,
            y: 178.17,
          },
          width: 25.73,
          height: 6.99,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
    ],
    basePdf: base64RelatorioYoutubeVideo,
  };

  var imageInfluencer = !tarefa.influenciador.urlAvatar
    ? ""
    : await converterImagemParaBase64(
      tarefa.influenciador.urlAvatar,
      setErro,
      setCarregando
    );

  if (imageInfluencer !== "AxiosError") {
    let totalViewsTotaisYTVideo: number = 0;
    let totalTempoReproducaoYTVideo: number = 0;
    let totalComentariosYTVideo: number = 0;
    let totalLikesYTVideo: number = 0;
    let totalDislikesYTVideo: number = 0;
    let totalImpressoesYTVideo: number = 0;
    let totalEspectadoresYTVideo: number = 0;

    tarefa.publicacoes.map((publicacao) => {
      if (publicacao.tipo === "PublicacaoYouTube") {
        if (publicacao.status === "Aprovado") {
          totalViewsTotaisYTVideo = somaMetrica(
            totalViewsTotaisYTVideo,
            publicacao.viewsTotal
          );

          totalTempoReproducaoYTVideo = somaMetrica(
            totalTempoReproducaoYTVideo,
            publicacao.tempoReproducao
          );

          totalComentariosYTVideo = somaMetrica(
            totalComentariosYTVideo,
            publicacao.comentarios
          );

          totalLikesYTVideo = somaMetrica(totalLikesYTVideo, publicacao.likes);

          totalDislikesYTVideo = somaMetrica(
            totalDislikesYTVideo,
            publicacao.dislikes
          );

          totalImpressoesYTVideo = somaMetrica(
            totalImpressoesYTVideo,
            publicacao.impressoes
          );

          totalEspectadoresYTVideo = somaMetrica(
            totalEspectadoresYTVideo,
            publicacao.espectadores
          );
        }
      }
    });

    const inputs = [
      {
        ViewsTotais: totalViewsTotaisYTVideo == 0 ? '-' : format("#.###.##0,#0", totalViewsTotaisYTVideo).replace(
          ",00",
          ""
        ),
        TempoReproducao: totalTempoReproducaoYTVideo == 0 ? '-' : converteSegundoParaTempoCompleto(
          totalTempoReproducaoYTVideo
        ),
        Comentarios: totalComentariosYTVideo == 0 ? '-' : format("#.###.##0,#0", totalComentariosYTVideo).replace(
          ",00",
          ""
        ),
        Likes: totalLikesYTVideo == 0 ? '-' : format("#.###.##0,#0", totalLikesYTVideo).replace(",00", ""),
        Deslikes: totalDislikesYTVideo == 0 ? '-' : format("#.###.##0,#0", totalDislikesYTVideo).replace(
          ",00",
          ""
        ),
        Impressoes: totalImpressoesYTVideo == 0 ? '-' : format("#.###.##0,#0", totalImpressoesYTVideo).replace(
          ",00",
          ""
        ),
        Espectadores: totalEspectadoresYTVideo == 0 ? '-' : format("#.###.##0,#0", totalEspectadoresYTVideo).replace(
          ",00",
          ""
        ),
        TaxaEngajamento: tarefa.youtubeVideoTaxaEngajamento == 0 ? '-' : format("#.###.##0,#0%", tarefa.youtubeVideoTaxaEngajamento),
        ImagemInflu: !imageInfluencer ? "" : imageInfluencer,
        MascaraImagemInflu: mascaraImagem,
        NomeInflu: tarefa.influenciador.nome.toString(),
        TipoInflu: tarefa.influenciador.perfil.toString(),
        EngajamentoInflu:
          tarefa.influenciador.engajamentoHistorico <= 0 ? "-" :
            "Taxa de engajamento histórico: " +
            format(
              "#.###.##0,#0%",
              tarefa.influenciador.engajamentoHistorico
            ).toString(),
        EngajamentoCampanha: taxaEngajamentoCampanha == 0 ? '-' : format(
          "#.###.##0,#0%",
          taxaEngajamentoCampanha
        ).toString(),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob5 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob5);
    });
  } else {
    return "AxiosError";
  }
}
