import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FunctionsPorInfluenciadores } from "./functions";

import { PublicacoesProps } from "../../../../services/publicacao";
import { Publicacoes } from "../../../../components/Publicacoes";
import { VisaoGeralInfluenciador } from "../../../../components/VisaoGeralInfluenciador";
import { CredenciaisContext } from "../../../../context/CredenciaisContext";
import { tarefaProps } from "../../../../services/tarefas";
import { Repercussao } from "../../../../components/Repercussao";
import { ConteudoMetricas } from "../../../../components/ConteudoMetricas";
import { ModalAtualizarInfluenciador } from "../../../../components/Modais/ModalAtualizarInfluenciador";

import { TabView, TabPanel } from "primereact/tabview";

import { RenovarTokenContext } from "../../../../context/RenovarTokenContext";

export interface InfluenciadoresCampanhaProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  nomeInfluenciadorSelecionado: string;
  avatarInfluenciadorSelecionado: string;
  setMostrarModalEditarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  setIndiceBarraLateral: React.Dispatch<React.SetStateAction<number>>;
  publicacaoSelecionada: PublicacoesProps;
  setPublicacaoSelecionada: React.Dispatch<
    React.SetStateAction<PublicacoesProps>
  >;
  setMostrarModalAprovarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  dataInicioCampanha: string | undefined;
  tarefa: tarefaProps | undefined;
  mostrarModalEditarTarefa?: boolean;
}
export const PorInfluenciador = ({
  idCampanha,
  idInfluenciadorSelecionado,
  nomeInfluenciadorSelecionado,
  avatarInfluenciadorSelecionado,
  setIndiceBarraLateral,
  setMostrarModalEditarTarefa,
  publicacaoSelecionada,
  setPublicacaoSelecionada,
  setMostrarModalAprovarTarefa,
  dataInicioCampanha,
  mostrarModalEditarTarefa,
}: InfluenciadoresCampanhaProps) => {
  const [tarefa, setTarefa] = useState<tarefaProps>();
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];

  const [tabViewIndiceAtivo, setTabViewIndiceAtivo] = useState<number>(0);
  const [tabViewConteudosEMetricasIndice, setTabViewConteudosEMetricasIndice] =
    useState<number>(0);
  const [sessaoDestino, setSessaoDestino] = useState<string>("");
  const [modalEditarInfluenciador, setModalEditarInfluenciado] =
    useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [editado, setEditado] = useState<boolean>(false);
  const [urlAvatar, setUrlAvatar] = useState<string>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterTarefa, obterInfluenciadorPorId } = FunctionsPorInfluenciadores({
    idCampanha,
    idInfluenciadorSelecionado,
    setTarefa,
    setUrlAvatar,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterTarefa();
  }, []);

  useEffect(() => {
    if (editado) {
      obterInfluenciadorPorId();
    }
  }, [recarregar, editado]);

  return (
    <>
      <div id={styles.containerDetalhesInfluenciador}>
        <div className={styles.campoNomeAvatar}>
          <i
            onClick={() => {
              setIndiceBarraLateral(4);
            }}
            style={{ fontSize: "2rem", cursor: "pointer" }}
            className="pi pi-arrow-circle-left"
          />
          <div
            onClick={() =>
              (perfil === "UsuarioNetcos" || perfil === "Administrador") &&
              setModalEditarInfluenciado(true)
            }
            className={styles.campoAvatar}
          >
            {avatarInfluenciadorSelecionado || urlAvatar ? (
              <img
                alt=""
                className={styles.avatar}
                src={editado ? urlAvatar : avatarInfluenciadorSelecionado}
              />
            ) : (
              <p>Avatar</p>
            )}
          </div>
          {nomeInfluenciadorSelecionado}
        </div>
        <TabView
          activeIndex={tabViewIndiceAtivo}
          onTabChange={(e) => setTabViewIndiceAtivo(e.index)}
        >
          <TabPanel header="Visão Geral">
            <VisaoGeralInfluenciador
              idCampanha={idCampanha!}
              idInfluenciador={idInfluenciadorSelecionado}
              taxaDeEngajamentoIgFeed={tarefa?.instagramFeedTaxaEngajamento!}
              taxaDeEngajamentoIgReels={tarefa?.instagramReelsTaxaEngajamento!}
              taxaDeEngajamentoIgStories={
                tarefa?.instagramStoriesTaxaEngajamento!
              }
              taxaDeEngajamentoTikTok={tarefa?.tikTokTaxaEngajamento!}
              taxaDeEngajamentoTwitter={tarefa?.twitterTaxaEngajamento!}
              taxaDeEngajamentoYoutubeShort={
                tarefa?.youtubeShortsTaxaEngajamento!
              }
              taxaDeEngajamentoYoutubeVideo={
                tarefa?.youtubeVideoTaxaEngajamento!
              }
              setTabViewIndiceAtivo={setTabViewIndiceAtivo}
              setTabViewConteudosEMetricasIndice={
                setTabViewConteudosEMetricasIndice
              }
              setSessaoDestino={setSessaoDestino}
            />
          </TabPanel>
          <TabPanel header="Conteúdos e Métricas">
            <ConteudoMetricas
              idCampanha={idCampanha}
              nomeInfluenciadorSelecionado={nomeInfluenciadorSelecionado}
              idInfluenciadorSelecionado={idInfluenciadorSelecionado}
              tabViewConteudosEMetricasIndice={tabViewConteudosEMetricasIndice}
              setTabViewConteudosEMetricasIndice={
                setTabViewConteudosEMetricasIndice
              }
              sessaoDestino={sessaoDestino}
              setSessaoDestino={setSessaoDestino}
            />
          </TabPanel>
          <TabPanel header="Repercussão">
            <Repercussao
              idCampanha={idCampanha}
              idInfluenciadorSelecionado={idInfluenciadorSelecionado}
              tarefa={tarefa}
              publicacaoSelecionada={publicacaoSelecionada}
            />
          </TabPanel>
          {(perfil === "UsuarioNetcos" || perfil === "Administrador") && (
            <TabPanel header="Publicações">
              {tarefa && (
                <Publicacoes
                  idTarefa={tarefa!.externalId}
                  dataInicioCampanha={dataInicioCampanha}
                  setMostrarModalAprovarTarefa={setMostrarModalAprovarTarefa}
                  setMostrarModalEditarTarefa={setMostrarModalEditarTarefa}
                  mostrarModalEditarTarefa={mostrarModalEditarTarefa}
                  setPublicacaoSelecionada={setPublicacaoSelecionada}
                  idCampanha={idCampanha}
                  idInfluenciadorSelecionado={idInfluenciadorSelecionado}
                  publicacaoSelecionada={publicacaoSelecionada}
                />
              )}
            </TabPanel>
          )}
        </TabView>
      </div>
      {modalEditarInfluenciador && tarefa !== undefined ? (
        <ModalAtualizarInfluenciador
          modalAberto={modalEditarInfluenciador}
          setModalAberto={setModalEditarInfluenciado}
          setRecarregar={() => {
            setRecarregar((state) => !state);
            setEditado(true);
          }}
          emailPrevio={tarefa.influenciador.email}
          idCredenciais={tarefa.influenciador.idCredenciais}
          imagemAvatarPrevia={tarefa.influenciador.urlAvatar}
          instagramPrevio={tarefa.influenciador.linkInstagram}
          nomePrevio={tarefa.influenciador.nome}
          perfilPrevio={tarefa.influenciador.perfil}
          statusPrevio={tarefa.influenciador.status}
          telefonePrevio={tarefa.influenciador.telefone}
          tiktokPrevio={tarefa.influenciador.linkTiktok}
          twitterPrevio={tarefa.influenciador.linkTwitter}
          youTubePrevio={tarefa.influenciador.linkYoutube}
        />
      ) : (
        <></>
      )}
    </>
  );
};
