import React, { createContext, ReactNode, useState } from "react";
import { credenciais } from "../../services/usuario";
import {
  limpaLocalStorage,
  obterCredenciais,
  salvaCredenciais,
} from "../../utils/LocalStorage";

interface Props {
  children?: ReactNode;
}

interface CredenciaisContextProps {
  atualizarCredenciais: (credenciais: credenciais) => void;
  removerCredenciais: () => void;
  credenciais: credenciais;
}

export const CredenciaisContext = createContext<CredenciaisContextProps>({
  atualizarCredenciais: () => {},
  credenciais: {
    celular: "",
    fotoUsuario: null,
    id: "",
    nome: "",
    perfil: [""],
    urlAvatar: null,
    userName: "",
    termosDeUsoAceitos: false,
  },
  removerCredenciais: () => {},
});

export const CredenciaisProvider = ({ children }: Props) => {
  const [credenciais, setCredenciais] = useState<credenciais>(
    obterCredenciais().credenciais
  );

  const atualizarCredenciais = (novasCredenciais: credenciais) => {
    setCredenciais(novasCredenciais);
    salvaCredenciais(novasCredenciais);
  };

  const removerCredenciais = () => {
    setCredenciais({
      celular: "",
      fotoUsuario: null,
      id: "",
      nome: "",
      perfil: [""],
      urlAvatar: null,
      userName: "",
      termosDeUsoAceitos: false,
    });
    // limpaLocalStorage();
    localStorage.removeItem('token');          
    localStorage.removeItem('tokenRenovacao'); 
    localStorage.removeItem('accessToken'); 
    localStorage.removeItem('credenciais');   
  };

  return (
    <CredenciaisContext.Provider
      value={{ atualizarCredenciais, removerCredenciais, credenciais }}
    >
      {children}
    </CredenciaisContext.Provider>
  );
};
