import { useState } from "react";

import { CaixaFlutuanteAtualizarMarca } from "../../CaixasFlutuantes/CaixaFlutuanteAtualizarMarca";

import styles from "./styles.module.css";

interface BotaoAtualizarMarcaListaProps {
    idMarca: string;
    nomeMarca: string;
    logotipo: string;
    setRecarregar: () => void;
    status: boolean;
}

export const BotaoAtualizarMarcaLista = ({
    idMarca,
    nomeMarca,
    logotipo,
    setRecarregar,
    status
}: BotaoAtualizarMarcaListaProps) => {
    const [caixaAbertaMarca, setCaixaAbertaMarca] = useState<boolean>(false);

    return (
        <button
            className={
                status ?
                    styles.botaoIconeColunaAcoes :
                    styles.botaoIconeColunaAcoesDisabled
            }
            disabled={
                status ? false : true
            }>
            <i
                className="pi pi-pencil"
                style={{ fontSize: "1.4rem", paddingRight: "1rem" }}
                onClick={() => status && setCaixaAbertaMarca(!caixaAbertaMarca)}
                title="Editar Marca"
            />
            {caixaAbertaMarca && <CaixaFlutuanteAtualizarMarca
                id={idMarca}
                nomeMarcaAntiga={nomeMarca}
                logotipoAntigo={logotipo}
                setRecarregar={setRecarregar}
                caixaAbertaMarca={caixaAbertaMarca}
                setCaixaAbertaMarca={setCaixaAbertaMarca}
            />}
        </button>
    );
};
