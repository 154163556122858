import { AxiosResponse } from "axios";
import { tipoOrdenacao } from "../@types/ordenacao";
import { api } from "./api";
import { cadastroTarefasProps } from "./campanha";
import { getInfluenciadorProps } from "./influenciador";
import { Prints, PublicacoesProps } from "./publicacao";

export interface tarefaProps {
  id: number;
  externalId: string;
  influenciador: getInfluenciadorProps;
  publicacoes: PublicacoesProps[];
  valorContrato: number;
  perfilInfluenciadorNoFechamentoDoContrato: string;
  numeroImpressoes: number;
  custoPorMilImpressoes: number;
  engajamentoTotalDaTarefa: number;
  taxaEngajamento: number;
  custoPorEngajamento: number;
  alcanceTotalDaTarefa: number;
  printsDeSentimento: Prints[];
  youtubeVideoTaxaEngajamento: number;
  youtubeShortsTaxaEngajamento: number;
  youtubeTotalTaxaEngajamento: number;
  youtubeTotalEngajamento: number;
  tikTokTaxaEngajamento: number;
  tikTokEngajamento: number;
  twitterTaxaEngajamento: number;
  twitterEngajamento: number;
  instagramStoriesTaxaEngajamento: number;
  instagramReelsTaxaEngajamento: number;
  instagramFeedTaxaEngajamento: number;
  instagramTotalTaxaEngajamento: number;
  instagramTotalEngajamento: number;
}

export interface postTarefaProps {
  idCampanha: string;
  tarefa: cadastroTarefasProps;
}

export interface tarefas {
  código: number;
  totalRegistros: number;
  tarefas: tarefaProps[];
}

export const getTarefasPorInfluenciador = (
  token: string,
  IdCredenciais: string
) => {
  const url = `/Tarefa/Tarefas/Abertas/PorInfluenciador?IdCredenciais=${IdCredenciais}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTarefasPorCampanha = (
  token: string,
  id: string,
  numeroPagina: number = 1,
  tamanhoPagina: number = 1000,
  tipoOrdenacao: tipoOrdenacao = "Descending",
  nomePropriedadeOrdenacao: string = ""
): Promise<AxiosResponse<tarefas, any>> => {
  const url = `/Campanha/Tarefas?Id=${id}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putTarefa = (
  token: string,
  idCampanha: string,
  idTarefa: string,
  printsDeSentimento: Prints[],
  valorContrato?: number
) => {
  const url = `/Tarefa`;

  const data = {
    idCampanha,
    tarefa: {
      idTarefa,
      valorContrato,
      printsDeSentimento,
    },
  };

  return api.put(url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const postTarefa = (
  token: string,
  idCampanha: string,
  tarefa: cadastroTarefasProps[]
) => {
  const url = `/Tarefa`;

  const data = {
    idCampanha,
    tarefa,
  };

  return api.post(url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTarefaPorNome = (
  token: string,
  id: string,
  numeroPagina: number = 1,
  tamanhoPagina: number = 5,
  tipoOrdenacao: tipoOrdenacao = "Descending",
  nomePropriedadeOrdenacao: string = "",
  nome: string
): Promise<AxiosResponse<tarefas, any>> => {
  const url = `/Campanha/Tarefas/Nome?Id=${id}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&NomeInfluenciador=${nome}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

interface deleteTarefaProps {
  idCampanha: string;
  idTarefa: string;
}

export const deleteTarefa = (
  token: string,
  idCampanha: string,
  idTarefa: string
): Promise<AxiosResponse<deleteTarefaProps, any>> => {
  const url = "/Tarefa";

  const data = {
    data: {
      idCampanha,
      idTarefa,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return api.delete(url, data);
};

export interface getMetricasPorInfluenciadorProps {
  taxaEngajamentoTotal: number;
  taxaEngajamentoPorCampanha: [
    {
      nomeCampanha: string;
      taxaEngajamento: number;
    }
  ];
}

export const getMetricasPorInfluenciador = (
  token: string,
  idInfluenciador: string
): Promise<AxiosResponse<getMetricasPorInfluenciadorProps, any>> => {
  const url = `/Tarefa/Metricas/PorInfluenciador?IdCredenciais=${idInfluenciador}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
