import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import MascaraGrafico from "../../../utils/MascaraRotulosGraficos";

import { somaMetrica } from "../../../utils/somaMetrica";

interface datasetsProps {
  type: string;
  label?: string;
  borderColor?: string;
  borderWidth?: number;
  fill?: boolean;
  tension?: number;
  data: number[];
  backgroundColor?: CanvasGradient;
}

export interface basicDataProps {
  labels: string[];
  datasets: datasetsProps[];
}

interface FuncoesGraficoResultadosGeraisPorRedeSocialProps {
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setDesconectar: React.Dispatch<React.SetStateAction<boolean>>;
  tipoRedeSocial: redesSociaisProps;
  setDadosGraficoResultadosGerais: React.Dispatch<
    React.SetStateAction<basicDataProps>
  >;

  setExisteInstagramFeed: React.Dispatch<React.SetStateAction<boolean>>;
  setExisteInstagramReels: React.Dispatch<React.SetStateAction<boolean>>;
  setExisteInstagramStories: React.Dispatch<React.SetStateAction<boolean>>;
  setAlcanceIG: React.Dispatch<React.SetStateAction<number>>;
  setComentariosIG: React.Dispatch<React.SetStateAction<number>>;
  setLikesIG: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesIG: React.Dispatch<React.SetStateAction<number>>;
  setSalvosIG: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosIG: React.Dispatch<React.SetStateAction<number>>;
  setVisitasPerfilIG: React.Dispatch<React.SetStateAction<number>>;
  setRespostasIG: React.Dispatch<React.SetStateAction<number>>;
  setCliquesLinkIG: React.Dispatch<React.SetStateAction<number>>;
  setCliquesStickersIG: React.Dispatch<React.SetStateAction<number>>;

  setViewsTotaisTikTok: React.Dispatch<React.SetStateAction<number>>;
  setTempoReproducaoTikTok: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTikTok: React.Dispatch<React.SetStateAction<number>>;
  setLikesTikTok: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosTikTok: React.Dispatch<React.SetStateAction<number>>;
  setSalvosTikTok: React.Dispatch<React.SetStateAction<number>>;
  setEspectadoresTikTok: React.Dispatch<React.SetStateAction<number>>;

  setEngajamentoTwitter: React.Dispatch<React.SetStateAction<number>>;
  setFavoritosTwitter: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTwitter: React.Dispatch<React.SetStateAction<number>>;
  setRetweets: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesTwitter: React.Dispatch<React.SetStateAction<number>>;
  setRespostasTwitter: React.Dispatch<React.SetStateAction<number>>;
  setViewsTwitter: React.Dispatch<React.SetStateAction<number>>;
  setCliquesLinkTwitter: React.Dispatch<React.SetStateAction<number>>;
  setCliquesHashtagTwitter: React.Dispatch<React.SetStateAction<number>>;
  setDetailExpandsTwitter: React.Dispatch<React.SetStateAction<number>>;

  setExisteYouTubeVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setExisteYouTubeShorts: React.Dispatch<React.SetStateAction<boolean>>;
  setViewsTotaisYouTube: React.Dispatch<React.SetStateAction<number>>;
  setTempoReproducaoYouTube: React.Dispatch<React.SetStateAction<number>>;
  setComentariosYouTube: React.Dispatch<React.SetStateAction<number>>;
  setLikesYouTube: React.Dispatch<React.SetStateAction<number>>;
  setDislikesYouTube: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesYouTube: React.Dispatch<React.SetStateAction<number>>;
  setEspectadoresYouTube: React.Dispatch<React.SetStateAction<number>>;
  setTaxaAprovacaoYouTube: React.Dispatch<React.SetStateAction<number>>;
}

export type redesSociaisProps = "Instagram" | "TikTok" | "Twitter" | "YouTube";

export const FuncoesGraficoResultadosGeraisPorRedeSocial = ({
  tipoRedeSocial,
  setDadosGraficoResultadosGerais,
  setExisteInstagramFeed,
  setExisteInstagramReels,
  setExisteInstagramStories,
  setAlcanceIG,
  setComentariosIG,
  setLikesIG,
  setImpressoesIG,
  setSalvosIG,
  setCompartilhamentosIG,
  setVisitasPerfilIG,
  setRespostasIG,
  setCliquesLinkIG,
  setCliquesStickersIG,
  setViewsTotaisTikTok,
  setTempoReproducaoTikTok,
  setComentariosTikTok,
  setLikesTikTok,
  setCompartilhamentosTikTok,
  setSalvosTikTok,
  setEspectadoresTikTok,
  setEngajamentoTwitter,
  setFavoritosTwitter,
  setComentariosTwitter,
  setRetweets,
  setImpressoesTwitter,
  setRespostasTwitter,
  setViewsTwitter,
  setCliquesLinkTwitter,
  setCliquesHashtagTwitter,
  setDetailExpandsTwitter,
  setExisteYouTubeShorts,
  setExisteYouTubeVideo,
  setViewsTotaisYouTube,
  setTempoReproducaoYouTube,
  setComentariosYouTube,
  setLikesYouTube,
  setDislikesYouTube,
  setImpressoesYouTube,
  setEspectadoresYouTube,
  setTaxaAprovacaoYouTube,
}: FuncoesGraficoResultadosGeraisPorRedeSocialProps) => {
  function filtraDadosPorRedeSocial(
    campanha: campanha,
    tipoRedeSocial: redesSociaisProps
  ) {
    // IG
    let quantidadePublicacoesIG: number = 0;

    let totalImpressoesIGFeed: number = 0;
    let totalComentariosIGFeed: number = 0;
    let totalAlcanceIGFeed: number = 0;
    let totalLikesIGFeed: number = 0;
    let totalSalvosIGFeed: number = 0;
    let totalVisitasPerfilIGFeed: number = 0;
    let totalCompartilhamentoIGFeed: number = 0;

    let totalComentariosIGReels: number = 0;
    let totalLikesIGReels: number = 0;
    let totalAlcanceIGReels: number = 0;
    let totalCompartilhamentosIGReels: number = 0;
    let totalSalvosIGReels: number = 0;

    let totalAlcanceIGStories: number = 0;
    let totalImpressoesIGStories: number = 0;
    let totalCompartilhamentosIGStories: number = 0;
    let totalCliquesLinkIGStories: number = 0;
    let totalCliquesStickersIGStories: number = 0;
    let totalVisitasPerfilIGStories: number = 0;
    let totalRespostasIGStories: number = 0;
    let totalLikesIGStories: number = 0;

    // Twitter
    let quantidadePublicacoesTwitter: number = 0;

    let totalImpressoesTwitter: number = 0;
    let totalEngajamentoTwitter: number = 0;
    let totalComentariosTwitter: number = 0;
    let totalRetweetsTwitter: number = 0;
    let totalFavoritosTwitter: number = 0;
    let totalRespostasTwitter: number = 0;
    let totalViewsTwitter: number = 0;
    let totalCliquesLinkTwitter: number = 0;
    let totalCliquesHashtagsTwitter: number = 0;
    let totalDetailExpandsTwitter: number = 0;

    // TikTok
    let quantidadePublicacoesTikTok: number = 0;

    let totalViewsTotaisTikTok: number = 0;
    let totalTempoReproducaoTikTok: number = 0;
    let totalComentariosTikTok: number = 0;
    let totalLikesTikTok: number = 0;
    let totalCompartilhamentosTikTok: number = 0;
    let totalSalvosTikTok: number = 0;
    let totalEspectadoresTikTok: number = 0;

    let mediaTempoReproducaoTikTok: number =
      totalTempoReproducaoTikTok / quantidadePublicacoesTikTok;

    // YouTube
    let quantidadePublicacoesYouTube: number = 0;
    let quantidadePublicacoesYouTubeVideo: number = 0;
    let quantidadePublicacoesYouTubeShorts: number = 0;

    let totalViewsTotaisYTVideo: number = 0;
    let totalTempoReproducaoYTVideo: number = 0;
    let totalComentariosYTVideo: number = 0;
    let totalLikesYTVideo: number = 0;
    let totalDislikesYTVideo: number = 0;
    let totalImpressoesYTVideo: number = 0;
    let totalEspectadoresYTVideo: number = 0;

    let totalImpressoesYTShorts: number = 0;
    let totalViewsTotaisYTShorts: number = 0;
    let totalComentariosYTShorts: number = 0;
    let totalLikesYTShorts: number = 0;
    let totalDislikesYTShorts: number = 0;
    let totalTaxaAprovacaoYTShorts: number = 0;
    let totalEspectadoresYTShorts: number = 0;

    let divGraficoResultadosGeraisPorRedeSocial = document.getElementById(
      "divGraficoResultadosGeraisPorRedeSocial"
    );
    let graficoResultadosGeraisPorRedeSocial =
      divGraficoResultadosGeraisPorRedeSocial?.firstElementChild;
    graficoResultadosGeraisPorRedeSocial?.setAttribute(
      "id",
      "graficoResultadosGeraisPorRedeSocial"
    );

    let graficoResultadosGeraisPorRedeSocialId = <HTMLCanvasElement>(
      document.getElementById("graficoResultadosGeraisPorRedeSocial")
    );
    let graficoResultadosGeraisPorRedeSocialContexto =
      graficoResultadosGeraisPorRedeSocialId?.getContext("2d");

    let gradienteFeed =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteFeed?.addColorStop(0.5, "#8331B4");
    gradienteFeed?.addColorStop(1, "#D67CFA");

    let gradienteReels =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteReels?.addColorStop(0.5, "#B6152A");
    gradienteReels?.addColorStop(1, "#FFDEE3");

    let gradienteStories =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteStories?.addColorStop(0.5, "#FA7E1E");
    gradienteStories?.addColorStop(1, "#FFE3CD");

    let gradienteTikTok =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteTikTok?.addColorStop(0.2, "#69C9D0");
    gradienteTikTok?.addColorStop(1, "#DFF5F7");

    let gradienteTwitter =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteTwitter?.addColorStop(0.5, "#50ABF1");
    gradienteTwitter?.addColorStop(1, "#00EEFF");

    let gradienteYouTube =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteYouTube?.addColorStop(0.5, "#F44336");
    gradienteYouTube?.addColorStop(1, "#FF7879");

    let gradienteYouTubeShorts =
      graficoResultadosGeraisPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteYouTubeShorts?.addColorStop(0.2, "#282828");
    gradienteYouTubeShorts?.addColorStop(1, "#787878");

    campanha.tarefas.map((tarefa) => {
      const instagramFeedExiste = tarefa.publicacoes.find(
        (publicacao) =>
          publicacao.tipo === "PublicacaoInstagramFeed"
      );

      const instagramReelsExiste = tarefa.publicacoes.find(
        (publicacao) =>
          publicacao.tipo === "PublicacaoInstagramReels"
      );

      const instagramStoriesExiste = tarefa.publicacoes.find(
        (publicacao) =>
          publicacao.tipo === "PublicacaoInstagramStories"
      );
      const youtubeVideoExiste = tarefa.publicacoes.find(
        (publicacao) =>
          publicacao.tipo === "PublicacaoYouTube"
      );
      const youtubeShortsExiste = tarefa.publicacoes.find(
        (publicacao) =>
          publicacao.tipo === "PublicacaoYouTubeShorts"
      );

      if (instagramFeedExiste) { setExisteInstagramFeed(true) }
      if (instagramReelsExiste) { setExisteInstagramReels(true) }
      if (instagramStoriesExiste) { setExisteInstagramStories(true) }
      if (youtubeVideoExiste) { setExisteYouTubeVideo(true) }
      if (youtubeShortsExiste) { setExisteYouTubeShorts(true) }

      tarefa.publicacoes.map((publicacao) => {
        if (publicacao.status === "Aprovado") {
          switch (publicacao.tipo) {
            case "PublicacaoInstagramFeed":
              quantidadePublicacoesIG = quantidadePublicacoesIG + 1;

              totalImpressoesIGFeed = somaMetrica(
                totalImpressoesIGFeed,
                publicacao.impressoes
              );

              totalAlcanceIGFeed = somaMetrica(
                totalAlcanceIGFeed,
                publicacao.alcance
              );

              totalComentariosIGFeed = somaMetrica(
                totalComentariosIGFeed,
                publicacao.comentarios
              );

              totalLikesIGFeed = somaMetrica(
                totalLikesIGFeed,
                publicacao.likes
              );

              totalSalvosIGFeed = somaMetrica(
                totalSalvosIGFeed,
                publicacao.salvos
              );

              totalVisitasPerfilIGFeed = somaMetrica(
                totalVisitasPerfilIGFeed,
                publicacao.visitasPerfil
              );

              totalVisitasPerfilIGFeed = somaMetrica(
                totalVisitasPerfilIGFeed,
                publicacao.visitasAoPerfil
              );

              totalCompartilhamentoIGFeed = somaMetrica(
                totalVisitasPerfilIGFeed,
                publicacao.compartilhamentos
              );

              break;

            case "PublicacaoInstagramReels":
              quantidadePublicacoesIG = quantidadePublicacoesIG + 1;

              totalComentariosIGReels = somaMetrica(
                totalComentariosIGReels,
                publicacao.comentarios
              );

              totalLikesIGReels = somaMetrica(
                totalLikesIGReels,
                publicacao.likes
              );

              totalAlcanceIGReels = somaMetrica(
                totalAlcanceIGReels,
                publicacao.alcance
              );

              totalCompartilhamentosIGReels = somaMetrica(
                totalCompartilhamentosIGReels,
                publicacao.compartilhamentos
              );

              totalSalvosIGReels = somaMetrica(
                totalSalvosIGReels,
                publicacao.salvos
              );

              break;

            case "PublicacaoInstagramStories":
              quantidadePublicacoesIG = quantidadePublicacoesIG + 1;

              totalAlcanceIGStories = somaMetrica(
                totalAlcanceIGStories,
                publicacao.alcance
              );

              totalImpressoesIGStories = somaMetrica(
                totalImpressoesIGStories,
                publicacao.impressoes
              );

              totalCompartilhamentosIGStories = somaMetrica(
                totalCompartilhamentosIGStories,
                publicacao.compartilhamentos
              );

              totalCliquesLinkIGStories = somaMetrica(
                totalCliquesLinkIGStories,
                publicacao.cliquesLink
              );

              totalCliquesStickersIGStories = somaMetrica(
                totalCliquesStickersIGStories,
                publicacao.cliquesStickers
              );

              totalVisitasPerfilIGStories = somaMetrica(
                totalVisitasPerfilIGStories,
                publicacao.visitasPerfil
              );

              totalVisitasPerfilIGStories = somaMetrica(
                totalVisitasPerfilIGStories,
                publicacao.visitasAoPerfil
              );

              totalRespostasIGStories = somaMetrica(
                totalRespostasIGStories,
                publicacao.respostas
              );

              totalLikesIGStories = somaMetrica(
                totalLikesIGStories,
                publicacao.likes
              );

              break;

            case "PublicacaoTikTok":
              quantidadePublicacoesTikTok = quantidadePublicacoesTikTok + 1;

              totalViewsTotaisTikTok = somaMetrica(
                totalViewsTotaisTikTok,
                publicacao.views
              );

              totalTempoReproducaoTikTok = somaMetrica(
                totalTempoReproducaoTikTok,
                publicacao.tempoReproducao
              );

              totalComentariosTikTok = somaMetrica(
                totalComentariosTikTok,
                publicacao.comentarios
              );

              totalLikesTikTok = somaMetrica(
                totalLikesTikTok,
                publicacao.likes
              );

              totalCompartilhamentosTikTok = somaMetrica(
                totalCompartilhamentosTikTok,
                publicacao.compartilhamentos
              );

              totalSalvosTikTok = somaMetrica(
                totalSalvosTikTok,
                publicacao.salvos
              );

              totalEspectadoresTikTok = somaMetrica(
                totalEspectadoresTikTok,
                publicacao.espectadores
              );

              break;

            case "PublicacaoTwitter":
              quantidadePublicacoesTwitter = quantidadePublicacoesTwitter + 1;

              totalImpressoesTwitter = somaMetrica(
                totalImpressoesTwitter,
                publicacao.impressoes
              );

              totalEngajamentoTwitter = somaMetrica(
                totalEngajamentoTwitter,
                publicacao.engajamento
              );

              totalComentariosTwitter = somaMetrica(
                totalComentariosTwitter,
                publicacao.comentarios
              );

              totalRetweetsTwitter = somaMetrica(
                totalRetweetsTwitter,
                publicacao.retweets
              );

              totalFavoritosTwitter = somaMetrica(
                totalFavoritosTwitter,
                publicacao.favoritos
              );

              totalRespostasTwitter = somaMetrica(
                totalRespostasTwitter,
                publicacao.respostas
              );

              totalViewsTwitter = somaMetrica(
                totalViewsTwitter,
                publicacao.views
              );

              totalCliquesLinkTwitter = somaMetrica(
                totalCliquesLinkTwitter,
                publicacao.cliquesLink
              );

              totalCliquesHashtagsTwitter = somaMetrica(
                totalCliquesHashtagsTwitter,
                publicacao.cliquesHashtag
              );

              totalDetailExpandsTwitter = somaMetrica(
                totalDetailExpandsTwitter,
                publicacao.detailExpands
              );

              break;

            case "PublicacaoYouTube":
              quantidadePublicacoesYouTube = quantidadePublicacoesYouTube + 1;

              quantidadePublicacoesYouTubeVideo =
                quantidadePublicacoesYouTubeVideo + 1;

              totalViewsTotaisYTVideo = somaMetrica(
                totalViewsTotaisYTVideo,
                publicacao.viewsTotal
              );

              totalTempoReproducaoYTVideo = somaMetrica(
                totalTempoReproducaoYTVideo,
                publicacao.tempoReproducao
              );

              totalComentariosYTVideo = somaMetrica(
                totalComentariosYTVideo,
                publicacao.comentarios
              );

              totalLikesYTVideo = somaMetrica(
                totalLikesYTVideo,
                publicacao.likes
              );

              totalDislikesYTVideo = somaMetrica(
                totalDislikesYTVideo,
                publicacao.dislikes
              );

              totalImpressoesYTVideo = somaMetrica(
                totalImpressoesYTVideo,
                publicacao.impressoes
              );

              totalEspectadoresYTVideo = somaMetrica(
                totalEspectadoresYTVideo,
                publicacao.espectadores
              );

              break;

            case "PublicacaoYouTubeShorts":
              quantidadePublicacoesYouTube = quantidadePublicacoesYouTube + 1;
              quantidadePublicacoesYouTubeShorts =
                quantidadePublicacoesYouTubeShorts + 1;

              totalImpressoesYTShorts = somaMetrica(
                totalImpressoesYTShorts,
                publicacao.impressoes
              );

              totalViewsTotaisYTShorts = somaMetrica(
                totalViewsTotaisYTShorts,
                publicacao.viewsTotais
              );

              totalComentariosYTShorts = somaMetrica(
                totalComentariosYTShorts,
                publicacao.comentarios
              );

              totalLikesYTShorts = somaMetrica(
                totalLikesYTShorts,
                publicacao.likes
              );

              totalDislikesYTShorts = somaMetrica(
                totalDislikesYTShorts,
                publicacao.dislikes
              );

              totalTaxaAprovacaoYTShorts = somaMetrica(
                totalTaxaAprovacaoYTShorts,
                publicacao.taxaAprovacao
              );

              totalEspectadoresYTShorts = somaMetrica(
                totalEspectadoresYTShorts,
                publicacao.espectadores
              );

              break;
          }
        }
      });
    });

    interface dadosGraficoResultadosGeraisInstagramProps {
      feedExiste?: boolean;
      reelsExiste?: boolean;
      storiesExiste?: boolean;
    }

    function dadosGraficoResultadosGeraisInstagram({
      feedExiste,
      reelsExiste,
      storiesExiste,
    }: dadosGraficoResultadosGeraisInstagramProps) {
      const datasets: any[] = [];

      feedExiste &&
        datasets.push({
          type: "bar",
          label: "Feed",
          backgroundColor: gradienteFeed,
          borderColor: "#8331B4",
          data: [
            totalAlcanceIGFeed,
            totalLikesIGFeed +
            totalComentariosIGFeed +
            totalCompartilhamentoIGFeed +
            totalSalvosIGFeed,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        });

      reelsExiste &&
        datasets.push({
          type: "bar",
          label: "Reels",
          backgroundColor: gradienteReels,
          borderColor: "#B51328",
          data: [
            totalAlcanceIGReels,
            totalLikesIGReels +
            totalComentariosIGReels +
            totalCompartilhamentosIGReels +
            totalSalvosIGReels,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        });

      storiesExiste &&
        datasets.push({
          type: "bar",
          label: "Stories",
          backgroundColor: gradienteStories,
          borderColor: "#fa7e1e",
          data: [
            totalAlcanceIGStories,
            totalCompartilhamentosIGStories +
            totalRespostasIGStories +
            totalCliquesLinkIGStories +
            totalCliquesStickersIGStories +
            totalLikesIGStories
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        });
      return {
        labels: ["Alcance", "Engajamento"],
        datasets,
      };
    }

    let dadosGraficoResultadosGeraisTikTok = {
      labels: [
        "Espectadores",
        "Views Totais",
        "Likes",
        "Comentários",
        "Compartilhamentos",
        "Salvos",
      ],
      datasets: [
        {
          type: "bar",
          backgroundColor: gradienteTikTok,
          borderColor: "#69c9d0",
          data: [
            totalEspectadoresTikTok,
            totalViewsTotaisTikTok,
            totalLikesTikTok,
            totalComentariosTikTok,
            totalCompartilhamentosTikTok,
            totalSalvosTikTok,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        },
      ],
    };

    let dadosGraficoResultadosGeraisTwitter = {
      labels: [
        "Impressões",
        "Engajamento",
        "Favoritos",
        "Comentários",
        "Retweets",
      ],
      datasets: [
        {
          type: "bar",
          backgroundColor: gradienteTwitter,
          borderColor: "#50ABF1",
          data: [
            totalImpressoesTwitter,
            totalEngajamentoTwitter,
            totalFavoritosTwitter,
            totalComentariosTwitter,
            totalRetweetsTwitter,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        },
      ],
    };

    interface dadosGraficoResultadosGeraisYouTubeProps {
      videoExite?: boolean;
      shortsExite?: boolean;
    }

    function dadosGraficoResultadosGeraisYouTube({
      videoExite,
      shortsExite,
    }: dadosGraficoResultadosGeraisYouTubeProps) {
      const datasets: any[] = [];
      videoExite &&
        datasets.push({
          type: "bar",
          label: "Video",
          backgroundColor: gradienteYouTube,
          borderColor: "#F44336",
          data: [
            totalEspectadoresYTVideo / quantidadePublicacoesYouTubeVideo,
            totalComentariosYTVideo + totalLikesYTVideo,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        });
      shortsExite &&
        datasets.push({
          type: "bar",
          label: "Shorts",
          backgroundColor: gradienteYouTubeShorts,
          borderColor: "#000000",
          data: [
            totalEspectadoresYTShorts / quantidadePublicacoesYouTubeShorts,
            totalComentariosYTShorts + totalLikesYTShorts,
          ],
          borderWidth: 0,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? "end" : 'start'
            },
            anchor: 'end'
          }
        });
      return {
        labels: ["Média de Espectadores", "Engajamento"],
        datasets,
      };
    }

    tipoRedeSocial === "Instagram" &&
      setDadosGraficoResultadosGerais(
        dadosGraficoResultadosGeraisInstagram({
          storiesExiste: totalAlcanceIGStories !== 0,
          feedExiste: totalAlcanceIGFeed !== 0,
          reelsExiste: totalAlcanceIGReels !== 0,
        })
      );
    tipoRedeSocial === "TikTok" &&
      setDadosGraficoResultadosGerais(dadosGraficoResultadosGeraisTikTok);
    tipoRedeSocial === "Twitter" &&
      setDadosGraficoResultadosGerais(dadosGraficoResultadosGeraisTwitter);
    tipoRedeSocial === "YouTube" &&
      setDadosGraficoResultadosGerais(
        dadosGraficoResultadosGeraisYouTube({
          shortsExite: totalEspectadoresYTShorts !== 0,
          videoExite: totalEspectadoresYTVideo !== 0,
        })
      );

    setAlcanceIG(
      (totalAlcanceIGFeed + totalAlcanceIGReels + totalAlcanceIGStories) /
      quantidadePublicacoesIG
    );
    setComentariosIG(totalComentariosIGFeed + totalComentariosIGReels);
    setLikesIG(totalLikesIGFeed + totalLikesIGReels + totalLikesIGStories);
    setImpressoesIG(totalImpressoesIGFeed + totalImpressoesIGStories);
    setSalvosIG(totalSalvosIGFeed + totalSalvosIGReels);
    setCompartilhamentosIG(
      totalCompartilhamentoIGFeed +
      totalCompartilhamentosIGReels +
      totalCompartilhamentosIGStories
    );
    setVisitasPerfilIG(totalVisitasPerfilIGFeed + totalVisitasPerfilIGStories);
    setRespostasIG(totalRespostasIGStories);
    setCliquesLinkIG(totalCliquesLinkIGStories);
    setCliquesStickersIG(totalCliquesStickersIGStories);

    setViewsTotaisTikTok(totalViewsTotaisTikTok);
    setTempoReproducaoTikTok(totalTempoReproducaoTikTok);
    setComentariosTikTok(totalComentariosTikTok);
    setLikesTikTok(totalLikesTikTok);
    setCompartilhamentosTikTok(totalCompartilhamentosTikTok);
    setSalvosTikTok(totalSalvosTikTok);
    setEspectadoresTikTok(totalEspectadoresTikTok);

    setComentariosTwitter(totalComentariosTwitter);
    setEngajamentoTwitter(totalEngajamentoTwitter);
    setFavoritosTwitter(totalFavoritosTwitter);
    setImpressoesTwitter(totalImpressoesTwitter);
    setRetweets(totalRetweetsTwitter);
    setRespostasTwitter(totalRespostasTwitter);
    setViewsTwitter(totalViewsTwitter);
    setCliquesLinkTwitter(totalCliquesLinkTwitter);
    setCliquesHashtagTwitter(totalCliquesHashtagsTwitter);
    setDetailExpandsTwitter(totalDetailExpandsTwitter);

    setViewsTotaisYouTube(totalViewsTotaisYTVideo + totalViewsTotaisYTShorts);
    setTempoReproducaoYouTube(totalTempoReproducaoYTVideo);
    setComentariosYouTube(totalComentariosYTVideo + totalComentariosYTShorts);
    setLikesYouTube(totalLikesYTVideo + totalLikesYTShorts);
    setDislikesYouTube(totalDislikesYTVideo + totalDislikesYTShorts);
    setImpressoesYouTube(totalImpressoesYTVideo + totalImpressoesYTShorts);
    setEspectadoresYouTube(
      totalEspectadoresYTVideo + totalEspectadoresYTShorts
    );
    setTaxaAprovacaoYouTube(
      totalTaxaAprovacaoYTShorts / quantidadePublicacoesYouTubeShorts
    );
  }

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display:
            tipoRedeSocial === "Instagram" || tipoRedeSocial === "YouTube"
              ? true
              : false,
        },
        datalabels: {
          color: function (context: any) {
            return context.dataset.data[context.dataIndex] <= context.chart.boxes[4].ticks[1].value ? context.dataset.backgroundColor : '#fff'
          },
          font: {
            weight: 'bold'
          },
          formatter: function (value: number) {
            return MascaraGrafico(value)
          },
        }
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
            maxTicksLimit: 9,
            callback: function (value: number) {
              return MascaraGrafico(value)
            },
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  return {
    filtraDadosPorRedeSocial,
    getLightTheme,
  };
};
