import { Documentos, getDocumentosLegais } from "../../services/documentosLegais";
import { NavigateFunction } from "react-router-dom";

export interface FunctionTermosDeAceiteProps {
  setTermosDeUso: React.Dispatch<React.SetStateAction<Documentos[]>>;
  setPoliticaDePrivacidade: React.Dispatch<React.SetStateAction<Documentos[]>>;
  setPoliticaDeConteudo: React.Dispatch<React.SetStateAction<Documentos[]>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  setAbrirModalDeslogar: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
}

export const FunctionTermosDeAceite = ({
  setPoliticaDeConteudo,
  setPoliticaDePrivacidade,
  setTermosDeUso,
  setCarregando
}: FunctionTermosDeAceiteProps) => {

  function pegaDocumentosLegais () {
    setCarregando(true)
    getDocumentosLegais("TermosDeUso", 1, 1, "Descending", "dataCriacao").then((res)=>{
      setTermosDeUso(res.data.documentos)
      getDocumentosLegais("PoliticaDeConteudo", 1, 1, "Descending", "dataCriacao").then((res)=>{
        setPoliticaDeConteudo(res.data.documentos)
        getDocumentosLegais("PoliticaDePrivacidade", 1, 1, "Descending", "dataCriacao").then((res)=>{
          setPoliticaDePrivacidade(res.data.documentos)
        }).finally(()=>setCarregando(false))
      })
    })
  }
 

  return {
    pegaDocumentosLegais,
  };
};