import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  campanha,
  getCampanhaTodasCampanhasInfluenciadorPorAgencia,
  getCampanhaTodasCampanhasInfluenciadorPorID,
  getCampanhaTodasCampanhasInfluenciadorPorMarca,
  getCampanhaTodasNome,
} from "../../../services/campanha";
import { obterTokens } from "../../../utils/LocalStorage";

interface FuncaoListarCampanhaInfluenciadorProps {
  idInfluenciador: string;
  setIdInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  idAgenciaFiltro: string;
  idMarcaFiltro: string;
  numeroPagina: number;
  tamanhoPagina: number;
  nomeCampanhaInfluenciadorPesquisa: string;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setTamanhoPagina: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  setListaDeCampanhasInfluenciador: React.Dispatch<campanha[]>;
  setTotalCampanhasInfluenciador: React.Dispatch<number>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncaoListarCampanhaInfluenciador = ({
  idInfluenciador,
  setIdInfluenciador,
  idAgenciaFiltro,
  idMarcaFiltro,
  numeroPagina,
  tamanhoPagina,
  nomeCampanhaInfluenciadorPesquisa,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  setTamanhoPagina,
  setErro,
  setCarregando,
  setCarregandoPesquisa,
  adicionarAListaEsperaRenovacaoToken,
  setListaDeCampanhasInfluenciador,
  setTotalCampanhasInfluenciador,
}: FuncaoListarCampanhaInfluenciadorProps) => {
  const obterCampanhasPorInfluenciador = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasCampanhasInfluenciadorPorID(
      token,
      idInfluenciador,
      numeroPagina,
      tamanhoPagina,
      nomePropriedadeOrdenacao,
      tipoOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasInfluenciador(res.data.campanhas);
        setTotalCampanhasInfluenciador(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasCampanhasInfluenciadorPorID(
              token,
              idInfluenciador,
              numeroPagina,
              tamanhoPagina,
              nomePropriedadeOrdenacao,
              tipoOrdenacao
            )
              .then((res) => {
                setListaDeCampanhasInfluenciador(res.data.campanhas);
                setTotalCampanhasInfluenciador(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Campanhas.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Campanhas.",
          });
          setCarregando(false);
        }
      });
  };

  const obterCampanhaInfluenciadorPorNome = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasNome(
      token,
      nomeCampanhaInfluenciadorPesquisa,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasInfluenciador(res.data.campanhas);
        setTotalCampanhasInfluenciador(res.data.totalRegistros);
        setCarregandoPesquisa(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasNome(
              token,
              nomeCampanhaInfluenciadorPesquisa,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaDeCampanhasInfluenciador(res.data.campanhas);
                setTotalCampanhasInfluenciador(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar as Campanhas.",
                });
              });
          }, setCarregandoPesquisa);
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
          setCarregandoPesquisa(false);
        }
      });
  };
  const obterCampanhaInfluenciadorPorMarca = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasCampanhasInfluenciadorPorMarca(
      token,
      idInfluenciador,
      idMarcaFiltro,
      numeroPagina,
      tamanhoPagina,
      nomePropriedadeOrdenacao,
      tipoOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasInfluenciador(res.data.campanhas);
        setTotalCampanhasInfluenciador(res.data.totalRegistros);
        setCarregandoPesquisa(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasCampanhasInfluenciadorPorMarca(
              token,
              idInfluenciador,
              idMarcaFiltro,
              numeroPagina,
              tamanhoPagina,
              nomePropriedadeOrdenacao,
              tipoOrdenacao
            )
              .then((res) => {
                setListaDeCampanhasInfluenciador(res.data.campanhas);
                setTotalCampanhasInfluenciador(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar as Campanhas.",
                });
              });
          }, setCarregandoPesquisa);
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
          setCarregandoPesquisa(false);
        }
      });
  };

  const obterCampanhaInfluenciadorPorAgencia = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasCampanhasInfluenciadorPorAgencia(
      token,
      idInfluenciador,
      idAgenciaFiltro,
      numeroPagina,
      tamanhoPagina,
      nomePropriedadeOrdenacao,
      tipoOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasInfluenciador(res.data.campanhas);
        setTotalCampanhasInfluenciador(res.data.totalRegistros);
        setCarregandoPesquisa(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasCampanhasInfluenciadorPorAgencia(
              token,
              idInfluenciador,
              idAgenciaFiltro,
              numeroPagina,
              tamanhoPagina,
              nomePropriedadeOrdenacao,
              tipoOrdenacao
            )
              .then((res) => {
                setListaDeCampanhasInfluenciador(res.data.campanhas);
                setTotalCampanhasInfluenciador(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar as Campanhas.",
                });
              });
          }, setCarregandoPesquisa);
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
        }
      });
  };

  return {
    obterCampanhasPorInfluenciador,
    obterCampanhaInfluenciadorPorNome,
    obterCampanhaInfluenciadorPorAgencia,
    obterCampanhaInfluenciadorPorMarca,
  };
};
