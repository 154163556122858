import { useContext } from "react";
import { MostrarToastContext } from "../../../context/MostrarToast";
import { deleteTarefa } from "../../../services/tarefas";
import { obterTokens } from "../../../utils/LocalStorage";

interface deletarInfluenciadorDaTarefaProps {
  idCampanha: string;
  idTarefa: string;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  recarregarTabela: () => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionDeletarInfluenciadorDaTarefa = ({
  setCarregando,
  setRecarregar,
  setModalDesativacao,
  recarregarTabela,
  adicionarAListaEsperaRenovacaoToken,
}: deletarInfluenciadorDaTarefaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = (mensagem: string) => {
    setRecarregar && setRecarregar((recarregar) => !recarregar);
    recarregarTabela();
    mostrarToast(
      "success",
      "Remoção feita com sucesso!",
      mensagem
    );
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Remoção não realizado",
      mensagem
    );
  };

  function deletarInfluenciadorNaTarefa(idCampanha: string, idTarefa: string) {
    const { token } = obterTokens();
    setCarregando && setCarregando(true);
    deleteTarefa(token, idCampanha, idTarefa)
      .then((res: any) => {
        sucessoRequisicao(res.data.mensagem);
        setCarregando && setCarregando(false);
        setModalDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await deleteTarefa(token, idCampanha, idTarefa)
                .then((res2: any) => {
                  sucessoRequisicao(res2.data.mensagem);
                  setModalDesativacao(false);
                })
                .catch((err2) => {
                  erroRequisicao(
                    err2.response.data.mensagem
                      ? err2.response.data.mensagem
                      : err2.response.data.message);
                });
            },
            () => setCarregando && setCarregando(false)
          );
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message);
          setCarregando && setCarregando(false);
        }
      });
  }
  return {
    deletarInfluenciadorNaTarefa,
  };
};
