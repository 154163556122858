import { useEffect, useRef, useState, useContext } from "react";

import { FunctionsListarCampanhasMarcas } from "./functions";

import { obterCredenciais } from "../../../utils/LocalStorage";
import { getUsuarioMarcaProps } from "../../../services/usuarioMarca";
import { campanha } from "../../../services/campanha";

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { TabelaListarCampanhasMarcas } from "../../../components/Tabelas/TabelaListarCampanhaMarca";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListarCampanhasMarca = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(true);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);

  const [usuarioMarca, setUsuarioMarca] = useState<getUsuarioMarcaProps>();
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [statusCampanha, setStatusCampanha] = useState<number>();

  const [numeroPagina, setNumeroPagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");
  const [listaDeCampanhasMarcas, setListaDeCampanhasMarcas] = useState<
    campanha[]
  >([]);
  const [totalCampanhasMarcas, setTotalCampanhasMarcas] = useState<number>(0);
  const [nomeCampanhaMarcaPesquisa, setNomeCampanhaMarcaPesquisa] =
    useState<string>("");
  const [idMarca, setIdMarca] = useState<string>("");
  const [datas, setDatas] = useState<Date | Date[] | undefined>([]);

  const [filtroPorData, setfiltroPorData] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { credenciais } = obterCredenciais();

  const colunas: TitulosTabelasType[] = [
    {
      nome: "Nome",
      ordenacao: "nome",
    },
    {
      nome: "Agência",
      ordenacao: "agencia",
    },

    {
      nome: "Data Início",
      ordenacao: "dataInicio",
    },
    {
      nome: "Data Fim",
      ordenacao: "dataFim",
    },
    {
      nome: "Taxa de Engajamento",
      ordenacao: "mediaTaxaEngajamento",
    },
    {
      nome: "Custo por Engajamento",
      ordenacao: "custoEngajamento",
    },
    {
      nome: "Data do Relatório",
      ordenacao: "dataCriacaoRelatorio",
    },
    {
      nome: "Status",
      ordenacao: "ativo",
    },
  ];

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterUsuarioMarcaPorId,
    obterCampanhaMarcasPorNome,
    obterCampanhaMarcasPorAgencia,
    obterCampanhaMarcasPorAgenciaPorPeriodo,
    obterCampanhasPorStatus,
  } = FunctionsListarCampanhasMarcas({
    idCredenciais: credenciais.id,
    idMarca: idMarca!,
    idAgenciaFiltro: idAgencia,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    nomeCampanhaMarcaPesquisa,
    dataInicial: datas
      ? Array.isArray(datas)
        ? datas.length !== 0
          ? datas[0].toISOString()
          : ""
        : ""
      : "",
    dataFinal: datas
      ? Array.isArray(datas)
        ? datas[1]
          ? datas[1].toISOString()
          : ""
        : ""
      : "",
    statusCampanha,
    setTamanhoPagina,
    setErro,
    setCarregando,
    setCarregandoPesquisa,
    setListaDeCampanhasMarcas,
    setTotalCampanhasMarcas,
    setUsuarioMarca,
    setIdMarca,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    if (filtroPorPeriodo) {
      setfiltroPorData(!filtroPorData);
    }
  }, [datas]);

  useEffect(() => {
    const pesquisaPorNome =
      nomeCampanhaMarcaPesquisa !== "" &&
      nomeCampanhaMarcaPesquisa !== undefined;
    const filtroPorAgencia = idAgencia !== "" && idAgencia !== undefined;
    const filtroPorStatus =
      statusCampanha !== null && statusCampanha !== undefined;
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    pesquisaPorNome
      ? obterCampanhaMarcasPorNome()
      : filtroPorAgencia
      ? obterCampanhaMarcasPorAgencia()
      : filtroPorPeriodo
      ? obterCampanhaMarcasPorAgenciaPorPeriodo()
      : filtroPorStatus
      ? obterCampanhasPorStatus()
      : obterUsuarioMarcaPorId();
  }, [
    tamanhoPagina,
    tipoOrdenacao,
    numeroPagina,
    nomeCampanhaMarcaPesquisa,
    idAgencia,
    filtroPorData,
    statusCampanha,
  ]);

  return (
    <>
      <TabelaListarCampanhasMarcas
        datas={datas}
        setDatas={setDatas}
        statusCampanha={statusCampanha}
        setStatusCampanha={setStatusCampanha}
        idAgenciaFiltro={idAgencia}
        setIdAgenciaFiltro={setIdAgencia}
        carregando={carregando}
        setCarregando={setCarregando}
        setErro={setErro}
        carregandoPesquisa={carregandoPesquisa}
        myToast={myToast}
        tipoOrdenacao={tipoOrdenacao}
        setTipoOrdenacao={setTipoOrdenacao}
        nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
        setNomeCampanhaPesquisa={setNomeCampanhaMarcaPesquisa}
        setRecarregar={() => setRecarregar(!recarregar)}
        erro={erro}
        tituloTabela={"Campanhas"}
        tituloColuna={colunas}
        listaDeCampanhas={listaDeCampanhasMarcas}
        // paginacao={
        //   <Paginator
        //     first={paginatorFirst}
        //     rows={tamanhoPagina}
        //     totalRecords={totalCampanhasMarcas}
        //     onPageChange={(e) => {
        //       setNumeroPagina(e.page);
        //       setPaginatorFirst(e.first);
        //     }}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e: any) => setTamanhoPagina(e.target.value)}
        //     totalLista={totalCampanhasMarcas}
        //     nomeLista={"campanhas"}
        //   />
        // }
      />
      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
