export function mascaraBRLExibir(valor: string) {
  //Primeiro, conferindo se o valor � positivo ou negativo
  var numeroNegativo: boolean = false;
  if (valor?.startsWith("-")) numeroNegativo = true;

  //Remover o lixo da string e deixar s� o n�mero
  var removerLixo = valor?.replace(/[\D]+/g, "");

  //Corrigir o valor para real
  var valorCorrigido = removerLixo + "";
  valorCorrigido = valorCorrigido?.replace(/([0-9]{2})$/g, ",$1");
  if (valorCorrigido == "NaN") valorCorrigido = "0,00";

  //Se o valor for zerado (sem esse tratamento, vai s� "0")
  if (valorCorrigido === "0.00" || valorCorrigido === "0")
    valorCorrigido = "0,00";

  //Se o valor for menor que 1
  if (valorCorrigido.startsWith(",")) valorCorrigido = "0" + valorCorrigido;

  //Se o valor for milh�o
  if (valorCorrigido.length > 8)
    valorCorrigido = valorCorrigido?.replace(
      /(\d)(\d{3})(\d{3}),/g,
      "$1.$2$3,"
    );

  if (valorCorrigido.length > 6)
    valorCorrigido = valorCorrigido?.replace(
      /([0-9]{3}),([0-9]{2}$)/g,
      ".$1,$2"
    );

  //Finalmente, se o valor for negativo no inicio antes dos replaces
  //agora ele � adicionado no in�cio da string
  if (numeroNegativo) valorCorrigido = "-" + valorCorrigido;

  return valorCorrigido;
}

export function mascaraBRLInput(
  e: string,
  setValor?: React.Dispatch<React.SetStateAction<string>>
) {
  //Remover o lixo da string e deixar s� o n�mero
  var removerLixo = e?.replace(/[\D]+/g, "");

  //Corrigir o valor para reals
  var valorCorrigido = removerLixo + "";
  valorCorrigido = valorCorrigido?.replace(/([0-9]{2})$/g, ",$1");

  if (valorCorrigido == "NaN") valorCorrigido = "0";

  //Se o valor for milh�o
  if (valorCorrigido.length > 8)
    valorCorrigido = valorCorrigido?.replace(
      /(\d)(\d{3})(\d{3}),/g,
      "$1.$2$3,"
    );

  if (valorCorrigido.length > 6)
    valorCorrigido = valorCorrigido?.replace(
      /([0-9]{3}),([0-9]{2}$)/g,
      ".$1,$2"
    );

  setValor && setValor(valorCorrigido);
  return valorCorrigido;
}
