import { ErroType } from "../../../@types/erro";
import { getEmailConfirmacaoReenviar } from "../../../services/usuario";

interface FunctionsModalReenvioSenhaProps {
  email: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setSucesso: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
}

export const FunctionsModalReenvioSenha = ({
  email,
  setCarregando,
  setModalAberto,
  setSucesso,
  setEmail,
  setErro,
}: FunctionsModalReenvioSenhaProps) => {
  function solicitaReenvioSenha() {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    getEmailConfirmacaoReenviar(email, "ConfirmacaoEmail", "0")
      .then((res) => {
        setSucesso(true);
      })
      .catch((err) => {
        let mensagem = "";
        switch (err.response.data.status) {
          case 400:
            mensagem = "Email inválido";
            break;
          default:
            mensagem = "Ocorreu um erro inesperado";
            break;
        }
        setErro({
          ativo: true,
          mensagem: mensagem,
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  }

  function fechaModal() {
    setSucesso(false);
    setErro({
      ativo: false,
      mensagem: "",
    });
    setEmail("");
    setCarregando(false);
    setModalAberto(false);
  }

  return {
    solicitaReenvioSenha,
    fechaModal,
  };
};
