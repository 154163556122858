import { useContext, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesGraficoAdsOrganicos } from "./functions";

import { ErroType } from "../../../@types/erro";
import { Input } from "../../Input";
import { Button } from "../../Botoes/Button";
import { AiOutlineSend } from "react-icons/ai";
import { CredenciaisContext } from "../../../context/CredenciaisContext";
import { campanha } from "../../../services/campanha";
import { ErroTabela } from "../../ErroTabela";
import { format } from "../../../utils/MascaraNumero/format";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "primereact/chart";
import { Chart as ChartJS } from "chart.js";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface GraficoAdsOrganicosProps {
  campanha: campanha;
  recarregar: () => void;
}

export const GraficoAdsOrganicos = ({
  campanha,
  recarregar,
}: GraficoAdsOrganicosProps) => {
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];

  const larguraTela = window.screen.width;
  const telaTablet = larguraTela <= 968;
  const telaMobile = larguraTela <= 420;

  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  const [taxaOutrasPublicacoes, setTaxaOutrasPublicacoes] = useState<number>(0);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  ChartJS.register(ChartDataLabels);

  const {
    adicionarTaxaEngajamentoOutrasPublicacoes,
    gradienteCampanha,
    gradienteOutrasPublis,
  } = FuncoesGraficoAdsOrganicos({
    campanha,
    taxaOutrasPublicacoes,
    setTaxaOutrasPublicacoes,
    recarregar,
    setCarregando,
    setErro,
    adicionarAListaEsperaRenovacaoToken,
  });

  let outrasPublis = campanha.taxaEngajamentoOutrasPublicacoes;
  let engajamentoCampanha = campanha.mediaTaxaEngajamento;

  let basicData = {
    labels: ["Outras publis", `${campanha.nome}`],
    datasets: [
      {
        type: "bar",
        label: "Taxa de Engajamento",
        backgroundColor: [gradienteOutrasPublis, gradienteCampanha],
        data: [outrasPublis, engajamentoCampanha],
        borderColor: ["#a0a0a0", "#07886e"],
        color: ["#b0b0b0", "#07886e"],
        borderWidth: 1,
        datalabels: {
          align: function (context: any) {
            return telaMobile
              ? context.dataset.data[context.dataIndex] > 50
                ? "center"
                : "end"
              : context.dataset.data[context.dataIndex] > 5
              ? "center"
              : "end";
          },
          anchor: function (context: any) {
            return telaMobile
              ? context.dataset.data[context.dataIndex] > 50
                ? "center"
                : "end"
              : context.dataset.data[context.dataIndex] > 5
              ? "center"
              : "end";
          },
        },
      },
    ],
  };

  const [lightOptions] = useState({
    indexAxis: "y",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.dataset.label}: ${format(
              "#.###.##0,#0",
              context.raw
            )}%`;
          },
        },
      },
      datalabels: {
        color: function (context: any) {
          return telaMobile
            ? context.dataset.data[context.dataIndex] > 50
              ? "#fff"
              : context.dataset.color
            : context.dataset.data[context.dataIndex] > 5
            ? "#fff"
            : context.dataset.color;
        },
        font: {
          weight: "bold",
          size: telaMobile ? 14 : 18,
        },
        formatter: function (value: number) {
          return `${format("#.###.##0,#0", value)}%`;
        },
      },
    },
    scales: {
      x: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          color: "#495057",
          callback: function (value: string) {
            return value + "%";
          },
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  });

  return (
    <>
      {erro.ativo ? (
        <ErroTabela erro={erro} recarregar={recarregar} />
      ) : (
        <>
          {(perfil === "UsuarioNetcos" || perfil === "Administrador") && (
            <div className={styles.campoInput}>
              <Input
                value={
                  taxaOutrasPublicacoes !== 0
                    ? taxaOutrasPublicacoes
                    : undefined
                }
                type="number"
                placeholder="Taxa de outras publis"
                onChange={(e) =>
                  setTaxaOutrasPublicacoes(e.target.valueAsNumber)
                }
              />
              <p>%</p>
              <Button
                importancia="secundario"
                tooltip="Comparar"
                iconLeft={<AiOutlineSend />}
                onClick={adicionarTaxaEngajamentoOutrasPublicacoes}
              />
            </div>
          )}

          <div className={styles.containerGrafico}>
            <Chart
              id="divGraficoAdsOrganicos"
              type="bar"
              data={basicData}
              options={lightOptions}
            />
          </div>
        </>
      )}
    </>
  );
};
