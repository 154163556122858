import { tarefaProps } from "../../../services/tarefas";
import { somaMetrica } from "../../../utils/somaMetrica";
import { redesSociaisProps } from "../../Graficos/GraficoResultadosGeraisPorRedeSocial/functions";

enum RedeSocial {
  Instagram = "Instagram",
  TikTok = "TikTok",
  Twitter = "Twitter",
  YouTube = "YouTube",
}

interface FuncoesTabelaComparativoRedesSociaisProps {
  tarefas: tarefaProps[];
  setTarefasTabela: React.Dispatch<React.SetStateAction<any[]>>
}

export const FuncoesTabelaComparativoRedesSociais = ({
  tarefas,
  setTarefasTabela,
}: FuncoesTabelaComparativoRedesSociaisProps) => {


  const criaObjetoTabela = () => {
    const arrayTarefasTabela = tarefas.map(tarefa => {
      const tarefaTabela = {
        nomeInfluenciador: tarefa.influenciador.nome,
        totalPosts: {
          instagram: somaPostagens(tarefa, RedeSocial.Instagram),
          tiktok: somaPostagens(tarefa, RedeSocial.TikTok),
          twitter: somaPostagens(tarefa, RedeSocial.Twitter),
          youtube: somaPostagens(tarefa, RedeSocial.YouTube),
        },
        impressoes: {
          instagram: setaMetrica(tarefa, RedeSocial.Instagram, 'impressoes'),
          tiktok: setaMetrica(tarefa, RedeSocial.TikTok, 'impressoes'),
          twitter: setaMetrica(tarefa, RedeSocial.Twitter, 'impressoes'),
          youtube: setaMetrica(tarefa, RedeSocial.YouTube, 'impressoes'),
        },
        interacoes: {
          instagram: tarefa.instagramTotalEngajamento,
          tiktok: tarefa.tikTokEngajamento,
          twitter: tarefa.twitterEngajamento,
          youtube: tarefa.youtubeTotalEngajamento,
        },
        compartilhamentos: {
          instagram: setaMetrica(tarefa, RedeSocial.Instagram, 'compartilhamentos'),
          tiktok: setaMetrica(tarefa, RedeSocial.TikTok, 'compartilhamentos'),
        },
        taxaEngajamento: {
          instagram: tarefa.instagramTotalTaxaEngajamento,
          tiktok: tarefa.tikTokTaxaEngajamento,
          twitter: tarefa.twitterTaxaEngajamento,
          youtube: tarefa.youtubeTotalTaxaEngajamento
        },
      }
      return tarefaTabela
    })

    setTarefasTabela(arrayTarefasTabela)
  }

  const setaMetrica = (tarefa: tarefaProps, redeSocial: redesSociaisProps, metrica: 'impressoes' | 'compartilhamentos') => {
    let impressoesInstagram = 0;
    let impressoesTikTok = 0;
    let impressoesTwitter = 0;
    let impressoesYouTube = 0;

    let compartilhamentosInstagram = 0;
    let compartilhamentosTikTok = 0;

    tarefa.publicacoes.filter(publicacao => publicacao.status === "Aprovado")
      .map((publicacao) => {
        switch (publicacao.tipo) {
          case "PublicacaoInstagramFeed":
            impressoesInstagram = somaMetrica(impressoesInstagram, publicacao.impressoes);
            compartilhamentosInstagram = somaMetrica(compartilhamentosInstagram, publicacao.compartilhamentos);
            break;
          case "PublicacaoInstagramReels":
            impressoesInstagram = somaMetrica(impressoesInstagram, publicacao.quantidadeReproducoes);
            compartilhamentosInstagram = somaMetrica(compartilhamentosInstagram, publicacao.compartilhamentos);
            break;
          case "PublicacaoInstagramStories":
            impressoesInstagram = somaMetrica(impressoesInstagram, publicacao.impressoes);
            compartilhamentosInstagram = somaMetrica(compartilhamentosInstagram, publicacao.compartilhamentos);
            break;
          case "PublicacaoTikTok":
            impressoesTikTok = somaMetrica(impressoesTikTok, publicacao.views);
            compartilhamentosTikTok = somaMetrica(compartilhamentosTikTok, publicacao.compartilhamentos);
            break;
          case "PublicacaoTwitter":
            impressoesTwitter = somaMetrica(impressoesTwitter, publicacao.impressoes);
            break;
          case "PublicacaoYouTube":
            impressoesYouTube = somaMetrica(impressoesYouTube, publicacao.impressoes);
            break;
          case "PublicacaoYouTubeShorts":
            impressoesYouTube = somaMetrica(impressoesYouTube, publicacao.impressoes);
            break;
        }
      })

    switch (redeSocial) {
      case "Instagram":
        return metrica === 'impressoes' ? impressoesInstagram : compartilhamentosInstagram;
      case "TikTok":
        return metrica === 'impressoes' ? impressoesTikTok : compartilhamentosTikTok;
      case "Twitter":
        return impressoesTwitter;
      case "YouTube":
        return impressoesYouTube;
    }
  }

  const identificaTop3 = (metrica: number, array: number[]) => {
    let top3 = array.sort((a, b) => b - a).slice(0, 3)

    if (metrica === top3[0]) { return 'top1' }
    if (metrica === top3[1]) { return 'top2' }
    if (metrica === top3[2]) { return 'top3' }
  }

  const somaPostagens = (tarefa: tarefaProps, redeSocial: redesSociaisProps) => {
    return (
      tarefa.publicacoes.filter(publicacao => publicacao.status === "Aprovado" &&
        (
          (publicacao.tipo.startsWith("PublicacaoInstagram") && redeSocial === "Instagram") ||
          (publicacao.tipo === "PublicacaoTikTok" && redeSocial === "TikTok") ||
          (publicacao.tipo === "PublicacaoTwitter" && redeSocial === "Twitter") ||
          (publicacao.tipo.startsWith("PublicacaoYouTube") && redeSocial === "YouTube")
        )).length
    )
  }

  return {
    criaObjetoTabela,
    identificaTop3,
  };
};
