import { PublicacoesProps } from "../../../services/publicacao";

interface FuncoesTabelaMetricasTwitterProps {
  setEngajamento: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setFavoritos: React.Dispatch<React.SetStateAction<number>>;
  setRetweets: React.Dispatch<React.SetStateAction<number>>;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  setCliquesLink: React.Dispatch<React.SetStateAction<number>>;
  setCliquesHashtag: React.Dispatch<React.SetStateAction<number>>;
  setDetailExpands: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaMetricasTwitter = ({
  setEngajamento,
  setComentarios,
  setFavoritos,
  setRetweets,
  setImpressoes,
  setRespostas,
  setCliquesLink,
  setCliquesHashtag,
  setDetailExpands,
}: FuncoesTabelaMetricasTwitterProps) => {
  const somaDadosPublicacaoTwitter = (
    listaDePublicacoes: PublicacoesProps[]
  ) => {
    let somaEngajamento: number = 0;
    let somaComentarios: number = 0;
    let somaFavoritos: number = 0;
    let somaRetweets: number = 0;
    let somaImpressoes: number = 0;
    let somaRespostas: number = 0;
    let somaCliquesLink: number = 0;
    let somaCliquesHashtag: number = 0;
    let somaDetailExpands: number = 0;

    listaDePublicacoes.map((publicacao) => {
      publicacao.engajamento &&
        (somaEngajamento = somaEngajamento + publicacao.engajamento);
      publicacao.comentarios &&
        (somaComentarios = somaComentarios + publicacao.comentarios);
      publicacao.favoritos &&
        (somaFavoritos = somaFavoritos + publicacao.favoritos);
      publicacao.retweets &&
        (somaRetweets = somaRetweets + publicacao.retweets);
      publicacao.impressoes &&
        (somaImpressoes = somaImpressoes + publicacao.impressoes);
      publicacao.respostas &&
        (somaRespostas = somaRespostas + publicacao.respostas);
      publicacao.cliquesLink &&
        (somaCliquesLink = somaCliquesLink + publicacao.cliquesLink);
      publicacao.cliquesLink && //adicionar cliques na Hashtag nas métricas
        (somaCliquesHashtag = publicacao.cliquesHashtag
          ? somaCliquesHashtag + publicacao.cliquesHashtag
          : somaCliquesHashtag);
      publicacao.impressoes && //adicionar detail expands nas métricas
        (somaDetailExpands = publicacao.detailExpands
          ? somaDetailExpands + publicacao.detailExpands
          : somaDetailExpands);
    });

    setEngajamento(somaEngajamento);
    setComentarios(somaComentarios);
    setFavoritos(somaFavoritos);
    setRetweets(somaRetweets);
    setImpressoes(somaImpressoes);
    setRespostas(somaRespostas);
    setCliquesLink(somaCliquesLink);
    setCliquesHashtag(somaCliquesHashtag);
    setDetailExpands(somaDetailExpands);
  };

  function pegaCodigoTwitterERetornaEmbed(urlPublicacao: string) {
    var regExp = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/;
    var match = urlPublicacao.match(regExp);
    var matchFinal = match ? match[3].toString() : "Vazio";
    return matchFinal;
  }

  return {
    somaDadosPublicacaoTwitter,
    pegaCodigoTwitterERetornaEmbed,
  };
};
