import { useContext } from "react";
import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";
import { deleteCampanha } from "../../../services/campanha";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsTabelaListarCampanhaProps {
  setRecarregar: () => void;
  setRecarregando?: React.Dispatch<React.SetStateAction<boolean>>;
  carregando: boolean;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  totalCampanhas: number;
  tamanhoPagina: number;
  setTamanhoPagina: React.Dispatch<React.SetStateAction<number>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsTabelaListarCampanha = ({
  setRecarregar,
  setTamanhoPagina,
  setRecarregando,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsTabelaListarCampanhaProps) => {
  function criaPDFListaDeCampanhas() {
    // setTamanhoPagina(totalCampanhas);

    const minhaTabela = document.getElementById(
      "corpoTabelaListarAgencias"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>Data Início</th><th>N° Usuários</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA

    setTamanhoPagina(5);
    setRecarregar();
  }

  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = (mensagem: string) => {
    mostrarToast(
      "success",
      "Exclusão realizada",
      mensagem
    );

    setRecarregando && setRecarregando((recarregar) => !recarregar);
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Exclusão não realizado",
      mensagem
    );
  };

  function deletarCampanha(idCampanha: string) {
    const { token, tokenRenovacao } = obterTokens();

    deleteCampanha(token, idCampanha)
      .then((res: any) => {
        sucessoRequisicao(res.data.mensagem);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await deleteCampanha(token, idCampanha)
              .then((res2: any) => {
                sucessoRequisicao(res2.data.message);
              })
              .catch((err2) => {
                erroRequisicao(
                  err2.response.data.mensagem
                    ? err2.response.data.mensagem
                    : err2.response.data.message);
              });
          }, setCarregando);
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message);
          setCarregando(false);
        }
      });
  }
  return {
    criaPDFListaDeCampanhas,
    deletarCampanha,
  };
};
