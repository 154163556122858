import { FileUpload } from "primereact/fileupload";
import { Carregando } from "../Carregando";
import { FunctionsUploadDragAndDrop } from "./functions";
import styles from "./styles.module.css";

export interface UploadDragAndDropProps {
  upload?: () => void;
  prints: File[];
  setPrints: React.Dispatch<React.SetStateAction<File[]>>;
  carregando?: boolean;
}

export const UploadDragAndDrop = ({
  upload,
  prints,
  setPrints,
  carregando,
}: UploadDragAndDropProps) => {
  const chooseOptions = {
    label: "Escolher",
    icon: "pi pi-fw pi-plus",
    style: { backgroundColor: "var(roxo-500)" },
  };
  const uploadOptions = {
    label: "Upload",
    icon: "pi pi-upload",
    className: "p-button-success",
    style: { display: upload ? "inline" : "none" },
  };
  const cancelOptions = {
    label: "Cancelar",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const { selecionaImagens, removeFoto, limpaTodasFotos } =
    FunctionsUploadDragAndDrop({
      prints,
      setPrints,
    });

  return (
    <div id={styles.containerFileUpload}>
      {carregando ? (
        <Carregando />
      ) : (
        <FileUpload
          name="prints[]"
          url="https://44.212.197.102/Dev/Publicacao"
          customUpload={true}
          uploadHandler={upload}
          accept="image/*,video/mp4,video/x-m4v,video/*"
          maxFileSize={100000000}
          multiple
          emptyTemplate={
            <p
              className="m-0"
              style={{
                fontSize: "1.5rem",
                textAlign: "center",
                fontFamily: "Exo, sans-serif",
              }}
            >
              Adicione os arquivos que deseja fazer upload.
            </p>
          }
          onSelect={selecionaImagens}
          onRemove={removeFoto}
          onClear={limpaTodasFotos}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
        />
      )}
    </div>
  );
};
