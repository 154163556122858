import React, { SetStateAction, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesTabelaListarUsuariosAgencia } from "./functions";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { ErroType } from "../../../@types/erro";
import { listaUsuarioAgenciaProps } from "../../../services/usuarioAgencia";
import { ModalAtualizarUsuarioAgencia } from "../../Modais/ModalAtualizarUsuarioAgencia";
import { ModalRedefinicao } from "../../Modais/ModalRedefinicao";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { TituloColuna } from "../../TituloColuna";
import { SelectAgencia } from "../../Select/SelectAgencia";
import { BotaoFiltro } from "../../Botoes/BotaoFiltro";
import { SelectStatus, statusType } from "../../Select/SelectStatus";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ModalCadastroUsuarioAgencia } from "../../Modais/ModalCadastroUsuarioAgencia";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { CardUsuarioAgencia } from "../../Cards/CardUsuarioAgencia";
import { ModalReenvioSenha } from "../../Modais/ModalReenvioSenha";

import { TabPanel, TabView } from "primereact/tabview";
import { BsRecordCircleFill } from "react-icons/bs";
import { Avatar } from "primereact/avatar";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface TabelaListarUsuariosAgenciaProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  listaUsuarios: listaUsuarioAgenciaProps[];
  // paginacao: any;
  // seletorDeQuantidade: any;
  erro: ErroType;
  carregando: boolean;
  setCarregando: React.Dispatch<boolean>;
  carregandoPesquisa: boolean;
  setNomeBusca: React.Dispatch<React.SetStateAction<string>>;
  idAgenciaPesquisa: string;
  setIdAgenciaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  modalAberto: boolean;
  setModalAberto: React.Dispatch<SetStateAction<boolean>>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<SetStateAction<tipoOrdenacao>>;
  setModalCadastroUsuarioAgencia: React.Dispatch<boolean>;
  setPropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  propriedadeOrdenacao: string;
  myToast: React.MutableRefObject<any>;
  setStatus: React.Dispatch<React.SetStateAction<statusType>>;
  status: statusType;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  setErro: React.Dispatch<ErroType>;
}

export const TabelaListarUsuariosAgencia = ({
  tituloTabela,
  tituloColuna,
  listaUsuarios,
  // paginacao,
  // seletorDeQuantidade,
  erro,
  carregando,
  setCarregando,
  carregandoPesquisa,
  setNomeBusca,
  idAgenciaPesquisa,
  setIdAgenciaPesquisa,
  setRecarregar,
  modalAberto,
  setModalAberto,
  tipoOrdenacao,
  setTipoOrdenacao,
  setPropriedadeOrdenacao,
  propriedadeOrdenacao,
  myToast,
  setModalCadastroUsuarioAgencia,
  setStatus,
  status,
  setErro,
}: TabelaListarUsuariosAgenciaProps) => {
  const [modalEditarUsuarioAgencia, setModalEditarUsuarioAgencia] =
    useState<boolean>(false);
  const [modalRedefinicao, setModalRedefinicao] = useState<boolean>(false);
  const [modalReenvioSenha, setModalReenvioSenha] = useState<boolean>(false);
  const [modalDesativacao, setModalDesativacao] = useState<boolean>(false);
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);

  const [idUsuario, setIdUsuario] = useState<string>("");
  const [nomeUsuario, setNomeUsuario] = useState<string>("");
  const [emailUsuario, setEmailUsuario] = useState<string>("");
  const [telefoneUsuario, setTelefoneUsuario] = useState<string>("");
  const [imagemAvatarUsuario, setImagemAvatarUsuario] = useState<string>("");
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [statusUsuario, setStatusUsuario] = useState<string>("");

  const [activeIndex, setActiveIndex] = useState(0);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { desativarUsuario, reativarUsuario, criaPDFListaDeUsuariosAgencia } =
    FuncoesTabelaListarUsuariosAgencia({
      idUsuario,
      imagemAvatarUsuario,
      myToast,
      nomeUsuario,
      idAgencia,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      setModalAbertoDesativacao: setModalDesativacao,
      telefoneUsuario,
      setRecarregar,
      setErro,
    });

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
              <div className={styles.botaoModalAdcAgencia}>
                <Button
                  importancia="secundario"
                  onClick={() => setModalCadastroUsuarioAgencia(true)}
                  tooltip="Cadastrar Usuário de Agência"
                >
                  {
                    <i
                      className="pi pi-plus-circle"
                      style={{ fontSize: "1.8rem" }}
                    />
                  }
                  Adicionar Usuário
                </Button>
              </div>
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                carregandoPesquisa={carregandoPesquisa}
                onChange={(e) => setNomeBusca(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaDeUsuariosAgencia} />
              <BotaoFiltro
                children={
                  <div id={styles.containerFiltrosTabelaCampanhaPorAgencia}>
                    <TabView
                      activeIndex={activeIndex}
                      onTabChange={(e) => {
                        setActiveIndex(e.index);
                        setIdAgenciaPesquisa("");
                        setStatus("");
                      }}
                    >
                      <TabPanel header="Agencia">
                        <SelectAgencia
                          idAgencia={idAgenciaPesquisa}
                          setIdAgencia={setIdAgenciaPesquisa}
                        />
                      </TabPanel>
                      <TabPanel header="Status">
                        <SelectStatus status={status} setStatus={setStatus} />
                      </TabPanel>
                    </TabView>
                  </div>
                }
              />
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 2, 4]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={propriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setPropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody id="corpoTabelaListaUsuarioAgencia" className={styles.corpoTabela}>
        {carregando ? (
          <Carregando listaUsuarios={listaUsuarios.length} spinner={true} />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {(listaUsuarios && listaUsuarios.length === 0) || carregando ? (
                  <tr>
                    <td style={{ justifyContent: "center", height: "37rem" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaUsuarios.map((usuario) => {
                    return (
                      <tr key={usuario.email}>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={usuario.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={usuario.urlAvatar}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{usuario.nome}</p>
                        </td>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={usuario.agencia.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={usuario.agencia.urlLogotipo}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{usuario.agencia.nome}</p>
                        </td>
                        <td>{usuario.email}</td>
                        <td>
                          {usuario.telefone.toString()}
                        </td>
                        <td>
                          <div
                            className={`${styles.status} ${styles[usuario.status.toLowerCase()]
                              }`}
                          >
                            <BsRecordCircleFill
                              style={{
                                color: `${(() => {
                                  switch (usuario.status) {
                                    case "Pendente":
                                      return "var(--laranja-500)";

                                    case "Ativo":
                                      return "var(--verde-500)";

                                    case "AguardandoRemocao":
                                      return "var(--laranja-700)";

                                    case "Removido":
                                      return "var(cinza-400)";
                                  }
                                })()}`,
                                fontSize: "1rem",
                              }}
                            />
                            {usuario.status === "AguardandoRemocao"
                              ? "Aguardando Remoção"
                              : usuario.status}
                          </div>
                        </td>
                        <td className="colunaBotoesAcoes">
                          <BotaoColunaAcoes
                            status={
                              usuario.status === "AguardandoRemocao"
                                ? "Removido"
                                : usuario.status
                            }
                            icone={"pi-pencil"}
                            titulo={"Editar Usuário"}
                            onClick={() => {
                              setModalEditarUsuarioAgencia(true);
                              setNomeUsuario(usuario.nome);
                              setImagemAvatarUsuario(usuario.urlAvatar);
                              setTelefoneUsuario(usuario.telefone.toString());
                              setIdUsuario(usuario.idCredenciais);
                              setStatusUsuario(usuario.status);
                              setIdAgencia(usuario.agencia.id);
                            }}
                          />
                          {usuario.status == "Pendente" ? (
                            <BotaoColunaAcoes
                              status={usuario.status}
                              icone={"pi-send"}
                              titulo={"Reenviar email de Confirmação"}
                              onClick={() => {
                                setModalReenvioSenha(true);
                                setEmailUsuario(usuario.email);
                              }}
                            />
                          ) : (
                            <BotaoColunaAcoes
                              status={
                                usuario.status === "AguardandoRemocao"
                                  ? "Removido"
                                  : usuario.status
                              }
                              icone={"pi-lock"}
                              titulo={"Redefinir Senha"}
                              onClick={() => {
                                setModalRedefinicao(true);
                                setEmailUsuario(usuario.email);
                              }}
                            />
                          )}
                          <BotaoColunaAcoes
                            icone={
                              usuario.status === "AguardandoRemocao"
                                ? "pi-user-plus"
                                : "pi-trash"
                            }
                            titulo={
                              usuario.status === "AguardandoRemocao"
                                ? usuario.agencia.ativo
                                  ? "Reativar Usuário"
                                  : "Não é possivel reativar usuário, pois a agência a que ele pertence está desativada."
                                : "Remover Usuário"
                            }
                            status={
                              usuario.status === "Removido" ||
                                !usuario.agencia.ativo
                                ? "Removido"
                                : ""
                            }
                            onClick={() => {
                              setModalDesativacao(true);
                              setIdUsuario(usuario.idCredenciais);
                              setNomeUsuario(usuario.nome);
                              setImagemAvatarUsuario(usuario.urlAvatar);
                              setTelefoneUsuario(usuario.telefone.toString());
                              setStatusUsuario(usuario.status);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </>
        )}
      </tbody>

      <div className={styles.listaCardsUsuario}>
        {listaUsuarios.length > 0 ? (
          listaUsuarios.map((usuario, index) => {
            return (
              <CardUsuarioAgencia
                usuario={usuario}
                setNomeUsuario={setNomeUsuario}
                setImagemAvatarUsuario={setImagemAvatarUsuario}
                setTelefoneUsuario={setTelefoneUsuario}
                setIdUsuario={setIdUsuario}
                setStatusUsuario={setStatusUsuario}
                setEmailUsuario={setEmailUsuario}
                setModalRedefinicao={setModalRedefinicao}
                setModalReenvioSenha={setModalReenvioSenha}
                setModalEditar={setModalEditarUsuarioAgencia}
                setModalDesativacao={setModalDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
      <tfoot className={styles.footerTabela}>
        {/* <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            {seletorDeQuantidade}
          </div>
          {paginacao}
        </div> */}
      </tfoot>

      {modalAberto && (
        <ModalCadastroUsuarioAgencia
          modalAberto={modalAberto}
          setModalAberto={setModalAberto}
          setRecarregar={setRecarregar}
        />
      )}
      {modalEditarUsuarioAgencia && (
        <ModalAtualizarUsuarioAgencia
          setRecarregar={setRecarregar}
          id={idUsuario}
          modalAberto={modalEditarUsuarioAgencia}
          setModalAberto={setModalEditarUsuarioAgencia}
          nomeUsuario={nomeUsuario}
          telefoneUsuario={telefoneUsuario}
          imagemAvatarUsuario={imagemAvatarUsuario}
          agenciaUsuario={idAgencia}
          statusUsuario={statusUsuario}
        />
      )}
      {modalRedefinicao && (
        <ModalRedefinicao
          modalAberto={modalRedefinicao}
          setModalAberto={setModalRedefinicao}
          emailPrevio={emailUsuario}
        />
      )}
      {modalReenvioSenha && (
        <ModalReenvioSenha
          modalAberto={modalReenvioSenha}
          setModalAberto={setModalReenvioSenha}
          emailPrevio={emailUsuario}
        />
      )}
      {modalDesativacao && (
        <ModalDesativacao
          modalAberto={modalDesativacao}
          setModalAberto={setModalDesativacao}
          nomeItemParaDesativar={nomeUsuario}
          status={statusUsuario}
          onClick={
            statusUsuario === "AguardandoRemocao"
              ? () => reativarUsuario()
              : () => desativarUsuario()
          }
        />
      )}
    </table>
  );
};
