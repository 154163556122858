import React from "react";
import styles from "./styles.module.css";


interface CaixaFlutuanteAtualizarAgenciaProps {
  caixaAbertaVisualizacao: boolean;
  setCaixaAbertaVisualizacao: React.Dispatch<boolean>;
  setVisualizacaoBenchmarking: React.Dispatch<boolean>;
}

export const CaixaFlutuanteVisualizacaoCampanha = ({
  caixaAbertaVisualizacao,
  setCaixaAbertaVisualizacao,
  setVisualizacaoBenchmarking,
}: CaixaFlutuanteAtualizarAgenciaProps) => {

  return (
    <div className={styles.conteudoCaixaFlutuanteVisualizacaoCampanha}>
      <label className={styles.labelInput} htmlFor={styles.fileUpload}>
        <div
          onClick={() => {
            setVisualizacaoBenchmarking(false)
            setCaixaAbertaVisualizacao(false)
          }}
          className={styles.fileUpload}
        >
          <i
            className="pi pi-list"
            style={{
              fontSize: "2rem",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
            }}
          />
          <p>Campanhas</p>
        </div>
      </label>
      <label className={styles.labelInput} htmlFor={styles.fileUpload}>
        <div
          onClick={() => {
            setVisualizacaoBenchmarking(true)
            setCaixaAbertaVisualizacao(false)
          }}
          className={styles.fileUpload}
        >
          <i
            className="pi pi-table"
            style={{
              fontSize: "2rem",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
            }}
          />
          <p>Benchmarking</p>
        </div>
      </label>
    </div>
  );
};
