import { PublicacoesProps } from "../../../services/publicacao";
import MascaraGrafico from "../../../utils/MascaraRotulosGraficos";
import { somaMetrica } from "../../../utils/somaMetrica";

export interface basicDataProps {
  labels: (string | null)[];
  datasets: datasetsProps[];
}

interface datasetsProps {
  type: string;
  label: string;
  borderColor?: string;
  borderWidth?: number;
  fill?: boolean;
  tension?: number;
  data: number[];
  backgroundColor?: CanvasGradient | string;
}

interface FuncoesGraficoVisaoGeralPorRedeSocialProps {
  setBasicData: React.Dispatch<React.SetStateAction<basicDataProps>>;
  setBasicOptions: React.Dispatch<any>;

  setAlcanceTotal: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoTotal: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesTotal: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTotal: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosTotal: React.Dispatch<React.SetStateAction<number>>;
  setLikesTotal: React.Dispatch<React.SetStateAction<number>>;
  setDislikesTotal: React.Dispatch<React.SetStateAction<number>>;
  setSalvosTotal: React.Dispatch<React.SetStateAction<number>>;
  setRetweetsTotal: React.Dispatch<React.SetStateAction<number>>;
  setFavoritosTotal: React.Dispatch<React.SetStateAction<number>>;
  setTwitter: React.Dispatch<React.SetStateAction<number>>;
  setInstagram: React.Dispatch<React.SetStateAction<number>>;
  setTiktok: React.Dispatch<React.SetStateAction<number>>;
  setYoutube: React.Dispatch<React.SetStateAction<number>>;

  setTwitterExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setInstagramExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setTiktokExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setYoutubeExiste: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FuncoesGraficoVisaoGeralPorRedeSocial = ({
  setBasicData,
  setAlcanceTotal,
  setComentariosTotal,
  setCompartilhamentosTotal,
  setDislikesTotal,
  setEngajamentoTotal,
  setFavoritosTotal,
  setImpressoesTotal,
  setInstagram,
  setLikesTotal,
  setRetweetsTotal,
  setSalvosTotal,
  setTiktok,
  setTwitter,
  setYoutube,
  setInstagramExiste,
  setTiktokExiste,
  setTwitterExiste,
  setYoutubeExiste,
  setBasicOptions,
}: FuncoesGraficoVisaoGeralPorRedeSocialProps) => {
  const larguraTela = window.screen.width;
  const telaTablet = larguraTela <= 968;
  const telaMobile = larguraTela <= 420;

  let somaInstagram: number = 0;
  let somaTiktok: number = 0;
  let somaTwitter: number = 0;
  let somaYoutube: number = 0;

  function setaDados(publicacoes: PublicacoesProps[]) {
    let dataArrayTwitter = setaDadosTwitter(publicacoes);
    let dataArrayInsta = setaDadosInsta(publicacoes);
    let dataArrayYoutube = setaDadosYoutube(publicacoes);
    let dataArrayTiktok = setaDadosTiktok(publicacoes);

    let divGraficoVisaoGeralPorRedeSocial = document.getElementById(
      "divGraficoVisaoGeralPorRedeSocial"
    );
    let graficoVisaoGeralPorRedeSocial =
      divGraficoVisaoGeralPorRedeSocial?.firstElementChild;
    graficoVisaoGeralPorRedeSocial?.setAttribute(
      "id",
      "graficoVisaoGeralPorRedeSocial"
    );

    var graficoVisaoGeralPorRedeSocialId = <HTMLCanvasElement>(
      document.getElementById("graficoVisaoGeralPorRedeSocial")
    );
    var graficoVisãoGeralPorRedeSocialContexto =
      graficoVisaoGeralPorRedeSocialId?.getContext("2d");

    var gradienteImpressoes =
      graficoVisãoGeralPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteImpressoes?.addColorStop(0, "#ef8ada");
    gradienteImpressoes?.addColorStop(0.05, "#fc887b");
    gradienteImpressoes?.addColorStop(0.1, "#ef8ada");
    gradienteImpressoes?.addColorStop(0.8, "#fc887b");

    var gradienteAlcance =
      graficoVisãoGeralPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteAlcance?.addColorStop(0, "#fc887b");
    gradienteAlcance?.addColorStop(0.05, "#ffdd95");
    gradienteAlcance?.addColorStop(0.1, "#fc887b");
    gradienteAlcance?.addColorStop(0.8, "#ffdd95");

    var gradienteEngajamento =
      graficoVisãoGeralPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteEngajamento?.addColorStop(0, "#ffdd95");
    gradienteEngajamento?.addColorStop(0.05, "#d0e6a5");
    gradienteEngajamento?.addColorStop(0.1, "#ffdd95");
    gradienteEngajamento?.addColorStop(0.8, "#d0e6a5");

    var gradienteLikes =
      graficoVisãoGeralPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteLikes?.addColorStop(0, "#d0e6a5");
    gradienteLikes?.addColorStop(0.05, "#86e3ce");
    gradienteLikes?.addColorStop(0.1, "#d0e6a5");
    gradienteLikes?.addColorStop(0.8, "#86e3ce");

    var gradienteComentarios =
      graficoVisãoGeralPorRedeSocialContexto?.createLinearGradient(
        0,
        0,
        0,
        400
      );
    gradienteComentarios?.addColorStop(0, "#86e3ce");
    gradienteComentarios?.addColorStop(0.05, "#89d5f8");
    gradienteComentarios?.addColorStop(0.1, "#86e3ce");
    gradienteComentarios?.addColorStop(1, "#89d5f8");

    const instagramExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoInstagramFeed" ||
        publicacao.tipo === "PublicacaoInstagramReels" ||
        publicacao.tipo === "PublicacaoInstagramStories"
    );

    const tiktokExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTikTok"
    );

    const twitterExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTwitter"
    );

    const youtubeExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoYouTube"
    );

    let dataImpressoes = []
    let dataAlcance = []
    let dataEngajamento = []
    let dataLikes = []
    let dataComentarios = []

    const labelsPresentes: string[] = [];
    if (instagramExiste) {
      setInstagramExiste(true);
      labelsPresentes.push("Instagram");
      dataImpressoes.push(dataArrayInsta[1])
      dataAlcance.push(dataArrayInsta[0])
      dataEngajamento.push(dataArrayInsta[2])
      dataLikes.push(dataArrayInsta[3])
      dataComentarios.push(dataArrayInsta[4])
    }
    if (tiktokExiste) {
      setTiktokExiste(true);
      labelsPresentes.push("TikTok");
      dataImpressoes.push(dataArrayTiktok[1])
      dataAlcance.push(dataArrayTiktok[0])
      dataEngajamento.push(dataArrayTiktok[2])
      dataLikes.push(dataArrayTiktok[3])
      dataComentarios.push(dataArrayTiktok[4])
    }
    if (twitterExiste) {
      setTwitterExiste(true);
      labelsPresentes.push("Twitter");
      dataImpressoes.push(dataArrayTwitter[1])
      dataAlcance.push(dataArrayTwitter[0])
      dataEngajamento.push(dataArrayTwitter[2])
      dataLikes.push(dataArrayTwitter[3])
      dataComentarios.push(dataArrayTwitter[4])
    }
    if (youtubeExiste) {
      setYoutubeExiste(true);
      labelsPresentes.push("YouTube");
      dataImpressoes.push(dataArrayYoutube[1])
      dataAlcance.push(dataArrayYoutube[0])
      dataEngajamento.push(dataArrayYoutube[2])
      dataLikes.push(dataArrayYoutube[3])
      dataComentarios.push(dataArrayYoutube[4])
    }

    let basicData = {
      labels: labelsPresentes,
      datasets: [
        {
          type: "bar",
          label: "Impressões",
          // backgroundColor: (context: any) => {
          //     console.log(context.element.y);

          //     if (!context.element.y || !context.element.height) {
          //       return
          //     }

          //     const {y, height} = context.element

          //     const gradienteImpressoes = graficoVisãoGeralPorRedeSocialContexto!.createLinearGradient(
          //       0,
          //       y - (height /2),
          //       0,
          //       y + (height /2),
          //     );
          //     gradienteImpressoes?.addColorStop(0.1, "#ef8ada");
          //     gradienteImpressoes?.addColorStop(1, "#fc887b");
          //     return gradienteImpressoes
          // },
          backgroundColor: gradienteImpressoes ? gradienteImpressoes : "#000",
          data: dataImpressoes,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
        {
          type: "bar",
          label: "Alcance",
          backgroundColor: gradienteAlcance ? gradienteAlcance : "#8331B4",
          data: dataAlcance,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
        {
          type: "bar",
          label: "Engajamento",
          backgroundColor: gradienteEngajamento
            ? gradienteEngajamento
            : "#50ABF1",
          data: dataEngajamento,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
        {
          type: "bar",
          label: "Likes",
          backgroundColor: gradienteLikes ? gradienteLikes : "#F44336",
          data: dataLikes,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
        {
          type: "bar",
          label: "Comentários",
          backgroundColor: gradienteComentarios
            ? gradienteComentarios
            : "#8331B4",
          data: dataComentarios,
          borderColor: "#00000000",
          borderWidth: 1,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
      ],
    };

    setBasicData(basicData);
  }

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
        datalabels: {
          color: function (context: any) {
            return context.dataset.data[context.dataIndex] <=
              context.chart.boxes[4].ticks[1].value
              ? context.dataset.backgroundColor
              : context.datasetIndex === 0
                ? "#671A72"
                : context.datasetIndex === 1
                  ? "#78172B"
                  : context.datasetIndex === 2
                    ? "#70611D"
                    : context.datasetIndex === 3
                      ? "#216D49"
                      : context.datasetIndex === 4
                        ? "#194B6C"
                        : "#fff";
          },
          display: function (context: any) {
            return telaTablet
              ? false
              : context.dataset.data[context.dataIndex] > 0;
          },

          font: {
            weight: "bold",
          },
          formatter: function (value: number) {
            return MascaraGrafico(value);
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          type: "logarithmic",
          ticks: {
            color: "#495057",
            maxTicksLimit: 9,
            callback: function (value: number) {
              return MascaraGrafico(value);
            },
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    setBasicOptions(basicOptions);

    return {
      basicOptions,
    };
  };

  function setaDadosTwitter(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTwitter" &&
        publicacao.status === "Aprovado"
      ) {
        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaTwitter = somaTwitter + 1;
      }
    });

    setTwitter(somaTwitter);

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosInsta(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoInstagramReels" ||
          publicacao.tipo === "PublicacaoInstagramFeed" ||
          publicacao.tipo === "PublicacaoInstagramStories") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaInstagram = somaInstagram + 1;
      }
    });

    setInstagram(somaInstagram);

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];
    return dataArray;
  }

  function setaDadosTiktok(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTikTok" &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.views);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotal);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotais);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaTiktok = somaTiktok + 1;
      }
    });

    setTiktok(somaTiktok);

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosYoutube(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoYouTube" ||
          publicacao.tipo === "PublicacaoYouTubeShorts") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaYoutube = somaYoutube + 1;
      }
    });

    setYoutube(somaYoutube);

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosMediaETotais(publicacoes: PublicacoesProps[]) {
    let mediaArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaFavoritos: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaDislikes: number = 0;
    let somaRetweets: number = 0;
    let somaSalvos: number = 0;

    let totalAprovados: number = 0;

    publicacoes.map((publicacao) => {
      if (publicacao.status === "Aprovado") {
        totalAprovados = totalAprovados + 1;
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.views);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaFavoritos = somaMetrica(somaFavoritos, publicacao.favoritos);

        somaCompartilhamentos = somaMetrica(
          somaCompartilhamentos,
          publicacao.compartilhamentos
        );

        somaDislikes = somaMetrica(somaDislikes, publicacao.dislikes);

        somaRetweets = somaMetrica(somaRetweets, publicacao.retweets);

        somaSalvos = somaMetrica(somaSalvos, publicacao.salvos);
      }
    });

    setAlcanceTotal(somaAlcance);
    setImpressoesTotal(somaImpressoes);
    setEngajamentoTotal(somaEngajamento);
    setLikesTotal(somaLikes);
    setComentariosTotal(somaComentarios);
    setFavoritosTotal(somaFavoritos);
    setCompartilhamentosTotal(somaCompartilhamentos);
    setDislikesTotal(somaDislikes);
    setRetweetsTotal(somaRetweets);
    setSalvosTotal(somaSalvos);

    mediaArray = [
      somaAlcance / totalAprovados,
      somaImpressoes / totalAprovados,
      somaEngajamento / totalAprovados,
      somaLikes / totalAprovados,
      somaComentarios / totalAprovados,
    ];

    return mediaArray;
  }

  return {
    setaDados,
    getLightTheme,
  };
};
