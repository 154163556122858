import { useContext } from "react";

import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";
import { putCampanha } from "../../../services/campanha";

export interface FuncoesModalEditarCampanhaProps {
  idCampanha: string;
  nomeCampanha: string;
  setNomeCampanha: React.Dispatch<React.SetStateAction<string>>;
  dataInicio: string;
  dataFim: string;
  setDatas: React.Dispatch<React.SetStateAction<Date | Date[] | undefined>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  recarregar: boolean;
  setModalAberto: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesModalEditarCampanha = ({
  idCampanha,
  nomeCampanha,
  setNomeCampanha,
  dataInicio,
  dataFim,
  setDatas,
  setCarregando,
  setRecarregar,
  recarregar,
  adicionarAListaEsperaRenovacaoToken,
  setModalAberto,
}: FuncoesModalEditarCampanhaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = (mensagem: string) => {
    mostrarToast("success", "Campanha atualizada", mensagem);
    LimparStates([setNomeCampanha, setDatas]);
    setCarregando(false);
    setRecarregar(!recarregar);
    setModalAberto(false);
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      mensagem
    );
  };

  function editarCampanha() {
    setCarregando(true);

    const { token, tokenRenovacao } = obterTokens();

    putCampanha(token, {
      comentarios: null,
      id: idCampanha,
      dataFim: dataFim,
      dataInicio: dataInicio,
      nome: nomeCampanha,
    })
      .then((res) => {
        sucessoRequisicao(res.data.mensagem)
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putCampanha(token, {
              comentarios: null,
              id: idCampanha,
              dataFim: dataFim,
              dataInicio: dataInicio,
              nome: nomeCampanha,
            })
              .then((res2) => {
                sucessoRequisicao(res2.data.mensagem)
              })
              .catch((err2) => {
                erroRequisicao(
                  err2.response.data.mensagem
                    ? err2.response.data.mensagem
                    : err2.response.data.message);
              });
          }, setCarregando);
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message);
          setCarregando(false);
        }
      });
  }

  return {
    editarCampanha,
  };
};
