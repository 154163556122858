import { tarefaProps } from '../../../../../../../services/tarefas';
import styles from "./styles.module.css";
import { format } from '../../../../../../../utils/MascaraNumero/format';
import { IoTrendingDownSharp, IoTrendingUpSharp } from 'react-icons/io5';
import { BotaoColunaAcoes } from '../../../../../../Botoes/BotaoColunaAcoes';
import { AvatarUsuario } from '../../Avatar';
import { BodyTemplate } from '../../../../../@Templates/Body';
import { obterCredenciais } from '../../../../../../../utils/LocalStorage';

interface BodyProps {
  taxaEngajamentoCampanha: number;
  setTarefaSelecionada: React.Dispatch<React.SetStateAction<tarefaProps>>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setModalDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  setModalEditarInfluenciado: React.Dispatch<React.SetStateAction<boolean>>;
  relatorio?: boolean;
}

export const Body = ({ taxaEngajamentoCampanha, setTarefaSelecionada, setModalAberto, setModalDesativacao, setModalEditarInfluenciado, relatorio }: BodyProps) => {
  const {
    credenciais: { perfil },
  } = obterCredenciais();

  const BodyInfluenciador = (rowData: tarefaProps) => {
    const influenciador = rowData.influenciador

    const handleEdicao = () => {
      setTarefaSelecionada(rowData);
      setModalEditarInfluenciado(true)
    }

    return (
      <BodyTemplate classesAdicionais={`${styles.containerInfluenciador}`}>

        
        {!relatorio &&
          <AvatarUsuario
            nomeUsuario={influenciador.nome}
            avatarUrl={influenciador.urlAvatar} />
        }
        <p className={`${styles.info} ${!relatorio && styles.influenciador}`}>
          {influenciador.nome}
        </p>
        {
          (perfil[0] === "UsuarioNetcos" ||
            perfil[0] === "Administrador") && !relatorio &&
          <button className={styles.botaoEdicao} onClick={() => handleEdicao()}>
            <i
              className={`pi pi-pencil ${styles.iconEditar}`}
              style={{ fontSize: "1.4rem", paddingRight: "1rem" }}
              title={"Editar Usuário"}
            />
          </button>
        }
      </BodyTemplate>
    )
  }

  const BodyAlcance = (rowData: tarefaProps) => {
    const alcance = rowData.alcanceTotalDaTarefa
    return (
      <BodyTemplate>
        <p className={`${styles.info} ${!relatorio && styles.alcance}`}>
          {
            format("#.###.##0,#0", alcance).replace(
              ",00",
              "")
          }
        </p>
      </BodyTemplate>
    )
  }

  const BodyEngajamento = (rowData: tarefaProps) => {
    const engajamento = rowData.engajamentoTotalDaTarefa
    return (
      <BodyTemplate>
        <p className={`${styles.info}`}>
          {
            format("#.###.##0,#0", engajamento).replace(
              ",00",
              "")
          }
        </p>
      </BodyTemplate>
    )
  }

  const BodyTaxaEngajamento = (rowData: tarefaProps) => {
    const taxaEngajamento = rowData.taxaEngajamento
    return (
      <BodyTemplate>
        <p className={`${styles.info}`}>
          {
            format("#.###.##0,#0%", taxaEngajamento)
          }
        </p>
      </BodyTemplate>
    )
  }
  const BodyComparativoCampanha = (rowData: tarefaProps) => {
    const taxaEngajamentoTarefa = rowData.taxaEngajamento
    const comparativo = taxaEngajamentoTarefa - taxaEngajamentoCampanha;
    const positivo = comparativo > 0;
    return (
      <BodyTemplate
        classesAdicionais={`${positivo ? styles.positivo : styles.negativo}`}
      >
        <p className={`${styles.info}`}>
          {
            format("#.###.##0,#0%", comparativo)
          }
          <span className={styles.containerIcon}>
            {positivo ? <IoTrendingUpSharp /> : <IoTrendingDownSharp />}
          </span>
        </p>
      </BodyTemplate>
    )
  }

  const BodyCPE = (rowData: tarefaProps) => {
    const CPE = rowData.custoPorEngajamento
    return (
      <BodyTemplate>
        <p className={`${styles.info}`}>
          {
            format("R$ #.###.##0,#0", CPE)
          }
        </p>
      </BodyTemplate>
    )
  }

  const BodyCPM = (rowData: tarefaProps) => {
    const CPM = rowData.custoPorMilImpressoes
    return (
      <BodyTemplate>
        <p className={`${styles.info}`}>
          {
            format("R$ #.###.##0,#0", CPM)
          }
        </p>
      </BodyTemplate>
    )

  }
  const BodyValorContrato = (rowData: tarefaProps) => {
    const valorContrato = rowData.valorContrato

    return (
      <BodyTemplate>
        <p className={`${styles.info}`}>
          {
            format("R$ #.###.##0,#0", valorContrato)
          }
        </p>
      </BodyTemplate>
    )
  }

  const BodyEntregas = (rowData: tarefaProps) => {
    const publicacoes = rowData.publicacoes;
    const entregues = setaEntregue();

    function setaEntregue() {
      let somaEntregue: number = 0;

      publicacoes.map((publicacao) => {
        if (publicacao.status === "Aprovado") {
          somaEntregue = somaEntregue + 1;
        }
      });

      return somaEntregue;
    }

    return (
      <BodyTemplate
        title={entregues + " / " + publicacoes.length + " publicações"}
      >
        <p className={`${styles.info}`}>
          {entregues + " / " + publicacoes.length}
        </p>
      </BodyTemplate>
    )
  }

  const BodyAcoes = (rowData: tarefaProps) => {


    const handleEdicao = () => {
      setTarefaSelecionada(rowData);
      setModalAberto(true)
    }

    const handleExclusao = () => {
      setTarefaSelecionada(rowData);
      setModalDesativacao(true)
    }

    return (
      <BodyTemplate>
        <BotaoColunaAcoes
          icone={"pi-pencil"}
          titulo={"Editar Valor de Contrato"}
          onClick={() => handleEdicao()}
        />

        <BotaoColunaAcoes
          icone={"pi-trash"}
          titulo={"Remover Influenciador"}
          onClick={() => handleExclusao()}
        />
      </BodyTemplate>
    )
  }


  return {
    BodyInfluenciador,
    BodyAlcance,
    BodyEngajamento,
    BodyTaxaEngajamento,
    BodyComparativoCampanha,
    BodyCPE,
    BodyCPM,
    BodyValorContrato,
    BodyEntregas,
    BodyAcoes
  }
}