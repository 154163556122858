import React, { useContext, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesCaixaFlutuanteAtualizarTermos } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { tipoDocumento } from "../../../services/documentosLegais";
import { SelectTipoDocumento } from "../../Select/SelectTipoDocumento";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

import { Toast } from "primereact/toast";

interface CaixaFlutuanteAtualizarAgenciaProps {
  caixaAbertaTermos: boolean;
  setCaixaAbertaTermos: React.Dispatch<boolean>;
  setRecarregarTudo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CaixaFlutuanteAtualizarTermos = ({
  caixaAbertaTermos,
  setCaixaAbertaTermos,
  setRecarregarTudo,
}: CaixaFlutuanteAtualizarAgenciaProps) => {
  const [carregando, setCarregando] = useState<boolean>(false);

  const [arquivo, setArquivo] = useState<string>("");
  const [nomeArquivo, setNomeArquivo] = useState<string>("");
  const [tipo, setTipo] = useState<tipoDocumento>("TermosDeUso");
  const [requerRenovacaoAceite, setRequerRenovacaoAceite] =
    useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const myToast = useRef<any>(null);

  const { atualizarTermos, fazConverteArquivoPara64 } =
    FuncoesCaixaFlutuanteAtualizarTermos({
      arquivo,
      myToast,
      requerRenovacaoAceite,
      setArquivo,
      setCarregando,
      setRequerRenovacaoAceite,
      setTipo,
      tipo,
      setNomeArquivo,
      setRecarregarTudo,
      setCaixaAbertaTermos,
      adicionarAListaEsperaRenovacaoToken,
    });

  return (
    <div className={styles.conteudoCaixaFlutuanteAtualizarTermos}>
      <div className={styles.containerInputs}>
        <SelectTipoDocumento setTipo={setTipo} tipo={tipo} />
      </div>
      <input
        required
        id={styles.fileUpload}
        type="file"
        accept=".pdf"
        onChange={(e) => {
          fazConverteArquivoPara64(e);
        }}
      />
      <label className={styles.labelInput} htmlFor={styles.fileUpload}>
        <div className={styles.fileUpload}>
          <i
            className="pi pi-upload"
            style={{
              fontSize: "2rem",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
            }}
          ></i>
          <p>{nomeArquivo !== "" ? nomeArquivo : "Novo documento"}</p>
        </div>
      </label>
      <div className={styles.containerInputs}>
        <input
          id="reaceitar-termos"
          type="checkbox"
          onClick={() => {
            setRequerRenovacaoAceite(!requerRenovacaoAceite);
          }}
        />
        <label className={styles.labelCheckBox} htmlFor="reaceitar-termos">
          A alteração afeta os direitos ou deveres dos usuários e deve solicitar
          novo aceite?
        </label>
      </div>

      {carregando ? (
        <Carregando />
      ) : (
        <div className={styles.containerBotoesModal}>
          <Button
            importancia="primario"
            title="Confirmar"
            onClick={atualizarTermos}
          />
          <Button
            type="button"
            importancia="secundario"
            title="Cancelar"
            onClick={() => setCaixaAbertaTermos(!caixaAbertaTermos)}
          />
        </div>
      )}

      <Toast ref={myToast} className="custom-toast" />
    </div>
  );
};
