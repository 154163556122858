import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CredenciaisContext } from "../../../context/CredenciaisContext";
import { obterCredenciais } from "../../../utils/LocalStorage";
import logo from "../../../assets/img/netcos-logo.png";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";

import "primereact/resources/themes/md-light-deeppurple/theme.css";
import styles from "./styles.module.css";
import { credenciais } from "../../../services/usuario";

export const DrawerNavbar = () => {
  const credenciais: credenciais = obterCredenciais().credenciais;

  const avatar = credenciais.urlAvatar;
  const nome = credenciais.nome;
  const perfil = credenciais.perfil[0];
  const [mostrarDrawer, setMostrarDrawer] = useState(false);
  const navigate = useNavigate();
  const removerCredenciais = useContext(CredenciaisContext).removerCredenciais;

  return (
    <>
      <Button
        id={styles.botaoAbrirDrawer}
        icon="pi pi-bars"
        onClick={() => setMostrarDrawer(true)}
        title="Menu Lateral"
      />
      <Sidebar
        id={styles.drawer}
        visible={mostrarDrawer}
        onHide={() => setMostrarDrawer(false)}
      >
        <header>
          <div
            id={styles.containerLogo}
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            <img src={logo} alt="Logo NetCos" className={styles.logoDrawer} />
            <p>NetCos</p>
          </div>
          <div
            className={styles.containerAvatarDrawer}
            onClick={() => {
              setMostrarDrawer(false);
              navigate("/perfil-usuario");
            }}
          >
            <Avatar
              className={styles.avatar}
              image={avatar ? avatar : undefined}
              title="Exibir Perfil"
              shape="circle"
              size="xlarge"
              icon="pi pi-user"
            >
              <p>
                Exibir
                <br />
                Perfil
              </p>
            </Avatar>
            <h1 title="Exibir Perfil">{nome}</h1>
          </div>
        </header>

        <ul className={styles.listaPaginasDrawer}>
          <header>
            <li
              className={styles.paginasDrawerItem}
              onClick={() => {
                setMostrarDrawer(false);
                navigate(
                  perfil == "Administrador" || perfil == "UsuarioNetcos"
                    ? "/usuariosNetcos/listar-campanhas"
                    : "/home"
                );
              }}
              title="Lista de Campanhas"
            >
              <i
                className="pi pi-images"
                style={{ fontSize: "2rem", padding: "0 2rem" }}
              ></i>
              Campanhas
            </li>

            {(perfil == "Administrador" || perfil == "UsuarioNetcos") && (
              <>
                <li
                  className={styles.paginasDrawerItem}
                  onClick={() => {
                    setMostrarDrawer(false);
                    navigate("/usuariosNetcos/listar-usuarios");
                  }}
                  title="Listas de Usuários"
                >
                  <i
                    className="pi pi-users"
                    style={{ fontSize: "2rem", padding: "0 2rem" }}
                  ></i>
                  Usuários
                </li>
                <li
                  className={styles.paginasDrawerItem}
                  onClick={() => {
                    setMostrarDrawer(false);
                    navigate("/usuariosNetcos/listar-agencias");
                  }}
                  title="Lista de Agências"
                >
                  <i
                    className="pi pi-briefcase"
                    style={{ fontSize: "2rem", padding: "0 2rem" }}
                  ></i>
                  Agências
                </li>
                <li
                  className={styles.paginasDrawerItem}
                  onClick={() => {
                    setMostrarDrawer(false);
                    navigate("/usuariosNetcos/listar-marcas");
                  }}
                  title="Lista de Marcas"
                >
                  <i
                    className="pi pi-building"
                    style={{ fontSize: "2rem", padding: "0 2rem" }}
                  ></i>
                  Marcas
                </li>
              </>
            )}
          </header>

          <footer>
            <li
              className={styles.paginasDrawerFooterItem}
              onClick={() => {
                setMostrarDrawer(false);
                navigate(
                  perfil == "Administrador" || perfil == "UsuarioNetcos"
                    ? "/usuarioNetcos/termos-de-aceite"
                    : "/termos-de-aceite"
                );
              }}
              title="Termos de Aceite"
            >
              <i
                className="pi pi-file-o"
                title="Termos de Uso"
                style={{ fontSize: "1.8rem", padding: "0 2rem" }}
              ></i>
              Termos de Aceite
            </li>
            <li
              className={styles.paginasDrawerFooterItem}
              onClick={() => {
                setMostrarDrawer(false);
                window.location.replace("https://netcos.art.br/contact/");
              }}
              title="Contato"
            >
              <i
                className="pi pi-question-circle"
                title="Contato"
                style={{ fontSize: "1.8rem", padding: "0 2rem" }}
              ></i>
              Contato
            </li>
            <li
              className={styles.paginasDrawerFooterItem}
              onClick={() => {
                setMostrarDrawer(false);
                removerCredenciais();
                navigate("/");
              }}
              title="Sair"
            >
              <i
                className="pi pi-sign-out"
                title="Sair"
                style={{ fontSize: "1.8rem", padding: "0 2rem" }}
              ></i>
              Sair
            </li>
          </footer>
        </ul>
      </Sidebar>
    </>
  );
};
