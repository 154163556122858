import React from "react";
import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  getInfluenciadores,
  getInfluenciadoresTodosNome,
  getInfluenciadorProps,
} from "../../../services/influenciador";
import { limpaLocalStorage, obterTokens } from "../../../utils/LocalStorage";

interface FunctionsObterInfluenciadoresProps {
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setListaInfluenciadores: React.Dispatch<getInfluenciadorProps[]>;
  setAbrirModalDeslogar: React.Dispatch<React.SetStateAction<boolean>>;
  setInfluenciadoresTotais: React.Dispatch<number>;
  nome: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsObterInfluenciadores = ({
  nome,
  numeroPagina,
  tamanhoPagina,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
  setErro,
  setCarregando,
  setListaInfluenciadores,
  setAbrirModalDeslogar,
  setInfluenciadoresTotais,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsObterInfluenciadoresProps) => {
  function obterTodos() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getInfluenciadores(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaInfluenciadores(res.data.influenciadores);
        setInfluenciadoresTotais(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadores(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaInfluenciadores(res.data.influenciadores);
                setInfluenciadoresTotais(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de influenciadores.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de influenciadores.",
          });
          setCarregando(false);
        }
      });
  }

  function obterTodosPorNome() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();
    getInfluenciadoresTodosNome(
      token,
      nome,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaInfluenciadores(res.data.influenciadores);
        setInfluenciadoresTotais(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadores(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaInfluenciadores(res.data.influenciadores);
                setInfluenciadoresTotais(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de influenciadores.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de influenciadores.",
          });
          setCarregando(false);
        }
      })
      .finally(() => setCarregando(false));
  }

  return {
    obterTodos,
    obterTodosPorNome,
  };
};
