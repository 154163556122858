import styles from "./styles.module.css";

import { ModalTeste } from "../ModalTeste";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../../components/Carregando";

interface ModalConfirmacaoProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  onClickConfirm: () => void;
  carregando: boolean;
  nomeCampanha: string;
}

export const ModalConfirmacao = ({
  modalAberto,
  setModalAberto,
  onClickConfirm,
  carregando,
  nomeCampanha,
}: ModalConfirmacaoProps) => {
  return (
    <ModalTeste
      titulo="Finalizar Campanha"
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
    >
      <div className={styles.container}>
        <h1 className={styles.title}>
          Tem certeza que deseja finalizar campanha {nomeCampanha}?
        </h1>
        <h2 className={styles.subtitle}>
          Ao confirmar um e-mail será enviado para todos os participantes da
          campanha.
        </h2>
      </div>
      <div className={styles.buttonContainer}>
        {carregando ? (
          <Carregando />
        ) : (
          <>
            <Button
              title="Confirmar"
              importancia="primario"
              onClick={onClickConfirm}
            />
            <Button
              title="Cancelar"
              importancia="secundario"
              onClick={() => setModalAberto(false)}
            />
          </>
        )}
      </div>
    </ModalTeste>
  );
};
