import React, { HTMLProps, useState } from "react";
import { Input } from "../../../Input";

import styles from "./styles.module.css";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";


interface InputDeEmailProps extends HTMLProps<HTMLInputElement> {
  value: string;
  onEmailChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onclick: () => void;
  inputAtivo: boolean
}

export const InputDeEmail = ({
  onEmailChange,
  onclick,
  value,
  inputAtivo
}: InputDeEmailProps) => {

  return (
    <div className={styles.containerInput}>
      <>
        <div className={styles.inputEmail}>
          <Input
            disabled={inputAtivo}
            placeholder="Email"
            value={value}
            required
            onChange={onEmailChange}
            // style={{
            //   color: inputAtivo ? 'var(--roxo-300)' : 'initial', 
            //   opacity: inputAtivo ? 1 : 0.5
            // }}
          />
        </div>
        <button className={styles.iconeCheck}>
          <i
            className="pi pi-pencil"
            style={{ 
              fontSize: "1.4rem", 
              paddingRight: "1rem",
              // color: inputAtivo === false ? 'var(--roxo-200)' : 'var(--roxo-300)', 
              // opacity: inputAtivo === false ? 1 : 0.5
            
            }}
            onClick={onclick}
            title="Editar Email"
          />
        </button>
      </>
    </div>
  );
};
