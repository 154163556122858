import React, { useState, useEffect, useContext } from "react";

import { FunctionsPostarPrintSentimento } from "./functions";

import styles from "./styles.module.css";

import { UploadDragAndDrop } from "../../UploadDragAndDrop";
import { ModalTeste } from "../ModalTeste";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Prints } from "../../../services/publicacao";

import { TabPanel, TabView } from "primereact/tabview";
import { useParams } from "react-router-dom";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ModalAdcRepercussão {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  idTarefa: string;
  printSentimento: Prints[];
  setPrintsSentimento: React.Dispatch<React.SetStateAction<Prints[]>>;
}

export const ModalAdcRepercussão = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
  idTarefa,
  printSentimento,
  setPrintsSentimento,
}: ModalAdcRepercussão) => {
  const { idCampanha } = useParams();

  const [printsPositivos, setPrintsPositivos] = useState<File[]>([]);
  const [printsNeutros, setPrintsNeutros] = useState<File[]>([]);
  const [printsNegativos, setPrintsNegativos] = useState<File[]>([]);

  const [carregando, setCarregando] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { postarPrintRepercussao, limpaImagens } =
    FunctionsPostarPrintSentimento({
      setCarregando,
      printsPositivos,
      printsNeutros,
      printsNegativos,
      setPrintsPositivos,
      setPrintsNeutros,
      setPrintsNegativos,
      printSentimento,
      setPrintsSentimento,
      setModalAberto,
      setRecarregar,
      idCampanha: idCampanha!,
      idTarefa,
      adicionarAListaEsperaRenovacaoToken,
    });

  useEffect(() => {
    limpaImagens();
  }, []);

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Comentários da Publicação"}
    >
      <div className={styles.conteudoModalAdcRepercussao}>
        <div id={styles.containerTabView}>
          <TabView activeIndex={activeIndex} renderActiveOnly={false}>
            <TabPanel header="Positivo">
              <UploadDragAndDrop
                carregando={carregando}
                prints={printsPositivos}
                setPrints={setPrintsPositivos}
              />
            </TabPanel>
            <TabPanel header="Neutro">
              <UploadDragAndDrop
                carregando={carregando}
                prints={printsNeutros}
                setPrints={setPrintsNeutros}
              />
            </TabPanel>
            <TabPanel header="Negativo">
              <UploadDragAndDrop
                carregando={carregando}
                prints={printsNegativos}
                setPrints={setPrintsNegativos}
              />
            </TabPanel>
          </TabView>
        </div>

        <footer>
          <div className={styles.containerBotoesModal}>
            {carregando ? (
              <Carregando botao />
            ) : (
              <Button
                onClick={postarPrintRepercussao}
                importancia="secundario"
                title="Adicionar"
              />
            )}
          </div>
        </footer>
      </div>
    </ModalTeste>
  );
};
