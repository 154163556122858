import { AxiosResponse } from "axios";
import { tipoOrdenacao } from "../@types/ordenacao";
import { api } from "./api"

export type tipoDocumento = "TermosDeUso" | "PoliticaDePrivacidade" | "PoliticaDeConteudo"

export interface postDocumentosLegaisProps {
    dataCriacao: string;
    arquivo: string;
    requerRenovacaoAceite: boolean;
    tipo: tipoDocumento
    idUsuarioResponsavel: string;
    token: string;
}

export interface getDocumentosLegaisProps {
    codigo: number;
    totalRegistros: number;
    documentos: Documentos[];
}
export interface Documentos {
    id: string;
    usuarioResponsavel: UsuarioResponsavel;
    tipo: string;
    dataCriacao: string;
    urlArquivo: string;
    requerRenovacaoAceite: boolean;
    ativo: boolean;
}

export interface UsuarioResponsavel {
    idCredenciais: string;
    nome: string;
    telefone: number;
    status: string;
    urlAvatar?: string;
}


export const postDocumentosLegais = ({
    token,
    arquivo,
    dataCriacao,
    idUsuarioResponsavel,
    requerRenovacaoAceite,
    tipo,
}: postDocumentosLegaisProps) => {

    let url = `/DocumentosLegais`

    let documentoLegal = {
        arquivo,
        dataCriacao,
        idUsuarioResponsavel,
        requerRenovacaoAceite,
        tipo
    }

    return api.post(url, { documentoLegal }, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
}

export const getDocumentosLegais = (
    tipo: tipoDocumento,
    numeroPagina: number,
    tamanhoPagina: number,
    tipoOrdenacao: tipoOrdenacao,
    nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<getDocumentosLegaisProps, any>> => {
    let url = `/DocumentosLegais?Tipo=${tipo}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`

    return api.get(url)
};