export function MascaraTelefone(
  e: string,
  setTelefone?: React.Dispatch<React.SetStateAction<number>>,
  setMascaraTelefone?: (value: React.SetStateAction<string>) => void
) {
  e = e.replace(/\D/g, "");
  setTelefone && setTelefone(Number(e));
  e = e.replace(/^(\d{2})(\d)/g, "($1) $2");
  e = e.replace(/(\d{5})(\d)/, "$1-$2");
  setMascaraTelefone && setMascaraTelefone(e);
  return e;
}
