import { Routes, Route } from "react-router-dom";

import { Login } from "../pages/Login";

import { RedefinirSenha } from "../pages/RedefinirSenha";
import { ConfirmacaoDeEmail } from "../pages/ConfirmacaoDeEmail";
import { CampanhaInfluenciadorDetalhes } from "../pages/Influenciador/CampanhaInfluenciadorDetalhes";

import { CadastroTemplateEmail } from "../pages/UsuarioNetcos/CadastroTemplateEmail";
import { useContext } from "react";
import { CredenciaisContext } from "../context/CredenciaisContext";
import { Navbar } from "../components/Navbar";
import { PaginaNaoEncontrada } from "../pages/PaginaNaoEncontrada";
import { CadastroInfluenciador } from "../pages/UsuarioNetcos/CadastroInfluenciador";
import { ListarUsuariosNetcos } from "../components/Listas/ListarUsuarioNetcos";
import { ListarInfluenciador } from "../components/Listas/ListarInfluenciador";
import { DetalhesCampanha } from "../pages/UsuarioNetcos/CampanhaDetalhes";
import { ListarTodosUsuarios } from "../components/Listas/ListarTodosUsuarios";
import { ListarAgencias } from "../pages/UsuarioNetcos/ListarAgencias";
import { ListarTodasCampanhas } from "../pages/UsuarioNetcos/ListarTodasCampanhas";
import { ListarMarcas } from "../pages/UsuarioNetcos/ListarMarcas";
import { CampanhaPorMarcasDetalhes } from "../pages/UsuarioMarca/CampanhaPorMarcasDetalhes";
import { DocumentosLegais } from "../pages/UsuarioNetcos/DocumentosLegais";
import { AceiteDeTermos } from "../pages/AceiteDeTermos";
import { PerfilUsuario } from "../pages/PerfilUsuario";
import { ListarCampanhasInfluenciador } from "../pages/Influenciador/ListagemCampanhaInfluenciador";
import { TermosDeAceite } from "../pages/TermosDeAceite";
import { ListarCampanhasMarca } from "../pages/UsuarioMarca/ListaCampanhasMarcas";
import { ListagemCampanhaUsuarioAgencia } from "../pages/UsuarioAgencia/ListagemCampanhaUsuarioAgencia";

export const Rotas = () => {
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/redefinicao-de-senha" element={<RedefinirSenha />} />
      <Route path="/confirmacao-email" element={<ConfirmacaoDeEmail />} />
      <Route path="/aceite-de-termos" element={<AceiteDeTermos />} />

      {perfil && (
        <>
          <Route
            path="/perfil-usuario"
            element={
              <>
                <Navbar />
                <PerfilUsuario />
              </>
            }
          />
          <Route
            path="/termos-de-aceite"
            element={
              <>
                <Navbar />
                <TermosDeAceite />
              </>
            }
          />
        </>
      )}

      {perfil === "Influenciador" && (
        <>
          <Route
            path="/home/:IdCredenciais"
            element={
              <>
                <Navbar />
                <ListarCampanhasInfluenciador />
              </>
            }
          />
          <Route
            path="/home/"
            element={
              <>
                <Navbar />
                <ListarCampanhasInfluenciador />
              </>
            }
          />
          <Route
            path="/CampanhaDetalhesInfluenciador/:idCampanha/:idTarefas"
            element={
              <>
                <Navbar />
                <CampanhaInfluenciadorDetalhes />
              </>
            }
          />
        </>
      )}
      {perfil === "UsuarioAgencia" && (
        <>
          <Route
            path="/home/"
            element={
              <>
                <Navbar />
                <ListagemCampanhaUsuarioAgencia />
              </>
            }
          />
          <Route
            path="/usuario-agencia/detalhes-campanha/:idCampanha"
            element={
              <>
                <Navbar />
                <DetalhesCampanha />
              </>
            }
          />
          <Route
            path="/CampanhaPorAgenciaDetalhes/:idCampanha"
            element={
              <>
                <Navbar />
                <CampanhaPorMarcasDetalhes />
              </>
            }
          />
        </>
      )}
      {perfil === "UsuarioMarca" && (
        <>
          <Route
            path="/home"
            element={
              <>
                <Navbar />
                <ListarCampanhasMarca />
              </>
            }
          />
          <Route
            path="/CampanhaPorMarcaDetalhes/:idCampanha"
            element={
              <>
                <Navbar />
                <CampanhaPorMarcasDetalhes />
              </>
            }
          />
        </>
      )}
      {(perfil === "UsuarioNetcos" || perfil === "Administrador") && (
        <>
          <Route
            path="/home"
            element={
              <>
                <Navbar />
                <ListarTodasCampanhas />
              </>
            }
          />
          <Route
            path="/home/:IdCredenciais"
            element={
              <>
                <Navbar />
                <ListarTodasCampanhas />
              </>
            }
          />

          <Route
            path="/usuarioNetcos/termos-de-aceite"
            element={
              <>
                <Navbar />
                <DocumentosLegais />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/cadastro-de-influenciador"
            element={
              <>
                <Navbar />
                <CadastroInfluenciador />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-usuarios-netcos"
            element={
              <>
                <Navbar />
                <ListarUsuariosNetcos />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-influenciador"
            element={
              <>
                <Navbar />
                <ListarInfluenciador />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-usuarios"
            element={
              <>
                <Navbar />
                <ListarTodosUsuarios />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-agencias"
            element={
              <>
                <Navbar />
                <ListarAgencias />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-marcas"
            element={
              <>
                <Navbar />
                <ListarMarcas />
              </>
            }
          />
          <Route
            path="/usuariosNetcos/listar-campanhas"
            element={
              <>
                <Navbar />
                <ListarTodasCampanhas />
              </>
            }
          />

          <Route
            path="/usuariosNetcos/cadastro-template-email"
            element={
              <>
                <Navbar />
                <CadastroTemplateEmail />
              </>
            }
          />

          <Route
            path="/usuariosNetcos/detalhes-campanha/:idCampanha"
            element={
              <>
                <Navbar />
                <DetalhesCampanha />
              </>
            }
          />

        </>
      )}

      <Route path="/*" element={<PaginaNaoEncontrada />} />
    </Routes>
  );
};
