import { SetStateAction, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarInfluenciador } from "./functions";

import { getInfluenciadorProps } from "../../../services/influenciador";
import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ModalAtualizarInfluenciador } from "../../Modais/ModalAtualizarInfluenciador";
import { ModalCadastroInfluenciador } from "../../Modais/ModalCadastroInfluenciador";
import { ModalRedefinicao } from "../../Modais/ModalRedefinicao";
import { ErroTabela } from "../../ErroTabela";
import { TituloColuna } from "../../TituloColuna";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { CardUsuarioInfluenciador } from "../../Cards/CardUsuarioInfluenciador";
import { ModalReenvioSenha } from "../../Modais/ModalReenvioSenha";

import { Avatar } from "primereact/avatar";
import { getActiveFormattingMask, parseCountry, usePhoneInput } from 'react-international-phone';

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { BsRecordCircleFill } from "react-icons/bs";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { guessCountryByPartialPhoneNumber } from "react-international-phone";

export interface TabelaListarInfluenciadorProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  nomeBusca: string;
  setNomeBusca: React.Dispatch<React.SetStateAction<string>>;
  listaInfluenciadores: getInfluenciadorProps[];
  // paginacao: any;
  // seletorDeQuantidade: any;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  carregando: boolean;
  erro: ErroType;
  setErro: React.Dispatch<ErroType>;
  setRecarregar: () => void;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
}

export const TabelaListarInfluenciadores = ({
  tituloTabela,
  tituloColuna,
  listaInfluenciadores,
  // paginacao,
  // seletorDeQuantidade,
  tipoOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  nomeBusca,
  setNomeBusca,
  carregando,
  erro,
  setRecarregar,
  setCarregando,
  myToast,
  setErro,
}: TabelaListarInfluenciadorProps) => {
  const [
    modalAbertoCadastrarIncluenciador,
    setModalAbertoCadastrarIncluenciador,
  ] = useState<boolean>(false);
  const [modalRedefinicao, setModalRedefinicao] = useState<boolean>(false);
  const [modalReenvioSenha, setModalReenvioSenha] = useState<boolean>(false);
  const [modalEditarInfluenciador, setModalEditarInfluenciador] = useState<boolean>(false);
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
  const [modalAbertoDesativacao, setModalAbertoDesativacao] = useState<boolean>(false);
  const [influenciadorSelecionado, setInfluenciadorSelecionado] = useState<getInfluenciadorProps>();
  const [nomeInfluenciador, setNomeInfluenciador] = useState<string>("");
  const [idInfluenciador, setIdInflfuenciador] = useState<string>("");
  const [emailInfluenciador, setEmailInfluenciador] = useState<string>("");
  const [avatarInfluenciador, setAvatarInfluenciador] = useState<string>("");
  const [telefoneInfluenciador, setTelefoneInfluenciador] = useState<string>("");
  const [perfilInfluenciador, setPerfilInfluenciador] = useState<string>("");
  const [statusInfluenciador, setStatusInfluenciador] = useState<string>("");
  const [linkFacebookInfluenciador, setLinkFacebookInfluenciador] = useState<string>("");
  const [linkInstagramInfluenciador, setLinkInstagramInfluenciador] = useState<string>("");
  const [linkTiktokInfluenciador, setLinkTiktokInfluenciador] = useState<string>("");
  const [linkYouTubeInfluenciador, setLinkYouTubeInfluenciador] = useState<string>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    criaPDFListaDeInfluenciadores,
    abriModalEditar,
    abriModalRedefinicao,
    abriModalReenvioSenha,
    desativarInfluenciador,
    reativarUsuario,
  } = FunctionsTabelaListarInfluenciador({
    setInfluenciadorSelecionado,
    setModalEditarInfluenciador,
    setModalRedefinicao,
    setModalReenvioSenha,
    avatarInfluenciador,
    emailInfluenciador,
    idInfluenciador,
    nomeInfluenciador,
    perfilInfluenciador,
    linkFacebookInfluenciador,
    linkInstagramInfluenciador,
    linkTiktokInfluenciador,
    linkYouTubeInfluenciador,
    telefoneInfluenciador,
    myToast,
    setModalAbertoDesativacao,
    setCarregando,
    setRecarregar,
    adicionarAListaEsperaRenovacaoToken,
    setErro,
  });

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
              <div className={styles.botaoModalAdcMarca}>
                <Button
                  importancia="secundario"
                  onClick={() => setModalAbertoCadastrarIncluenciador(true)}
                  tooltip="Cadastrar Influenciador"
                >
                  {
                    <i
                      className="pi pi-plus-circle"
                      style={{ fontSize: "1.8rem" }}
                    />
                  }
                  Adicionar Usuário
                </Button>
                {modalAbertoCadastrarIncluenciador && (
                  <ModalCadastroInfluenciador
                    modalAberto={modalAbertoCadastrarIncluenciador}
                    setModalAberto={setModalAbertoCadastrarIncluenciador}
                    setRecarregar={setRecarregar}
                  />
                )}
              </div>
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                onChange={(e) => setNomeBusca(e.target.value)}
                value={nomeBusca}
              />
              <BotaoImprimir onClick={criaPDFListaDeInfluenciadores} />
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 1, 4]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>
      <tbody
        id="corpoTabelaListarInfluenciadores"
        className={styles.corpoTabela}
      >
        {carregando ? (
          <Carregando
            listaUsuarios={listaInfluenciadores.length}
            spinner={true}
          />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : listaInfluenciadores.length === 0 || carregando ? (
              <tr>
                <td style={{ justifyContent: "center" }}>
                  Nenhum resultado a ser exibido.
                </td>
              </tr>
            ) : (
              listaInfluenciadores.map((influenciador) => {
                return (
                  <tr key={influenciador.idCredenciais}>
                    <td className={styles.celulaComImagem}>
                      <Avatar
                        label={influenciador.nome.charAt(0)}
                        shape="circle"
                        size="xlarge"
                        image={influenciador.urlAvatar}
                        style={{
                          textTransform: "uppercase",
                          borderRadius: "50%",
                        }}
                      />
                      <p>{influenciador.nome}</p>
                    </td>
                    <td>{influenciador.email}</td>
                    <td>
                      {influenciador.telefone.toString()}
                    </td>

                    <td>{influenciador.perfil}</td>
                    <td>
                      <div
                        className={`${styles.status} ${styles[influenciador.status.toLowerCase()]
                          }`}
                      >
                        <BsRecordCircleFill
                          style={{
                            color: `${(() => {
                              switch (influenciador.status) {
                                case "Pendente":
                                  return "var(--laranja-500)";

                                case "Ativo":
                                  return "var(--verde-500)";

                                case "AguardandoRemocao":
                                  return "var(--laranja-700)";

                                case "Removido":
                                  return "var(cinza-400)";
                              }
                            })()}`,
                            fontSize: "1rem",
                          }}
                        />
                        {influenciador.status === "AguardandoRemocao"
                          ? "Aguardando Remoção"
                          : influenciador.status}
                      </div>
                    </td>

                    <td className="colunaBotoesAcoes">
                      <BotaoColunaAcoes
                        status={
                          influenciador.status === "AguardandoRemocao"
                            ? "Removido"
                            : influenciador.status
                        }
                        icone={"pi-pencil"}
                        titulo={"Editar Usuário"}
                        onClick={() => abriModalEditar(influenciador)}
                      />
                      {influenciador.status == "Pendente" ? (
                        <BotaoColunaAcoes
                          status={influenciador.status}
                          icone={"pi-send"}
                          titulo={"Reenviar email de Confirmação"}
                          onClick={() => abriModalReenvioSenha(influenciador)}
                        />
                      ) : (
                        <BotaoColunaAcoes
                          status={
                            influenciador.status === "AguardandoRemocao"
                              ? "Removido"
                              : influenciador.status
                          }
                          icone={"pi-lock"}
                          titulo={"Redefinir Senha"}
                          onClick={() => abriModalRedefinicao(influenciador)}
                        />
                      )}
                      <BotaoColunaAcoes
                        icone={
                          influenciador.status === "AguardandoRemocao"
                            ? "pi-user-plus"
                            : "pi-trash"
                        }
                        status={influenciador.status}
                        titulo={
                          influenciador.status == "AguardandoRemocao"
                            ? "Reativar Usuário"
                            : "Remover Usuário"
                        }
                        onClick={() => {
                          setModalAbertoDesativacao(true);
                          setIdInflfuenciador(influenciador.idCredenciais);
                          setNomeInfluenciador(influenciador.nome);
                          setAvatarInfluenciador(influenciador.urlAvatar);
                          setEmailInfluenciador(influenciador.email);
                          setTelefoneInfluenciador(influenciador.telefone.toString());
                          setPerfilInfluenciador(influenciador.perfil);
                          setStatusInfluenciador(influenciador.status);
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </>
        )}
        {modalAbertoDesativacao && (
          <ModalDesativacao
            modalAberto={modalAbertoDesativacao}
            setModalAberto={setModalAbertoDesativacao}
            nomeItemParaDesativar={nomeInfluenciador}
            status={statusInfluenciador}
            onClick={
              statusInfluenciador === "AguardandoRemocao"
                ? () => reativarUsuario()
                : () => desativarInfluenciador()
            }
          />
        )}
      </tbody>
      <div className={styles.listaCardsUsuario}>
        {listaInfluenciadores.length > 0 ? (
          listaInfluenciadores.map((influenciador, index) => {
            return (
              <CardUsuarioInfluenciador
                usuario={influenciador}
                setNomeUsuario={setNomeInfluenciador}
                setImagemAvatarUsuario={setAvatarInfluenciador}
                setTelefoneUsuario={setTelefoneInfluenciador}
                setIdUsuario={setIdInflfuenciador}
                setStatusUsuario={setStatusInfluenciador}
                setEmailUsuario={setEmailInfluenciador}
                setModalRedefinicao={abriModalRedefinicao}
                setModalReenvioSenha={abriModalReenvioSenha}
                setModalEditar={abriModalEditar}
                setModalDesativacao={setModalAbertoDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
      <tfoot className={styles.footerTabela}>
        {/* <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            {seletorDeQuantidade}
          </div>
          {paginacao}
        </div> */}
      </tfoot>
      {modalEditarInfluenciador && influenciadorSelecionado && (
        <ModalAtualizarInfluenciador
          instagramPrevio={influenciadorSelecionado.linkInstagram}
          twitterPrevio={influenciadorSelecionado.linkTwitter}
          youTubePrevio={influenciadorSelecionado.linkYoutube}
          tiktokPrevio={influenciadorSelecionado.linkTiktok}
          emailPrevio={influenciadorSelecionado.email}
          idCredenciais={influenciadorSelecionado.idCredenciais}
          imagemAvatarPrevia={influenciadorSelecionado.urlAvatar}
          perfilPrevio={influenciadorSelecionado.perfil}
          nomePrevio={influenciadorSelecionado.nome}
          telefonePrevio={influenciadorSelecionado.telefone}
          statusPrevio={influenciadorSelecionado.status}
          modalAberto={modalEditarInfluenciador}
          setModalAberto={setModalEditarInfluenciador}
          setRecarregar={setRecarregar}
        />
      )}
      {modalRedefinicao && influenciadorSelecionado && (
        <ModalRedefinicao
          modalAberto={modalRedefinicao}
          setModalAberto={setModalRedefinicao}
          emailPrevio={influenciadorSelecionado.email}
        />
      )}
      {modalReenvioSenha && influenciadorSelecionado && (
        <ModalReenvioSenha
          modalAberto={modalReenvioSenha}
          setModalAberto={setModalReenvioSenha}
          emailPrevio={influenciadorSelecionado.email}
        />
      )}
    </table>
  );
};
