//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { ErroType } from "../../../../@types/erro";
import { getBenchmarkTaxaEngajamento } from "../../../../services/benchmark";
import { tipoPublicacaoProps } from "../../../../services/campanha";

import { obterTokens } from "../../../../utils/LocalStorage";
import { format } from "../../../../utils/MascaraNumero/format";
import { base64RelatorioFinal } from "./imagensRelatorioBase64";
import { toJpeg, toPng } from 'html-to-image';
import { Options } from "html-to-image/lib/types";

interface gerarRelatorioFinalProps {
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  listaDePdfs: any;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  comentarioCampanha: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export async function gerarRelatorioFinal({
  listaDePdfs,
  setCarregando,
  setErro,
  comentarioCampanha,
  adicionarAListaEsperaRenovacaoToken,
}: gerarRelatorioFinalProps) {

  async function fetchAsDataURL<T>(
    url: string,
    init: RequestInit | undefined,
    process: (data: { result: string; res: Response }) => T,
  ): Promise<T> {
    const res = await fetch(url, init)
    if (res.status === 404) {
      throw new Error(`Resource "${res.url}" not found`)
    }
    const blob = await res.blob()
    return new Promise<T>((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onloadend = () => {
        try {
          resolve(process({ res, result: reader.result as string }))
        } catch (error) {
          reject(error)
        }
      }

      reader.readAsDataURL(blob)
    })
  }

  //----------------------------------------------------

  const config: Options = {
    quality: 1,
    width: 1770,
    height: 870,
    style: {
      fontSize: "25px",
      lineHeight: "35px",
      fontFamily: "Exo2",
    }
  }
  const dataUrls = [];
  const dataUrl: string = await fetchAsDataURL(`https://fonts.gstatic.com/s/exo2/v21/7cHmv4okm5zmbtYoK-4.woff2`, {}, ({ result }) => {
    return `
            @font-face {
              font-family: Exo2;
              font-style: normal;
              font-weight: normal;
              src: url(${result});
          }
          `
  });
  dataUrls.push(dataUrl)
  config.fontEmbedCSS = dataUrl;
  let tmp = document.createElement("DIV");
  tmp.innerHTML = comentarioCampanha ? comentarioCampanha.replaceAll("<br>", "") : "";
  // tmp.innerHTML = comentarioCampanha ? comentarioCampanha.replaceAll("<p>", `<p style='font-family: Exo2'>`) : "";
  const imagemComentarioNetcos = toPng(
    tmp, config)
    .then((dataUrl) => {
      return dataUrl;
    })

  //---------------------------------------------------------------

  const template: Template = {
    schemas: [
      {
        ComentarioNetcos: {
          type: "image",
          position: {
            x: 15,
            y: 29,
          },
          width: 310,
          height: 140,
        },
      },
      {
        InstaFeedExcelenteMicro: {
          type: "text",
          position: {
            x: 70,
            y: 50.27,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaFeedMediaMicro: {
          type: "text",
          position: {
            x: 76,
            y: 58.43,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaFeedAbaixoMicro: {
          type: "text",
          position: {
            x: 70,
            y: 66.04,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsExcelenteMicro: {
          type: "text",
          position: {
            x: 150,
            y: 50.22,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsMediaMicro: {
          type: "text",
          position: {
            x: 156,
            y: 58.38,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsAbaixoMicro: {
          type: "text",
          position: {
            x: 150,
            y: 66.25,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesExcelenteMicro: {
          type: "text",
          position: {
            x: 234,
            y: 50.22,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesMediaMicro: {
          type: "text",
          position: {
            x: 239.2,
            y: 58.59,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesAbaixoMicro: {
          type: "text",
          position: {
            x: 234,
            y: 66.2,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoExcelenteMicro: {
          type: "text",
          position: {
            x: 75,
            y: 91.42,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoMediaMicro: {
          type: "text",
          position: {
            x: 80,
            y: 99.47,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoAbaixoMicro: {
          type: "text",
          position: {
            x: 74.5,
            y: 107.53,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsExcelenteMicro: {
          type: "text",
          position: {
            x: 153,
            y: 91.69,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsMediaMicro: {
          type: "text",
          position: {
            x: 159,
            y: 99.42,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsAbaixoMicro: {
          type: "text",
          position: {
            x: 153,
            y: 107.48,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokExcelenteMicro: {
          type: "text",
          position: {
            x: 232,
            y: 92.16,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokMediaMicro: {
          type: "text",
          position: {
            x: 237.5,
            y: 99.37,
          },
          width: 40,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokAbaixoMicro: {
          type: "text",
          position: {
            x: 232,
            y: 107.43,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterExcelenteMicro: {
          type: "text",
          position: {
            x: 151,
            y: 132,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterMediaMicro: {
          type: "text",
          position: {
            x: 156.3,
            y: 139.59,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterAbaixoMicro: {
          type: "text",
          position: {
            x: 151.09,
            y: 147.91,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
      {
        InstaFeedExcelenteMacro: {
          type: "text",
          position: {
            x: 70,
            y: 50.27,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaFeedMediaMacro: {
          type: "text",
          position: {
            x: 76,
            y: 58.43,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaFeedAbaixoMacro: {
          type: "text",
          position: {
            x: 70,
            y: 66.04,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsExcelenteMacro: {
          type: "text",
          position: {
            x: 150,
            y: 50.22,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsMediaMacro: {
          type: "text",
          position: {
            x: 156,
            y: 58.38,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaReelsAbaixoMacro: {
          type: "text",
          position: {
            x: 150,
            y: 66.25,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesExcelenteMacro: {
          type: "text",
          position: {
            x: 234,
            y: 50.22,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesMediaMacro: {
          type: "text",
          position: {
            x: 239.2,
            y: 58.59,
          },
          width: 35,
          height: 6.99,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        InstaStoriesAbaixoMacro: {
          type: "text",
          position: {
            x: 234,
            y: 66.2,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoExcelenteMacro: {
          type: "text",
          position: {
            x: 75,
            y: 91.42,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoMediaMacro: {
          type: "text",
          position: {
            x: 80,
            y: 99.47,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeVideoAbaixoMacro: {
          type: "text",
          position: {
            x: 74.5,
            y: 107.53,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsExcelenteMacro: {
          type: "text",
          position: {
            x: 153,
            y: 91.69,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsMediaMacro: {
          type: "text",
          position: {
            x: 159,
            y: 99.42,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        YoutubeShortsAbaixoMacro: {
          type: "text",
          position: {
            x: 153,
            y: 107.48,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokExcelenteMacro: {
          type: "text",
          position: {
            x: 232,
            y: 92.16,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokMediaMacro: {
          type: "text",
          position: {
            x: 237.5,
            y: 99.37,
          },
          width: 40,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TiktokAbaixoMacro: {
          type: "text",
          position: {
            x: 232,
            y: 107.43,
          },
          width: 40,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterExcelenteMacro: {
          type: "text",
          position: {
            x: 151,
            y: 132,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterMediaMacro: {
          type: "text",
          position: {
            x: 156.3,
            y: 139.59,
          },
          width: 35,
          height: 7.52,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TwitterAbaixoMacro: {
          type: "text",
          position: {
            x: 151.09,
            y: 147.91,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
      {},
    ],
    basePdf: base64RelatorioFinal,
  };
  setErro({ ativo: false, mensagem: "" });
  const { token, tokenRenovacao } = obterTokens();

  let InstaFeedMediaMacro: number = 0;
  let InstaFeedMediaMicro: number = 0;

  let InstaReelsMediaMacro: number = 0;
  let InstaReelsMediaMicro: number = 0;

  let InstaStoriesMediaMacro: number = 0;
  let InstaStoriesMediaMicro: number = 0;

  let YoutubeVideoMediaMacro: number = 0;
  let YoutubeVideoMediaMicro: number = 0;

  let YoutubeShortsMediaMacro: number = 0;
  let YoutubeShortsMediaMicro: number = 0;

  let TiktokMediaMacro: number = 0;
  let TiktokMediaMicro: number = 0;

  let TwitterMediaMacro: number = 0;
  let TwitterMediaMicro: number = 0;

  await getBenchmarkTaxaEngajamento(
    token,
    "PublicacaoInstagramFeed",
    "MacroInfluenciador"
  )
    .then(async (res1) => {
      InstaFeedMediaMacro = res1.data.valor;
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoInstagramFeed",
        "MicroInfluenciador"
      ).then((res) => {
        InstaFeedMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoInstagramReels",
        "MacroInfluenciador"
      ).then((res) => {
        InstaReelsMediaMacro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoInstagramReels",
        "MicroInfluenciador"
      ).then((res) => {
        InstaReelsMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoInstagramStories",
        "MacroInfluenciador"
      ).then((res) => {
        InstaStoriesMediaMacro = res;
      });
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoInstagramStories",
        "MicroInfluenciador"
      ).then((res) => {
        InstaStoriesMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoTikTok",
        "MacroInfluenciador"
      ).then((res) => {
        TiktokMediaMacro = res;
      });
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoTikTok",
        "MicroInfluenciador"
      ).then((res) => {
        TiktokMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoTwitter",
        "MacroInfluenciador"
      ).then((res) => {
        TwitterMediaMacro = res;
      });
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoTwitter",
        "MicroInfluenciador"
      ).then((res) => {
        TwitterMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoYouTube",
        "MacroInfluenciador"
      ).then((res) => {
        YoutubeVideoMediaMacro = res;
      });
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoYouTube",
        "MicroInfluenciador"
      ).then((res) => {
        YoutubeVideoMediaMicro = res;
      });

      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoYouTubeShorts",
        "MacroInfluenciador"
      ).then((res) => {
        YoutubeShortsMediaMacro = res;
      });
      await pegaBenchmarkingTaxaEngajamento(
        token,
        "PublicacaoYouTubeShorts",
        "MicroInfluenciador"
      ).then((res) => {
        YoutubeShortsMediaMicro = res;
      });
    })
    .catch((err) => {
      if (err.message.includes("401")) {
        adicionarAListaEsperaRenovacaoToken(async (token) => {
          await getBenchmarkTaxaEngajamento(
            token,
            "PublicacaoInstagramFeed",
            "MacroInfluenciador"
          )
            .then(async (res2) => {
              InstaFeedMediaMacro = res2.data.valor;
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoInstagramFeed",
                "MicroInfluenciador"
              ).then((res) => {
                InstaFeedMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoInstagramReels",
                "MacroInfluenciador"
              ).then((res) => {
                InstaReelsMediaMacro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoInstagramReels",
                "MicroInfluenciador"
              ).then((res) => {
                InstaReelsMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoInstagramStories",
                "MacroInfluenciador"
              ).then((res) => {
                InstaStoriesMediaMacro = res;
              });
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoInstagramStories",
                "MicroInfluenciador"
              ).then((res) => {
                InstaStoriesMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoTikTok",
                "MacroInfluenciador"
              ).then((res) => {
                TiktokMediaMacro = res;
              });
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoTikTok",
                "MicroInfluenciador"
              ).then((res) => {
                TiktokMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoTwitter",
                "MacroInfluenciador"
              ).then((res) => {
                TwitterMediaMacro = res;
              });
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoTwitter",
                "MicroInfluenciador"
              ).then((res) => {
                TwitterMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoYouTube",
                "MacroInfluenciador"
              ).then((res) => {
                YoutubeVideoMediaMacro = res;
              });
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoYouTube",
                "MicroInfluenciador"
              ).then((res) => {
                YoutubeVideoMediaMicro = res;
              });

              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoYouTubeShorts",
                "MacroInfluenciador"
              ).then((res) => {
                YoutubeShortsMediaMacro = res;
              });
              await pegaBenchmarkingTaxaEngajamento(
                token,
                "PublicacaoYouTubeShorts",
                "MicroInfluenciador"
              ).then((res) => {
                YoutubeShortsMediaMicro = res;
              });
            })
            .catch((err) => {
              setCarregando(false);
              setErro({
                ativo: true,
                mensagem: "Ocorreu um erro ao gerar o relatório.",
              });
            });
        }, setCarregando);
      } else {
        setCarregando(false);
        setErro({
          ativo: true,
          mensagem: "Ocorreu um erro ao gerar o relatório.",
        });
      }
    });

  async function pegaBenchmarkingTaxaEngajamento(
    token: string,
    tipoPublicacao: tipoPublicacaoProps,
    tipoInfluenciador: string
  ) {
    return await getBenchmarkTaxaEngajamento(
      token,
      tipoPublicacao,
      tipoInfluenciador
    )
      .then((res1) => {
        return res1.data.valor;
      })
      .catch((err) => {
        setErro({
          ativo: true,
          mensagem: "Ocorreu um erro ao gerar o relatório.",
        });
        return 0;
      });
  }

  const inputs = [
    {
      ComentarioNetcos: await imagemComentarioNetcos,
      InstaFeedExcelenteMicro:
        "> " + format("#.###.##0,##%", InstaFeedMediaMicro * 1.1),
      InstaFeedMediaMicro: format("#.###.##0,##%", InstaFeedMediaMicro),
      InstaFeedAbaixoMicro:
        "< " + format("#.###.##0,##%", InstaFeedMediaMicro * 0.9),
      InstaReelsExcelenteMicro:
        "> " + format("#.###.##0,##%", InstaReelsMediaMicro * 1.1),
      InstaReelsMediaMicro: format("#.###.##0,##%", InstaReelsMediaMicro),
      InstaReelsAbaixoMicro:
        "< " + format("#.###.##0,##%", InstaReelsMediaMicro * 0.9),
      InstaStoriesExcelenteMicro:
        "> " + format("#.###.##0,##%", InstaStoriesMediaMicro * 1.1),
      InstaStoriesMediaMicro: format("#.###.##0,##%", InstaStoriesMediaMicro),
      InstaStoriesAbaixoMicro:
        "< " + format("#.###.##0,##%", InstaStoriesMediaMicro * 0.9),
      YoutubeVideoExcelenteMicro:
        "> " + format("#.###.##0,##%", YoutubeVideoMediaMicro * 1.1),
      YoutubeVideoMediaMicro: format("#.###.##0,##%", YoutubeVideoMediaMicro),
      YoutubeVideoAbaixoMicro:
        "< " + format("#.###.##0,##%", YoutubeVideoMediaMicro * 0.9),
      YoutubeShortsExcelenteMicro:
        "> " + format("#.###.##0,##%", YoutubeShortsMediaMicro * 1.1),
      YoutubeShortsMediaMicro: format("#.###.##0,##%", YoutubeShortsMediaMicro),
      YoutubeShortsAbaixoMicro:
        "< " + format("#.###.##0,##%", YoutubeShortsMediaMicro * 0.9),
      TiktokExcelenteMicro:
        "> " + format("#.###.##0,##%", TiktokMediaMicro * 1.1),
      TiktokMediaMicro: format("#.###.##0,##%", TiktokMediaMicro),
      TiktokAbaixoMicro: "< " + format("#.###.##0,##%", TiktokMediaMicro * 0.9),
      TwitterExcelenteMicro:
        "> " + format("#.###.##0,##%", TwitterMediaMicro * 1.1),
      TwitterMediaMicro: format("#.###.##0,##%", TwitterMediaMicro),
      TwitterAbaixoMicro:
        "< " + format("#.###.##0,##%", TwitterMediaMicro * 0.9),
      InstaFeedExcelenteMacro:
        "> " + format("#.###.##0,##%", InstaFeedMediaMacro * 1.1),
      InstaFeedMediaMacro: format("#.###.##0,##%", InstaFeedMediaMacro),
      InstaFeedAbaixoMacro:
        "< " + format("#.###.##0,##%", InstaFeedMediaMacro * 0.9),
      InstaReelsExcelenteMacro:
        "> " + format("#.###.##0,##%", InstaReelsMediaMacro * 1.1),
      InstaReelsMediaMacro: format("#.###.##0,##%", InstaReelsMediaMacro),
      InstaReelsAbaixoMacro:
        "< " + format("#.###.##0,##%", InstaReelsMediaMacro * 0.9),
      InstaStoriesExcelenteMacro:
        "> " + format("#.###.##0,##%", InstaStoriesMediaMacro * 1.1),
      InstaStoriesMediaMacro: format("#.###.##0,##%", InstaStoriesMediaMacro),
      InstaStoriesAbaixoMacro:
        "< " + format("#.###.##0,##%", InstaStoriesMediaMacro * 0.9),
      YoutubeVideoExcelenteMacro:
        "> " + format("#.###.##0,##%", YoutubeVideoMediaMacro * 1.1),
      YoutubeVideoMediaMacro: format("#.###.##0,##%", YoutubeVideoMediaMacro),
      YoutubeVideoAbaixoMacro:
        "< " + format("#.###.##0,##%", YoutubeVideoMediaMacro * 0.9),
      YoutubeShortsExcelenteMacro:
        "> " + format("#.###.##0,##%", YoutubeShortsMediaMacro * 1.1),
      YoutubeShortsMediaMacro: format("#.###.##0,##%", YoutubeShortsMediaMacro),
      YoutubeShortsAbaixoMacro:
        "< " + format("#.###.##0,##%", YoutubeShortsMediaMacro * 0.9),
      TiktokExcelenteMacro:
        "> " + format("#.###.##0,##%", TiktokMediaMacro * 1.1),
      TiktokMediaMacro: format("#.###.##0,##%", TiktokMediaMacro),
      TiktokAbaixoMacro: "< " + format("#.###.##0,##%", TiktokMediaMacro * 0.9),
      TwitterExcelenteMacro:
        "> " + format("#.###.##0,##%", TwitterMediaMacro * 1.1),
      TwitterMediaMacro: format("#.###.##0,##%", TwitterMediaMacro),
      TwitterAbaixoMacro:
        "< " + format("#.###.##0,##%", TwitterMediaMacro * 0.9),
    },
  ];

  const font: Font = {
    exo: {
      data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
      fallback: true
    }
  };

  const plugins = { text, image };

  await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
    // Browser
    var blob11 = new Blob([pdf.buffer], { type: "application/pdf" });
    listaDePdfs.push(blob11);
  });
}

function stripHtml(html: string) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}
