import { ErroType } from "../../@types/erro";
import {
  getTodasPublicacoesDaCampanhaPorInfluenciador,
  PublicacoesProps,
} from "../../services/publicacao";
import { obterTokens } from "../../utils/LocalStorage";
import { tipoOrdenacao } from "../../@types/ordenacao";

interface FuncoesConteudoMetricasProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  listaDePublicacoesYouTube: PublicacoesProps[];
  setListaDePublicacoes: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setListaDePublicacoesInstagram: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setListaDePublicacoesTikTok: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setListaDePublicacoesTwitter: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setListaDePublicacoesYouTube: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setTotalDePublicacoes: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  nomePropriedadeOrdenacao: "Id" | "DataFinalizacao" | "Status" | string;
  tipoOrdenacao: tipoOrdenacao;
  numeroPagina: number;
  tamanhoPagina: number;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesConteudoMetricas = ({
  idCampanha,
  idInfluenciadorSelecionado,
  setListaDePublicacoes,
  setListaDePublicacoesInstagram,
  setListaDePublicacoesTikTok,
  setListaDePublicacoesTwitter,
  setListaDePublicacoesYouTube,
  setTotalDePublicacoes,
  setErro,
  setCarregando,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
  numeroPagina,
  tamanhoPagina,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesConteudoMetricasProps) => {
  const obterPublicacoesTarefa = () => {
    setErro({ ativo: false, mensagem: "" });

    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTodasPublicacoesDaCampanhaPorInfluenciador(
      token,
      idCampanha,
      idInfluenciadorSelecionado,
      tipoOrdenacao,
      nomePropriedadeOrdenacao,
      numeroPagina,
      tamanhoPagina
    )
      .then((res) => {
        setListaDePublicacoes(res.data.tarefas);
        setTotalDePublicacoes(res.data.totalRegistros);
        filtrarPorRedeSocial(res.data.tarefas);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTodasPublicacoesDaCampanhaPorInfluenciador(
              token,
              idCampanha,
              idInfluenciadorSelecionado,
              tipoOrdenacao,
              nomePropriedadeOrdenacao,
              numeroPagina,
              tamanhoPagina
            )
              .then((res) => {
                setListaDePublicacoes(res.data.tarefas);
                setTotalDePublicacoes(res.data.totalRegistros);
                filtrarPorRedeSocial(res.data.tarefas);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Publicações.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Publicações.",
          });
          setCarregando(false);
        }
      });
  };

  const filtrarPorRedeSocial = (lista: PublicacoesProps[]) => {
    setListaDePublicacoesInstagram(
      lista.filter(
        (item) =>
          item.status === "Aprovado" &&
          item.tipo.includes("PublicacaoInstagram")
      )
    );
    setListaDePublicacoesTikTok(
      lista.filter(
        (item) => item.status === "Aprovado" && item.tipo === "PublicacaoTikTok"
      )
    );
    setListaDePublicacoesTwitter(
      lista.filter(
        (item) =>
          item.status === "Aprovado" && item.tipo === "PublicacaoTwitter"
      )
    );
    setListaDePublicacoesYouTube(
      lista.filter(
        (item) =>
          item.status === "Aprovado" && item.tipo.includes("PublicacaoYouTube")
      )
    );
  };

  return {
    obterPublicacoesTarefa,
  };
};
