import { PublicacoesProps } from "../../../services/publicacao";

interface FuncoesTabelaMetricasYoutubeProps {
  setViewsTotais: React.Dispatch<React.SetStateAction<number>>;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  setDislikes: React.Dispatch<React.SetStateAction<number>>;
  setTempoDeReproducao: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setTempoDeVisualizacao: React.Dispatch<React.SetStateAction<number>>;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
  setTaxaDeAprovacao: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaMetricasYoutube = ({
  setViewsTotais,
  setLikes,
  setDislikes,
  setComentarios,
  setEspectadores,
  setImpressoes,
  setTaxaDeAprovacao,
  setTempoDeReproducao,
  setTempoDeVisualizacao
}: FuncoesTabelaMetricasYoutubeProps) => {

  const somaDadosPublicacaoYoutube = (listaDePublicacoes: PublicacoesProps[]) => {
    let somaViewsTotais: number = 0;
    let somaLikes: number = 0;
    let somaDislikes: number = 0;
    let somaComentarios: number = 0;
    let somaEspectadores: number = 0;
    let somaImpressoes: number = 0;
    let somaTaxaDeAprovacao: number = 0;
    let somaTempoDeReproducao: number = 0;
    let somaTempoDeVisualizacao: number = 0;
    

    listaDePublicacoes.map((publicacao) => {
      publicacao.viewsTotais && (
        somaViewsTotais = somaViewsTotais + publicacao.viewsTotais
      )
      publicacao.likes && (
        somaLikes = somaLikes + publicacao.likes
      )
      publicacao.dislikes && (
        somaDislikes = somaDislikes + publicacao.dislikes
      )
      publicacao.comentarios && (
        somaComentarios = somaComentarios + publicacao.comentarios
      )
      publicacao.espectadores && (
        somaEspectadores = somaEspectadores + publicacao.espectadores
      )
      publicacao.impressoes && (
        somaImpressoes = somaImpressoes + publicacao.impressoes
      )
      publicacao.taxaAprovacao && (
        somaTaxaDeAprovacao = somaTaxaDeAprovacao + publicacao.taxaAprovacao
      )
      publicacao.tempoReproducao && (
        somaTempoDeReproducao = somaTempoDeReproducao + publicacao.tempoReproducao
      )
    });

    setViewsTotais(somaViewsTotais);
    setLikes(somaLikes);
    setDislikes(somaDislikes);
    setComentarios(somaComentarios);
    setEspectadores(somaEspectadores);
    setImpressoes(somaImpressoes);
    setTaxaDeAprovacao(somaTaxaDeAprovacao);
    setTempoDeReproducao(somaTempoDeReproducao);
    setTempoDeVisualizacao(somaTempoDeVisualizacao/listaDePublicacoes.length);

  }

  function pegaCodigoYoutubeEBotaEmbed(urlPublicacao:string) {
    var id = urlPublicacao.match('shorts/([a-zA-Z0-9_-]+)&?') ? urlPublicacao.match('shorts/([a-zA-Z0-9_-]+)&?')![1] : "";
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = urlPublicacao.match(regExp);
    return (match && (match[7].length==11)) ? "https://www.youtube.com/embed/" + match[7] : `https://www.youtube.com/embed/${id}`;
  }

  return {
    somaDadosPublicacaoYoutube,
    pegaCodigoYoutubeEBotaEmbed
  }
}