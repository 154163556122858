  export const converteSegundoParaTempoCompleto = (segundos: number) => {
    var hora = Math.floor(segundos / 3600);
    var minuto = Math.floor(segundos % 3600 / 60);
    var segundo = Math.floor(segundos % 3600 % 60);
    
    return hora + ' h:' + minuto + ' m:' + segundo + ' s';
  }
  export const converteSegundoParaHora = (segundos: number) => {
    var hora = Math.floor(segundos / 3600);
    
    return hora;
  }
  export const converteSegundoParaMinuto = (segundos: number) => {
    var minuto = Math.floor(segundos % 3600 / 60);
    
    return minuto;
  }
  export const converteSegundoParaSegundoRestante = (segundos: number) => {
    var segundo = Math.floor(segundos % 3600 % 60);
    
    return segundo;
  }

  
  export const converteTempoCompletoParaSegundo = (
    hora: number,
    minuto: number,
    segundo: number
    ) => {

      var horaEmSegundos = Math.floor(hora * 3600);
      var minutoEmSegundos = Math.floor(minuto * 60);

      return horaEmSegundos + minutoEmSegundos + segundo;      
  }
