import styles from './styles.module.css'

interface BodyTemplateProps extends React.HTMLAttributes<HTMLDivElement> {
  classesAdicionais?: string
}

export const BodyTemplate = ({ children, classesAdicionais }: BodyTemplateProps) => {
  return (
    <div className={`${styles.container} ${classesAdicionais}`}>
      {children}
    </div>
  )
}