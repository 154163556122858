import { SetStateAction } from "react";
import { tipoOrdenacao } from "../../@types/ordenacao";

interface FunctionsTituloColunaProps {
  tituloColuna: string;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<tipoOrdenacao> | undefined;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<string> | undefined;
  iconOrdenacao: number[];
}

export const FunctionsTituloColuna = ({
  iconOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setTipoOrdenacao,
  tipoOrdenacao,
}: FunctionsTituloColunaProps) => {
  function handleOrdenacao(tituloColuna: string) {
    setNomePropriedadeOrdenacao && setNomePropriedadeOrdenacao(tituloColuna);
    setTipoOrdenacao && setTipoOrdenacao(
      tipoOrdenacao === "Ascending" ? "Descending" : "Ascending"
    );
  }

  return { handleOrdenacao };
};
