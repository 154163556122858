import { useEffect, useState } from "react";

import { PublicacoesProps } from "../../../services/publicacao";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";
import { CampoInformacoes } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { TituloColuna } from "../../TituloColuna";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";

import { FuncoesTabelaMetricasYoutube } from "./functions";
import styles from "./styles.module.css";
import { format } from "../../../utils/MascaraNumero/format";
import { converteSegundoParaTempoCompleto } from "../../../utils/ConversorTempo";
import { CarrosselMetricas } from "../../Carrossel/CarrosselMetricas";
import { BiTimeFive } from "react-icons/bi";
import { DateFormatter } from "../../../utils/DataFormatter";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { YouTubeEmbed } from "react-social-media-embed";

export interface TabelaMetricasYoutubeProps {
  listaDePublicacoes: PublicacoesProps[];
  nomeInfluenciadorSelecionado: string;
  sessaoDestino?: string;
}

export const TabelaMetricasYoutube = ({
  listaDePublicacoes,
  nomeInfluenciadorSelecionado,
  sessaoDestino,
}: TabelaMetricasYoutubeProps) => {
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<
    "Id" | "DataFinalizacao" | "Status" | string
  >("Id");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");

  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  const colunasTabela = [
    { nome: "Views Totais", ordenacao: "" },
    { nome: "Tempo de reprodução", ordenacao: "" },
    { nome: "Comentários", ordenacao: "" },
    { nome: "Likes", ordenacao: "" },
    { nome: "Dislikes", ordenacao: "" },
    { nome: "Tempo de visualização", ordenacao: "" },
    { nome: "Impressões", ordenacao: "" },
    { nome: "Espectadores", ordenacao: "" },
    { nome: "Taxa de Aprovação", ordenacao: "" },
  ];

  const [viewsTotais, setViewsTotais] = useState<number>(0);
  const [likes, setLikes] = useState<number>(0);
  const [dislikes, setDislikes] = useState<number>(0);
  const [tempoDeReproducao, setTempoDeReproducao] = useState<number>(0);
  const [comentarios, setComentarios] = useState<number>(0);
  const [tempoDeVisualizacao, setTempoDeVisualizacao] = useState<number>(0);
  const [impressoes, setImpressoes] = useState<number>(0);
  const [espectadores, setEspectadores] = useState<number>(0);
  const [taxaDeAprovacao, setTaxaDeAprovacao] = useState<number>(0);

  const perfil = obterCredenciais().credenciais.perfil[0];

  const { somaDadosPublicacaoYoutube, pegaCodigoYoutubeEBotaEmbed } =
    FuncoesTabelaMetricasYoutube({
      setViewsTotais,
      setLikes,
      setDislikes,
      setComentarios,
      setEspectadores,
      setImpressoes,
      setTaxaDeAprovacao,
      setTempoDeReproducao,
      setTempoDeVisualizacao,
    });

  sessaoDestino &&
    document
      .getElementById(`Metricas${sessaoDestino}`)
      ?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    somaDadosPublicacaoYoutube(listaDePublicacoes);
  }, []);

  return (
    <div className={styles.conteudoTotal}>
      <CampoInformacoes titulo={"Métricas"} corDeFundo={"var(--branco)"}>
        <table className={styles.containerTabela}>
          <thead>
            <tr>
              {colunasTabela.map((titulo) => {
                return (
                  <TituloColuna
                    key={colunasTabela.indexOf(titulo)}
                    colunaIndex={colunasTabela.indexOf(titulo)}
                    tituloColuna={titulo.nome}
                    tituloOrdenacao={titulo.ordenacao}
                    iconOrdenacao={[]}
                    tipoOrdenacao={tipoOrdenacao}
                    setTipoOrdenacao={setTipoOrdenacao}
                    nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                    setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody className={styles.corpoTabela}>
            {carregando ? (
              <Carregando
                listaUsuarios={listaDePublicacoes.length}
                spinner={true}
              />
            ) : (
              <>
                {erro.ativo ? (
                  <ErroTabela erro={erro} setRecarregar={setRecarregar} />
                ) : (
                  <>
                    {listaDePublicacoes && listaDePublicacoes.length === 0 ? (
                      <tr>
                        <td style={{ justifyContent: "center" }}>
                          Nenhum resultado a ser exibido.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{format("#.###.##0,#", viewsTotais)}</td>
                        <td>
                          {converteSegundoParaTempoCompleto(tempoDeReproducao)}
                        </td>
                        <td>{format("#.###.##0,#", comentarios)}</td>
                        <td>{format("#.###.##0,#", likes)}</td>
                        <td>{format("#.###.##0,#", dislikes)}</td>
                        <td>
                          {converteSegundoParaTempoCompleto(
                            tempoDeVisualizacao
                          )}
                        </td>
                        <td>{format("#.###.##0,#", impressoes)}</td>
                        <td>{format("#.###.##0,#", espectadores)}</td>
                        <td>
                          {format(
                            "#.###.##0,#0 %",
                            (likes / (likes + dislikes)) * 100
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            )}
          </tbody>
          <tfoot className={styles.footerTabela}></tfoot>
        </table>

        <div className={styles.containerLista}>
          <div>
            <p>Views Totais: </p>
            {viewsTotais}
          </div>
          <div>
            <p>Tempo de reprodução: </p>
            {tempoDeReproducao}
          </div>
          <div>
            <p>Comentários: </p>
            {comentarios}
          </div>
          <div>
            <p>Likes: </p>
            {likes}
          </div>
          <div>
            <p>Dislikes: </p>
            {dislikes}
          </div>
          <div>
            <p>Tempo de visualização: </p>
            {tempoDeVisualizacao}
          </div>
          <div>
            <p>Impressões: </p>
            {impressoes}
          </div>
          <div>
            <p>Espectadores: </p>
            {espectadores}
          </div>
          <div>
            <p>Taxa de Aprovação: </p>
            {taxaDeAprovacao}
          </div>
        </div>
      </CampoInformacoes>
      <CampoInformacoes titulo={"Publicações"}>
        <div className={styles.campoPublicacoes}>
          <div id={"MetricasPublicacaoYouTube"}>
            {listaDePublicacoes
              .filter((publicacao) => publicacao.tipo === "PublicacaoYouTube")
              .map((publicacao) => {
                return (
                  <div className={styles.containerPublicacao}>
                    <div className={styles.tituloPublicacao}>
                      {(perfil === "Administrador" ||
                        perfil === "UsuarioNetcos") &&
                        `${publicacao.numeroIdentificadorDePublicacao} - `}
                      {publicacao.tipo === "PublicacaoYouTube" && "Video - "}
                      {publicacao.tipo === "PublicacaoYouTubeShorts" &&
                        "Shorts - "}
                      Data da Publicação:{" "}
                      {DateFormatter(publicacao.dataFinalizacao)}
                    </div>
                    <div className={styles.containerResultadoEPublicacoes}>
                      <div className={styles.containerDadosResultados}>
                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-eye"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Views</h1>
                            <p>
                              {publicacao.viewsTotal ||
                              publicacao.viewsTotal === 0
                                ? format("#.###.##0,#", publicacao.viewsTotal)
                                : format("#.###.##0,#", publicacao.viewsTotais)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-user-plus"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Impressões</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.impressoes)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-user"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Espectadores</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.espectadores)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-comments"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Comentários</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.comentarios)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-thumbs-up"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Likes</h1>
                            <p>{format("#.###.##0,#", publicacao.likes)}</p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-thumbs-down"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Dislikes</h1>
                            <p>{format("#.###.##0,#", publicacao.dislikes)}</p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-percentage"
                            style={{
                              fontSize: "1.6rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Taxa de Aprovaçao</h1>
                            <p>
                              {format(
                                "#.###.##0,#0 %",
                                (publicacao.likes! /
                                  (publicacao.likes! + publicacao.dislikes!)) *
                                  100
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className={styles.campoPublicacao}>
                        <div className={styles.containerVideoYoutube}>
                          <p className={styles.headerCampoEmbed}>
                            Evidência da Publicação
                          </p>
                          {/* <iframe
                          // style={{marginTop: '6rem', marginLeft: '5rem'}}
                          height={"80%"}
                          width={"80%"}
                          src={pegaCodigoYoutubeEBotaEmbed(
                            publicacao.urlPublicacao!
                          )}
                          allowFullScreen={true}
                        /> */}

                          <YouTubeEmbed url={publicacao.urlPublicacao!} />
                        </div>
                        {/* <div className={styles.carrosselEvidencias}>
                        <CarrosselMetricas
                          titulo="Evidência da publicação"
                          prints={publicacao.midiasDeEvidencia}
                          exibicao
                        />
                      </div> */}

                        {publicacao.printsDeMetrica?.length! > 0 && (
                          <div className={styles.carrosselMetricas}>
                            <CarrosselMetricas
                              prints={publicacao.printsDeMetrica}
                              exibicao
                              carroselYoutube
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div id={"MetricasPublicacaoYouTubeShorts"}>
            {listaDePublicacoes
              .filter(
                (publicacao) => publicacao.tipo === "PublicacaoYouTubeShorts"
              )
              .map((publicacao) => {
                return (
                  <div className={styles.containerPublicacao}>
                    <div className={styles.tituloPublicacao}>
                      {(perfil === "Administrador" ||
                        perfil === "UsuarioNetcos") &&
                        `${publicacao.numeroIdentificadorDePublicacao} - `}
                      {publicacao.tipo === "PublicacaoYouTube" && "Video - "}
                      {publicacao.tipo === "PublicacaoYouTubeShorts" &&
                        "Shorts - "}
                      Data da Publicação:{" "}
                      {DateFormatter(publicacao.dataFinalizacao)}
                    </div>
                    <div className={styles.containerResultadoEPublicacoes}>
                      <div className={styles.containerDadosResultados}>
                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-eye"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Views</h1>
                            <p>
                              {publicacao.viewsTotal ||
                              publicacao.viewsTotal === 0
                                ? format("#.###.##0,#", publicacao.viewsTotal)
                                : format("#.###.##0,#", publicacao.viewsTotais)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-user-plus"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Impressões</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.impressoes)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-user"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Espectadores</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.espectadores)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-comments"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Comentários</h1>
                            <p>
                              {format("#.###.##0,#", publicacao.comentarios)}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <BiTimeFive
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          />
                          <div>
                            <h1>Tempo de reproduçao</h1>
                            <p>
                              {publicacao.tempoReproducao
                                ? converteSegundoParaTempoCompleto(
                                    publicacao.tempoReproducao
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-thumbs-up"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Likes</h1>
                            <p>{format("#.###.##0,#", publicacao.likes)}</p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-thumbs-down"
                            style={{
                              fontSize: "2rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Dislikes</h1>
                            <p>{format("#.###.##0,#", publicacao.dislikes)}</p>
                          </div>
                        </div>

                        <div className={styles.dadoResultado}>
                          <i
                            className="pi pi-percentage"
                            style={{
                              fontSize: "1.6rem",
                              fontWeight: "500",
                              color: "var(--roxo-500)",
                            }}
                          ></i>
                          <div>
                            <h1>Taxa de Aprovaçao</h1>
                            <p>
                              {format(
                                "#.###.##0,#0 %",
                                (publicacao.likes! /
                                  (publicacao.likes! + publicacao.dislikes!)) *
                                  100
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className={styles.campoPublicacao}>
                        <div className={styles.containerVideoYoutube}>
                          <p className={styles.headerCampoEmbed}>
                            Evidência da Publicação
                          </p>
                          <iframe
                            // style={{marginTop: '6rem', marginLeft: '5rem'}}
                            height={"80%"}
                            width={"80%"}
                            src={pegaCodigoYoutubeEBotaEmbed(
                              publicacao.urlPublicacao!
                            )}
                            allowFullScreen={true}
                          />
                        </div>
                        {/* <div className={styles.carrosselEvidencias}>
                        <CarrosselMetricas
                          titulo="Evidência da publicação"
                          prints={publicacao.midiasDeEvidencia}
                          exibicao
                        />
                      </div> */}

                        {publicacao.printsDeMetrica?.length! > 0 && (
                          <div className={styles.carrosselMetricas}>
                            <CarrosselMetricas
                              prints={publicacao.printsDeMetrica}
                              exibicao
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </CampoInformacoes>
    </div>
  );
};
