import { Avatar } from 'primereact/avatar';

interface AvatarProps {
  nomeUsuario: string;
  avatarUrl: string;
}

export const AvatarUsuario = ({ nomeUsuario, avatarUrl }: AvatarProps) => {
  return (
    <div style={{ flex: '1' }}>
      <Avatar
        label={nomeUsuario.charAt(0)}
        shape='circle'
        size='xlarge'
        image={avatarUrl}
      />
    </div>
  );
}