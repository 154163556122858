import { InputMetrica } from "./inputs/InputMetrica";

interface InstagramReelsProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  salvos: number;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  quantidadeReproducoes: number;
  setQuantidadeReproducoes: React.Dispatch<React.SetStateAction<number>>;
}

export const InstagramReels = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  compartilhamentos,
  setCompartilhamentos,
  salvos,
  setSalvos,
  alcance,
  setAlcance,
  quantidadeReproducoes,
  setQuantidadeReproducoes

}: InstagramReelsProps) => {
  const listaMetricas = [
    { nome: "Reproduções", metrica: { valor: quantidadeReproducoes, dispatch: setQuantidadeReproducoes } },
    { nome: "Alcance", metrica: { valor: alcance, dispatch: setAlcance } },
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Compartilhamentos", metrica: { valor: compartilhamentos, dispatch: setCompartilhamentos } },
    { nome: "Salvos", metrica: { valor: salvos, dispatch: setSalvos } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}
    </>
  )
}