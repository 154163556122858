import { AxiosResponse } from "axios";
import { tipoOrdenacao } from "../@types/ordenacao";
import { api } from "./api";
import { tipoPublicacaoProps } from "./campanha";

export interface publicacaoProps {
  id: string;
  dataFinalizacao?: string;
  printsDeMetrica?: Prints[] | null;
  printsDeSentimento?: Prints[] | null;
}

export interface printsDeMetricaProps {
  idUploader: string;
  dataUpload: string;
  imagem: string;
}
export interface printsDeSentimentoPropsPut {
  idUploader: string;
  dataUpload: Date;
  imagem: string;
  sentimento: string;
}

export interface printsDeSentimentoPropsGet {
  idUploader: string;
  dataUpload: Date;
  urlImagem?: string;
  imagem?: string;
  sentimento: string;
}

export interface putPublicacaoProps {
  idCampanha: string;
  publicacao: publicacaoProps & {
    urlPublicacao?: string;
    midiasDeEvidencia?: Prints[] | null;
    status?: "Aprovado" | "EmAnalise" | "Pendente" | "Ajustes";
    alcance?: number;
    impressoes?: number;
    likes?: number;
    comentarios?: number;
    comentariosNetcos?: string;
    compartilhamentos?: number;
    views?: number;
    salvos?: number;
    visitasPerfil?: number;
    visitasAoPerfil?: number;
    cliquesLink?: number;
    viewsTotais?: number;
    viewsTotal?: number;
    tempoReproducao?: number;
    publicoAtingido?: string;
    taxaEngajamento?: number;
    engajamentos?: number;
    favoritos?: number;
    respostas?: number;
    retweets?: number;
    dislikes?: number;
    tempoMedioReproducao?: number;
    taxaAprovacao?: number;
    quantidadeReproducoes?: number;
    cliquesStickers?: number;
    cliquesHashtag?: number;
    detailExpands?: number;
    espectadores?: number;
  };
}

export interface GetTarefa {
  codigo: number;
  totalRegistros: number;
  quantidadeDePublicacoesAprovadas: number;
  tarefas: PublicacoesProps[];
}
export interface Tarefas {
  id: number;
  externalId: string;
  influenciador: Influenciador;
  publicacoes: PublicacoesProps[];
  valorContrato: number;
  perfilInfluenciadorNoFechamentoDoContrato: string;
}
export interface Influenciador {
  idCredenciais: string;
  nome: string;
  email: string;
  perfil: string;
  telefone: number;
  status: string;
  urlAvatar: string;
}
export interface PublicacoesProps {
  comentariosNetcos?: string;
  urlPublicacao?: string;
  midiasDeEvidencia?: Prints[];
  alcance?: number;
  impressoes?: number;
  likes?: number;
  comentarios?: number;
  compartilhamentos?: number;
  externalId: string;
  numeroIdentificadorDePublicacao?: number;
  tipo: tipoPublicacaoProps;
  dataFinalizacao: string;
  printsDeMetrica?: Prints[];
  printsDeSentimento?: Prints[];
  status: "Aprovado" | "EmAnalise" | "Pendente" | "Ajustes";
  views?: number;
  salvos?: number;
  visitasPerfil?: number;
  visitasAoPerfil?: number;
  cliquesLink?: number;
  viewsTotais?: number;
  viewsTotal?: number;
  tempoReproducao?: number;
  publicoAtingido?: number;
  taxaEngajamento?: number;
  engajamento?: number;
  favoritos?: number;
  respostas?: number;
  retweets?: number;
  dislikes?: number;
  tempoMedioReproducao?: number;
  taxaAprovacao?: number;
  quantidadeReproducoes?: number;
  cliquesStickers?: number;
  cliquesHashtag?: number;
  detailExpands?: number;
  espectadores?: number;
}
export interface Prints {
  idUploader: string;
  dataUpload: string;
  urlImagem?: string;
  midia?: string;
  imagem?: string;
  sentimento?: string;
}

export interface putPublicacaoRequest {
  idCampanha: string;
  publicacao: {
    id: string;
    dataFinalizacao: Date;
    printsDeSentimento: printsDeSentimentoPropsPut[];
  };
}
export interface postPublicacaoProps {
  idCampanha: string;
  idTarefa: string;
  publicacoes: PublicacoesProps;
}

export interface deletePrintProps {
  idCampanha: string;
  nomeArquivo: string;
}

export interface deletePublicacaoProps {
  idCampanha: string;
  idPublicacao: string;
}

export const putPublicacao = (
  token: string,
  publicacaoRequest: putPublicacaoProps
) => {
  const url = "/Publicacao";

  return api.put(url, publicacaoRequest, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTodasPublicacoesDaCampanhaPorInfluenciador = (
  token: string,
  idCampanha: string,
  idInfluenciadorSelecionado: string,
  TipoOrdenacao: tipoOrdenacao,
  PropriedadeOrdenacao: "Id" | "DataFinalizacao" | "Status" | string,
  numeroPagina: number,
  tamanhoPagina: number
): Promise<AxiosResponse<GetTarefa, any>> => {
  const url = `/Publicacao/Todas/Campanha/Influenciador?IdCampanha=${idCampanha}&IdInfluenciador=${idInfluenciadorSelecionado}&TipoOrdenacao=${TipoOrdenacao}&PropriedadeOrdenacao=${PropriedadeOrdenacao}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postPublicacao = (
  token: string,
  idCampanha: string,
  idTarefa: string,
  publicacoes: publicacaoProps[]
): Promise<AxiosResponse<postPublicacaoProps, any>> => {
  const url = "/Publicacao";

  const data = {
    idCampanha,
    idTarefa,
    publicacoes,
  };

  return api.post(url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deletePrintsdeMetrica = (
  token: string,
  idCampanha: string,
  nomeArquivo: string
) => {
  const url = "/Publicacao/PrintDeMetrica";

  const data = {
    data: {
      idCampanha,
      nomeArquivo,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return api.delete(
    url,
    data
    //    {
    //   headers: {
    //     Authorization: "Bearer" + token,
    //   },
    // }
  );
};

export const deleteMidiaDeEvidencia = (
  token: string,
  idCampanha: string,
  nomeArquivo: string
): Promise<AxiosResponse<deletePrintProps, any>> => {
  const url = "/Publicacao/MidiaDeEvidencia";

  const data = {
    data: {
      idCampanha,
      nomeArquivo,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return api.delete(
    url,
    data
    //   {
    //   headers: {
    //     Authorization: "Bearer" + token,
    //   },
    // }
  );
};

export const deletePublicacao = (
  token: string,
  idCampanha: string,
  idPublicacao: string
): Promise<AxiosResponse<deletePublicacaoProps, any>> => {
  const url = "/Publicacao/";

  const data = {
    data: {
      idCampanha,
      idPublicacao,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return api.delete(
    url,
    {
      data: {
        idCampanha,
        idPublicacao,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    }
    //   {
    //   headers: {
    //     Authorization: "Bearer" + token,
    //   },
    // }
  );
};
