import { AxiosResponse } from "axios";
import { api } from "./api";

export interface getBenchmarkTaxaEngajamentoProps {
  código: number,
  tipoPublicacao: string,
  valor: number
}

export const getBenchmarkTaxaEngajamento = (
  token: string,
  tipoPublicacao: string,
  tipoInfluenciador: string
): Promise<AxiosResponse<getBenchmarkTaxaEngajamentoProps, any>> => {
  const url = `/Benchmark/TaxaEngajamento?TipoPublicacao=${tipoPublicacao}&TipoInfluenciador=${tipoInfluenciador}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};