import { useContext, useEffect, useRef, useState } from "react";

import { FuncaoListagemCampanhaUsuarioAgencia } from "./functions";

import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { obterCredenciais } from "../../../utils/LocalStorage";

import { Toast } from "primereact/toast";

import { TabelaListarCampanhasAgencias } from "../../../components/Tabelas/TabelaListarCampanhaAgencia";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListagemCampanhaUsuarioAgencia = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);
  const [numeroDePagina, setNumeroDePagina] = useState<number>(0);
  const [tamanhoDePagina, setTamanhoDePagina] = useState<number>(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");

  const [nomePesquisa, setNomePesquisa] = useState<string>("");
  const [idMarca, setIdMarca] = useState<string>("");
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [statusCampanha, setStatusCampanha] = useState<number>();

  const [listaDeCampanhas, setListaDeCampanhas] = useState<campanha[]>([]);
  const [totalCampanhas, setTotalCampanhas] = useState<number>(0);
  const [datas, setDatas] = useState<Date | Date[] | undefined>([]);

  const [filtroPorData, setfiltroPorData] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { credenciais } = obterCredenciais();

  const colunas = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Marca", ordenacao: "marca" },
    { nome: "Data Início", ordenacao: "dataInicio" },
    { nome: "Data Fim", ordenacao: "dataFim" },
    { nome: "Taxa de Engajamento", ordenacao: "mediaTaxaEngajamento" },
    { nome: "Custo por Engajamento", ordenacao: "custoEngajamento" },
    { nome: "Data do Relatório", ordenacao: "dataCriacaoRelatorio" },
    { nome: "Status", ordenacao: "status" },
  ];

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterUsuarioAgencia,
    obterCampanhaAgenciaPorNome,
    obterCampanhaAgenciaPorMarca,
    obterCampanhasPorPeriodo,
    obterCampanhasPorStatus,
  } = FuncaoListagemCampanhaUsuarioAgencia({
    idCredenciais: credenciais.id,
    idAgencia,
    idMarca,
    numeroDePagina: numeroDePagina + 1,
    tamanhoDePagina,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    nomePesquisa,
    dataInicial: datas
      ? Array.isArray(datas)
        ? datas.length !== 0
          ? datas[0].toISOString()
          : ""
        : ""
      : "",
    dataFinal: datas
      ? Array.isArray(datas)
        ? datas[1]
          ? datas[1].toISOString()
          : ""
        : ""
      : "",
    statusCampanha,
    setTamanhoDePagina,
    setErro,
    setCarregando,
    setCarregandoPesquisa,
    adicionarAListaEsperaRenovacaoToken,
    setIdAgencia,
    setListaDeCampanhas,
    setTotalCampanhas,
  });

  useEffect(() => {
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    if (filtroPorPeriodo) {
      setfiltroPorData(!filtroPorData);
    }
  }, [datas]);

  useEffect(() => {
    const pesquisaPorNome = nomePesquisa !== "" && nomePesquisa !== undefined;
    const filtroPorMarca = idMarca !== "" && idMarca !== undefined;
    const filtroPorStatus =
      statusCampanha !== null && statusCampanha !== undefined;
    const filtroPorPeriodo = datas
      ? Array.isArray(datas)
        ? datas[0] && datas[1]
        : false
      : false;

    pesquisaPorNome
      ? obterCampanhaAgenciaPorNome()
      : filtroPorMarca
      ? obterCampanhaAgenciaPorMarca()
      : filtroPorPeriodo
      ? obterCampanhasPorPeriodo()
      : filtroPorStatus
      ? obterCampanhasPorStatus()
      : obterUsuarioAgencia();
  }, [
    tamanhoDePagina,
    tipoOrdenacao,
    numeroDePagina,
    recarregar,
    nomePesquisa,
    idMarca,
    filtroPorData,
    statusCampanha,
  ]);

  return (
    <>
      <TabelaListarCampanhasAgencias
        datas={datas}
        setDatas={setDatas}
        statusCampanha={statusCampanha}
        setStatusCampanha={setStatusCampanha}
        idMarcaFiltro={idMarca}
        setIdMarcaFiltro={setIdMarca}
        carregando={carregando}
        setCarregando={setCarregando}
        setErro={setErro}
        carregandoPesquisa={carregandoPesquisa}
        myToast={myToast}
        tipoOrdenacao={tipoOrdenacao}
        setTipoOrdenacao={setTipoOrdenacao}
        nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
        setNomeCampanhaPesquisa={setNomePesquisa}
        setRecarregar={() => setRecarregar(!recarregar)}
        erro={erro}
        tituloTabela={"Campanhas"}
        tituloColuna={colunas}
        listaDeCampanhas={listaDeCampanhas}
        // paginacao={
        //   <Paginator
        //     first={paginatorFirst}
        //     rows={tamanhoDePagina}
        //     totalRecords={totalCampanhas}
        //     onPageChange={(e) => {
        //       setNumeroDePagina(e.page);
        //       setPaginatorFirst(e.first);
        //     }}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e: any) => setTamanhoDePagina(e.target.value)}
        //     totalLista={totalCampanhas}
        //     nomeLista={"campanhas"}
        //   />
        // }
      />
      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
