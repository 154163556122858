import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FunctionsModalAtualizarInfluenciador } from "./functions";


import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Input } from "../../Input";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";
import { SelectPerfilInfluenciador } from "../../Select/SelectPerfilInfluenciador";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { ModalTeste } from "../ModalTeste";
import { InputDeEmail } from "./InputEditarEmail";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ModalAtualizarInfluenciadorProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: () => void;
  idCredenciais: string;
  nomePrevio: string;
  emailPrevio: string;
  telefonePrevio: number;
  imagemAvatarPrevia: string;
  perfilPrevio: string;
  statusPrevio: string;
  instagramPrevio: string;
  twitterPrevio: string;
  youTubePrevio: string;
  tiktokPrevio: string;
}

export const ModalAtualizarInfluenciador = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
  idCredenciais,
  imagemAvatarPrevia,
  nomePrevio,
  emailPrevio,
  perfilPrevio,
  telefonePrevio,
  statusPrevio,
  twitterPrevio,
  instagramPrevio,
  tiktokPrevio,
  youTubePrevio,
}: ModalAtualizarInfluenciadorProps) => {
  const [nome, setNome] = useState<string>(nomePrevio);
  const [email, setEmail] = useState<string>(emailPrevio);
  const [telefone, setTelefone] = useState<number>(telefonePrevio);
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [perfil, setPerfil] = useState<string>(perfilPrevio);
  const [status] = useState<string>(statusPrevio);
  const [linkInstagram, setLinkInstagram] = useState<string>(instagramPrevio);
  const [linkTwitter, setLinkTwitter] = useState<string>(twitterPrevio);
  const [linkYouTube, setLinkYouTube] = useState<string>(youTubePrevio);
  const [linkTiktok, setLinkTiktok] = useState<string>(tiktokPrevio);

  const [carregando, setCarregando] = useState<boolean>(false);
  const [inputAtivo, setInputAtivo] = useState<boolean>(true);

  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] = useState<string>("");

  useEffect(() => {
    MascaraTelefone(
      telefonePrevio.toString(),
      setTelefone,
      setMascaraNumeroWhatsapp
    );
  }, []);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { atualizaInfluenciador, fazMascaraTelefone } =
    FunctionsModalAtualizarInfluenciador({
      idCredenciais,
      nome,
      setNome,
      email,
      setEmail,
      telefone,
      setTelefone,
      MascaraNumeroWhatsapp,
      setMascaraNumeroWhatsapp,
      perfil,
      setPerfil,
      imagemAvatar: imagemAvatar ? imagemAvatar : null,
      setImagemAvatar,
      status,
      linkTwitter,
      linkInstagram,
      linkTiktok,
      linkYouTube,
      setCarregando,
      setModalAberto,
      setRecarregar,
      adicionarAListaEsperaRenovacaoToken,
      emailPrevio,
    });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Atualizar Influenciador"}
    >
      <div
        className={styles.conteudoModalAtualizacaoInfluenciador}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <BotaoUploadImagem
            imagemAnterior={imagemAvatarPrevia}
            setImg={setImagemAvatar}
          />
          <div className={styles.containerDados}>
            <div className={styles.containerInputs}>
              <Input
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </div>
            {nome === '' && (
                <small className={styles.mensagemValidacao}>
                  O campo nome é obrigatório.
                </small>
            )}

            <div className={styles.inputTelefonePerfil}>
              <PhoneInput
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
              />
            </div>
            <div className={styles.containerInputs}>
              <SelectPerfilInfluenciador
                setPerfil={setPerfil}
                perfil={perfil}
              />
            </div>

            <div className={styles.containerInputRedesSociais}>
              <Input
                type="text"
                required
                placeholder="Instagram"
                value={linkInstagram}
                onChange={(e) => {
                  setLinkInstagram(e.target.value);
                }}
              />
              <Input
                type="text"
                required
                placeholder="Twitter"
                value={linkTwitter}
                onChange={(e) => {
                  setLinkTwitter(e.target.value);
                }}
              />
            </div>
            <div className={styles.containerInputRedesSociais}>
              <Input
                type="text"
                required
                placeholder="Tiktok"
                value={linkTiktok}
                onChange={(e) => {
                  setLinkTiktok(e.target.value);
                }}
              />
              <Input
                type="text"
                required
                placeholder="Youtube"
                value={linkYouTube}
                onChange={(e) => {
                  setLinkYouTube(e.target.value);
                }}
              />
            </div>

            <InputDeEmail
              inputAtivo={inputAtivo}
              onEmailChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              onclick={() => setInputAtivo(false)}
            />
          </div>
        </body>

        {carregando ? (
          <Carregando />
        ) : (
          <div className={styles.containerBotoesModal}>
            <Button
              importancia="primario"
              title="Confirmar"
              onClick={atualizaInfluenciador}
            />
            <Button
              importancia="secundario"
              type="button"
              title="Cancelar"
              onClick={() => setModalAberto(false)}
            />
          </div>
        )}
      </div>
    </ModalTeste>
  );
};
