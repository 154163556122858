import logo from "../../../../assets/img/netcos-logo.png";
import { useContext } from "react";
import { CredenciaisContext } from "../../../../context/CredenciaisContext";
import styles from "./styles.module.css";

interface BarraLateralProps {
  indice: number;
  setIndice: React.Dispatch<React.SetStateAction<number>>;
}

export const BarraLateral = ({ indice, setIndice }: BarraLateralProps) => {
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];

  return (
    <div className={styles.containerGeral}>
      <div className={styles.containerLogo}>
        <img src={logo} alt="Logo NetCos" className={styles.logoDrawer} />
        <p className={styles.textoLogo}>NetCos</p>
      </div>
      <div className={styles.listaLinks}>
        <label
          htmlFor={styles.radioVisaoGeral}
          className={styles.listaLinksItem}
          style={
            indice === 0
              ? { color: "var(--roxo-500)", fontWeight: "600" }
              : {}
          }
        >
          <input
            id={styles.radioVisaoGeral}
            type="radio"
            name="resultados"
            onChange={() => setIndice(0)}
          />
          <i className="pi pi-chart-bar"></i>
          <p className={styles.textoLinkItem}>Visão Geral</p>
        </label>

        <label
          htmlFor={styles.radioRedeSocial}
          className={styles.listaLinksItem}
          style={
            indice === 1
              ? { color: "var(--roxo-500)", fontWeight: "600" }
              : {}
          }
        >
          <input
            id={styles.radioRedeSocial}
            type="radio"
            name="resultados"
            onChange={() => setIndice(1)}
          />
          <i className="pi pi-at"></i>
          <p className={styles.textoLinkItem}>Por Rede Social</p>
        </label>

        <label
          htmlFor={styles.radioInfluenciadores}
          className={styles.listaLinksItem}
          style={
            indice === 4 || indice === 5
              ? { color: "var(--roxo-500)", fontWeight: "600" }
              : {}
          }
        >
          <input
            id={styles.radioInfluenciadores}
            type="radio"
            name="resultados"
            onChange={() => setIndice(4)}
          />
          <i className="pi pi-users"></i>
          <p className={styles.textoLinkItem}>Influenciadores</p>
        </label>

        <label
          htmlFor={styles.radioBenchmark}
          className={styles.listaLinksItem}
          style={
            indice === 3
              ? { color: "var(--roxo-500)", fontWeight: "600" }
              : {}
          }
        >
          <input
            id={styles.radioBenchmark}
            type="radio"
            name="resultados"
            onChange={() => setIndice(3)}
          />
          <i className="pi pi-chart-line"></i>
          <p className={styles.textoLinkItem}>Benchmarking</p>
        </label>
      </div>
    </div>
  );
};
