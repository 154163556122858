import { AxiosResponse } from "axios";
import { statusType } from "../components/Select/SelectStatus";
import { api } from "./api";

export interface respostaPostUsuarioNetcos {
  codigo: number;
  mensagem: string;
}

export const postUsuarioNetcos = (
  token: string,
  usuario: usuarioNetcosPostProps
): Promise<respostaPostUsuarioNetcos> => {
  const url = "/usuarioNetcos";

  return api.post(
    url,
    { usuarioNetcos: usuario },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export interface usuarioNetcosProps {
  idCredenciais: string;
  nome: string;
  telefone: string;
  urlAvatar: string;
  status: string;
  email: string;
}

export interface usuarioNetcosPutProps {
  id: string;
  nome: string;
  telefone: string;
  imagemAvatar: string | null;
  status: string;
}

export interface usuarioNetcosPostProps {
  nome: string,
  email: string,
  imagemAvatar: string,
  telefone: string,
  status: statusType
}

export interface respostaGetUsuarioNetcos {
  codigo: number;
  usuarios: usuarioNetcosProps[];
  totalRegistros: number;
}
export interface respostaGetUsuarioNetcosPorNome {
  codigo: number;
  totalRegistros: number;
  usuarios: usuarioNetcosProps[];
}

export const getUsuarioNetcosTodos = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetUsuarioNetcos | any>> => {
  const url = `/UsuarioNetcos/Todos?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioNetcosTodosPorNome = (
  token: string,
  nomeUsuarioNetcosPesquisa: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetUsuarioNetcosPorNome | any>> => {
  const url = `/UsuarioNetcos/Todos/Nome?Nome=${nomeUsuarioNetcosPesquisa}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putUsuarioNetcos = (
  token: string,
  usuarioNetcos: usuarioNetcosPutProps
) => {
  const url = "/UsuarioNetcos";

  return api.put(
    url,
    { usuarioNetcos },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
};
