import { useContext, useEffect, useState } from "react";

import { ErroType } from "../../../@types/erro";
import { PublicacoesProps } from "../../../services/publicacao";
import { BarraLateral } from "../../UsuarioNetcos/CampanhaDetalhes/BarraLateral";
import { Benchmarking } from "../../UsuarioNetcos/CampanhaDetalhes/Benchmarking";
import { ComparativoInfluenciadores } from "../../UsuarioNetcos/CampanhaDetalhes/ComparativoInfluenciadores";
import { PorInfluenciador } from "../../UsuarioNetcos/CampanhaDetalhes/PorInfluenciador";
import { VisaoGeral } from "../../UsuarioNetcos/CampanhaDetalhes/VisãoGeral";
import { campanha } from "../../../services/campanha";
import { funcoesCampanhaDetalhes } from "../../UsuarioNetcos/CampanhaDetalhes/functions";
import { DateFormatter } from "../../../utils/DataFormatter";
import { InfluenciadoresCampanha } from "../../UsuarioNetcos/CampanhaDetalhes/InfluenciadoresCampanha";
import { Carregando } from "../../../components/Carregando";
import styles from "./styles.module.css";
import { PorRedeSocial } from "../../UsuarioNetcos/CampanhaDetalhes/PorRedeSocial";

import { useParams } from "react-router-dom";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const CampanhaPorMarcasDetalhes = () => {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);

  const [mostrarModalEditarTarefa, setMostrarModalEditarTarefa] =
    useState<boolean>(false);
  const [mostrarModalAprovarTarefa, setMostrarModalAprovarTarefa] =
    useState<boolean>(false);

  const [indiceBarraLateral, setIndiceBarraLateral] = useState(0);
  const [campanha, setCampanha] = useState<campanha>();
  const [idInfluenciadorSelecionado, setIdInfluenciadorSelecionado] =
    useState<string>("");
  const [nomeInfluenciadorSelecionado, setNomeInfluenciadorSelecionado] =
    useState<string>("");
  const [avatarInfluenciadorSelecionado, setAvatarInfluenciadorSelecionado] =
    useState<string>("");

  const [instagramPresente, setInstagramPresente] = useState<boolean>(false);
  const [tiktokPresente, setTiktokPresente] = useState<boolean>(false);
  const [twitterPresente, setTwitterPresente] = useState<boolean>(false);
  const [youtubePresente, setYoutubePresente] = useState<boolean>(false);

  const [publicacaoSelecionada, setPublicacaoSelecionada] =
    useState<PublicacoesProps>({
      status: "Pendente",
      dataFinalizacao: new Date().toISOString(),
      externalId: "",
      tipo: "PublicacaoInstagramFeed",
    });

  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterCampanha } = funcoesCampanhaDetalhes({
    setErro,
    setCarregando,
    setCampanha,
    idCampanha: idCampanha!,
    setInstagramPresente,
    setTiktokPresente,
    setTwitterPresente,
    setYoutubePresente,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterCampanha();
  }, [recarregar]);

  return (
    <div className={styles.containerPagina}>
      <div className={styles.containerBarraLateral}>
        <BarraLateral
          indice={indiceBarraLateral}
          setIndice={setIndiceBarraLateral}
        />
      </div>
      {carregando ? (
        <Carregando />
      ) : erro.ativo ? (
        <div className={styles.containerErro}>
          <h1>{erro.mensagem}</h1>
          <button
            onClick={() => setRecarregar(!recarregar)}
            className={styles.botaoIconeColunaAcoes}
          >
            <i
              className={"pi pi-refresh"}
              style={{ fontSize: "3rem" }}
              title={"Recarregar gráfico"}
            />
          </button>
        </div>
      ) : (
        campanha && (
          <div className={styles.informacoesPagina}>
            <div className={styles.headerCompleto}>
              <div className={styles.headerCampanha}>
                <div className={styles.campoLogotipo}>
                  {campanha.marcas[0].urlLogotipo ? (
                    <img
                      className={styles.logotipo}
                      src={campanha.marcas[0].urlLogotipo}
                    />
                  ) : (
                    <p>{campanha.marcas[0].nome?.charAt(0)}</p>
                  )}
                </div>

                <div className={styles.campoInformacoesCampanha}>
                  <p className={styles.tituloCampanha}>{campanha.nome}</p>
                  <div className={styles.campoMarcaPeriodo}>
                    <p className={styles.nomeMarca}>
                      {campanha.marcas[0].nome}
                    </p>
                    <div className={styles.dataInidividual}>
                      <span>Período da Campanha: </span>
                      {DateFormatter(campanha.dataInicio)} -{" "}
                      {DateFormatter(campanha.dataFim)}
                    </div>
                  </div>
                </div>

                <div>
                  {campanha.dataCriacaoRelatorio !== "0001-01-01T00:00:00" && (
                    <div className={styles.campoDatasCampanha}>
                      <div className={styles.dataInidividual}>
                        <span>Data do Relatório:</span>{" "}
                        {DateFormatter(campanha.dataCriacaoRelatorio)}
                      </div>
                      <div className={styles.dataInidividual}>
                        <span>Relatório Disponível até</span>
                        {DateFormatter(
                          new Date(campanha.dataCriacaoRelatorio).setMonth(
                            new Date(campanha.dataCriacaoRelatorio).getMonth() +
                              6
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.containerResultados}>
              {/*------------ Itens da Barra Lateral ------------- */}

              {indiceBarraLateral === 0 && (
                <VisaoGeral
                  campanha={campanha}
                  recarregar={() => setRecarregar(!recarregar)}
                />
              )}

              {indiceBarraLateral === 1 && (
                <PorRedeSocial
                  campanha={campanha}
                  carregandoCampanha={carregando}
                  instagramPresente={instagramPresente}
                  tiktokPresente={tiktokPresente}
                  twitterPresente={twitterPresente}
                  youtubePresente={youtubePresente}
                />
              )}
              {indiceBarraLateral === 2 && <ComparativoInfluenciadores />}
              {indiceBarraLateral === 3 && <Benchmarking />}
              {indiceBarraLateral === 4 && (
                <InfluenciadoresCampanha
                  taxaEngajamento={campanha.mediaTaxaEngajamento}
                  setIdInfluenciadorSelecionado={setIdInfluenciadorSelecionado}
                  setIndiceBarraLateral={setIndiceBarraLateral}
                  setNomeInfluenciadorSelecionado={
                    setNomeInfluenciadorSelecionado
                  }
                  setAvatarInfluenciadorSelecionado={
                    setAvatarInfluenciadorSelecionado
                  }
                  idCampanha={idCampanha ? idCampanha : ""}
                />
              )}

              {/*------------ Outras Renderizações Condicionais ------------- */}

              {indiceBarraLateral === 5 && (
                <PorInfluenciador
                  publicacaoSelecionada={publicacaoSelecionada}
                  setPublicacaoSelecionada={setPublicacaoSelecionada}
                  idInfluenciadorSelecionado={idInfluenciadorSelecionado}
                  nomeInfluenciadorSelecionado={nomeInfluenciadorSelecionado}
                  avatarInfluenciadorSelecionado={
                    avatarInfluenciadorSelecionado
                  }
                  idCampanha={idCampanha ? idCampanha : ""}
                  setMostrarModalEditarTarefa={setMostrarModalEditarTarefa}
                  setIndiceBarraLateral={setIndiceBarraLateral}
                  setMostrarModalAprovarTarefa={setMostrarModalAprovarTarefa}
                  dataInicioCampanha={campanha.dataInicio}
                  tarefa={campanha.tarefas.find(
                    (tarefa) =>
                      tarefa.influenciador.idCredenciais ===
                      idInfluenciadorSelecionado
                  )}
                />
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};
