import { useContext } from "react";

import styles from "./style.module.css";

import { FunctionsModalDeslogar } from "./functions";

import { CredenciaisContext } from "../../../context/CredenciaisContext";
import { DesconectarContext } from "../../../context/DesconectarContext";
import { Button } from "../../Botoes/Button";
import { ModalTeste } from "../ModalTeste";

import { useNavigate } from "react-router-dom";

export const ModalDeslogar = () => {
  const { removerCredenciais } = useContext(CredenciaisContext);
  const { desconectar, setDesconectar } = useContext(DesconectarContext);

  const navigate = useNavigate();

  const { deslogar } = FunctionsModalDeslogar({
    removerCredenciais,
    navigate,
    setAbrirModal: setDesconectar,
  });

  return (
    <ModalTeste
      naoFecharModalAoClicarFora
      modalAberto={desconectar}
      fecharModal={deslogar}
      titulo="Você foi deslogado"
    >
      <div className={styles.conteudoModalDeslogar}>
        <div className={styles.mensagem}>
          Para sua segurança o tempo de sessão terminou, faça seu login
          novamente.
        </div>
        <div className={styles.buttonModalRedefinicaoSenha}>
          <Button importancia="primario" title="Login" onClick={deslogar} />
        </div>
      </div>
    </ModalTeste>
  );
};
