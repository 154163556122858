import { ErroType } from "../../../@types/erro";
import { obterTokens } from "../../../utils/LocalStorage";
import {
  getInfluenciadores,
  getInfluenciadoresTodosStatus,
  getInfluenciadorProps,
} from "../../../services/influenciador";
import { tipoOrdenacao } from "../../../@types/ordenacao";

interface FuncoesSelectAgenciaProps {
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setListaInfluenciadores: React.Dispatch<
    React.SetStateAction<getInfluenciadorProps[]>
  >;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setIdInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setEmailInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setPerfilInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  listaInfluenciadores: getInfluenciadorProps[];

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesSelectAgencia = ({
  numeroPagina,
  tamanhoPagina,
  setListaInfluenciadores,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setEmailInfluenciador,
  setIdInfluenciador,
  setNomeInfluenciador,
  setPerfilInfluenciador,
  listaInfluenciadores,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
}: FuncoesSelectAgenciaProps) => {
  function listarTodosInflueciadores() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getInfluenciadores(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        let influenciadores: getInfluenciadorProps[] =
          res.data.influenciadores.filter(
            (influenciador: getInfluenciadorProps) =>
              influenciador.status !== "AguardandoRemocao"
          );
        setListaInfluenciadores(influenciadores);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadores(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                let influenciadores: getInfluenciadorProps[] =
                  res.data.influenciadores.filter(
                    (influenciador: getInfluenciadorProps) =>
                      influenciador.status !== "AguardandoRemocao"
                  );
                setListaInfluenciadores(influenciadores);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de influenciadores.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de influenciadores.",
          });
          setCarregando(false);
        }
      });
  }

  function listarTodosInflueciadoresStatus() {
    let { token, tokenRenovacao } = obterTokens();
    setCarregando(true);
    getInfluenciadoresTodosStatus(
      token,
      "ativo",
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res1) => {
        setListaInfluenciadores(res1.data.influenciadores);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadoresTodosStatus(
              token,
              "ativo",
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res2) => {
                setListaInfluenciadores(res2.data.influenciadores);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Ocorreu um erro a buscar os influenciadores",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Ocorreu um erro a buscar os influenciadores",
          });
          setCarregando(false);
        }
      });
  }

  function pegaEmailENomeIncluenciadorComId(id: string) {
    setIdInfluenciador(id);

    let influenciador = listaInfluenciadores.find(
      (influenciador) => influenciador.idCredenciais === id
    );
    setEmailInfluenciador(influenciador ? influenciador.email : "");
    setNomeInfluenciador(influenciador ? influenciador.nome : "");
    setPerfilInfluenciador(influenciador ? influenciador.perfil : "");
  }

  return {
    pegaEmailENomeIncluenciadorComId,
    listarTodosInflueciadores,
  };
};
