import { useEffect, useState } from "react";

import { PublicacoesProps } from "../../../services/publicacao";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";
import { CampoInformacoes } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { TituloColuna } from "../../TituloColuna";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";

import styles from "./styles.module.css";
import { FuncoesTabelaMetricasTiktok } from "./functions";
import { PublicacaoIndividualTiktok } from "./PublicacaoIndividualTiktok";
import { format } from "../../../utils/MascaraNumero/format";
import { converteSegundoParaTempoCompleto } from "../../../utils/ConversorTempo";

export interface TabelaMetricasTiktokProps {
  listaDePublicacoes: PublicacoesProps[];
  nomeInfluenciadorSelecionado: string;
}

export const TabelaMetricasTikTok = ({
  listaDePublicacoes,
  nomeInfluenciadorSelecionado,
}: TabelaMetricasTiktokProps) => {
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<
    "Id" | "DataFinalizacao" | "Status" | string
  >("Id");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");

  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  const colunasTabela = [
    { nome: "Tempo de reprodução", ordenacao: "" },
    { nome: "Comentários", ordenacao: "" },
    { nome: "Likes", ordenacao: "" },
    { nome: "Shared", ordenacao: "" },
    { nome: "Salvos", ordenacao: "" },
    { nome: "Views", ordenacao: "" },
    { nome: "Espectadores", ordenacao: "" },
  ];

  const [viewsTotais, setViewsTotais] = useState<number>(0);
  const [tempoDeReproducao, setTempoDeReproducao] = useState<number>(0);
  const [comentarios, setComentarios] = useState<number>(0);
  const [likes, setLikes] = useState<number>(0);
  const [compartilhamentos, setCompartilhamentos] = useState<number>(0);
  const [salvos, setSalvos] = useState<number>(0);
  const [views, setViews] = useState<number>(0);
  const [espectadores, setEspectadores] = useState<number>(0);

  const { somaDadosPublicacaoTiktok } = FuncoesTabelaMetricasTiktok({
    setViewsTotais,
    setTempoDeReproducao,
    setComentarios,
    setLikes,
    setCompartilhamentos,
    setSalvos,
    setViews,
    setEspectadores,
  });

  useEffect(() => {
    somaDadosPublicacaoTiktok(listaDePublicacoes);
  }, []);

  return (
    <div className={styles.conteudoTotal}>
      <CampoInformacoes titulo={"Métricas"} corDeFundo={"var(--branco)"}>
        <table className={styles.containerTabela}>
          <thead>
            <tr>
              {colunasTabela.map((titulo) => {
                return (
                  <TituloColuna
                    key={colunasTabela.indexOf(titulo)}
                    colunaIndex={colunasTabela.indexOf(titulo)}
                    tituloColuna={titulo.nome}
                    tituloOrdenacao={titulo.ordenacao}
                    iconOrdenacao={[]}
                    tipoOrdenacao={tipoOrdenacao}
                    setTipoOrdenacao={setTipoOrdenacao}
                    nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                    setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody className={styles.corpoTabela}>
            {carregando ? (
              <Carregando
                listaUsuarios={listaDePublicacoes.length}
                spinner={true}
              />
            ) : (
              <>
                {erro.ativo ? (
                  <ErroTabela erro={erro} setRecarregar={setRecarregar} />
                ) : (
                  <>
                    {listaDePublicacoes && listaDePublicacoes.length === 0 ? (
                      <tr>
                        <td style={{ justifyContent: "center" }}>
                          Nenhum resultado a ser exibido.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          {converteSegundoParaTempoCompleto(tempoDeReproducao)}
                        </td>
                        <td>
                          {format("#.###.##0,#0", comentarios).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", likes).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", compartilhamentos).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", salvos).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", views).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", espectadores).replace(
                            ",00",
                            ""
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            )}
          </tbody>
          <tfoot className={styles.footerTabela}></tfoot>
        </table>

        <div className={styles.containerLista}>
          <div>
            <p>Tempo de reprodução: </p>
            {tempoDeReproducao}
          </div>
          <div>
            <p>Comentários: </p>
            {comentarios}
          </div>
          <div>
            <p>Likes: </p>
            {likes}
          </div>
          <div>
            <p>Shared: </p>
            {compartilhamentos}
          </div>
          <div>
            <p>Salvos: </p>
            {salvos}
          </div>
          <div>
            <p>Views: </p>
            {views}
          </div>
          <div>
            <p>Espectadores: </p>
            {espectadores}
          </div>
        </div>
      </CampoInformacoes>
      <CampoInformacoes titulo={"Publicações"}>
        <div
          id={"MetricasPublicacaoTikTok"}
          className={styles.campoPublicacoes}
        >
          {listaDePublicacoes.map((publicacao) => {
            return <PublicacaoIndividualTiktok publicacao={publicacao} />;
          })}
        </div>
      </CampoInformacoes>
    </div>
  );
};
