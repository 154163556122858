import { useEffect, useState, useContext } from "react";

import styles from "./styles.module.css";

import {
  basicDataProps,
  FuncoesGraficoInfluenciadoresDaCampanha,
} from "./functions";

import { campanha } from "../../../services/campanha";
import { tarefaProps } from "../../../services/tarefas";
import { ErroType } from "../../../@types/erro";
import { Carregando } from "../../Carregando";
import { CampoInformacoes } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";

import { useParams } from "react-router-dom";
import { ToggleButton } from "primereact/togglebutton";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "primereact/chart";
import { Chart as ChartJS } from "chart.js";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface GraficoInfluenciadoresDaCampanhaProps {
  campanha: campanha;
}

export const GraficoInfluenciadoresDaCampanha = ({
  campanha,
}: GraficoInfluenciadoresDaCampanhaProps) => {
  const [basicData, setBasicData] = useState<basicDataProps>({
    labels: [],
    datasets: [],
  });
  const [isTop3, setIsTop3] = useState<boolean>(false);

  const [tarefas, setTarefas] = useState<tarefaProps[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [desconectar, setDesconectar] = useState<boolean>(false);
  const [chartOptions, setChartOptions] = useState({});
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const { idCampanha } = useParams();

  ChartJS.register(ChartDataLabels);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { setaDados, pegaTarefasDaCampanha } =
    FuncoesGraficoInfluenciadoresDaCampanha({
      setBasicData,
      setTarefas,
      setCarregando,
      setErro,
      adicionarAListaEsperaRenovacaoToken,
      setChartOptions,
      id: idCampanha!,
    });

  useEffect(() => {
    setaDados(campanha, tarefas);
  }, [tarefas]);

  return (
    <CampoInformacoes
      titulo={
        <div className={styles.containerTitulo}>
          <div>Comparativo Influenciadores</div>
          <div className={styles.containerBotaoTop3}>
            <ToggleButton
              checked={!(tarefas.length > 0)}
              onChange={() => {
                tarefas.length > 0 ? setTarefas([]) : pegaTarefasDaCampanha();
                setIsTop3(!isTop3);
              }}
              onLabel="Ver Top 3 Influenciadores"
              offLabel="Ver Todos Influenciadores"
              className="w-full sm:w-10rem"
              aria-label="Confirmation"
            />
          </div>
        </div>
      }
      subtitulo={isTop3 ? "Top 3 Influenciadores com maior engajamento" : ""}
    >
      <div>
        <div className={styles.containerGrafico}>
          {carregando ? (
            <Carregando />
          ) : erro.ativo ? (
            <p>{erro.mensagem}</p>
          ) : (
            <>
              <Chart
                id="divGraficoInfluenciadoresDaCampanha"
                type="bar"
                data={basicData}
                options={chartOptions}
              />
            </>
          )}
        </div>
      </div>
    </CampoInformacoes>
  );
};
