import { useState, useEffect, useRef, useContext } from "react";

import { FuncoesListarUsuariosAgencia } from "./functions";

import { listaUsuarioAgenciaProps } from "../../../services/usuarioAgencia";

import { ErroType } from "../../../@types/erro";
import { TabelaListarUsuariosAgencia } from "../../Tabelas/TabelaListarUsuariosAgencia";
import { statusType } from "../../Select/SelectStatus";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListarUsuariosAgencia = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);
  const [abrirModalDeslogar, setAbrirModalDeslogar] = useState<boolean>(false);
  const [nomePesquisa, setNomePesquisa] = useState<string>("");
  const [idAgenciaPesquisa, setIdAgenciaPesquisa] = useState<string>("");
  const [modalCadastro, setModalCadastro] = useState<boolean>(false);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [propriedadeOrdenacao, setPropriedadeOrdenacao] =
    useState<string>("nome");

  const [listaUsuarios, setListaUsuarios] = useState<
    listaUsuarioAgenciaProps[]
  >([]);
  const [usuariosTotais, setUsuariosTotais] = useState<number>(0);
  const [numeroPagina, setNumeroPagina] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(99999);

  const [statusBusca, setStatusBusca] = useState<statusType>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterUsuarios,
    obterUsuariosPorNome,
    obterUsuariosPorAgencia,
    obterUsuariosPorStatus,
  } = FuncoesListarUsuariosAgencia({
    setListaUsuarios,
    adicionarAListaEsperaRenovacaoToken,
    setErro,
    setCarregando,
    setCarregandoPesquisa,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
    tipoOrdenacao,
    propriedadeOrdenacao,
    nomePesquisa,
    setUsuariosTotais,
    idAgencia: idAgenciaPesquisa,
    status: statusBusca,
  });

  useEffect(() => {
    nomePesquisa === ""
      ? idAgenciaPesquisa === "" || idAgenciaPesquisa === undefined
        ? statusBusca === "" || statusBusca === undefined
          ? obterUsuarios()
          : obterUsuariosPorStatus()
        : obterUsuariosPorAgencia()
      : obterUsuariosPorNome();
  }, [
    numeroPagina,
    tamanhoPagina,
    recarregar,
    nomePesquisa,
    idAgenciaPesquisa,
    statusBusca,
    tipoOrdenacao,
    propriedadeOrdenacao,
  ]);

  const myToast = useRef<any>(null);

  const colunas: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Agência", ordenacao: "agencia" },
    { nome: "Email", ordenacao: "email" },
    { nome: "Telefone", ordenacao: "telefone" },
    { nome: "Status", ordenacao: "status" },
    { nome: "Ações", ordenacao: "" },
  ];

  return (
    <>
      <TabelaListarUsuariosAgencia
        setErro={setErro}
        myToast={myToast}
        carregando={carregando}
        setCarregando={setCarregando}
        carregandoPesquisa={carregandoPesquisa}
        tipoOrdenacao={tipoOrdenacao}
        setTipoOrdenacao={setTipoOrdenacao}
        nomePropriedadeOrdenacao={propriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setPropriedadeOrdenacao}
        setNomeBusca={setNomePesquisa}
        setStatus={setStatusBusca}
        status={statusBusca}
        idAgenciaPesquisa={idAgenciaPesquisa}
        setIdAgenciaPesquisa={setIdAgenciaPesquisa}
        setRecarregar={() => setRecarregar(!recarregar)}
        modalAberto={modalCadastro}
        setModalAberto={setModalCadastro}
        erro={erro}
        tituloTabela={"Usuários Agência"}
        tituloColuna={colunas}
        propriedadeOrdenacao={propriedadeOrdenacao}
        setPropriedadeOrdenacao={setPropriedadeOrdenacao}
        listaUsuarios={listaUsuarios}
        setModalCadastroUsuarioAgencia={setModalCadastro}
        // paginacao={
        //   <Paginator
        //     first={numeroPagina}
        //     rows={1}
        //     totalRecords={usuariosTotais / tamanhoPagina}
        //     onPageChange={(e) => {
        //       setNumeroPagina(e.page);
        //       setNumeroPagina(e.first);
        //     }}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e) => setTamanhoPagina(Number(e.target.value))}
        //     totalLista={usuariosTotais}
        //     nomeLista={"usuários de agência"}
        //   />
        // }
      />
    </>
  );
};
