import { PublicacoesProps } from "../../../services/publicacao";

interface FuncoesTabelaMetricasTiktokProps {
  setViewsTotais: React.Dispatch<React.SetStateAction<number>>;
  setTempoDeReproducao: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaMetricasTiktok = ({
  setViewsTotais,
  setTempoDeReproducao,
  setComentarios,
  setLikes,
  setCompartilhamentos,
  setSalvos,
  setViews,
  setEspectadores
}: FuncoesTabelaMetricasTiktokProps) => {

  const somaDadosPublicacaoTiktok = (listaDePublicacoes: PublicacoesProps[]) => {
    let somaViewsTotais: number = 0;
    let somaTempoDeReproducao: number = 0;
    let somaComentarios: number = 0;
    let somaLikes: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaSalvos: number = 0;
    let somaViews: number = 0;
    let somaEspectadores: number = 0;


    listaDePublicacoes.map((publicacao) => {
      publicacao.viewsTotais && (
        somaViewsTotais = somaViewsTotais + publicacao.viewsTotais
      )
      publicacao.tempoReproducao && (
        somaTempoDeReproducao = somaTempoDeReproducao + publicacao.tempoReproducao
      )
      publicacao.comentarios && (
        somaComentarios = somaComentarios + publicacao.comentarios
      )
      publicacao.likes && (
        somaLikes = somaLikes + publicacao.likes
      )
      publicacao.compartilhamentos && (
        somaCompartilhamentos = somaCompartilhamentos + publicacao.compartilhamentos
      )
      publicacao.salvos && (
        somaSalvos = somaSalvos + publicacao.salvos
      )
      publicacao.views && (
        somaViews = somaViews + publicacao.views
      )
      publicacao.espectadores && (
        somaEspectadores = somaEspectadores + publicacao.espectadores
      )
    });

    setViewsTotais(somaViewsTotais);
    setTempoDeReproducao(somaTempoDeReproducao);
    setComentarios(somaComentarios);
    setLikes(somaLikes);
    setCompartilhamentos(somaCompartilhamentos);
    setSalvos(somaSalvos);
    setViews(somaViews);
    setEspectadores(somaEspectadores);

  }

  

  return {
    somaDadosPublicacaoTiktok,
  }
}