import React, { useState } from "react";

import { Button } from "../Button";
import { CaixaFlutuanteAtualizarTermos } from "../../CaixasFlutuantes/CaixaFlutuanteAtualizarTermos";

import styles from "./styles.module.css";

interface BotaoAtualizarAgenciaListaProps {
    setRecarregarTudo: React.Dispatch<React.SetStateAction<boolean>>
}

export const BotaoAtualizarTermos = ({
    setRecarregarTudo
}: BotaoAtualizarAgenciaListaProps) => {

    const [caixaAbertaTermos, setCaixaAbertaTermos] = useState<boolean>(false);

    return (
        <div className={styles.botaoIconeColunaAcoes}>
            <Button
                importancia="secundario"
                onClick={() => setCaixaAbertaTermos(!caixaAbertaTermos)}
            >
                {
                    <i
                        className="pi pi-plus-circle"
                        style={{ fontSize: "1.8rem" }}
                    />
                }
                Atualizar Termos
            </Button>
            {caixaAbertaTermos && <CaixaFlutuanteAtualizarTermos
                caixaAbertaTermos={caixaAbertaTermos}
                setCaixaAbertaTermos={setCaixaAbertaTermos}
                setRecarregarTudo={setRecarregarTudo}
            />
            }
        </div>
    );
};
