import {
  getUsuarioCredencias,
  postUsuarioLogin,
  putUsuarioRemoverDadosUsuarioPendentes,
} from "../../services/usuario";
import { salvarTokens } from "../../utils/LocalStorage";
import { NavigateFunction } from "react-router-dom";
import { useContext } from "react";
import { CredenciaisContext } from "../../context/CredenciaisContext";

export interface FunctionLoginProps {
  email: string;
  senha: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  idCredenciais: string;
  setIdCredenciais: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  fazerRequisicoeDeAgenciaEMarca: () => void;
}

export const FunctionLogin = ({
  email,
  senha,
  setCarregando,
  myToast,
  setIdCredenciais,
  navigate,
  fazerRequisicoeDeAgenciaEMarca,
}: FunctionLoginProps) => {
  const { atualizarCredenciais } = useContext(CredenciaisContext);

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "";
    let confirmacao: boolean = false;


    switch (mensagemApi) {
      case "E-mail do usuário não foi confirmado.":
        mensagem = "E-mail do usuário não foi confirmado";
        confirmacao = true;
        break;
      case "Usuário não autorizado.":
        mensagem = "Usuário e/ou senha incorreto";
        break;

      default:
        mensagem =
          "Verifique as informações.";
    }

    showToast("error", "Não foi possível realizar o login", mensagem, confirmacao);
  };

  function fazerLogin() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const redirecionamento = urlParams.get("redirecionamento")!;

    setCarregando(true);
    postUsuarioLogin(email, senha)
      .then((res) => {
        const { tokenAcesso, tokenRenovacao } = res.data.camposAdicionais;
        salvarTokens(tokenAcesso, tokenRenovacao);
        getUsuarioCredencias(email, tokenAcesso)
          .then((res1) => {
            atualizarCredenciais(res1.data.resultado.credenciais);

            putUsuarioRemoverDadosUsuarioPendentes();

            setIdCredenciais(res1.data.resultado.credenciais.id);

            let termosAceitos =
              res1.data.resultado.credenciais.termosDeUsoAceitos;

            return termosAceitos;
          })
          .then((termosAceitos) => {
            fazerRequisicoeDeAgenciaEMarca();
            termosAceitos
              ? redirecionamento
                ? navigate(redirecionamento)
                : navigate("/home/")
              : navigate("/aceite-de-termos");

            setCarregando(false);
          })
          .catch((err) => {
            setCarregando(false);
            erroRequisicao(err.response.data.mensagem);
          });
      })
      .catch((err) => {
        setCarregando(false);
        erroRequisicao(err.response.data.mensagem);
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    confirmacao?: boolean,
  ) => {
    confirmacao ?
      myToast.current.show([
        {
          severity: severityValue,
          summary: summaryValue,
          detail: detailValue,
        },
        {
          severity: 'info',
          summary: "E-mail do usuário não foi confirmado",
          detail: "Entre em contato conosco para reenviarmos o e-mail de confirmação",
          sticky: true,
        },
      ]
      )
      :
      myToast.current.show([
        {
          severity: severityValue,
          summary: summaryValue,
          detail: detailValue,
        },
      ]
      )

  };

  return {
    fazerLogin,
    showToast,
  };
};
