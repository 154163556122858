import imageCompression from "browser-image-compression";
import React from "react";

export const converteImgPara64 = async (
  event: any,
  setImagem: React.Dispatch<any>,
  naoImagem?: boolean,
  setNomeArquivo?: React.Dispatch<React.SetStateAction<string>>
) => {
  let imagemOriginal = event.target.files[0];
  
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1300,
    useWebWorker: true
  }

  try {
    let imagemComprimida

    naoImagem ?
      imagemComprimida = imagemOriginal
      :
      imagemComprimida = await imageCompression(imagemOriginal, options);

    var reader = new FileReader();
    reader.readAsDataURL(imagemComprimida);
    reader.onload = function () {
      setImagem(reader.result);
      setNomeArquivo && setNomeArquivo(imagemOriginal.name)
    };
    reader.onerror = function (error) {
      console.log('Error FileReader: ', error)
    };
  } catch (error) {
    console.log(error);
  }
};
