import React, { useContext, useState } from 'react';

import { tarefaProps } from '../../../../services/tarefas';
import { tipoOrdenacao } from '../../../../@types/ordenacao';
import { RenovarTokenContext } from '../../../../context/RenovarTokenContext';
import { obterCredenciais } from '../../../../utils/LocalStorage';

import { Header } from './components/Templates/Header';
import { Body } from './components/Templates/Body';
import { ModalEditarValorDeContrato } from '../../../Modais/ModalEditarValorDeContrato';
import { ModalAtualizarInfluenciador } from '../../../Modais/ModalAtualizarInfluenciador';
import { ModalDesativacao } from '../../../Modais/ModalDesativacao';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';

import { FunctionDeletarInfluenciadorDaTarefa } from '../deletarInfluenciador';

import { Carregando } from '../../../Carregando';
import styles from './styles.module.css'


interface TabelaListarTarefasIncluidasProps {
  idCampanha: string;
  listaDeTarefas: tarefaProps[];
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao?: React.Dispatch<React.SetStateAction<string>>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao?: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  taxaEngajamentoCampanha: number;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
  setIdInfluenciadorSelecionado?: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciadorSelecionado?: React.Dispatch<React.SetStateAction<string>>;
  setAvatarInfluenciadorSelecionado?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setIndiceBarraLateral?: React.Dispatch<React.SetStateAction<number>>;
  setTituloCaixa?: React.Dispatch<any>;
  carregando?: boolean;
  relatorio?: boolean;
}

export const TabelaListarTarefasIncluidas = ({
  idCampanha,
  listaDeTarefas,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  tipoOrdenacao,
  setTipoOrdenacao,
  taxaEngajamentoCampanha,
  setRecarregar,
  setIdInfluenciadorSelecionado,
  setNomeInfluenciadorSelecionado,
  setAvatarInfluenciadorSelecionado,
  setIndiceBarraLateral,
  setTituloCaixa,
  carregando,
  relatorio
}: TabelaListarTarefasIncluidasProps) => {
  const { credenciais: { perfil } } = obterCredenciais();

  const [modalAberto, setModalAberto] = useState<boolean>(false);
  const [modalEditarInfluenciador, setModalEditarInfluenciado] =
    useState<boolean>(false);
  const [modalDesativacao, setModalDesativacao] = useState<boolean>(false);
  const [carregandoModalDeletar, setCarregandoModalDeletar] =
    useState<boolean>(false);
  const [tarefaSelecionada, setTarefaSelecionada] = useState<tarefaProps>({} as tarefaProps);
  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { deletarInfluenciadorNaTarefa } = FunctionDeletarInfluenciadorDaTarefa(
    {
      idCampanha: idCampanha!,
      idTarefa: tarefaSelecionada.externalId,
      setCarregando: setCarregandoModalDeletar,
      setRecarregar: setCarregandoModalDeletar,
      setModalDesativacao,
      recarregarTabela: () => setRecarregar!(state => !state),
      adicionarAListaEsperaRenovacaoToken,
    }
  );

  const {
    HeaderInfluenciador,
    HeaderAlcance,
    HeaderEngajamento,
    HeaderTaxaEngajamento,
    HeaderComparativoCampanha,
    HeaderCPE,
    HeaderCPM,
    HeaderValorContrato,
    HeaderEntregas,
    HeaderAcoes
  } = Header({
    nomePropriedadeOrdenacao,
    setNomePropriedadeOrdenacao: setNomePropriedadeOrdenacao!,
    tipoOrdenacao,
    setTipoOrdenacao: setTipoOrdenacao!,
    relatorio
  })

  const {
    BodyInfluenciador,
    BodyAlcance,
    BodyEngajamento,
    BodyTaxaEngajamento,
    BodyComparativoCampanha,
    BodyCPE,
    BodyCPM,
    BodyValorContrato,
    BodyEntregas,
    BodyAcoes
  } = Body({
    taxaEngajamentoCampanha,
    setTarefaSelecionada,
    setModalAberto,
    setModalDesativacao,
    setModalEditarInfluenciado,
    relatorio
  })

  const handleSelection = (rowData: tarefaProps) => {
    const influenciador = rowData.influenciador;
    const idCredenciais = influenciador.idCredenciais;
    const nome = influenciador.nome;
    const avatarUrl = influenciador.urlAvatar;

    setIdInfluenciadorSelecionado!(idCredenciais);
    setNomeInfluenciadorSelecionado!(nome);
    setAvatarInfluenciadorSelecionado!(avatarUrl);
    setIndiceBarraLateral!(5);
    setTituloCaixa!(
      <>
        <Avatar
          label={nome.charAt(0)}
          shape="circle"
          size="xlarge"
          image={avatarUrl}
          style={{
            textTransform: "uppercase",
            borderRadius: "50%",
          }}
        />
        <p
          className={styles.nomeTopoCaixa}
        >
          {nome}
        </p>
      </>
    );
  }

  const respostaVazia = () => {
    return (
      <>
        {carregando ?
          <div className={styles.containerCarregando}>
            <Carregando listaUsuarios={listaDeTarefas.length} spinner={true} />
          </div>
          :
          <div className={styles.containerRespostaVazia}>
            <p className={styles.respostaVazia}>Nenhum resultado a ser exibido.</p>
          </div>
        }
      </>
    )
  }

  return (
    <>
      <div
        className={styles.tabela}
        style={{ width: '100%', maxWidth: '1366px' }}
      >
        <DataTable
          value={listaDeTarefas}
          emptyMessage={respostaVazia}
          size='small'
          selectionMode='single'
          onRowClick={(e) => handleSelection(e.data)}
        >
          <Column header={HeaderInfluenciador} body={BodyInfluenciador} frozen></Column>
          <Column header={HeaderAlcance} body={BodyAlcance}></Column>
          <Column header={HeaderEngajamento} body={BodyEngajamento}></Column>
          <Column header={HeaderTaxaEngajamento} body={BodyTaxaEngajamento}></Column>
          <Column header={HeaderComparativoCampanha} body={BodyComparativoCampanha}></Column>
          <Column header={HeaderCPE} body={BodyCPE}></Column>
          <Column header={HeaderCPM} body={BodyCPM}></Column>
          <Column header={HeaderValorContrato} body={BodyValorContrato}></Column>
          <Column header={HeaderEntregas} body={BodyEntregas}></Column>
          {
            (perfil[0] === "UsuarioNetcos" || perfil[0] === "Administrador") && !relatorio &&
            <Column header={HeaderAcoes} body={BodyAcoes}></Column>
          }
        </DataTable>
      </div>

      {modalAberto && (
        <ModalEditarValorDeContrato
          idCampanha={idCampanha}
          recarregarTabela={() => setRecarregar!((state) => !state)}
          tarefa={tarefaSelecionada!}
          moodalAberto={modalAberto}
          setModalAberto={setModalAberto}
        />
      )}

      {modalEditarInfluenciador && (
        <ModalAtualizarInfluenciador
          modalAberto={modalEditarInfluenciador}
          setModalAberto={setModalEditarInfluenciado}
          setRecarregar={() => setRecarregar!((state) => !state)}
          emailPrevio={tarefaSelecionada.influenciador.email}
          idCredenciais={tarefaSelecionada.influenciador.idCredenciais}
          imagemAvatarPrevia={tarefaSelecionada.influenciador.urlAvatar}
          instagramPrevio={tarefaSelecionada.influenciador.linkInstagram}
          nomePrevio={tarefaSelecionada.influenciador.nome}
          perfilPrevio={tarefaSelecionada.influenciador.perfil}
          statusPrevio={tarefaSelecionada.influenciador.status}
          telefonePrevio={tarefaSelecionada.influenciador.telefone}
          tiktokPrevio={tarefaSelecionada.influenciador.linkTiktok}
          twitterPrevio={tarefaSelecionada.influenciador.linkTwitter}
          youTubePrevio={tarefaSelecionada.influenciador.linkYoutube}
        />
      )}

      {modalDesativacao && (
        <ModalDesativacao
          remocaoDaCampanha
          carregando={carregandoModalDeletar}
          modalAberto={modalDesativacao}
          setModalAberto={setModalDesativacao}
          nomeItemParaDesativar={tarefaSelecionada.influenciador.nome}
          onClick={() =>
            deletarInfluenciadorNaTarefa(idCampanha!, tarefaSelecionada.externalId)
          }
        />
      )}
    </>

  );
};