import { SetStateAction } from "react";
import { ErroType } from "../../../@types/erro";
import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";
import {
  dadosUsuarioProps,
  putUsuarioReativacao,
} from "../../../services/usuario";
import {
  putUsuarioNetcos,
  usuarioNetcosProps,
} from "../../../services/usuarioNetcos";
import { obterTokens } from "../../../utils/LocalStorage";

interface FunctionsTabelaListarUsuarioNetCosProps {
  idUsuarioNetcos: string;
  nomeUsuarioNetcos: string;
  telefoneUsuarioNetcos: string;
  imagemAvatarUsuarioNetcos: string;
  myToast: React.MutableRefObject<any>;
  setUsuarioNetCosSelecionado: React.Dispatch<
    React.SetStateAction<usuarioNetcosProps | undefined>
  >;
  setModalAbertoDesativacao: React.Dispatch<SetStateAction<boolean>>;
  setModalEditarUsuarioNetCos: React.Dispatch<React.SetStateAction<boolean>>;
  setModalRedefinicao: React.Dispatch<React.SetStateAction<boolean>>;
  setModalReenvioSenha: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: () => void;
  setCarregando: React.Dispatch<SetStateAction<boolean>>;
  setErro: React.Dispatch<ErroType>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsTabelaListarUsuarioNetCos = ({
  idUsuarioNetcos,
  nomeUsuarioNetcos,
  telefoneUsuarioNetcos,
  imagemAvatarUsuarioNetcos,
  myToast,
  setUsuarioNetCosSelecionado,
  setModalEditarUsuarioNetCos,
  setModalRedefinicao,
  setModalReenvioSenha,
  setModalAbertoDesativacao,
  setRecarregar,
  setCarregando,
  setErro,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsTabelaListarUsuarioNetCosProps) => {
  function abriModalEditar(usuarioNetcos: usuarioNetcosProps) {
    setModalEditarUsuarioNetCos(true);
    setUsuarioNetCosSelecionado(usuarioNetcos);
  }

  function abriModalRedefinicao(usuarioNetcos: usuarioNetcosProps) {
    setModalRedefinicao(true);
    setUsuarioNetCosSelecionado(usuarioNetcos);
  }

  function abriModalReenvioSenha(usuarioNetcos: usuarioNetcosProps) {
    setModalReenvioSenha(true);
    setUsuarioNetCosSelecionado(usuarioNetcos);
  }

  function desativarUsuarioNetcos() {
    const sucessoRequisicao = () => {
      showToast(
        "success",
        "Desativação realizada com sucesso",
        "Agencia desativada!"
      );
    };

    const erroRequisicao = () => {
      showToast(
        "error",
        "Desativação não realizada",
        "Não foi possível desativar o usuário."
      );
      setModalAbertoDesativacao(false);
    };
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();

    setCarregando(true);

    putUsuarioNetcos(
      token,
      {
        id: idUsuarioNetcos,
        nome: nomeUsuarioNetcos,
        imagemAvatar: null,
        telefone: telefoneUsuarioNetcos.toString(),
        status: "Removido"
      }
    )
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
        setModalAbertoDesativacao(false);
        setTimeout(function () {
          setRecarregar();
        }, 1500);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioNetcos(
                token,
                {
                  id: idUsuarioNetcos,
                  nome: nomeUsuarioNetcos,
                  imagemAvatar: null,
                  telefone: telefoneUsuarioNetcos.toString(),
                  status: "Removido"
                }
              )
                .then(() => {
                  sucessoRequisicao();
                })
                .catch((err) => {
                  erroRequisicao();
                });
            },
            () => {
              setCarregando(false);
              setModalAbertoDesativacao(false);
              setTimeout(function () {
                setRecarregar();
              }, 1500);
            }
          );
        } else {
          erroRequisicao();
          setCarregando(false);
          setTimeout(function () {
            setRecarregar();
          }, 1500);
        }
      });
  }

  function sucessoReativacao() {
    showToast(
      "success",
      "Reativação realizada com sucesso",
      "Usuário reativado!"
    );
  }

  function falhaReativacao() {
    showToast(
      "error",
      "Reativação não realizada",
      "Não foi possível reativar o usuário"
    );
  }

  function reativarUsuario() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);

    let dadosUsuario: dadosUsuarioProps = {
      id: idUsuarioNetcos,
      nome: nomeUsuarioNetcos,
      imagemAvatar: imagemAvatarUsuarioNetcos,
      status: "Ativo",
      telefone: telefoneUsuarioNetcos.toString(),
      termosDeUsoAceitos: true,
    };

    const { token, tokenRenovacao } = obterTokens();
    putUsuarioReativacao(token, dadosUsuario)
      .then(() => {
        sucessoReativacao();
        setCarregando(false);
        setRecarregar();
        setModalAbertoDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioReativacao(token, dadosUsuario)
                .then(() => sucessoReativacao())
                .catch(() => falhaReativacao());
            },
            () => {
              setCarregando(false);
              setRecarregar();
              setModalAbertoDesativacao(false);
            }
          );
        } else {
          falhaReativacao();
          setCarregando(false);
          setRecarregar();
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  function criaPDFListaDeUsuariosNetCos() {
    const minhaTabela = document.getElementById(
      "tabelaListarUsuariosNetCos"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>E-mail</th><th>Telefone</th><th>Status</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }

  return {
    abriModalEditar,
    criaPDFListaDeUsuariosNetCos,
    desativarUsuarioNetcos,
    reativarUsuario,
    abriModalRedefinicao,
    abriModalReenvioSenha,
  };
};
