import { useEffect, useState, useContext } from "react";

import styles from "./styles.module.css";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { FuncoesAtualizacaoUsuarioNetcos } from "./functions";

import { Input } from "../../Input";
import { Carregando } from "../../Carregando";
import { Button } from "../../Botoes/Button";

import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { ModalTeste } from "../ModalTeste";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";

export interface ModalAtualizacarDeUsuarioNetcosProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: () => void;
  id: string;
  nomePrevio: string;
  telefonePrevio: string;
  imagemAvatarPrevia: string;
  statusPrevio: string;
}

export const ModalAtualizacaoDeUsuarioNetcos = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
  id,
  imagemAvatarPrevia,
  nomePrevio,
  telefonePrevio,
  statusPrevio,
}: ModalAtualizacarDeUsuarioNetcosProps) => {
  const [telefone, setTelefone] = useState<number>(parseInt(telefonePrevio));
  const [nome, setNome] = useState<string>(nomePrevio);
  const [imagemAvatar, setImagemAvatar] = useState<string>("");

  const [carregando, setCarregando] = useState<boolean>(false);

  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] =
    useState<string>("");

  useEffect(() => {
    MascaraTelefone(
      telefonePrevio.toString(),
      setTelefone,
      setMascaraNumeroWhatsapp
    );
  }, []);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { atualizarUsuarioNetcos, fazMascaraTelefone } =
    FuncoesAtualizacaoUsuarioNetcos({
      id,
      telefone,
      setTelefone,
      nome,
      setNome,
      imagemAvatar: imagemAvatar ? imagemAvatar : null,
      setImagemAvatar,
      status: statusPrevio,
      setCarregando,
      setModalAberto,
      setRecarregar,
      adicionarAListaEsperaRenovacaoToken,
      MascaraNumeroWhatsapp,
      setMascaraNumeroWhatsapp,
    });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo="Atualizar Usuário Netcos"
    >
      <div
        className={styles.containerModalAtualizacaoDeUsuarioNetcos}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <BotaoUploadImagem
            setImg={setImagemAvatar}
            imagemAnterior={imagemAvatarPrevia}
          />
          <div className={styles.containerDados}>
            <div className={styles.containerInputs}>
              <Input
                type="text"
                value={nome}
                placeholder="Nome"
                required
                onChange={(e) => setNome(e.target.value)}
              />

              <PhoneInput
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
              />
            </div>
          </div>
        </body>

        <footer>
          {carregando ? (
            <Carregando />
          ) : (
            <div className={styles.containerBotoesModal}>
              <Button
                onClick={() => setModalAberto(false)}
                importancia="terciario"
                title="Cancelar"
              />
              <Button
                importancia="primario"
                title="Confirmar"
                onClick={atualizarUsuarioNetcos}
              />
            </div>
          )}
        </footer>
      </div>
    </ModalTeste>
  );
};
