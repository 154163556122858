import styles from "./styles.module.css";

export interface BotaoBusca {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string;
  carregandoPesquisa?: boolean
}


export const BotaoBusca = ({ onChange, value, carregandoPesquisa }: BotaoBusca) => {
  const larguraTela = window.innerWidth;

  return (
    <div
      className={styles.caixaDeBusca}
      >
      <input
        value={value}
        onChange={onChange}
        className={larguraTela < 768 ? styles.inputBuscaAberto : styles.inputBusca}
        type="search"
        placeholder="Pesquisar"
        />
      <div className={styles.botaoBuscar} title="Pesquisar">
        {carregandoPesquisa ?
          <i
          className="pi pi-spin pi-spinner"
          style={{ fontSize: "1.4rem", paddingRight: "1rem", paddingLeft: "1rem" }}
          />
          :
          <i
          className="pi pi-search"
          style={{ fontSize: "1.4rem", paddingRight: "1rem", paddingLeft: "1rem" }}
          />
        }
      </div>
    </div>
  )
}
