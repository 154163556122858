import { useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarMarcas } from "./functions";

import { getMarcaProps } from "../../../services/marca";
import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Carregando } from "../../Carregando";
import { ModalCadastroUsuarioMarca } from "../../Modais/ModalCadastroUsuarioMarca";
import { ErroTabela } from "../../ErroTabela";
import { BotaoCadastrarMarca } from "../../Botoes/BotaoCadastrarMarca";
import { BotaoAtualizarMarcaLista } from "../../Botoes/BotaoAtualizarMarcaLista";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { TituloColuna } from "../../TituloColuna";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { Avatar } from "primereact/avatar";
import { BsRecordCircleFill } from "react-icons/bs";
import { CardMarca } from "../../Cards/CardMarca";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface TabelaListarMarcasProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  listaMarcas: getMarcaProps[];
  tipoOrdenacao: tipoOrdenacao;
  carregando: boolean;
  myToast: React.MutableRefObject<any>;
  setTipoOrdenacao: React.Dispatch<tipoOrdenacao>;
  setNomeMarcaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  nomePropriedadeOrdenacao: string;
  erro: ErroType;
  setNomePropriedadeOrdenacao: React.Dispatch<string>;
}

export const TabelaListarMarcas = ({
  tituloTabela,
  tituloColuna,
  listaMarcas,
  erro,
  carregando,
  tipoOrdenacao,
  myToast,
  setTipoOrdenacao,
  setNomeMarcaPesquisa,
  setRecarregar,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
}: TabelaListarMarcasProps) => {
  const [, setImagemAvatar] = useState<string>("");
  const [idMarca, setIdMarca] = useState<string>("");
  const [nomeMarca, setNomeMarca] = useState<string>("");
  const [logotipoMarca, setLogotipoMarca] = useState<string>("");
  const [botaoAbertoMarca, setBotaoAbertoMarca] = useState<boolean>(false);
  const [modalAbertoUsuarioMarca, setModalAbertoUsuarioMarca] =
    useState<boolean>(false);
  const [modalAbertoDesativacao, setModalAbertoDesativacao] =
    useState<boolean>(false);
  const [carregandoDesativacao, setCarregandoDesativacao] =
    useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { criaPDFListaDeMarcas, desativarMarca } = FunctionsTabelaListarMarcas({
    idMarca,
    nomeMarca,
    logotipoMarca,
    myToast,
    setCarregandoDesativacao,
    adicionarAListaEsperaRenovacaoToken,
    setModalAbertoDesativacao,
    setRecarregar,
  });

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>

              <BotaoCadastrarMarca
                botaoAbertoMarca={botaoAbertoMarca}
                setBotaoAbertoMarca={setBotaoAbertoMarca}
                setImg={setImagemAvatar}
                setRecarregar={setRecarregar}
              />
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                onChange={(e) => setNomeMarcaPesquisa(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaDeMarcas} />
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 1, 2, 3]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody id="corpoTabelaListarMarcas" className={styles.corpoTabela}>
        {carregando ? (
          <Carregando listaUsuarios={listaMarcas.length} spinner={true} />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {listaMarcas && listaMarcas.length === 0 ? (
                  <tr>
                    <td style={{ justifyContent: "center" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaMarcas.map((marca) => {
                    return (
                      <tr key={marca.id}>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={marca.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={marca.urlLogotipo}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{marca.nome}</p>
                        </td>
                        <td>{marca.totalCampanhas}</td>
                        <td>{marca.totalUsuarios}</td>
                        <td>
                          <div
                            className={`${styles.status} ${
                              styles[marca.ativo.toString()]
                            }`}
                          >
                            <BsRecordCircleFill
                              style={
                                marca.ativo === true
                                  ? {
                                      color: "var(--verde-400)",
                                      fontSize: "1rem",
                                    }
                                  : {
                                      color: "var(--cinza-400)",
                                      fontSize: "1rem",
                                    }
                              }
                            />
                            {marca.ativo === true ? "Ativo" : "Inativo"}
                          </div>
                        </td>

                        <td className="colunaBotoesAcoes">
                          <BotaoAtualizarMarcaLista
                            idMarca={marca.id}
                            nomeMarca={marca.nome}
                            logotipo={marca.urlLogotipo}
                            setRecarregar={setRecarregar}
                            status={marca.ativo}
                          />
                          <BotaoColunaAcoes
                            status={!marca.ativo ? "Removido" : ""}
                            icone={"pi-user-plus"}
                            titulo={"Adicionar Usuário"}
                            onClick={() => {
                              setModalAbertoUsuarioMarca(true);
                            }}
                          />
                          <BotaoColunaAcoes
                            status={!marca.ativo ? "Removido" : ""}
                            icone={"pi-trash"}
                            titulo={"Remover Marca"}
                            onClick={() => {
                              setIdMarca(marca.id);
                              setNomeMarca(marca.nome);
                              setLogotipoMarca(marca.urlLogotipo);
                              setModalAbertoDesativacao(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}

                {modalAbertoUsuarioMarca && (
                  <ModalCadastroUsuarioMarca
                    modalAberto={modalAbertoUsuarioMarca}
                    setModalAberto={setModalAbertoUsuarioMarca}
                    setRecarregar={setRecarregar}
                  />
                )}

                {modalAbertoDesativacao && (
                  <ModalDesativacao
                    carregando={carregandoDesativacao}
                    modalAberto={modalAbertoDesativacao}
                    setModalAberto={setModalAbertoDesativacao}
                    nomeItemParaDesativar={nomeMarca}
                    onClick={desativarMarca}
                  />
                )}
              </>
            )}
          </>
        )}
      </tbody>

      <div className={styles.listaCardsMarca}>
        {listaMarcas.length > 0 ? (
          listaMarcas.map((marca, index) => {
            return (
              <CardMarca
                marca={marca}
                setRecarregar={setRecarregar}
                setIdMarca={setIdMarca}
                setNomeMarca={setNomeMarca}
                setLogotipoMarca={setLogotipoMarca}
                setModalAbertoUsuarioMarca={setModalAbertoUsuarioMarca}
                setModalAbertoDesativacao={setModalAbertoDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
    </table>
  );
};
