import React, { useState, useContext } from "react";

import { Button } from "../../Botoes/Button";
import { BotaoCadastrarMarca } from "../../Botoes/BotaoCadastrarMarca";

import { Dropdown } from "primereact/dropdown";

import { MarcaContext } from "../../../context/MarcaContext";

import styles from "./styles.module.css";

export interface listaMarcasSelectProps {
  setIdMarca?: React.Dispatch<React.SetStateAction<string>>;
  idMarca?: string;
  setIdMarcas?: React.Dispatch<React.SetStateAction<string[]>>;
  idMarcas?: string[];
  myToast?: React.MutableRefObject<any>;
}

export const SelectMarca = ({
  setIdMarcas,
  idMarcas,
  setIdMarca,
  idMarca,
  myToast,
}: listaMarcasSelectProps) => {
  const [, setImagemAvatar] = useState<string>("");

  const [botaoAbertoMarca, setBotaoAbertoMarca] = useState<boolean>(false);

  const {
    carregandoListaDeMarcas,
    erroListaDeMarcas,
    listaDeMarcas,
    recarregarListaDeMarcas,
  } = useContext(MarcaContext);

  return erroListaDeMarcas.ativo ? (
    <p style={{ zIndex: "1", position: "relative" }}>
      {erroListaDeMarcas.mensagem}
    </p>
  ) : (
    <div className={styles.conteudoSelectMarca}>
      <Dropdown
        emptyMessage={
          carregandoListaDeMarcas ? "Carregando..." : "Nenhuma marca cadastrada"
        }
        placeholder={"Seleciona uma marca"}
        id="select-marca"
        value={idMarca ? idMarca : idMarcas && idMarcas[0]}
        options={listaDeMarcas.filter((marca) => marca.ativo)}
        onChange={(e) =>
          setIdMarca
            ? setIdMarca(e.target.value)
            : setIdMarcas && setIdMarcas([e.target.value])
        }
        optionValue="id"
        optionLabel="nome"
        filter
        resetFilterOnHide
        filterPlaceholder="Encontre uma marca"
        emptyFilterMessage={
          <Button
            importancia="secundario"
            title="Cadastrar Marca"
            onClick={() => setBotaoAbertoMarca(true)}
          />
        }
        showClear
        style={{
          width: "100%",
          color: "#fff",
          outline: "none",
          boxShadow: "none",
          fontFamily: "Exo 2",
          height: "3.5rem",
        }}
        panelStyle={{
          fontSize: "1.2rem",
        }}
        filterBy="nome"
      />
      <BotaoCadastrarMarca
        botaoAbertoMarca={botaoAbertoMarca}
        setBotaoAbertoMarca={setBotaoAbertoMarca}
        setImg={setImagemAvatar}
        setRecarregar={recarregarListaDeMarcas}
        modal
      />
    </div>
  );
};
