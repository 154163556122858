import { NavigateFunction } from "react-router-dom";

import { limpaLocalStorage } from "../../../utils/LocalStorage";

export interface FunctionsModalDeslogarProps {
  removerCredenciais: () => void;
  navigate: NavigateFunction;
  setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FunctionsModalDeslogar = ({
  removerCredenciais,
  navigate,
  setAbrirModal,
}: FunctionsModalDeslogarProps) => {
  function deslogar() {
    removerCredenciais();
    limpaLocalStorage();
    setAbrirModal(false);
    navigate("/");
  }

  return {
    deslogar,
  };
};
