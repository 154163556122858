import { ErroType } from "../../../@types/erro";
import { getTokenDeLongaDuracao } from "../../../services/influenciador";
import { obterTokens } from "../../../utils/LocalStorage";

export interface FuncoesModalAvisoOptinProps {
  tokenAcesso: string;
  appId: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesModalAvisoOptin = ({
  tokenAcesso,
  appId,
  adicionarAListaEsperaRenovacaoToken,
  setCarregando,
  setErro,
}: FuncoesModalAvisoOptinProps) => {
  const { token } = obterTokens();

  function tokenLongaDuracao(tokenAcesso: string) {
    getTokenDeLongaDuracao(token, tokenAcesso, (appId = "741129074259476"))
      .then((res) => {
        const jsonResponse = JSON.parse(res.data.resposta);
        const tokenLongaDuracao = jsonResponse.access_token;
        localStorage.setItem("tokenLongaDuracao", tokenLongaDuracao);
        console.log('token vindo do back', tokenLongaDuracao);
        
      })
      .catch(async (err) => {
        if (err.message.includes("401")) {
          await adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTokenDeLongaDuracao(
              token,
              tokenAcesso,
              (appId = "741129074259476")
            ).then((res) => {
              const jsonResponse = JSON.parse(res.data.resposta);
              const tokenLongaDuracao = jsonResponse.access_token;
              localStorage.setItem("tokenLongaDuracao", tokenLongaDuracao);
              
            });
          }, setCarregando);
        } else {
          setCarregando(false);
          setErro({
            ativo: true,
            mensagem: "Ocorreu um erro a carregar sua campanha.",
          });
        }
      });
  }
  return {
    tokenLongaDuracao,
  };
};
