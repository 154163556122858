import { ListagemPublicaçõesInfluenciador } from "../../ListagemPublicaçõesInfluenciador";
import styles from "./styles.module.css";


interface TabInformacoesCampanhaProps {
  dataInicioCampanha:string
  dataRelatorio:string
}

export const TabInformacoesCampanha = ({
  dataInicioCampanha,
  dataRelatorio
}:TabInformacoesCampanhaProps) => {
  
  return (
    <>
      <div id={styles.containerDetalhesInfluenciador}>
        <ListagemPublicaçõesInfluenciador 
          dataInicioCampanha={dataInicioCampanha}
          dataRelatorio={dataRelatorio}
        />
        {/* <TabView>
          <TabPanel header="Resultados Gerais">
            <VisaoGeralInfluenciador alcanceTotal={alcanceTotal} custoEngajamento={custoEngajamento} custoPorMilImpressoes={custoPorMilImpressoes} taxaEngajamento={taxaEngajamento} idCampanha={idCampanha!} idInfluenciador={credenciais.id} comentarioNetcos={comentarioNetcos} />
          </TabPanel>
          <TabPanel header="Análises Repercussão">
            <CampoInformacoes titulo="Análises Repercussão"></CampoInformacoes>
          </TabPanel>
          <TabPanel header="Publicações">
            <CampoInformacoes titulo="">
              <ListagemPublicaçõesInfluenciador />
            </CampoInformacoes>
          </TabPanel>
        </TabView> */}
      </div>
    </>
  );
};
