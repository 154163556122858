import { useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarAgencias } from "./functions";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { ErroType } from "../../../@types/erro";
import { listaAgenciaProps } from "../../../services/agencia";
import { ModalCadastroUsuarioAgencia } from "../../Modais/ModalCadastroUsuarioAgencia";
import { BotaoCadastrarAgencia } from "../../Botoes/BotaoCadastrarAgencia";
import { BotaoAtualizarAgenciaLista } from "../../Botoes/BotaoAtualizarAgenciaLista";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TituloColuna } from "../../TituloColuna";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { CardAgencia } from "../../Cards/CardAgencia";

import { BsRecordCircleFill } from "react-icons/bs";
import { Avatar } from "primereact/avatar";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface TabelaListarAgenciasProps {
  tituloTabela: string;
  tituloColuna: {
    nome: string;
    ordenacao: string;
  }[];
  listaDeAgencias: listaAgenciaProps[];
  erro: ErroType;
  carregando: boolean;
  setNomeDaAgenciaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  myToast: React.MutableRefObject<any>;
  setTipoOrdenacao: (tipoOrdenacao: tipoOrdenacao) => void;
  tipoOrdenacao: tipoOrdenacao;
  setNomePropriedadeOrdenacao: (nomePropriedadeOrdenacao: string) => void;
  nomePropriedadeOrdenacao: string;
}

export const TabelaListarAgencias = ({
  tituloTabela,
  tituloColuna,
  listaDeAgencias,
  erro,
  carregando,
  setRecarregar,
  myToast,
  setNomeDaAgenciaPesquisa,
  setNomePropriedadeOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
}: TabelaListarAgenciasProps) => {
  const [, setImagemAvatar] = useState<string>("");
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [nomeAgencia, setNomeAgencia] = useState<string>("");
  const [logotipoAgencia, setLogotipoAgencia] = useState<string>("");
  const [botaoAbertoAgencia, setBotaoAbertoAgencia] = useState<boolean>(false);
  const [modalAbertoUsuarioAgencia, setModalAbertoUsuarioAgencia] =
    useState<boolean>(false);
  const [modalAbertoDesativacao, setModalAbertoDesativacao] =
    useState<boolean>(false);
  const [carregandoDesativacao, setCarregandoDesativacao] =
    useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { criaPDFListaDeAgencias, desativarAgencia } =
    FunctionsTabelaListarAgencias({
      idAgencia,
      nomeAgencia,
      setRecarregar,
      setCarregandoDesativacao,
      adicionarAListaEsperaRenovacaoToken,
      setModalAbertoDesativacao,
      myToast,
    });

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
              <BotaoCadastrarAgencia
                botaoAbertoAgencia={botaoAbertoAgencia}
                setBotaoAbertoAgencia={setBotaoAbertoAgencia}
                setImg={setImagemAvatar}
                setRecarregar={setRecarregar}
              />
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                onChange={(e) => setNomeDaAgenciaPesquisa(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaDeAgencias} />
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 1, 2, 3, 4]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody id="corpoTabelaListarAgencias" className={styles.corpoTabela}>
        {carregando ? (
          <Carregando listaUsuarios={listaDeAgencias.length} spinner={true} />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {listaDeAgencias.length === 0 ? (
                  <tr>
                    <td style={{ justifyContent: "center" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaDeAgencias.map((agencia) => {
                    return (
                      <tr>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={agencia.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={agencia.urlLogotipo}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{agencia.nome}</p>
                        </td>
                        <td>{agencia.totalCampanhas}</td>
                        <td>{agencia.totalUsuarios}</td>
                        <td>{agencia.totalMarcas}</td>
                        <td>
                          <div
                            className={`${styles.status} ${
                              styles[agencia.ativo.toString()]
                            }`}
                          >
                            <BsRecordCircleFill
                              style={
                                agencia.ativo === true
                                  ? {
                                      color: "var(--verde-400)",
                                      fontSize: "1rem",
                                    }
                                  : {
                                      color: "var(--cinza-400)",
                                      fontSize: "1rem",
                                    }
                              }
                            />
                            {agencia.ativo === true ? "Ativo" : "Inativo"}
                          </div>
                        </td>

                        <td className="colunaBotoesAcoes">
                          <BotaoAtualizarAgenciaLista
                            myToast={myToast}
                            idAgencia={agencia.id}
                            nomeAgencia={agencia.nome}
                            logotipo={agencia.urlLogotipo}
                            setRecarregar={setRecarregar}
                            status={agencia.ativo}
                          />
                          <BotaoColunaAcoes
                            status={!agencia.ativo ? "Removido" : ""}
                            icone={"pi-user-plus"}
                            titulo={"Adicionar Usuário"}
                            onClick={() => {
                              setModalAbertoUsuarioAgencia(true);
                            }}
                          />
                          <BotaoColunaAcoes
                            status={!agencia.ativo ? "Removido" : ""}
                            icone={"pi-trash"}
                            titulo={"Remover Agência"}
                            onClick={() => {
                              setIdAgencia(agencia.id);
                              setNomeAgencia(agencia.nome);
                              setLogotipoAgencia(agencia.urlLogotipo);
                              setModalAbertoDesativacao(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}

                {modalAbertoUsuarioAgencia && (
                  <ModalCadastroUsuarioAgencia
                    modalAberto={modalAbertoUsuarioAgencia}
                    setModalAberto={setModalAbertoUsuarioAgencia}
                    setRecarregar={setRecarregar}
                  />
                )}

                {modalAbertoDesativacao && (
                  <ModalDesativacao
                    carregando={carregandoDesativacao}
                    modalAberto={modalAbertoDesativacao}
                    setModalAberto={setModalAbertoDesativacao}
                    nomeItemParaDesativar={nomeAgencia}
                    onClick={desativarAgencia}
                  />
                )}
              </>
            )}
          </>
        )}
      </tbody>

      <div className={styles.listaCardsAgencia}>
        {listaDeAgencias.length > 0 ? (
          listaDeAgencias.map((agencia, index) => {
            return (
              <CardAgencia
                agencia={agencia}
                setRecarregar={setRecarregar}
                setIdAgencia={setIdAgencia}
                setNomeAgencia={setNomeAgencia}
                setLogotipoAgencia={setLogotipoAgencia}
                setModalAbertoUsuarioAgencia={setModalAbertoUsuarioAgencia}
                setModalAbertoDesativacao={setModalAbertoDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
    </table>
  );
};
