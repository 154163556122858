import { ErroType } from "../../../@types/erro";
import { getBenchmarkTaxaEngajamento } from "../../../services/benchmark";
import { obterTokens } from "../../../utils/LocalStorage";

interface FuncoesTabelaBenchmarkingProps {
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setMediaIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setMediaIgReels: React.Dispatch<React.SetStateAction<number>>;
  setMediaIgStories: React.Dispatch<React.SetStateAction<number>>;
  setMediaTwitter: React.Dispatch<React.SetStateAction<number>>;
  setMediaTikTok: React.Dispatch<React.SetStateAction<number>>;
  setMediaYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setMediaYouTubeShort: React.Dispatch<React.SetStateAction<number>>;
  tipoInfluenciador: string;
  setErro: React.Dispatch<ErroType>;

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesTabelaBenchmarking = ({
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setMediaIgFeed,
  setMediaIgReels,
  setMediaIgStories,
  setMediaTikTok,
  setMediaTwitter,
  setMediaYouTubeShort,
  setMediaYouTubeVideo,
  tipoInfluenciador,
  setErro,
}: FuncoesTabelaBenchmarkingProps) => {
  function erroRequisicao(
    mensagem: string = "Algo deu errado, tente novamente mais tarde."
  ) {
    setErro({ ativo: true, mensagem: mensagem });
  }

  function pegaDadosDasTabelas() {
    setErro({ ativo: false, mensagem: "" });

    pegaIgFeed();
  }

  function pegaIgFeed() {
    setErro({ ativo: false, mensagem: "" });
    const { token } = obterTokens();

    setCarregando(true);

    getBenchmarkTaxaEngajamento(
      token,
      "PublicacaoInstagramFeed",
      tipoInfluenciador
    )
      .then((res1) => {
        setMediaIgFeed(res1.data.valor);
        pegaIgReels(token);
        pegaIgStories(token);
        pegaTiktok(token);
        pegaTwitter(token);
        pegaYoutubeShorts(token);
        pegaYoutubeVideo(token);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getBenchmarkTaxaEngajamento(
              token,
              "PublicacaoInstagramFeed",
              tipoInfluenciador
            )
              .then((res2) => {
                setMediaIgFeed(res2.data.valor);
                pegaIgReels(token);
                pegaIgStories(token);
                pegaTiktok(token);
                pegaTwitter(token);
                pegaYoutubeShorts(token);
                pegaYoutubeVideo(token);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  function pegaIgReels(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(
      token,
      "PublicacaoInstagramReels",
      tipoInfluenciador
    )
      .then((res1) => {
        setMediaIgReels(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  function pegaIgStories(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(
      token,
      "PublicacaoInstagramStories",
      tipoInfluenciador
    )
      .then((res1) => {
        setMediaIgStories(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  function pegaTwitter(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(token, "PublicacaoTwitter", tipoInfluenciador)
      .then((res1) => {
        setMediaTwitter(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  function pegaTiktok(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(token, "PublicacaoTikTok", tipoInfluenciador)
      .then((res1) => {
        setMediaTikTok(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  function pegaYoutubeVideo(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(token, "PublicacaoYoutube", tipoInfluenciador)
      .then((res1) => {
        setMediaYouTubeVideo(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  function pegaYoutubeShorts(token: string) {
    setErro({ ativo: false, mensagem: "" });
    getBenchmarkTaxaEngajamento(
      token,
      "PublicacaoYoutubeShorts",
      tipoInfluenciador
    )
      .then((res1) => {
        setMediaYouTubeShort(res1.data.valor);
      })
      .catch(() => {
        erroRequisicao();
      });
  }

  return {
    pegaDadosDasTabelas,
  };
};
