import { converteSegundoParaTempoCompleto} from '../../../../utils/ConversorTempo';
import { format } from '../../../../utils/MascaraNumero/format';
import styles from './styles.module.css';

interface DadosLateraisTikTokProps {
  viewsTotais: number;
  tempoReproducao: number;
  comentarios: number;
  likes: number;
  compartilhamentos: number;
  salvos: number;
  espectadores: number;
}

export const DadosLateraisTikTok = ({
  viewsTotais,
  tempoReproducao,
  comentarios,
  likes,
  compartilhamentos,
  salvos,
  espectadores,
}: DadosLateraisTikTokProps) => {

  return (
    <div className={styles.dadosResultados}>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-comments"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Comentários</h1>
          <p>{format("#.###.##0,#0", comentarios).replace(",00", "")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-share-alt"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Compartilhamentos</h1>
          <p>{format("#.###.##0,#0", compartilhamentos).replace(",00", "")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-user"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Espectadores</h1>
          <p>{format("#.###.##0,#0", espectadores).replace(",00", "")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-thumbs-up"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Likes</h1>
          <p>{format("#.###.##0,#0", likes).replace(",00", "")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-bookmark"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Salvos</h1>
          <p>{format("#.###.##0,#0", salvos).replace(",00", "")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-video"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Tempo de Reprodução</h1>
          <p>
            {converteSegundoParaTempoCompleto(tempoReproducao)}
          </p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-eye"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Views Totais</h1>
          <p>{format("#.###.##0,#0", viewsTotais).replace(",00", "")}</p>
        </div>
      </div>

    </div>
  )

}