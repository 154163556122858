import { useState } from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { BotaoColunaAcoes } from '../../Botoes/BotaoColunaAcoes';
import { getInfluenciadorProps } from '../../../services/influenciador';
import styles from './styles.module.css';


export interface CardUsuarioInfluenciadorProps {
  usuario: getInfluenciadorProps;

  setNomeUsuario: React.Dispatch<React.SetStateAction<string>>;
  setImagemAvatarUsuario: React.Dispatch<React.SetStateAction<string>>;
  setTelefoneUsuario: React.Dispatch<React.SetStateAction<string>>;
  setIdUsuario: React.Dispatch<React.SetStateAction<string>>;
  setStatusUsuario: React.Dispatch<React.SetStateAction<string>>;
  setEmailUsuario: React.Dispatch<React.SetStateAction<string>>;

  setModalRedefinicao: (influenciador: getInfluenciadorProps) => void;
  setModalReenvioSenha: (influenciador: getInfluenciadorProps) => void;
  setModalEditar: (influenciador: getInfluenciadorProps) => void;
  setModalDesativacao: React.Dispatch<React.SetStateAction<boolean>>;

  index: number;
}

export const CardUsuarioInfluenciador = ({ usuario, setNomeUsuario, setImagemAvatarUsuario, setTelefoneUsuario, setIdUsuario, setStatusUsuario, setEmailUsuario, setModalRedefinicao, setModalReenvioSenha, setModalEditar, setModalDesativacao, index }: CardUsuarioInfluenciadorProps) => {

  const [abrirCardUsuario, setAbrirCardUsuario] = useState<boolean>(false);

  return (
    <>
      <div
        className={styles.cardUsuario}
      >
        <header className={styles.headerCardUsuario} onClick={() => setAbrirCardUsuario(!abrirCardUsuario)}
        >
          <p>
            {usuario.nome}

          </p>
          <BsRecordCircleFill
            style={{
              color: `${(() => {
                switch (usuario.status) {
                  case "Pendente":
                    return 'var(--laranja-500)';

                  case "Ativo":
                    return 'var(--verde-500)';

                  case "Removido":
                    return 'var(cinza-400)'
                }
              }
              )()}`,
              fontSize: '1rem',
            }}
          />
        </header>
        <body className={abrirCardUsuario ? styles.bodyCardUsuarioAberto : styles.bodyCardUsuarioFechado}>

          <div className={styles.detalhesCard}>
            <div className={styles.itemCard}>
              <p>Perfil:</p> {usuario.perfil}
            </div>

            <div className={styles.itemCard}>
              <p>Email:</p> {usuario.email}
            </div>

            <div className={styles.itemCard}>
              <p>Telefone:</p> {usuario.telefone}
            </div>
            {(
              // usuario.linkTwitter ||
              usuario.linkInstagram ||
              usuario.linkTiktok ||
              usuario.linkYoutube
            ) &&
              <>

                <p className={styles.redesSociais}>Redes</p>
                {/* {usuario.linkTwitter &&
                  <div className={styles.itemCard}>
                    <p>Facebook:</p> {usuario.linkTwitter}
                  </div>
                } */}
                {usuario.linkInstagram &&
                  <div className={styles.itemCard}>
                    <p>Instagram:</p> {usuario.linkInstagram}
                  </div>
                }
                {usuario.linkTiktok &&
                  <div className={styles.itemCard}>
                    <p>TikTok:</p> {usuario.linkTiktok}
                  </div>
                }
                {usuario.linkYoutube &&
                  <div className={styles.itemCard}>
                    <p>YouTube:</p> {usuario.linkYoutube}
                  </div>
                }
              </>
            }


            <div className={styles.campoAcoes}>
              <BotaoColunaAcoes
                status={usuario.status}
                icone={"pi-pencil"}
                titulo={"Editar Usuário"}
                onClick={() => {
                  setModalEditar(usuario);
                  setNomeUsuario(usuario.nome);
                  setImagemAvatarUsuario(usuario.urlAvatar);
                  setTelefoneUsuario(usuario.telefone.toString());
                  setIdUsuario(usuario.idCredenciais);
                  setStatusUsuario(usuario.status);
                }}
              />
              {usuario.status == "Pendente" ?
                <BotaoColunaAcoes
                  status={usuario.status}
                  icone={"pi-send"}
                  titulo={"Reenviar email de Confirmação"}
                  onClick={() => {
                    setModalReenvioSenha(usuario);
                    setEmailUsuario(usuario.email);
                  }} />
                :
                <BotaoColunaAcoes
                  status={usuario.status}
                  icone={"pi-lock"}
                  titulo={"Redefinir Senha"}
                  onClick={() => {
                    setModalRedefinicao(usuario);
                    setEmailUsuario(usuario.email);
                  }}
                />
              }
              <BotaoColunaAcoes
                icone={usuario.status === "Removido" ? "pi-user-plus" : "pi-trash"}
                titulo={usuario.status == "Removido" ? "Reativar Usuário" : "Remover Usuário"}
                onClick={() => {
                  setModalDesativacao(true);
                  setIdUsuario(usuario.idCredenciais);
                  setNomeUsuario(usuario.nome);
                  setImagemAvatarUsuario(usuario.urlAvatar);
                  setTelefoneUsuario(usuario.telefone.toString());
                  setStatusUsuario(usuario.status);
                }}
              />
            </div>
          </div>

        </body>
        <div
          className={abrirCardUsuario ? styles.footerCard : styles.footerCardFechado}
          onClick={() => setAbrirCardUsuario(!abrirCardUsuario)}
        >
          <div
            className={styles.botaoSetinha}
          >
            {
              !abrirCardUsuario ?
                <MdExpandMore />
                :
                <MdExpandLess />
            }
          </div>
        </div>

      </div>
    </>
  )
}