import React, { SetStateAction, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarUsuariosMarca } from "./functions";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { BotaoFiltro } from "../../Botoes/BotaoFiltro";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { ErroType } from "../../../@types/erro";
import { usuarioMarcaProps } from "../../../services/usuarioMarca";
import { ModalAtualizarUsuarioMarca } from "../../Modais/ModalAtualizarUsuarioMarca";
import { ModalRedefinicao } from "../../Modais/ModalRedefinicao";
import { TituloColuna } from "../../TituloColuna";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { FiltrosTabelaUsuarioMarca } from "../../Filtros/FiltrosTabelaUsuarioMarca";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { statusType } from "../../Select/SelectStatus";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { CardUsuarioMarca } from "../../Cards/CardUsuarioMarca";
import { ModalReenvioSenha } from "../../Modais/ModalReenvioSenha";

import { Avatar } from "primereact/avatar";
import { BsRecordCircleFill } from "react-icons/bs";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { getActiveFormattingMask, guessCountryByPartialPhoneNumber } from "react-international-phone";

export interface TabelaListarUsuariosMarcaProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  nomeBusca: string;
  setNomeBusca: React.Dispatch<React.SetStateAction<string>>;
  // paginacao: any;
  // seletorDeQuantidade: any;
  carregando: boolean;
  setCarregando: React.Dispatch<boolean>;
  erro: ErroType;
  setRecarregar: () => void;
  listaUsuarios: usuarioMarcaProps[];
  setModalCadastroUsuarioMarca: React.Dispatch<boolean>;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  tipoOrdenacao: tipoOrdenacao;
  setPropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  propriedadeOrdenacao: string;
  myToast: React.MutableRefObject<any>;
  setFiltroStatus: React.Dispatch<React.SetStateAction<statusType>>;
  setFiltroIdMarca: React.Dispatch<React.SetStateAction<string>>;
  status: statusType;
  idMarcaFiltro: string;
  showToast: (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    stickyValue: boolean
  ) => void;
  setErro: React.Dispatch<ErroType>;
}

export const TabelaListarUsuariosMarca = ({
  carregando,
  erro,
  nomeBusca,
  // paginacao,
  setRecarregar,
  // seletorDeQuantidade,
  setNomeBusca,
  tituloColuna,
  tituloTabela,
  listaUsuarios,
  setTipoOrdenacao,
  setModalCadastroUsuarioMarca,
  tipoOrdenacao,
  myToast,
  setPropriedadeOrdenacao,
  propriedadeOrdenacao,
  setCarregando,
  setFiltroIdMarca,
  setFiltroStatus,
  status,
  idMarcaFiltro,
  setErro,
}: TabelaListarUsuariosMarcaProps) => {
  const [modalEditar, setModalEditar] = useState<boolean>(false);
  const [modalRedefinicao, setModalRedefinicao] = useState<boolean>(false);
  const [modalReenvioSenha, setModalReenvioSenha] = useState<boolean>(false);
  const [modalDesativacao, setModalDesativacao] = useState<boolean>(false);

  const [idUsuario, setIdUsuario] = useState<string>("");
  const [nomeUsuario, setNomeUsuario] = useState<string>("");
  const [telefoneUsuario, setTelefoneUsuario] = useState<string>("");
  const [imagemAvatarUsuario, setImagemAvatarUsuario] = useState<string>("");
  const [emailUsuario, setEmailUsuario] = useState<string>("");
  const [statusUsuario, setStatusUsuario] = useState<string>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { desativarUsuario, reativarUsuario, criaPDFListaDeUsuariosMarca } =
    FunctionsTabelaListarUsuariosMarca({
      idUsuario,
      imagemAvatarUsuario,
      myToast,
      nomeUsuario,
      setRecarregar,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      setModalAbertoDesativacao: setModalDesativacao,
      telefoneUsuario,
      setErro,
    });

  // showToast(
  //   "success",
  //   "Desativação realizada com sucesso",
  //   "Usuário desativada!",
  //   false
  // ),

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
              <Button
                importancia="secundario"
                onClick={() => setModalCadastroUsuarioMarca(true)}
                tooltip="Cadastrar Usuário de Marca"
              >
                {
                  <i
                    className="pi pi-plus-circle"
                    style={{ fontSize: "1.8rem" }}
                  />
                }
                Adicionar Usuário
              </Button>
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                onChange={(e) => setNomeBusca(e.target.value)}
                value={nomeBusca}
              />
              <BotaoImprimir onClick={criaPDFListaDeUsuariosMarca} />
              <BotaoFiltro>
                <FiltrosTabelaUsuarioMarca
                  setFiltroIdMarca={setFiltroIdMarca}
                  setFiltroStatus={setFiltroStatus}
                  status={status}
                  idMarcaFiltro={idMarcaFiltro}
                />
              </BotaoFiltro>
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 2, 4]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={propriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setPropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody id="corpoTabelaListaUsuarioMarca" className={styles.corpoTabela}>
        {carregando ? (
          <Carregando listaUsuarios={listaUsuarios.length} spinner={true} />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {(listaUsuarios && listaUsuarios.length === 0) || carregando ? (
                  <tr>
                    <td style={{ justifyContent: "center" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaUsuarios.map(
                    ({
                      nome,
                      idCredenciais,
                      marca,
                      status,
                      telefone,
                      urlAvatar,
                      email,
                    }) => (
                      <tr key={idCredenciais}>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={urlAvatar}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{nome}</p>
                        </td>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            className={styles.avatar}
                            label={marca.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={marca.urlLogotipo}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{marca.nome}</p>
                        </td>
                        <td>{email}</td>
                        <td>
                          {telefone.toString()}
                        </td>
                        <td>
                          <div
                            className={`${styles.status} ${styles[status.toLowerCase()]
                              }`}
                          >
                            <BsRecordCircleFill
                              style={{
                                color: `${(() => {
                                  switch (status) {
                                    case "Pendente":
                                      return "var(--laranja-500)";

                                    case "Ativo":
                                      return "var(--verde-500)";

                                    case "AguardandoRemocao":
                                      return "var(--laranja-700)";

                                    case "Removido":
                                      return "var(cinza-400)";
                                  }
                                })()}`,
                                fontSize: "1rem",
                              }}
                            />
                            {status === "AguardandoRemocao"
                              ? "Aguardando Remoção"
                              : status}
                          </div>
                        </td>
                        <td className="colunaBotoesAcoes">
                          <BotaoColunaAcoes
                            status={
                              status === "AguardandoRemocao"
                                ? "Removido"
                                : status
                            }
                            icone={"pi-pencil"}
                            titulo={"Editar Usuário"}
                            onClick={() => {
                              setModalEditar(true);
                              setNomeUsuario(nome);
                              setImagemAvatarUsuario(urlAvatar);
                              setTelefoneUsuario(telefone.toString());
                              setIdUsuario(idCredenciais);
                              setStatusUsuario(status);
                            }}
                          />
                          {status == "Pendente" ? (
                            <BotaoColunaAcoes
                              status={status}
                              icone={"pi-send"}
                              titulo={"Reenviar email de Confirmação"}
                              onClick={() => {
                                setModalReenvioSenha(true);
                                setEmailUsuario(email);
                              }}
                            />
                          ) : (
                            <BotaoColunaAcoes
                              status={
                                status === "AguardandoRemocao"
                                  ? "Removido"
                                  : status
                              }
                              icone={"pi-lock"}
                              titulo={"Redefinir Senha"}
                              onClick={() => {
                                setModalRedefinicao(true);
                                setEmailUsuario(email);
                              }}
                            />
                          )}
                          <BotaoColunaAcoes
                            icone={
                              status === "AguardandoRemocao"
                                ? "pi-user-plus"
                                : "pi-trash"
                            }
                            titulo={
                              status === "AguardandoRemocao"
                                ? marca.ativo
                                  ? "Reativar Usuário"
                                  : "Não é possivel reativar usuário, pois a marca a que ele pertence está desativada."
                                : "Remover Usuário"
                            }
                            status={
                              status === "Removido" || !marca.ativo
                                ? "Removido"
                                : ""
                            }
                            onClick={() => {
                              setModalDesativacao(true);
                              setIdUsuario(idCredenciais);
                              setNomeUsuario(nome);
                              setImagemAvatarUsuario(urlAvatar);
                              setTelefoneUsuario(telefone.toString());
                              setStatusUsuario(status);
                            }}
                          />
                        </td>
                      </tr>
                    )
                  )
                )}
              </>
            )}
          </>
        )}
      </tbody>

      <div className={styles.listaCardsUsuario}>
        {listaUsuarios.length > 0 ? (
          listaUsuarios.map((usuarioMarca, index) => {
            return (
              <CardUsuarioMarca
                usuario={usuarioMarca}
                setNomeUsuario={setNomeUsuario}
                setImagemAvatarUsuario={setImagemAvatarUsuario}
                setTelefoneUsuario={setTelefoneUsuario}
                setIdUsuario={setIdUsuario}
                setStatusUsuario={setStatusUsuario}
                setEmailUsuario={setEmailUsuario}
                setModalRedefinicao={setModalRedefinicao}
                setModalReenvioSenha={setModalReenvioSenha}
                setModalEditar={setModalEditar}
                setModalDesativacao={setModalDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>

      <tfoot className={styles.footerTabela}>
        {/* <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            {seletorDeQuantidade}
          </div>
          {paginacao}
        </div> */}
      </tfoot>
      {modalEditar && (
        <ModalAtualizarUsuarioMarca
          recarregar={setRecarregar}
          id={idUsuario}
          modalAberto={modalEditar}
          setModalAberto={setModalEditar}
          nomeUsuario={nomeUsuario}
          telefoneUsuario={telefoneUsuario}
          imagemAvatarUsuario={imagemAvatarUsuario}
          statusUsuario={statusUsuario}
        />
      )}
      {modalRedefinicao && (
        <ModalRedefinicao
          modalAberto={modalRedefinicao}
          setModalAberto={setModalRedefinicao}
          emailPrevio={emailUsuario}
        />
      )}
      {modalReenvioSenha && (
        <ModalReenvioSenha
          modalAberto={modalReenvioSenha}
          setModalAberto={setModalReenvioSenha}
          emailPrevio={emailUsuario}
        />
      )}
      {modalDesativacao && (
        <ModalDesativacao
          modalAberto={modalDesativacao}
          setModalAberto={setModalDesativacao}
          nomeItemParaDesativar={nomeUsuario}
          status={statusUsuario}
          onClick={
            statusUsuario === "AguardandoRemocao"
              ? () => reativarUsuario()
              : () => desativarUsuario()
          }
        />
      )}
    </table>
  );
};
