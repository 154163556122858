import { api } from "./api";


export interface postOcrProps{
  data:{
     imagens: string[];
     tipoPublicacao: string;
   }
 }

  export const postOcr = (
    token: string,
    imagens: string[],
    tipoPublicacao: string,
    
  ) => {
    const url = "/Ocr";
  
    const data = {
      imagens,
      tipoPublicacao
     
    }
  
    return api.post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  };
  