import { ErroType } from "../../../@types/erro";
import { PublicacoesProps } from "../../../services/publicacao";
import { somaMetrica } from "../../../utils/somaMetrica";

interface FuncoesTabelaMetricasInstagramProps {
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  setVisitasPerfil: React.Dispatch<React.SetStateAction<number>>;
  setReproducao: React.Dispatch<React.SetStateAction<number>>;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  setCliquesLink: React.Dispatch<React.SetStateAction<number>>;
  setCliquesStickers: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaMetricasInstagram = ({
  setImpressoes,
  setLikes,
  setComentarios,
  setAlcance,
  setSalvos,
  setCompartilhamentos,
  setVisitasPerfil,
  setReproducao,
  setRespostas,
  setCliquesLink,
  setCliquesStickers,
}: FuncoesTabelaMetricasInstagramProps) => {
  const somaDadosPublicacaoInstagram = (
    listaDePublicacoes: PublicacoesProps[]
  ) => {

    let somaImpressoes: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaAlcance: number = 0;
    let somaSalvos: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaVisitasPerfil: number = 0;
    let somaReproducao: number = 0;
    let somaRespostas: number = 0;
    let somaCliquesLink: number = 0;
    let somaCliquesStickers: number = 0;

    listaDePublicacoes.map((publicacao) => {

      somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

      somaLikes = somaMetrica(somaLikes, publicacao.likes);

      somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

      somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

      somaSalvos = somaMetrica(somaSalvos, publicacao.salvos);

      somaCompartilhamentos = somaMetrica(somaCompartilhamentos, publicacao.compartilhamentos);

      somaVisitasPerfil = somaMetrica(somaVisitasPerfil, publicacao.visitasAoPerfil);

      somaVisitasPerfil = somaMetrica(somaVisitasPerfil, publicacao.visitasPerfil);

      somaReproducao = somaMetrica(somaReproducao, publicacao.quantidadeReproducoes);

      somaRespostas = somaMetrica(somaRespostas, publicacao.respostas);

      somaCliquesLink = somaMetrica(somaCliquesLink, publicacao.cliquesLink);

      somaCliquesStickers = somaMetrica(somaCliquesStickers, publicacao.cliquesStickers);

    });

    setImpressoes(somaImpressoes);
    setLikes(somaLikes);
    setComentarios(somaComentarios);
    setAlcance(somaAlcance);
    setSalvos(somaSalvos);
    setCompartilhamentos(somaCompartilhamentos);
    setVisitasPerfil(somaVisitasPerfil);
    setReproducao(somaReproducao);
    setRespostas(somaRespostas);
    setCliquesLink(somaCliquesLink);
    setCliquesStickers(somaCliquesStickers);
  };

  return {
    somaDadosPublicacaoInstagram,
  };
};
