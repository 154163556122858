import { SetStateAction, useState } from "react";

import styles from "./styles.module.css";

import { SelectMarca } from "../../Select/SelectMarca";
import { SelectAgencia } from "../../Select/SelectAgencia";
import { TabView, TabPanel } from "primereact/tabview";


interface FiltrosTabelaCampanhasPorInfluenciadorProps {
  setIdMarcaFiltro: React.Dispatch<SetStateAction<string>>;
  idMarcaFiltro: string;
  setIdAgenciaFiltro: React.Dispatch<SetStateAction<string>>;
  idAgenciaFiltro: string;
}

export const FiltrosTabelaCampanhasPorInfluenciador = ({
  idAgenciaFiltro,
  setIdAgenciaFiltro,
  idMarcaFiltro,
  setIdMarcaFiltro
}: FiltrosTabelaCampanhasPorInfluenciadorProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div id={styles.conatinerFiltrosTabelaListarCampanhas}>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index);
          setIdAgenciaFiltro("");
          setIdMarcaFiltro("");
          
        }}
      >
        <TabPanel header="Agencia">
          <SelectAgencia
            idAgencia={idAgenciaFiltro}
            setIdAgencia={setIdAgenciaFiltro}
        />
        </TabPanel>

        <TabPanel header="Marca">
          <SelectMarca setIdMarca={setIdMarcaFiltro} idMarca={idMarcaFiltro} />
        </TabPanel>
      </TabView>
    </div>
  );
};
