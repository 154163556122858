import React from "react";
import { ErroType } from "../../../@types/erro";
import { postTemplateEmail } from "../../../services/templateEmail";
import { obterTokens } from "../../../utils/LocalStorage";
import { verificarPreenchimento } from "../../../utils/VerificarPreenchimento";

export interface FunctionsTemplateEmailProps {
  assunto: string;
  mensagemHtml: string;
  mensagemTextoPuro: string;
  tipo: string;
  setSucesso: React.Dispatch<boolean>;
  setErro: React.Dispatch<ErroType>;
  setCarregando: React.Dispatch<boolean>;
  setErroPreenchimento: React.Dispatch<boolean>;
  myToast: React.MutableRefObject<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsTemplateEmail = ({
  assunto,
  mensagemHtml,
  mensagemTextoPuro,
  tipo,
  setCarregando,
  setErro,
  setSucesso,
  adicionarAListaEsperaRenovacaoToken,
  myToast,
}: FunctionsTemplateEmailProps) => {
  function salvarNovoEmail(e: React.FormEvent<HTMLFormElement>) {
    setErro({ ativo: false, mensagem: "" });
    e.preventDefault();

    if (
      verificarPreenchimento([assunto, mensagemHtml, mensagemTextoPuro, tipo])
    ) {
      const { token, tokenRenovacao } = obterTokens();

      setCarregando(true);

      postTemplateEmail(token, assunto, mensagemHtml, mensagemTextoPuro, tipo)
        .then((res) => {
          setSucesso(true);
          showToast(
            "success",
            "cadastro realizado",
            "Template cadastrado com sucesso!"
          );
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              postTemplateEmail(
                token,
                assunto,
                mensagemHtml,
                mensagemTextoPuro,
                tipo
              )
                .then(() => {
                  setSucesso(true);
                  showToast(
                    "success",
                    "cadastro realizado",
                    "Template cadastrado com sucesso!"
                  );
                })
                .catch((err) => {
                  console.error(err);
                  setErro({
                    ativo: true,
                    mensagem: "Algo deu errado, tente novamente mais tarde.",
                  });
                  showToast(
                    "error",
                    "cadastro não realizado",
                    "Não foi possível cadastrar o template."
                  );
                });
            }, setCarregando);
          } else {
            setErro({
              ativo: true,
              mensagem: "Todos os campos devem ser preenchidos.",
            });
            showToast(
              "error",
              "cadastro não realizado",
              "Não foi possível cadastrar o template."
            );
            setCarregando(false);
          }
        });
    }
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    salvarNovoEmail,
    showToast,
  };
};
