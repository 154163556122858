import { useContext, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesCadastroFuncionario } from "./functions";

import { Input } from "../../Input";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";
import { ModalTeste } from "../ModalTeste";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";
import { MinLengthValidation } from "../../../validation/validators";
import { SubmitHandler } from "react-hook-form";

export interface ModalCadastroDeUsuarioNetcosProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: () => void;
}

export const ModalCadastroDeUsuarioNetcos = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
}: ModalCadastroDeUsuarioNetcosProps) => {
  const [email, setEmail] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [telefone, setTelefone] = useState<number>(0);

  const [, setSucesso] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);

  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] =
    useState<string>("");
  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const nomeMinLengthValidator = new MinLengthValidation('nome', 3);
  const emailMinLengthValidator = new MinLengthValidation('email', 5);

  const [validationErrors, setValidationErrors] = useState<Record<string, string | null>>({});

  const { cadastrarUsuarioNetcos, confirmarCadastro, fazMascaraTelefone } =
    FuncoesCadastroFuncionario({
      email,
      setEmail,
      nome,
      setNome,
      imagemAvatar,
      setImagemAvatar,
      telefone,
      setTelefone,
      MascaraNumeroWhatsapp,
      setMascaraNumeroWhatsapp,
      setSucesso,
      setCarregando,
      setRecarregar,
      setModalAberto,
      adicionarAListaEsperaRenovacaoToken,
      myToast,
    });

    const handleFormSubmit = (nome: string, email: string)=> {
    const nomeError = nomeMinLengthValidator.validate({ nome: nome });
    const emailError = emailMinLengthValidator.validate({ email: email });

    setValidationErrors({
      nome: nomeError ? 'Nome muito curto. Por favor, digite um nome válido' : null,
      email: emailError ? 'E-mail muito curto. Por favor, digite um e-mail válido' : null,
    });

    if (!nomeError && !emailError) {
      cadastrarUsuarioNetcos();
    }
  };

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo="Cadastro de Usuário Netcos"
    >
      <div
        className={styles.containerModalCadastroDeUsuarioNetcos}
      >
        <body>
          <BotaoUploadImagem setImg={setImagemAvatar} />
          <div className={styles.containerDados}>
            <div className={styles.containerInputs}>
              <Input
                type="text"
                value={nome}
                placeholder="Nome"
                required
                onChange={(e) => setNome(e.target.value)}
              />
              {validationErrors.nome && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.nome}
                </small>
              )

              }
              <Input
                type="email"
                required
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {validationErrors.email && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.email}
                </small>
              )

              }

              <PhoneInput
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
              />
            </div>
          </div>
        </body>

        <footer>
          {carregando ? (
            <Carregando />
          ) : (
            <div className={styles.containerBotoesModal}>
              <Button
                onClick={() => setModalAberto(false)}
                importancia="terciario"
                title="Cancelar"
              />
              <Button
                importancia="primario"
                type="submit"
                title="Cadastrar"
                onClick={() => { return handleFormSubmit(nome, email)}}
              />
            </div>
          )}
        </footer>

        <Toast ref={myToast} className="custom-toast" />
      </div>
    </ModalTeste>
  );
};
