import { AxiosResponse } from "axios";
import { api } from "./api";

import { getMarcaProps } from "./marca";

export interface usuarioMarcaProps {
  idCredenciais: string;
  nome: string;
  telefone: number;
  status: string;
  urlAvatar: string;
  marca: getMarcaProps;
  email: string;
}

export interface postUsuarioMarcaProps {
  nome: string;
  email: string;
  imagemAvatar: string;
  telefone: string;
  idMarca: string;
  status: "Pendente";
}

export const postUsuarioMarca = (
  token: string,
  usuario: postUsuarioMarcaProps,
  enviarNotificacaoDeCadastro: boolean
) => {
  const url = "/UsuarioMarca";

  return api.post(
    url,
    { usuario, enviarNotificacaoDeCadastro },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putUsuarioMarca = (
  token: string,
  id: string,
  nome: string,
  telefone: string,
  imagemAvatar: string | null,
  status: string
) => {
  const url = "/UsuarioMarca";

  const usuario = {
    id,
    nome,
    telefone,
    imagemAvatar,
    status,
  };

  return api.put(
    url,
    { usuario },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export interface getUsuarioMarcaProps {
  codigo: number;
  totalRegistros: number;
  usuarios: usuarioMarcaProps[];
}

export const getUsuariosMarca = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<getUsuarioMarcaProps | any>> => {
  const url = `/UsuarioMarca/Todos?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioMarcaTodosNome = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
  nome: string
): Promise<AxiosResponse<getUsuarioMarcaProps | any>> => {
  const url = `/UsuarioMarca/Todos/Nome?Nome=${nome}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioMarcaTodosMarca = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string = "nome",
  idMarca: string
): Promise<AxiosResponse<getUsuarioMarcaProps | any>> => {
  const url = `/UsuarioMarca/Todos/Marca?IdMarca=${idMarca}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioMarcaTodosStatus = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string = "nome",
  status: string
): Promise<AxiosResponse<getUsuarioMarcaProps | any>> => {
  const url = `/UsuarioMarca/Todos/Status?Status=${status}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const getUsuarioMarcaId = (
  token: string,
  idCredenciais: string
): Promise<AxiosResponse<getUsuarioMarcaProps | any>> => {
  const url = `/UsuarioMarca/?IdCredenciais=${idCredenciais}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
