import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  Documentos,
  getDocumentosLegais,
} from "../../../services/documentosLegais";

interface FuncoesTermosDeUsoListagemProps {
  setListaDeDocumentos: React.Dispatch<React.SetStateAction<Documentos[]>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalDeDocumentos: React.Dispatch<React.SetStateAction<number>>;
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
}

export const FuncoesTermosDeUsoListagem = ({
  setCarregando,
  setErro,
  setListaDeDocumentos,
  setTotalDeDocumentos,
  numeroPagina,
  tamanhoPagina,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
}: FuncoesTermosDeUsoListagemProps) => {
  function pegaDocumentosLegais() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    getDocumentosLegais(
      "TermosDeUso",
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeDocumentos(res.data.documentos);
        setTotalDeDocumentos(res.data.totalRegistros);
      })
      .catch((err) => {
        setErro({
          ativo: true,
          mensagem:
            "Ocorreu um erro ao listar os documentos, tente novamente mais tarde.",
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  }

  return {
    pegaDocumentosLegais,
  };
};
