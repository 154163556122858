import React from "react";

import styles from "./styles.module.css";
import { Dialog } from "primereact/dialog";

export interface ModalProps {
  modalAberto: boolean;
  fecharModal?: () => void;
  setModalAberto?: React.Dispatch<boolean>;
  children: React.ReactNode;
  titulo?: string;
  zIndex?: number;
  naoFecharModalAoClicarFora?: boolean;
  closeOnEscape?: boolean;
}

export const ModalTeste = ({
  modalAberto,
  setModalAberto,
  children,
  titulo,
  zIndex,
  naoFecharModalAoClicarFora,
  closeOnEscape,
  fecharModal,
}: ModalProps) => {
  const header = (
    <div className={styles.cabecalho}>
      <h1>{titulo}</h1>
    </div>
  );

  return (
    <div className={styles.campoModalBase}>
      <Dialog
        closeOnEscape={closeOnEscape ? true : false}
        className={styles.modalBase}
        header={header}
        visible={modalAberto}
        onHide={() => {
          fecharModal && fecharModal();
          setModalAberto && setModalAberto(false);
        }}
        dismissableMask={naoFecharModalAoClicarFora ? false : true}
        draggable
        // maximizable //TODO: sumindo o modal quando volta para o tamanho normal
        modal
        blockScroll
        keepInViewport
        focusOnShow
      >
        <div className={styles.conteudoModal}>{children}</div>
      </Dialog>
    </div>
  );
};
