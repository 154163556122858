import { AxiosResponse } from "axios";
import { api } from "./api";

export interface agenciaProps {
  nome: string;
  logotipo: string;
  urlLogotipo?: string;
}

export interface listaAgenciaProps {
  id: string;
  nome: string;
  urlLogotipo: string;
  totalUsuarios: number;
  totalCampanhas: number;
  totalMarcas: number;
  ativo: boolean;
}

export interface agenciaNaCampanhaProps {
  id: string;
  urlLogotipo: string;
  nome: string;
  totalUsuarios: number;
  totalCampanhas: number;
  totalMarcas: number;
  ativo: boolean;
}

export interface getAgenciaProps {
  id: string;
  urlLogotipo: string;
  nome: string;
}

export interface respostaGetAgencia {
  codigo: number;
  totalRegistros: number;
  agencias: listaAgenciaProps[];
}

export interface respostaGetAgenciaPorNome {
  codigo: number;
  totalRegistros: number;
  agencias: listaAgenciaProps[];
}

export const postAgencia = (token: string, agencia: agenciaProps) => {
  const url = `/Agencia`;

  return api.post(url, agencia, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAgenciaTodas = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetAgencia, any>> => {
  const url = `/Agencia/Todas?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAgenciaTodasPorNome = (
  token: string,
  nomeAgenciaPesquisa: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetAgenciaPorNome, any>> => {
  const url = `/Agencia/Todas/Nome?Nome=${nomeAgenciaPesquisa}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAgenciaTodasPorStatus = (
  token: string,
  status: boolean,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetAgenciaPorNome, any>> => {
  const url = `/Agencia/Todas/Status?Status=${status}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putAgencia = (
  token: string,
  id: string,
  nome: string,
  logotipo: string | null,
  ativo: boolean
) => {
  const url = "/Agencia";

  const agencia = {
    id,
    nome,
    logotipo,
    ativo,
  };

  return api.put(url, agencia, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
