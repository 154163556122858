import { ErroType } from "../../../@types/erro";
import {
  getTodasPublicacoesDaCampanhaPorInfluenciador,
  PublicacoesProps,
} from "../../../services/publicacao";
import { obterTokens } from "../../../utils/LocalStorage";
import { tipoOrdenacao } from "../../../@types/ordenacao";

interface FuncaoModalTarefasProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  setListaDePublicacoes: React.Dispatch<
    React.SetStateAction<PublicacoesProps[]>
  >;
  setTotalDePublicacoes: React.Dispatch<React.SetStateAction<number>>;
  setEntregues?: React.Dispatch<React.SetStateAction<number>>;
  setContratadas?: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  nomePropriedadeOrdenacao: "Id" | "DataFinalizacao" | "Status" | string;
  tipoOrdenacao: tipoOrdenacao;
  numeroPagina: number;
  tamanhoPagina: number;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesTabelaPublicacao = ({
  idCampanha,
  idInfluenciadorSelecionado,
  setListaDePublicacoes,
  setTotalDePublicacoes,
  setEntregues,
  setContratadas,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
  numeroPagina,
  tamanhoPagina,
}: FuncaoModalTarefasProps) => {
  const obterPublicacoesTarefa = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTodasPublicacoesDaCampanhaPorInfluenciador(
      token,
      idCampanha,
      idInfluenciadorSelecionado,
      tipoOrdenacao,
      nomePropriedadeOrdenacao,
      numeroPagina,
      tamanhoPagina
    )
      .then((res) => {
        setListaDePublicacoes(res.data.tarefas);
        setTotalDePublicacoes(res.data.totalRegistros);
        setContratadas && setContratadas(res.data.totalRegistros);
        setEntregues && setEntregues(res.data.quantidadeDePublicacoesAprovadas);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTodasPublicacoesDaCampanhaPorInfluenciador(
              token,
              idCampanha,
              idInfluenciadorSelecionado,
              tipoOrdenacao,
              nomePropriedadeOrdenacao,
              numeroPagina,
              tamanhoPagina
            )
              .then((res) => {
                setListaDePublicacoes(res.data.tarefas);
                setTotalDePublicacoes(res.data.totalRegistros);
                setContratadas && setContratadas(res.data.totalRegistros);
                setEntregues &&
                  setEntregues(res.data.quantidadeDePublicacoesAprovadas);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Publicações.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Publicações.",
          });
          setCarregando(false);
        }
      });
  };

  return {
    obterPublicacoesTarefa,
  };
};
