import { useContext } from "react";

import {
  cadastroTarefasProps,
  postCampanhaProps,
  postCampanha,
} from "../../../services/campanha";
import { putMarcaAgencia } from "../../../services/marca";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

export interface FuncoesCadastroCampanhaProps {
  idAgencia: string;
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  idMarcas: string[];
  setIdMarcas: React.Dispatch<React.SetStateAction<string[]>>;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  dataInicio: string;
  dataFim: string;
  setDatas: React.Dispatch<React.SetStateAction<Date | Date[] | undefined>>;
  tarefas: cadastroTarefasProps[];
  setTarefas: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;
  setRecarregar: () => void;

  //Paginação
  numeroPagina: number;
  tamanhoPagina: number;

  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesCadastroCampanha = ({
  idAgencia,
  setIdAgencia,
  idMarcas,
  setIdMarcas,
  nome,
  setNome,
  dataInicio,
  dataFim,
  setDatas,
  tarefas,
  setTarefas,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setRecarregar,
  setModalAberto,
}: FuncoesCadastroCampanhaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const erroRequisicao = (mensagemAPI: string) => {
    let mensagem: string = "";

    switch (mensagemAPI) {
      case "Data final":
        mensagem = "Selecione o período da Campanha (Data Início e Data Fim)";
        break;

      default:
        mensagem =
          "Não foi possível cadastrar a campanha.";
    }

    mostrarToast("error", "Cadastro não realizado", mensagem);
  };

  const removerTarefas = (indexDaTarefa: number) => {
    const novaListaDeTarefas = tarefas.filter(
      (tarefas, index) => indexDaTarefa !== index
    );
    setTarefas(novaListaDeTarefas);
  };

  function cadastraCampanha(token: string, idAgenciaPreenchido: string) {
    const campanha: postCampanhaProps = {
      idAgencia: idAgenciaPreenchido,
      campanha: {
        idMarcas: idMarcas,
        nome,
        dataInicio,
        dataFim,
        tarefas: tarefas,
      },
    };

    postCampanha(token, campanha)
      .then((res) => {
        LimparStates([setIdAgencia, setNome, setTarefas]);
        setIdMarcas([]);
        setDatas([]);
        mostrarToast(
          "success",
          "Cadastro realizado",
          res.mensagem
        );
        setModalAberto(false);
        setRecarregar();
      })
      .catch((err) => {
        if (dataFim === '') {
          erroRequisicao('Data final')
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message)
        }
      })
      .finally(() => {
        setCarregando(false);
      });
  }

  function vinculaMarcaEAgenciaEConfimaCampanha(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    let idAgenciaPreenchido: string = "";

    if (idMarcas.length === 0 || idMarcas[0] === undefined) {
      setCarregando(false);
      mostrarToast("error", "Cadastro não realizado", "Selecione uma Marca");
    } else if (tarefas.length === 0) {
      setCarregando(false);
      mostrarToast(
        "error",
        "Cadastro não realizado",
        "Adicione Influenciadores"
      );
    } else if (nome === "") {
      setCarregando(false);
      mostrarToast(
        "error",
        "Cadastro não realizado",
        "Escreva um nome para a campanha"
      );
    } else {
      if (idAgencia === "" || idAgencia === undefined) {
        idAgenciaPreenchido = "e702f33a-8566-44db-bf2a-fdcf586de777";
      } else {
        idAgenciaPreenchido = idAgencia;
      }

      putMarcaAgencia({
        token,
        idMarca: idMarcas[0],
        idAgencia: idAgenciaPreenchido,
      })
        .then(() => {
          setCarregando(true);
          cadastraCampanha(token, idAgenciaPreenchido);

          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await cadastraCampanha(token, idAgenciaPreenchido);
            }, setCarregando);
          } else {
            mostrarToast(
              "error",
              "Cadastro não realizado",
              err.response.data.mensagem
                ? err.response.data.mensagem
                : err.response.data.message
            );

            setCarregando(false);
          }
        });
    }
  }

  return {
    cadastraCampanha,
    vinculaMarcaEAgenciaEConfimaCampanha,
    removerTarefas,
  };
};
