import { SetStateAction, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarUsuarioNetCos } from "./functions";

import { ErroType } from "../../../@types/erro";
import { usuarioNetcosProps } from "../../../services/usuarioNetcos";
import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { ModalAtualizacaoDeUsuarioNetcos } from "../../Modais/ModalAtualizacaoDeUsuarioNetcos";
import { ModalCadastroDeUsuarioNetcos } from "../../Modais/ModalCadastroDeUsuarioNetcos";
import { ModalDesativacao } from "../../Modais/ModalDesativacao";
import { ModalRedefinicao } from "../../Modais/ModalRedefinicao";
import { ModalReenvioSenha } from "../../Modais/ModalReenvioSenha";
import { TituloColuna } from "../../TituloColuna";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";
import { CardUsuarioNetcos } from "../../Cards/CardUsuarioNetcos";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { Avatar } from "primereact/avatar";
import { BsRecordCircleFill } from "react-icons/bs";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface TabelaListarUsuarioNetcosProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  nomeBusca?: string;
  setNomeBusca?: React.Dispatch<React.SetStateAction<string>>;
  listaUsuarioNetCos: usuarioNetcosProps[];
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  setNomeUsuarioNetcosPesquisa: React.Dispatch<React.SetStateAction<string>>;
  // paginacao: any;
  // seletorDeQuantidade: any;
  carregando: boolean;
  myToast: React.MutableRefObject<any>;
  setCarregando: React.Dispatch<SetStateAction<boolean>>;
  erro: ErroType;
  setRecarregar: () => void;
  carregandoPesquisa: boolean;
  setErro: React.Dispatch<ErroType>;
}

export const TabelaListarUsuarioNetcos = ({
  tituloTabela,
  tituloColuna,
  listaUsuarioNetCos,
  // paginacao,
  // seletorDeQuantidade,
  carregando,
  setCarregando,
  erro,
  setRecarregar,
  tipoOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setNomeUsuarioNetcosPesquisa,
  carregandoPesquisa,
  myToast,
  setErro,
}: TabelaListarUsuarioNetcosProps) => {
  const [idUsuarioNetcos, setIdUsuarioNetcos] = useState<string>("");
  const [nomeUsuarioNetcos, setNomeUsuarioNetcos] = useState<string>("");
  const [telefoneUsuarioNetcos, setTelefoneUsuarioNetcos] =
    useState<string>("");
  const [imagemAvatarUsuarioNetcos, setImagemAvatarUsuarioNetcos] =
    useState<string>("");
  const [statusUsuarioNetcos, setStatusUsuarioNetcos] = useState<string>("");

  const [
    modalAbertoCadastrarUsuarioNetCos,
    setModalAbertoCadastrarUsuarioNetCos,
  ] = useState<boolean>(false);
  const [modalRedefinicao, setModalRedefinicao] = useState<boolean>(false);
  const [modalReenvioSenha, setModalReenvioSenha] = useState<boolean>(false);
  const [modalEditarUsuarioNetCos, setModalEditarUsuarioNetCos] =
    useState<boolean>(false);
  const [modalAbertoDesativacao, setModalAbertoDesativacao] =
    useState<boolean>(false);
  const [usuarioNetCosSelecionado, setUsuarioNetCosSelecionado] =
    useState<usuarioNetcosProps>();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    criaPDFListaDeUsuariosNetCos,
    abriModalEditar,
    abriModalRedefinicao,
    abriModalReenvioSenha,
    desativarUsuarioNetcos,
    reativarUsuario,
  } = FunctionsTabelaListarUsuarioNetCos({
    idUsuarioNetcos,
    nomeUsuarioNetcos,
    telefoneUsuarioNetcos,
    imagemAvatarUsuarioNetcos,
    setUsuarioNetCosSelecionado,
    setModalEditarUsuarioNetCos,
    setModalAbertoDesativacao,
    setModalRedefinicao,
    setModalReenvioSenha,
    setRecarregar,
    setCarregando,
    myToast,
    setErro,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
              <div className={styles.botaoModalAdcMarca}>
                <Button
                  importancia="secundario"
                  onClick={() => setModalAbertoCadastrarUsuarioNetCos(true)}
                  tooltip="Cadastrar Usuário da NetCos"
                >
                  {
                    <i
                      className="pi pi-plus-circle"
                      style={{ fontSize: "1.8rem" }}
                    />
                  }
                  Adicionar Usuário
                </Button>
                {modalAbertoCadastrarUsuarioNetCos && (
                  <ModalCadastroDeUsuarioNetcos
                    modalAberto={modalAbertoCadastrarUsuarioNetCos}
                    setModalAberto={setModalAbertoCadastrarUsuarioNetCos}
                    setRecarregar={setRecarregar}
                  />
                )}
              </div>
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                carregandoPesquisa={carregandoPesquisa}
                onChange={(e) => setNomeUsuarioNetcosPesquisa(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaDeUsuariosNetCos} />
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[0, 1, 3]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody id="tabelaListarUsuariosNetCos" className={styles.corpoTabela}>
        {carregando ? (
          <Carregando
            listaUsuarios={listaUsuarioNetCos.length}
            spinner={true}
          />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {listaUsuarioNetCos.length === 0 || carregando ? (
                  <tr>
                    <td style={{ justifyContent: "center" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaUsuarioNetCos.map((usuarioNetcos) => {
                    return (
                      <tr>
                        <td className={styles.celulaComImagem}>
                          <Avatar
                            label={usuarioNetcos.nome.charAt(0)}
                            shape="circle"
                            size="xlarge"
                            image={usuarioNetcos.urlAvatar}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />
                          <p>{usuarioNetcos.nome}</p>
                        </td>
                        <td>{usuarioNetcos.email}</td>
                        <td>
                          {usuarioNetcos.telefone.toString()}
                        </td>
                        <td>
                          <div
                            className={`${styles.status} ${styles[usuarioNetcos.status.toLowerCase()]
                              }`}
                          >
                            <BsRecordCircleFill
                              style={{
                                color: `${(() => {
                                  switch (usuarioNetcos.status) {
                                    case "Pendente":
                                      return "var(--laranja-500)";

                                    case "Ativo":
                                      return "var(--verde-500)";

                                    case "Removido":
                                      return "var(cinza-400)";
                                  }
                                })()}`,
                                fontSize: "1rem",
                              }}
                            />
                            {usuarioNetcos.status}
                          </div>
                        </td>

                        <td className="colunaBotoesAcoes">
                          <BotaoColunaAcoes
                            status={
                              usuarioNetcos.email !== "master@netcos.art.br"
                                ? usuarioNetcos.status
                                : "Removido"
                            }
                            icone={"pi-pencil"}
                            titulo={
                              usuarioNetcos.email !== "master@netcos.art.br"
                                ? "Editar Usuário"
                                : "Usuário Master não pode ser editado"
                            }
                            onClick={() => {
                              abriModalEditar(usuarioNetcos);
                            }}
                          />
                          {usuarioNetcos.status == "Pendente" ? (
                            <BotaoColunaAcoes
                              status={
                                usuarioNetcos.email !== "master@netcos.art.br"
                                  ? usuarioNetcos.status
                                  : "Removido"
                              }
                              icone={"pi-send"}
                              titulo={
                                usuarioNetcos.email !== "master@netcos.art.br"
                                  ? "Reenviar email de Confirmação"
                                  : "Usuário Master não precisa confirmar o email"
                              }
                              onClick={() => {
                                abriModalReenvioSenha(usuarioNetcos);
                              }}
                            />
                          ) : (
                            <BotaoColunaAcoes
                              status={
                                usuarioNetcos.email !== "master@netcos.art.br"
                                  ? usuarioNetcos.status
                                  : "Removido"
                              }
                              icone={"pi-lock"}
                              titulo={
                                usuarioNetcos.email !== "master@netcos.art.br"
                                  ? "Redefinir Senha"
                                  : "Usuário Master não pode ter a senha redefinida"
                              }
                              onClick={() => {
                                abriModalRedefinicao(usuarioNetcos);
                              }}
                            />
                          )}
                          <BotaoColunaAcoes
                            status={
                              usuarioNetcos.email !== "master@netcos.art.br"
                                ? ""
                                : "Removido"
                            }
                            icone={
                              usuarioNetcos.status === "Removido"
                                ? "pi-user-plus"
                                : "pi-trash"
                            }
                            titulo={
                              usuarioNetcos.email !== "master@netcos.art.br"
                                ? usuarioNetcos.status == "Removido"
                                  ? "Reativar Usuário"
                                  : "Remover Usuário"
                                : "Usuário Master não pode ser removido"
                            }
                            onClick={() => {
                              setModalAbertoDesativacao(true);
                              setIdUsuarioNetcos(usuarioNetcos.idCredenciais);
                              setNomeUsuarioNetcos(usuarioNetcos.nome);
                              setImagemAvatarUsuarioNetcos(
                                usuarioNetcos.urlAvatar
                              );
                              setTelefoneUsuarioNetcos(usuarioNetcos.telefone);
                              setStatusUsuarioNetcos(usuarioNetcos.status);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </>
        )}
      </tbody>

      <div className={styles.listaCardsUsuario}>
        {listaUsuarioNetCos.length > 0 ? (
          listaUsuarioNetCos.map((usuario, index) => {
            return (
              <CardUsuarioNetcos
                usuario={usuario}
                setNomeUsuario={setNomeUsuarioNetcos}
                setImagemAvatarUsuario={setImagemAvatarUsuarioNetcos}
                setTelefoneUsuario={setTelefoneUsuarioNetcos}
                setIdUsuario={setIdUsuarioNetcos}
                setStatusUsuario={setStatusUsuarioNetcos}
                setModalRedefinicao={abriModalRedefinicao}
                setModalReenvioSenha={abriModalReenvioSenha}
                setModalEditar={abriModalEditar}
                setModalDesativacao={setModalAbertoDesativacao}
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
      <tfoot className={styles.footerTabela}>
        {/* <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            {seletorDeQuantidade}
          </div>
          {paginacao}
        </div> */}
      </tfoot>
      {modalEditarUsuarioNetCos && usuarioNetCosSelecionado && (
        <ModalAtualizacaoDeUsuarioNetcos
          id={usuarioNetCosSelecionado.idCredenciais}
          imagemAvatarPrevia={usuarioNetCosSelecionado.urlAvatar}
          nomePrevio={usuarioNetCosSelecionado.nome}
          telefonePrevio={usuarioNetCosSelecionado.telefone}
          modalAberto={modalEditarUsuarioNetCos}
          setModalAberto={setModalEditarUsuarioNetCos}
          setRecarregar={setRecarregar}
          statusPrevio={usuarioNetCosSelecionado.status}
        />
      )}
      {modalRedefinicao && usuarioNetCosSelecionado && (
        <ModalRedefinicao
          modalAberto={modalRedefinicao}
          setModalAberto={setModalRedefinicao}
          emailPrevio={usuarioNetCosSelecionado.email}
        />
      )}
      {modalReenvioSenha && usuarioNetCosSelecionado && (
        <ModalReenvioSenha
          modalAberto={modalReenvioSenha}
          setModalAberto={setModalReenvioSenha}
          emailPrevio={usuarioNetCosSelecionado.email}
        />
      )}
      {modalAbertoDesativacao && (
        <ModalDesativacao
          modalAberto={modalAbertoDesativacao}
          setModalAberto={setModalAbertoDesativacao}
          nomeItemParaDesativar={nomeUsuarioNetcos}
          status={statusUsuarioNetcos}
          onClick={
            statusUsuarioNetcos === "Removido"
              ? () => reativarUsuario()
              : desativarUsuarioNetcos
          }
        />
      )}
    </table>
  );
};
