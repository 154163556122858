import React from 'react';
import { Dropdown } from 'primereact/dropdown';

import styles from "./styles.module.css";


export type statusType = "Removido" | "Ativo" | "Pendente" | "";
export interface selectTipoDocumentoProps {
  setStatus: React.Dispatch<React.SetStateAction<statusType>>,
  status: statusType,
}

export const SelectStatus = ({ setStatus, status }: selectTipoDocumentoProps) => {

  const statusOptions = [
    {
      nome: 'Pendente',
      value: 'Pendente'
    },
    {
      nome: 'Ativo',
      value: 'Ativo'
    },
    {
      nome: 'Aguardando Remoção',
      value: 'AguardandoRemocao'
    },
    {
      nome: 'Removido',
      value: 'Removido'
    }
  ]


  return (
    <div className={styles.conteudoSelectStatus}>
      <Dropdown
        emptyMessage={"Nenhum tipo de status encontrado"}
        placeholder={"Selecione um status"}
        id="select-status"
        value={status}
        options={statusOptions}
        onChange={(e) => setStatus(e.target.value)}
        optionValue="value"
        optionLabel="nome"
        filterPlaceholder='Encontre um status'
        emptyFilterMessage={'Nenhum Status com esses termos encontrado.'}
        showClear
        style={{
          width: "100%",
          color: '#fff',
          outline: 'none',
          boxShadow: 'none',
          fontFamily: 'Exo 2',
          height: '3.5rem',
        }}
        panelStyle={{
          fontSize: '1.2rem',
        }}
      />
    </div>
  )
}