import React from "react";
import styles from "./styles.module.css";


interface CaixaFlutuanteFiltrosProps {
  setCaixaAberta: React.Dispatch<boolean>;
  children: React.ReactNode;
}

export const CaixaFlutuanteFiltros = ({
  setCaixaAberta,
  children,
}: CaixaFlutuanteFiltrosProps) => {
  return (
    <>
      <div
        className={styles.backgroundCaixaFlutuanteFiltro}
        onClick={() => setCaixaAberta(false)}
      ></div>
      <div className={styles.conteudoCaixaFlutuanteFiltro}>{children}</div>
    </>
  );
};
