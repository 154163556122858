import React from "react";
import { useNavigate } from "react-router-dom";
import { postSenhaRedefinir } from "../../services/usuario";

interface FunctionsRedefinicaoSenhaProps {
  senha: string;
  confirmacaoSenha: string;
  token: string;
  email: string;
  setErro: React.Dispatch<boolean>;
  setSucesso: React.Dispatch<boolean>;
  setCarregando: React.Dispatch<boolean>;
  myToast: React.MutableRefObject<any>;
  setErroConfirmacaoSenha: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FunctionsRedefinicaoSenha = ({
  email,
  senha,
  confirmacaoSenha,
  setCarregando,
  setErro,
  setSucesso,
  token,
  myToast,
  setErroConfirmacaoSenha,
}: FunctionsRedefinicaoSenhaProps) => {
  const navigate = useNavigate();
  function redefinirSenha() {
    setErro(false);
    setCarregando(true);
    if (senha === confirmacaoSenha) {
      postSenhaRedefinir(email, token, senha)
        .then(() => {
          setSucesso(true);
          setErro(false);
          showToast(
            "success",
            "Redefinição realizado",
            "Senha redefinida com sucesso"
          );
          setTimeout(() => navigate("/"), 2000);
        })
        .catch((err) => {
          console.error(err);
          setErro(true);
          showToast(
            "error",
            "Redefinição não realizado",
            "Não foi possível redefinir a senha."
          );
        })
        .finally(() => setCarregando(false));
    } else {
      setCarregando(false);
      setErroConfirmacaoSenha(true);
    }
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    redefinirSenha,
    showToast,
  };
};
