import { AxiosResponse } from "axios";
import { api } from "./api";

import { listaAgenciaProps } from "./agencia";
import { statusType } from "../components/Select/SelectStatus";

export interface listaUsuarioAgenciaProps {
  idCredenciais: string;
  nome: string;
  email: string;
  telefone: number;
  status: string;
  urlAvatar: string;
  agencia: listaAgenciaProps;
}

export interface usuarioAgenciaProps {
  nome: string;
  email: string;
  imagemAvatar: string;
  telefone: string;
  idAgencia: string;
  status: statusType;
}


export const postUsuarioAgencia = (
  token: string,
  usuario: usuarioAgenciaProps,
  enviarNotificacaoDeCadastro: boolean
) => {
  const url = "/usuarioAgencia";

  return api.post(
    url,
    { usuario, enviarNotificacaoDeCadastro },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putUsuarioAgencia = (
  token: string,
  id: string,
  nome: string,
  imagemAvatar: string | null,
  telefone: string,
  idAgencia: string,
  status: string,
) => {
  const url = "/UsuarioAgencia";

  const usuario = {
    id,
    nome,
    imagemAvatar,
    telefone,
    idAgencia,
    status,
  };

  return api.put(
    url,
    { usuario },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export interface respostaGetUsuarioAgencia {
  codigo: number;
  usuario: listaUsuarioAgenciaProps;
}

export const getUsuarioAgencia = (
  token: string,
  idCredenciais: string
): Promise<AxiosResponse<respostaGetUsuarioAgencia, any>> => {
  const url = `/UsuarioAgencia?IdCredenciais=${idCredenciais}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export interface respostaGetUsuarioAgenciaTodos {
  codigo: number;
  totalRegistros: number;
  usuarios: listaUsuarioAgenciaProps[];
}
export const getUsuariosAgenciaTodos = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetUsuarioAgenciaTodos, any>> => {
  const url = `/UsuarioAgencia/Todos?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export const getUsuarioAgenciaTodosNome = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
  nomePesquisa: string
): Promise<AxiosResponse<respostaGetUsuarioAgencia, any>> => {
  const url = `/UsuarioAgencia/Todos/Nome?Nome=${nomePesquisa}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioAgenciaTodosAgencia = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
  idAgencia: string
): Promise<AxiosResponse<respostaGetUsuarioAgencia, any>> => {
  const url = `/UsuarioAgencia/Todos/Agencia?IdAgencia=${idAgencia}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsuarioAgenciaTodosStatus = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string,
  status: string
): Promise<AxiosResponse<respostaGetUsuarioAgencia, any>> => {
  const url = `/UsuarioAgencia/Todos/Status?Status=${status}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
