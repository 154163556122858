import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesSelectAgencia } from "./functions";

import { ErroType } from "../../../@types/erro";
import { Button } from "../../Botoes/Button";
import { getInfluenciadorProps } from "../../../services/influenciador";
import { ModalCadastroInfluenciador } from "../../Modais/ModalCadastroInfluenciador";
import { tipoOrdenacao } from "../../../@types/ordenacao";

import { BsRecordCircleFill } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface listaAgenciasSelectProps {
  idInfluenciador: string;
  setIdInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  emailInfluenciador?: string;
  perfilInfluenciador?: string;
  nomeInfluenciador?: string;
  setEmailInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setPerfilInfluenciador: React.Dispatch<React.SetStateAction<string>>;
}
export const SelectInfluenciador = ({
  idInfluenciador,
  perfilInfluenciador,
  nomeInfluenciador,
  emailInfluenciador,
  setIdInfluenciador,
  setEmailInfluenciador,
  setNomeInfluenciador,
  setPerfilInfluenciador,
}: listaAgenciasSelectProps) => {
  const [modalCadastroInfluenciador, setModalCadastroInfluenciador] =
    useState<boolean>(false);
  const [numeroPagina, setNumeroPagina] = useState<number>(1);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(2000);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [carregando, setCarregando] = useState<boolean>(false);

  const [recarregar, setRecarregar] = useState<boolean>(true);

  const [listaInfluenciadores, setListaInfluenciadores] = useState<getInfluenciadorProps[]>([]);

  const [tipoOrdenacao, setTipoOrdenacao] = useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<string>("Nome");
  const [nomeInput, setNomeInput] = useState<string>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { listarTodosInflueciadores, pegaEmailENomeIncluenciadorComId } =
    FuncoesSelectAgencia({
      numeroPagina,
      tamanhoPagina,
      setListaInfluenciadores,
      setErro,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      listaInfluenciadores,
      setIdInfluenciador,
      setNomeInfluenciador,
      setEmailInfluenciador,
      setPerfilInfluenciador,
      nomePropriedadeOrdenacao,
      tipoOrdenacao,
    });

  const modeloOpcoesInfluenciador = (influenciador: any) => {
    return (
      <div className={styles.opcoesInfluenciador}>
        <div
          className={
            influenciador.urlAvatar
              ? styles.containerAvatar
              : styles.containerAvatarSemFoto
          }
        ></div>
        <div className={styles.containerExibicaoAvatar}>
          <img src={influenciador.urlAvatar} className={styles.avatarMaior} />
        </div>

        <div className={styles.opcaoInfluenciador}>
          <BsRecordCircleFill
            style={{
              color: `${(() => {
                switch (influenciador.status) {
                  case "Pendente":
                    return "var(--laranja-500)";

                  case "Ativo":
                    return "var(--verde-500)";

                  case "Removido":
                    return "var(cinza-400)";
                }
              })()}`,
              fontSize: "1rem",
            }}
            title={influenciador.status}
          />
          <p>{influenciador.nome}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    listarTodosInflueciadores();
  }, [recarregar]);

  return erro.ativo ? (
    <p style={{ zIndex: "1", position: "relative" }}>{erro.mensagem}</p>
  ) : (
    <div className={styles.selectInfluenciador}>
      {!modalCadastroInfluenciador && (
        <Dropdown
          emptyMessage={
            carregando
              ? "Carregando..."
              : "Nenhum(a) influenciador(a) cadastrado(a)"
          }
          placeholder={"Selecione um(a) Influenciador(a)"}
          id="select-influenciador"
          value={idInfluenciador}
          options={listaInfluenciadores}
          onChange={(e) => pegaEmailENomeIncluenciadorComId(e.target.value)}
          optionValue="idCredenciais"
          optionLabel="nome"
          itemTemplate={modeloOpcoesInfluenciador}
          filter
          onFilter={(e) => setNomeInput(e.filter)}
          resetFilterOnHide
          filterPlaceholder="Encontre um(a) Influenciador(a)"
          emptyFilterMessage={
            <Button
              importancia="secundario"
              type="button"
              title="Adicionar Influenciador(a)"
              onClick={() => {
                setModalCadastroInfluenciador(true);
              }}
            />
          }
          showClear
          style={{
            width: "100%",
            background: "#ffffff60",
            backdropFilter: "blur(5px)",
            color: "#fff",
            outline: "none",
            boxShadow: "none",
            fontFamily: "Exo 2",
            height: "3.5rem",
          }}
          panelStyle={{
            backdropFilter: "blur(5px)",
            fontSize: "1.2rem",
          }}
          filterBy="nome"
        />
      )}

      <ModalCadastroInfluenciador
        nomeInput={nomeInput}
        nomeInfluenciador={nomeInfluenciador}
        emailInfluenciador={emailInfluenciador}
        perfilInfluenciador={perfilInfluenciador}
        setIdInfluenciador={setIdInfluenciador}
        setNomeInfluenciador={setNomeInfluenciador}
        setEmailInfluenciador={setEmailInfluenciador}
        setPerfilInfluenciador={setPerfilInfluenciador}
        setRecarregar={setRecarregar}
        modalAberto={modalCadastroInfluenciador}
        setModalAberto={setModalCadastroInfluenciador}
      />
    </div>
  );
};
