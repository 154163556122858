import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import {
  basicDataProps,
  FuncoesGraficoVisaoGeralPorRedeSocial,
} from "./functions";

import styles from "./styles.module.css";
import { PublicacoesProps } from "../../../services/publicacao";

interface GraficoVisaoGeralPorRedeSocialProps {
  publicacoes: PublicacoesProps[];
}

export const GraficoVisaoGeralPorRedeSocial = ({
  publicacoes,
}: GraficoVisaoGeralPorRedeSocialProps) => {
  const [alcanceTotal, setAlcanceTotal] = useState<number>(0);
  const [engajamentoTotal, setEngajamentoTotal] = useState<number>(0);
  const [impressoesTotal, setImpressoesTotal] = useState<number>(0);
  const [comentariosTotal, setComentariosTotal] = useState<number>(0);
  const [compartilhamentosTotal, setCompartilhamentosTotal] =
    useState<number>(0);
  const [likesTotal, setLikesTotal] = useState<number>(0);
  const [dislikesTotal, setDislikesTotal] = useState<number>(0);
  const [salvosTotal, setSalvosTotal] = useState<number>(0);
  const [retweetsTotal, setRetweetsTotal] = useState<number>(0);
  const [favoritosTotal, setFavoritosTotal] = useState<number>(0);
  const [twitter, setTwitter] = useState<number>(0);
  const [instagram, setInstagram] = useState<number>(0);
  const [tiktok, setTiktok] = useState<number>(0);
  const [youtube, setYoutube] = useState<number>(0);

  const [youtubeExiste, setYoutubeExiste] = useState<boolean>(false);
  const [tiktokExiste, setTiktokExiste] = useState<boolean>(false);
  const [instagramExiste, setInstagramExiste] = useState<boolean>(false);
  const [twitterExiste, setTwitterExiste] = useState<boolean>(false);

  const [basicData, setBasicData] = useState<basicDataProps>({
    labels: [],
    datasets: [],
  });

  const [basicOptions, setBasicOptions] = useState<any>({});

  useEffect(() => {
    setaDados(publicacoes);
    getLightTheme();
  }, [publicacoes]);

  const { setaDados, getLightTheme } = FuncoesGraficoVisaoGeralPorRedeSocial({
    setBasicData,
    setBasicOptions,
    setAlcanceTotal,
    setComentariosTotal,
    setCompartilhamentosTotal,
    setDislikesTotal,
    setEngajamentoTotal,
    setFavoritosTotal,
    setImpressoesTotal,
    setInstagram,
    setLikesTotal,
    setRetweetsTotal,
    setSalvosTotal,
    setTiktok,
    setTwitter,
    setYoutube,
    setInstagramExiste,
    setTiktokExiste,
    setTwitterExiste,
    setYoutubeExiste,
  });

  return (
    <>
      <div className={styles.containerGrafico}>
        <Chart
          id="divGraficoVisaoGeralPorRedeSocial"
          type="bar"
          data={basicData}
          options={basicOptions}
        />
      </div>
    </>
  );
};
