import { Prints } from "../../../services/publicacao";


interface datasetsProps {
  data: number[];
  backgroundColor?: string[];
  hoverBackgroundColor?: string[];
}

export interface basicDataProps {
  labels: string[];
  datasets: datasetsProps[];
}

interface FuncoesGraficoRepercussaoProps {
  setBasicData: React.Dispatch<React.SetStateAction<basicDataProps>>;
}

export const FuncoesGraficoRepercussao = ({
  setBasicData,
}: FuncoesGraficoRepercussaoProps) => {
  const setaDados = (printSentimento: Prints[]) => {
    let positivo: number = 0;
    let neutro: number = 0;
    let negativo: number = 0;

    printSentimento &&
      printSentimento.map((printSentimento) => {
        switch (printSentimento.sentimento) {
          case "Positivo":
            positivo = positivo + 1;
            break;

          case "Neutro":
            neutro = neutro + 1;
            break;

          case "Negativo":
            negativo = negativo + 1;
            break;
        }
      });

    let basicData = {
      labels: ["Positivos", "Neutros", "Negativos"],
      datasets: [
        {
          label: "Número de comentários",
          data: [positivo, neutro, negativo],
          backgroundColor: ["#00AD2B", "#c4c4c4", "#ff0000"],
          hoverBackgroundColor: ["#07886e", "#a8a8a8", "#a50303"],
          datalabels: {
            anchor: 'center',
            backgroundColor: null,
            borderWidth: 0
          }
        },
      ],
    };

    setBasicData(basicData);
  };

  return {
    setaDados,
  };
};
