import { useState, useEffect } from "react";

import styles from "./styles.module.css";

import { format } from "../../../utils/MascaraNumero/format";
import { FuncoesTabelaComparativoRedesSociais } from "./functions";
import { campanha } from "../../../services/campanha";

export interface TabelaComparativoRedesSociaisProps {
  campanha: campanha;
}

export const TabelaComparativoRedesSociais = ({
  campanha
}: TabelaComparativoRedesSociaisProps) => {

  // tem tipo de publicação?
  const [publicacaoIgFeed, setPublicacaoIgFeed] = useState<boolean>(false);
  const [publicacaoIgReels, setPublicacaoIgReels] = useState<boolean>(false);
  const [publicacaoIgStories, setPublicacaoIgStories] = useState<boolean>(false);
  const [publicacaoTwitter, setPublicacaoTwitter] = useState<boolean>(false);
  const [publicacaoTikTok, setPublicacaoTikTok] = useState<boolean>(false);
  const [publicacaoYouTubeVideo, setPublicacaoYouTubeVideo] = useState<boolean>(false);
  const [publicacaoYouTubeShort, setPublicacaoYouTubeShort] = useState<boolean>(false);

  // Total de Posts
  const [totalIgFeed, setTotalIgFeed] = useState<number>(0);
  const [totalIgReels, setTotalIgReels] = useState<number>(0);
  const [totalIgStories, setTotalIgStories] = useState<number>(0);
  const [totalTikTok, setTotalTikTok] = useState<number>(0);
  const [totalTwitter, setTotalTwitter] = useState<number>(0);
  const [totalYouTubeVideo, setTotalYouTubeVideo] = useState<number>(0);
  const [totalYouTubeShort, setTotalYouTubeShort] = useState<number>(0);

  let totalPosts = totalIgFeed + totalIgReels + totalIgStories + totalTikTok + totalTwitter + totalYouTubeVideo + totalYouTubeShort

  //Compartilhamentos
  const [compartilhamentosIgFeed, setCompartilhamentosIgFeed] = useState<number>(0);
  const [compartilhamentosIgReels, setCompartilhamentosIgReels] = useState<number>(0);
  const [compartilhamentosIgStories, setCompartilhamentosIgStories] = useState<number>(0);
  const [compartilhamentosTikTok, setCompartilhamentosTikTok] = useState<number>(0);

  let totalCompartilhamentos = compartilhamentosIgFeed + compartilhamentosIgReels + compartilhamentosIgStories + compartilhamentosTikTok

  let arrImpressoes = [campanha.instagramFeedImpressoes, campanha.instagramReelsImpressoes, campanha.instagramStoriesImpressoes, campanha.tiktokImpressoes, campanha.twitterImpressoes, campanha.youtubeVideoImpressoes, campanha.youtubeShortsImpressoes]

  let arrInteracoes = [campanha.instagramFeedEngajamento, campanha.instagramReelsEngajamento, campanha.instagramStoriesEngajamento, campanha.tiktokEngajamento, campanha.twitterEngajamento, campanha.youtubeVideoEngajamento, campanha.youtubeShortsEngajamento]

  let arrCompartilhamentos = [compartilhamentosIgFeed, compartilhamentosIgReels, compartilhamentosIgStories, compartilhamentosTikTok]

  let arrTxEngajamento = [campanha.instagramFeedTaxaEngajamento, campanha.instagramReelsTaxaEngajamento, campanha.instagramStoriesTaxaEngajamento, campanha.tiktokTaxaEngajamento, campanha.twitterTaxaEngajamento, campanha.youtubeVideoTaxaEngajamento, campanha.youtubeShortsTaxaEngajamento]

  const { setaDadosDaTabela, identificaTop3 } = FuncoesTabelaComparativoRedesSociais({
    campanha,
    setPublicacaoIgFeed,
    setPublicacaoIgReels,
    setPublicacaoIgStories,
    setPublicacaoTwitter,
    setPublicacaoTikTok,
    setPublicacaoYouTubeVideo,
    setPublicacaoYouTubeShort,
    setTotalIgFeed,
    setTotalIgReels,
    setTotalIgStories,
    setTotalTikTok,
    setTotalTwitter,
    setTotalYouTubeVideo,
    setTotalYouTubeShort,
    setCompartilhamentosIgFeed,
    setCompartilhamentosIgReels,
    setCompartilhamentosIgStories,
    setCompartilhamentosTikTok,
  });



  useEffect(() => {
    setaDadosDaTabela();
  }, []);  

  return (
    <table className={styles.tabela}>
      <thead>
        <tr>
          <th className={`${styles.headerTabela}`}>
            Tipo de publicação
          </th>

          <th
            className={`${styles.headerTabela}`}>
            Total de posts
          </th>

          <th className={`${styles.headerTabela}`}>
            Impressões
          </th>

          <th className={`${styles.headerTabela}`}>
            Interações
          </th>

          {
            (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
            <th className={`${styles.headerTabela}`}>
              Compartilhamentos
            </th>
          }

          <th className={`${styles.headerTabela}`} >
            Taxa de Engajamento
          </th>
        </tr>
      </thead>

      <tbody>
        {publicacaoIgFeed &&
          <tr>
            <td>
              Instagram Feed
            </td>
            <td>
              {format("#.###.##0,#0", totalIgFeed).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramFeedImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramFeedImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramFeedEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramFeedEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td className={styles[`${identificaTop3(compartilhamentosIgFeed, arrCompartilhamentos)}`]}>
                {format("#.###.##0,#0", compartilhamentosIgFeed).replace(",00", "")}
              </td>
            }
            <td className={styles[`${identificaTop3(campanha.instagramFeedTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.instagramFeedTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoIgReels &&
          <tr>
            <td>Instagram Reels</td>
            <td>
              {format("#.###.##0,#0", totalIgReels).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramReelsImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramReelsImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramReelsEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramReelsEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td className={styles[`${identificaTop3(compartilhamentosIgReels, arrCompartilhamentos)}`]}>
                {format("#.###.##0,#0", compartilhamentosIgReels).replace(",00", "")}
              </td>
            }
            <td className={styles[`${identificaTop3(campanha.instagramReelsTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.instagramReelsTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoIgStories &&
          <tr>
            <td>Instagram Stories</td>
            <td>
              {format("#.###.##0,#0", totalIgStories).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramStoriesImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramStoriesImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.instagramStoriesEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.instagramStoriesEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td className={styles[`${identificaTop3(compartilhamentosIgStories, arrCompartilhamentos)}`]}>
                {format("#.###.##0,#0", compartilhamentosIgStories).replace(",00", "")}
              </td>
            }
            <td className={styles[`${identificaTop3(campanha.instagramStoriesTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.instagramStoriesTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoTikTok &&
          <tr>
            <td>TikTok</td>
            <td>
              {format("#.###.##0,#0", totalTikTok).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.tiktokImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.tiktokImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.tiktokEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.tiktokEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td className={styles[`${identificaTop3(compartilhamentosTikTok, arrCompartilhamentos)}`]}>
                {format("#.###.##0,#0", compartilhamentosTikTok).replace(",00", "")}
              </td>
            }
            <td className={styles[`${identificaTop3(campanha.tiktokTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.tiktokTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoTwitter &&
          <tr>
            <td>Twitter</td>
            <td>
              {format("#.###.##0,#0", totalTwitter).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.twitterImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.twitterImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.twitterEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.twitterEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td>

              </td>
            }
            <td className={styles[`${identificaTop3(campanha.twitterTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.twitterTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoYouTubeVideo &&
          <tr>
            <td>YouTube Video</td>
            <td>
              {format("#.###.##0,#0", totalYouTubeVideo).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.youtubeVideoImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.youtubeVideoImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.youtubeVideoEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.youtubeVideoEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td>

              </td>
            }
            <td className={styles[`${identificaTop3(campanha.youtubeVideoTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.youtubeVideoTaxaEngajamento)}
            </td>
          </tr>
        }

        {publicacaoYouTubeShort &&
          <tr>
            <td>YouTube Shorts</td>
            <td>
              {format("#.###.##0,#0", totalYouTubeShort).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.youtubeShortsImpressoes, arrImpressoes)}`]}>
              {format("#.###.##0,#0", campanha.youtubeShortsImpressoes).replace(",00", "")}
            </td>
            <td className={styles[`${identificaTop3(campanha.youtubeShortsEngajamento, arrInteracoes)}`]}>
              {format("#.###.##0,#0", campanha.youtubeShortsEngajamento).replace(",00", "")}
            </td>
            {
              (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
              <td>

              </td>
            }
            <td className={styles[`${identificaTop3(campanha.youtubeShortsTaxaEngajamento, arrTxEngajamento)}`]}>
              {format("#.###.##0,#0%", campanha.youtubeShortsTaxaEngajamento)}
            </td>
          </tr>
        }

        <tr className={styles.footerTabela}>
          <td>Totais</td>
          <td>
            {format("#.###.##0,#0", totalPosts).replace(",00", "")}
          </td>
          <td>
            {format("#.###.##0,#0", campanha.numeroImpressoes).replace(",00", "")}
          </td>
          <td>
            {format("#.###.##0,#0", campanha.engajamentoTotal).replace(",00", "")}
          </td>
          {
            (publicacaoIgFeed || publicacaoIgReels || publicacaoIgStories || publicacaoTikTok) &&
            <td>
              {format("#.###.##0,#0", totalCompartilhamentos).replace(",00", "")}
            </td>
          }
          <td>
            {format("#.###.##0,#0%", campanha.mediaTaxaEngajamento)}
          </td>
        </tr>
      </tbody>
      <div className={styles.legenda}>

        <div className={`${styles.legendaItem} ${styles.top1}`}></div><div>Maior valor</div>

        <div className={`${styles.legendaItem} ${styles.top2}`}></div><div>Segundo maior valor</div>

        <div className={`${styles.legendaItem} ${styles.top3}`}></div><div>Terceiro maior valor</div>

      </div>
    </table >


  );
};
