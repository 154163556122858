import { useRef, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesModalExclusaoPublicacao } from "./functions";

import { Button } from "../../Botoes/Button";
import { ModalTeste } from "../ModalTeste";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface ModalExclusaoProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  idCampanha: string;
  idPublicacao: string;
  setRecarregar: () => void;
}

export const ModalExclusaoDePublicacao = ({
  modalAberto,
  setModalAberto,
  idCampanha,
  idPublicacao,
  setRecarregar,
}: ModalExclusaoProps) => {
  const [carregando, setCarregando] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { deletarPublicacao } = FuncoesModalExclusaoPublicacao({
    idCampanha,
    idPublicacao,
    myToast,
    setCarregando,
    setRecarregar,
    setModalAberto,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Excluir publicação"}
    >
      <div className={styles.conteudoModalDesativacao}>
        <div className={styles.conteudoInformacoesModalDesativacao}>
          <p className={styles.avisoConfirmacao}>
            Tem certeza que deseja excluir essa publicação?
          </p>
        </div>
        <div className={styles.containerBotoesModal}>
          <Button
            importancia="terciario"
            type="button"
            title="Cancelar"
            onClick={() => setModalAberto(false)}
          />
          <Button
            importancia="primario"
            title="Confirmar"
            onClick={() => deletarPublicacao(idCampanha, idPublicacao)}
          />
        </div>
      </div>
    </ModalTeste>
  );
};
