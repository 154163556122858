import { credenciais } from "../../services/usuario"

export const salvarTokens = (token: string, tokenRenovacao: string) => {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenRenovacao', tokenRenovacao)
}

export const obterTokens = () => {
    const token = localStorage.getItem("token")
    const tokenRenovacao = localStorage.getItem('tokenRenovacao')

    return {
        token: token ? token : '',
        tokenRenovacao: tokenRenovacao ? tokenRenovacao : ''
    }
}

export const salvaCredenciais = (credenciais: credenciais) => {
    localStorage.setItem('credenciais', JSON.stringify(credenciais))
}

export const limpaLocalStorage = () => {
    localStorage.clear()
}

export const obterCredenciais = () => {
    const credenciais = localStorage.getItem('credenciais')
    let retorno: credenciais = credenciais ? JSON.parse(credenciais) : { perfil: [""] }
    return {
        credenciais: retorno
    }
}