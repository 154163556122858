import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  Documentos,
  getDocumentosLegais,
} from "../../../services/documentosLegais";
import { obterTokens } from "../../../utils/LocalStorage";

interface FuncoesTermosDeUsoListagemProps {
  setListaDeDocumentos: React.Dispatch<React.SetStateAction<Documentos[]>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalDeDocumentos: React.Dispatch<React.SetStateAction<number>>;
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesTermosDeUsoListagem = ({
  setCarregando,
  setErro,
  setListaDeDocumentos,
  setTotalDeDocumentos,
  numeroPagina,
  tamanhoPagina,
  nomePropriedadeOrdenacao,
  tipoOrdenacao,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesTermosDeUsoListagemProps) => {
  function pegaDocumentosLegais() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);
    getDocumentosLegais(
      "PoliticaDeConteudo",
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeDocumentos(res.data.documentos);
        setTotalDeDocumentos(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getDocumentosLegais(
              "PoliticaDeConteudo",
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                setListaDeDocumentos(res.data.documentos);
                setTotalDeDocumentos(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem:
                    "Ocorreu um erro ao listar os documentos, tente novamente mais tarde.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem:
              "Ocorreu um erro ao listar os documentos, tente novamente mais tarde.",
          });
          setCarregando(false);
        }
      });
  }

  return {
    pegaDocumentosLegais,
  };
};
