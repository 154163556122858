import { useState } from 'react';
import { cadastroPublicacaoInstagramFeedProps, 
          cadastroPublicacaoInstagramReelsProps, 
          cadastroPublicacaoInstagramStoriesProps, 
          cadastroPublicacaoTikTokProps, 
          cadastroPublicacaoTwitterProps, 
          cadastroPublicacaoYouTubeProps, 
          cadastroPublicacoesProps} from '../../../services/campanha';
import { mascaraBRLExibir } from '../../../utils/MascaraBRL';

import styles from './styles.module.css';

export interface CardTarefaCampanhaProps {
  publicacoes: Array<
    cadastroPublicacoesProps
    | cadastroPublicacaoInstagramFeedProps
    | cadastroPublicacaoInstagramReelsProps
    | cadastroPublicacaoInstagramStoriesProps
    | cadastroPublicacaoTikTokProps
    | cadastroPublicacaoTwitterProps
    | cadastroPublicacaoYouTubeProps
  >;
  nomeInfluenciador: string;
  valorContrato: number;
  emailInfluenciador: string;
  removerTarefas?: (indexDaTarefa: number) => void;
  index: number;
}

export const CardTarefaCampanha = ({ publicacoes, nomeInfluenciador, valorContrato, emailInfluenciador, removerTarefas, index }: CardTarefaCampanhaProps) => {

  const [abrirCardTarefa, setAbrirCardTarefa] = useState<boolean>(false);

  return (
    <>
      <div className={styles.cardTarefa}>
        <header className={styles.headerCardTarefa} onClick={() => setAbrirCardTarefa(!abrirCardTarefa)}>
          <p>{nomeInfluenciador}</p>


          <button
            className={styles.botaoRemoverTarefa}
            onClick={() =>
              removerTarefas && removerTarefas(index)
            }
          >
            <i className="pi pi-trash"></i>
          </button>
        </header>
        <body className={abrirCardTarefa ? styles.bodyCardTarefaAberto : styles.bodyCardTarefaFechado}>
          <div className={styles.emailCard}>
            {emailInfluenciador}
          </div>
          <div className={styles.publicacoesCard}>
            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoTwitter").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>
              Twitter: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoTwitter").length}
            </p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoInstagramReels").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>IG Reels: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoInstagramReels").length}</p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoInstagramFeed").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>IG Feed: {publicacoes.filter(x => x.tipoPublicacao === 'PublicacaoInstagramFeed').length}</p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoInstagramStories").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>IG Stories: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoInstagramStories").length}</p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoYouTube").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>YouTube: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoYouTube").length}
            </p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoYouTubeShorts").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>YT Shorts: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoYouTubeShorts").length}</p>

            <p className={(publicacoes.filter(x => x.tipoPublicacao === "PublicacaoTikTok").length) > 0 ? styles.itemTarefaMostrar : styles.itemTarefaEsconder}>Tiktok: {publicacoes.filter(x => x.tipoPublicacao === "PublicacaoTikTok").length}</p>

          </div>
        <div className={styles.footerCard}>
          <p>Valor: R$ {mascaraBRLExibir(valorContrato.toFixed(2))}</p>
        </div>
        </body>
      </div>
    </>
  )
}