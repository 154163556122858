import { InvalidFieldError } from "../errors";
import { FieldValidation } from "../protocols";


export class MinLengthValidation implements FieldValidation {
  constructor(readonly field: string, private readonly minLength: number) {}

  validate(input: Record<string, any>): Error | null {
    const value = input[this.field];
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length < this.minLength ? new InvalidFieldError() : null;
    }
    return new InvalidFieldError();
  }
}