/**
 *
 * Método que filtra caractéres númericos de dados
 * e retorna uma string contendo apenas números
 * Utiliza Regex.
 *
 * @param value valor a ser formatado
 * @returns string contendo apenas dígitos numéricos
 */
export function filtrarApenasDigitos(value: any): number {
  if (typeof value !== "string") {
    value = value.toString();
  }
    return value.replace(/\D/g, "").parseFloat();
}

/**
 *
 * Método que filtra caractéres númericos de dados separados por vírgula
 * e retorna um number contendo apenas números
 *
 * @param input valor a ser formatado
 * @returns number contendo apenas dígitos numéricos
 */
export function converterStringParaNumberContendoVirgula(
  input: string
): number {
  const stringLimpa = input.replace(/,/g, "");
  const valorNumerico = parseFloat(stringLimpa);
      return isNaN(valorNumerico) ? NaN : valorNumerico;
}

/**
 *
 * Método que filtra caractéres númericos que contém a consoante "k",
 * converte seu valor para dezena de milhar,
 * e retorna um number contendo apenas números
 *
 * @param input valor a ser formatado
 * @returns number contendo apenas dígitos numéricos
 */

export function converterStringParaNumberMilhar(input: string): number {
  const stringLimpa = input.replace(/,/g, "").trim();
  const temK = stringLimpa.toLowerCase().includes("k");
  const temM = stringLimpa.toLowerCase().includes("m");
  const valorNumerico = parseFloat(stringLimpa.replace(/k|K|m|M$/, ""));
 
   if (isNaN(valorNumerico)) {
    return NaN;
  } else {
    if (temK) {
      return valorNumerico * 1000;
    } else if (temM) {
      return valorNumerico * 1000000;
    } else {
      return valorNumerico;
    }
  }
}




interface TimeObject {
  horas: number;
  minutos: number;
  segundos: number;
}

/**
 *
 * Método que converte a string em array de number,
 * dividindo em hora, minutos e segungos
 * @example: [30,42,07] = convertTimeString("30:42:07")
 * @param tempoEmString valor a ser formatado
 * @returns retorna um objeto TimeObject
 */
export const convertTimeString = (tempoEmString: string): TimeObject => {
  const valores = tempoEmString.split(':');
  const tempo = valores.map(item=>Number(item.replace(/\D/g, "")))
  const objTempo: TimeObject = {horas: tempo[0], minutos: tempo[1], segundos: tempo[2]}
    
    return  objTempo;
}

