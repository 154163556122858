export const FunctionVisaoGeral = () => {
  function tamanhoCaixaComentarioNetcos(comentario: string) {
    const primeiraTeg = comentario.substring(0, 3);
    // console.log(primeiraTeg);

    const paragrafos = comentario.split("<p>");
    let linhas = 0;

    paragrafos.forEach((paragrafo) => {
      const tamanhoTela = window.innerWidth;
      const tamanhoParagrafo = paragrafo.length;

      if (tamanhoTela <= 1000) {
        linhas += Math.ceil(tamanhoParagrafo / 80);
      } else if (tamanhoTela <= 1300) {
        linhas += Math.ceil(tamanhoParagrafo / 110);
      } else if (tamanhoTela <= 1300) {
        linhas += Math.ceil(tamanhoParagrafo / 140);
      } else if (tamanhoTela <= 1600) {
        linhas += Math.ceil(tamanhoParagrafo / 170);
      } else if (tamanhoTela <= 1900) {
        linhas += Math.ceil(tamanhoParagrafo / 200);
      } else {
        linhas += 1;
      }
    });

    console.log(linhas * 18, linhas);

    if (linhas < 10) {
      return { linhas: linhas, tamanho: linhas * 20 };
    } else {
      return { linhas: linhas, tamanho: 10 * 20 };
    }
  }

  return {
    tamanhoCaixaComentarioNetcos,
  };
};
