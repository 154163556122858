import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  getTarefasPorCampanha,
  tarefaProps,
  getTarefaPorNome,
} from "../../../services/tarefas";

import { obterTokens } from "../../../utils/LocalStorage";
import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";

interface FuncaoModalTarefasProps {
  idCampanha: string;
  listaDeTarefas: tarefaProps[];
  setListaDeTarefas: React.Dispatch<tarefaProps[]>;
  setTotalDeTarefas: React.Dispatch<number>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  nome: string;
  idTarefa: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesModalTarefas = ({
  idCampanha,
  setListaDeTarefas,
  setTotalDeTarefas,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  nomePropriedadeOrdenacao,
  numeroPagina,
  tamanhoPagina,
  tipoOrdenacao,
  nome,
}: FuncaoModalTarefasProps) => {
  const obterTarefasCampanha = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTarefasPorCampanha(
      token,
      idCampanha,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeTarefas(res.data.tarefas);
        setTotalDeTarefas(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTarefasPorCampanha(token, idCampanha)
              .then((res) => {
                setListaDeTarefas(res.data.tarefas);
                setTotalDeTarefas(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Tarefas.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Tarefas.",
          });
          setCarregando(false);
        }
      });
  };

  const obterTarefasCampanhaPorNome = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTarefaPorNome(
      token,
      idCampanha,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao,
      nome
    )
      .then((res) => {
        setListaDeTarefas(res.data.tarefas);
        setTotalDeTarefas(res.data.totalRegistros);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTarefaPorNome(
              token,
              idCampanha,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao,
              nome
            )
              .then((res) => {
                setListaDeTarefas(res.data.tarefas);
                setTotalDeTarefas(res.data.totalRegistros);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Tarefas.",
                });
              });
          }, setCarregando);
        } else if (
          err.response.data.mensagem === "Não há influenciadores cadastrados."
        ) {
          setListaDeTarefas([]);
          setTotalDeTarefas(0);
          setCarregando(false);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Tarefas.",
          });
          setCarregando(false);
        }
      });
  };

  function criaPDFListaInfluenciadoresCampanha() {
    const minhaTabela = document.getElementById(
      "tabelaInfluenciadoresDaCampanha"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Influenciador</th><th>Entregas</th><th>Alcance</th><th>Taxa Engajamento</th><th>Comparativo Histórico</th><th>Comparativo Campanha</th><th>Custo por Engajamento</th><th>CPM</th><th>Valor do Contrato</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }
  return {
    obterTarefasCampanha,
    obterTarefasCampanhaPorNome,
    criaPDFListaInfluenciadoresCampanha,
  };
};
