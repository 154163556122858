import styles from "./styles.module.css";

interface BotaoColunaAcoesProps {
    icone: string;
    titulo: string;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    status?: string;
}

export const BotaoColunaAcoes = ({
    status,
    icone,
    titulo,
    onClick
}: BotaoColunaAcoesProps) => {
    return (
        <button
            disabled={status === "Removido" ? true : false}
            onClick={onClick}
            className={
                status === "Removido" ?
                    styles.botaoIconeColunaAcoesDisabled :
                    styles.botaoIconeColunaAcoes
            }
        >
            <i
                className={"pi " + icone}
                style={{ fontSize: "1.4rem", paddingRight: "1rem" }}
                title={titulo}
            />
        </button>
    );
};
