import { useEffect, useState, useContext } from "react";

import { FuncoesTabelaPublicacao } from "../Tabelas/TabelaListarPublicacoesDeTarefa/functions";

import { CampoInformacoes } from "../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { PublicacoesProps } from "../../services/publicacao";

import { TabelaListarPublicacoesDeTarefa } from "../Tabelas/TabelaListarPublicacoesDeTarefa";
import { tipoOrdenacao } from "../../@types/ordenacao";
import { ErroType } from "../../@types/erro";
import { ModalAcrescentarPublicacaoNaTarefa } from "../Modais/ModalAdicionarPublicacaoNaTarefa";
import { ModalEditarTarefa } from "../Modais/ModalEditarTarefa";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";

export interface InfluenciadoresCampanhaProps {
  idTarefa: string;
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  setMostrarModalEditarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  publicacaoSelecionada: PublicacoesProps;
  setPublicacaoSelecionada: React.Dispatch<
    React.SetStateAction<PublicacoesProps>
  >;
  setMostrarModalAprovarTarefa: React.Dispatch<React.SetStateAction<boolean>>;
  dataInicioCampanha: string | undefined;

  mostrarModalEditarTarefa?: boolean;
}

export const Publicacoes = ({
  idTarefa,
  idCampanha,
  idInfluenciadorSelecionado,
  setMostrarModalEditarTarefa,
  setPublicacaoSelecionada,
  setMostrarModalAprovarTarefa,
  dataInicioCampanha,

  mostrarModalEditarTarefa,
  publicacaoSelecionada,
}: InfluenciadoresCampanhaProps) => {
  const [tamanhoDePagina, setTamanhoDePagina] = useState<number>(99999);
  const [numeroDePagina, setNumeroDePagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<
    "Id" | "DataFinalizacao" | "Status" | string
  >("Nome");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");

  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  // ------------ Lista de Publicações -------------
  const [totalDePublicacoes, setTotalDePublicacoes] = useState<number>(0);
  const [listaDePublicacoes, setListaDePublicacoes] = useState<
    PublicacoesProps[]
  >([]);

  const colunasPublicacoes = [
    { nome: "Rede Social", ordenacao: "tipo" },
    { nome: "Data da Publicação", ordenacao: "dataFinalizacao" },
    // { nome: "Sentimento", ordenacao: "" },
    { nome: "Status", ordenacao: "aprovado" },
    { nome: "Ações", ordenacao: "" },
  ];

  const [abrirModal, setAbrirModal] = useState<boolean>(false);
  const [aprovar, setAprovar] = useState<boolean>(false);
  const [recarregarTarefas, setRecarregarTarefas] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterPublicacoesTarefa } = FuncoesTabelaPublicacao({
    idCampanha,
    idInfluenciadorSelecionado,
    setListaDePublicacoes,
    setTotalDePublicacoes,
    setCarregando,
    setErro,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    numeroPagina: numeroDePagina + 1,
    tamanhoPagina: tamanhoDePagina,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterPublicacoesTarefa();
  }, [
    idCampanha,
    idInfluenciadorSelecionado,
    numeroDePagina,
    tamanhoDePagina,
    recarregar,
    recarregarTarefas,
  ]);

  return (
    <>
      <CampoInformacoes titulo={"Publicações"} corDeFundo={"var(--branco)"}>
        <ModalAcrescentarPublicacaoNaTarefa
          importancia="secundario"
          idTarefa={idTarefa}
          idInfluenciadorSelecionado={idInfluenciadorSelecionado}
          setRecarregar={() => setRecarregar(!recarregar)}
        />

        <TabelaListarPublicacoesDeTarefa
          setPublicacaoSelecionada={setPublicacaoSelecionada}
          tituloColuna={colunasPublicacoes}
          listaDePublicacoes={listaDePublicacoes}
          carregando={carregando}
          tipoOrdenacao={"Ascending"}
          erro={erro}
          totalDePublicacoes={totalDePublicacoes}
          nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
          setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
          setMostrarModalEditarTarefa={setAbrirModal}
          setCarregando={setCarregando}
          setTipoOrdenacao={setTipoOrdenacao}
          setErro={setErro}
          setRecarregar={() => setRecarregarTarefas(!recarregarTarefas)}
          dataInicioCampanha={dataInicioCampanha}
          // paginacao={
          //   <Paginator
          //     first={paginatorFirst}
          //     rows={tamanhoDePagina}
          //     totalRecords={totalDePublicacoes}
          //     onPageChange={(e) => {
          //       setNumeroDePagina(e.page);
          //       setPaginatorFirst(e.first);
          //     }}
          //   />
          // }
          // seletorDeQuantidade={
          //   <SelectQtdadeItensLista
          //     onChange={(e: any) => setTamanhoDePagina(e.target.value)}
          //     totalLista={totalDePublicacoes}
          //     nomeLista={"publicações"}
          //   />
          // }
          tamanhoDePagina={totalDePublicacoes}
          setMostrarModalAprovarTarefa={setAprovar}
        />
      </CampoInformacoes>

      {aprovar && (
        <ModalEditarTarefa
          publicacaoSelecionada={publicacaoSelecionada}
          modalAberto={aprovar}
          setModalAberto={setAprovar}
          setRecarregar={setRecarregarTarefas}
          aprovar={aprovar}
        />
      )}
      {abrirModal && (
        <ModalEditarTarefa
          publicacaoSelecionada={publicacaoSelecionada}
          modalAberto={abrirModal}
          setModalAberto={setAbrirModal}
          setRecarregar={setRecarregarTarefas}
        />
      )}
    </>
  );
};
