import { useEffect, useState, useContext } from "react";

import { FuncoesAbaRedeSocial } from "./functions";

import { useParams } from "react-router-dom";

import { ErroType } from "../../../@types/erro";

import { CampoBigNumbers } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoBigNumbers";
import { CampoInformacoes } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { campanha } from "../../../services/campanha";
import { Carregando } from "../../Carregando";
import { GraficoResultadosGeraisPorRedeSocial } from "../../Graficos/GraficoResultadosGeraisPorRedeSocial";
import { redesSociaisProps } from "../../Graficos/GraficoResultadosGeraisPorRedeSocial/functions";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { TabelaComparativoRedesSociais } from "../../Tabelas/TabelaComparativoRedesSociais";
import { TabelaAnaliseDoFormado } from "../../Tabelas/TabelaAnaliseDoFormato";

interface AbaRedeSocialProps {
  tipoRedeSocial: redesSociaisProps;
  campanha: campanha;
  carregandoCampanha: boolean;
}

export const AbaRedeSocial = ({
  tipoRedeSocial,
  campanha,
  carregandoCampanha,
}: AbaRedeSocialProps) => {

  return carregandoCampanha ? (
    <Carregando />
  ) : (
    <>
      {campanha ? (
        <>
          {tipoRedeSocial === "Instagram" && campanha && (
            <CampoBigNumbers
              custoEngajamento={campanha.instagramTotalCPE}
              custoPorMilImpressoes={campanha.instagramTotalCPM}
              engajamentoTotal={campanha.instagramTotalEngajamento}
              impressoes={campanha.instagramTotalImpressoes}
              tooltipTxEngajamento={"Percentual do total de Engajamento em relação ao total de Alcance"}
              tooltipImpressoes={"Total de Impressões do formato. Para Reels é considerado a métrica Reprodução."}
              taxaEngajamento={campanha.instagramTotalTaxaEngajamento}
              redeSocial
            />
          )}
          {tipoRedeSocial === "TikTok" && (
            <CampoBigNumbers
              custoEngajamento={campanha?.tiktokCPE}
              custoPorMilImpressoes={campanha?.tiktokCPM}
              engajamentoTotal={campanha?.tiktokEngajamento}
              impressoes={campanha?.tiktokImpressoes}
              tooltipTxEngajamento={"Percentual do total de Engajamento em relação ao total de Espectadores"}
              tooltipImpressoes={"Total de Views do formato."}
              taxaEngajamento={campanha?.tiktokTaxaEngajamento}
              redeSocial
            />
          )}
          {tipoRedeSocial === "Twitter" && (
            <CampoBigNumbers
              custoEngajamento={campanha?.twitterEngajamento}
              custoPorMilImpressoes={campanha?.twitterCPM}
              engajamentoTotal={campanha?.twitterEngajamento}
              impressoes={campanha?.twitterImpressoes}
              tooltipTxEngajamento={"Percentual do total de Engajamento em relação ao total de Impressões"}
              tooltipImpressoes={"Total de Impressões do formato."}
              taxaEngajamento={campanha?.twitterTaxaEngajamento}
              redeSocial
            />
          )}
          {tipoRedeSocial === "YouTube" && (
            <CampoBigNumbers
              custoEngajamento={campanha?.youtubeTotalCPE}
              custoPorMilImpressoes={campanha?.youtubeTotalCPM}
              engajamentoTotal={campanha?.youtubeTotalEngajamento}
              impressoes={campanha?.youtubeTotalImpressoes}
              tooltipTxEngajamento={"Percentual do total de Engajamento em relação ao total de Espectadores"}
              tooltipImpressoes={"Total de Impressões do formato."}
              taxaEngajamento={campanha?.youtubeTotalTaxaEngajamento}
              redeSocial
            />
          )}

          <CampoInformacoes titulo="Resultados Gerais">
            <GraficoResultadosGeraisPorRedeSocial
              campanha={campanha}
              tipoRedeSocial={tipoRedeSocial}
            />
          </CampoInformacoes>
          <CampoInformacoes titulo="Análise do Formato">
            <TabelaAnaliseDoFormado tarefas={campanha.tarefas} redeSocial={tipoRedeSocial} />
          </CampoInformacoes>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
