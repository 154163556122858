import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { getRenovarToken } from "../../services/usuario";
import { obterTokens, salvarTokens } from "../../utils/LocalStorage";

import { DesconectarContext } from "../DesconectarContext";

interface Props {
  children: ReactNode;
}

interface listaEsperaRenovacaoType {
  callback: (token: string) => Promise<void>;
}

interface RenovarTokenContextProps {
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const RenovarTokenContext = createContext<RenovarTokenContextProps>({
  adicionarAListaEsperaRenovacaoToken: () => {},
});

export const RenovarTokenProvider = ({ children }: Props) => {
  const [listaEsperaRenovacao, setListaEsperaRenovacao] = useState<
    listaEsperaRenovacaoType[]
  >([]);
  const [listaStatusRequisicao, setListaStatusRequisicao] = useState<
    { status: "pendente" | "concluido" }[]
  >([]);
  const [listaCarregando, setListaCarregando] = useState<
    {
      status: "pendente" | "concluido";
      setCarregando:
        | Function
        | React.Dispatch<React.SetStateAction<boolean>>
        | React.Dispatch<boolean>;
    }[]
  >([]);

  const { setDesconectar } = useContext(DesconectarContext);
  const [carregandoRenovacao, setCarregandoRenovacao] =
    useState<boolean>(false);

  function adicionarAListaEsperaRenovacaoToken(
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) {
    setListaEsperaRenovacao((state) => {
      return [...state, { callback }];
    });

    setListaStatusRequisicao((state) => [...state, { status: "pendente" }]);
    setCarregando &&
      setListaCarregando((state) => [
        ...state,
        { status: "pendente", setCarregando },
      ]);
  }

  async function renovarToken() {
    if (!carregandoRenovacao) {
      setCarregandoRenovacao(true);

      const { token, tokenRenovacao } = obterTokens();
      await getRenovarToken(token, tokenRenovacao)
        .then(async (res) => {
          salvarTokens(res.data.tokenAcesso, res.data.tokenRenovacao);

          for await (let [
            index,
            requisicao,
          ] of listaEsperaRenovacao.entries()) {
            if (listaStatusRequisicao[index].status === "pendente") {
              try {
                await requisicao.callback(res.data.tokenAcesso);

                setListaStatusRequisicao((state) => {
                  const listaTemporaria = state;
                  listaTemporaria[index] = { status: "concluido" };
                  return listaTemporaria;
                });
              } catch (err) {
                break;
              }
            }
          }
        })
        .catch(() => {
          setListaEsperaRenovacao([]);
          setListaStatusRequisicao([]);
          setDesconectar(true);
        })
        .finally(() => {
          listaCarregando.forEach((carregando, index) => {
            if (carregando.status === "pendente") {
              setListaCarregando((state) => {
                const listaTemporaria = state;
                listaTemporaria[index].setCarregando(false);
                listaTemporaria[index].status = "concluido";

                return listaTemporaria;
              });
            }
          });

          setCarregandoRenovacao(false);
        });
    }
  }

  useEffect(() => {
    if (listaEsperaRenovacao.length) {
      renovarToken();
    }
  }, [listaEsperaRenovacao.length]);

  return (
    <RenovarTokenContext.Provider
      value={{ adicionarAListaEsperaRenovacaoToken }}
    >
      {children}
    </RenovarTokenContext.Provider>
  );
};
