import { createContext, ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
  myToast: React.MutableRefObject<any>;
}

interface MostrarToastContextProps {
  mostrarToast: (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => void;
}

export const MostrarToastContext = createContext<MostrarToastContextProps>({
  mostrarToast: () => {},
});

export const MostrarToastProvider = ({ children, myToast }: Props) => {
  const mostrarToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return (
    <MostrarToastContext.Provider value={{ mostrarToast }}>
      {children}
    </MostrarToastContext.Provider>
  );
};
