import { useRef, useState, useContext } from "react";

import styles from "./styles.module.css";
import { FunctionsModalEnviarRelatorio } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Input } from "../../Input";
import { ModalTeste } from "../ModalTeste";

import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { destinatariosExtrasProps } from "../../../services/campanha";
import { BotaoColunaAcoes } from "../../Botoes/BotaoColunaAcoes";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ModalEnviarRelatorioProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  gerarRelatorio: (baixarRelatorio: boolean) => Promise<string>;
}

export const ModalEnviarRelatorio = ({
  modalAberto,
  setModalAberto,
  gerarRelatorio,
}: ModalEnviarRelatorioProps) => {
  const [carregando, setCarregando] = useState<boolean>(false);

  const [enviarParaUsuariosAgencia, setEnviarParaUsuariosAgencia] =
    useState<boolean>(false);
  const [enviarParaUsuariosMarca, setEnviarParaUsuariosMarca] =
    useState<boolean>(false);
  const [destinatariosExtras, setDestinatariosExtras] = useState<
    string[]
  >([]);
  const [email, setEmail] = useState<string>("");

  const myToast = useRef<any>(null);
  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { enviaRelatorio, adicionaEmailALista, retiraEmailDaLista } =
    FunctionsModalEnviarRelatorio({
      destinatariosExtras,
      enviarParaUsuariosAgencia,
      enviarParaUsuariosMarca,
      gerarRelatorio,
      idCampanha: idCampanha!,
      myToast,
      setCarregando,
      setModalAberto,
      adicionarAListaEsperaRenovacaoToken,
      setDestinatariosExtras,
      email,
      setEmail,
    });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Enviar relatório"}
    >
      <div
        className={styles.conteudoModalCadastroUsuarioAgencia}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <div className={styles.containerDados}>
            <div className={styles.subtitulo}>Destinatários</div>
            <div className={styles.containerInputsCheck}>
              <label
                className={styles.labelCheckBox}
                htmlFor="enviar-usuario-marca"
              >
                <input
                  id="enviar-usuario-marca"
                  type="checkbox"
                  onClick={() => {
                    setEnviarParaUsuariosMarca(!enviarParaUsuariosMarca);
                  }}
                />
                Usuários Marca
              </label>
              <label
                className={styles.labelCheckBox}
                htmlFor="enviar-usuario-agencia"
              >
                <input
                  id="enviar-usuario-agencia"
                  type="checkbox"
                  onClick={() => {
                    setEnviarParaUsuariosAgencia(!enviarParaUsuariosAgencia);
                  }}
                />
                Usuários Agência
              </label>
            </div>
            <div className={styles.subtitulo}>Outros destinatários</div>
            <div className={styles.containerInputEBotao}>
              <div className={styles.containerInputEmail}>
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <BotaoColunaAcoes
                icone="pi-check"
                onClick={() => adicionaEmailALista()}
                titulo="Adicionar email a lista"
              />
            </div>
            <div className={styles.containerEmailsAdicionais}>
              {destinatariosExtras.map((destinatario, index) => {
                return (
                  <div key={index} className={styles.containerEmail}>
                    <p>{destinatario}</p>
                    <BotaoColunaAcoes
                      icone="pi-times"
                      onClick={() => retiraEmailDaLista(destinatario)}
                      titulo="Adicionar email a lista"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </body>

        <footer>
          {carregando ? (
            <Carregando />
          ) : (
            <div className={styles.containerBotoesModal}>
              <Button
                importancia="terciario"
                type="button"
                title="Cancelar"
                onClick={() => setModalAberto(false)}
              />
              <Button
                importancia="primario"
                title="Confirmar"
                onClick={enviaRelatorio}
              />
            </div>
          )}
        </footer>

        <Toast ref={myToast} className="custom-toast" />
      </div>
    </ModalTeste>
  );
};
