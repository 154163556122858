import axios from "axios";
import React, { useContext} from "react";
import { MostrarToastContext } from "../../../context/MostrarToast";


export interface Post {
  id: string;
  shortcode: string;
}
export interface Story {
  permalink: string;
  timestamp: string;
  id: string;
}

interface pagesProps {
  instagram_business_account: InstagramBusinessAccount;
  id: string;
}
interface InstagramBusinessAccount {
  id: string;
  name: string;
  username: string;
}

export interface FunctionsOptInProps {
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  engajamentos: number;
  setEngajamentos: React.Dispatch<React.SetStateAction<number>>;
  visitasAoPerfil: number;
  setVisitasAoPerfil: React.Dispatch<React.SetStateAction<number>>;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  setPlays: React.Dispatch<React.SetStateAction<number>>;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  setDataInclusaoStories: React.Dispatch<React.SetStateAction<string>>;
  setStatesOptinPreenchidas: React.Dispatch<React.SetStateAction<boolean>>;
  setQuantidadeReproducoes: React.Dispatch<React.SetStateAction<number>>;

}

interface pegaPaginasDoUsuarioProps {
  urlPublicacao: string,
  userIdFacebook: string,
  tokenDeAcesso: string,
}
interface RespostaPegaMediasProps {
  data?: Post[] | null;
  paging: Paging;
}
interface Cursors {
  before: string;
  after: string;
}
interface Paging {
  cursors: Cursors;
}



export const FunctionsOptIn = ({
  impressoes,
  setImpressoes,
  alcance,
  setAlcance,
  compartilhamentos,
  setCompartilhamentos,
  engajamentos,
  setEngajamentos,
  visitasAoPerfil,
  setVisitasAoPerfil,
  setComentarios,
  setLikes,
  setPlays,
  setSalvos,
  setViews,
  setRespostas,
  setDataInclusaoStories,
  setStatesOptinPreenchidas,
  setQuantidadeReproducoes
 
}: FunctionsOptInProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  let mostrarMensagemDeErro363: string = "não mudou";
  let postUrl: any;

  const erroURLInvalida = () => {
    mostrarToast("error", "URL inválido", "Verifique as informações");
  };

  const erroMetricas = () => {
    mostrarToast(
      "error",
      "Não foi possível obter as métricas",
      "Tente novamente mais tarde"
    );
  };

  const erroIdBusinessNaoEncontrado = () => {
    mostrarToast(
      "error",
      "Não foi possível localizar sua conta",
      "Verifique se você sua conta de instagram está vinculada a página do facebook."
    );
  };

  const erroMediasVazias = () => {
    mostrarToast(
      "error",
      "Não foi possível localizar sua publicação",
      "Sua publicação foi feita após você vincular sua conta do instagram a página no facebook?"
    );
  };

  const erroStoriesVazio = () => {
    mostrarToast(
      "error",
      "A validade do story expirou",
      "Não é possível ober um story fora do ar"
    );
  };

  const erroTipoDePublicacaoInvalido = () => {
    mostrarToast(
      "error",
      "Tipo de publicação inválido",
      "Verifique o tipo de publicação."
    );
  };


  // const tokenDeAcesso = 'EAAKiDY80NhQBO4yRsgxOZCqVj4Rv3CYGQSdpN0d86VfCJSEutHubZBS5s34qnaIwZA4LObmLIoiP4iOLIbXRAisvkKoXe0ih0XOE2YOChleZAuLNSZBZCxcgABCdJYmjtufxf2U4h2SfJ0LvJKdSQ8tlLdtmemZC0elE7n0rsza99A25QEAdJ6IRwZDZD'
  // pega idBusiness
  const pegarMetricas = async (
    tokenDeAcesso: string,
    tipoPublicacao: string,
    urlPublicacao: string,
    userIdFacebook: string
  ) => {

    let chamarMetodo: boolean = false;

    let pages: pagesProps[] = await pegaPaginasDoUsuario({
      tokenDeAcesso,
      urlPublicacao,
      userIdFacebook
    })


    let medias: Post[] & Story[] = []

    if (pages.length > 0) {

      switch (tipoPublicacao) {
        case "PublicacaoInstagramStories":
          let arrayDeArrayDeMediaStories: Story[][] = await Promise.all(
            pages.map(async (pagina) => {
              let mediasProvisoriasStories: Story[] = await pegarStories(
                pagina.instagram_business_account.id,
                tokenDeAcesso
              );
              return mediasProvisoriasStories
            }))
          arrayDeArrayDeMediaStories.map(arrayDeMedia => {
            medias.push(...arrayDeMedia)
          })
          break;

        case "PublicacaoInstagramFeed":
          let arrayDeArrayDeMediaFeed: Post[][] = await Promise.all(
            pages.map(async (pagina) => {
              let mediasProvisoriasFeed: Post[] = await pegarMediaId(
                pagina.instagram_business_account.id,
                tokenDeAcesso
              );
              return mediasProvisoriasFeed
            }))
          arrayDeArrayDeMediaFeed.map(arrayDeMedia => {
            medias.push(...arrayDeMedia)
          })
          break;

        case "PublicacaoInstagramReels":
          let arrayDeArrayDeMediaReels: Post[][] = await Promise.all(
            pages.map(async (pagina) => {
              let mediasProvisoriasReels: Post[] = await pegarMediaIdReels(
                pagina.instagram_business_account.id,
                tokenDeAcesso
              );
              return mediasProvisoriasReels
            }))
          arrayDeArrayDeMediaReels.map(arrayDeMedia => {
            medias.push(...arrayDeMedia)
          })
          break;

        case "PublicacaoInstagramAlbum":
          let arrayDeArrayDeMediaAlbum: Post[][] = await Promise.all(
            pages.map(async (pagina) => {
              let mediasProvisoriasAlbum: Post[] = await pegarMediaIdAlbum(
                pagina.instagram_business_account.id,
                tokenDeAcesso
              );
              return mediasProvisoriasAlbum
            }))
          arrayDeArrayDeMediaAlbum.map(arrayDeMedia => {
            medias.push(...arrayDeMedia)
          })
          break;
        default:
          erroTipoDePublicacaoInvalido();
          break;
      }

    } else {
      erroIdBusinessNaoEncontrado();
    }

    if (medias.length > 0) {
      switch (tipoPublicacao) {
        case "PublicacaoInstagramFeed":
          CompararShortcode(tokenDeAcesso, medias);
          break;
        case "PublicacaoInstagramReels":
          CompararShortcodeReels(tokenDeAcesso, medias);
          break;
        case "PublicacaoInstagramAlbum":
          CompararShortcodeAlbum(tokenDeAcesso, medias);
          break;

        case "PublicacaoInstagramStories":
          CompararPermalinkStories(tokenDeAcesso, medias);
          break;

      }


    } else {
      erroMediasVazias()
    };

  }

  async function pegaPaginasDoUsuario({
    urlPublicacao,
    tokenDeAcesso,
    userIdFacebook
  }: pegaPaginasDoUsuarioProps
  ) {

    const baseURLIdInstagramBusiness = `https://graph.facebook.com/v18.0/${userIdFacebook}/accounts?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account&access_token=${tokenDeAcesso}`;

    return axios.get(baseURLIdInstagramBusiness)
      .then((response1) => {
        postUrl = urlPublicacao;
        return response1.data.data
      })
      .catch((erro) => {
        erroURLInvalida();
        return []
      })
  }

  const pegarMediaId = async (
    idInstagramBusiness: string,
    tokenDeAcesso: string
  ) => {
    const baseURLMediaId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenDeAcesso}`;

    return axios
      .get(baseURLMediaId)
      .then((response2) => {
        console.log(idInstagramBusiness);
        return response2.data.data
        
      })
      .catch((erro) => {
        erroURLInvalida();
      });
  };
  //pegar mediaId do album
  const pegarMediaIdAlbum = async (
    idInstagramBusiness: string,
    tokenDeAcesso: string
  ) => {
    const baseURLMediaId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenDeAcesso}`;

    return axios
      .get(baseURLMediaId)
      .then((response2) => {
        return response2.data.data
      })
      .catch((erro) => {
        erroURLInvalida();
      });
  };

  //pegar mediaIdReels
  const pegarMediaIdReels = async (
    idInstagramBusiness: string,
    tokenDeAcesso: string
  ) => {
    const baseURLMediaId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenDeAcesso}`;

    return axios
      .get(baseURLMediaId)
      .then((response2) => {
        return response2.data.data
      })
      .catch((erro) => {
        erroURLInvalida();
      });
  };

  async function CompararShortcode(
    tokenDeAcesso: string,
    postArray: Post[]
  ) {
    // let chamarMetodo: boolean = false;
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true

    postArray.map((post) => {
      if (postUrl.includes(post.shortcode)) {
        postShortcode = post;
        mostrarErro = false
        pegarMetricasFeed(postShortcode, tokenDeAcesso);
      }
    });

    if (mostrarErro) {
      erroURLInvalida();
    }

    //  chamarMetodo && pegarMetricasFeed(postShortcode, tokenDeAcesso);
  }

  function CompararShortcodeReels(
    tokenDeAcesso: string,
    postArray: Post[]
  ) {
    // let chamarMetodo: boolean = false;
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true

    postArray.map((post) => {
      if (postUrl.includes(post.shortcode)) {
        mostrarErro = false
        postShortcode = post;
        pegarMetricasReels(postShortcode, tokenDeAcesso);
      }
    });

    if (mostrarErro) {
      erroURLInvalida();
    }
  }

  function CompararShortcodeAlbum(
    tokenDeAcesso: string,
    postArray: Post[]
  ) {
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true

    postArray.map((post) => {
      if (postUrl.includes(post.shortcode)) {
        mostrarErro = false
        postShortcode = post;
        pegarMetricasAlbum(postShortcode, tokenDeAcesso);
      }
    });

    if (mostrarErro) {
      erroURLInvalida();
    }
  }

  //pega insights de uma publicação
  const pegarMetricasFeed = (post: Post, tokenDeAcesso: string) => {
    const metricasFeed = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=impressions,shares,total_interactions,profile_visits,reach,saved&access_token=${tokenDeAcesso}`;

    const metricasFeed2 = `https://graph.facebook.com/v18.0/${post.id}?fields=like_count,comments_count&access_token=${tokenDeAcesso}`;

    axios
      .get(metricasFeed)
      .then((response4) => {
        
        setImpressoes(response4.data.data[0].values[0].value);
        setAlcance(response4.data.data[4].values[0].value);
        setSalvos(response4.data.data[5].values[0].value);
        setCompartilhamentos(response4.data.data[1].values[0].value);
        setEngajamentos(response4.data.data[4].values[0].value);
        setVisitasAoPerfil(response4.data.data[3].values[0].value);
        setStatesOptinPreenchidas(true);
      })
      .catch((erro) => {
        if (
          erro.response.data.error.message ===
          "(#100) Incompatible metric (shares) with the media"
        ) {
          pegarMetricasAlbum(post, tokenDeAcesso);
          setStatesOptinPreenchidas(true);
        }

        if (
          erro.response.data.error.message ===
          "Unsupported get request. Object with ID 'insights' does not exist, cannot be loaded due to missing permissions, or does not support this operation. Please read the Graph API documentation at https://developers.facebook.com/docs/graph-api"
        ) {

        }
      })

    axios
      .get(metricasFeed2)
      .then((res) => {
        setLikes(res.data.like_count);
        setComentarios(res.data.comments_count);
        // setStatesOptinPreenchidas(true);
      })
      .catch((erro) => {
        if (
          erro.response.data.error.message ===
          "(#100) Incompatible metric (shares) with the media"
        ) {
          pegarMetricasAlbum(post, tokenDeAcesso);
        }

        if (
          erro.response.data.error.message ===
          "Unsupported get request. Object with ID 'insights' does not exist, cannot be loaded due to missing permissions, or does not support this operation. Please read the Graph API documentation at https://developers.facebook.com/docs/graph-api"
        ) {

        }
      })

  };

  const pegarMetricasReels = (post: Post, tokenDeAcesso: string) => {
    const metricasFeed = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=comments,likes,plays,reach,saved,shares,total_interactions&access_token=${tokenDeAcesso}`;

    axios
      .get(metricasFeed)
      .then((response4) => {
        setComentarios(response4.data.data[0].values[0].value);
        setLikes(response4.data.data[1].values[0].value);
        setQuantidadeReproducoes(response4.data.data[2].values[0].value);
        setAlcance(response4.data.data[3].values[0].value);
        setSalvos(response4.data.data[4].values[0].value);
        setCompartilhamentos(response4.data.data[5].values[0].value);
        setEngajamentos(response4.data.data[6].values[0].value);
        setStatesOptinPreenchidas(true);
      })
      .catch((error) => {
        erroMetricas();
        setStatesOptinPreenchidas(false);
      });

  };

  //pega insights de uma publicação tipo album
  const pegarMetricasAlbum = (post: Post, tokenDeAcesso: string) => {
    const metricasFeedAlbum = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=carousel_album_impressions,carousel_album_engagement,carousel_album_reach,carousel_album_saved,carousel_album_video_views&access_token=${tokenDeAcesso}`;

    const metricasFeed2 = `https://graph.facebook.com/v18.0/${post.id}?fields=like_count,comments_count&access_token=${tokenDeAcesso}`;

    axios
      .get(metricasFeedAlbum)
      .then((response4) => {
        setImpressoes(response4.data.data[0].values[0].value);
        setEngajamentos(response4.data.data[1].values[0].value);
        setAlcance(response4.data.data[2].values[0].value);
        setSalvos(response4.data.data[3].values[0].value);
        setViews(response4.data.data[4].values[0].value);
        setStatesOptinPreenchidas(true);
      })
      .catch((erro) => {
        erroMetricas();
        setStatesOptinPreenchidas(false);
      });

    axios
      .get(metricasFeed2)
      .then((res) => {
        setLikes(res.data.like_count);
        setComentarios(res.data.comments_count);
        setStatesOptinPreenchidas(true);
      })
      .catch((error) => {
        erroMetricas();
        setStatesOptinPreenchidas(false);
      });


  };

  //pega id dos stories
  const pegarStories = async (
    idInstagramBusiness: string,
    tokenDeAcesso: string
  ) => {
    const baseURLStories = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/stories?fields=permalink,timestamp&access_token=${tokenDeAcesso}`;
    return axios
      .get(baseURLStories)
      .then((response3) => {
        return  response3.data.data ; 
      })
      .catch((erro) => {
        erroURLInvalida();
        setStatesOptinPreenchidas(false);
      });
  };

  function CompararPermalinkStories(
    tokenDeAcesso: string,
    storiesArray: Story[]
  ) {
    // let chamarMetodo: boolean = false;
    let storyLink: Story = { permalink: "", timestamp: "", id: ""};
    let mostrarErro = true

    storiesArray.map((story) => {
      let permalinkCode = story.permalink.split("/")[5];

      if (postUrl.includes(permalinkCode)) {
        storyLink = story;
        mostrarErro = false
        setDataInclusaoStories(story.timestamp);
        pegarMetricasStories(storyLink.id, tokenDeAcesso);
      }
    });

    if (mostrarErro) {
      erroURLInvalida();
    }

  }

  //pega insights dos stories
  const pegarMetricasStories = (idStories: string, tokenDeAcesso: string) => {
    const metricasStories = `https://graph.facebook.com/v18.0/${idStories}/insights?metric=reach,impressions,replies,shares,profile_visits&access_token=${tokenDeAcesso}`;
    axios
      .get(metricasStories)
      .then((response5) => {
        setImpressoes(response5.data.data[1].values[0].value);
        setRespostas(response5.data.data[2].values[0].value);
        setAlcance(response5.data.data[0].values[0].value);
        setCompartilhamentos(response5.data.data[3].values[0].value);
        setVisitasAoPerfil(response5.data.data[4].values[0].value);
        setStatesOptinPreenchidas(true);
      })
      .catch((erro) => {
        erroMetricas();
        setStatesOptinPreenchidas(false);
      });


  };


  return {
    pegarMetricas,
  };
};