import React, { useContext, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FunctionsModalCadastroUsuarioMarca } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ModalTeste } from "../ModalTeste";
import { Input } from "../../Input";
import { SelectMarca } from "../../Select/SelectMarca";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";
import { MinLengthValidation, SelectFieldValidation } from "../../../validation/validators";


interface ModalCadastroUsuarioMarca {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: () => void;
}

export const ModalCadastroUsuarioMarca = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
}: ModalCadastroUsuarioMarca) => {
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [telefone, setTelefone] = useState<number>(0);
  const [idMarca, setIdMarca] = useState<string>("");
  const [enviarNotificacaoDeCadastro, setEnviarNotificacaoDeCadastro] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<Record<string, string | null>>({});
  const nomeMinLengthValidator = new MinLengthValidation('nome', 3);
  const emailMinLengthValidator = new MinLengthValidation('email', 5);
  const selectMarcaValidator = new SelectFieldValidation('idMarca');
  const myToast = useRef<any>(null);
  const { adicionarAListaEsperaRenovacaoToken } = useContext(RenovarTokenContext);

  const handleFormSubmit = (nome: string, email: string, idMarca: string) => {
    const nomeError = nomeMinLengthValidator.validate({ nome: nome });
    const emailError = emailMinLengthValidator.validate({ email: email });
    const selectMarcaError = selectMarcaValidator.validate({ idMarca: idMarca });

    setValidationErrors({
      nome: nomeError ? 'O campo nome é obrigatório' : null,
      email: emailError ? 'O campo email é obrigatório' : null,
      idMarca: selectMarcaError ? 'Por favor, selecione uma marca' : null,
    });

    if (!nomeError && !emailError && !selectMarcaError) {
      salvarUsuarioMarca();
    }
  };
  
  const { salvarUsuarioMarca } =
    FunctionsModalCadastroUsuarioMarca({
      nome,
      setNome,
      email,
      setEmail,
      imagemAvatar,
      setImagemAvatar,
      telefone,
      setTelefone,
      idMarca,
      setIdMarca,
      enviarNotificacaoDeCadastro,
      setEnviarNotificacaoDeCadastro,
      myToast,
      MascaraNumeroWhatsapp,
      setMascaraNumeroWhatsapp,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      setRecarregar,
      setModalAberto,
    });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Cadastro Usuário Marca"}
    >
      <div
        className={styles.conteudoModalCadastroUsuarioMarca}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <BotaoUploadImagem setImg={setImagemAvatar} />,
          <div className={styles.containerDados}>
            <div className={styles.containerInputNomeEmail}>
              <Input
                required
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              {validationErrors.nome && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.nome}

                </small>)}

              <Input
                type="text"
                required
                placeholder="E-mail"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {validationErrors.email && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.email}

                </small>)}
            </div>
            <div className={styles.containerInputs}>
              <PhoneInput
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
              />
              <SelectMarca
                setIdMarca={setIdMarca}
                idMarca={idMarca}
                myToast={myToast}
              />
              {validationErrors.idMarca && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.idMarca}
                </small>
              )}
            </div>

            <div className={styles.containerInputCheckbox}>
              <input
                id="enviar-email"
                type="checkbox"
                onClick={() => {
                  setEnviarNotificacaoDeCadastro(!enviarNotificacaoDeCadastro);
                }}
              />
              <label className={styles.labelCheckBox} htmlFor="enviar-email">
                Enviar email de cadastro para o usuário?
              </label>
            </div>
          </div>
        </body>

        <footer>
          {carregando ? (
            <Carregando />
          ) : (
            <div className={styles.containerBotoesModal}>
              <Button
                onClick={() => setModalAberto(false)}
                type="button"
                importancia="terciario"
                title="Cancelar"
              />
              <Button
                importancia="primario"
                title="Cadastrar"
                onClick={() => { return handleFormSubmit(nome, email, idMarca) }}
              />
            </div>
          )}
        </footer>

        <Toast ref={myToast} className="custom-toast" />
      </div>
    </ModalTeste>
  );
};
