import { ErroType } from "../../../@types/erro";
import {
  campanha,
  getCampanhaId,
  putCampanhaFechar,
} from "../../../services/campanha";
import { limpaLocalStorage, obterTokens } from "../../../utils/LocalStorage";

export interface funcoesCampanhaDetalhesProps {
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setCarregandoFechar?: React.Dispatch<React.SetStateAction<boolean>>;
  setCampanha: React.Dispatch<React.SetStateAction<campanha | undefined>>;
  idCampanha: string;
  myToast?: React.MutableRefObject<any>;
  recarregar?: boolean;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
  setInstagramPresente?: React.Dispatch<React.SetStateAction<boolean>>;
  setTiktokPresente?: React.Dispatch<React.SetStateAction<boolean>>;
  setTwitterPresente?: React.Dispatch<React.SetStateAction<boolean>>;
  setYoutubePresente?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalConfirmacaoFinalizacaoCampanhaAberto?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const funcoesCampanhaDetalhes = ({
  myToast,
  recarregar,
  idCampanha,
  setErro,
  setCarregando,
  setCampanha,
  adicionarAListaEsperaRenovacaoToken,
  setCarregandoFechar,
  setRecarregar,
  setInstagramPresente,
  setTiktokPresente,
  setTwitterPresente,
  setYoutubePresente,
  setModalConfirmacaoFinalizacaoCampanhaAberto,
}: funcoesCampanhaDetalhesProps) => {
  async function obterCampanha() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token } = obterTokens();

    let renovandoToken = false;

    await getCampanhaId(token, idCampanha)
      .then((res) => {
        setCampanha(res.data.campanha);
        verificaPresençaRedeSocial(res.data.campanha);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          renovandoToken = true;
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            setCarregando(true);
            await getCampanhaId(token, idCampanha)
              .then((res) => {
                setCampanha(res.data.campanha);
                verificaPresençaRedeSocial(res.data.campanha);
                setCarregando(false);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Ocorreu um erro a carregar sua campanha.",
                });
                setCarregando(false);
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Ocorreu um erro a carregar sua campanha.",
          });
          setCarregando(false);
        }
      });
  }

  function verificaPresençaRedeSocial(campanha: campanha) {
    campanha.tarefas.map((tarefa) => {
      tarefa.publicacoes.map((publicacao) => {
        if (publicacao.status === "Aprovado") {
          if (publicacao.tipo === "PublicacaoYouTube") {
            setYoutubePresente && setYoutubePresente(true);
          }
          if (publicacao.tipo === "PublicacaoYouTubeShorts") {
            setYoutubePresente && setYoutubePresente(true);
          }
          if (publicacao.tipo === "PublicacaoTwitter") {
            setTwitterPresente && setTwitterPresente(true);
          }
          if (publicacao.tipo === "PublicacaoTikTok") {
            setTiktokPresente && setTiktokPresente(true);
          }
          if (publicacao.tipo === "PublicacaoInstagramFeed") {
            setInstagramPresente && setInstagramPresente(true);
          }
          if (publicacao.tipo === "PublicacaoInstagramReels") {
            setInstagramPresente && setInstagramPresente(true);
          }
          if (publicacao.tipo === "PublicacaoInstagramStories") {
            setInstagramPresente && setInstagramPresente(true);
          }
        }
      });
    });
  }

  function fecharCampanha() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();

    putCampanhaFechar(token, idCampanha)
      .then(() => {
        showToast(
          "success",
          "Campanha Finalizada!",
          "Geração de relatórios disponível"
        );
        setModalConfirmacaoFinalizacaoCampanhaAberto!(false);
        setCarregandoFechar && setCarregandoFechar(false);
        setRecarregar && setRecarregar(!recarregar);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putCampanhaFechar(token, idCampanha)
                .then((res) => {
                  showToast(
                    "success",
                    "Campanha Finalizada!",
                    "Geração de relatórios disponível."
                  );
                })
                .catch(() => {
                  showToast(
                    "error",
                    "Campanha não finalizada!",
                    "Ocorreu um erro ao finalizar a campanha."
                  );
                  setModalConfirmacaoFinalizacaoCampanhaAberto!(false);
                });
            },
            () => {
              setCarregandoFechar && setCarregandoFechar(false);
              setRecarregar && setRecarregar(!recarregar);
            }
          );
        } else {
          showToast(
            "error",
            "Campanha não finalizada!",
            "Ocorreu um erro ao finalizar a campanha."
          );
          setCarregandoFechar && setCarregandoFechar(false);
          setRecarregar && setRecarregar(!recarregar);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast &&
      myToast.current.show({
        severity: severityValue,
        summary: summaryValue,
        detail: detailValue,
      });
  };
  return {
    obterCampanha,
    fecharCampanha,
  };
};
