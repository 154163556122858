import { AxiosResponse } from "axios";
import {
  getTarefasPorCampanha,
  tarefaProps,
  tarefas,
} from "../../../../services/tarefas";
import { obterTokens } from "../../../../utils/LocalStorage";
import { getInfluenciadorPorId } from "../../../../services/influenciador";
import React from "react";

interface FunctionsPorInfluenciadoresProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  setTarefa: React.Dispatch<tarefaProps>;
  setUrlAvatar: React.Dispatch<React.SetStateAction<string>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsPorInfluenciadores = ({
  idCampanha,
  idInfluenciadorSelecionado,
  setTarefa,
  setUrlAvatar,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsPorInfluenciadoresProps) => {
  async function obterTarefa() {
    const { token, tokenRenovacao } = obterTokens();

    function sucessoRequisicao(res: AxiosResponse<tarefas, any>) {
      setTarefa(
        res.data.tarefas.filter(
          (tarefa) =>
            tarefa.influenciador.idCredenciais === idInfluenciadorSelecionado
        )[0]
      );
    }

    await getTarefasPorCampanha(token, idCampanha, 1, 999, "Ascending", "nome")
      .then((res) => {
        sucessoRequisicao(res);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTarefasPorCampanha(
              token,
              idCampanha,
              1,
              999,
              "Ascending",
              "nome"
            ).then((res) => {
              sucessoRequisicao(res);
            });
          });
        }
      });
  }

  function obterInfluenciadorPorId() {
    const { token, tokenRenovacao } = obterTokens();
    getInfluenciadorPorId(token, idInfluenciadorSelecionado)
      .then((res) => {
        setUrlAvatar(res.data.influenciador.urlAvatar);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getInfluenciadorPorId(token, idInfluenciadorSelecionado).then(
              (res) => {
                setUrlAvatar(res.data.influenciador.urlAvatar);
              }
            );
          });
        }
      });
  }

  return {
    obterTarefa,
    obterInfluenciadorPorId,
  };
};
