import React from "react";

import { ErroType } from "../../../@types/erro";
import {
  campanha,
  getCampanhaporStatus,
  getCampanhaTodasMarca,
  getCampanhaTodasPorAgenciaPorMarca,
  getCampanhaTodasPorMarcasPorPeriodo,
  getCampanhaTodasPorMarcasPorNome,
  respostaGetCampanha,
} from "../../../services/campanha";
import { obterTokens } from "../../../utils/LocalStorage";
import { AxiosResponse } from "axios";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  getUsuarioMarcaId,
  getUsuarioMarcaProps,
} from "../../../services/usuarioMarca";

export interface FunctionsListarCampanhasMarcaProps {
  idCredenciais: string;
  setUsuarioMarca: React.Dispatch<
    React.SetStateAction<getUsuarioMarcaProps | undefined>
  >;
  idMarca: string;
  setIdMarca: React.Dispatch<React.SetStateAction<string>>;
  idAgenciaFiltro: string;
  numeroPagina: number;
  tamanhoPagina: number;
  dataInicial: string;
  dataFinal: string;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  nomeCampanhaMarcaPesquisa: string;
  statusCampanha: number | undefined;
  setTamanhoPagina: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  setListaDeCampanhasMarcas: React.Dispatch<campanha[]>;
  setTotalCampanhasMarcas: React.Dispatch<number>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsListarCampanhasMarcas = ({
  idCredenciais,
  idMarca,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  tamanhoPagina,
  numeroPagina,
  nomeCampanhaMarcaPesquisa,
  idAgenciaFiltro,
  dataInicial,
  dataFinal,
  statusCampanha,
  setListaDeCampanhasMarcas,
  setErro,
  setTotalCampanhasMarcas,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setIdMarca,
  setCarregandoPesquisa,
}: FunctionsListarCampanhasMarcaProps) => {
  const sucessoRequisicao = (res: AxiosResponse<respostaGetCampanha, any>) => {
    setListaDeCampanhasMarcas(res.data.campanhas);
    setTotalCampanhasMarcas(res.data.totalRegistros);
  };

  const erroRequisicao = () => {
    setErro({
      ativo: true,
      mensagem: "Erro ao carregar a lista de Campanhas.",
    });
  };

  const obterUsuarioMarcaPorId = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioMarcaId(token, idCredenciais)
      .then((res) => {
        obterCampanhasPorMarca(res.data.usuario.marca.id);
        setIdMarca(res.data.usuario.marca.id);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioMarcaId(token, idCredenciais)
              .then((res) => {
                obterCampanhasPorMarca(res.data.usuario.marca.id);
                setIdMarca(res.data.usuario.marca.id);
              })
              .catch(() => {
                erroRequisicao();
              });
          });
        } else {
          erroRequisicao();
        }
      });
  };

  const obterCampanhasPorMarca = (idMarca: string) => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasMarca(
      token,
      idMarca,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getCampanhaTodasMarca(
              token,
              idMarca,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhaMarcasPorNome = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorMarcasPorNome(
      token,
      nomeCampanhaMarcaPesquisa,
      idMarca,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasMarcas(res.data.campanhas);
        setTotalCampanhasMarcas(res.data.totalRegistros);
        setCarregandoPesquisa(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              getCampanhaTodasPorMarcasPorNome(
                token,
                nomeCampanhaMarcaPesquisa,
                idMarca,
                numeroPagina,
                tamanhoPagina,
                tipoOrdenacao,
                nomePropriedadeOrdenacao
              )
                .then((res) => {
                  setListaDeCampanhasMarcas(res.data.campanhas);
                  setTotalCampanhasMarcas(res.data.totalRegistros);
                })
                .catch(() => {
                  setErro({
                    ativo: true,
                    mensagem: "Erro ao carregar as Campanhas.",
                  });
                });
            },
            () => {
              setCarregandoPesquisa(false);
              setCarregando(false);
            }
          );
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
          setCarregandoPesquisa(false);
          setCarregando(false);
        }
      });
  };

  const obterCampanhaMarcasPorAgencia = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    setCarregandoPesquisa(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorAgenciaPorMarca(
      token,
      idMarca,
      idAgenciaFiltro,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasMarcas(res.data.campanhas);
        setTotalCampanhasMarcas(res.data.totalRegistros);
        setCarregandoPesquisa(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              getCampanhaTodasPorAgenciaPorMarca(
                token,
                idMarca,
                idAgenciaFiltro,
                numeroPagina,
                tamanhoPagina,
                tipoOrdenacao,
                nomePropriedadeOrdenacao
              )
                .then((res) => {
                  setListaDeCampanhasMarcas(res.data.campanhas);
                  setTotalCampanhasMarcas(res.data.totalRegistros);
                })
                .catch(() => {
                  setErro({
                    ativo: true,
                    mensagem: "Erro ao carregar as Campanhas.",
                  });
                });
            },
            () => {
              setCarregandoPesquisa(false);
              setCarregando(false);
            }
          );
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
          setCarregandoPesquisa(false);
          setCarregando(false);
        }
      });
  };
  const obterCampanhaMarcasPorAgenciaPorPeriodo = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregandoPesquisa(true);
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorMarcasPorPeriodo(
      token,
      idMarca,
      dataInicial,
      dataFinal,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        setListaDeCampanhasMarcas(res.data.campanhas);
        setTotalCampanhasMarcas(res.data.totalRegistros);
        setCarregandoPesquisa(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await getCampanhaTodasPorMarcasPorPeriodo(
                token,
                idMarca,
                dataInicial,
                dataFinal,
                numeroPagina,
                tamanhoPagina,
                tipoOrdenacao,
                nomePropriedadeOrdenacao
              )
                .then((res) => {
                  setListaDeCampanhasMarcas(res.data.campanhas);
                  setTotalCampanhasMarcas(res.data.totalRegistros);
                })
                .catch(() => {
                  setErro({
                    ativo: true,
                    mensagem: "Erro ao carregar as Campanhas.",
                  });
                });
            },
            () => {
              setCarregandoPesquisa(false);
              setCarregando(false);
            }
          );
        } else {
          setErro({ ativo: true, mensagem: "Erro ao carregar as Campanhas." });
          setCarregandoPesquisa(false);
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorStatus = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaporStatus(
      token,
      statusCampanha!,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getCampanhaporStatus(
              token,
              statusCampanha!,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  return {
    obterCampanhaMarcasPorAgencia,
    obterUsuarioMarcaPorId,
    obterCampanhaMarcasPorNome,
    obterCampanhaMarcasPorAgenciaPorPeriodo,
    obterCampanhasPorStatus,
  };
};
