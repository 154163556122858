import { useRef, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsDeletarPrintSentimento } from "./functions";

import { Prints, PublicacoesProps } from "../../../services/publicacao";
import { DateFormatter } from "../../../utils/DataFormatter";
import { Button } from "../../Botoes/Button";

import { useParams } from "react-router-dom";
import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";
import { FaThumbsDown, FaThumbsUp, FaTrash } from "react-icons/fa";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

type sentimentos = "Positivo" | "Neutro" | "Negativo" | "Pendente";

interface CardComentarioProps {
  publicacao: PublicacoesProps;
  idTarefa: string;
  printSentimento: Prints[];
  setPrintsSentimento: React.Dispatch<React.SetStateAction<Prints[]>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CarrosselRepercussao = ({
  printSentimento,
  setPrintsSentimento,
  publicacao,
  idTarefa,
  setRecarregar,
}: CardComentarioProps) => {
  const [indicePrint, setIndicePrint] = useState<number>(0);
  const galleriaFullScreen = useRef<Galleria>(null);
  const [carregando, setCarregando] = useState<boolean>(false);
  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { deletarPrintRepercussao } = FunctionsDeletarPrintSentimento({
    setCarregando,
    setRecarregar,
    printSentimento,
    setPrintsSentimento,
    indicePrint,
    setIndicePrint,
    idCampanha: idCampanha!,
    idTarefa,
    adicionarAListaEsperaRenovacaoToken,
  });

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  document.onkeydown = (e) => {
    e.key == "ArrowLeft" &&
      setIndicePrint(indicePrint === 0 ? indicePrint : indicePrint - 1);
    e.key == "ArrowRight" &&
      setIndicePrint(
        indicePrint === printSentimento!.length - 1 ? 0 : indicePrint + 1
      );
    e.key == "Escape" && galleriaFullScreen.current?.hide();
  };

  const itemTemplate = (item: any) => {
    return (
      <div className={styles.containerCardImg}>
        <Image
          src={item.urlImagem}
          imageClassName={styles.imagemCard}
          downloadable
          height="100%"
          width="100%"
          onClick={() => galleriaFullScreen.current?.show()}
          onHide={() => galleriaFullScreen.current?.hide()}
        />
      </div>
    );
  };

  const itemTemplateGrande = (item: any) => {
    return (
      <Image src={item.urlImagem} imageClassName={styles.imagemCardGrande} />
    );
  };

  const headerTemplate = (
    item: any,
    publicacao: PublicacoesProps,
    indice: number
  ) => {
    return (
      <>
        <div className={styles.cardHeader}>
          <div className={styles.cardData} title="Data do Print">
            <p>Upload: </p>
            {DateFormatter(item[indice].dataUpload)}
          </div>
        </div>
      </>
    );
  };

  const footerTemplate = (item: any, indice: number) => {
    return (
      <>
        {/* <div className={styles.indicadorPrintAtual}><b>{indice + 1}</b>&nbsp;de {printSentimento.length}</div> */}
        <div
          className={`${styles.cardFooter}
          ${styles[item[indice].sentimento]}`}
        >
          {(() => {
            switch (item[indice].sentimento) {
              case "Positivo":
                return (
                  <>
                    <FaThumbsUp size={"2rem"} />
                    <p>Comentário Positivo</p>
                  </>
                );

              case "Negativo":
                return (
                  <>
                    <FaThumbsDown size={"2rem"} />
                    <p>Comentário Negativo</p>
                  </>
                );

              default:
                return (
                  <>
                    <p>Comentário Neutro</p>
                  </>
                );
            }
          })()}
        </div>
        <div className={styles.btnDeletePrint}>
          <Button
            importancia="terciario"
            icon={<FaTrash size={"2rem"} />}
            title="Deletar Print"
            onClick={deletarPrintRepercussao}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.cardComentario}>
      <Galleria
        ref={galleriaFullScreen}
        value={printSentimento}
        numVisible={9}
        style={{ maxWidth: "100%" }}
        circular
        fullScreen
        showItemNavigators={printSentimento!.length === 1 ? false : true}
        showThumbnails={false}
        showIndicators
        activeIndex={indicePrint}
        onItemChange={(e) => setIndicePrint(e.index)}
        item={itemTemplateGrande}
      />
      {printSentimento && printSentimento.length > 0 ? (
        <Galleria
          activeIndex={indicePrint}
          onItemChange={(e) => {
            return setIndicePrint(e.index), console.log(e.index);
          }}
          value={printSentimento}
          responsiveOptions={responsiveOptions}
          showThumbnails={false}
          showItemNavigators={printSentimento!.length === 1 ? false : true}
          // showIndicators
          item={itemTemplate}
          circular
          transitionInterval={2000}
          header={headerTemplate(printSentimento, publicacao, indicePrint)}
          footer={footerTemplate(printSentimento, indicePrint)}
        />
      ) : (
        <p>Nenhum print enviado</p>
      )}
    </div>
  );
};
