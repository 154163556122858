import { SelectFieldError } from "../errors";
import { FieldValidation } from "../protocols";

export class SelectFieldValidation implements FieldValidation {
    constructor(readonly field: string) {}
  
    validate(input: Record<string, unknown>): Error | null {
      const value = input[this.field];
      if (value === undefined || value === null || value === '' || value === 'default') {
        return new SelectFieldError(this.field);
      }
      return null;
    }
  }
  

  