import { SetStateAction, useState } from "react";

import styles from "./styles.module.css";

import { SelectMarca } from "../../Select/SelectMarca";
import { SelectAgencia } from "../../Select/SelectAgencia";
import { SelectStatusCampanha } from "../../Select/SelectStatusCampanha";

import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";

interface FiltrosTabelaCampanhasProps {
  setIdMarcaFiltro: React.Dispatch<SetStateAction<string>>;
  idMarcaFiltro: string;
  setIdAgenciaFiltro: React.Dispatch<SetStateAction<string>>;
  idAgenciaFiltro: string;
  datas: Date | Date[] | undefined;
  setDatas: React.Dispatch<SetStateAction<Date | Date[] | undefined>>;
  statusCampanha: number | undefined;
  setStatusCampanha: React.Dispatch<number | undefined>;
}

export const FiltrosTabelaCampanhas = ({
  idAgenciaFiltro,
  idMarcaFiltro,
  setIdAgenciaFiltro,
  setIdMarcaFiltro,
  datas,
  setDatas,
  setStatusCampanha,
  statusCampanha,
}: FiltrosTabelaCampanhasProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpa",
  });

  return (
    <div id={styles.containerFiltrosTabelaListarCampanhas}>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index);
          setIdAgenciaFiltro("");
          setIdMarcaFiltro("");
          setDatas([]);
          setStatusCampanha(undefined);
        }}
      >
        <TabPanel header="Marca">
          <SelectMarca setIdMarca={setIdMarcaFiltro} idMarca={idMarcaFiltro} />
        </TabPanel>

        <TabPanel header="Agencia">
          <SelectAgencia
            idAgencia={idAgenciaFiltro}
            setIdAgencia={setIdAgenciaFiltro}
          />
        </TabPanel>

        <TabPanel header="Período">
          <Calendar
            dateFormat="dd/mm/yy"
            locale="pt-br"
            placeholder="Período da campanha"
            className="input-data"
            selectionMode="range"
            value={datas}
            required
            onChange={(e) => {
              setDatas(e.value);
            }}
            showIcon
            style={{
              width: "100%",
              color: "#fff",
              outline: "none",
              boxShadow: "none",
              fontFamily: "Exo 2",
              height: "3.5rem",
            }}
            panelStyle={{
              fontSize: "1.2rem",
            }}
          />
        </TabPanel>
        <TabPanel header="Status">
          <SelectStatusCampanha
            setStatusCampanha={setStatusCampanha}
            statusCampanha={statusCampanha}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};
