//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { campanha } from "../../../../services/campanha";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioComparativoEntreFormatos } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import QuickChart from 'quickchart-js';
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { TabelaComparativoRedesSociais } from "../../../../components/Tabelas/TabelaComparativoRedesSociais";
import { PublicacoesProps } from "../../../../services/publicacao";
import { somaMetrica } from "../../../../utils/somaMetrica";
import * as htmlToImage from 'html-to-image';
import { createRoot } from "react-dom/client";

export async function gerarPaginaComparativoRedesSociais(
  campanha: campanha,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        GraficoRedesSociais: {
          type: "image",
          position: {
            x: 9,
            y: 32
          },
          width: 319.1,
          height: 134.11
        }
      },
      {
        TabelaRedesSociais: {
          type: "image",
          position: {
            x: 9.05,
            y: 32.41
          },
          width: 319,
          height: 134
        }
      }
    ],
    basePdf: base64RelatorioComparativoEntreFormatos,
  };

  var imagemMarca = !campanha?.marcas[0].urlLogotipo
    ? ""
    : await converterImagemParaBase64(
      campanha.marcas[0].urlLogotipo,
      setErro,
      setCarregando
    );
  if (imagemMarca !== "AxiosError") {

    let publicacoesTemporarias: PublicacoesProps[] = [];
    campanha.tarefas.map((tarefa) => {
      publicacoesTemporarias.push(...tarefa.publicacoes);
    });
    var publicacoes = publicacoesTemporarias;

    let dataArrayTwitter = setaDadosTwitter(publicacoes);
    let dataArrayInsta = setaDadosInsta(publicacoes);
    let dataArrayYoutube = setaDadosYoutube(publicacoes);
    let dataArrayTiktok = setaDadosTiktok(publicacoes);

    const instagramExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoInstagramFeed" ||
        publicacao.tipo === "PublicacaoInstagramReels" ||
        publicacao.tipo === "PublicacaoInstagramStories"
    );

    const tiktokExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTikTok"
    );

    const twitterExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTwitter"
    );

    const youtubeExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoYouTube"
    );

    const labelsPresentes: string[] = [];
    if (instagramExiste) {
      labelsPresentes.push("Instagram");
    }
    if (tiktokExiste) {
      labelsPresentes.push("TikTok");
    }
    if (twitterExiste) {
      labelsPresentes.push("Twitter");
    }
    if (youtubeExiste) {
      labelsPresentes.push("YouTube");
    }

    ChartJS.register(ChartDataLabels);

    const graficoRedesSociais = new QuickChart();

    graficoRedesSociais.setConfig({
      type: 'bar',
      data: {
        labels: labelsPresentes,
        datasets: [
          {
            type: "bar",
            label: "Impressões",
            yAxisID: "y",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#ef8ada", "#fc887b"]),
            data: [
              dataArrayInsta[1],
              dataArrayTiktok[1],
              dataArrayTwitter[1],
              dataArrayYoutube[1],
            ],
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
          {
            type: "bar",
            label: "Alcance",
            yAxisID: "y",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#fc887b", "#ffdd95"]),
            data: [
              dataArrayInsta[0],
              dataArrayTiktok[0],
              dataArrayTwitter[0],
              dataArrayYoutube[0],
            ],
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
          {
            type: "bar",
            label: "Engajamento",
            yAxisID: "y",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#ffdd95", "#d0e6a5"]),
            data: [
              dataArrayInsta[2],
              dataArrayTiktok[2],
              dataArrayTwitter[2],
              dataArrayYoutube[2],
            ],
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
          {
            type: "bar",
            label: "Likes",
            yAxisID: "y",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#d0e6a5", "#86e3ce"]),
            data: [
              dataArrayInsta[3],
              dataArrayTiktok[3],
              dataArrayTwitter[3],
              dataArrayYoutube[3],
            ],
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
          {
            type: "bar",
            label: "Comentários",
            yAxisID: "y",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#86e3ce", "#89d5f8"]),
            data: [
              dataArrayInsta[4],
              dataArrayTiktok[4],
              dataArrayTwitter[4],
              dataArrayYoutube[4],
            ],
            borderColor: "#00000000",
            borderWidth: 1,
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
          datalabels: {
            backgroundColor: function (context: any) {
              return "#ffffff00";
            },
            borderRadius: 4,
            color: function (context: any) {
              return context.datasetIndex === 2
                  ? "#242424"
                  : "#242424";
            },
            display: function (context: any) {
              return context.dataset.data[context.dataIndex] > 0;
            },
            formatter: function (value: number, context: any) {
              return value < 1e3 ? value :
                  value >= 1e3 && value < 1e6 ? +(value / 1e3).toFixed(1) + "K" :
                    value >= 1e6 && value < 1e9 ? +(value / 1e6).toFixed(1) + "M" :
                      value >= 1e9 && value < 1e12 ? +(value / 1e9).toFixed(1) + "B" :
                        +(value / 1e12).toFixed(1) + "T";
            },
            font: {
              size: 14,
              weight: "bold",
            },
          },
        },
        scales: {
          //@ts-ignore
          yAxes: [
            {
              id: 'y',
              stacked: false,
              type: "logarithmic",
              display: true,
              position: "left",
              ticks: {
                min: 0,
                maxTicksLimit: 9,
                callback: (value: any) => {
                  return value < 1e3 ? value :
                    value >= 1e3 && value < 1e6 ? +(value / 1e3).toFixed(1) + "K" :
                      value >= 1e6 && value < 1e9 ? +(value / 1e6).toFixed(1) + "M" :
                        value >= 1e9 && value < 1e12 ? +(value / 1e9).toFixed(1) + "B" :
                          +(value / 1e12).toFixed(1) + "T";
                },
              },
            },
          ]
        },
      },
    });

    graficoRedesSociais.setWidth(1200);
    graficoRedesSociais.setHeight(500);

    graficoRedesSociais.setBackgroundColor('transparent');

    const tabelaRedesTemp = document.createElement('div');
    const root = createRoot(tabelaRedesTemp);

    root.render(<TabelaComparativoRedesSociais campanha={campanha} />)

    const tabelaRedesElement = tabelaRedesTemp as HTMLElement;
    tabelaRedesElement.style.width = "1280px";
    tabelaRedesElement.style.maxWidth = "1280px";
    tabelaRedesElement.style.backgroundColor = "#fff"
    tabelaRedesElement.style.display = "flex"
    tabelaRedesElement.style.justifyContent = "center"

    document.body.appendChild(tabelaRedesElement);

    const inputs = [
      {
        GraficoRedesSociais: await converterImagemParaBase64(
          (await graficoRedesSociais.getShortUrl()).toString(),
          setErro,
          setCarregando
        ),
        TabelaRedesSociais: await htmlToImage.toPng(tabelaRedesElement)
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob2 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob2);
      document.body.removeChild(tabelaRedesElement);
    });
  } else {
    return "AxiosError";
  }

  function setaDadosTwitter(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTwitter" &&
        publicacao.status === "Aprovado"
      ) {
        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

      }
    });

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosInsta(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoInstagramReels" ||
          publicacao.tipo === "PublicacaoInstagramFeed" ||
          publicacao.tipo === "PublicacaoInstagramStories") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

      }
    });

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];
    return dataArray;
  }

  function setaDadosTiktok(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTikTok" &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.views);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotal);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotais);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

      }
    });

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosYoutube(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoYouTube" ||
          publicacao.tipo === "PublicacaoYouTubeShorts") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

      }
    });

    dataArray = [
      somaAlcance,
      somaImpressoes,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }
}
