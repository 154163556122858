import {
  dadosUsuarioProps,
  putUsuarioReativacao,
} from "../../../services/usuario";

import { obterTokens } from "../../../utils/LocalStorage";

import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";
import { putUsuarioAgencia } from "../../../services/usuarioAgencia";
import { ErroType } from "../../../@types/erro";

interface FuncoesTabelaListarUsuariosAgenciaProps {
  idUsuario: string;
  nomeUsuario: string;
  idAgencia: string;
  telefoneUsuario: string;
  imagemAvatarUsuario: string;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  setCarregando: React.Dispatch<boolean>;
  setRecarregar: () => void;
  setErro: React.Dispatch<ErroType>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesTabelaListarUsuariosAgencia = ({
  idUsuario,
  imagemAvatarUsuario,
  myToast,
  nomeUsuario,
  idAgencia,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setModalAbertoDesativacao,
  telefoneUsuario,
  setRecarregar,
  setErro,
}: FuncoesTabelaListarUsuariosAgenciaProps) => {
  function sucessoRequisicao() {
    showToast(
      "success",
      "Desativação realizada com sucesso",
      "Agencia desativada!"
    );
  }

  function erroRequisicao() {
    showToast(
      "error",
      "Desativação não realizada",
      "Não foi possível desativar a Agencia."
    );
    setModalAbertoDesativacao(false);
  }

  function desativarUsuario() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();
    putUsuarioAgencia(
      token,
      idUsuario,
      nomeUsuario,
      null,
      telefoneUsuario,
      idAgencia,
      "Removido"
    )
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
        setRecarregar();
        setModalAbertoDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioAgencia(
                token,
                idUsuario,
                nomeUsuario,
                "",
                telefoneUsuario,
                idAgencia,
                "Removido"
              )
                .then(() => sucessoRequisicao())
                .catch(() => erroRequisicao());
            },
            () => {
              setCarregando(false);
              setRecarregar();
              setModalAbertoDesativacao(false);
            }
          );
        } else {
          erroRequisicao();
          setCarregando(false);
          setRecarregar();
        }
      });
  }

  function sucessoReativacao() {
    showToast(
      "success",
      "Reativação realizada com sucesso",
      "Usuário reativado!"
    );
  }

  function falhaReativacao() {
    showToast(
      "error",
      "Reativação não realizada",
      "Não foi possível reativar o usuário"
    );
  }

  function reativarUsuario() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);

    let dadosUsuario: dadosUsuarioProps = {
      id: idUsuario,
      nome: nomeUsuario,
      imagemAvatar: imagemAvatarUsuario,
      status: "Ativo",
      telefone: telefoneUsuario,
      termosDeUsoAceitos: true,
    };

    const { token, tokenRenovacao } = obterTokens();
    putUsuarioReativacao(token, dadosUsuario)
      .then(() => {
        sucessoReativacao();
        setCarregando(false);
        setRecarregar();
        setModalAbertoDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioReativacao(token, dadosUsuario)
                .then(() => sucessoReativacao())
                .catch(() => falhaReativacao());
            },
            () => {
              setCarregando(false);
              setRecarregar();
              setModalAbertoDesativacao(false);
            }
          );
        } else {
          falhaReativacao();
          setCarregando(false);
          setRecarregar();
          setModalAbertoDesativacao(false);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  function criaPDFListaDeUsuariosAgencia() {
    const minhaTabela = document.getElementById(
      "corpoTabelaListaUsuarioAgencia"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>Agência</th><th>E-mail</th><th>Telefone</th><th>Status</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }

  return {
    desativarUsuario,
    reativarUsuario,
    criaPDFListaDeUsuariosAgencia,
  };
};
