import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { DateFormatter } from "../../../utils/DataFormatter";
import { funcoesCampanhaDetalhes } from "../../UsuarioNetcos/CampanhaDetalhes/functions";
import { TabInformacoesCampanha } from "./TabInformacoesCampanha";
import { Carregando } from "../../../components/Carregando";

import { FiCalendar } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const CampanhaInfluenciadorDetalhes = () => {
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [carregando, setCarregando] = useState<boolean>(true);

  const [campanha, setCampanha] = useState<campanha>();

  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterCampanha } = funcoesCampanhaDetalhes({
    setErro,
    setCarregando,
    setCampanha,
    idCampanha: idCampanha!,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterCampanha();
  }, [recarregar]);

  return (
    <>
      <div className={styles.containerPagina}>
        {carregando ? (
          <Carregando />
        ) : erro.ativo ? (
          <div className={styles.containerErro}>
            <h1>{erro.mensagem}</h1>
            <button
              onClick={() => setRecarregar(!recarregar)}
              className={styles.botaoIconeColunaAcoes}
            >
              <i
                className={"pi pi-refresh"}
                style={{ fontSize: "3rem" }}
                title={"Recarregar gráfico"}
              />
            </button>
          </div>
        ) : (
          campanha && (
            <div className={styles.informacoesPagina}>
              <div className={styles.headerCompleto}>
                <div className={styles.headerCampanha}>
                  <div className={styles.campoLogotipo}>
                    {campanha.marcas[0].urlLogotipo ? (
                      <img
                        className={styles.logotipo}
                        src={campanha.marcas[0].urlLogotipo}
                      />
                    ) : (
                      <p>{campanha.marcas[0].nome?.charAt(0)}</p>
                    )}
                  </div>

                  <div className={styles.campoInformacoesCampanha}>
                    <p className={styles.tituloCampanha}>{campanha.nome}</p>
                    <div className={styles.campoMarcaPeriodo}>
                      <p className={styles.nomeMarca}>
                        {campanha.marcas[0].nome}
                      </p>
                      <div className={styles.campoDatasCampanha}>
                        <div className={styles.dataInidividual}>
                          <FiCalendar />
                          <span>Período da Campanha: </span>
                          {DateFormatter(campanha.dataInicio)} -{" "}
                          {DateFormatter(campanha.dataFim)}
                        </div>
                        {campanha.dataCriacaoRelatorio !==
                          "0001-01-01T00:00:00" && (
                          <div className={styles.campoDatasCampanha}>
                            <div className={styles.dataInidividual}>
                              Data do Relatório:{" "}
                              {DateFormatter(campanha.dataCriacaoRelatorio)}
                            </div>
                            <div className={styles.dataInidividual}>
                              Relatório Disponível até{" "}
                              {DateFormatter(
                                new Date(
                                  campanha.dataCriacaoRelatorio
                                ).setMonth(
                                  new Date(
                                    campanha.dataCriacaoRelatorio
                                  ).getMonth() + 6
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TabInformacoesCampanha
                dataInicioCampanha={campanha.dataInicio}
                dataRelatorio={campanha.dataCriacaoRelatorio}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};
