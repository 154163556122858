import { AnimacaoNumero } from "../../utils/AnimacaoNumero";
import styles from "./styles.module.css";

interface ProgressBarProps {
  progresso: number;
}

const BarraDeProgresso = ({ progresso }: ProgressBarProps) => (

  <div className={styles.containerProgresso}>
    <div className={styles.barraExterna}>
      <div className={styles.barraProgresso} style={{ width: progresso + "%", transition: 'width ease-in-out 1s' }} />
    </div>
    <p className={styles.percentualCompleto}>
      <AnimacaoNumero numero={progresso ? progresso : 0} inteiro={true} instantaneo={true} /> %
    </p>
  </div>
);

export default BarraDeProgresso;
