import { useEffect, useRef, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesPerfilUsuario } from "./functions";

import { Button } from "../../components/Botoes/Button";
import { Input } from "../../components/Input";
import { MascaraTelefone } from "../../utils/MascaraTelefone";
import { obterCredenciais } from "../../utils/LocalStorage";
import { converteImgPara64 } from "../../utils/ConverteImgPara64";
import { Carregando } from "../../components/Carregando";
import { ModalDesativacao } from "../../components/Modais/ModalDesativacao";

import { Avatar } from "primereact/avatar";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";

export const PerfilUsuario = () => {
  const credenciais = obterCredenciais().credenciais;

  const [edicao, setEdicao] = useState<boolean>(true);

  const [nome, setNome] = useState<string>(credenciais.nome);
  const [imagemAvatarPrevia, setImagemAvatarPrevia] = useState<string | null>(
    credenciais.urlAvatar
  );
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [telefone, setTelefone] = useState<string>(credenciais.celular);
  const [linkInstagram, setLinkInstagram] = useState<string>("");
  const [linkTwitter, setLinkTwitter] = useState<string>("");
  const [linkTiktok, setLinkTiktok] = useState<string>("");
  const [linkYoutube, setLinkYoutube] = useState<string>("");

  const [carregando, setCarregando] = useState<boolean>(false);
  const [modalDesativacao, setModalDesativacao] = useState<boolean>(false);

  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] =
    useState<string>("");
  const [login, setLogin] = useState<boolean>(false);
  const [usuarioFacebook, setUsuarioFacebook] = useState<any>({});

  const perfil = credenciais.perfil[0];
  const myToast = useRef<any>(null);

  const navigate = useNavigate();

  // const resetarEdicao = () => {
  //   setEdicao(false);
  //   setNome(credenciais.nome);
  //   setImagemAvatarPrevia(credenciais.urlAvatar);
  //   setTelefone(Number(credenciais.celular));
  // };

  // useEffect(() => {
  //   telefone &&
  //     MascaraTelefone(
  //       telefone.toString(),
  //       setTelefone,
  //       setMascaraNumeroWhatsapp
  //     );
  // }, []);

  useEffect(() => {
    perfil === "Influenciador" && obterInfluenciadorPorId();
  }, []);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    desativarUsuario,
    atualizarUsuario,
    atualizarInfluenciador,
    obterInfluenciadorPorId,
  } = FuncoesPerfilUsuario({
    nome,
    urlAvatar: imagemAvatar ? imagemAvatar : null,
    telefone,
    setTelefone: setTelefone,
    setEdicao,
    myToast,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    setModalAbertoDesativacao: setModalDesativacao,
    navigate,
    setMascaraNumeroWhatsapp,
    linkInstagram,
    linkTwitter,
    linkTiktok,
    linkYoutube,
    setLinkInstagram,
    setLinkTwitter,
    setLinkTiktok,
    setLinkYoutube,
    setLogin,
    setUsuarioFacebook,
  });

  return (
    <div className={styles.containerPerfilNetCos}>
      <header className={styles.headerPerfilNetcos}>
        <h1>Dados Pessoais</h1>
      </header>
      <div className={styles.bodyPerfilNetcos}>
        <div className={styles.containerAvatar}>
          <Avatar
            label={credenciais.nome.charAt(0)}
            image={imagemAvatarPrevia ? imagemAvatarPrevia : ""}
            style={{
              borderRadius: "50%",
              fontSize: "4rem",
              background: "var(--cinza-200)",
              boxShadow: "inset 0 0 10px 1px #00000040",
            }}
            shape="circle"
          >
            {/* {(imagemAvatar || imagemAvatarPrevia) &&
              <div
                className={edicao ? styles.hoverRemoverImagem : styles.labelInputDesativado}
                onClick={() => setImagemAvatarPrevia("")}
              >
                Remover
                <br />
                Imagem
              </div>
            } */}
          </Avatar>
          {perfil !== "Administrador" && (
            <label className={styles.labelInput} htmlFor={styles.fileUpload}>
              <i className="pi pi-camera"></i>
              <input
                id={styles.fileUpload}
                type="file"
                accept="image/*"
                onChange={(e) => (
                  converteImgPara64(e, setImagemAvatar),
                  converteImgPara64(e, setImagemAvatarPrevia)
                )}
              />
            </label>
          )}
        </div>

        <div
          className={styles.containerInputs}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            type="text"
            value={nome}
            placeholder="Nome"
            required
            disabled={perfil === "Administrador" ? true : false}
            onChange={(e) => setNome(e.target.value)}
          />

          <Input
            type="text"
            value={
              perfil === "Administrador"
                ? "master@netcos.art.br"
                : credenciais.userName
            }
            placeholder="Email"
            required
            disabled={true}
            onChange={(e) => setNome(e.target.value)}
          />

          {perfil != "Administrador" && (
            <PhoneInput
              defaultCountry="br"
              forceDialCode
              preferredCountries={["br", "us"]}
              placeholder="Telefone"
              value={MascaraNumeroWhatsapp}
              onChange={(phone, country) => setMascaraNumeroWhatsapp(country.inputValue)}
            />
          )}
          {perfil === "Influenciador" && (
            <>
              <>
                <Input
                  value={linkTwitter}
                  placeholder="Url Twitter"
                  onChange={(e) => {
                    setLinkTwitter(e.target.value);
                  }}
                />
                <Input
                  type="text"
                  value={linkInstagram}
                  placeholder="Url Instagram"
                  onChange={(e) => {
                    setLinkInstagram(e.target.value);
                  }}
                />
                <Input
                  type="text"
                  value={linkTiktok}
                  placeholder="Url TikTok"
                  onChange={(e) => {
                    setLinkTiktok(e.target.value);
                  }}
                />
                <Input
                  type="text"
                  value={linkYoutube}
                  placeholder="Url Youtube"
                  onChange={(e) => {
                    setLinkYoutube(e.target.value);
                  }}
                />
              </>

              {/* <div className={styles.containerIcons}>
                  {linkInstagram && (
                    <a href={adicionarHttps(linkInstagram)} target="_blank">
                      <AiFillInstagram
                        className={`${styles.logoRedesSociais} ${styles.youtubeLogo}`}
                      />
                    </a>
                  )}

                  {linkTwitter && (
                    <a href={adicionarHttps(linkTwitter)} target="_blank">
                      <FaTwitter className={styles.logoRedesSociais} />
                    </a>
                  )}

                  {linkTiktok && (
                    <a href={adicionarHttps(linkTiktok)} target="_blank">
                      <FaTiktok className={styles.logoRedesSociais} />
                    </a>
                  )}

                  {linkYoutube && (
                    <a href={adicionarHttps(linkYoutube)} target="_blank">
                      <FaYoutube
                        className={`${styles.logoRedesSociais} ${styles.youtubeLogo}`}
                      />
                    </a>
                  )}
                </div> */}
            </>
          )}
        </div>
      </div>

      <Toast ref={myToast} className="custom-toast" />
      {carregando ? (
        <Carregando />
      ) : (
        <div className={styles.containeraButton}>
          {perfil !== "Administrador" && (
            <Button
              importancia="primario"
              title="Atualizar"
              onClick={() => {
                perfil === "Influenciador"
                  ? atualizarInfluenciador()
                  : atualizarUsuario();
              }}
            />
          )}
        </div>
      )}

      {modalDesativacao && (
        <ModalDesativacao
          modalAberto={modalDesativacao}
          nomeItemParaDesativar={nome}
          setModalAberto={setModalDesativacao}
          onClick={() => desativarUsuario()}
        />
      )}
    </div>
  );
};
