import React from 'react';
import { tipoOrdenacao } from '../../../../../../@types/ordenacao';

interface IconeOrdenacaoProps {
  tituloOrdenacao?: string;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<tipoOrdenacao>;
  tituloColuna: string;
  nomePropriedadeOrdenacao: string;
  setnomePropriedadeOrdenacao: React.Dispatch<string>;
}
const IconeOrdenacao = ({ tituloOrdenacao, tipoOrdenacao, setTipoOrdenacao, nomePropriedadeOrdenacao, tituloColuna, setnomePropriedadeOrdenacao }: IconeOrdenacaoProps) => {

  const handleOrdenacao = () => {
    setTipoOrdenacao(tipoOrdenacao === 'Ascending' ? 'Descending' : 'Ascending');
    setnomePropriedadeOrdenacao(tituloOrdenacao ? tituloOrdenacao : tituloColuna);
  }

  return (
    <div
      onClick={handleOrdenacao}
      title={"Ordenar por " + tituloColuna}
    >
      <i
        className={tipoOrdenacao === 'Descending' &&
          nomePropriedadeOrdenacao === (tituloOrdenacao || tituloColuna)
          ? "pi pi-sort-amount-up-alt"
          : "pi pi-sort-amount-down-alt"
        }
        style={
          nomePropriedadeOrdenacao === (tituloOrdenacao || tituloColuna)
            ? {
              fontSize: "1.4rem",
              paddingLeft: "0.5rem",
              color: "var(--roxo-500)",
              cursor: "pointer",
            }
            : {
              fontSize: "1.4rem",
              paddingLeft: "0.5rem",
              cursor: "pointer",
            }
        }
      />
    </div>
  );
};

export default IconeOrdenacao;