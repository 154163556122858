import { useRef, useState, useContext } from "react";

import "./styles.css";

import { FunctionsTemplateEmail } from "./functions";

import { Input } from "../../../components/Input";
import { Button } from "../../../components/Botoes/Button";
import { Textarea } from "../../../components/Textarea";
import { VisualizadorHtml } from "../../../components/VisualizadorEmail";
import { ErroType } from "../../../@types/erro";

import { Toast } from "primereact/toast";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const CadastroTemplateEmail = () => {
  const [mensagemHtml, setMensagemHtml] = useState<string>("");
  const [assunto, setAssunto] = useState<string>("");
  const [mensagemTextoPuro, setMensagemTextoPuro] = useState<string>("");
  const [tipo, setTipo] = useState<string>("1");
  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [erroPreenchimento, setErroPreenchimento] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { salvarNovoEmail } = FunctionsTemplateEmail({
    assunto,
    mensagemHtml,
    mensagemTextoPuro,
    setCarregando,
    setErro,
    setErroPreenchimento,
    setSucesso,
    tipo,
    myToast,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <div className="pagina-template-email">
      <>
        <form onSubmit={salvarNovoEmail}>
          <Input
            required
            placeholder="Assunto"
            value={assunto}
            onChange={(e) => setAssunto(e.target.value)}
          />
          <Textarea
            placeholder="HTML"
            maxWidth={400}
            rows={10}
            value={mensagemHtml}
            onChange={(e) => setMensagemHtml(e.target.value)}
          />
          <Input
            required
            placeholder="Texto"
            value={mensagemTextoPuro}
            onChange={(e) => setMensagemTextoPuro(e.target.value)}
          />
          <select onChange={(e) => setTipo(e.target.value)}>
            <option value="1">Confirmação de e-mail de funcionário </option>
            <option value="2">Confirmação de e-mail de contratante </option>
            <option value="3">Convidar Influenciador </option>
            <option value="4">Confirmação de e-mail de influenciador </option>
          </select>
          {carregando ? (
            <p>Carregando...</p>
          ) : (
            <Button title="Salvar" onClick={salvarNovoEmail} type="submit" />
          )}
          <Toast ref={myToast} className="custom-toast" />
        </form>

        <VisualizadorHtml html={mensagemHtml} />
      </>
      )
    </div>
  );
};
