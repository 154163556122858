import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FuncaoListarCampanhaInfluenciador } from "./functions";

import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TabelaListarCampanhasInfluenciador } from "../../../components/Tabelas/TabelaListarCampanhaInfluenciadores";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListarCampanhasInfluenciador = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(true);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);

  const [idInfluenciador, setIdInfluenciador] = useState<string>("");
  const [idMarcaFiltro, setIdMarcaFiltro] = useState<string>("");
  const [idAgenciaFiltro, setIdAgenciaFiltro] = useState<string>("");
  const [idAgencia, setIdAgencia] = useState<string>("");
  const [idMarca, setIdMarca] = useState<string>("");
  const [numeroPagina, setNumeroPagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");
  const [listaDeCampanhasInfluenciador, setListaDeCampanhasInfluenciador] =
    useState<campanha[]>([]);
  const [totalCampanhasInfluenciador, setTotalCampanhasInfluenciador] =
    useState<number>(0);
  const [
    nomeCampanhaInfluenciadorPesquisa,
    setNomeCampanhaInfluenciadorPesquisa,
  ] = useState<string>("");

  const myToast = useRef<any>(null);

  const { credenciais } = obterCredenciais();

  const colunas: TitulosTabelasType[] = [
    {
      nome: "Nome",
      ordenacao: "nome",
    },
    {
      nome: "Agência",
      ordenacao: "agencia",
    },
    {
      nome: "Marca",
      ordenacao: "marca",
    },
    {
      nome: "Data Início",
      ordenacao: "dataInicio",
    },
    {
      nome: "Data Fim",
      ordenacao: "dataFim",
    },
    {
      nome: "Status",
      ordenacao: "ativo",
    },
  ];

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterCampanhasPorInfluenciador,
    obterCampanhaInfluenciadorPorNome,
    obterCampanhaInfluenciadorPorMarca,
    obterCampanhaInfluenciadorPorAgencia,
  } = FuncaoListarCampanhaInfluenciador({
    idInfluenciador: credenciais.id,
    setIdInfluenciador,
    idAgenciaFiltro: idAgencia,
    idMarcaFiltro: idMarca,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
    nomeCampanhaInfluenciadorPesquisa,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    setTamanhoPagina,
    setErro,
    setCarregando,
    setCarregandoPesquisa,
    adicionarAListaEsperaRenovacaoToken,
    setListaDeCampanhasInfluenciador,
    setTotalCampanhasInfluenciador,
  });

  useEffect(() => {
    const pesquisaPorNome =
      nomeCampanhaInfluenciadorPesquisa !== "" &&
      nomeCampanhaInfluenciadorPesquisa !== undefined;
    const filtroPorAgencia = idAgencia !== "" && idAgencia !== undefined;
    const filtroPorMarca = idMarca !== "" && idMarca !== undefined;

    pesquisaPorNome
      ? obterCampanhaInfluenciadorPorNome()
      : filtroPorAgencia
      ? obterCampanhaInfluenciadorPorAgencia()
      : filtroPorMarca
      ? obterCampanhaInfluenciadorPorMarca()
      : obterCampanhasPorInfluenciador();
  }, [
    tamanhoPagina,
    nomeCampanhaInfluenciadorPesquisa,
    tipoOrdenacao,
    numeroPagina,
    recarregar,
    idMarca,
    idAgencia,
  ]);

  return (
    <>
      <div className={styles.tabelaCampanhas}>
        <TabelaListarCampanhasInfluenciador
          idAgenciaFiltro={idAgencia}
          setIdAgenciaFiltro={setIdAgencia}
          idMarcaFiltro={idMarca}
          setIdMarcaFiltro={setIdMarca}
          carregando={carregando}
          setCarregando={setCarregando}
          setErro={setErro}
          carregandoPesquisa={carregandoPesquisa}
          myToast={myToast}
          tipoOrdenacao={tipoOrdenacao}
          setTipoOrdenacao={setTipoOrdenacao}
          nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
          setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
          setNomeCampanhaPesquisa={setNomeCampanhaInfluenciadorPesquisa}
          setRecarregar={() => setRecarregar(!recarregar)}
          erro={erro}
          tituloTabela={"Campanhas"}
          tituloColuna={colunas}
          listaDeCampanhas={listaDeCampanhasInfluenciador}
          // paginacao={
          //   <Paginator
          //     first={paginatorFirst}
          //     rows={tamanhoPagina}
          //     totalRecords={totalCampanhasInfluenciador}
          //     onPageChange={(e) => {
          //       setNumeroPagina(e.page);
          //       setPaginatorFirst(e.first);
          //     }}
          //   />
          // }
          // seletorDeQuantidade={
          //   <SelectQtdadeItensLista
          //     onChange={(e: any) => setTamanhoPagina(e.target.value)}
          //     totalLista={totalCampanhasInfluenciador}
          //     nomeLista={"campanhas"}
          //   />
          // }
        />
      </div>

      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
