import {
  FileUploadRemoveParams,
  FileUploadSelectParams,
} from "primereact/fileupload";

interface FunctionsUploadDragAndDropProps {
  prints: File[];
  setPrints: React.Dispatch<React.SetStateAction<File[]>>;
}

export const FunctionsUploadDragAndDrop = ({
  prints,
  setPrints,
}: FunctionsUploadDragAndDropProps) => {
  //setando a img do objeto printsDeSentimentos
  function selecionaImagens(event: FileUploadSelectParams) {
    let printsTemporarios = [];
    for (let i = 0; i < event.files.length; i++) {
      printsTemporarios.push(event.files[i]);
    }
    setPrints([...prints, ...printsTemporarios]);
  }

  function removeFoto(e: FileUploadRemoveParams) {
    let printsRemovido = prints.filter((file) => file !== e.file);
    setPrints(printsRemovido);
  }

  function limpaTodasFotos() {
    setPrints([]);
  }

  return {
    selecionaImagens,
    removeFoto,
    limpaTodasFotos,
  };
};
