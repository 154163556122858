import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { FuncoesModalEditarTarefa } from "./functions";
import { FunctionsOptIn } from "./functionsOptIn";

import styles from "./styles.module.css";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InstagramLoginButton } from "react-social-login-buttons";
import { Broom, FileSearch } from "@phosphor-icons/react";

import { Input } from "../../Input";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { tipoPublicacaoProps } from "../../../services/campanha";
import { ModalTeste } from "../ModalTeste";
import { Prints, PublicacoesProps } from "../../../services/publicacao";
import { UploadDragAndDrop } from "../../UploadDragAndDrop";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { CarrosselMetricas } from "../../Carrossel/CarrosselMetricas";
import { LoginSocialFacebook } from "reactjs-social-login";
import {
  converteSegundoParaHora,
  converteSegundoParaMinuto,
  converteSegundoParaSegundoRestante,
} from "../../../utils/ConversorTempo";
import { AvisoOptIn } from "../ModalAvisoOptIn";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { DateFormatter } from "../../../utils/DataFormatter";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { TabPanel, TabView } from "primereact/tabview";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { InstagramFeed } from "./inputsPorTipoPublicacao/instagramFeed";
import { InstagramReels } from "./inputsPorTipoPublicacao/instagramReels";
import { InstagramStories } from "./inputsPorTipoPublicacao/instagramStories";
import { TikTok } from "./inputsPorTipoPublicacao/tikTok";
import { Twitter } from "./inputsPorTipoPublicacao/twitter";
import { YoutubeVideo } from "./inputsPorTipoPublicacao/youtubeVideo";
import { YoutubeShorts } from "./inputsPorTipoPublicacao/youtubeShorts";
import { useFacebookLogin } from "../../../hooks/useFacebookLogin";

export interface ModalEditarTarefaProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  publicacaoSelecionada: PublicacoesProps;
  idCampanhaParametro?: string;
  aprovar?: boolean;
  influenciador?: boolean;
}

export const ModalEditarTarefa = ({
  modalAberto,
  setModalAberto,
  publicacaoSelecionada,
  idCampanhaParametro,
  aprovar = false,
  setRecarregar,
  influenciador,
}: ModalEditarTarefaProps) => {
  // #region VARIÁVEIS
  const [urlPublicacao, setUrlPublicacao] = useState<string>(
    publicacaoSelecionada.urlPublicacao
      ? publicacaoSelecionada.urlPublicacao
      : ""
  );

  const [dataPublicacao, setDataPublicacao] = useState<
    Date | Date[] | undefined
  >(
    publicacaoSelecionada.dataFinalizacao &&
      publicacaoSelecionada.dataFinalizacao !== "0001-01-01T00:00:00"
      ? new Date(publicacaoSelecionada.dataFinalizacao)
      : new Date()
  );

  const [comentariosNetcos, setComentariosNetcos] = useState<string>(
    publicacaoSelecionada.comentariosNetcos
      ? publicacaoSelecionada.comentariosNetcos
      : ""
  );
  const [alcance, setAlcance] = useState<number>(
    publicacaoSelecionada.alcance ? publicacaoSelecionada.alcance : -1
  );
  const [cliquesLink, setCliquesLink] = useState<number>(
    publicacaoSelecionada.cliquesLink ? publicacaoSelecionada.cliquesLink : -1
  );

  const [cliquesStickers, setCliquesStickers] = useState<number>(
    publicacaoSelecionada.cliquesStickers
      ? publicacaoSelecionada.cliquesStickers
      : -1
  );
  const [cliquesHashtag, setCliquesHashtag] = useState<number>(
    publicacaoSelecionada.cliquesHashtag
      ? publicacaoSelecionada.cliquesHashtag
      : -1
  );
  const [comentarios, setComentarios] = useState<number>(
    publicacaoSelecionada.comentarios ? publicacaoSelecionada.comentarios : -1
  );
  const [compartilhamentos, setCompartilhamentos] = useState<number>(
    publicacaoSelecionada.compartilhamentos
      ? publicacaoSelecionada.compartilhamentos
      : -1
  );
  const [dislikes, setDislikes] = useState<number>(
    publicacaoSelecionada.dislikes ? publicacaoSelecionada.dislikes : -1
  );
  const [engajamentos, setEngajamentos] = useState<number>(
    publicacaoSelecionada.engajamento ? publicacaoSelecionada.engajamento : -1
  );
  const [favoritos, setFavoritos] = useState<number>(
    publicacaoSelecionada.favoritos ? publicacaoSelecionada.favoritos : -1
  );
  const [impressoes, setImpressoes] = useState<number>(
    publicacaoSelecionada.impressoes ? publicacaoSelecionada.impressoes : -1
  );
  const [likes, setLikes] = useState<number>(
    publicacaoSelecionada.likes ? publicacaoSelecionada.likes : -1
  );

  const [respostas, setRespostas] = useState<number>(
    publicacaoSelecionada.respostas ? publicacaoSelecionada.respostas : -1
  );
  const [retweets, setRetweets] = useState<number>(
    publicacaoSelecionada.retweets ? publicacaoSelecionada.retweets : -1
  );
  const [salvos, setSalvos] = useState<number>(
    publicacaoSelecionada.salvos ? publicacaoSelecionada.salvos : -1
  );
  const [taxaAprovacao, setTaxaAprovacao] = useState<number>(
    publicacaoSelecionada.taxaAprovacao
      ? publicacaoSelecionada.taxaAprovacao
      : -1
  );

  const [tempoReproducaoHora, setTempoReproducaoHora] = useState<number>(
    publicacaoSelecionada.tempoReproducao
      ? converteSegundoParaHora(publicacaoSelecionada.tempoReproducao)
      : 0
  );
  const [tempoReproducaoMinuto, setTempoReproducaoMinuto] = useState<number>(
    publicacaoSelecionada.tempoReproducao
      ? converteSegundoParaMinuto(publicacaoSelecionada.tempoReproducao)
      : 0
  );
  const [tempoReproducaoSegundo, setTempoReproducaoSegundo] = useState<number>(
    publicacaoSelecionada.tempoReproducao
      ? converteSegundoParaSegundoRestante(
        publicacaoSelecionada.tempoReproducao
      )
      : 0
  );

  const [views, setViews] = useState<number>(
    publicacaoSelecionada.views ? publicacaoSelecionada.views : -1
  );
  const [viewsTotais, setViewsTotais] = useState<number>(
    publicacaoSelecionada.viewsTotais ? publicacaoSelecionada.viewsTotais : -1
  );
  const [viewsTotal, setViewsTotal] = useState<number>(
    publicacaoSelecionada.viewsTotal ? publicacaoSelecionada.viewsTotal : -1
  );
  const [visitasAoPerfil, setVisitasAoPerfil] = useState<number>(
    publicacaoSelecionada.visitasAoPerfil
      ? publicacaoSelecionada.visitasAoPerfil
      : -1
  );
  const [visitasPerfil, setVisitasPerfil] = useState<number>(
    publicacaoSelecionada.visitasPerfil
      ? publicacaoSelecionada.visitasPerfil
      : -1
  );

  const [quantidadeReproducoes, setQuantidadeReproducoes] = useState<number>(
    publicacaoSelecionada.quantidadeReproducoes
      ? publicacaoSelecionada.quantidadeReproducoes
      : -1
  );
  const [detailExpands, setDetailExpands] = useState<number>(
    publicacaoSelecionada.detailExpands
      ? publicacaoSelecionada.detailExpands
      : -1
  );
  const [espectadores, setEspectadores] = useState<number>(
    publicacaoSelecionada.espectadores ?? -1
  );

  const [tempoReproducaoTiktokHora, setTempoReproducaoTiktokHora] =
    useState<number>(
      publicacaoSelecionada.tempoReproducao
        ? converteSegundoParaHora(publicacaoSelecionada.tempoReproducao)
        : 0
    );
  const [tempoReproducaoTiktokMinuto, setTempoReproducaoTiktokMinuto] =
    useState<number>(
      publicacaoSelecionada.tempoReproducao
        ? converteSegundoParaMinuto(publicacaoSelecionada.tempoReproducao)
        : 0
    );
  const [tempoReproducaoTiktokSegundo, setTempoReproducaoTiktokSegundo] =
    useState<number>(
      publicacaoSelecionada.tempoReproducao
        ? converteSegundoParaSegundoRestante(
          publicacaoSelecionada.tempoReproducao
        )
        : 0
    );

  const [carregando, setCarregando] = useState<boolean>(false);
  const [carregandoBuscarMetricas, setCarregandoBuscarMetricas] = useState<boolean>(false);

  const [URLRequired, setURLRequired] = useState<boolean>(false);
  const [printMetricaRequired, setPrintMetricaRequired] = useState<boolean>(false);
  const [abrirModalComentario, setAbrirModalComentario] = useState<boolean>(false);

  const [desabilitarCampos, setDesabilitarCampos] = useState<boolean>(false);
  const [plays, setPlays] = useState<number>(0);
  const [dataInclusaoStories, setDataInclusaoStories] = useState<string>("");
  const [modalAvisoOptIn, setModalAvisoOptIn] = useState<boolean>(false);
  const [nomeArquivo, setNomeArquivo] = useState<string>("");
  const [indicePrint, setIndicePrint] = useState<number>(0);

  const [imagemAberta, setImagemAberta] = useState<boolean>(false);
  const [statesOptinPreenchidas, setStatesOptinPreenchidas] = useState<boolean>(false);
  const [fezOptin, setFezOptin] = useState<boolean>(false);
  // let tokenLongaDuracao ='';
  // let userID ='';
  // let tokenAcesso = '';

  const tokenLongaDuracao = localStorage.getItem('tokenLongaDuracao') ?? 'tokenLongaDuracao nao chegou';
  const tokenAcesso = localStorage.getItem('accessToken') ?? 'token nao chegou';
  const userID = localStorage.getItem('userID') ?? 'userId nao chegou';

  const [prints, setPrints] = useState<File[]>([]);
  const [printsDeMetrica, setPrintsDeMetrica] = useState<Prints[]>(
    publicacaoSelecionada.printsDeMetrica
      ? publicacaoSelecionada.printsDeMetrica
      : []
  );
  const [printsDeMetricaCarrossel, setPrintsDeMetricaCarrossel] = useState<
    Prints[]
  >(
    publicacaoSelecionada.printsDeMetrica
      ? publicacaoSelecionada.printsDeMetrica
      : []
  );

  const [publicacaoMidia, setPublicacaoMidia] = useState<File[]>([]);
  const [midiaDaPublicacao, setMidiaDaPublicacao] = useState<Prints[]>(
    publicacaoSelecionada.midiasDeEvidencia
      ? publicacaoSelecionada.midiasDeEvidencia
      : []
  );
  const [midiaDaPublicacaoCarrossel, setMidiaDaPublicacaoCarrossel] = useState<
    Prints[]
  >(
    publicacaoSelecionada.midiasDeEvidencia
      ? publicacaoSelecionada.midiasDeEvidencia
      : []
  );
  const [urlMidiaDaPublicacaoASerApagada, setUrlMidiaDaPublicacaoASerApagada] =
    useState<string>(
      midiaDaPublicacao.length ? midiaDaPublicacao[0].urlImagem! : ""
    );
  const [urlPrintsDeMetricaASerApagada, setUrlPrintsDeMetricaASerApagada] =
    useState<string>(
      printsDeMetrica.length ? printsDeMetrica[0].urlImagem! : ""
    );

  const [carregarDelet, setCarregarDelet] = useState<boolean>(false);

  let tipoPublicacao: tipoPublicacaoProps = publicacaoSelecionada.tipo;
  const { idCampanha } = useParams();
  const id = publicacaoSelecionada.externalId;
  const myToast = useRef<any>(null);

  let perfil = obterCredenciais().credenciais.perfil[0];
  // #endregion
  const [fbSdkLoaded, setFbSdkLoaded] = useState<boolean>(false);
  const { adicionarAListaEsperaRenovacaoToken } = useContext(RenovarTokenContext);
  // const { handleLogin, loading, error } = useFacebookLogin();

  const {
    editarMetricasPublicacaoInfluenciador,
    mapeaObjeto,
    buscaMetricasPeloPrint,
    deletarPrintsDeMetrica,
    deletarMidiaDeEvidencia,
    limparStatesDeMetricas,
  } = FuncoesModalEditarTarefa({
    setCarregarDelet,
    midiaDaPublicacaoCarrossel,
    printsDeMetricaCarrossel,
    urlMidiaDaPublicacaoASerApagada,
    urlPrintsDeMetricaASerApagada,
    setPrintsDeMetrica: setPrintsDeMetricaCarrossel,
    setMidiaDaPublicacao: setMidiaDaPublicacaoCarrossel,
    nomeArquivo,
    alcance,
    setAlcance,
    cliquesLink,
    setCliquesLink,
    comentarios,
    setComentarios,
    compartilhamentos,
    setCompartilhamentos,
    dislikes,
    setDislikes,
    engajamentos,
    setEngajamentos,
    favoritos,
    setFavoritos,
    id,
    idCampanha: idCampanhaParametro ? idCampanhaParametro : idCampanha!,
    impressoes,
    setImpressoes,
    likes,
    setLikes,
    respostas,
    setRespostas,
    retweets,
    setRetweets,
    salvos,
    setSalvos,
    taxaAprovacao,
    setTaxaAprovacao,
    tempoReproducaoHora,
    setTempoReproducaoHora,
    tempoReproducaoMinuto,
    setTempoReproducaoMinuto,
    tempoReproducaoSegundo,
    setTempoReproducaoSegundo,
    views,
    setViews,
    viewsTotais,
    setViewsTotais,
    viewsTotal,
    setViewsTotal,
    visitasAoPerfil,
    setVisitasAoPerfil,
    visitasPerfil,
    setVisitasPerfil,
    espectadores,
    setEspectadores,
    cliquesStickers,
    setCliquesStickers,
    cliquesHashtag,
    setCliquesHashtag,
    detailExpands,
    setDetailExpands,
    quantidadeReproducoes,
    setQuantidadeReproducoes,
    myToast,
    adicionarAListaEsperaRenovacaoToken,
    setCarregando,
    urlPublicacao,
    printsDeMetrica,
    setModalAberto,
    setRecarregar,
    setURLRequired,
    setPrintMetricaRequired,
    comentariosNetcos,
    tipoPublicacao,
    dataPublicacao: dataPublicacao
      ? Array.isArray(dataPublicacao)
        ? dataPublicacao.length !== 0
          ? dataPublicacao[0].toISOString()
          : ""
        : dataPublicacao.toISOString()
      : "",
    setAbrirModalComentario,
    midiaDaPublicacao,
    setCarregandoBuscarMetricas,
    setTempoReproducaoTiktokHora,
    setTempoReproducaoTiktokMinuto,
    setTempoReproducaoTiktokSegundo,
    tempoReproducaoTiktokHora,
    tempoReproducaoTiktokMinuto,
    tempoReproducaoTiktokSegundo,
    fezOptin,
  });

  const { pegarMetricas } = FunctionsOptIn({
    impressoes,
    setImpressoes,
    alcance,
    setAlcance,
    compartilhamentos,
    setCompartilhamentos,
    engajamentos,
    setEngajamentos,
    visitasAoPerfil,
    setVisitasAoPerfil,
    setComentarios,
    setLikes,
    setPlays,
    setSalvos,
    setViews,
    setRespostas,
    setDataInclusaoStories,
    setStatesOptinPreenchidas,
    setQuantidadeReproducoes,
  });

  useEffect(() => {
    prints.length !== 0 && setPrintsDeMetrica(mapeaObjeto(prints));
  }, [prints]);

  useEffect(() => {
    publicacaoMidia.length !== 0 &&
      setMidiaDaPublicacao(mapeaObjeto(publicacaoMidia));
  }, [publicacaoMidia]);

  const headerComentarioNetcos = () => {
    return (
      <div className={styles.headerComentarioNetcos}>
        Ajustes a serem feitos:
      </div>
    );
  };

  const footerComentarioNetcos = () => {
    return (
      <div className={styles.footerComentarioNetcos}>
        <Button
          title="Comentar"
          importancia="primario"
          onClick={() => editarMetricasPublicacaoInfluenciador(false)}
        />
        <Button
          title={comentariosNetcos ? "Limpar" : "Não Comentar"}
          importancia="secundario"
          onClick={
            comentariosNetcos
              ? () => setComentariosNetcos("")
              : () => editarMetricasPublicacaoInfluenciador(false)
          }
        />

        <Button
          title="Cancelar"
          importancia="secundario"
          onClick={() => setAbrirModalComentario(false)}
        />
      </div>
    );
  };

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpa",
  });

  const handleInputsDeMetricas = (tipoPublicacao: tipoPublicacaoProps) => {
    switch (tipoPublicacao) {
      case "PublicacaoInstagramFeed":
        return (
          <InstagramFeed
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            comentarios={comentarios}
            setComentarios={setComentarios}
            compartilhamentos={compartilhamentos}
            setCompartilhamentos={setCompartilhamentos}
            salvos={salvos}
            setSalvos={setSalvos}
            alcance={alcance}
            setAlcance={setAlcance}
            impressoes={impressoes}
            setImpressoes={setImpressoes}
            visitasPerfil={visitasPerfil}
            setVisitasPerfil={setVisitasPerfil}
            visitasAoPerfil={visitasAoPerfil}
            setVisitasAoPerfil={setVisitasAoPerfil}
          />
        );
      case "PublicacaoInstagramReels":
        return (
          <InstagramReels
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            comentarios={comentarios}
            setComentarios={setComentarios}
            compartilhamentos={compartilhamentos}
            setCompartilhamentos={setCompartilhamentos}
            salvos={salvos}
            setSalvos={setSalvos}
            alcance={alcance}
            setAlcance={setAlcance}
            quantidadeReproducoes={quantidadeReproducoes}
            setQuantidadeReproducoes={setQuantidadeReproducoes}
          />
        );
      case "PublicacaoInstagramStories":
        return (
          <InstagramStories
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            respostas={respostas}
            setRespostas={setRespostas}
            impressoes={impressoes}
            setImpressoes={setImpressoes}
            cliquesLink={cliquesLink}
            setCliquesLink={setCliquesLink}
            cliquesStickers={cliquesStickers}
            setCliquesStickers={setCliquesStickers}
            visitasPerfil={visitasPerfil}
            setVisitasPerfil={setVisitasPerfil}
            visitasAoPerfil={visitasAoPerfil}
            setVisitasAoPerfil={setVisitasAoPerfil}
            compartilhamentos={compartilhamentos}
            setCompartilhamentos={setCompartilhamentos}
            alcance={alcance}
            setAlcance={setAlcance}
          />
        );
      case "PublicacaoTikTok":
        return (
          <TikTok
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            comentarios={comentarios}
            setComentarios={setComentarios}
            salvos={salvos}
            setSalvos={setSalvos}
            views={views}
            setViews={setViews}
            tempoReproducaoTiktokHora={tempoReproducaoTiktokHora}
            setTempoReproducaoTiktokHora={setTempoReproducaoTiktokHora}
            tempoReproducaoTiktokMinuto={tempoReproducaoTiktokMinuto}
            setTempoReproducaoTiktokMinuto={setTempoReproducaoTiktokMinuto}
            tempoReproducaoTiktokSegundo={tempoReproducaoTiktokSegundo}
            setTempoReproducaoTiktokSegundo={setTempoReproducaoTiktokSegundo}
            espectadores={espectadores}
            setEspectadores={setEspectadores}
            compartilhamentos={compartilhamentos}
            setCompartilhamentos={setCompartilhamentos}
          />
        );
      case "PublicacaoTwitter":
        return (
          <Twitter
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            comentarios={comentarios}
            setComentarios={setComentarios}
            impressoes={impressoes}
            setImpressoes={setImpressoes}
            views={views}
            setViews={setViews}
            cliquesLink={cliquesLink}
            setCliquesLink={setCliquesLink}
            engajamentos={engajamentos}
            setEngajamentos={setEngajamentos}
            favoritos={favoritos}
            setFavoritos={setFavoritos}
            retweets={retweets}
            setRetweets={setRetweets}
            detailExpands={detailExpands}
            setDetailExpands={setDetailExpands}
            cliquesHashtag={cliquesHashtag}
            setCliquesHashtag={setCliquesHashtag}
          />
        );
      case "PublicacaoYouTube":
        return (
          <YoutubeVideo
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            comentarios={comentarios}
            setComentarios={setComentarios}
            impressoes={impressoes}
            setImpressoes={setImpressoes}
            dislikes={dislikes}
            setDislikes={setDislikes}
            tempoReproducaoHora={tempoReproducaoHora}
            setTempoReproducaoHora={setTempoReproducaoHora}
            tempoReproducaoMinuto={tempoReproducaoMinuto}
            setTempoReproducaoMinuto={setTempoReproducaoMinuto}
            tempoReproducaoSegundo={tempoReproducaoSegundo}
            setTempoReproducaoSegundo={setTempoReproducaoSegundo}
            views={viewsTotal}
            setViews={setViewsTotal}
            espectadores={espectadores}
            setEspectadores={setEspectadores}
          />
        );
      case "PublicacaoYouTubeShorts":
        return (
          <YoutubeShorts
            aprovar={aprovar}
            desabilitarCampos={desabilitarCampos}
            likes={likes}
            setLikes={setLikes}
            comentarios={comentarios}
            setComentarios={setComentarios}
            impressoes={impressoes}
            setImpressoes={setImpressoes}
            dislikes={dislikes}
            setDislikes={setDislikes}
            views={viewsTotais}
            setViews={setViewsTotais}
            espectadores={espectadores}
            setEspectadores={setEspectadores}
          />
        );
    }
  };

  return (
    <ModalTeste
      modalAberto={modalAberto}
      fecharModal={() => {
        // setRecarregar((state) => !state);
        setModalAberto(false);
      }}
      setModalAberto={setModalAberto}
      titulo="Adicionar Publicação"
      closeOnEscape={!imagemAberta}
    >
      <Toast ref={myToast} className="custom-toast" />

      <div className={styles.conteudoModalEditarTarefa}>
        <header>
          <div className={styles.containerNomePublicacao}>
            {tipoPublicacao === "PublicacaoInstagramFeed" && (
              <h1>Instagram - Feed</h1>
            )}
            {tipoPublicacao === "PublicacaoInstagramReels" && (
              <h1>Instagram - Reels</h1>
            )}
            {tipoPublicacao === "PublicacaoInstagramStories" && (
              <h1>Instagram - Stories</h1>
            )}
            {tipoPublicacao === "PublicacaoTikTok" && <h1>TikTok</h1>}
            {tipoPublicacao === "PublicacaoTwitter" && <h1>Twitter</h1>}
            {tipoPublicacao === "PublicacaoYouTube" && <h1>YouTube - Video</h1>}
            {tipoPublicacao === "PublicacaoYouTubeShorts" && (
              <h1>YouTube - Shorts</h1>
            )}
          </div>

          <div className={styles.campoURL}>
            <Input
              type="url"
              required
              disabled={aprovar}
              maxLength={2000}
              onClick={() => {
                aprovar && window.open(urlPublicacao);
              }}
              placeholder="URL da Publicação"
              value={urlPublicacao}
              onChange={(e) => {
                setUrlPublicacao(e.target.value);
              }}
            />
            {URLRequired && (
              <p className={styles.mensagemErro}>
                A url da publicação é obrigatória.
              </p>
            )}
            {printMetricaRequired && (
              <p className={styles.mensagemErro}>
                Prints de métrica são obrigatórios.
              </p>
            )}

            {(tipoPublicacao === "PublicacaoInstagramFeed" ||
              tipoPublicacao === "PublicacaoInstagramReels" ||
              tipoPublicacao === "PublicacaoInstagramStories") &&
              !aprovar &&
              !(perfil === "Administrador" || perfil === "UsuarioNetcos") && (
                <div className={styles.botaoOptIn}>
                  {urlPublicacao === "" ? (
                    <InstagramLoginButton
                      text="Buscar métricas"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 25,
                        width: 165,
                        height: 40,
                        fontSize: 14,
                        filter: "opacity(0.30)",
                      }}
                    />
                  ) : (
                    <LoginSocialFacebook
                      appId="741129074259476"
                      scope="
                      instagram_basic,
                      instagram_manage_insights,
                      pages_show_list,
                      public_profile,
                      business_management,
                      read_insights,
                      "
                      fieldsProfile="id,
                        first_name,
                        last_name,
                        middle_name,
                        name,
                        name_format,
                        picture,
                        short_name,
                        email,
                        gender,
                        permissions
                        "
                      onResolve={(response) => {
                        // handleLogin(response, pegarMetricas, publicacaoSelecionada, urlPublicacao);
                        pegarMetricas(
                          response.data!.accessToken,
                          publicacaoSelecionada.tipo,
                          urlPublicacao,
                          response.data!.userID,
                        );
                        console.log('login fb');
                        setDesabilitarCampos(true);
                        setFezOptin(true);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      {statesOptinPreenchidas === true ? (
                        <p>
                          {" "}
                          Métricas coletadas automaticamente junto à Meta após
                          opt-in pelo influenciador.
                        </p>
                      ) : (
                        ""
                      )}
                      <InstagramLoginButton
                        text="Buscar métricas"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 25,
                          width: 165,
                          height: 40,
                          fontSize: 14,
                        }}
                      />
                    </LoginSocialFacebook>
                  )}
              
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      setModalAvisoOptIn(true);
                    }}
                  >
                    <AiOutlineInfoCircle
                      color="#698bec"
                      size={20}
                      style={{ marginTop: "1rem", cursor: "pointer" }}
                    />
                  </button>
                  {modalAvisoOptIn && (
                    <AvisoOptIn
                      modalAberto={modalAvisoOptIn}
                      setModalAberto={setModalAvisoOptIn}
                    />
                  )}
                </div>
                // <button
                //   className={styles.botaoOptin}
                //   onClick={() => {
                //     pegarMetricas(
                //       publicacaoSelecionada.tipo,
                //       urlPublicacao,
                //       userID                      
                //     );
                
                //   }}
                //   >
                //   buscar métricas
                // </button>
              )}
            <div className={styles.campoDataPublicacao}>
              {dataInclusaoStories ? (
                <p>{DateFormatter(dataInclusaoStories)}</p>
              ) : (
                <Calendar
                  className={styles.calendario}
                  disabled={aprovar}
                  dateFormat="dd/mm/yy"
                  locale="pt-br"
                  placeholder="Data da Publicacao"
                  value={dataPublicacao}
                  required
                  onChange={(e) => setDataPublicacao(e.value)}
                  showIcon
                  style={{
                    outline: "none",
                    boxShadow: "none",
                  }}
                />
              )}
            </div>
          </div>
        </header>

        <div
          className={
            !aprovar ? styles.containerInputs : styles.containerPrintsSozinhos
          }
        >
          <div id={styles.containerInputPrints}>
            <TabView renderActiveOnly={false}>
              <TabPanel header="Prints da Publicação">
                <div className={styles.printsPublicacao}>
                  <div className={styles.containerUploadMaisCarrossel}>
                    {!aprovar && (
                      <UploadDragAndDrop
                        prints={publicacaoMidia}
                        setPrints={setPublicacaoMidia}
                      />
                    )}
                    <div className={styles.carrossel}>
                      {!carregarDelet ? (
                        <>
                          <CarrosselMetricas
                            prints={midiaDaPublicacaoCarrossel}
                            setImagemAberta={setImagemAberta}
                            imagemAberta={imagemAberta}
                            setImagemAtual={setUrlMidiaDaPublicacaoASerApagada}
                            carroselYoutube={publicacaoSelecionada.tipo
                              .toLocaleLowerCase()
                              .includes("youtube")}
                          />
                          <div
                            className={`${styles.containerButton} ${
                              midiaDaPublicacaoCarrossel.length
                                ? ""
                                : styles.hidden
                            }`}
                          >
                            <Button
                              importancia="terciario"
                              title="Deletar print"
                              onClick={() => {
                                deletarMidiaDeEvidencia(
                                  publicacaoSelecionada.midiasDeEvidencia!
                                );
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className={styles.containerCarregando}>
                          <Carregando botao />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel header="Prints das Métricas">
                <div className={styles.printsMetricas}>
                  <div className={styles.containerUploadMaisCarrossel}>
                    {!aprovar && (
                      <UploadDragAndDrop
                        prints={prints}
                        setPrints={setPrints}
                      />
                    )}
                    <div className={styles.carrossel}>
                      {!carregarDelet ? (
                        <>
                          <CarrosselMetricas
                            indicePrint={indicePrint}
                            prints={printsDeMetricaCarrossel}
                            setImagemAberta={setImagemAberta}
                            imagemAberta={imagemAberta}
                            setImagemAtual={setUrlPrintsDeMetricaASerApagada}
                            carroselYoutube={publicacaoSelecionada.tipo
                              .toLocaleLowerCase()
                              .includes("youtube")}
                          />
                          <div
                            className={`${styles.containerButton} ${
                              printsDeMetricaCarrossel.length
                                ? ""
                                : styles.hidden
                            }`}
                          >
                            <Button
                              importancia="terciario"
                              title="Deletar print"
                              onClick={() => {
                                deletarPrintsDeMetrica(
                                  publicacaoSelecionada.printsDeMetrica!
                                );
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className={styles.containerCarregando}>
                          <Carregando botao />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>

          <div className={styles.containerInputMetricas}>
            <p>Métricas</p>

            <div className={styles.containerTituloMaisBotaoOCR}>
              <div className={styles.containerMetricasEbotaoLimpar}>
                {(tipoPublicacao === "PublicacaoInstagramFeed" ||
                  tipoPublicacao === "PublicacaoInstagramReels" ||
                  tipoPublicacao === "PublicacaoInstagramStories" ||
                  tipoPublicacao === "PublicacaoTikTok") &&
                  (carregandoBuscarMetricas ? (
                    <Carregando botao />
                  ) : (
                    <Button
                      id={styles.botaoBuscarMetricasPrints}
                      title="Buscar métricas pelos prints"
                      disabled={!prints || prints.length === 0}
                      importancia="terciario"
                      onClick={buscaMetricasPeloPrint}
                      iconLeft={<FileSearch size={18} />}
                      tooltip={
                        !prints || prints.length === 0
                          ? "Não há prints de métricas para serem analisados. Prints anteriores não podem ser analisados"
                          : "Busca os valores das métricas nos prints selecionados"
                      }
                    />
                  ))}
                <Button
                  tooltip={"Limpar campos"}
                  importancia="terciario"
                  onClick={limparStatesDeMetricas}
                  iconLeft={<Broom size={18} />}
                >
                  limpar campos
                </Button>
              </div>
            </div>

            {handleInputsDeMetricas(tipoPublicacao)}
          </div>
        </div>

        <div className={styles.containerBotoesModal}>
          {carregando ? (
            <Carregando botao />
          ) : (
            <Button
              onClick={() => {
                editarMetricasPublicacaoInfluenciador(
                  true,
                  dataInclusaoStories
                );
              }}
              importancia="primario"
              title={aprovar ? "Aprovar" : "Adicionar"}
            />
          )}

          {!influenciador && (
            <>
              {carregando ? (
                <Carregando botao />
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setAbrirModalComentario(true);
                    }}
                    importancia="secundario"
                    title="Solicitar Ajustes"
                  />

                  <Dialog
                    className={styles.modalComentarioNetcos}
                    header={headerComentarioNetcos}
                    visible={abrirModalComentario}
                    onHide={() => setAbrirModalComentario(false)}
                    modal={false}
                    keepInViewport
                    closable
                    closeOnEscape
                    dismissableMask
                    footer={footerComentarioNetcos}
                  >
                    <div>
                      <Input
                        type="text"
                        min={0}
                        required
                        placeholder="Comentário"
                        value={comentariosNetcos}
                        onChange={(e) => {
                          setComentariosNetcos(e.target.value);
                        }}
                      />
                    </div>
                  </Dialog>
                </>
              )}
            </>
          )}

          {/* {!carregando && (
            <Button
              onClick={() => {
                setModalAberto(false);
              }}
              importancia="terciario"
              className="botao-limpar"
            >
              Cancelar
            </Button>
          )} */}
        </div>
      </div>
    </ModalTeste>
  );
};
