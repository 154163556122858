import { FuncoesTabelaComparativoRedesSociais } from "./functions";
import { redesSociaisProps } from "../../Graficos/GraficoResultadosGeraisPorRedeSocial/functions";
import { tarefaProps } from "../../../services/tarefas";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { format } from "../../../utils/MascaraNumero/format";

export interface TabelaAnaliseDoFormadoProps {
  tarefas: tarefaProps[];
  redeSocial: redesSociaisProps;
}

export const TabelaAnaliseDoFormado = ({
  tarefas,
  redeSocial
}: TabelaAnaliseDoFormadoProps) => {
  const [tarefasTabela, setTarefasTabela] = useState<any[]>([])

  const {
    criaObjetoTabela,
    identificaTop3
  } = FuncoesTabelaComparativoRedesSociais({
    tarefas,
    setTarefasTabela
  });

  useEffect(() => {
    criaObjetoTabela();
  }, [])


  return (
    <table className={styles.tabela}>
      <thead>
        <tr>
          <th className={`${styles.headerTabela}`}>
            Influenciador
          </th>

          <th className={`${styles.headerTabela}`} >
            Taxa de Engajamento
          </th>

          <th className={`${styles.headerTabela}`}>
            Impressões
          </th>

          <th className={`${styles.headerTabela}`}>
            Interações
          </th>

          {
            (redeSocial === "Instagram" || redeSocial === "TikTok") &&
            <th className={`${styles.headerTabela}`}>
              Compartilhamentos
            </th>
          }
        </tr>
      </thead>

      <tbody>
        {tarefasTabela.map((tarefa) => {
          return (
            <>
              {(tarefa.totalPosts[redeSocial.toLowerCase()] > 0) &&
                <tr>
                  {/* Nome */}
                  <td>
                    {tarefa.nomeInfluenciador}
                  </td>

                  {/* Taxa de Engajamento */}
                  <td className={
                    styles[
                    `${identificaTop3(tarefa.taxaEngajamento[redeSocial.toLowerCase()], tarefasTabela.map(tarefa => tarefa.taxaEngajamento[redeSocial.toLowerCase()]))}`
                    ]}>
                    {
                      tarefa.taxaEngajamento[redeSocial.toLowerCase()] !== 0 &&
                      format("#.###.##0,#0%",
                        tarefa.taxaEngajamento[redeSocial.toLowerCase()]
                      )
                    }
                  </td>

                  {/* Impressões */}
                  <td
                    className={
                      styles[
                      `${identificaTop3(tarefa.impressoes[redeSocial.toLowerCase()], tarefasTabela.map(tarefa => tarefa.impressoes[redeSocial.toLowerCase()]))}`
                      ]}>
                    {
                      tarefa.impressoes[redeSocial.toLowerCase()] !== 0 &&
                      format("#.###.##0,#0",
                        tarefa.impressoes[redeSocial.toLowerCase()]
                      ).replace(",00", "")
                    }
                  </td>
                  
                  {/* Interações */}
                  <td

                    className={
                      styles[
                      `${identificaTop3(tarefa.interacoes[redeSocial.toLowerCase()], tarefasTabela.map(tarefa => tarefa.interacoes[redeSocial.toLowerCase()]))}`
                      ]}
                  >
                    {
                      tarefa.interacoes[redeSocial.toLowerCase()] !== 0 &&
                      format("#.###.##0,#0",
                        tarefa.interacoes[redeSocial.toLowerCase()]
                      ).replace(",00", "")
                    }
                  </td>

                  {/* Compartilhamentos */}
                  {
                    (redeSocial === "Instagram" || redeSocial === "TikTok") &&
                    <td
                      className={
                        styles[
                        `${identificaTop3(tarefa.compartilhamentos[redeSocial.toLowerCase()], tarefasTabela.map(tarefa => tarefa.compartilhamentos[redeSocial.toLowerCase()]))}`
                        ]}>
                      {
                        tarefa.compartilhamentos[redeSocial.toLowerCase()] !== 0 &&
                        format("#.###.##0,#0",
                          tarefa.compartilhamentos[redeSocial.toLowerCase()]
                        ).replace(",00", "")
                      }
                    </td>
                  }
                </tr>
              }
            </>
          )
        })}

      </tbody>
      <div className={styles.legenda}>

        <div className={`${styles.legendaItem} ${styles.top1}`}></div><div>Maior valor</div>

        <div className={`${styles.legendaItem} ${styles.top2}`}></div><div>Segundo maior valor</div>

        <div className={`${styles.legendaItem} ${styles.top3}`}></div><div>Terceiro maior valor</div>

      </div>
    </table >


  );
};
