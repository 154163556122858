import { useState } from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';
import { getMarcaProps } from '../../../services/marca';


import styles from './styles.module.css';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { BotaoAtualizarMarcaLista } from '../../Botoes/BotaoAtualizarMarcaLista';
import { BotaoColunaAcoes } from '../../Botoes/BotaoColunaAcoes';

export interface CardMarcaProps {
  marca: getMarcaProps;
  setRecarregar: () => void;
  setIdMarca: React.Dispatch<React.SetStateAction<string>>;
  setNomeMarca: React.Dispatch<React.SetStateAction<string>>;
  setLogotipoMarca: React.Dispatch<React.SetStateAction<string>>;
  setModalAbertoUsuarioMarca: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;

  index: number;
}

export const CardMarca = ({ marca, setRecarregar, setIdMarca, setNomeMarca, setLogotipoMarca, setModalAbertoUsuarioMarca, setModalAbertoDesativacao, index }: CardMarcaProps) => {

  const [abrirCardMarca, setAbrirCardMarca] = useState<boolean>(false);

  return (
    <>
      <div
        className={styles.cardMarca}
      >
        <header className={styles.headerCardMarca} onClick={() => setAbrirCardMarca(!abrirCardMarca)}
        >
          <p>{marca.nome}</p>
          {marca.ativo ? (
            <div
              className={`${styles.status} ${styles.statusAtivo}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--verde-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          ) : (

            <div className={`${styles.status} ${styles.statusEncerrado}`}
            >
              <BsRecordCircleFill
                className='iconeStatus'
                style={{
                  color: 'var(--cinza-400)',
                  fontSize: '1rem',
                }}
              />
            </div>
          )}
        </header>
        <body className={abrirCardMarca ? styles.bodyCardMarcaAberto : styles.bodyCardMarcaFechado}>

          <div className={styles.detalhesCard}>
            <div className={styles.itemCard}>
              <p>Número de Campanhas:</p>{marca.totalCampanhas}
            </div>

            <div className={styles.itemCard}>
              <p>Número de Usuários:</p>{marca.totalUsuarios}
            </div>

            <div className={styles.campoAcoes}>
              <BotaoAtualizarMarcaLista
                idMarca={marca.id}
                nomeMarca={marca.nome}
                logotipo={marca.urlLogotipo}
                setRecarregar={setRecarregar}
                status={marca.ativo}
              />
              <BotaoColunaAcoes
                status={!marca.ativo ? "Removido" : ""}
                icone={"pi-user-plus"}
                titulo={"Adicionar Usuário"}
                onClick={() => {
                  setModalAbertoUsuarioMarca(true);
                }}
              />
              <BotaoColunaAcoes
                status={!marca.ativo ? "Removido" : ""}
                icone={"pi-trash"}
                titulo={"Remover Marca"}
                onClick={() => {
                  setIdMarca(marca.id);
                  setNomeMarca(marca.nome);
                  setLogotipoMarca(marca.urlLogotipo);
                  setModalAbertoDesativacao(true);
                }}
              />
            </div>
          </div>

        </body>
        <div
          className={abrirCardMarca ? styles.footerCard : styles.footerCardFechado}
          onClick={() => setAbrirCardMarca(!abrirCardMarca)}
        >
          <div
            className={styles.botaoSetinha}
          >
            {
              !abrirCardMarca ?
                <MdExpandMore />
                :
                <MdExpandLess />
            }
          </div>
        </div>

      </div>
    </>
  )
}