import { AiOutlineRetweet } from 'react-icons/ai';
import { RiBarChartFill } from "react-icons/ri";
import { format } from '../../../../utils/MascaraNumero/format';
import styles from './styles.module.css';

interface DadosLateraisTwitterProps {
  impressoes: number;
  engajamento: number;
  comentarios: number;
  retweets: number;
  favoritos: number;
  respostas: number;
  views: number;
  cliquesLink: number;
  cliquesHashtag: number;
  detailExpands: number;
}

export const DadosLateraisTwitter = ({
  impressoes,
  engajamento,
  comentarios,
  retweets,
  favoritos,
  respostas,
  views,
  cliquesLink,
  cliquesHashtag,
  detailExpands,
}: DadosLateraisTwitterProps) => {

  return (
    <div className={styles.dadosResultados}>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-hashtag"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Cliques na Hashtag</h1>
          <p>{format("#.###.##0,#0",cliquesHashtag).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-link"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Cliques no Link</h1>
          <p>{format("#.###.##0,#0",cliquesLink).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-comments"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Comentários</h1>
          <p>{format("#.###.##0,#0",comentarios).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <RiBarChartFill
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        />
        <div>
          <h1>Detail Expands</h1>
          <p>{format("#.###.##0,#0",detailExpands).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-chart-line"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Engajamento</h1>
          <p>{format("#.###.##0,#0",engajamento).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-heart"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Favoritos</h1>
          <p>{format("#.###.##0,#0",favoritos).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-user-plus"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Impressões</h1>
          <p>{format("#.###.##0,#0",impressoes).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-reply"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Respostas</h1>
          <p>{format("#.###.##0,#0",respostas).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <AiOutlineRetweet
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)"
          }}
        />
        <div>
          <h1>Retweets</h1>
          <p>{format("#.###.##0,#0",retweets).replace(",00","")}</p>
        </div>
      </div>

      <div className={styles.dadoResultado}>
        <i
          className="pi pi-eye"
          style={{
            fontSize: "2rem",
            fontWeight: "500",
            color: "var(--roxo-500)",
          }}
        ></i>
        <div>
          <h1>Views</h1>
          <p>{format("#.###.##0,#0",views).replace(",00","")}</p>
        </div>
      </div>

    </div>
  )

}