import { useState } from "react";

import { TabPanel, TabView } from "primereact/tabview";
import { UploadDragAndDrop } from "../../../components/UploadDragAndDrop";

// import "./styles.css";
import styles from './styles.module.css'

import { ModalCadastroUsuarioMarca } from "../../../components/Modais/ModalCadastroUsuarioMarca";
import { ModalAdcRepercussão } from "../../../components/Modais/ModalAdcRepercussão";

export const CadastroInfluenciador = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [arquivoPrint, setArquivoPrint] = useState<string>('')
  const [comentarioPositivo, setcomentarioPositivo] = useState<string>('')
  const [comentarioNegativo, setcomentarioNegativo] = useState<string>('') 
  const [activeIndex, setActiveIndex] = useState(0); 
 
  return (
    <div>
      <button onClick={() => setModal(!modal)}>Modal</button>
     

      {/* <div className={styles.conteudoModalAdcRepercussao}>
        <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
                setcomentarioPositivo('');
                setcomentarioNegativo('');
            }}
        />
        <div id={styles.containerTabView}>
            <TabView>
                <TabPanel header="Positivo">
                    <UploadDragAndDrop file={setArquivoPrint} />
                </TabPanel>

                <TabPanel header="Negativo">
                    <UploadDragAndDrop file={setArquivoPrint} />
                </TabPanel>
        </TabView>
        </div>
      </div> */}
    </div>
  );
};