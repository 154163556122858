import { useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsBotaoCadastroAgencia } from "./functions";

import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { Input } from "../../Input";
import { Button } from "../Button";
import { ModalTeste } from "../../Modais/ModalTeste";

import { Avatar } from "primereact/avatar";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface BotaoCadastrarAgenciaProps {
  botaoAbertoAgencia: boolean;
  setBotaoAbertoAgencia: React.Dispatch<React.SetStateAction<boolean>>;
  setImg: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  modal?: boolean;
}

export const BotaoCadastrarAgencia = ({
  botaoAbertoAgencia,
  setBotaoAbertoAgencia,
  setImg,
  setRecarregar,
  modal,
}: BotaoCadastrarAgenciaProps) => {
  const [nome, setNome] = useState<string>("");
  const [logotipo, setLogotipo] = useState<string>("");

  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { salvarAgenciaButton } = FunctionsBotaoCadastroAgencia({
    logotipo,
    setLogotipo,
    nome,
    setNome,
    setCarregando,
    setRecarregar,
    botaoAbertoAgencia,
    setBotaoAbertoAgencia,
    adicionarAListaEsperaRenovacaoToken,
  });

  const Conteudo = (
    <>
      {carregando ? (
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }} />
      ) : (
        <div className={styles.caixaDeInputs}>
          {logotipo ? (
            <Avatar
              className={styles.avatar}
              image={logotipo}
              title="Inserir Imagem"
              shape="circle"
              size="large"
              icon={"pi pi-user"}
            />
          ) : (
            <label className={styles.labelInput} htmlFor={styles.fileUpload}>
              <div className={styles.botaoConfirmar}>
                <i
                  className="pi pi-camera"
                  style={{
                    fontSize: "2rem",
                    paddingRight: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                  title="Inserir Imagem"
                ></i>
              </div>
              <input
                id={styles.fileUpload}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  return (
                    converteImgPara64(e, setLogotipo),
                    converteImgPara64(e, setImg)
                  );
                }}
              />
            </label>
          )}

          <Input
            required
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />

          <div className={styles.botaoConfirmar}>
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "2rem" }}
              onClick={salvarAgenciaButton}
              title="Cadastrar"
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {modal ? (
        <ModalTeste
          titulo="Cadastrar Agencia"
          modalAberto={botaoAbertoAgencia}
          setModalAberto={setBotaoAbertoAgencia}
        >
          {Conteudo}
        </ModalTeste>
      ) : (
        <div className={styles.campoCadastroAgencia}>
          <Inplace active={botaoAbertoAgencia} closable>
            <InplaceDisplay>
              <Button
                importancia="secundario"
                iconLeft={
                  <i
                    className="pi pi-plus-circle"
                    style={{ fontSize: "1.8rem" }}
                  />
                }
                title="Cadastrar Agência"
              ></Button>
            </InplaceDisplay>
            <InplaceContent>{Conteudo}</InplaceContent>
          </Inplace>
        </div>
      )}
    </>
  );
};
