import { useRef, useState, useContext } from "react";

import { TabelaListarMarcas } from "../../../components/Tabelas/TabelaListarMarcas";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";

import { MarcaContext } from "../../../context/MarcaContext";

import { Toast } from "primereact/toast";

export const ListarMarcas = () => {
  const [nomeMarcaPesquisa, setNomeMarcaPesquisa] = useState<string>("");

  const {
    carregandoListaDeMarcas,
    erroListaDeMarcas,
    listaDeMarcas,
    mudarNomeDaPropriedadeDeOrdenacao,
    mudarTipoOrdenacao,
    recarregarListaDeMarcas,
    nomeDaPropriedadeDeOrdenacao,
    tipoDeOrdenacao,
  } = useContext(MarcaContext);

  const myToast = useRef<any>(null);

  const colunas: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Campanhas", ordenacao: "TotalCampanhas" },
    { nome: "Usuários", ordenacao: "TotalUsuarios" },
    { nome: "Status", ordenacao: "Ativo" },
    { nome: "Ações", ordenacao: "" },
  ];

  return (
    <>
      <TabelaListarMarcas
        carregando={carregandoListaDeMarcas}
        myToast={myToast}
        tipoOrdenacao={tipoDeOrdenacao}
        setTipoOrdenacao={mudarTipoOrdenacao}
        setNomePropriedadeOrdenacao={mudarNomeDaPropriedadeDeOrdenacao}
        nomePropriedadeOrdenacao={nomeDaPropriedadeDeOrdenacao}
        setNomeMarcaPesquisa={setNomeMarcaPesquisa}
        setRecarregar={recarregarListaDeMarcas}
        erro={erroListaDeMarcas}
        tituloTabela={"Marcas"}
        tituloColuna={colunas}
        listaMarcas={listaDeMarcas.filter((marca) =>
          marca.nome
            .toLowerCase()
            .includes(nomeMarcaPesquisa.toLowerCase().trim())
        )}
      />
      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
