//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { campanha } from "../../../../services/campanha";
import {
  Influenciador,
  PublicacoesProps,
} from "../../../../services/publicacao";
import { DateFormatter } from "../../../../utils/DataFormatter";
import { converterImagemParaBase64 } from "./functions";
import {
  base64RelatorioInicio,
  base64RelatorioPublicacao,
} from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";

export async function gerarRelatorioImagensPublicacao(
  publicacao: PublicacoesProps,
  influenciador: Influenciador,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        TipoPublicacao: {
          type: "text",
          position: {
            x: 130.97,
            y: 4.5,
          },
          width: 63.3,
          height: 6.99,
          alignment: "center",
          fontSize: 18,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        NomeInflu: {
          type: "text",
          position: {
            x: 252.76,
            y: 1.85,
          },
          width: 75.73,
          height: 8.85,
          alignment: "center",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        DataInclusao: {
          type: "text",
          position: {
            x: 246.07,
            y: 11.9,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 15,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        UrlPublicacao: {
          type: "text",
          position: {
            x: 58,
            y: 175,
          },
          width: 229,
          height: 7,
          alignment: "center",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        ImagemPubli1: {
          type: "image",
          position: {
            x: 44.45,
            y: 27.51,
          },
          width: 74.68,
          height: 136.07,
        },
        ImagemPubli2: {
          type: "image",
          position: {
            x: 131.71,
            y: 27.46,
          },
          width: 74.68,
          height: 136.07,
        },
        ImagemPubli3: {
          type: "image",
          position: {
            x: 218.5,
            y: 27.41,
          },
          width: 74.68,
          height: 136.07,
        },
      },
    ],
    basePdf: base64RelatorioPublicacao,
  };

  var printMetrica = publicacao.printsDeMetrica;

  let tipoPublicacaoAtual = "";

  switch (publicacao.tipo.toString()) {
    case "PublicacaoInstagramFeed":
      tipoPublicacaoAtual = "Instagram Feed"
      break;
    case "PublicacaoInstagramReels":
      tipoPublicacaoAtual = "Instagram Reels"
      break;
    case "PublicacaoInstagramStories":
      tipoPublicacaoAtual = "Instagram Stories"
      break;
    case "PublicacaoTikTok":
      tipoPublicacaoAtual = "Tiktok"
      break;
    case "PublicacaoTwitter":
      tipoPublicacaoAtual = "Twitter"
      break;
    case "PublicacaoYouTube":
      tipoPublicacaoAtual = "Youtube"
      break;
    case "PublicacaoYouTubeShorts":
      tipoPublicacaoAtual = "Youtube Shorts"
      break;

    default:
      break;
  }

  const inputs = [
    {
      TipoPublicacao: tipoPublicacaoAtual,
      NomeInflu: influenciador.nome,
      DataInclusao: DateFormatter(publicacao.dataFinalizacao),
      UrlPublicacao: "Link direto para a publicação original: " + publicacao.urlPublicacao!,
      ImagemPubli1: !printMetrica
        ? ""
        : printMetrica[1]
          ? await converterImagemParaBase64(
            printMetrica[1].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
      ImagemPubli2: !printMetrica
        ? ""
        : printMetrica[0]
          ? await converterImagemParaBase64(
            printMetrica[0].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
      ImagemPubli3: !printMetrica
        ? ""
        : printMetrica[2]
          ? await converterImagemParaBase64(
            printMetrica[2].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
    },
  ];

  if (
    inputs[0].ImagemPubli1 === "AxiosError" ||
    inputs[0].ImagemPubli2 === "AxiosError" ||
    inputs[0].ImagemPubli3 === "AxiosError"
  ) {
    return "AxiosError";
  }

  const font: Font = {
    exo: {
      //data: readFileSync('./Exo-VariableFont_wght.ttf'),
      data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
      fallback: true
    }
  };

  const plugins = { text, image };

  await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
    // Browser
    var blob10 = new Blob([pdf.buffer], { type: "application/pdf" });
    listaDePdfs.push(blob10);
  });
}
