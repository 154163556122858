import { log } from "console";
import { PublicacoesProps, publicacaoProps } from "../../../services/publicacao";
import { calculaEngajamentoYoutube, somaMetrica } from "../../../utils/somaMetrica";

interface FuncoesTabelaMetricasPorInfluenciadorProps {
  publicacoes: PublicacoesProps[];

  setPublicacaoIgFeed: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoIgReels: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoIgStories: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoTwitter: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoTikTok: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoYouTubeVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoYouTubeShort: React.Dispatch<React.SetStateAction<boolean>>;

  // alcance
  setAlcanceIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setAlcanceIgReels: React.Dispatch<React.SetStateAction<number>>;
  setAlcanceIgStories: React.Dispatch<React.SetStateAction<number>>;
  setAlcanceTikTok: React.Dispatch<React.SetStateAction<number>>;

  //impressões
  setImpressoesIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesIgStories: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesTwitter: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesYouTubeShort: React.Dispatch<React.SetStateAction<number>>;

  //Views
  setViewsIgReels: React.Dispatch<React.SetStateAction<number>>;
  setViewsTwitter: React.Dispatch<React.SetStateAction<number>>;
  setViewsTikTok: React.Dispatch<React.SetStateAction<number>>;
  setViewsYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setViewsYouTubeShort: React.Dispatch<React.SetStateAction<number>>;

  //Engajamento
  setEngajamentoIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoIgReels: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoIgStories: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoTwitter: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoTikTok: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoYouTubeShort: React.Dispatch<React.SetStateAction<number>>;

  //Espectadores
  setEspectadoresYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setEspectadoresYouTubeShort: React.Dispatch<React.SetStateAction<number>>;

  //Comentários
  setComentariosYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setComentariosYouTubeShort: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTiktok: React.Dispatch<React.SetStateAction<number>>;
  setComentariosIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setComentariosIgReels: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTwitter: React.Dispatch<React.SetStateAction<number>>;

  //Taxa de aprovação
  setTaxaDeAprovacaoYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setTaxaDeAprovacaoYouTubeShort: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaMetricasPorInfluenciador = ({
  setAlcanceIgFeed,
  setAlcanceIgReels,
  setAlcanceIgStories,
  setAlcanceTikTok,
  setComentariosYouTubeShort,
  setComentariosYouTubeVideo,
  setComentariosTiktok,
  setComentariosIgFeed,
  setComentariosIgReels,
  setComentariosTwitter,
  setEngajamentoIgFeed,
  setEngajamentoIgReels,
  setEngajamentoIgStories,
  setEngajamentoYouTubeShort,
  setEngajamentoYouTubeVideo,
  setEngajamentoTikTok,
  setEngajamentoTwitter,
  setEspectadoresYouTubeVideo,
  setEspectadoresYouTubeShort,
  setImpressoesIgFeed,
  setImpressoesIgStories,
  setImpressoesTwitter,
  setImpressoesYouTubeShort,
  setImpressoesYouTubeVideo,
  setPublicacaoIgFeed,
  setPublicacaoIgReels,
  setPublicacaoIgStories,
  setPublicacaoTikTok,
  setPublicacaoTwitter,
  setPublicacaoYouTubeShort,
  setPublicacaoYouTubeVideo,
  setTaxaDeAprovacaoYouTubeShort,
  setTaxaDeAprovacaoYouTubeVideo,
  setViewsIgReels,
  setViewsTikTok,
  setViewsTwitter,
  setViewsYouTubeShort,
  setViewsYouTubeVideo,
  publicacoes,
}: FuncoesTabelaMetricasPorInfluenciadorProps) => {


  function setaDadosDaTabela() {
    let PublicacaoIgFeed = 0;
    let PublicacaoIgReels = 0;
    let PublicacaoIgStories = 0;
    let PublicacaoTikTok = 0;
    let PublicacaoTwitter = 0;
    let PublicacaoYouTubeShort = 0;
    let PublicacaoYouTubeVideo = 0;

    let AlcanceIgFeed = 0;
    let AlcanceIgReels = 0;
    let AlcanceIgStories = 0;
    let AlcanceTikTok = 0;
    let ComentariosYouTubeShort = 0;
    let ComentariosYouTubeVideo = 0;
    let ComentariosIgFeed = 0
    let ComentariosIgReels = 0
    let ComentariosTiktok = 0
    let ComentariosTwitter = 0
    let EngajamentoIgFeed = 0;
    let EngajamentoIgReels = 0;
    let EngajamentoIgStories = 0;
    let EngajamentoTikTok = 0;
    let EngajamentoTwitter = 0;
    let EngajamentoYouTubeVideo = 0;
    let EngajamentoYouTubeShort = 0;
    let EspectadoresYouTubeShort = 0;
    let EspectadoresYouTubeVideo = 0;
    let ImpressoesIgFeed = 0;
    let ImpressoesIgStories = 0;
    let ImpressoesTwitter = 0;
    let ImpressoesYouTubeShort = 0;
    let ImpressoesYouTubeVideo = 0;
    let LikesYouTubeShort = 0;
    let LikesYouTubeVideo = 0;
    let DislikesYouTubeShort = 0;
    let DislikesYouTubeVideo = 0;
    let ViewsIgReels = 0;
    let ViewsTikTok = 0;
    let ViewsTwitter = 0;
    let ViewsYouTubeShort = 0;
    let ViewsYouTubeVideo = 0;
    let somaLikesYouTubeVideo = 0;
    let somaLikesYouTubeShorts = 0;
    let somaComentariosYouTubeVideo = 0;
    let somaComentariosYouTubeShorts = 0;

    let terminouMap = publicacoes.map((publicacao) => {
      if (publicacao.status === "Aprovado") {
        switch (publicacao.tipo) {
          case "PublicacaoInstagramFeed":
            PublicacaoIgFeed = somaMetrica(PublicacaoIgFeed, 1);
            AlcanceIgFeed = somaMetrica(AlcanceIgFeed, publicacao.alcance);
            ImpressoesIgFeed = somaMetrica(ImpressoesIgFeed, publicacao.impressoes);
            EngajamentoIgFeed = somaMetrica(EngajamentoIgFeed, publicacao.likes);
            ComentariosIgFeed = somaMetrica(ComentariosIgFeed, publicacao.comentarios);
            EngajamentoIgFeed = somaMetrica(EngajamentoIgFeed, publicacao.compartilhamentos);
            EngajamentoIgFeed = somaMetrica(EngajamentoIgFeed, publicacao.salvos);
            EngajamentoIgFeed = somaMetrica(EngajamentoIgFeed, publicacao.comentarios);
            break;
          case "PublicacaoInstagramReels":
            PublicacaoIgReels = somaMetrica(PublicacaoIgReels, 1);
            AlcanceIgReels = somaMetrica(AlcanceIgReels, publicacao.alcance);
            ViewsIgReels = somaMetrica(ViewsIgReels, publicacao.quantidadeReproducoes);
            EngajamentoIgReels = somaMetrica(EngajamentoIgReels, publicacao.likes);
            ComentariosIgReels = somaMetrica(ComentariosIgReels, publicacao.comentarios);
            EngajamentoIgReels = somaMetrica(EngajamentoIgReels, publicacao.compartilhamentos);
            EngajamentoIgReels = somaMetrica(EngajamentoIgReels, publicacao.salvos);
            EngajamentoIgReels = somaMetrica(EngajamentoIgReels, publicacao.comentarios);
            break;
          case "PublicacaoInstagramStories":
            PublicacaoIgStories = somaMetrica(PublicacaoIgStories, 1);
            AlcanceIgStories = somaMetrica(AlcanceIgStories, publicacao.alcance);
            ImpressoesIgStories = somaMetrica(ImpressoesIgStories, publicacao.impressoes);
            EngajamentoIgStories = somaMetrica(EngajamentoIgStories, publicacao.compartilhamentos);
            EngajamentoIgStories = somaMetrica(EngajamentoIgStories, publicacao.respostas);
            EngajamentoIgStories = somaMetrica(EngajamentoIgStories, publicacao.cliquesLink);
            EngajamentoIgStories = somaMetrica(EngajamentoIgStories, publicacao.cliquesStickers);
            EngajamentoIgStories = somaMetrica(EngajamentoIgStories, publicacao.likes);
            break;
          case "PublicacaoTikTok":
            PublicacaoTikTok = somaMetrica(PublicacaoTikTok, 1);
            AlcanceTikTok = somaMetrica(AlcanceTikTok, publicacao.espectadores);
            ViewsTikTok = somaMetrica(ViewsTikTok, publicacao.views);
            ViewsTikTok = somaMetrica(ViewsTikTok, publicacao.viewsTotais);
            ViewsTikTok = somaMetrica(ViewsTikTok, publicacao.viewsTotal);
            EngajamentoTikTok = somaMetrica(EngajamentoTikTok, publicacao.likes);
            ComentariosTiktok = somaMetrica(ComentariosTiktok, publicacao.comentarios);
            EngajamentoTikTok = somaMetrica(EngajamentoTikTok, publicacao.compartilhamentos);
            EngajamentoTikTok = somaMetrica(EngajamentoTikTok, publicacao.salvos);
            EngajamentoTikTok = somaMetrica(EngajamentoTikTok, publicacao.comentarios);
            break;
          case "PublicacaoTwitter":
            PublicacaoTwitter = somaMetrica(PublicacaoTwitter, 1);
            ImpressoesTwitter = somaMetrica(ImpressoesTwitter, publicacao.impressoes);
            ViewsTwitter = somaMetrica(ViewsTwitter, publicacao.views);
            ViewsTwitter = somaMetrica(ViewsTwitter, publicacao.viewsTotais);
            ViewsTwitter = somaMetrica(ViewsTwitter, publicacao.viewsTotal);
            ComentariosTwitter = somaMetrica(ComentariosTwitter, publicacao.comentarios);
            EngajamentoTwitter = somaMetrica(EngajamentoTwitter, publicacao.engajamento);
            break;
          case "PublicacaoYouTube":
            PublicacaoYouTubeVideo = somaMetrica(PublicacaoYouTubeVideo, 1);
            ImpressoesYouTubeVideo = somaMetrica(ImpressoesYouTubeVideo, publicacao.impressoes);
            ViewsYouTubeVideo = somaMetrica(ViewsYouTubeVideo, publicacao.views);
            ViewsYouTubeVideo = somaMetrica(ViewsYouTubeVideo, publicacao.viewsTotais);
            ViewsYouTubeVideo = somaMetrica(ViewsYouTubeVideo, publicacao.viewsTotal);
            EspectadoresYouTubeVideo = somaMetrica(EspectadoresYouTubeVideo, publicacao.espectadores);
            ComentariosYouTubeVideo = somaMetrica(ComentariosYouTubeVideo, publicacao.comentarios);
            LikesYouTubeVideo = somaMetrica(LikesYouTubeVideo, publicacao.likes);
            DislikesYouTubeVideo = somaMetrica(DislikesYouTubeVideo, publicacao.dislikes);
            somaLikesYouTubeVideo = somaMetrica(somaLikesYouTubeVideo, publicacao.likes);
            somaComentariosYouTubeVideo = somaMetrica(somaComentariosYouTubeVideo, publicacao.comentarios);
            EngajamentoYouTubeVideo = calculaEngajamentoYoutube(somaComentariosYouTubeVideo, somaLikesYouTubeVideo);
            break;
          case "PublicacaoYouTubeShorts":
            PublicacaoYouTubeShort = somaMetrica(PublicacaoYouTubeShort, 1);
            ImpressoesYouTubeShort = somaMetrica(ImpressoesYouTubeShort, publicacao.impressoes);
            ViewsYouTubeShort = somaMetrica(ViewsYouTubeShort, publicacao.views);
            ViewsYouTubeShort = somaMetrica(ViewsYouTubeShort, publicacao.viewsTotais);
            ViewsYouTubeShort = somaMetrica(ViewsYouTubeShort, publicacao.viewsTotal);
            EspectadoresYouTubeShort = somaMetrica(EspectadoresYouTubeShort, publicacao.espectadores);
            ComentariosYouTubeShort = somaMetrica(ComentariosYouTubeShort, publicacao.comentarios);
            LikesYouTubeShort = somaMetrica(LikesYouTubeShort, publicacao.likes);
            DislikesYouTubeShort = somaMetrica(DislikesYouTubeShort, publicacao.dislikes);
            somaLikesYouTubeShorts = somaMetrica(somaLikesYouTubeShorts, publicacao.likes);
            somaComentariosYouTubeShorts = somaMetrica(somaComentariosYouTubeShorts, publicacao.comentarios);
            EngajamentoYouTubeShort = calculaEngajamentoYoutube(somaLikesYouTubeShorts, somaComentariosYouTubeShorts);
            break;
        }
      }
      return true;
    });

    if (terminouMap.length > 0) {
      setAlcanceIgFeed(AlcanceIgFeed);
      setAlcanceIgReels(AlcanceIgReels);
      setAlcanceIgStories(AlcanceIgStories);
      setAlcanceTikTok(AlcanceTikTok);
      setComentariosYouTubeShort(ComentariosYouTubeShort);
      setComentariosYouTubeVideo(ComentariosYouTubeVideo);
      setComentariosTiktok(ComentariosTiktok);
      setComentariosIgFeed(ComentariosIgFeed);
      setComentariosIgReels(ComentariosIgReels);
      setComentariosTwitter(ComentariosTwitter);
      setEngajamentoIgFeed(EngajamentoIgFeed);
      setEngajamentoIgReels(EngajamentoIgReels);
      setEngajamentoIgStories(EngajamentoIgStories);
      setEngajamentoTikTok(EngajamentoTikTok);
      setEngajamentoTwitter(EngajamentoTwitter);
      setEngajamentoYouTubeVideo(EngajamentoYouTubeVideo);
      setEngajamentoYouTubeShort(EngajamentoYouTubeShort);
      setEspectadoresYouTubeVideo(EspectadoresYouTubeVideo);
      setEspectadoresYouTubeShort(EspectadoresYouTubeShort);
      setImpressoesIgFeed(ImpressoesIgFeed);
      setImpressoesIgStories(ImpressoesIgStories);
      setImpressoesTwitter(ImpressoesTwitter);
      setImpressoesYouTubeShort(ImpressoesYouTubeShort);
      setImpressoesYouTubeVideo(ImpressoesYouTubeVideo);
      setPublicacaoIgFeed(PublicacaoIgFeed > 0);
      setPublicacaoIgReels(PublicacaoIgReels > 0);
      setPublicacaoIgStories(PublicacaoIgStories > 0);
      setPublicacaoTikTok(PublicacaoTikTok > 0);
      setPublicacaoTwitter(PublicacaoTwitter > 0);
      setPublicacaoYouTubeShort(PublicacaoYouTubeShort > 0);
      setPublicacaoYouTubeVideo(PublicacaoYouTubeVideo > 0);
      setTaxaDeAprovacaoYouTubeShort(
        (LikesYouTubeShort / (LikesYouTubeShort + DislikesYouTubeShort)) * 100
      );
      setTaxaDeAprovacaoYouTubeVideo(
        (LikesYouTubeVideo / (LikesYouTubeVideo + DislikesYouTubeVideo)) * 100
      );
      setViewsIgReels(ViewsIgReels);
      setViewsTikTok(ViewsTikTok);
      setViewsTwitter(ViewsTwitter);
      setViewsYouTubeShort(ViewsYouTubeShort);
      setViewsYouTubeVideo(ViewsYouTubeVideo);
    }
  }




  return {
    setaDadosDaTabela,
  };
};
