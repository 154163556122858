//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioGraficoComparativoEntreInfluenciadores } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import { campanha } from "../../../../services/campanha";
import * as htmlToImage from 'html-to-image';
import ReactDOM from "react-dom";
import styles from "./styles.module.css";
import { TituloColuna } from "../../../../components/TituloColuna";
import { LinhaTarefaIncluida } from "../../../../components/Tabelas/TabelaListarTarefasIncluidas/antiga/LinhaTarefaIncluida";
import { format } from "../../../../utils/MascaraNumero/format";
import { IoTrendingDownSharp, IoTrendingUpSharp } from "react-icons/io5";
import { TabelaListarTarefasIncluidas } from "../../../../components/Tabelas/TabelaListarTarefasIncluidas/v2";


export async function gerarPaginaTabelaComparativoInfluenciadores(
  campanha: campanha,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const colunasTarefas = [
    { nome: "Influenciador", ordenacao: "", title: "" },
    { nome: "Alcance", ordenacao: "", title: "Total das métricas de alcance do Influenciador.\n-Para o formato TikTok é considerado a métrica Espectadores", hasToolTip: false },
    { nome: "Engajamento", ordenacao: "", title: "Total de engajamento de todos os formatos da campanha.", hasToolTip: false },
    { nome: "Taxa Engajamento", ordenacao: "", title: "(Total de interações da campanha / Total de impressões da campanha) * 100", hasToolTip: false },
    {
      nome: "Comparativo Campanha",
      ordenacao: "",
      title:
        "Diferença percentual entre a taxa de engajamento desse influenciador\nnessa campanha e a taxa de engajamento geral da campanha.",
      hasToolTip: false
    },
    { nome: "CPE", ordenacao: "", title: "Custo por Engajamento:\nValor Investido no Influenciador / Total de engajamento em todos os formatos", hasToolTip: false },
    { nome: "CPM", ordenacao: "", title: "Custo por Mil Impressões:\n(Valor Investido no Influenciador / Total de de Impressões do Influenciador) * 1000", hasToolTip: false },
    { nome: "Valor do Contrato", ordenacao: "", title: "" },
    // { nome: "Entregas", ordenacao: "", title: "" },
  ];

  const template: Template = {
    schemas: [
      {
        TabelaComparativoInflus: {
          type: "image",
          position: {
            x: 9,
            y: 32
          },
          width: 319.1,
          height: 134.11
        }
      }
    ],
    basePdf: base64RelatorioGraficoComparativoEntreInfluenciadores,
  };

  var imagemMarca = !campanha?.marcas[0].urlLogotipo
    ? ""
    : await converterImagemParaBase64(
      campanha.marcas[0].urlLogotipo,
      setErro,
      setCarregando
    );
  if (imagemMarca !== "AxiosError") {

    const tabelaComparativoInfluTemp = document.createElement('div');
    tabelaComparativoInfluTemp.style.width = "1120px";

    ReactDOM.render(
      <TabelaListarTarefasIncluidas
        idCampanha={campanha.id}
        listaDeTarefas={campanha.tarefas}
        nomePropriedadeOrdenacao="Nome"
        tipoOrdenacao="Ascending"
        taxaEngajamentoCampanha={campanha.mediaTaxaEngajamento}
        relatorio
      />
      // <div style={{ maxWidth: '1280px' }}>
      //   <table style={{ width: '100%' }}>
      //     <thead>
      //       <tr style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', fontSize: '1.4rem', alignItems: 'center', padding: '16px 0', borderBottom: '1px solid #e1e1e6' }}>
      //         {colunasTarefas.map((titulo) => {
      //           return (
      //             <th style={{ textAlign: 'center' }}>
      //               {titulo.nome}
      //             </th>
      //           );
      //         })}
      //       </tr>
      //     </thead>

      //     <tbody>
      //       {campanha.tarefas.map((tarefa) => {
      //         return (
      //           <tr style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', marginTop: '1rem' }}>
      //             <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
      //               {tarefa.influenciador.nome}
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {tarefa.alcanceTotalDaTarefa
      //                 ? format("#.###.##0,#0", tarefa.alcanceTotalDaTarefa).replace(
      //                   ",00",
      //                   ""
      //                 )
      //                 : 0}
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {tarefa.engajamentoTotalDaTarefa
      //                 ? format("#.###.##0,#0", tarefa.engajamentoTotalDaTarefa).replace(
      //                   ",00",
      //                   ""
      //                 )
      //                 : 0}
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {format("#.###.##0,#0", tarefa.taxaEngajamento)}%
      //             </td>
      //             <td style={{ display: 'flex', justifyContent: 'center', color: tarefa.taxaEngajamento - campanha.mediaTaxaEngajamento > 0 ? '#00AD2B' : '#d0312d' }}>
      //               {format("#.###.##0,#0", tarefa.taxaEngajamento - campanha.mediaTaxaEngajamento)}%
      //               <div style={{ marginLeft: '0.2rem' }}>
      //                 {tarefa.taxaEngajamento - campanha.mediaTaxaEngajamento >= 0 ? (
      //                   <IoTrendingUpSharp />
      //                 ) : (
      //                   <IoTrendingDownSharp />
      //                 )}
      //               </div>
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {tarefa.custoPorEngajamento
      //                 ? format("R$ #.###.##0,#0", tarefa.custoPorEngajamento)
      //                 : "R$ 0,00"}
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {format("R$ #.###.##0,#0", tarefa.custoPorMilImpressoes)}
      //             </td>
      //             <td style={{ textAlign: 'center' }}>
      //               {format("R$ #.###.##0,#0", tarefa.valorContrato)}
      //             </td>
      //           </tr>
      //         );
      //       })}

      //     </tbody>
      //   </table>
      // </div>
      // <div className={styles.containerCompleto}>
      //   <table className={styles.containerTabela}>
      //     <thead>
      //       <tr className={styles.tituloColuna}>
      //         {colunasTarefas.map((titulo) => {
      //           return (
      //             <TituloColuna
      //               key={colunasTarefas.indexOf(titulo)}
      //               colunaIndex={colunasTarefas.indexOf(titulo)}
      //               tituloColuna={titulo.nome}
      //               title={titulo.title}
      //               hasToolTip={titulo.hasToolTip}
      //               tituloOrdenacao={titulo.ordenacao}
      //               iconOrdenacao={[]}
      //               tipoOrdenacao={"Ascending"}
      //               nomePropriedadeOrdenacao={"Nome"}
      //               fontSize={"1.4rem"}
      //             />
      //           );
      //         })}
      //       </tr>
      //     </thead>

      //     <tbody
      //       id="tabelaInfluenciadoresDaCampanha"
      //       className={styles.corpoTabela}
      //     >
      //       <>
      //         {campanha.tarefas && campanha.tarefas.length === 0 ? (
      //           <tr>
      //             <td style={{ justifyContent: "center" }}>
      //               Nenhum resultado a ser exibido.
      //             </td>
      //           </tr>
      //         ) : (
      //           campanha.tarefas.map((tarefa) => {
      //             return (
      //               <>
      //                 <LinhaTarefaIncluida
      //                   idInfluenciador={tarefa.influenciador.idCredenciais}
      //                   idCampanha={campanha.id}
      //                   naoExibirImagemAvatar={true}
      //                   recarregarTabela={() => console.log("teste2")}
      //                   taxaEngajamento={campanha.mediaTaxaEngajamento}
      //                   tarefa={tarefa}
      //                   onClick={() => console.log("teste")}
      //                   removerColunaAcoes={true}
      //                 />
      //               </>
      //             );
      //           })
      //         )}
      //       </>
      //     </tbody>
      //     <tfoot className={styles.footerTabela}>
      //     </tfoot>
      //   </table>
      // </div>
      , tabelaComparativoInfluTemp);


    const tabelaComparativoInfluElement = tabelaComparativoInfluTemp as HTMLElement;
    tabelaComparativoInfluElement.style.backgroundColor = "#fff"

    document.body.appendChild(tabelaComparativoInfluElement);

    const inputs = [
      {
        TabelaComparativoInflus: await htmlToImage.toPng(tabelaComparativoInfluElement),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob2 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob2);
      document.body.removeChild(tabelaComparativoInfluElement);

    });
  } else {
    return "AxiosError";
  }

}
