import { useEffect, useRef, useState } from "react";

import { Prints } from "../../../services/publicacao";

import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";

import styles from "./styles.module.css";

interface CarrosselMetricasProps {
  prints: Prints[] | undefined;
  exibicao?: boolean;
  titulo?: string;
  indicePrint?: number;
  setImagemAberta?: React.Dispatch<React.SetStateAction<boolean>>;
  imagemAberta?: boolean;
  setImagemAtual?: React.Dispatch<React.SetStateAction<string>>;
  carroselYoutube?: boolean;
}

export const CarrosselMetricas = ({
  prints,
  exibicao,
  titulo,
  imagemAberta,
  setImagemAberta,
  setImagemAtual,
  carroselYoutube,
}: CarrosselMetricasProps) => {
  const [indicePrint, setIndicePrint] = useState<number>(0);
  const galleriaFullScreen = useRef<Galleria>(null);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  function simulateMouseClick(element: any) {
    element.dispatchEvent(
      new MouseEvent("mousedown", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  }

  document.onkeydown = (e) => {
    e.key === "ArrowLeft" &&
      setIndicePrint(indicePrint === 0 ? indicePrint : indicePrint - 1);
    e.key === "ArrowRight" &&
      setIndicePrint(indicePrint === prints!.length - 1 ? 0 : indicePrint + 1);
    e.key === "Escape" && galleriaFullScreen.current?.hide();
  };

  const headerTemplate = () => {
    return (
      <p className={styles.headerCardPrints}>
        {titulo ? titulo : exibicao ? "Prints de Métrica" : "Prints Anteriores"}
      </p>
    );
  };

  useEffect(() => {
    document.addEventListener("keydown", (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setImagemAberta && setImagemAberta(false);
        galleriaFullScreen.current?.hide();
      }
    });
  }, []);

  const itemTemplate = (item: any) => {
    return (
      <div className={styles.containerCardImg}>
        <Image
          src={item.urlImagem}
          imageClassName={styles.imagemCard}
          downloadable
          height={carroselYoutube ? "auto" : '100%'}
          width="100%"
          onClick={() => {
            galleriaFullScreen.current?.show();
            setImagemAberta && setImagemAberta(true);
          }}
          onHide={() => {
            galleriaFullScreen.current?.hide();
          }}
        />
      </div>
    );
  };

  const itemTemplateGrande = (item: any) => {
    return (
      <Image src={item.urlImagem} imageClassName={styles.imagemCardGrande} />
    );
  };
  useEffect(() => {
    if (prints?.length) setImagemAtual && setImagemAtual(prints[0].urlImagem!);
  }, []);

  useEffect(() => {
    setIndicePrint(0);
    if (prints?.length) setImagemAtual && setImagemAtual(prints[0].urlImagem!);
  }, [prints]);

  return (
    <div className={styles.containerCarrossel}>
      {headerTemplate()}
      <div className={styles.cardMetrica}>
        {prints && (
          <Galleria
            ref={galleriaFullScreen}
            value={prints.length ? prints : []}
            numVisible={9}
            style={{ maxWidth: "100%" }}
            circular
            fullScreen
            showItemNavigators={prints!.length === 1 ? false : true}
            showThumbnails={false}
            showIndicators
            activeIndex={indicePrint}
            onItemChange={(e) => {
              if (prints) {
                setImagemAtual && setImagemAtual(prints[e.index].urlImagem!);
              }

              setIndicePrint(e.index);
            }}
            item={itemTemplateGrande}
            onHide={() => setImagemAberta && setImagemAberta(false)}
          />
        )}
        {prints && prints.length > 0 ? (
          <Galleria
            activeIndex={indicePrint}
            onItemChange={(e) => {
              if (prints) {
                setImagemAtual && setImagemAtual(prints[e.index].urlImagem!);
              }
              setIndicePrint(e.index);
            }}
            value={prints.length ? prints : []}
            responsiveOptions={responsiveOptions}
            showThumbnails={false}
            showIndicators
            item={itemTemplate}
            circular
            transitionInterval={2000}
          />
        ) : (
          <div className={styles.campoVazio}>
            <p>Nenhum print enviado até o momento</p>
          </div>
        )}
      </div>
    </div>
  );
};
