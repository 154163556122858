import { useEffect, useState } from "react";
import { PublicacoesProps } from "../../../../services/publicacao";
import { FuncoesPublicacaoIndividualTiktok } from "./functions";

import styles from "./styles.module.css";
import { ErroType } from "../../../../@types/erro";
import { format } from "../../../../utils/MascaraNumero/format";
import { converteSegundoParaTempoCompleto } from "../../../../utils/ConversorTempo";
import { CarrosselMetricas } from "../../../Carrossel/CarrosselMetricas";
import { BiTimeFive } from "react-icons/bi";
import { DateFormatter } from "../../../../utils/DataFormatter";
import { obterCredenciais } from "../../../../utils/LocalStorage";

import { TikTokEmbed } from 'react-social-media-embed';

interface PublicacaoIndividualTiktokProps {
  publicacao: PublicacoesProps;
}

export const PublicacaoIndividualTiktok = ({
  publicacao,
}: PublicacaoIndividualTiktokProps) => {
  const [htmlEmbedTiktok, setHtmlEmbedTiktok] = useState<string>("");
  const [error, setError] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [carregando, setCarregando] = useState<boolean>(true);

  const perfil = obterCredenciais().credenciais.perfil[0];

  const { pegaCodigoTiktokEBotaEmbed } = FuncoesPublicacaoIndividualTiktok({
    publicacao,
    setHtmlEmbedTiktok,
    setError,
    setCarregando,
  });

  return (
    <div className={styles.container}>
      <div className={styles.tituloPublicacao}>
        {(perfil === "Administrador" || perfil === "UsuarioNetcos") &&
          `${publicacao.numeroIdentificadorDePublicacao} - `}
        Data da Publicação: {DateFormatter(publicacao.dataFinalizacao)}
      </div>
      <div className={styles.containerResultadoEPublicacoes}>
        <div className={styles.containerDadosResultados}>
          <div className={styles.dadoResultado}>
            <i
              className="pi pi-eye"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Views</h1>
              <p>
                {format("#.###.##0,#0", publicacao.views).replace(",00", "")}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-user"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Espectadores</h1>
              <p>
                {format("#.###.##0,#0", publicacao.espectadores).replace(
                  ",00",
                  ""
                )}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <BiTimeFive
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            />
            <div>
              <h1>Tempo de reproduçao</h1>
              <p>
                {publicacao.tempoReproducao
                  ? converteSegundoParaTempoCompleto(publicacao.tempoReproducao)
                  : "0"}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-thumbs-up"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Likes</h1>
              <p>
                {format("#.###.##0,#0", publicacao.likes).replace(",00", "")}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-comments"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Comentários</h1>
              <p>
                {format("#.###.##0,#0", publicacao.comentarios).replace(
                  ",00",
                  ""
                )}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-share-alt"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Compartilhamentos</h1>
              <p>
                {format("#.###.##0,#0", publicacao.compartilhamentos).replace(
                  ",00",
                  ""
                )}
              </p>
            </div>
          </div>

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-bookmark"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Salvos</h1>
              <p>
                {format("#.###.##0,#0", publicacao.salvos).replace(",00", "")}
              </p>
            </div>
          </div>

        </div>

        <div className={styles.campoPublicacao}>
          <div className={styles.containerVideoTiktok}>
            <p className={styles.headerCampoEmbed}>
              Evidência da Publicação
            </p>

            <TikTokEmbed url={publicacao.urlPublicacao!} />

            {/* <iframe
                className={styles.iframeTiktok}
                height={"100%"}
                width={"100%"}
                allowFullScreen={true}
                src={pegaCodigoTiktokEBotaEmbed(publicacao.urlPublicacao!)}
              /> */}
          </div>
          {/* <div className={styles.carrosselEvidencias}>
            <CarrosselMetricas
              titulo="Evidência da publicação"
              prints={publicacao.midiasDeEvidencia}
              exibicao
            />
          </div> */}

          {(publicacao.printsDeMetrica?.length! > 0) &&
            <div className={styles.carrosselMetricas}>
              <CarrosselMetricas prints={publicacao.printsDeMetrica} exibicao />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
