//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from '@pdfme/common';
import { text, image } from '@pdfme/schemas';
import { FaPage4 } from "react-icons/fa";
import { campanha } from "../../../../services/campanha";
import { PublicacoesProps } from "../../../../services/publicacao";
import { DateFormatter } from "../../../../utils/DataFormatter";
import { format } from "../../../../utils/MascaraNumero/format";
import { somaMetrica } from "../../../../utils/somaMetrica";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioInicio } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import mascaraImagem from "../../../../assets/img/MascaraImagensRelatorio.png";

export async function gerarRelatorioInicio(
  campanha: campanha,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        NomeMarca: {
          type: "text",
          position: {
            x: 214.32,
            y: 16.93,
          },
          width: 37.63,
          height: 6.99,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        PeriodoInicial: {
          type: "text",
          position: {
            x: 185.47,
            y: 159.76,
          },
          width: 44,
          height: 8.85,
          alignment: "center",
          fontSize: 20,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        PeriodoFinal: {
          type: "text",
          position: {
            x: 185.94,
            y: 169.02,
          },
          width: 44,
          height: 8.85,
          alignment: "center",
          fontSize: 20,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        ImagemMarca: {
          type: "image",
          position: {
            x: 232.04,
            y: 52.65,
          },
          width: 88,
          height: 88,
        },
        MascaraImagemMarca: {
          type: "image",
          position: {
            x: 232.04,
            y: 52.65,
          },
          width: 88,
          height: 88,
        },
      },
      {
        BigNumImpressoes: {
          type: "text",
          position: {
            x: 50.27,
            y: 43.66,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        BigNumEngajamento: {
          type: "text",
          position: {
            x: 101.02,
            y: 43.34,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        BigNumTxEngajamento: {
          type: "text",
          position: {
            x: 152.03,
            y: 43.55,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        BigNumCpm: {
          type: "text",
          position: {
            x: 204.63,
            y: 43.5,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        BigNumCpe: {
          type: "text",
          position: {
            x: 257.02,
            y: 43.45,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        AlcanceGeral: {
          type: "text",
          position: {
            x: 101.65,
            y: 79.16,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoGeral: {
          type: "text",
          position: {
            x: 101.6,
            y: 91.54,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        CompartilharGeral: {
          type: "text",
          position: {
            x: 102.34,
            y: 104.19,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        DeslikesGeral: {
          type: "text",
          position: {
            x: 101.75,
            y: 116.31,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        LikesGeral: {
          type: "text",
          position: {
            x: 101.43,
            y: 128.69,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        RetweetsGeral: {
          type: "text",
          position: {
            x: 230.49,
            y: 79.42,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        ImpressoesGeral: {
          type: "text",
          position: {
            x: 230.44,
            y: 91.54,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        SalvosGeral: {
          type: "text",
          position: {
            x: 230.65,
            y: 103.92,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        ComentáriosGeral: {
          type: "text",
          position: {
            x: 230.6,
            y: 116.3,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        FavoritosGeral: {
          type: "text",
          position: {
            x: 230.55,
            y: 128.42,
          },
          width: 43.2,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
    ],
    basePdf: base64RelatorioInicio,
  };

  var imagemMarca = !campanha?.marcas[0].urlLogotipo
    ? ""
    : await converterImagemParaBase64(
      campanha.marcas[0].urlLogotipo,
      setErro,
      setCarregando
    );
  if (imagemMarca !== "AxiosError") {
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaFavoritos: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaDislikes: number = 0;
    let somaRetweets: number = 0;
    let somaSalvos: number = 0;

    let totalAprovados: number = 0;

    let publicacoesTemporarias: PublicacoesProps[] = [];

    campanha.tarefas.map((tarefa) => {
      publicacoesTemporarias.push(...tarefa.publicacoes);
    });

    publicacoesTemporarias.map((publicacao) => {
      if (publicacao.status === "Aprovado") {
        totalAprovados = totalAprovados + 1;
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.views);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaFavoritos = somaMetrica(somaFavoritos, publicacao.favoritos);

        somaCompartilhamentos = somaMetrica(
          somaCompartilhamentos,
          publicacao.compartilhamentos
        );

        somaDislikes = somaMetrica(somaDislikes, publicacao.dislikes);

        somaRetweets = somaMetrica(somaRetweets, publicacao.retweets);

        somaSalvos = somaMetrica(somaSalvos, publicacao.salvos);
      }
    });

    const inputs = [
      {
        NomeMarca: !campanha ? "" : campanha.marcas[0].nome.toString(),
        PeriodoInicial: !campanha
          ? ""
          : DateFormatter(campanha.dataInicio).toString(),
        PeriodoFinal: !campanha
          ? ""
          : DateFormatter(campanha.dataFim).toString(),
        ImagemMarca: imagemMarca!,
        MascaraImagemMarca: mascaraImagem,
        BigNumImpressoes: !campanha
          ? ""
          : campanha.numeroImpressoes == 0 ? "-"
            : format("#.###.##0,#0", campanha.numeroImpressoes).replace(
              ",00",
              ""
            ),
        BigNumEngajamento: !campanha
          ? ""
          : campanha.engajamentoTotal == 0 ? "-"
            : format("#.###.##0,#0", campanha.engajamentoTotal).replace(
              ",00",
              ""
            ),
        BigNumTxEngajamento: !campanha
          ? ""
          : campanha.mediaTaxaEngajamento == 0 ? "-"
            : format("#.###.##0,#0%", campanha.mediaTaxaEngajamento).replace(
              ",00",
              ""
            ),
        BigNumCpm: !campanha
          ? ""
          : campanha.custoPorMilImpressoes == 0 ? "-"
            : format("R$ #.###.##0,#0", campanha.custoPorMilImpressoes),
        BigNumCpe: !campanha
          ? ""
          : campanha.custoEngajamento == 0 ? "-"
            : format("R$ #.###.##0,#0", campanha.custoEngajamento),
        AlcanceGeral: !campanha
          ? ""
          : somaAlcance == 0 ? "-"
            : format("#.###.##0,#0", somaAlcance).replace(",00", ""),
        EngajamentoGeral: !campanha
          ? ""
          : campanha.engajamentoTotal == 0 ? "-"
            : format("#.###.##0,#0", campanha.engajamentoTotal).replace(
              ",00",
              ""
            ),
        CompartilharGeral:
          somaCompartilhamentos == 0 ? "-" :
            format(
              "#.###.##0,#0",
              somaCompartilhamentos
            ).replace(",00", ""),
        DeslikesGeral:
          somaDislikes == 0 ? "-" :
            format("#.###.##0,#0", somaDislikes).replace(",00", ""),
        LikesGeral:
          somaLikes == 0 ? "-" :
            format("#.###.##0,#0", somaLikes).replace(",00", ""),
        RetweetsGeral:
          somaRetweets == 0 ? "-" :
            format("#.###.##0,#0", somaRetweets).replace(",00", ""),
        ImpressoesGeral:
          somaImpressoes == 0 ? "-" :
            format("#.###.##0,#0", somaImpressoes).replace(
              ",00",
              ""
            ),
        SalvosGeral:
          somaSalvos == 0 ? "-" :
            format("#.###.##0,#0", somaSalvos).replace(",00", ""),
        ComentáriosGeral:
          somaComentarios == 0 ? "-" :
            format("#.###.##0,#0", somaComentarios).replace(
              ",00",
              ""
            ),
        FavoritosGeral:
          somaFavoritos == 0 ? "-" :
            format("#.###.##0,#0", somaFavoritos).replace(
              ",00",
              ""
            ),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob1 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob1);
    });
  } else {
    return "AxiosError";
  }
}
