import {
  credenciais,
  putUsuario,
  putUsuarioDesativacao,
} from "../../services/usuario";
import {
  putInfluenciador,
  getInfluenciadorPorId,
} from "../../services/influenciador";
import { obterTokens } from "../../utils/LocalStorage";
import { MascaraTelefone } from "../../utils/MascaraTelefone";
import { useContext } from "react";
import { CredenciaisContext } from "../../context/CredenciaisContext";

import { NavigateFunction } from "react-router-dom";

interface FuncoesPerfilUsuarioProps {
  nome: string;
  telefone: string;
  urlAvatar: string | null;
  setTelefone: React.Dispatch<React.SetStateAction<string>>;
  setEdicao: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  setCarregando: React.Dispatch<boolean>;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  linkInstagram: string;
  linkTwitter: string;
  linkTiktok: string;
  linkYoutube: string;
  setLinkInstagram: React.Dispatch<string>;
  setLinkTwitter: React.Dispatch<string>;
  setLinkTiktok: React.Dispatch<string>;
  setLinkYoutube: React.Dispatch<string>;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setUsuarioFacebook: React.Dispatch<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesPerfilUsuario = ({
  nome,
  telefone,
  urlAvatar,
  setEdicao,
  myToast,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setModalAbertoDesativacao,
  navigate,
  setTelefone,
  setMascaraNumeroWhatsapp,
  linkInstagram,
  linkTwitter,
  linkTiktok,
  linkYoutube,
  setLinkTwitter,
  setLinkInstagram,
  setLinkTiktok,
  setLinkYoutube,
  setLogin,
  setUsuarioFacebook,
}: FuncoesPerfilUsuarioProps) => {
  const { atualizarCredenciais, credenciais } = useContext(CredenciaisContext);
  const removerCredenciais = useContext(CredenciaisContext).removerCredenciais;

  function sucessoDesativacao() {
    showToast(
      "success",
      "Desativação realizada com sucesso",
      "Usuário desativado!",
      false
    );
  }

  // function erroDesativacao(mensagem: string) {
  //   showToast(
  //     "error",
  //     "Desativação não realizada",
  //     mensagem ===
  //       "Usuário está participando em uma campanha no momento. O status não pode ser alterado."
  //       ? "Não é possível desativar influenciador em campanha ativa."
  //       : "Não foi possível desativar o usuário."
  //   );
  //   setModalAbertoDesativacao(false);
  // }

  function erroDesativacao(err: any) {
    const mensagem =
      err.response.data.codigo === 400
        ? "Não é possível desativar influenciador em campanha ativa! Entre em contato com a NetCos."
        : "Não foi possível desativar o influenciador";
    const sticky = err.response.data.codigo === 400 ? true : false;
    showToast("error", "Desativação não realizada", mensagem, sticky);

    setModalAbertoDesativacao(false);
  }

  function sucessoRequisicaoInfluenciadorPorId(res: any) {
    const { linkYoutube, linkTiktok, linkInstagram, linkTwitter } =
      res.data.influenciador;
    setLinkTwitter(linkTwitter);
    setLinkInstagram(linkInstagram);
    setLinkTiktok(linkTiktok);
    setLinkYoutube(linkYoutube);
  }

  function sucessoAtualizacao() {
    showToast(
      "success",
      "Atualização realizada com sucesso",
      "Usuário atualizado!",
      false
    );
  }

  function erroAtualizacao() {
    showToast(
      "error",
      "Atualização não realizada",
      "Não foi possível atualizar o usuário.",
      false
    );
    setModalAbertoDesativacao(false);
  }

  function desativarUsuario() {
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    let novaCredenciais: credenciais = {
      celular: credenciais.celular,
      fotoUsuario: credenciais.fotoUsuario,
      id: credenciais.id,
      nome: credenciais.nome,
      perfil: credenciais.perfil,
      termosDeUsoAceitos: false,
      urlAvatar: credenciais.urlAvatar,
      userName: credenciais.userName,
    };

    putUsuarioDesativacao(token, credenciais)
      .then(() => {
        atualizarCredenciais(novaCredenciais);
        sucessoDesativacao();
        setCarregando(false);
        setModalAbertoDesativacao(false);
        setTimeout(() => {
          removerCredenciais();
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioDesativacao(token, credenciais).then(() => {
                atualizarCredenciais(novaCredenciais);
                sucessoDesativacao();
                setTimeout(() => {
                  removerCredenciais();
                  navigate("/");
                }, 2000);
              });
            },
            () => {
              setCarregando(false);
              setModalAbertoDesativacao(false);
            }
          );
        } else {
          erroDesativacao(err);
          setCarregando(false);
          setModalAbertoDesativacao(false);
        }
      });
  }

  function atualizarUsuario() {
    const { token, tokenRenovacao } = obterTokens();

    setCarregando(true);

    let novaCredenciais: credenciais = {
      celular: telefone ? telefone.toString() : "",
      fotoUsuario: credenciais.fotoUsuario,
      id: credenciais.id,
      nome: nome,
      perfil: credenciais.perfil,
      termosDeUsoAceitos: credenciais.termosDeUsoAceitos,
      urlAvatar: urlAvatar,
      userName: credenciais.userName,
    };

    putUsuario(token, credenciais, nome, urlAvatar, telefone)
      .then(() => {
        atualizarCredenciais(novaCredenciais);
        setEdicao(false);
        sucessoAtualizacao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putUsuario(
              token,
              credenciais,
              nome,
              urlAvatar,
              telefone
            ).then(() => {
              atualizarCredenciais(novaCredenciais);
              setEdicao(false);
              sucessoAtualizacao();
            });
          }, setCarregando);
        } else {
          erroAtualizacao();
          setCarregando(false);
        }
      });
  }

  // function fazMascaraTelefone(telefoneUsuario: string) {
  //   MascaraTelefone(telefoneUsuario, setTelefone, setMascaraNumeroWhatsapp);
  // }

  function atualizarInfluenciador() {
    const { token, tokenRenovacao } = obterTokens();
    const { id } = credenciais;

    let novaCredenciais: credenciais = {
      celular: telefone ? telefone.toString() : "",
      fotoUsuario: credenciais.fotoUsuario,
      id: credenciais.id,
      nome: nome,
      perfil: credenciais.perfil,
      termosDeUsoAceitos: credenciais.termosDeUsoAceitos,
      urlAvatar: urlAvatar,
      userName: credenciais.userName,
    };

    setCarregando(true);
    putInfluenciador(
      token,
      id,
      nome,
      urlAvatar,
      telefone,
      null,
      null,
      linkInstagram,
      linkTwitter,
      linkTiktok,
      linkYoutube
    )
      .then(() => {
        sucessoAtualizacao();
        atualizarCredenciais(novaCredenciais);
        setEdicao(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putInfluenciador(
              token,
              id,
              nome,
              urlAvatar,
              telefone,
              null,
              null,
              linkInstagram,
              linkTwitter,
              linkTiktok,
              linkYoutube
            ).then(() => {
              sucessoAtualizacao();
              atualizarCredenciais(novaCredenciais);
              setEdicao(false);
            });
          }, setCarregando);
        } else {
          erroAtualizacao();
          setCarregando(false);
        }
      });
  }

  function obterInfluenciadorPorId() {
    const { token, tokenRenovacao } = obterTokens();
    getInfluenciadorPorId(token, credenciais.id)
      .then((res) => {
        sucessoRequisicaoInfluenciadorPorId(res);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadorPorId(token, credenciais.id).then((res) => {
              sucessoRequisicaoInfluenciadorPorId(res);
            });
          });
        }
      });
  }

  function responseFacebook(response: any) {
    setUsuarioFacebook(response);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    stickyValue: boolean
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
      sticky: stickyValue,
    });
  };

  return {
    desativarUsuario,
    atualizarUsuario,
    atualizarInfluenciador,
    obterInfluenciadorPorId,
    responseFacebook,
  };
};
