import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesTabelaPublicacao } from "../../../components/Tabelas/TabelaListarPublicacoesDeTarefa/functions";

import { ErroType } from "../../../@types/erro";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TabelaListarPublicacoesDeTarefa } from "../../../components/Tabelas/TabelaListarPublicacoesDeTarefa";
import { PublicacoesProps } from "../../../services/publicacao";
import { ModalEditarTarefa } from "../../../components/Modais/ModalEditarTarefa";
import { ProgressBar } from "primereact/progressbar";
import { ModalAcrescentarPublicacaoNaTarefa } from "../../../components/Modais/ModalAdicionarPublicacaoNaTarefa";

import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ListagemPublicaçõesInfluenciadorProps {
  dataInicioCampanha: string;
  dataRelatorio: string;
}

export const ListagemPublicaçõesInfluenciador = ({
  dataInicioCampanha,
  dataRelatorio,
}: ListagemPublicaçõesInfluenciadorProps) => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(true);

  const [numeroPagina, setNumeroPagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Id");

  const [listaPublicacoes, setListaDePublicacoes] = useState<
    PublicacoesProps[]
  >([]);
  const [totalDePublicacoes, setTotalDePublicacoes] = useState<number>(0);
  const [mostrarModalEditarTarefa, setMostrarModalEditarTarefa] =
    useState<boolean>(false);
  const [mostrarModalAprovarTarefa, setMostrarModalAprovarTarefa] =
    useState<boolean>(false);

  const [publicacaoSelecionada, setPublicacaoSelecionada] =
    useState<PublicacoesProps>({
      status: "Pendente",
      dataFinalizacao: new Date().toISOString(),
      externalId: "",
      tipo: "PublicacaoInstagramFeed",
    });
  const myToast = useRef<any>(null);

  const { credenciais } = obterCredenciais();
  const { idCampanha, idTarefas } = useParams();

  const [entregues, setEntregues] = useState<number>(0);
  const [contratadas, setContratadas] = useState<number>(0);

  let percentualEntregues = ((entregues * 100) / contratadas).toFixed(2);

  const colunas = [
    {
      nome: "Tipo de Publicação",
      ordenacao: "tipoPublicacao",
    },
    {
      nome: "Data da Publicação",
      ordenacao: "DataFinalizacao",
    },
    {
      nome: "Status",
      ordenacao: "Nome",
    },
    {
      nome: "Ações",
      ordenacao: "acoes",
    },
  ];

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterPublicacoesTarefa } = FuncoesTabelaPublicacao({
    idCampanha: idCampanha!,
    idInfluenciadorSelecionado: credenciais.id,
    setListaDePublicacoes,
    setTotalDePublicacoes,
    setEntregues,
    setContratadas,
    setErro,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
  });

  useEffect(() => {
    obterPublicacoesTarefa();
  }, [tamanhoPagina, numeroPagina, recarregar, tamanhoPagina]);

  return (
    <>
      <TabelaListarPublicacoesDeTarefa
        tituloTabela={
          <div className={styles.container}>
            <div className={styles.tituloListaPublicacoes}>
              Publicações
              <div className={styles.campoProgresso}>
                <p className={styles.progressoTitulo}>
                  Progresso ({percentualEntregues}%)
                </p>
                <ProgressBar value={percentualEntregues} />
                <p className={styles.progressoInfo}>
                  {entregues}/{contratadas} publicações entregues
                </p>
              </div>
            </div>
            <div className={styles.containerModalAcrescentarPublicacaoNaTarefa}>
              <ModalAcrescentarPublicacaoNaTarefa
                importancia="secundario"
                idTarefa={idTarefas!}
                idInfluenciadorSelecionado={credenciais.id}
                setRecarregar={() => setRecarregar(!recarregar)}
              />
            </div>
          </div>
        }
        dataInicioCampanha={dataInicioCampanha}
        dataRelatorio={dataRelatorio}
        tituloColuna={colunas}
        listaDePublicacoes={listaPublicacoes}
        carregando={carregando}
        tipoOrdenacao={"Ascending"}
        erro={erro}
        nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
        setCarregando={setCarregando}
        setTipoOrdenacao={setTipoOrdenacao}
        setErro={setErro}
        setRecarregar={() => setRecarregar(!recarregar)}
        setMostrarModalEditarTarefa={setMostrarModalEditarTarefa}
        // paginacao={<Paginator
        //   first={paginatorFirst}
        //   rows={tamanhoPagina}
        //   totalRecords={totalDePublicacoes}
        //   onPageChange={(e) => {
        //     setNumeroPagina(e.page);
        //     setPaginatorFirst(e.first);
        //   } } />}
        // seletorDeQuantidade={<SelectQtdadeItensLista
        //   onChange={(e: any) => setTamanhoPagina(e.target.value)}
        //   totalLista={totalDePublicacoes}
        //   nomeLista={"publicacoes"} />}
        totalDePublicacoes={totalDePublicacoes}
        tamanhoDePagina={totalDePublicacoes}
        setPublicacaoSelecionada={setPublicacaoSelecionada}
        setMostrarModalAprovarTarefa={setMostrarModalAprovarTarefa}
      />

      {mostrarModalEditarTarefa && publicacaoSelecionada.externalId !== "" && (
        <ModalEditarTarefa
          publicacaoSelecionada={publicacaoSelecionada}
          modalAberto={mostrarModalEditarTarefa}
          setModalAberto={setMostrarModalEditarTarefa}
          setRecarregar={setRecarregar}
          influenciador
        />
      )}
      {mostrarModalAprovarTarefa && publicacaoSelecionada.externalId !== "" && (
        <ModalEditarTarefa
          publicacaoSelecionada={publicacaoSelecionada}
          modalAberto={mostrarModalAprovarTarefa}
          setModalAberto={setMostrarModalAprovarTarefa}
          aprovar={true}
          setRecarregar={setRecarregar}
          influenciador
        />
      )}

      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
