//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { Influenciador } from "../../../../services/publicacao";
import { tarefaProps } from "../../../../services/tarefas";
import { format } from "../../../../utils/MascaraNumero/format";
import { somaMetrica } from "../../../../utils/somaMetrica";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioInstaFeed } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import mascaraImagem from "../../../../assets/img/MascaraImagensRelatorio.png";


export async function gerarPaginaInstaFeed(
  tarefa: tarefaProps,
  listaDePdfs: any,
  taxaEngajamentoCampanha: number,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        Alcance: {
          type: "text",
          position: {
            x: 259.56,
            y: 47.89,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Impressoes: {
          type: "text",
          position: {
            x: 259.77,
            y: 61.59,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Likes: {
          type: "text",
          position: {
            x: 259.45,
            y: 76.62,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Comentarios: {
          type: "text",
          position: {
            x: 259.67,
            y: 90.06,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Compartilhamentos: {
          type: "text",
          position: {
            x: 259.87,
            y: 103.5,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        Salvos: {
          type: "text",
          position: {
            x: 259.93,
            y: 117.73,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        VisitasPerfil: {
          type: "text",
          position: {
            x: 259.94,
            y: 132.94,
          },
          width: 35,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TaxaEngajamento: {
          type: "text",
          position: {
            x: 282.43,
            y: 146.76,
          },
          width: 20,
          height: 7,
          alignment: "left",
          fontSize: 13,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        ImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        MascaraImagemInflu: {
          type: "image",
          position: {
            x: 20.11,
            y: 68,
          },
          width: 40,
          height: 40,
        },
        NomeInflu: {
          type: "text",
          position: {
            x: 63.97,
            y: 68.26,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        TipoInflu: {
          type: "text",
          position: {
            x: 64.18,
            y: 76.94,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoInflu: {
          type: "text",
          position: {
            x: 64.39,
            y: 100.7,
          },
          width: 66.48,
          height: 7,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
        EngajamentoCampanha: {
          type: "text",
          position: {
            x: 94.5,
            y: 178.17,
          },
          width: 25.73,
          height: 6.99,
          alignment: "left",
          fontSize: 14,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
        },
      },
    ],
    basePdf: base64RelatorioInstaFeed,
  };

  var imageInfluencer = !tarefa.influenciador.urlAvatar
    ? ""
    : await converterImagemParaBase64(
      tarefa.influenciador.urlAvatar,
      setErro,
      setCarregando
    );
  if (imageInfluencer !== "AxiosError") {
    let somaImpressoes: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaAlcance: number = 0;
    let somaSalvos: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaVisitasPerfil: number = 0;

    tarefa.publicacoes.map((publicacao) => {
      if (publicacao.tipo === "PublicacaoInstagramFeed") {
        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaSalvos = somaMetrica(somaSalvos, publicacao.salvos);

        somaCompartilhamentos = somaMetrica(
          somaCompartilhamentos,
          publicacao.compartilhamentos
        );

        somaVisitasPerfil = somaMetrica(
          somaVisitasPerfil,
          publicacao.visitasAoPerfil
        );

        somaVisitasPerfil = somaMetrica(
          somaVisitasPerfil,
          publicacao.visitasPerfil
        );
      }
    });

    const inputs = [
      {
        Alcance: somaAlcance == 0 ? "-" : format("#.###.##0,#0", somaAlcance).replace(",00", ""),
        Impressoes: somaImpressoes == 0 ? "-" : format("#.###.##0,#0", somaImpressoes).replace(",00", ""),
        Likes: somaLikes == 0 ? "-" : format("#.###.##0,#0", somaLikes).replace(",00", ""),
        Comentarios: somaComentarios == 0 ? "-" : format("#.###.##0,#0", somaComentarios).replace(",00", ""),
        Compartilhamentos: somaCompartilhamentos == 0 ? "-" : format(
          "#.###.##0,#0",
          somaCompartilhamentos
        ).replace(",00", ""),
        Salvos: somaSalvos == 0 ? "-" : format("#.###.##0,#0", somaSalvos).replace(",00", ""),
        VisitasPerfil: somaVisitasPerfil == 0 ? "-" : format("#.###.##0,#0", somaVisitasPerfil).replace(
          ",00",
          ""
        ),
        TaxaEngajamento: tarefa.instagramFeedTaxaEngajamento == 0 ? "-" : format("#.###.##0,#0%", tarefa.instagramFeedTaxaEngajamento),
        ImagemInflu: !imageInfluencer ? "" : imageInfluencer,
        MascaraImagemInflu: mascaraImagem,
        NomeInflu: tarefa.influenciador.nome.toString(),
        TipoInflu: tarefa.influenciador.perfil.toString(),
        EngajamentoInflu:
          tarefa.influenciador.engajamentoHistorico <= 0 ? "-" :
            "Taxa de engajamento histórico: " +
            format(
              "#.###.##0,#0%",
              tarefa.influenciador.engajamentoHistorico
            ).toString(),
        EngajamentoCampanha: taxaEngajamentoCampanha == 0 ? "-" : format(
          "#.###.##0,#0%",
          taxaEngajamentoCampanha
        ).toString(),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob2 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob2);
    });
  } else {
    return "AxiosError";
  }
}
