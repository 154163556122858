import styles from "./styles.module.css";

export interface SelectQtdadeItensListaProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined
  totalLista?: number;
  nomeLista?: string;
}

export const SelectQtdadeItensLista = ({
  onChange,
  totalLista,
  nomeLista
}: SelectQtdadeItensListaProps) => (
  <div className={styles.containerSeletor}>
    Exibindo até

    <select className={styles.customSelect} onChange={onChange}>
      <option selected value={"5"}>5</option>
      <option value={"10"}>10</option>
      <option value={"15"}>15</option>
    </select>

    de {totalLista} {nomeLista}
  </div>
);
