import { useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsModalEditarValorDeContrato } from "./functions";

import { MostrarToastContext } from "../../../context/MostrarToast";
import { Button } from "../../Botoes/Button";
import { Input } from "../../Input";
import { ModalTeste } from "../ModalTeste";
import { tarefaProps } from "../../../services/tarefas";
import { mascaraBRLInput } from "../../../utils/MascaraBRL";
import { mascaraUSD } from "../../../utils/MarcaraUSD";
import { Carregando } from "../../Carregando";
import { format } from "../../../utils/MascaraNumero/format";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ModalEditarValorDeContratoProps {
  moodalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  tarefa: tarefaProps;
  recarregarTabela: () => void;
  idCampanha: string;
}

export function ModalEditarValorDeContrato({
  moodalAberto,
  setModalAberto,
  tarefa,
  recarregarTabela,
  idCampanha,
}: ModalEditarValorDeContratoProps) {
  const [valorDeContrato, setValorDeContrato] = useState<string>("");
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [carregandoRequisição, setCarregandoRequisicao] =
    useState<boolean>(false);

  const { mostrarToast } = useContext(MostrarToastContext);
  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { editarValorDoContrato } = FunctionsModalEditarValorDeContrato({
    fecharModal: () => setModalAberto(false),
    recarregarTabela,
    mostrarToast,
    idCampanha: idCampanha,
    idTarefa: tarefa.externalId,
    printsDeSentimento: tarefa.printsDeSentimento,
    valorContrato: Number(mascaraUSD(valorDeContrato).replace(" ", "")),
    setCarregandoRequisicao,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <ModalTeste
      modalAberto={moodalAberto}
      closeOnEscape
      setModalAberto={setModalAberto}
      titulo="Editar Valor Do Contrato"
    >
      <form
        className={styles.formModalEditarValorDeContrato}
        onSubmit={(e) => {
          e.preventDefault();
          editarValorDoContrato();
        }}
      >
        <Input
          onFocus={() => setInputFocus(true)}
          title="Valor do Contrato"
          value={
            inputFocus
              ? "R$ " + valorDeContrato
              : format("R$ #.###.##0,#0", tarefa.valorContrato)
          }
          onChange={(e) => {
            mascaraBRLInput(e.target.value, setValorDeContrato);
          }}
          className={styles.input}
        />
        <div className={styles.containerButtons}>
          {carregandoRequisição ? (
            <Carregando />
          ) : (
            <>
              <Button
                importancia="terciario"
                title="Cancelar"
                onClick={() => setModalAberto(false)}
              />
              <Button type="submit" importancia="primario" title="Confirmar" />
            </>
          )}
        </div>
      </form>
    </ModalTeste>
  );
}
