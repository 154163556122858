import { api } from "./api";

const URL = '/TemplateEmail'

export const postTemplateEmail = (
    token: string,
    assunto: string,
    mensagemTextoPuro: string,
    mensagemHtml: string,
    tipo: string,) => {

    const templateEmail = {
        assunto,
        mensagemHtml,
        mensagemTextoPuro,
        tipo
    }

    return api.post(URL, templateEmail, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export interface TemplateEmailProps {
    assunto: string,
    mensagemTextoPuro: string,
    mensagemHtml: string,
    tipo: string,
}

export interface getTemplateEmailPorTipoResposta {
    data: {
        codigo: number,
        templates: TemplateEmailProps
    }
}

export const getTemplateEmailPorTipo = (tipo: string): Promise<getTemplateEmailPorTipoResposta> => {
    const url = `${URL}?Tipo=${tipo}`

    return api.get(url)
}

export const putTemplateEmail = (
    token: string,
    assunto: string,
    mensagemTextoPuro: string,
    mensagemHtml: string,
    tipo: string,
) => {
    const templateEmail = {
        assunto,
        conteudoHtml: mensagemHtml,
        conteudoTexto: mensagemTextoPuro,
        tipo
    }

    return api.put(URL, { templateEmail }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

