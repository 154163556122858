import React, { useState } from "react";

import { CaixaFlutuanteAtualizarAgencia } from "../../CaixasFlutuantes/CaixaFlutuanteAtualizarAgencia";

import styles from "./styles.module.css";

interface BotaoAtualizarAgenciaListaProps {
    idAgencia: string;
    nomeAgencia: string;
    logotipo: string;
    setRecarregar: () => void;
    myToast: React.MutableRefObject<any>;
    status: boolean;
}

export const BotaoAtualizarAgenciaLista = ({
    idAgencia,
    nomeAgencia,
    logotipo,
    setRecarregar,
    myToast,
    status
}: BotaoAtualizarAgenciaListaProps) => {
    const [caixaAbertaAgencia, setCaixaAbertaAgencia] = useState<boolean>(false);

    return (
        <button
            className={
                status ?
                    styles.botaoIconeColunaAcoes :
                    styles.botaoIconeColunaAcoesDisabled
            }
            disabled={
                status ? false : true
            }>
            <i
                className="pi pi-pencil"
                style={{ fontSize: "1.4rem", paddingRight: "1rem" }}
                onClick={() => status && setCaixaAbertaAgencia(!caixaAbertaAgencia)}
                title="Editar Agência"
            />
            {caixaAbertaAgencia && <CaixaFlutuanteAtualizarAgencia
                myToast={myToast}
                id={idAgencia}
                nomeAgenciaAntiga={nomeAgencia}
                logotipoAntigo={logotipo}
                setRecarregar={setRecarregar}
                caixaAbertaAgencia={caixaAbertaAgencia}
                setCaixaAbertaAgencia={setCaixaAbertaAgencia}
            />}
        </button>
    );
};
