import { ErroType } from "../../@types/erro";
import {
  getTodasPublicacoesDaCampanhaPorInfluenciador,
  PublicacoesProps,
} from "../../services/publicacao";
import { obterTokens } from "../../utils/LocalStorage";

interface FuncoesVisaoGeralInfluenciadorProps {
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<ErroType>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
  setPublicacoes: React.Dispatch<React.SetStateAction<PublicacoesProps[]>>;
  setContratadas: React.Dispatch<React.SetStateAction<number>>;
  setEntregues: React.Dispatch<React.SetStateAction<number>>;
  idCampanha: string;
  idInfluenciador: string;
}

export const FuncoesVisaoGeralInfluenciador = ({
  idInfluenciador,
  idCampanha,

  setPublicacoes,
  setContratadas,
  setEntregues,

  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setError,
}: FuncoesVisaoGeralInfluenciadorProps) => {
  function obtemDadosGrafico() {
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);
    setError({ ativo: false, mensagem: "" });

    getTodasPublicacoesDaCampanhaPorInfluenciador(
      token,
      idCampanha,
      idInfluenciador,
      "Ascending",
      "Id",
      1,
      2147483647
    )
      .then((res) => {
        setPublicacoes(res.data.tarefas);
        setContratadas(res.data.tarefas.length);
        setaEntregue(res.data.tarefas);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTodasPublicacoesDaCampanhaPorInfluenciador(
              token,
              idCampanha,
              idInfluenciador,
              "Ascending",
              "Id",
              1,
              2147483647
            )
              .then((res) => {
                setPublicacoes(res.data.tarefas);
                setContratadas(res.data.tarefas.length);
                setaEntregue(res.data.tarefas);
              })
              .catch(() => {
                setCarregando(false);
                setError({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Publicações.",
                });
              });
          }, setCarregando);
        } else {
          setError({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Publicações.",
          });
        }
      });
  }

  function setaEntregue(publicacoes: PublicacoesProps[]) {
    let somaEntregue: number = 0;

    publicacoes.map((publicacao) => {
      if (publicacao.status === "Aprovado") {
        somaEntregue = somaEntregue + 1;
      }
    });

    setEntregues(somaEntregue);
    setCarregando(false);
  }

  return {
    obtemDadosGrafico,
  };
};
