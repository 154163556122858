import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { getTarefasPorCampanha, tarefaProps } from "../../../services/tarefas";
import { obterTokens } from "../../../utils/LocalStorage";
import { format } from "../../../utils/MascaraNumero/format";
import MascaraGrafico from "../../../utils/MascaraRotulosGraficos";

export interface basicDataProps {
  labels: string[];
  datasets: datasetsProps[];
}

interface datasetsProps {
  type: string;
  label: string;
  borderColor?: string;
  borderWidth?: number;
  fill?: boolean;
  tension?: number;
  data: number[];
  backgroundColor?: CanvasGradient;
  yAxisID?: string;
  datalabels?: datalabelsProps;
  pointStyle?: string | null;
}

interface datalabelsProps {
  align?: string | ((context: any) => string);
  anchor?: string;
}

interface FuncoesGraficoInfluenciadoresDaCampanhaProps {
  setBasicData: React.Dispatch<React.SetStateAction<basicDataProps>>;
  setTarefas: React.Dispatch<React.SetStateAction<tarefaProps[]>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  setChartOptions: React.Dispatch<React.SetStateAction<{}>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesGraficoInfluenciadoresDaCampanha = ({
  setBasicData,
  setTarefas,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  id,
  setChartOptions,
}: FuncoesGraficoInfluenciadoresDaCampanhaProps) => {
  const larguraTela = window.screen.width;
  const telaTablet = larguraTela <= 968;
  const telaMobile = larguraTela <= 420;

  function pegaTarefasDaCampanha() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();

    setCarregando(true);
    getTarefasPorCampanha(
      token,
      id,
      1,
      3,
      "Descending",
      "EngajamentoTotalDaTarefa"
    )
      .then((res) => {
        setTarefas(res.data.tarefas.reverse());
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTarefasPorCampanha(
              token,
              id,
              1,
              3,
              "Descending",
              "EngajamentoTotalDaTarefa"
            )
              .then((res) => {
                setTarefas(res.data.tarefas.reverse());
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Ocorreu um erro ao carregar o top 3.",
                });
              });
          }, setCarregando);
        } else {
          setCarregando(false);
          setErro({
            ativo: true,
            mensagem: "Ocorreu um erro ao carregar o top 3.",
          });
          setCarregando(false);
        }
      });
  }

  function setaDados(campanha: campanha, tarefas: tarefaProps[]) {
    let influenciadores = pegaInfluenciadores(
      tarefas.length > 0 ? tarefas : campanha.tarefas
    );
    let engajamentoLinhaGenerator = pegaTaxaEngajamentoTarefa(
      tarefas.length > 0 ? tarefas : campanha.tarefas
    );
    let engajamentoTotal = setaEngajamentoTotal(
      tarefas.length > 0 ? tarefas : campanha.tarefas
    );
    let alcanceTotal = setaAlcanceTotal(
      tarefas.length > 0 ? tarefas : campanha.tarefas
    );

    let divGraficoInfluenciadoresDaCampanha = document.getElementById(
      "divGraficoInfluenciadoresDaCampanha"
    );
    let graficoInfluenciadoresDaCampanha =
      divGraficoInfluenciadoresDaCampanha?.firstElementChild;
    graficoInfluenciadoresDaCampanha?.setAttribute(
      "id",
      "graficoInfluenciadoresDaCampanha"
    );

    var graficoInfluenciadoresDaCampanhaId = <HTMLCanvasElement>(
      document.getElementById("graficoInfluenciadoresDaCampanha")
    );
    var graficoVisãoGeralContexto =
      graficoInfluenciadoresDaCampanhaId?.getContext("2d");

    var gradienteAlcance = graficoVisãoGeralContexto?.createLinearGradient(
      0,
      0,
      0,
      400
    );
    gradienteAlcance?.addColorStop(0, "#fc887b");
    gradienteAlcance?.addColorStop(0.05, "#ffdd95");
    gradienteAlcance?.addColorStop(0.1, "#fc887b");
    gradienteAlcance?.addColorStop(0.8, "#ffdd95");

    var gradiente = graficoVisãoGeralContexto?.createLinearGradient(
      0,
      0,
      0,
      400
    );
    gradiente?.addColorStop(0, "#ffdd95");
    gradiente?.addColorStop(0.05, "#d0e6a5");
    gradiente?.addColorStop(0.1, "#ffdd95");
    gradiente?.addColorStop(1, "#d0e6a5");

    //Ordenando os dados para aparecerem em formato decrescente no gráfico
    const todosOsDados = [];

    for (let i = 0; i < influenciadores.length; ++i) {
      todosOsDados.push({
        label: influenciadores[i],
        dadosEngajamentoTotal: engajamentoTotal[i],
        dadosEngajamentoLinha: engajamentoLinhaGenerator[i],
        dadosAlcance: alcanceTotal[i],
      });
    }

    todosOsDados.sort(
      (a, b) => b.dadosEngajamentoTotal - a.dadosEngajamentoTotal
    );

    const influenciadoresOrdenados = todosOsDados.map((e) => e.label);
    const alcanceTotalOrdenado = todosOsDados.map(
      (e) => e.dadosAlcance
    );
    const engajamentoTotalOrdenado = todosOsDados.map(
      (e) => e.dadosEngajamentoTotal
    );
    const engajamentoLinhaOrdenado = todosOsDados.map(
      (e) => e.dadosEngajamentoLinha
    );

    let basicData: basicDataProps = {
      labels: influenciadoresOrdenados,
      datasets: [
        {
          type: "line",
          label: "Taxa de Engajamento na Campanha",
          borderColor: "#51C876",
          borderWidth: larguraTela < 768 ? 2 : 3,
          yAxisID: "y1",
          fill: false,
          tension: 0.4,
          data: engajamentoLinhaOrdenado,
          pointStyle: telaMobile ? "rect" : "line",
          datalabels: {
            align: "center",
            anchor: "center",
          },
        },
        {
          type: "bar",
          label: "Alcance",
          borderWidth: 0,
          yAxisID: "y",
          data: alcanceTotalOrdenado,
          pointStyle: "rect",
          backgroundColor: gradienteAlcance,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
        {
          type: "bar",
          label: "Engajamento Total",
          borderWidth: 0,
          yAxisID: "y",
          data: engajamentoTotalOrdenado,
          pointStyle: "rect",
          backgroundColor: gradiente,
          datalabels: {
            align: function (context: any) {
              return context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? "end"
                : "start";
            },
            anchor: "end",
          },
        },
      ],
    };

    const options = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            pointStyleWidth: 40,
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.dataset.label}: ${format(
                "#.###.##0,#0",
                context.raw
              ).replace(",00", "")}${context.dataset.type === "line" ? "%" : ""
                }`;
            },
          },
        },
        datalabels: {
          backgroundColor: function (context: any) {
            return context.dataset.type === "line"
              ? context.dataset.borderColor
              : "#ffffff00";
          },
          borderRadius: 4,
          color: function (context: any) {
            return context.dataset.type === "line"
              ? "#F4FDF1"
              : context.dataset.data[context.dataIndex] <=
                context.chart.boxes[4].ticks[1].value
                ? context.dataset.backgroundColor
                : context.datasetIndex === 1
                  ? "#78172B"
                  : context.datasetIndex === 2
                    ? "#70611D"
                    : "#fff";
          },
          display: function (context: any) {
            return telaTablet
              ? false
              : context.dataset.data[context.dataIndex] > 0;
          },
          font: {
            size: telaTablet ? 12 : 14,
            weight: "bold",
          },
          formatter: function (value: number, context: any) {
            return context.dataset.type === "line"
              ? format("#.###.##0,#0", value).replace(",00", "") + "%"
              : MascaraGrafico(value);
          },
        },
      },
      scales: {
        y: {
          type: "logarithmic",
          display: true,
          position: "left",
          min: 0,
          ticks: {
            maxTicksLimit: 9,
            callback: function (value: number) {
              return MascaraGrafico(value);
            },
          },
        },
        y1: {
          position: "right",
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            color: "#495057",
            callback: function (value: string) {
              return value + "%";
            },
          },
          grid: {
            display: false,
          },
        },
      },
    };

    setChartOptions(options);

    setBasicData(basicData);
  }

  function pegaInfluenciadores(tarefas: any[]) {
    let influenciadores: string[] = [];

    tarefas.map((tarefa) => {
      influenciadores.push(tarefa.influenciador.nome);
    });

    return influenciadores;
  }

  function pegaTaxaEngajamentoTarefa(tarefas: tarefaProps[]) {
    let taxaEngajamentoTarefa: number[] = [];

    tarefas.map((tarefa) => {
      taxaEngajamentoTarefa.push(tarefa.taxaEngajamento);
    });

    return taxaEngajamentoTarefa;
  }

  function setaEngajamentoTotal(tarefas: tarefaProps[]) {
    let engajamentoTotal: number[] = [];

    tarefas.map((tarefa) => {
      engajamentoTotal.push(tarefa.engajamentoTotalDaTarefa);
    });

    return engajamentoTotal;
  }
  function setaAlcanceTotal(tarefas: tarefaProps[]) {
    let alcanceTotal: number[] = [];

    tarefas.map((tarefa) => {
      alcanceTotal.push(tarefa.alcanceTotalDaTarefa);
    });

    return alcanceTotal;
  }

  return {
    pegaTarefasDaCampanha,
    setaDados,
  };
};
