import React, { useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesCaixaFlutuanteAtualizarMarca } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Input } from "../../Input";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";

import { Toast } from "primereact/toast";
import { useRef, useState } from "react";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface CaixaFlutuanteAtualizarMarcaProps {
  caixaAbertaMarca: boolean;
  setCaixaAbertaMarca: React.Dispatch<boolean>;
  id: string;
  nomeMarcaAntiga: string;
  logotipoAntigo: string;
  setRecarregar: () => void;
}

export const CaixaFlutuanteAtualizarMarca = ({
  caixaAbertaMarca,
  setCaixaAbertaMarca,
  id,
  nomeMarcaAntiga,
  logotipoAntigo,
  setRecarregar,
}: CaixaFlutuanteAtualizarMarcaProps) => {
  const [nome, setNome] = useState<string>(nomeMarcaAntiga);
  const [logotipo, setLogotipo] = useState<string>("");
  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const myToast = useRef<any>(null);

  const { atualizarMarca } = FuncoesCaixaFlutuanteAtualizarMarca({
    nome,
    setNome,
    id,
    logotipo: logotipo ? logotipo : null,
    setLogotipo,
    myToast,
    setCarregando,
    setRecarregar,
    adicionarAListaEsperaRenovacaoToken,
  });

  return (
    <div className={styles.conteudoCompletoCaixa}>
      <div
        className={styles.backgroundCaixaFlutuanteAtualizarMarca}
        onClick={() => setCaixaAbertaMarca(false)}
      ></div>
      <div className={styles.conteudoCaixaFlutuanteAtualizarMarca}>
        <BotaoUploadImagem
          setImg={setLogotipo}
          imagemAnterior={logotipoAntigo}
          nomeParaLabel={nome}
        />

        <div className={styles.containerInputs}>
          <Input
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </div>

        {carregando ? (
          <Carregando />
        ) : (
          <div className={styles.containerBotoesModal}>
            <Button
              importancia="secundario"
              title="Confirmar"
              onClick={atualizarMarca}
            />
            <Button
              type="button"
              importancia="terciario"
              title="Cancelar"
              onClick={() => setCaixaAbertaMarca(!caixaAbertaMarca)}
            />
          </div>
        )}

        <Toast ref={myToast} className="custom-toast" />
      </div>
    </div>
  );
};
