import { InputMetrica } from "./inputs/InputMetrica";

interface YoutubeShortsProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  dislikes: number;
  setDislikes: React.Dispatch<React.SetStateAction<number>>;
  views: number;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  espectadores: number;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
}

export const YoutubeShorts = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  impressoes,
  setImpressoes,
  dislikes,
  setDislikes,
  views,
  setViews,
  espectadores,
  setEspectadores

}: YoutubeShortsProps) => {
  const listaMetricas = [
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Impressoes", metrica: { valor: impressoes, dispatch: setImpressoes } },
    { nome: "Dislikes", metrica: { valor: dislikes, dispatch: setDislikes } },
    { nome: "Views", metrica: { valor: views, dispatch: setViews } },
    { nome: "Espectadores", metrica: { valor: espectadores, dispatch: setEspectadores } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}
    </>
  )
}