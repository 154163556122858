import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FunctionAceiteTermos } from "./functions";

import { Button } from "../../components/Botoes/Button";
import { Carregando } from "../../components/Carregando";

import { useNavigate } from "react-router-dom";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";

export const AceiteDeTermos = () => {
  const [liConcordo, setLiConcordo] = useState<boolean>(false);

  const [termosDeUsoURL, setTermosDeUsoURL] = useState<string>("");
  const [politicaDePrivacidadeURL, setPoliticaDePrivacidadeURL] =
    useState<string>("");
  const [politicaDeConteudoURL, setPoliticaDeConteudoURL] =
    useState<string>("");
  const [carregando, setCarregando] = useState<boolean>(false);

  const navigate = useNavigate();

  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { pegaDocumentosLegais, editaTermosUsuario } = FunctionAceiteTermos({
    setPoliticaDeConteudoURL,
    setPoliticaDePrivacidadeURL,
    setTermosDeUsoURL,
    navigate,
    myToast,
    adicionarAListaEsperaRenovacaoToken,
    setCarregando,
  });

  useEffect(() => {
    pegaDocumentosLegais();
  }, []);

  return (
    <>
      <div className={styles.telaAceitarTermos}>
        <div className={styles.boxAceitarConvite}>
          <div className={styles.termosConfirma}>
            <div className={styles.termosConfirmaLabel}>
              <input
                id="termos-uso"
                type="checkbox"
                onClick={() => {
                  setLiConcordo(!liConcordo);
                }}
              />
              <label htmlFor="termos-uso">
                Li e concordo com os{" "}
                <a target="_blank" href={termosDeUsoURL}>
                  termos de uso
                </a>
                ,{" "}
                <a target="_blank" href={politicaDePrivacidadeURL}>
                  política de privacidade
                </a>{" "}
                e{" "}
                <a target="_blank" href={politicaDeConteudoURL}>
                  política de conteúdo
                </a>
                .
              </label>
            </div>
            {carregando ? (
              <Carregando botao />
            ) : (
              <Button
                title="Confirmar aceite"
                importancia="primario"
                disabled={!liConcordo}
                onClick={editaTermosUsuario}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
