import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterCredenciais, obterTokens } from "../../../utils/LocalStorage";
import {
  postDocumentosLegais,
  tipoDocumento,
} from "../../../services/documentosLegais";

export interface FuncoesCaixaFlutuanteAtualizarTermosProps {
  arquivo: string;
  setArquivo: React.Dispatch<React.SetStateAction<string>>;
  setNomeArquivo: React.Dispatch<React.SetStateAction<string>>;
  requerRenovacaoAceite: boolean;
  setRequerRenovacaoAceite: React.Dispatch<React.SetStateAction<boolean>>;
  tipo: tipoDocumento;
  setTipo: React.Dispatch<React.SetStateAction<tipoDocumento>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  setRecarregarTudo: React.Dispatch<React.SetStateAction<boolean>>;
  setCaixaAbertaTermos: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesCaixaFlutuanteAtualizarTermos = ({
  arquivo,
  setArquivo,
  setNomeArquivo,
  requerRenovacaoAceite,
  setRequerRenovacaoAceite,
  tipo,
  setTipo,
  setCarregando,
  myToast,
  setRecarregarTudo,
  setCaixaAbertaTermos,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesCaixaFlutuanteAtualizarTermosProps) => {
  const sucessoRequisicao = () => {
    setRecarregarTudo((state) => !state);
    showToast("success", "Atualizado com sucesso", "Documento atualizada!");
    setCaixaAbertaTermos(false);
  };

  const erroRequisicao = () => {
    showToast(
      "error",
      "Atualização não realizada",
      "Não foi possível atualizar o documento."
    );
  };
  function atualizarTermos() {
    const { token } = obterTokens();
    const idUsuarioResponsavel = obterCredenciais().credenciais.id;

    setCarregando(true);

    postDocumentosLegais({
      token,
      arquivo,
      dataCriacao: new Date().toISOString(),
      idUsuarioResponsavel,
      requerRenovacaoAceite,
      tipo,
    })
      .then(() => {
        sucessoRequisicao();
        LimparStates([setArquivo, setTipo]);
        setRequerRenovacaoAceite(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postDocumentosLegais({
              token,
              arquivo,
              dataCriacao: new Date().toISOString(),
              idUsuarioResponsavel,
              requerRenovacaoAceite,
              tipo,
            })
              .then(() => {
                sucessoRequisicao();
                LimparStates([setArquivo, setTipo]);
              })
              .catch((err) => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  function confirmarAtualizacao(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    atualizarTermos();
  }

  function fazConverteArquivoPara64(event: any) {
    converteImgPara64(event, setArquivo, true, setNomeArquivo);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    atualizarTermos,
    confirmarAtualizacao,
    fazConverteArquivoPara64,
    showToast,
  };
};
