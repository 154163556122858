import styles from './styles.module.css';

interface HeaderTemplateProps extends React.HTMLAttributes<HTMLDivElement> {}

export const HeaderTemplate = ({ children }: HeaderTemplateProps) => {
  return (
    <div className={styles.tituloColuna}>
      {children}
    </div>
  );
};