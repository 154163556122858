import { ButtonHTMLAttributes } from "react";

import styles from "./styles.module.css";

export interface BotaoGerarExcelProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: any) => void;
  carregando?: boolean;
}

export const BotaoGerarExcel = ({
  onClick,
  carregando,
}: BotaoGerarExcelProps) => (
  <button onClick={onClick} className={styles.botaoGerarXls} type="submit">
    {carregando ? (
      <i
        className="pi pi-spinner pi-spin"
        style={{ fontSize: "1.5rem" }}
        title="Carregando"
      />
    ) : (
      <i
        className="pi pi-file-excel"
        style={{ fontSize: "1.5rem" }}
        title="Gerar XLS"
      />
    )}
  </button>
);
