import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FunctionListarUsuarioNetcos } from "./functions";

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { usuarioNetcosProps } from "../../../services/usuarioNetcos";
import { TabelaListarUsuarioNetcos } from "../../Tabelas/TabelaListarUsuarioNetCos";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export const ListarUsuariosNetcos = () => {
  const [numeroPagina, setNumeroPagina] = useState(0);
  const [usuariosNetcosTotais, setUsuariosNetcosTotais] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState(99999);
  const [nomeUsuarioNetcosPesquisa, setNomeUsuarioNetcosPesquisa] =
    useState<string>("");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");
  const [listaDeUsuariosNetcos, setListaDeUsuariosNetcos] = useState<
    usuarioNetcosProps[]
  >([]);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [abrirModalDeslogar, setAbrirModalDeslogar] = useState<boolean>(false);
  const [carregandoPesquisa, setCarregandoPesquisa] = useState<boolean>(false);

  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { listarTodosUsuariosNetcos, pesquisaUsuarioNetcosPorNome } =
    FunctionListarUsuarioNetcos({
      numeroPagina: numeroPagina + 1,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao,
      setErro,
      setCarregando,
      setListaDeUsuariosNetcos,
      setAbrirModalDeslogar,
      setUsuariosNetcosTotais,
      nomeUsuarioNetcosPesquisa,
      setCarregandoPesquisa,
      adicionarAListaEsperaRenovacaoToken,
    });

  useEffect(() => {
    nomeUsuarioNetcosPesquisa
      ? pesquisaUsuarioNetcosPorNome()
      : listarTodosUsuariosNetcos();
  }, [
    numeroPagina,
    tamanhoPagina,
    tipoOrdenacao,
    recarregar,
    nomeUsuarioNetcosPesquisa,
  ]);

  const colunas: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Email", ordenacao: "email" },
    { nome: "Telefone", ordenacao: "telefone" },
    { nome: "Status", ordenacao: "status" },
    { nome: "Ações", ordenacao: "" },
  ];

  return (
    <div className={styles.containerListarUsuarioNetcos}>
      <TabelaListarUsuarioNetcos
        setErro={setErro}
        carregandoPesquisa={carregandoPesquisa}
        erro={erro}
        setRecarregar={() => setRecarregar(!recarregar)}
        tituloTabela={"Usuários NetCos"}
        tituloColuna={colunas}
        carregando={carregando}
        setCarregando={setCarregando}
        myToast={myToast}
        nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
        tipoOrdenacao={tipoOrdenacao}
        setTipoOrdenacao={setTipoOrdenacao}
        setNomeUsuarioNetcosPesquisa={setNomeUsuarioNetcosPesquisa}
        listaUsuarioNetCos={listaDeUsuariosNetcos}
        // paginacao={
        //   <Paginator
        //     first={numeroPagina}
        //     rows={1}
        //     totalRecords={usuariosNetcosTotais / tamanhoPagina}
        //     onPageChange={(e) => setNumeroPagina(e.first)}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e) => setTamanhoPagina(Number(e.target.value))}
        //     totalLista={usuariosNetcosTotais}
        //     nomeLista={"usuários da NetCos"}
        //   />
        // }
      />
    </div>
  );
};
