import { useState, useEffect, useRef, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsListarUsuariosMarca } from "./functions";

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { TabelaListarUsuariosMarca } from "../../Tabelas/TabelaListarUsuariosMarca";
import { ModalCadastroUsuarioMarca } from "../../Modais/ModalCadastroUsuarioMarca";
import { statusType } from "../../Select/SelectStatus";

import { usuarioMarcaProps } from "../../../services/usuarioMarca";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ListarUsuariosMarcaProps {
  showToast: (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    stickyValue: boolean
  ) => void;
}

export const ListarUsuariosMarca = ({
  showToast,
}: ListarUsuariosMarcaProps) => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [nome, setNome] = useState<string>("");
  const [modalCadastro, setModalCadastro] = useState<boolean>(false);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [propriedadeOrdenacao, setPropriedadeOrdenacao] =
    useState<string>("nome");
  const [numeroPagina, setNumeroPagina] = useState(0);
  const [listaUsuarios, setListaUsuarios] = useState<usuarioMarcaProps[]>([]);
  const [, setUsuariosTotais] = useState<number>(0);
  const [tamanhoPagina] = useState(99999);
  const [idMarcaFiltro, setIdMarcaFiltro] = useState<string>("");
  const [statusFiltro, setStatusFiltro] = useState<statusType>("");

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const {
    obterUsuarios,
    obterUsuariosPorNome,
    obterUsuariosPorMarca,
    obterUsuariosPorStatus,
  } = FunctionsListarUsuariosMarca({
    setListaUsuarios,
    adicionarAListaEsperaRenovacaoToken,
    setErro,
    setCarregando,
    setUsuariosTotais,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
    tipoOrdenacao,
    propriedadeOrdenacao,
    nome,
    idMarcaFiltro,
    statusFiltro,
  });

  useEffect(() => {
    nome === ""
      ? idMarcaFiltro === "" || idMarcaFiltro === undefined
        ? statusFiltro === "" || statusFiltro === undefined
          ? obterUsuarios()
          : obterUsuariosPorStatus()
        : obterUsuariosPorMarca()
      : obterUsuariosPorNome();
  }, [
    numeroPagina,
    tamanhoPagina,
    recarregar,
    nome,
    tipoOrdenacao,
    propriedadeOrdenacao,
    idMarcaFiltro,
    statusFiltro,
  ]);
  const colunas: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Marca", ordenacao: "marca" },
    { nome: "Email", ordenacao: "email" },
    { nome: "Telefone", ordenacao: "telefone" },
    { nome: "Status", ordenacao: "status" },
    { nome: "Ações", ordenacao: "" },
  ];

  const myToast = useRef<any>(null);

  return (
    <div className={styles.containerListarUsuariosMarca}>
      <TabelaListarUsuariosMarca
        setErro={setErro}
        showToast={showToast}
        setFiltroIdMarca={setIdMarcaFiltro}
        idMarcaFiltro={idMarcaFiltro}
        setFiltroStatus={setStatusFiltro}
        status={statusFiltro}
        myToast={myToast}
        setCarregando={setCarregando}
        setModalCadastroUsuarioMarca={setModalCadastro}
        listaUsuarios={listaUsuarios}
        erro={erro}
        setRecarregar={() => setRecarregar(!recarregar)}
        tituloTabela={"Usuários Marca"}
        tituloColuna={colunas}
        propriedadeOrdenacao={propriedadeOrdenacao}
        setPropriedadeOrdenacao={setPropriedadeOrdenacao}
        carregando={carregando}
        nomeBusca={nome}
        setNomeBusca={setNome}
        setTipoOrdenacao={setTipoOrdenacao}
        tipoOrdenacao={tipoOrdenacao}
        // paginacao={
        //   <Paginator
        //     first={numeroPagina}
        //     rows={1}
        //     totalRecords={usuariosTotais / tamanhoPagina}
        //     onPageChange={(e) => setNumeroPagina(e.first)}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e) => setTamanhoPagina(Number(e.target.value))}
        //     totalLista={usuariosTotais}
        //     nomeLista={"usuários de marca"}
        //   />
        // }
      />
      {modalCadastro && (
        <ModalCadastroUsuarioMarca
          modalAberto={modalCadastro}
          setModalAberto={setModalCadastro}
          setRecarregar={() => setRecarregar(!recarregar)}
        />
      )}

      <Toast ref={myToast} className="custom-toast" />
    </div>
  );
};
