import { useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesTermosDeUsoListagem } from "./functions";

import { ErroType } from "../../../@types/erro";
import { Documentos } from "../../../services/documentosLegais";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { SelectQtdadeItensLista } from "../../Select/SelectQtadeLista";
import { DateFormatter } from "../../../utils/DataFormatter";
import { tipoOrdenacao } from "../../../@types/ordenacao";

import { Avatar } from "primereact/avatar";
import { Paginator } from "primereact/paginator";

interface TermosDeUsoListagemProps {
  recarregarTudo: boolean;
}

export const PoliticaDePrivacidadeListagem = ({
  recarregarTudo,
}: TermosDeUsoListagemProps) => {
  const [carregando, setCarregando] = useState<boolean>(true);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);

  const [listaDeDocumentos, setListaDeDocumentos] = useState<Documentos[]>([]);
  const [totalDeDocumentos, setTotalDeDocumentos] = useState<number>(0);

  const [tamanhoDaPagina, setTamanhoDaPagina] = useState<number>(5);
  const [numeroDePagina, setNumeroDePagina] = useState<number>(0);
  const [paginatorFirst, setPaginatorFirst] = useState<number>(0);
  const nomePropriedadeOrdenacao = "dataCriacao";
  const tipoOrdenacao: tipoOrdenacao = "Descending";

  useEffect(() => {
    pegaDocumentosLegais();
  }, [recarregar, recarregarTudo, numeroDePagina, tamanhoDaPagina]);

  const { pegaDocumentosLegais } = FuncoesTermosDeUsoListagem({
    setCarregando,
    setErro,
    setListaDeDocumentos,
    setTotalDeDocumentos,
    numeroPagina: numeroDePagina + 1,
    tamanhoPagina: tamanhoDaPagina,
    nomePropriedadeOrdenacao,
    tipoOrdenacao,
  });

  return (
    <table id="tabelaListarAgencias" className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              Histórico Política de Privacidade
            </div>
          </th>
        </tr>

        <tr>
          <th className={styles.headerTituloColunas}>
            <div className={styles.tituloColunaUploader}>Colocado por</div>
          </th>
          <th className={styles.headerTituloColunas}>
            <div className={styles.tituloColuna}>Data de Upload</div>
          </th>
          <th className={styles.headerTituloColunas}>
            <div className={styles.tituloColuna}>Documento</div>
          </th>
        </tr>
      </thead>

      <tbody className={styles.corpoTabela}>
        {carregando ? (
          <Carregando />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela
                erro={erro}
                recarregar={() => setRecarregar(!recarregar)}
              />
            ) : (
              <>
                {listaDeDocumentos.map((documento) => {
                  return (
                    <tr>
                      <td>
                        <div className={styles.tableDataNomeLogo}>
                          <Avatar
                            label={documento.usuarioResponsavel.nome.charAt(0)}
                            size="xlarge"
                            shape="circle"
                            image={documento.usuarioResponsavel.urlAvatar}
                            style={{
                              textTransform: "uppercase",
                              borderRadius: "50%",
                            }}
                          />{" "}
                          {documento.usuarioResponsavel.nome}
                        </div>
                      </td>
                      <td>{DateFormatter(documento.dataCriacao)}</td>
                      <td>
                        <a
                          className={styles.botaoIconeColunaAcoes}
                          href={documento.urlArquivo}
                          target="_blank"
                        >
                          <i
                            className="pi pi-file-pdf"
                            style={{ fontSize: "2rem", paddingRight: "1rem" }}
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </>
        )}
      </tbody>
      <tfoot className={styles.footerTabela}>
        <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            <SelectQtdadeItensLista
              onChange={(e: any) => setTamanhoDaPagina(e.target.value)}
              totalLista={totalDeDocumentos}
              nomeLista="Políticas de Privacidade"
            />
          </div>
          <Paginator
            first={paginatorFirst}
            rows={tamanhoDaPagina}
            totalRecords={totalDeDocumentos}
            onPageChange={(e) => {
              setNumeroDePagina(e.page);
              setPaginatorFirst(e.first);
            }}
          />
        </div>
      </tfoot>
    </table>
  );
};
