import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { tipoDocumento } from '../../../services/documentosLegais';

import styles from './styles.module.css';


export interface selectTipoDocumentoProps {
  setTipo?: React.Dispatch<React.SetStateAction<tipoDocumento>>,
  tipo?: tipoDocumento,
}

export const SelectTipoDocumento = ({ setTipo, tipo }: selectTipoDocumentoProps) => {

  const documentos = [
    {
      nome: 'Termos de Uso',
      value: 'TermosDeUso'
    },
    {
      nome: 'Politica de Privacidade',
      value: 'PoliticaDePrivacidade'
    },
    {
      nome: 'Politica de Conteudo',
      value: 'PoliticaDeConteudo'
    }
  ]


  return (
    <div className={styles.conteudoSelectTermos}>
      <Dropdown
        emptyMessage={"Nenhum tipo de documento encontrado"}
        placeholder={"Selecione um tipo de documento"}
        id="select-documento"
        value={tipo}
        options={documentos}
        onChange={(e) => setTipo && setTipo(e.target.value)}
        optionValue="value"
        optionLabel="nome"
        filterPlaceholder='Encontre um perfil'
        emptyFilterMessage={'Nenhum perfil com esses termos encontrado.'}
        showClear
        style={{
          width: "100%",
          color: '#fff',
          outline: 'none',
          boxShadow: 'none',
          fontFamily: 'Exo 2',
          height: '3.5rem',
        }}
        panelStyle={{
          fontSize: '1.2rem',
        }}
      />
    </div>
  )
}