import { ButtonHTMLAttributes } from "react";
import styles from "./styles.module.css";

export interface BotaoImprimirProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: any) => void;
}

export const BotaoImprimir = ({ onClick }: BotaoImprimirProps) => (
  <button onClick={onClick} className={styles.botaoImprimir} type="submit">
    {<i className="pi pi-print" style={{ fontSize: "1.5rem" }} title="Imprimir"/>}
  </button>
);
