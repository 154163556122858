import { InputMetrica } from "./inputs/InputMetrica";

interface TwitterProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  views: number;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  cliquesLink: number;
  setCliquesLink: React.Dispatch<React.SetStateAction<number>>;
  engajamentos: number;
  setEngajamentos: React.Dispatch<React.SetStateAction<number>>;
  favoritos: number;
  setFavoritos: React.Dispatch<React.SetStateAction<number>>;
  retweets: number;
  setRetweets: React.Dispatch<React.SetStateAction<number>>;
  detailExpands: number;
  setDetailExpands: React.Dispatch<React.SetStateAction<number>>;
  cliquesHashtag: number;
  setCliquesHashtag: React.Dispatch<React.SetStateAction<number>>;
}

export const Twitter = ({
  aprovar,
  desabilitarCampos,
  comentarios,
  setComentarios,
  views,
  setViews,
  retweets,
  setRetweets,
  detailExpands,
  setDetailExpands,
  cliquesHashtag,
  setCliquesHashtag,
  impressoes,
  setImpressoes,
  cliquesLink,
  setCliquesLink,
  engajamentos,
  setEngajamentos,
  favoritos,
  setFavoritos

}: TwitterProps) => {
  const listaMetricas = [
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Impressoes", metrica: { valor: impressoes, dispatch: setImpressoes } },
    { nome: "Views", metrica: { valor: views, dispatch: setViews } },
    { nome: "Cliques no Link da Bio", metrica: { valor: cliquesLink, dispatch: setCliquesLink } },
    { nome: "Engajamentos", metrica: { valor: engajamentos, dispatch: setEngajamentos } },
    { nome: "Favoritos", metrica: { valor: favoritos, dispatch: setFavoritos } },
    { nome: "Retweets", metrica: { valor: retweets, dispatch: setRetweets } },
    { nome: "Cliques na Hashtag", metrica: { valor: cliquesHashtag, dispatch: setCliquesHashtag } },
    { nome: "Details Expands", metrica: { valor: detailExpands, dispatch: setDetailExpands } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}
    </>
  )
}