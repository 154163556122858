import { useState, useEffect } from "react";

import styles from "./styles.module.css";
import { FuncoesTabelaMetricasPorInfluenciador } from "./functions";

import { PublicacoesProps } from "../../../services/publicacao";
import { format } from "../../../utils/MascaraNumero/format";
import { tipoPublicacaoProps } from "../../../services/campanha";

export interface TabelaMetricasPorInfluenciadorProps {
  publicacoes: PublicacoesProps[];
  taxaDeEngajamentoIgFeed: number;
  taxaDeEngajamentoIgReels: number;
  taxaDeEngajamentoIgStories: number;
  taxaDeEngajamentoTwitter: number;
  taxaDeEngajamentoTikTok: number;
  taxaDeEngajamentoYoutubeVideo: number;
  taxaDeEngajamentoYoutubeShort: number;
  setTabViewIndiceAtivo: React.Dispatch<React.SetStateAction<number>>;
  setTabViewConteudosEMetricasIndice: React.Dispatch<React.SetStateAction<number>>;
  setSessaoDestino: React.Dispatch<React.SetStateAction<string>>;
}

type esconderElementoType =
  | "alcance"
  | "impressoes"
  | "views"
  | "engajamento"
  | "espectadores"
  | "comentarios"
  | "taxaEngajamento"
  | "taxaAprovacao";

export const TabelaMetricasPorInfluenciador = ({
  publicacoes,
  taxaDeEngajamentoIgFeed,
  taxaDeEngajamentoIgReels,
  taxaDeEngajamentoIgStories,
  taxaDeEngajamentoTikTok,
  taxaDeEngajamentoTwitter,
  taxaDeEngajamentoYoutubeShort,
  taxaDeEngajamentoYoutubeVideo,
  setTabViewIndiceAtivo,
  setTabViewConteudosEMetricasIndice,
  setSessaoDestino
}: TabelaMetricasPorInfluenciadorProps) => {
  // tem tipo de publicação?
  const [publicacaoIgFeed, setPublicacaoIgFeed] = useState<boolean>(false);
  const [publicacaoIgReels, setPublicacaoIgReels] = useState<boolean>(false);
  const [publicacaoIgStories, setPublicacaoIgStories] = useState<boolean>(false);
  const [publicacaoTwitter, setPublicacaoTwitter] = useState<boolean>(false);
  const [publicacaoTikTok, setPublicacaoTikTok] = useState<boolean>(false);
  const [publicacaoYouTubeVideo, setPublicacaoYouTubeVideo] = useState<boolean>(false);
  const [publicacaoYouTubeShort, setPublicacaoYouTubeShort] = useState<boolean>(false);

  // alcance
  const [alcanceIgFeed, setAlcanceIgFeed] = useState<number>(0);
  const [alcanceIgReels, setAlcanceIgReels] = useState<number>(0);
  const [alcanceIgStories, setAlcanceIgStories] = useState<number>(0);
  const [alcanceTikTok, setAlcanceTikTok] = useState<number>(0);

  //impressões
  const [impressoesIgFeed, setImpressoesIgFeed] = useState<number>(0);
  const [viewsIgReels, setViewsIgReels] = useState<number>(0);
  const [impressoesIgStories, setImpressoesIgStories] = useState<number>(0);
  const [impressoesTwitter, setImpressoesTwitter] = useState<number>(0);
  const [impressoesYouTubeVideo, setImpressoesYouTubeVideo] = useState<number>(0);
  const [impressoesYouTubeShort, setImpressoesYouTubeShort] = useState<number>(0);

  //Views
  const [viewsTwitter, setViewsTwitter] = useState<number>(0);
  const [viewsTikTok, setViewsTikTok] = useState<number>(0);
  const [viewsYouTubeVideo, setViewsYouTubeVideo] = useState<number>(0);
  const [viewsYouTubeShort, setViewsYouTubeShort] = useState<number>(0);

  //Engajamento
  const [engajamentoIgFeed, setEngajamentoIgFeed] = useState<number>(0);
  const [engajamentoIgReels, setEngajamentoIgReels] = useState<number>(0);
  const [engajamentoIgStories, setEngajamentoIgStories] = useState<number>(0);
  const [engajamentoTwitter, setEngajamentoTwitter] = useState<number>(0);
  const [engajamentoTikTok, setEngajamentoTikTok] = useState<number>(0);
  const [engajamentoYouTubeVideo, setEngajamentoYouTubeVideo] = useState<number>(0);
  const [engajamentoYouTubeShort, setEngajamentoYouTubeShort] = useState<number>(0);

  //Espectadores
  const [espectadoresYouTubeVideo, setEspectadoresYouTubeVideo] = useState<number>(0);
  const [espectadoresYouTubeShort, setEspectadoresYouTubeShort] = useState<number>(0);

  //Comentários
  const [comentariosYouTubeVideo, setComentariosYouTubeVideo] = useState<number>(0);
  const [comentariosYouTubeShort, setComentariosYouTubeShort] = useState<number>(0);
  const [comentariosTiktok, setComentariosTiktok] = useState<number>(0);
  const [comentariosIgFeed, setComentariosIgFeed] = useState<number>(0);
  const [comentariosIgReels, setComentariosIgReels] = useState<number>(0);
  const [comentariosTwitter, setComentariosTwitter] = useState<number>(0);

  //Taxa de aprovação
  const [taxaDeAprovacaoYouTubeVideo, setTaxaDeAprovacaoYouTubeVideo] = useState<number>(0);
  const [taxaDeAprovacaoYouTubeShort, setTaxaDeAprovacaoYouTubeShort] = useState<number>(0);

  const { setaDadosDaTabela } = FuncoesTabelaMetricasPorInfluenciador({
    publicacoes,
    setAlcanceIgFeed,
    setAlcanceIgReels,
    setAlcanceIgStories,
    setAlcanceTikTok,
    setComentariosYouTubeShort,
    setComentariosYouTubeVideo,
    setComentariosIgFeed,
    setComentariosIgReels,
    setComentariosTiktok,
    setComentariosTwitter,
    setEngajamentoIgFeed,
    setEngajamentoIgReels,
    setEngajamentoIgStories,
    setEngajamentoTikTok,
    setEngajamentoTwitter,
    setEngajamentoYouTubeShort,
    setEngajamentoYouTubeVideo,
    setEspectadoresYouTubeShort,
    setEspectadoresYouTubeVideo,
    setImpressoesIgFeed,
    setImpressoesIgStories,
    setImpressoesTwitter,
    setImpressoesYouTubeShort,
    setImpressoesYouTubeVideo,
    setPublicacaoIgFeed,
    setPublicacaoIgReels,
    setPublicacaoIgStories,
    setPublicacaoTikTok,
    setPublicacaoTwitter,
    setPublicacaoYouTubeShort,
    setPublicacaoYouTubeVideo,
    setTaxaDeAprovacaoYouTubeShort,
    setTaxaDeAprovacaoYouTubeVideo,
    setViewsIgReels,
    setViewsTikTok,
    setViewsTwitter,
    setViewsYouTubeShort,
    setViewsYouTubeVideo,
  });

  useEffect(() => {
    setaDadosDaTabela();
  }, []);

  const existeAlcance =
    alcanceIgFeed || alcanceIgReels || alcanceIgStories || alcanceTikTok;
  const existeImpressoes =
    impressoesIgFeed ||
    viewsIgReels ||
    impressoesIgStories ||
    impressoesTwitter ||
    impressoesYouTubeShort ||
    impressoesYouTubeVideo;
  const existeViews =
    viewsTikTok ||
    viewsTwitter ||
    viewsYouTubeShort ||
    viewsYouTubeVideo;
  const existeEngajamento =
    engajamentoIgFeed ||
    engajamentoIgReels ||
    engajamentoIgStories ||
    engajamentoTikTok ||
    engajamentoYouTubeVideo ||
    engajamentoYouTubeShort ||
    engajamentoTwitter;
  const existeEspectadores =
    espectadoresYouTubeShort || espectadoresYouTubeVideo;
  const existeComentarios =
    comentariosYouTubeShort ||
    comentariosYouTubeVideo ||
    comentariosIgFeed ||
    comentariosIgReels ||
    comentariosTwitter ||
    comentariosTiktok;
  const existeTaxaDeEngajamento =
    taxaDeEngajamentoIgFeed ||
    taxaDeEngajamentoIgReels ||
    taxaDeEngajamentoIgStories ||
    taxaDeEngajamentoTikTok ||
    taxaDeEngajamentoTwitter ||
    taxaDeEngajamentoYoutubeShort ||
    taxaDeEngajamentoYoutubeVideo;
  const existeTaxaDeAprovacao =
    taxaDeAprovacaoYouTubeShort || taxaDeAprovacaoYouTubeVideo;

  // em ves de fazer a função mudar apanas uma variável

  function esconderElemento(tipo: esconderElementoType): string {
    let className = "";

    const existe = (existe: number) =>
      existe ? className : (className = styles.escondido);

    switch (tipo) {
      case "alcance":
        existe(existeAlcance);
        break;
      case "comentarios":
        existe(existeComentarios);
        break;
      case "engajamento":
        existe(existeEngajamento);
        break;
      case "espectadores":
        existe(existeEspectadores);
        break;
      case "impressoes":
        existe(existeImpressoes);
        break;
      case "taxaAprovacao":
        existe(existeTaxaDeAprovacao);
        break;
      case "taxaEngajamento":
        existe(existeTaxaDeEngajamento);
        break;
      case "views":
        existe(existeViews);
        break;
    }

    return className;
  }

  const mudarAbaParaConteudosEMetricas = (tipoPublicacao: tipoPublicacaoProps) => {
    setTabViewIndiceAtivo(1);
    setTabViewConteudosEMetricasIndice((tipoPublicacao === "PublicacaoInstagramFeed"
      || tipoPublicacao === "PublicacaoInstagramReels"
      || tipoPublicacao === "PublicacaoInstagramStories") ? 0 : tipoPublicacao === "PublicacaoTikTok" ? 1 : tipoPublicacao === "PublicacaoTwitter" ? 2 : 3)
    setSessaoDestino(tipoPublicacao)
  }

  return (
    <table className={styles.tabelaBenchmarking}>
      <thead>
        <tr>
          <th className={`${styles.headerTabela}`}>Tipo de publicação</th>

          {existeAlcance ? (
            <th className={`${styles.headerTabela} ${esconderElemento("alcance")}`} >
              Alcance
            </th>
          ) : (
            <></>
          )}

          {existeImpressoes ? (
            <th
              className={`${styles.headerTabela} ${esconderElemento("impressoes")}`}>
              Impressões
            </th>
          ) : (
            <></>
          )}

          {existeViews ? (
            <th className={`${styles.headerTabela} ${esconderElemento("views")}`}>
              Views
            </th>
          ) : (
            <></>
          )}

          {existeEngajamento ? (
            <th className={`${styles.headerTabela} ${esconderElemento("engajamento")}`}>
              Engajamentos
            </th>
          ) : (
            <></>
          )}

          {existeEspectadores ? (
            <th className={`${styles.headerTabela} ${esconderElemento("espectadores")}`}>
              Espectadores
            </th>
          ) : (
            <></>
          )}

          {existeComentarios ? (
            <th className={`${styles.headerTabela} ${esconderElemento("comentarios")}`} >
              Comentários
            </th>
          ) : (
            <></>
          )}

          {existeTaxaDeEngajamento ? (
            <th className={`${styles.headerTabela} ${esconderElemento("taxaEngajamento")}`} >
              Taxa de engajamento
            </th>
          ) : (
            <></>
          )}

          {existeTaxaDeAprovacao ? (
            <th className={`${styles.headerTabela} ${esconderElemento("taxaAprovacao")}`}>
              Taxa de aprovação
            </th>
          ) : (
            <></>
          )}
        </tr>
      </thead>

      <tbody>
        {publicacaoIgFeed ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoInstagramFeed")}>Instagram Feed</td>
            <td className={esconderElemento("alcance")}>
              {format("#.###.##0,#0", alcanceIgFeed).replace(",00", "")}
            </td>
            <td className={esconderElemento("impressoes")}>
              {format("#.###.##0,#0", impressoesIgFeed).replace(",00", "")}
            </td>
            <td className={esconderElemento("views")}></td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoIgFeed).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}></td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosIgFeed).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoIgFeed)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}></td>
          </tr>
        ) : (
          <></>
        )}

        {publicacaoIgReels ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoInstagramReels")}>Instagram Reels</td>
            <td className={esconderElemento("alcance")}>
              {format("#.###.##0,#0", alcanceIgReels).replace(",00", "")}
            </td>
            <td className={esconderElemento("impressoes")}>
              <div className={styles.tooltip}>
                {format("#.###.##0,#0", viewsIgReels).replace(",00", "")}
                <span className={styles.tooltiptext}>Reproduções</span>
              </div>
            </td>
            <td className={esconderElemento("views")}></td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoIgReels).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}></td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosIgReels).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoIgReels)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}></td>
          </tr>
        ) : (
          <></>
        )}
        {publicacaoIgStories ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoInstagramStories")}>Instagram Stories</td>
            <td className={esconderElemento("alcance")}>
              {format("#.###.##0,#0", alcanceIgStories).replace(",00", "")}
            </td>
            <td className={esconderElemento("impressoes")}>
              {format("#.###.##0,#0", impressoesIgStories).replace(",00", "")}
            </td>
            <td className={esconderElemento("views")}></td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoIgStories).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}></td>
            <td className={esconderElemento("comentarios")}></td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoIgStories)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}></td>
          </tr>
        ) : (
          <></>
        )}
        {publicacaoTikTok ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoTikTok")}>TikTok</td>
            <td className={esconderElemento("alcance")}>
              {format("#.###.##0,#0", alcanceTikTok).replace(",00", "")}
            </td>
            <td className={esconderElemento("impressoes")}>
              <div className={styles.tooltip}>
                {format("#.###.##0,#0", viewsTikTok).replace(",00", "")}
                <span className={styles.tooltiptext}>Views</span>
              </div>
            </td>
            <td className={esconderElemento("views")}>
              {format("#.###.##0,#0", viewsTikTok).replace(",00", "")}
            </td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoTikTok).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}>
              {format("#.###.##0,#0", alcanceTikTok).replace(",00", "")}
            </td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosTiktok).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoTikTok)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}></td>
          </tr>
        ) : (
          <></>
        )}
        {publicacaoTwitter ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoTwitter")}>Twitter</td>
            <td className={esconderElemento("alcance")}></td>
            <td className={esconderElemento("impressoes")}>
              {format("#.###.##0,#0", impressoesTwitter).replace(",00", "")}
            </td>
            <td className={esconderElemento("views")}>
              {format("#.###.##0,#0", viewsTwitter).replace(",00", "")}
            </td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoTwitter).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}></td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosTwitter).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoTwitter)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}></td>
          </tr>
        ) : (
          <></>
        )}
        {publicacaoYouTubeVideo ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoYouTube")}>YouTube Video</td>
            <td className={esconderElemento("alcance")}>
              <div className={styles.tooltip}>
                {format("#.###.##0,#0", espectadoresYouTubeVideo).replace(",00", "")}
                <span className={styles.tooltiptext}>Espectadores</span>
              </div>
            </td>
            <td className={esconderElemento("impressoes")}>
              {format("#.###.##0,#0", impressoesYouTubeVideo).replace(",00", "")}
            </td>
            <td className={esconderElemento("views")}>
              {format("#.###.##0,#0", viewsYouTubeVideo).replace(",00", "")}
            </td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoYouTubeVideo).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}>
              {format("#.###.##0,#0", espectadoresYouTubeVideo).replace(",00", "")}
            </td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosYouTubeVideo).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoYoutubeVideo)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}>
              {format("#.###.##0,#0%", taxaDeAprovacaoYouTubeVideo)}
            </td>
          </tr>
        ) : (
          <></>
        )}
        {publicacaoYouTubeShort ? (
          <tr>
            <td onClick={() => mudarAbaParaConteudosEMetricas("PublicacaoYouTubeShorts")}>YouTube Shorts</td>
            <td className={esconderElemento("alcance")}>
              <div className={styles.tooltip}>
                {format("#.###.##0,#0", espectadoresYouTubeShort).replace(",00", "")}
                <span className={styles.tooltiptext}>Espectadores</span>
              </div>
            </td>
            <td className={esconderElemento("impressoes")}>
              {format("#.###.##0,#0", impressoesYouTubeShort).replace(",00", "")}
            </td>
            <td className={esconderElemento("views")}>
              {format("#.###.##0,#0", viewsYouTubeShort).replace(",00", "")}
            </td>
            <td className={esconderElemento("engajamento")}>
              {format("#.###.##0,#0", engajamentoYouTubeShort).replace(",00", "")}
            </td>
            <td className={esconderElemento("espectadores")}>
              {format("#.###.##0,#0", espectadoresYouTubeShort).replace(",00", "")}
            </td>
            <td className={esconderElemento("comentarios")}>
              {format("#.###.##0,#0", comentariosYouTubeShort).replace(",00", "")}
            </td>
            <td className={esconderElemento("taxaEngajamento")}>
              {format("#.###.##0,#0%", taxaDeEngajamentoYoutubeShort)}
            </td>
            <td className={esconderElemento("taxaAprovacao")}>
              {format("#.###.##0,#0%", taxaDeAprovacaoYouTubeShort)}
            </td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};
