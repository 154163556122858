import { AxiosResponse } from "axios";
import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import {
  campanha,
  getCampanhaTodasAgencia,
  respostaGetCampanha,
  getCampanhaporStatus,
  getCampanhaTodasAgenciaNome,
  getCampanhaTodasPorAgenciaPorMarca,
  getCampanhaTodasPorAgenciaPorPeriodo,
} from "../../../services/campanha";

import { getUsuarioAgencia } from "../../../services/usuarioAgencia";
import { obterTokens } from "../../../utils/LocalStorage";

interface FuncaoListagemCampanhaUsuarioAgenciaProps {
  idMarca: string;
  idAgencia: string;
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  nomePesquisa: string;
  numeroDePagina: number;
  tamanhoDePagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setTamanhoDePagina: React.Dispatch<React.SetStateAction<number>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  setListaDeCampanhas: React.Dispatch<campanha[]>;
  setTotalCampanhas: React.Dispatch<number>;
  dataInicial: string;
  dataFinal: string;
  statusCampanha: number | undefined;
  idCredenciais: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncaoListagemCampanhaUsuarioAgencia = ({
  dataInicial,
  dataFinal,
  idCredenciais,
  idAgencia,
  setIdAgencia,
  idMarca,
  nomePesquisa,
  numeroDePagina,
  tamanhoDePagina,
  tipoOrdenacao,
  nomePropriedadeOrdenacao,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setListaDeCampanhas,
  setTotalCampanhas,
  statusCampanha,
}: FuncaoListagemCampanhaUsuarioAgenciaProps) => {
  const sucessoRequisicao = (res: AxiosResponse<respostaGetCampanha, any>) => {
    setListaDeCampanhas(res.data.campanhas);
    setTotalCampanhas(res.data.totalRegistros);
  };

  const erroRequisicao = () => {
    setErro({
      ativo: true,
      mensagem: "Erro ao carregar a lista de Campanhas.",
    });
  };

  const obterUsuarioAgencia = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioAgencia(token, idCredenciais)
      .then((res1) => {
        obterCampanhasPorAgencia(res1.data.usuario.agencia.id, token);
        setIdAgencia(res1.data.usuario.agencia.id);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioAgencia(token, idCredenciais)
              .then((res3) => {
                obterCampanhasPorAgencia(res3.data.usuario.agencia.id, token);
                setIdAgencia(res3.data.usuario.agencia.id);
              })
              .catch(() => {
                erroRequisicao();
              });
          });
        } else {
          erroRequisicao();
        }
      });
  };

  const obterCampanhasPorAgencia = (idAgencia: string, token: string) => {
    setErro({
      ativo: false,
      mensagem: "",
    });

    getCampanhaTodasAgencia(
      token,
      idAgencia,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
      })
      .catch(() => {
        erroRequisicao();
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const obterCampanhaAgenciaPorNome = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasAgenciaNome(
      token,
      nomePesquisa,
      idAgencia,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasAgenciaNome(
              token,
              nomePesquisa,
              idAgencia,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhaAgenciaPorMarca = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorAgenciaPorMarca(
      token,
      idMarca,
      idAgencia,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasPorAgenciaPorMarca(
              token,
              idMarca,
              idAgencia,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorPeriodo = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaTodasPorAgenciaPorPeriodo(
      token,
      idAgencia,
      dataInicial,
      dataFinal,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaTodasPorAgenciaPorPeriodo(
              token,
              idAgencia,
              dataInicial,
              dataFinal,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterCampanhasPorStatus = () => {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getCampanhaporStatus(
      token,
      statusCampanha!,
      numeroDePagina,
      tamanhoDePagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getCampanhaporStatus(
              token,
              statusCampanha!,
              numeroDePagina,
              tamanhoDePagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                sucessoRequisicao(res);
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  return {
    obterUsuarioAgencia,
    obterCampanhaAgenciaPorNome,
    obterCampanhaAgenciaPorMarca,
    obterCampanhasPorPeriodo,
    obterCampanhasPorStatus,
  };
};
