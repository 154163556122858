import { tarefaProps } from "./tarefas";
import { AxiosResponse } from "axios";
import { agenciaNaCampanhaProps, agenciaProps } from "./agencia";
import { api } from "./api";
import { getMarcaProps } from "./marca";
import { printsDeSentimentoPropsGet } from "./publicacao";

import { respostaPostUsuarioNetcos } from "./usuarioNetcos";

export interface respostaPostCampanha extends respostaPostUsuarioNetcos { }

export interface postCampanhaProps {
  idAgencia: string;
  campanha: {
    idMarcas: string[];
    nome: string;
    dataInicio: string;
    dataFim: string;
    tarefas: cadastroTarefasProps[];
  };
}

export interface campanha {
  id: string;
  nome: string;
  dataInicio: string;
  dataFim: string;
  comentarios: string;
  valorInvestido: number;
  numeroImpressoes: number;
  custoPorMilImpressoes: number;
  mediaTaxaEngajamento: number;
  custoEngajamento: number;
  alcanceTotal: number;
  dataCriacaoRelatorio: string;
  engajamentoTotal: number;
  taxaEngajamentoOutrasPublicacoes: number;
  agencia: agenciaNaCampanhaProps;
  marcas: getMarcaProps[];
  tarefas: tarefaProps[];

  acimaMediaNetcos: boolean;

  twitterTaxaEngajamento: number;
  twitterImpressoes: number;
  twitterEngajamento: number;
  twitterCPM: number;
  twitterCPE: number;

  tiktokTaxaEngajamento: number;
  tiktokImpressoes: number;
  tiktokEngajamento: number;
  tiktokCPM: number;
  tiktokCPE: number;

  youtubeTotalTaxaEngajamento: number;
  youtubeTotalImpressoes: number;
  youtubeTotalEngajamento: number;
  youtubeTotalCPM: number;
  youtubeTotalCPE: number;

  youtubeVideoTaxaEngajamento: number;
  youtubeVideoImpressoes: number;
  youtubeVideoEngajamento: number;
  youtubeVideoCPM: number;
  youtubeVideoCPE: number;

  youtubeShortsTaxaEngajamento: number;
  youtubeShortsImpressoes: number;
  youtubeShortsEngajamento: number;
  youtubeShortsCPM: number;
  youtubeShortsCPE: number;

  instagramTotalTaxaEngajamento: number;
  instagramTotalImpressoes: number;
  instagramTotalEngajamento: number;
  instagramTotalCPM: number;
  instagramTotalCPE: number;

  instagramFeedTaxaEngajamento: number;
  instagramFeedImpressoes: number;
  instagramFeedEngajamento: number;
  instagramFeedCPM: number;
  instagramFeedCPE: number;

  instagramReelsTaxaEngajamento: number;
  instagramReelsImpressoes: number;
  instagramReelsEngajamento: number;
  instagramReelsCPM: number;
  instagramReelsCPE: number;

  instagramStoriesTaxaEngajamento: number;
  instagramStoriesImpressoes: number;
  instagramStoriesEngajamento: number;
  instagramStoriesCPM: number;
  instagramStoriesCPE: number;
}

export interface cadastroTarefasProps {
  valorContrato: number;
  idInfluenciador: string;
  perfilInfluenciadorNoFechamentoDoContrato: string;
  nomeInfluenciador: string;
  emailInfluenciador: string;
  publicacoes: Array<
    | cadastroPublicacoesProps
    | cadastroPublicacaoInstagramFeedProps
    | cadastroPublicacaoInstagramReelsProps
    | cadastroPublicacaoInstagramStoriesProps
    | cadastroPublicacaoTikTokProps
    | cadastroPublicacaoTwitterProps
    | cadastroPublicacaoYouTubeProps
  >;
}

export type tipoPublicacaoProps =
  | "PublicacaoInstagramFeed"
  | "PublicacaoInstagramReels"
  | "PublicacaoInstagramStories"
  | "PublicacaoTikTok"
  | "PublicacaoTwitter"
  | "PublicacaoYouTube"
  | "PublicacaoYouTubeShorts";

export interface cadastroPublicacoesProps {
  numeroIdentificadorDePublicacao: number;
  tipoPublicacao: tipoPublicacaoProps;
  externalId: string;
  printsDeMetrica: printsDeSentimentoPropsGet[];
  printsDeSentimento: printsDeSentimentoPropsGet[];
  dataFinalizacao: Date;
  sentimento: string;
  status?: string;
  tipo?: string;
}

export interface cadastroPublicacaoInstagramFeedProps
  extends cadastroPublicacoesProps {
  alcance: number;
  impressoes: number;
  likes: number;
  comentarios: number;
  compartilhamentos: number;
}
export interface cadastroPublicacaoInstagramReelsProps
  extends cadastroPublicacoesProps {
  likes: number;
  comentarios: number;
  views: number;
}
export interface cadastroPublicacaoInstagramStoriesProps
  extends cadastroPublicacoesProps {
  alcance: number;
  impressoes: number;
  salvos: number;
  visitasPerfil: number;
  cliquesLink: number;
  compartilhamentos: number;
}

export interface cadastroPublicacaoTikTokProps
  extends cadastroPublicacoesProps {
  views: number;
  viewsTotais: number;
  tempoReproducao: number;
  likes: number;
  comentarios: number;
  compartilhamentos: number;
  salvos: number;
  publicoAtingido: string;
  taxaEngajamento: number;
}

export interface cadastroPublicacaoTwitterProps
  extends cadastroPublicacoesProps {
  impressoes: number;
  engajamentos: number;
  favoritos: number;
  respostas: number;
  retweets: number;
}

export interface cadastroPublicacaoYouTubeProps
  extends cadastroPublicacoesProps {
  views: number;
  likes: number;
  dislikes: number;
  tempoMedioReproducao: number;
}

export const postCampanha = (
  token: string,
  campanha: postCampanhaProps
): Promise<respostaPostCampanha> => {
  const url = "/Campanha";

  return api.post(url, campanha, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export interface respostaGetCampanha {
  codigo: number;
  totalRegistros: number;
  campanhas: campanha[];
}

export interface respostaGetCampanhaId {
  codigo: number;
  campanha: campanha;
}

export const getCampanhaId = (
  token: string,
  id: string
): Promise<AxiosResponse<respostaGetCampanhaId, any>> => {
  const url = `/Campanha/Id?Id=${id}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanha = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasContratante = (
  token: string,
  IdContratante: string,
  NumeroPagina: number,
  TamanhoPagina: number
): Promise<respostaGetCampanha> => {
  const url = `/Campanha/Todas/Contratante?IdContratante=${IdContratante}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasNome = (
  token: string,
  nome: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Nome?Nome=${nome}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasMarca = (
  token: string,
  idMarca: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Marca?IdMarca=${idMarca}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasAgencia = (
  token: string,
  idAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Agencia?IdAgencia=${idAgencia}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPeriodo = (
  token: string,
  dataInicial: string,
  dataFinal: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Periodo?DataInicial=${dataInicial}&DataFinal=${dataFinal}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasCampanhasInfluenciadorPorID = (
  token: string,
  IdInfluenciador: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  nomePropriedadeOrdenacao: string,
  tipoOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Influenciador/Id?IdInfluenciador=${IdInfluenciador}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&TipoOrdenacao=${tipoOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasCampanhasInfluenciadorPorAgencia = (
  token: string,
  IdInfluenciador: string,
  IdAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  nomePropriedadeOrdenacao: string,
  tipoOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Influenciador/Agencia?IdInfluenciador=${IdInfluenciador}&IdAgencia=${IdAgencia}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&TipoOrdenacao=${tipoOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasCampanhasInfluenciadorPorMarca = (
  token: string,
  IdInfluenciador: string,
  IdMarca: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  nomePropriedadeOrdenacao: string,
  tipoOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Influenciador/Marca?IdInfluenciador=${IdInfluenciador}&IdMarca=${IdMarca}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&TipoOrdenacao=${tipoOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasCampanhasInfluenciadorPorNome = (
  token: string,
  nome: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  nomePropriedadeOrdenacao: string,
  tipoOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Influenciador/nome?nome=${nome}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&TipoOrdenacao=${tipoOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export interface putCampanhaProps {
  id: string;
  nome: string | null;
  dataInicio: string | null;
  dataFim: string | null;
  comentarios: string | null;
  taxaEngajamentoOutrasPublicacoes?: number | null;
}

interface putCampanhaResposta extends respostaGetCampanhaId { }

export const putCampanha = (
  token: string,
  campanha: putCampanhaProps
): Promise<AxiosResponse<putCampanhaResposta | any>> => {
  const url = `/Campanha`;

  return api.put(
    url,
    { campanha: campanha },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getCampanhaporStatus = (
  token: string,
  status: number,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Status?Status=${status}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}&TipoOrdenacao=${tipoOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putCampanhaFechar = (
  token: string,
  idCampanha: string
): Promise<AxiosResponse<putCampanhaResposta | any>> => {
  const url = `/Campanha/Fechar`;

  return api.put(
    url,
    {
      idCampanha,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

// USUARIO MARCA
export const getCampanhaTodasPorMarcasPorNome = (
  token: string,
  nome: string,
  idMarca: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Marca/Nome?IdMarca=${idMarca}&NomeCampanha=${nome}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPorMarcasPorAgencia = (
  token: string,
  IdMarca: string,
  IdAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Marca/Agencia?IdMarca=${IdMarca}&IdAgencia=${IdAgencia}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPorMarcasPorPeriodo = (
  token: string,
  marcaId: string,
  dataInicial: string,
  dataFinal: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Marca/Periodo?MarcaId=${marcaId}&DataInicial=${dataInicial}&DataFinal=${dataFinal}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// USUARIO AGENCIA
export const getCampanhaTodasAgenciaNome = (
  token: string,
  nome: string,
  idAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Agencia/Nome?IdAgencia=${idAgencia}&NomeCampanha=${nome}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPorAgenciaPorMarca = (
  token: string,
  IdMarca: string,
  IdAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Agencia/Marca?IdMarca=${IdMarca}&IdAgencia=${IdAgencia}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPorAgenciaPorNome = (
  token: string,
  nome: string,
  IdAgencia: string,
  NumeroPagina: number,
  TamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Agencia/Nome?NomeCampanha=${nome}&IdAgencia=${IdAgencia}&NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCampanhaTodasPorAgenciaPorPeriodo = (
  token: string,
  agenciaId: string,
  dataInicial: string,
  dataFinal: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<respostaGetCampanha, any>> => {
  const url = `/Campanha/Todas/Agencia/Periodo?IdAgencia=${agenciaId}&DataInicial=${dataInicial}&DataFinal=${dataFinal}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

interface putCampanhaEnviarRelatorioProps {
  token: string;
  idCampanha: string;
  arquivoRelatorio: string;
  enviarParaUsuariosMarca: boolean;
  enviarParaUsuariosAgencia: boolean;
  destinatariosExtras: string[];
}

export interface destinatariosExtrasProps {
  name: string;
  email: string;
}

export const putCampanhaEnviarRelatorio = ({
  token,
  idCampanha,
  arquivoRelatorio,
  destinatariosExtras,
  enviarParaUsuariosAgencia,
  enviarParaUsuariosMarca,
}: putCampanhaEnviarRelatorioProps) => {
  const url = `/Campanha/EnviarRelatorio`;

  return api.put(
    url,
    {
      idCampanha,
      arquivoRelatorio,
      extensaoArquivoRelatorio: "pdf",
      enviarParaUsuariosAgencia,
      enviarParaUsuariosMarca,
      destinatariosExtras,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

interface deleteCampanhaProps {
  idCampanha: string;
}

export const deleteCampanha = (
  token: string,
  idCampanha: string
): Promise<AxiosResponse<deleteCampanhaProps, any>> => {
  const url = "/Campanha/";

  const data = {
    data: {
      idCampanha,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return api.delete(url, data);
};
