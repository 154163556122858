import { useContext } from "react";

import { postAgencia } from "../../../services/agencia";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsBotaoCadastroAgenciaProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  logotipo: string;
  setLogotipo: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  botaoAbertoAgencia: boolean;
  setBotaoAbertoAgencia: React.Dispatch<React.SetStateAction<boolean>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsBotaoCadastroAgencia = ({
  logotipo,
  setLogotipo,
  nome,
  setNome,
  setCarregando,
  setRecarregar,
  setBotaoAbertoAgencia,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsBotaoCadastroAgenciaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = (mensagem: string) => {
    mostrarToast(
      "success",
      "Cadastro realizado",
      mensagem
    );
    LimparStates([setNome, setLogotipo]);
    setRecarregar((recarregar) => !recarregar);
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Cadastro não realizado",
      mensagem
    );
  };

  const erroAgenciaSemNome = () => {
    setCarregando(false);
    setTimeout(() => setBotaoAbertoAgencia(false), 1000);
    mostrarToast(
      "error",
      "Cadastro não realizado",
      "Não é possível cadastrar uma Agência sem nome."
    );
  };

  const salvarAgenciaButton = () => {
    setCarregando(true);
    const { token } = obterTokens();

    nome !== "" ?
      postAgencia(token, { nome, logotipo })
        .then((res) => {
          sucessoRequisicao(res.data.mensagem);
          setTimeout(() => setBotaoAbertoAgencia(false), 1000);
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await postAgencia(token, { nome, logotipo })
                .then((res2) => {
                  sucessoRequisicao(res2.data.mensagem);
                  setTimeout(() => setBotaoAbertoAgencia(false), 1000);
                })
                .catch((err2) => {
                  erroRequisicao(
                    err2.response.data.mensagem
                      ? err2.response.data.mensagem
                      : err2.response.data.message);
                });
            }, setCarregando);
          } else {
            erroRequisicao(
              err.response.data.mensagem
                ? err.response.data.mensagem
                : err.response.data.message);
            setCarregando(false);
          }
        })
      :
      erroAgenciaSemNome()
  };

  return {
    salvarAgenciaButton,
  };
};
