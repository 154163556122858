import { ErroType } from "../../@types/erro";
import { Prints } from "../../services/publicacao";
import { getTarefasPorCampanha } from "../../services/tarefas";
import { obterTokens } from "../../utils/LocalStorage";

interface FuncaoModalTarefasProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  setPrintsSentimento: React.Dispatch<React.SetStateAction<Prints[]>>;
  setTotalDeTarefas: React.Dispatch<React.SetStateAction<number>>;
  setIdTarefa: React.Dispatch<React.SetStateAction<string>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export interface putTarefaRequest {
  idCampanha: string;
  tarefa: {
    idTarefa: string;
    printsDeSentimento: Prints[];
  };
}

export const FuncoesRepercussao = ({
  idCampanha,
  idInfluenciadorSelecionado,
  setPrintsSentimento,
  setTotalDeTarefas,
  setIdTarefa,
  setErro,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
}: FuncaoModalTarefasProps) => {
  const obterTarefaEPrints = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTarefasPorCampanha(token, idCampanha)
      .then((res) => {
        let tarefa = res.data.tarefas.find(
          (tarefa) =>
            tarefa.influenciador.idCredenciais === idInfluenciadorSelecionado
        );
        setPrintsSentimento(tarefa ? tarefa.printsDeSentimento : []);
        setTotalDeTarefas(res.data.totalRegistros);
        setIdTarefa(tarefa ? tarefa.externalId : "");
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getTarefasPorCampanha(token, idCampanha)
              .then((res) => {
                let tarefa = res.data.tarefas.find(
                  (tarefa) =>
                    tarefa.influenciador.idCredenciais ===
                    idInfluenciadorSelecionado
                );
                setPrintsSentimento(tarefa ? tarefa.printsDeSentimento : []);
                setTotalDeTarefas(res.data.totalRegistros);
                setIdTarefa(tarefa ? tarefa.externalId : "");
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de Prints.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de Prints.",
          });
          setCarregando(false);
        }
      });
  };

  return {
    obterTarefaEPrints,
  };
};
