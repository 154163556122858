import { ErroType } from "../../../@types/erro";
import { getSenhaSolicitarRedefinicao } from "../../../services/usuario";

interface FunctionsModalRedefinicaoProps {
  email: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setSucesso: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
}

export const FunctionsModalRedefinicao = ({
  email,
  setCarregando,
  setModalAberto,
  setSucesso,
  setEmail,
  setErro,
}: FunctionsModalRedefinicaoProps) => {
  function solicitaRedefinicao() {
    setErro({
      ativo: false,
      mensagem: "",
    });
    setCarregando(true);
    getSenhaSolicitarRedefinicao(email)
      .then((res) => {
        setSucesso(true);
      })
      .catch((err) => {
        let mensagem = "";
        switch (err.response.status) {
          case 400:
            mensagem = "Email inválido";
            break;
          case 500:
            mensagem = "Usuário não cadastrado em nosso sistema.";
            break;
          default:
            mensagem = "Ocorreu um erro inesperado";
            break;
        }
        setErro({
          ativo: true,
          mensagem: mensagem,
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  }

  function fechaModal() {
    setSucesso(false);
    setErro({
      ativo: false,
      mensagem: "",
    });
    setEmail("");
    setCarregando(false);
    setModalAberto(false);
  }

  return {
    solicitaRedefinicao,
    fechaModal,
  };
};
