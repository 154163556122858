import { SetStateAction, useEffect, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsTabelaListarCampanha } from "./functions";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { BotaoFiltro } from "../../Botoes/BotaoFiltro";
import { ErroType } from "../../../@types/erro";
import { campanha } from "../../../services/campanha";
import { DateFormatter } from "../../../utils/DataFormatter";
import { ModalCadastroDeCampanha } from "../../Modais/ModalCadastroDeCampanha";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TituloColuna } from "../../TituloColuna";
import { FiltrosTabelaCampanhas } from "../../Filtros/FiltrosTabelaCampanhas";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { mascaraBRLExibir } from "../../../utils/MascaraBRL";
import { CardCampanha } from "../../Cards/CardCampanha";
import { CaixaFlutuanteVisualizacaoCampanha } from "../../CaixasFlutuantes/CaixaFlutuanteVisualizacaoCampanha";
import { format } from "../../../utils/MascaraNumero/format";
import { BotaoGerarExcel } from "../../Botoes/BotaoGerarExcel";

import { useNavigate } from "react-router-dom";
import { BsRecordCircleFill } from "react-icons/bs";
import { Avatar } from "primereact/avatar";
import { IoTrendingUpSharp, IoTrendingDownSharp } from "react-icons/io5";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

type perfils =
  | "Administrador"
  | "UsuarioNetcos"
  | "Influenciador"
  | "UsuarioMarca"
  | "UsuarioAgencia"
  | "";

export interface TabelaListarAgenciasProps {
  tituloTabela: string;
  tituloColuna: TitulosTabelasType[];
  listaDeCampanhas: campanha[];
  // paginacao: any;
  // seletorDeQuantidade: any;
  erro: ErroType;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  carregando: boolean;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  carregandoPesquisa: boolean;
  setNomeCampanhaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  myToast: React.MutableRefObject<any>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  setDesconectar: React.Dispatch<React.SetStateAction<boolean>>;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  setIdMarcaFiltro: React.Dispatch<SetStateAction<string>>;
  idMarcaFiltro: string;
  setIdAgenciaFiltro: React.Dispatch<SetStateAction<string>>;
  idAgenciaFiltro: string;
  datas: Date | Date[] | undefined;
  setDatas: React.Dispatch<SetStateAction<Date | Date[] | undefined>>;
  statusCampanha: number | undefined;
  setStatusCampanha: React.Dispatch<number | undefined>;
  perfil?: perfils;
  totalCampanhas: number;
  setTamanhoPagina: React.Dispatch<React.SetStateAction<number>>;
  tamanhoPagina: number;
  criaPDFListaDeCampanhas: () => void;
  gerarXLSXListaDeCampanhas: () => void;
  carregandoXlS: boolean;
}

export const TabelaListarCampanhas = ({
  tituloTabela,
  tituloColuna,
  listaDeCampanhas,
  // paginacao,
  // seletorDeQuantidade,
  erro,
  carregando,
  carregandoPesquisa,
  setNomeCampanhaPesquisa,
  setRecarregar,
  tipoOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  idAgenciaFiltro,
  idMarcaFiltro,
  setIdAgenciaFiltro,
  setIdMarcaFiltro,
  datas,
  setDatas,
  setStatusCampanha,
  statusCampanha,
  perfil,
  criaPDFListaDeCampanhas,
  gerarXLSXListaDeCampanhas,
  carregandoXlS,
  setCarregando,
}: TabelaListarAgenciasProps) => {
  const [modalAbertoCampanha, setModalAbertoAbertoCampanha] =
    useState<boolean>(false);
  const [idCampanha, setIdCampanha] = useState<string>("");
  const [nomeCampanha, setNomeCampanha] = useState<string>("");
  const [visualizacaoBenchmarking, setVisualizacaoBenchmarking] =
    useState<boolean>(false);
  const [caixaAbertaVisualizacao, setCaixaAbertaVisualizacao] =
    useState<boolean>(false);

  const [tamanhoPagina, setTamanhoPagina] = useState<number>(0);
  const [recarregando, setRecarregando] = useState<boolean>(false);
  const [totalCampanhas, setTotalCampanhas] = useState<number>(0);

  const navigate = useNavigate();
  const hoje = new Date().toISOString();

  let carregandoImprecao = false;

  const colunasBenchmarking: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Data Início", ordenacao: "dataInicio" },
    { nome: "Data Fim", ordenacao: "dataFim" },
    { nome: "Data do Relatório", ordenacao: "dataCriacaoRelatorio" },
    { nome: "Feed", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Reels", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Stories", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Tiktok", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Twitter", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Youtube", ordenacao: "", title: "Taxa de engajamento" },
    { nome: "Youtube Shorts", ordenacao: "", title: "Taxa de engajamento" },
  ];

  useEffect(() => {
    carregandoImprecao = carregando;
  }, [carregando]);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { deletarCampanha } = FunctionsTabelaListarCampanha({
    setRecarregar,
    setRecarregando,
    carregando,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    totalCampanhas,
    tamanhoPagina,
    setTamanhoPagina,
  });

  return (
    <>
      <div className={styles.containerTabela}>
        <thead>
          <tr>
            <th className={styles.headerTabela}>
              <div className={styles.tituloTabela}>
                <div className={styles.botaoModalVisualizacao}>
                  <h1>{tituloTabela}</h1>
                  {(!perfil ||
                    perfil === "Administrador" ||
                    perfil === "UsuarioNetcos") && (
                      <Button
                        importancia="secundario"
                        onClick={() => setCaixaAbertaVisualizacao(true)}
                      >
                        <i
                          className="pi pi-angle-down"
                          style={{ fontSize: "1.8rem" }}
                        />
                      </Button>
                    )}
                  {caixaAbertaVisualizacao && (
                    <CaixaFlutuanteVisualizacaoCampanha
                      caixaAbertaVisualizacao={caixaAbertaVisualizacao}
                      setCaixaAbertaVisualizacao={setCaixaAbertaVisualizacao}
                      setVisualizacaoBenchmarking={setVisualizacaoBenchmarking}
                    />
                  )}
                </div>
                <div className={styles.botaoModalAdcAgencia}>
                  <Button
                    importancia="secundario"
                    onClick={() => setModalAbertoAbertoCampanha(true)}
                  >
                    {
                      <i
                        className="pi pi-plus-circle"
                        style={{ fontSize: "1.8rem" }}
                      />
                    }
                    Cadastrar campanha
                  </Button>
                </div>
              </div>

              <div className={styles.botoesIconeHeaderContainer}>
                <BotaoBusca
                  carregandoPesquisa={carregandoPesquisa}
                  onChange={(e) => setNomeCampanhaPesquisa(e.target.value)}
                />
                <BotaoImprimir onClick={criaPDFListaDeCampanhas} />
                {visualizacaoBenchmarking && (
                  <BotaoGerarExcel
                    carregando={carregandoXlS}
                    onClick={gerarXLSXListaDeCampanhas}
                  />
                )}
                <BotaoFiltro>
                  <FiltrosTabelaCampanhas
                    setStatusCampanha={setStatusCampanha}
                    statusCampanha={statusCampanha}
                    idAgenciaFiltro={idAgenciaFiltro}
                    idMarcaFiltro={idMarcaFiltro}
                    setIdAgenciaFiltro={setIdAgenciaFiltro}
                    setIdMarcaFiltro={setIdMarcaFiltro}
                    datas={datas}
                    setDatas={setDatas}
                  />
                </BotaoFiltro>
              </div>
            </th>
          </tr>

          <tr className={styles.tituloColuna}>
            {visualizacaoBenchmarking
              ? colunasBenchmarking.map((titulo) => {
                return (
                  <TituloColuna
                    key={colunasBenchmarking.indexOf(titulo)}
                    colunaIndex={colunasBenchmarking.indexOf(titulo)}
                    tituloColuna={titulo.nome}
                    iconOrdenacao={[0, 1, 2, 3]}
                    tipoOrdenacao={tipoOrdenacao}
                    tituloOrdenacao={titulo.ordenacao}
                    setTipoOrdenacao={setTipoOrdenacao}
                    nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                    setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                    fontSize={"1.4rem"}
                    title={titulo.title}
                  />
                );
              })
              : tituloColuna.map((titulo) => {
                return (
                  <TituloColuna
                    key={tituloColuna.indexOf(titulo)}
                    colunaIndex={tituloColuna.indexOf(titulo)}
                    tituloColuna={titulo.nome}
                    iconOrdenacao={[0, 3, 4, 5, 6, 7]}
                    tipoOrdenacao={tipoOrdenacao}
                    tituloOrdenacao={titulo.ordenacao}
                    setTipoOrdenacao={setTipoOrdenacao}
                    nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                    setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                    fontSize={"1.4rem"}
                  />
                );
              })}
          </tr>
        </thead>

        <tbody id="corpoTabelaListarAgencias" className={styles.corpoTabela}>
          {carregando ? (
            <Carregando
              listaUsuarios={listaDeCampanhas.length}
              spinner={true}
            />
          ) : (
            <>
              {erro.ativo ? (
                <ErroTabela erro={erro} recarregar={setRecarregar} />
              ) : (
                <>
                  {listaDeCampanhas.length === 0 ? (
                    <tr>
                      <td style={{ justifyContent: "center" }}>
                        Nenhum resultado a ser exibido.
                      </td>
                    </tr>
                  ) : visualizacaoBenchmarking ? (
                    listaDeCampanhas.map((campanha) => {
                      return (
                        <tr
                          onClick={() => {
                            setIdCampanha(campanha.id);
                            setNomeCampanha(campanha.nome);
                          }}
                        >
                          <td
                            className={styles.colunaNome}
                            onClick={() => {
                              ((!perfil ||
                                perfil == "Administrador" ||
                                perfil == "UsuarioNetcos") &&
                                navigate(
                                  `/usuariosNetcos/detalhes-campanha/${campanha.id}`
                                )) ||
                                (perfil == "UsuarioAgencia" &&
                                  navigate(
                                    `/usuario-agencia/detalhes-campanha/${campanha.id}`
                                  ));
                            }}
                          >
                            <p title={campanha.nome}>{campanha.nome}</p>
                          </td>
                          <td>{DateFormatter(campanha.dataInicio)}</td>
                          <td>{DateFormatter(campanha.dataFim)}</td>
                          <td>
                            {campanha.dataCriacaoRelatorio !==
                              "0001-01-01T00:00:00" ? (
                              DateFormatter(campanha.dataCriacaoRelatorio)
                            ) : (
                              <p title="Relatório não gerado">Não gerado</p>
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.instagramFeedTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.instagramReelsTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.instagramStoriesTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.tiktokTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.twitterTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.youtubeVideoTaxaEngajamento
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.youtubeShortsTaxaEngajamento
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    listaDeCampanhas.map((campanha) => {
                      return (
                        <tr
                          onClick={() => {
                            setIdCampanha(campanha.id);
                            setNomeCampanha(campanha.nome);
                          }}
                        >
                          <td
                            className={styles.colunaNome}
                            onClick={() => {
                              ((!perfil ||
                                perfil == "Administrador" ||
                                perfil == "UsuarioNetcos") &&
                                navigate(
                                  `/usuariosNetcos/detalhes-campanha/${campanha.id}`
                                )) ||
                                (perfil == "UsuarioAgencia" &&
                                  navigate(
                                    `/usuario-agencia/detalhes-campanha/${campanha.id}`
                                  ));
                            }}
                          >
                            <p title={campanha.nome}>{campanha.nome}</p>
                          </td>

                          {perfil != "UsuarioAgencia" && (
                            <td>
                              <div className={styles.celulaComImagem}>
                                {campanha.agencia.nome === "Agência Netcos" ? (
                                  <p></p>
                                ) : (
                                  <>
                                    <Avatar
                                      label={campanha.agencia.nome.charAt(0)}
                                      shape="circle"
                                      size="xlarge"
                                      image={
                                        campanha.agencia.urlLogotipo
                                          ? campanha.agencia.urlLogotipo
                                          : campanha.agencia.urlLogotipo
                                      }
                                      style={{
                                        textTransform: "uppercase",
                                        borderRadius: "50%",
                                      }}
                                      title={campanha.agencia.nome}
                                    />
                                    <p>{campanha.agencia.nome}</p>
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                          <td>
                            {campanha.marcas.map((marca) => {
                              return (
                                <div
                                  key={marca.id}
                                  className={styles.celulaComImagem}
                                >
                                  <Avatar
                                    label={marca.nome.charAt(0)}
                                    shape="circle"
                                    size="xlarge"
                                    image={marca.urlLogotipo}
                                    style={{
                                      textTransform: "uppercase",
                                      borderRadius: "50%",
                                    }}
                                    title={marca.nome}
                                  />
                                  <p>{marca.nome}</p>
                                </div>
                              );
                            })}
                          </td>
                          <td>{DateFormatter(campanha.dataInicio)}</td>
                          <td>{DateFormatter(campanha.dataFim)}</td>
                          <td>
                            {campanha.dataCriacaoRelatorio !==
                              "0001-01-01T00:00:00" ? (
                              DateFormatter(campanha.dataCriacaoRelatorio)
                            ) : (
                              <p title="Relatório não gerado">Não gerado</p>
                            )}
                          </td>
                          <td>
                            {format(
                              "#.###.##0,#0%",
                              campanha.mediaTaxaEngajamento
                            )}
                            <div
                              title={
                                campanha.acimaMediaNetcos
                                  ? "Acima da média Netcos"
                                  : "Abaixo da média Netcos"
                              }
                              className={
                                campanha.acimaMediaNetcos
                                  ? styles.containerIconPositivo
                                  : styles.containerIconNegativo
                              }
                            >
                              {campanha.acimaMediaNetcos ? (
                                <IoTrendingUpSharp />
                              ) : (
                                <IoTrendingDownSharp />
                              )}
                            </div>
                          </td>
                          <td
                            title={
                              "R$ " +
                              mascaraBRLExibir(
                                campanha.custoEngajamento.toFixed(2)
                              )
                            }
                          >
                            R${" "}
                            {mascaraBRLExibir(
                              campanha.custoEngajamento.toFixed(2)
                            )}
                          </td>
                          <td>
                            {campanha.dataFim < hoje ? (
                              <div
                                className={`${styles.status} ${styles.statusEncerrado}`}
                              >
                                <BsRecordCircleFill
                                  style={{
                                    color: "var(--cinza-400)",
                                    fontSize: "1rem",
                                  }}
                                />
                                <p>Encerrada</p>
                              </div>
                            ) : (
                              <div
                                className={`${styles.status} ${styles.statusAtivo}`}
                              >
                                <BsRecordCircleFill
                                  style={{
                                    color: "var(--verde-400)",
                                    fontSize: "1rem",
                                  }}
                                />
                                <p>Ativa</p>
                              </div>
                            )}
                          </td>
                          {/* <td>
                            <BotaoColunaAcoes
                              icone={"pi-trash"}
                              titulo={"Excluir Campanha"}
                              status={"Aprovado" || "Pendente"}
                              onClick={
                               ()=> setModalAbertoDesativacao(true)
                              }
                            />
                          </td> */}
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </>
          )}
        </tbody>
        <div className={styles.listaCardsCampanhas}>
          {listaDeCampanhas.length > 0 ? (
            listaDeCampanhas.map((campanha, index) => {
              return (
                <CardCampanha
                  campanha={campanha}
                  onClick={() => {
                    ((!perfil ||
                      perfil == "Administrador" ||
                      perfil == "UsuarioNetcos") &&
                      navigate(
                        `/usuariosNetcos/detalhes-campanha/${campanha.id}`
                      )) ||
                      (perfil == "UsuarioAgencia" &&
                        navigate(
                          `/usuario-agencia/detalhes-campanha/${campanha.id}`
                        ));
                  }}
                  index={index}
                />
              );
            })
          ) : (
            <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
          )}
        </div>
        {/* 
        <tfoot className={styles.footerTabela}>
          <div className={styles.containerItensFooter}>
            <div className={styles.containerSelectNumPorPagina}>
              {seletorDeQuantidade}
            </div>
            {paginacao}
          </div>
        </tfoot> */}
        {modalAbertoCampanha && (
          <ModalCadastroDeCampanha
            modalAberto={modalAbertoCampanha}
            setModalAberto={setModalAbertoAbertoCampanha}
            setRecarregar={setRecarregar}
          />
        )}
        {/* {modalAbertoDesativacao && (
          <ModalDesativacao
            modalAberto={modalAbertoDesativacao}
            setModalAberto={setModalAbertoDesativacao} 
            nomeItemParaDesativar={nomeCampanha} 
            onClick={deletarCampanha}            
          />
        )} */}
      </div>
    </>
  );
};
