import { useContext, useState } from "react";

import styles from "./styles.module.css";

import { FunctionsModalAdicaoDeComentariosCampanha } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";

import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface ModalAdicaoDeComentariosCampanhaProps {
  perfilUsuario: string;
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  id?: string;
  comentario: string;
  recarregar: () => void;
}

export const ModalAdicaoDeComentariosCampanha = ({
  perfilUsuario,
  modalAberto,
  setModalAberto,
  id,
  comentario,
  recarregar,
}: ModalAdicaoDeComentariosCampanhaProps) => {
  const [comentarios, setComentarios] = useState<any>(comentario);
  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { salvarComentarioDaCampanha } =
    FunctionsModalAdicaoDeComentariosCampanha({
      comentarios,
      id,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      setModalAdicaoDeComentariosCampanhaAberto: setModalAberto,
      recarregar,
    });

  return (
    <div className={styles.modalCompletoEdicaoComentarioNetcos}>
      <Dialog
        className={styles.modalEdicaoComentarioNetcos}
        header={<h1>Comentários sobre a campanha</h1>}
        visible={modalAberto}
        onHide={() => setModalAberto(false)}
        position={"right"}
        draggable={false}
        resizable={false}
      >
        <>
          <div
            className={
              perfilUsuario === "UsuarioNetcos" ||
              perfilUsuario === "Administrador"
                ? `${styles.conteudoModalAdicaoDeComentariosCampanhaNetcos}`
                : `${styles.conteudoModalAdicaoDeComentariosCampanhaOutros}`
            }
          >
            <Editor
              className={styles.editorComentario}
              value={comentarios}
              onTextChange={(e) => setComentarios(e.htmlValue)}
              style={{ height: "30rem" }}
              headerTemplate={
                perfilUsuario === "UsuarioNetcos" ||
                perfilUsuario === "Administrador" ? (
                  ""
                ) : (
                  <></>
                )
              }
              readOnly={
                perfilUsuario === "UsuarioNetcos" ||
                perfilUsuario === "Administrador"
                  ? false
                  : true
              }
            />
          </div>

          {(perfilUsuario === "UsuarioNetcos" ||
            perfilUsuario === "Administrador") && (
            <div className={styles.botoesModalAdicaoDeComentariosCampanha}>
              {!carregando ? (
                <>
                  <Button
                    importancia="terciario"
                    title="Cancelar"
                    onClick={() => setModalAberto(false)}
                  />
                  <Button
                    importancia="primario"
                    title="Confirmar"
                    onClick={salvarComentarioDaCampanha}
                  />
                </>
              ) : (
                <div className={styles.containerCarregando}>
                  <Carregando botao />
                </div>
              )}
            </div>
          )}
        </>
      </Dialog>
    </div>
  );
};
