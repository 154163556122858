import { useContext, useState } from "react";

import styles from "./styles.module.css";

import { Button } from "../../Botoes/Button";
import { BotaoCadastrarAgencia } from "../../Botoes/BotaoCadastrarAgencia";

import { AgenciaContext } from "../../../context/AgenciaContext";

import { Dropdown } from "primereact/dropdown";

export interface listaAgenciasSelectProps {
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  idAgencia: string;
}
export const SelectAgencia = ({
  setIdAgencia,
  idAgencia,
}: listaAgenciasSelectProps) => {
  const [botaoAbertoAgencia, setBotaoAbertoAgencia] = useState<boolean>(false);
  const [, setImagemAvatar] = useState<string>("");

  const {
    erroListaDeAgencia,
    carregandoListaDeAgencias,
    listaDeAgencias,
    recarregarListaDeAgencias,
  } = useContext(AgenciaContext);

  return erroListaDeAgencia.ativo ? (
    <p style={{ zIndex: "1", position: "relative" }}>
      {erroListaDeAgencia.mensagem}
    </p>
  ) : (
    <div className={styles.conteudoSelectAgencia}>
      <Dropdown
        emptyMessage={
          carregandoListaDeAgencias
            ? "Carregando..."
            : "Nenhuma agência cadastrada"
        }
        placeholder={"Selecione uma agência"}
        id="select-influenciador"
        value={idAgencia}
        options={listaDeAgencias.filter((agencia) => agencia.ativo)}
        onChange={(e) => setIdAgencia(e.target.value)}
        optionValue="id"
        optionLabel="nome"
        filter
        resetFilterOnHide
        filterPlaceholder="Encontre uma agência"
        emptyFilterMessage={
          <Button
            importancia="secundario"
            title="Cadastrar Agência"
            onClick={() => setBotaoAbertoAgencia(true)}
          />
        }
        showClear
        filterBy="nome"
      />
      <BotaoCadastrarAgencia
        botaoAbertoAgencia={botaoAbertoAgencia}
        setBotaoAbertoAgencia={setBotaoAbertoAgencia}
        setImg={setImagemAvatar}
        setRecarregar={recarregarListaDeAgencias}
        modal
      />
    </div>
  );
};
