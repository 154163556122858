import { ErroType } from "../../../../@types/erro";
import { campanha } from "../../../../services/campanha";
import PDFMerger from "pdf-merger-js/browser";
import { PublicacoesProps } from "../../../../services/publicacao";
import { tarefaProps } from "../../../../services/tarefas";
import { getConverterImagemUrlParaBase64 } from "../../../../services/converterImagem";
import { gerarRelatorioInicio } from "./1-GerarRelatorioInicial";
import { gerarRelatorioFinal } from "./11-GerarRelatorioFinal";
import { gerarRelatorioImagensPublicacao } from "./10-GerarRelatorioImagensPublicacao";
import { gerarPaginaInstaFeed } from "./2-PaginaRelatorioInstaFeed";
import { DateFormatter } from "../../../../utils/DataFormatter";
import { gerarPaginaInstaReels } from "./3-PaginaRelatorioInstaReels";
import { gerarPaginaTwitter } from "./8-PaginaRelatorioTwitter";
import { gerarPaginaInstaStories } from "./4-PaginaRelatorioInstaStories";
import { gerarPaginaYoutubeVideo } from "./5-PaginaRelatorioYoutubeVideo";
import { gerarPaginaYoutubeShorts } from "./6-PaginaRelatorioYoutubeShorts";
import { gerarPaginaTiktok } from "./7-PaginaRelatorioTiktok";
import { postRelatorio, relatorioProps } from "../../../../services/relatorio";
import { obterTokens } from "../../../../utils/LocalStorage";
import { gerarRelatorioImagensComentarios } from "./9-GerarRelatorioImagensComentarios";
import { gerarPaginaGraficoComparativoInfluenciadores } from "./1.2-PaginaGraficoComparativoInfluenciadores";
import { gerarPaginaComparativoRedesSociais } from "./1.1-PaginaComparativoRedesSociais";
import { gerarPaginaTabelaComparativoInfluenciadores } from "./1.3-PaginaTabelaComparativoInfluenciadores";

export interface funcoesGeracaoDeRelatorioProps {
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregandoGeracaoRelatorio: React.Dispatch<React.SetStateAction<boolean>>;
  setProgressoUpload: React.Dispatch<React.SetStateAction<number>>;
  listaDeTarefas?: tarefaProps[];
  campanha?: campanha;
  myToast?: React.MutableRefObject<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando?:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export async function converterImagemParaBase64(
  urlImagem: string,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
): Promise<string> {
  var data = "";
  await getConverterImagemUrlParaBase64(urlImagem)
    .then((res) => {
      data = res.data.base64;
    })
    .catch((e) => {
      setCarregando(false);
      setErro({
        ativo: true,
        mensagem: "Algo deu errado, tente novamente mais tarde.",
      });
      data = e.name;
    });
  return data;
}

export const funcoesGeracaoDeRelatorio = ({
  setErro,
  setCarregando,
  setCarregandoGeracaoRelatorio,
  setProgressoUpload,
  campanha,
  listaDeTarefas,
  adicionarAListaEsperaRenovacaoToken,
}: funcoesGeracaoDeRelatorioProps) => {
  var listaDePdfs: any[] = [];

  async function gerarRelatorio(baixarRelatorio: boolean): Promise<string> {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    setCarregandoGeracaoRelatorio(true);
    let cancelarGeracaoRelatorio = false;

    const resultadoGerarRelatorioInicio = await gerarRelatorioInicio(
      campanha!,
      listaDePdfs,
      setErro,
      setCarregando
    );
    setProgressoUpload(10);

    if (resultadoGerarRelatorioInicio === "AxiosError") {
      cancelarGeracaoRelatorio = true;
      setCarregandoGeracaoRelatorio(false);

      return "";
    }

    //------------ Código abaixo até linha 140 é para gerar as páginas de tabelas e gráficos, comentado até liberação da NetCos -------------------------

    const resultadoGerarRelatorioComparativoRedes = await gerarPaginaComparativoRedesSociais(
      campanha!,
      listaDePdfs,
      setErro,
      setCarregando
    );
    setProgressoUpload(20);

    if (resultadoGerarRelatorioComparativoRedes === "AxiosError") {
      cancelarGeracaoRelatorio = true;
      setCarregandoGeracaoRelatorio(false);

      return "";
    }

    const resultadoGerarRelatorioGraficoComparativoInflus = await gerarPaginaGraficoComparativoInfluenciadores(
      campanha!,
      listaDePdfs,
      setErro,
      setCarregando
    );
    setProgressoUpload(30);

    if (resultadoGerarRelatorioGraficoComparativoInflus === "AxiosError") {
      cancelarGeracaoRelatorio = true;
      setCarregandoGeracaoRelatorio(false);

      return "";
    }

    const resultadoGerarRelatorioTabelaComparativoInflus = await gerarPaginaTabelaComparativoInfluenciadores(
      campanha!,
      listaDePdfs,
      setErro,
      setCarregando
    );
    setProgressoUpload(40);

    if (resultadoGerarRelatorioTabelaComparativoInflus === "AxiosError") {
      cancelarGeracaoRelatorio = true;
      setCarregandoGeracaoRelatorio(false);

      return "";
    }

    //----------- Checagem para inserir o tipo SOMENTE caso o influenciador tenha publicações daquele tipo ---------------

    for (const tarefa of listaDeTarefas!) {
      let PublicacoesInstagramFeed: PublicacoesProps[] = [];
      let PublicacoesInstagramReels: PublicacoesProps[] = [];
      let PublicacoesInstagramStories: PublicacoesProps[] = [];
      let PublicacoesYoutubeVideo: PublicacoesProps[] = [];
      let PublicacoesYoutubeShorts: PublicacoesProps[] = [];
      let PublicacoesTiktok: PublicacoesProps[] = [];
      let PublicacoesTwitter: PublicacoesProps[] = [];

      PublicacoesInstagramFeed = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoInstagramFeed" && a.status == "Aprovado"
      );
      PublicacoesInstagramReels = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoInstagramReels" && a.status == "Aprovado"
      );
      PublicacoesInstagramStories = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoInstagramStories" && a.status == "Aprovado"
      );
      PublicacoesYoutubeVideo = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoYouTube" && a.status == "Aprovado"
      );
      PublicacoesYoutubeShorts = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoYouTubeShorts" && a.status == "Aprovado"
      );
      PublicacoesTiktok = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoTikTok" && a.status == "Aprovado"
      );
      PublicacoesTwitter = tarefa.publicacoes.filter(
        (a) => a.tipo == "PublicacaoTwitter" && a.status == "Aprovado"
      );

      if (PublicacoesInstagramFeed.length !== 0) {
        const resultadoPaginaInstaFeed = await gerarPaginaInstaFeed(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );
        if (resultadoPaginaInstaFeed === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);

          break;
        }
        setProgressoUpload(50);

        for (const publicacao of PublicacoesInstagramFeed) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );
          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }

      if (PublicacoesInstagramReels.length !== 0) {
        const resultadoPaginaInstaReels = await gerarPaginaInstaReels(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );
        if (resultadoPaginaInstaReels === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);
          break;
        }
        setProgressoUpload(55);

        for (const publicacao of PublicacoesInstagramReels) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }

      if (PublicacoesInstagramStories.length !== 0) {
        const PaginaInstaStories = await gerarPaginaInstaStories(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );
        if (PaginaInstaStories === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);

          break;
        }

        for (const publicacao of PublicacoesInstagramStories) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }
      setProgressoUpload(60);

      if (PublicacoesYoutubeVideo.length !== 0) {
        const resultadoPaginaYoutubeVideo = await gerarPaginaYoutubeVideo(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );
        if (resultadoPaginaYoutubeVideo === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);

          break;
        }

        for (const publicacao of PublicacoesYoutubeVideo) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }
      setProgressoUpload(65);

      if (PublicacoesYoutubeShorts.length !== 0) {
        const resultadoPaginaYoutubeShorts = await gerarPaginaYoutubeShorts(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );

        if (resultadoPaginaYoutubeShorts === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;
          setCarregandoGeracaoRelatorio(false);

          setCarregando(false);

          break;
        }

        for (const publicacao of PublicacoesYoutubeShorts) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }
      setProgressoUpload(70);

      if (PublicacoesTiktok.length !== 0) {
        const resultadoPaginaTiktok = await gerarPaginaTiktok(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );

        if (resultadoPaginaTiktok === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);

          break;
        }

        for (const publicacao of PublicacoesTiktok) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }
      setTimeout(() => { setProgressoUpload(75) }, 3000);

      if (PublicacoesTwitter.length !== 0) {
        const resultadoPaginaTwitter = await gerarPaginaTwitter(
          tarefa,
          listaDePdfs,
          campanha?.mediaTaxaEngajamento!,
          setErro,
          setCarregando
        );

        if (resultadoPaginaTwitter === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;

          setCarregando(false);
          setCarregandoGeracaoRelatorio(false);

          break;
        }

        for (const publicacao of PublicacoesTwitter) {
          const resultadoGerarRelatorioImagensPublicacao =
            await gerarRelatorioImagensPublicacao(
              publicacao,
              tarefa.influenciador,
              listaDePdfs,
              setErro,
              setCarregando
            );

          if (resultadoGerarRelatorioImagensPublicacao === "AxiosError") {
            cancelarGeracaoRelatorio = true;
            setCarregandoGeracaoRelatorio(false);

            break;
          }
        }
      }


      if (
        PublicacoesInstagramFeed.length !== 0 ||
        PublicacoesInstagramReels.length !== 0 ||
        PublicacoesInstagramStories.length !== 0 ||
        PublicacoesYoutubeVideo.length !== 0 ||
        PublicacoesYoutubeShorts.length !== 0 ||
        PublicacoesTiktok.length !== 0 ||
        PublicacoesTwitter.length !== 0
      ) {
        const resultadoGerarRelatorioImagensComentarios =
          await gerarRelatorioImagensComentarios(
            tarefa,
            listaDePdfs,
            setErro,
            setCarregando
          );

        if (resultadoGerarRelatorioImagensComentarios === "AxiosError") {
          setErro({
            ativo: true,
            mensagem: "Algo deu errado, na geração do relatório.",
          });
          cancelarGeracaoRelatorio = true;
          setCarregandoGeracaoRelatorio(false);

          setCarregando(false);

          break;
        }
      }
    }

    //---------------------------------------------------------------------------------------------------------------------
    await gerarRelatorioFinal({
      listaDePdfs,
      setCarregando,
      setErro,
      comentarioCampanha: campanha?.comentarios!,
      adicionarAListaEsperaRenovacaoToken,
    });

    setProgressoUpload(90);
    let arquivo = await UnirPdfs(listaDePdfs, baixarRelatorio);
    setCarregando(false);
    return arquivo ? arquivo.toString() : "";
  }

  async function UnirPdfs(files: Blob[], baixarRelatorio: boolean) {
    const merger = new PDFMerger();

    for (const file of files) {
      await merger.add(file);
    }

    setProgressoUpload(100);
    const mergedPdf = await merger.saveAsBlob();
    const mergedPdfBuffer = await merger.saveAsBuffer();
    let relatorioBase64 = await transformaBlobEmBase64(mergedPdf);

    // await enviarRelatorio(relatorioBase64!.toString())

    if (!baixarRelatorio) {
      let relatorioBase64 = await transformaBlobEmBase64(mergedPdf);
      setCarregandoGeracaoRelatorio(false);
      return relatorioBase64;
    } else {
      const url = URL.createObjectURL(mergedPdf);

      await merger.save(
        campanha!.nome +
        " - " +
        DateFormatter(campanha!.dataCriacaoRelatorio).replace("/", "-")
      );

      setCarregandoGeracaoRelatorio(false);
      return url;
    }
  }

  async function enviarRelatorio(arquivo: string) {
    const { token } = obterTokens();

    let renovandoToken = false;

    setCarregando(true);

    let relatorioParaEnvio: relatorioProps = {
      arquivo: arquivo,
      extensaoArquivo: "pdf",
      idAgencia: campanha!.agencia.id,
      idCampanha: campanha!.id
    }

    console.log(relatorioParaEnvio)

    await postRelatorio(
      token,
      relatorioParaEnvio
    ).then((res) => {
      setCarregando(false);
      window.open(res.data.url, "_blank");
    }).catch((err) => {
      if (err.message.includes("401")) {
        renovandoToken = true;
        adicionarAListaEsperaRenovacaoToken(async (token) => {
          setCarregando(true);
          await postRelatorio(token, relatorioParaEnvio)
            .then((res) => {
              setCarregando(false);
              window.open(res.data.url, "_blank");
            })
            .catch(() => {
              setErro({
                ativo: true,
                mensagem: "Ocorreu um erro durante a geração de relatório.",
              });
              setCarregando(false);
            });
        }, setCarregando);
      } else {
        setErro({
          ativo: true,
          mensagem: "Ocorreu um erro durante a geração de relatório.",
        });
        setCarregando(false);
      }
    });


  }

  return {
    gerarRelatorio,
  };
};

async function transformaBlobEmBase64(mergedPdf: Blob) {
  var reader = new FileReader();

  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject("");
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(mergedPdf);
  });
}
