import { useState } from 'react';

import styles from "./styles.module.css"

import { FunctionsModalReenvioSenha } from './functions';

import { Button } from '../../Botoes/Button';
import { Input } from '../../Input';
import { ModalTeste } from '../ModalTeste';

import { ErroType } from '../../../@types/erro';


interface ModalReenvioSenhaProps {
    modalAberto: boolean;
    setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
    setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
    emailPrevio?: string;
}


export const ModalReenvioSenha = ({ modalAberto, setModalAberto, emailPrevio }: ModalReenvioSenhaProps) => {
    const [email, setEmail] = useState<string>(emailPrevio ? emailPrevio : '');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [erro, setErro] = useState<ErroType>({
        ativo: false,
        mensagem: ''
    });
    const [sucesso, setSucesso] = useState<boolean>(false);

    const { solicitaReenvioSenha } = FunctionsModalReenvioSenha({ email, setCarregando, setSucesso, setEmail, setModalAberto, setErro });

    return (
        <ModalTeste modalAberto={modalAberto} setModalAberto={setModalAberto} titulo="Reenvio do Email de Confirmação">
            <div
                onSubmit={(e) => e.preventDefault()}
                className={styles.conteudoModalReenvioSenha}>
                {sucesso ? (
                    <p className={styles.mensagemSucesso}>
                        {emailPrevio ?
                            "O email de criação de senha inicial foi enviado com sucesso!"
                            :
                            "O email de criação de senha inicial foi enviado com sucesso!"
                        }
                    </p>
                )
                    :
                    (
                        <div className={styles.conteudoInformacoesModalRedefinicao}>

                            <p className={styles.tituloModalRedefinicao}>
                                <p>Tem certeza que deseja enviar o email de criação de senha para o usuário?</p>
                                {emailPrevio ? "O usuário receberá um email para criar sua primeira senha no email abaixo:" : "Insira o seu Email abaixo:"}
                            </p>
                            <div className={styles.containerInputEmail}>
                                {emailPrevio ?
                                    <p>{emailPrevio}</p>
                                    :
                                    <Input
                                        type='email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                }
                            </div>

                            {erro.ativo && <p className={styles.mensagemFalha}>{erro.mensagem}</p>}
                        </div>
                    )
                }
                {carregando ? <p>Carregando...</p> :
                    !sucesso &&
                    <div className={styles.containerBotoesModal}>
                        <Button
                            importancia="terciario"
                            type="button"
                            title="Cancelar"
                            onClick={() => setModalAberto(false)}
                        />
                        <Button importancia='secundario' title='Enviar' type='submit' onClick={solicitaReenvioSenha} />
                    </div>
                }
            </div>
        </ModalTeste>
    );
};