import { useContext } from "react";

import {
  credenciais,
  getEmailConfirmacao,
  getEmailConfirmacaoReenviar,
  getUsuarioCredencias,
  postUsuarioLogin,
  putUsuarioSenhaAlterar,
} from "../../services/usuario";
import { obterTokens, salvarTokens } from "../../utils/LocalStorage";
import { CredenciaisContext } from "../../context/CredenciaisContext";
import { getDocumentosLegais } from "../../services/documentosLegais";
import { NavigateFunction } from "react-router-dom";
import { ErroType } from "../../@types/erro";

export interface FunctionConfirmarEmailProps {
  email: string;
  token: string;
  tipo: string;
  senha: string;
  novaSenha: string;
  novaSenhaConfirmacao: string;
  setConfirmado: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregandoConfirmacaoEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregandoRenovacaoSenha: React.Dispatch<React.SetStateAction<boolean>>;
  setReenviar: React.Dispatch<React.SetStateAction<boolean>>;
  setErro: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<ErroType>>;
  setVerificaDocumento: React.Dispatch<React.SetStateAction<boolean>>;
  credenciais: credenciais;
  setCredenciais: React.Dispatch<React.SetStateAction<credenciais>>;
  setSenhaAlterada: React.Dispatch<React.SetStateAction<boolean>>;
  setTempoSessao: React.Dispatch<React.SetStateAction<boolean>>;
  setErroSenhaAlterada: React.Dispatch<React.SetStateAction<boolean>>;
  setErroReenviar: React.Dispatch<React.SetStateAction<boolean>>;
  setErroConfirmacaoSenha: React.Dispatch<React.SetStateAction<boolean>>;
  setTermosDeUsoURL: React.Dispatch<React.SetStateAction<string>>;
  setPoliticaDePrivacidadeURL: React.Dispatch<React.SetStateAction<string>>;
  setPoliticaDeConteudoURL: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  setCarregandoTermos: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FunctionConfirmarEmail = ({
  email,
  token,
  tipo,
  setConfirmado,
  setCarregandoConfirmacaoEmail,
  setCarregandoRenovacaoSenha,
  setReenviar,
  setErro,
  senha,
  credenciais,
  setCredenciais,
  novaSenha,
  novaSenhaConfirmacao,
  setSenhaAlterada,
  setTempoSessao,
  setErroSenhaAlterada,
  setErroReenviar,
  setErroConfirmacaoSenha,
  setPoliticaDeConteudoURL,
  setPoliticaDePrivacidadeURL,
  setTermosDeUsoURL,
  navigate,
  setCarregandoTermos,
  setError,
  setVerificaDocumento,
}: FunctionConfirmarEmailProps) => {
  const { atualizarCredenciais } = useContext(CredenciaisContext);

  let tokenAcesso: string;
  let tokenRenovacao: string;

  function confirmarEmail() {
    setError({ ativo: false, mensagem: "" });
    setErro(false);
    setConfirmado(true);
    setCarregandoConfirmacaoEmail(true);
    getEmailConfirmacao(email, token)
      .then(async (res) => {
        setErro(false);
        await postUsuarioLogin(email, senha)
          .then((res) => {
            tokenAcesso = res.data.camposAdicionais.tokenAcesso;
            tokenRenovacao = res.data.camposAdicionais.tokenRenovacao;
            salvarTokens(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao
            );
            getUsuarioCredencias(email, tokenAcesso)
              .then((res1) => {
                setCredenciais(res1.data.resultado.credenciais);
                atualizarCredenciais(res1.data.resultado.credenciais);
              })
              .catch((err) => {
                setErro(true);
              });
          })
          .catch((err) => {
            setErro(true);
          });
      })
      .catch((err) => {
        setErro(true);
      })
      .finally(() => setCarregandoConfirmacaoEmail(false));
  }

  function reenviarEmail() {
    setError({ ativo: false, mensagem: "" });
    setErroReenviar(false);
    getEmailConfirmacaoReenviar(email, tipo, senha)
      .then((res) => {
        setReenviar(true);
        setErroReenviar(false);
      })
      .catch((err) => {
        setErroReenviar(true);
      });
  }

  function gerarNovaSenha() {
    const { token } = obterTokens();
    setError({ ativo: false, mensagem: "" });
    if (novaSenha === novaSenhaConfirmacao) {
      setErroSenhaAlterada(false);
      setErroConfirmacaoSenha(false);
      setCarregandoRenovacaoSenha(true);
      putUsuarioSenhaAlterar(token, credenciais.id, senha, novaSenha)
        .then(() => {
          setSenhaAlterada(true);
          navigate("/home");
        })
        .catch((err) => {
         if (err.message.includes('401')) {
            setTempoSessao(true);
          } else {
            setErroSenhaAlterada(true);
          }
        })
        .finally(() => setCarregandoRenovacaoSenha(false));
    } else {
      setErroConfirmacaoSenha(true);
    }
  }

  function pegaDocumentosLegais() {
    setError({ ativo: false, mensagem: "" });
    setCarregandoTermos(true);
    getDocumentosLegais("TermosDeUso", 1, 1, "Descending", "dataCriacao").then(
      (res) => {
        let documentosLegaisTermosDeUso =
          res.data.documentos.length > 0
            ? (setTermosDeUsoURL(res.data.documentos[0].urlArquivo),
              getDocumentosLegais(
                "PoliticaDeConteudo",
                1,
                1,
                "Descending",
                "dataCriacao"
              ).then((res) => {
                let documentosLegaisPoliticaDePrivacidade =
                  res.data.documentos.length > 0
                    ? (setPoliticaDeConteudoURL(
                        res.data.documentos[0].urlArquivo
                      ),
                      getDocumentosLegais(
                        "PoliticaDePrivacidade",
                        1,
                        1,
                        "Descending",
                        "dataCriacao"
                      ).then((res) => {
                        let documentosLegaisPoliticaDeConteudo =
                          res.data.documentos.length > 0
                            ? (setVerificaDocumento(true),
                              setPoliticaDePrivacidadeURL(
                                res.data.documentos[0].urlArquivo
                              ),
                              setCarregandoTermos(false))
                            : setCarregandoTermos(false);
                      }))
                    : setCarregandoTermos(false);
              }))
            : setCarregandoTermos(false);
      }
    );
  }

  return {
    confirmarEmail,
    reenviarEmail,
    gerarNovaSenha,
    pegaDocumentosLegais,
  };
};
