import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";
import { putAgencia } from "../../../services/agencia";
import { obterTokens } from "../../../utils/LocalStorage";

interface FunctionsFunctionsTabelaListarAgencias {
  idAgencia: string;
  nomeAgencia: string;
  setRecarregar: () => void;
  setCarregandoDesativacao: React.Dispatch<boolean>;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsTabelaListarAgencias = ({
  adicionarAListaEsperaRenovacaoToken,
  idAgencia,
  myToast,
  nomeAgencia,
  setCarregandoDesativacao,
  setModalAbertoDesativacao,
  setRecarregar,
}: FunctionsFunctionsTabelaListarAgencias) => {
  const sucessoRequisicao = () => {
    showToast(
      "success",
      "Desativação realizada com sucesso",
      "Agencia desativada!"
    );
    setRecarregar();
    setModalAbertoDesativacao(false);
  };

  const erroRequisicao = () => {
    showToast(
      "error",
      "Desativação não realizada",
      "Não foi possível desativar a Agencia."
    );
  };

  function desativarAgencia() {
    const { token, tokenRenovacao } = obterTokens();

    setCarregandoDesativacao(true);

    putAgencia(token, idAgencia, nomeAgencia, null, false)
      .then(() => {
        sucessoRequisicao();
        setCarregandoDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putAgencia(token, idAgencia, nomeAgencia, null, false)
              .then(() => {
                sucessoRequisicao();
              })
              .catch((err) => {
                erroRequisicao();
              });
          }, setCarregandoDesativacao);
        } else {
          erroRequisicao();
          setCarregandoDesativacao(false);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  function criaPDFListaDeAgencias() {
    const minhaTabela = document.getElementById(
      "corpoTabelaListarAgencias"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>N° Campanhas</th><th>N° Usuários</th><th>N° Marcas</th><th>Status</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }

  return {
    criaPDFListaDeAgencias,
    desativarAgencia,
  };
};
