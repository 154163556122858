import { useState, useEffect, useRef, useContext } from "react";

import styles from "./styles.module.css";

import { FunctionsObterInfluenciadores } from "./functions";

import { ErroType } from "../../../@types/erro";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TitulosTabelasType } from "../../../@types/titulosTabelas";
import { getInfluenciadorProps } from "../../../services/influenciador";

import { TabelaListarInfluenciadores } from "../../Tabelas/TabelaListarInfluenciadores";

import { DesconectarContext } from "../../../context/DesconectarContext";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

let prevInfluenciLista: getInfluenciadorProps[];

export const ListarInfluenciador = () => {
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(true);
  const [abrirModalDeslogar, setAbrirModalDeslogar] = useState<boolean>(false);
  const [nome, setNome] = useState<string>("");
  const [listaInfluenciadores, setListaInfluenciadores] = useState<
    getInfluenciadorProps[]
  >(!prevInfluenciLista ? [] : prevInfluenciLista);
  const [numeroPagina, setNumeroPagina] = useState(0);
  const [influenciadoresTotais, setInfluenciadoresTotais] = useState<number>(0);
  const [tamanhoPagina, setTamanhoPagina] = useState(99999);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] =
    useState<string>("Nome");

  const myToast = useRef<any>(null);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterTodos, obterTodosPorNome } = FunctionsObterInfluenciadores({
    nome,
    nomePropriedadeOrdenacao,
    tipoOrdenacao,
    numeroPagina: numeroPagina + 1,
    tamanhoPagina,
    setErro,
    setCarregando,
    setListaInfluenciadores,
    setAbrirModalDeslogar,
    setInfluenciadoresTotais,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    if (nome === "") {
      obterTodos();
    } else {
      obterTodosPorNome();
    }
  }, [numeroPagina, tamanhoPagina, nome, recarregar, tipoOrdenacao]);

  const colunas: TitulosTabelasType[] = [
    { nome: "Nome", ordenacao: "nome" },
    { nome: "Email", ordenacao: "email" },
    { nome: "Telefone", ordenacao: "telefone" },
    { nome: "Tipo de perfil", ordenacao: "tipoPerfil" },
    { nome: "Status", ordenacao: "status" },
    { nome: "Ações", ordenacao: "" },
  ];

  return (
    <div className={styles.containerListarMarcas}>
      <TabelaListarInfluenciadores
        setErro={setErro}
        erro={erro}
        setRecarregar={() => setRecarregar(!recarregar)}
        tituloTabela={"Influenciadores"}
        tituloColuna={colunas}
        carregando={carregando}
        nomeBusca={nome}
        setNomeBusca={setNome}
        listaInfluenciadores={listaInfluenciadores}
        nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
        setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
        tipoOrdenacao={tipoOrdenacao}
        setTipoOrdenacao={setTipoOrdenacao}
        // paginacao={
        //   <Paginator
        //     first={numeroPagina}
        //     rows={1}
        //     totalRecords={influenciadoresTotais / tamanhoPagina}
        //     onPageChange={(e) => setNumeroPagina(e.first)}
        //   />
        // }
        // seletorDeQuantidade={
        //   <SelectQtdadeItensLista
        //     onChange={(e) => setTamanhoPagina(Number(e.target.value))}
        //     totalLista={influenciadoresTotais}
        //     nomeLista={"influenciadores"}
        //   />
        // }
        setCarregando={setCarregando}
        myToast={myToast}
      />
      <Toast ref={myToast} className="custom-toast" />
    </div>
  );
};
