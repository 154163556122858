import { useSpring, animated } from "react-spring"
import { format } from "../MascaraNumero/format";

export interface AnimacaoNumeroProps {
  numero: number;
  inteiro?: boolean;
  instantaneo?: boolean;
}
export function AnimacaoNumero({ numero, inteiro, instantaneo }: AnimacaoNumeroProps) {

  const { numeroAnimado } = useSpring({
    from: { numeroAnimado: 0 },
    numeroAnimado: numero,
    delay: instantaneo ? 0 : 200,
    config: {
      duration: instantaneo ? 0 : 800,
    },
  });

  return (
    <animated.div>
      {
        inteiro ? numeroAnimado.to((numero) => format("#.###.##0,#0", numero).replace(",00", ""))
          :
          numeroAnimado.to((numero) => format("#.###.##0,#0", numero))
      }
    </animated.div>
  )
}