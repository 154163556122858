import { putMarca } from "../../../services/marca";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";

export interface FuncoesCaixaFlutuanteAtualizarMarcaProps {
  id: string;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  logotipo: string | null;
  setLogotipo: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  setRecarregar: () => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesCaixaFlutuanteAtualizarMarca = ({
  id,
  nome,
  setNome,
  logotipo,
  setLogotipo,
  setCarregando,
  myToast,
  setRecarregar,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesCaixaFlutuanteAtualizarMarcaProps) => {
  const sucessoRequisicao = () => {
    showToast(
      "success",
      "Atualizado realizada com sucesso",
      "Marca atualizada!"
    );
    setRecarregar();
  };

  const erroRequisicao = () => {
    showToast(
      "error",
      "Atualização não realizada",
      "Não foi possível atualizar a Marca."
    );
  };
  function atualizarMarca() {
    const { token } = obterTokens();

    setCarregando(true);

    putMarca(token, id, nome, logotipo, true)
      .then(() => {
        sucessoRequisicao();
        LimparStates([setNome, setLogotipo]);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putMarca(token, id, nome, logotipo, true)
              .then(() => {
                sucessoRequisicao();
                LimparStates([setNome, setLogotipo]);
              })
              .catch((err) => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  function confirmarAtualizacao(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    atualizarMarca();
  }

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setLogotipo);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    atualizarMarca,
    confirmarAtualizacao,
    fazConverteImgPara64,
    showToast,
  };
};
