import { useState } from "react";

import styles from "./styles.module.css";

import { SelectMarca } from "../../Select/SelectMarca";
import { SelectStatus, statusType } from "../../Select/SelectStatus";


import { TabView, TabPanel } from "primereact/tabview";

interface FiltrosTabelaUsuarioMarcaProps {
  setFiltroStatus: React.Dispatch<React.SetStateAction<statusType>>;
  setFiltroIdMarca: React.Dispatch<React.SetStateAction<string>>;
  status: statusType;
  idMarcaFiltro: string;
}

export const FiltrosTabelaUsuarioMarca = ({
  setFiltroIdMarca,
  setFiltroStatus,
  status,
  idMarcaFiltro,
}: FiltrosTabelaUsuarioMarcaProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div id={styles.containerFiltrosTabelaListarUsuariosMarca}>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index);
          setFiltroStatus("");
          setFiltroIdMarca("");
        }}
      >
        <TabPanel header="Marca">
          <SelectMarca setIdMarca={setFiltroIdMarca} idMarca={idMarcaFiltro} />
        </TabPanel>

        <TabPanel header="Status">
          <SelectStatus status={status} setStatus={setFiltroStatus} />
        </TabPanel>
      </TabView>
    </div>
  );
};
