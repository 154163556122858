import styles from "./styles.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import { AbaRedeSocial } from "../../../../components/Abas/AbaRedeSocial";
import { campanha } from "../../../../services/campanha";

interface PorRedeSocialProps {
  instagramPresente?: boolean;
  tiktokPresente?: boolean;
  twitterPresente?: boolean;
  youtubePresente?: boolean;
  campanha: campanha;
  carregandoCampanha: boolean;
}
export const PorRedeSocial = ({
  instagramPresente,
  tiktokPresente,
  twitterPresente,
  youtubePresente,
  campanha,
  carregandoCampanha,
}: PorRedeSocialProps) => {

  return !instagramPresente &&
    !tiktokPresente &&
    !twitterPresente &&
    !youtubePresente ? (
    <p>Nenhuma publicação aprovada</p>
  ) : (
    <div id={styles.containerRedesSociais}>
      <TabView
        activeIndex={
          instagramPresente ? 0 : tiktokPresente ? 1 : twitterPresente ? 2 : 3
        }
      >
        {instagramPresente && (
          <TabPanel header="Instagram" disabled={!instagramPresente}>
            <AbaRedeSocial
              tipoRedeSocial={"Instagram"}
              campanha={campanha}
              carregandoCampanha={carregandoCampanha}
            />
          </TabPanel>
        )}

        {tiktokPresente && (
          <TabPanel header="TikTok" disabled={!tiktokPresente}>
            <AbaRedeSocial
              tipoRedeSocial={"TikTok"}
              campanha={campanha}
              carregandoCampanha={carregandoCampanha}
            />
          </TabPanel>
        )}

        {twitterPresente && (
          <TabPanel header="Twitter" disabled={!twitterPresente}>
            <AbaRedeSocial
              tipoRedeSocial={"Twitter"}
              campanha={campanha}
              carregandoCampanha={carregandoCampanha}
            />
          </TabPanel>
        )}

        {youtubePresente && (
          <TabPanel header="YouTube" disabled={!youtubePresente}>
            <AbaRedeSocial
              tipoRedeSocial={"YouTube"}
              campanha={campanha}
              carregandoCampanha={carregandoCampanha}
            />
          </TabPanel>
        )}
      </TabView>
    </div>
  );
};
