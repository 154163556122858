import axios from "axios";

// API de Desenvolvimento

// export const api = axios.create({
//   baseURL: "https://44.212.197.102/Dev/",
// });

// export const api = axios.create({
//   baseURL: "https://44.212.197.102/Dev/",
// });

// export const api = axios.create({
//   baseURL: 'https://44.212.197.102/Homolog/'
// })

export const api = axios.create({
  baseURL: "https://api.netcos.art.br/Prod/",
});

// export const api = axios.create({
//     baseURL: 'https://44.212.197.102/Prod/'
// })
