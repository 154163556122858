import { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { basicDataProps, FuncoesGraficoVisaoGeral } from "./functions";

import styles from "./styles.module.css";
import { CampoRedesSociais } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoRedesSociais";
import { PublicacoesProps } from "../../../services/publicacao";
import { AiOutlineRetweet } from "react-icons/ai";
import { format } from "../../../utils/MascaraNumero/format";

interface GraficoVisaoGeralProps {
  publicacoes: PublicacoesProps[];
  impressoes?: number;
  taxaEngajamento?: number;
  custoPorMilImpressoes?: number;
  custoEngajamento?: number;
}

export const GraficoVisaoGeral = ({ publicacoes, impressoes, taxaEngajamento, custoEngajamento, custoPorMilImpressoes }: GraficoVisaoGeralProps) => {
  const [alcanceTotal, setAlcanceTotal] = useState<number>(0);
  const [engajamentoTotal, setEngajamentoTotal] = useState<number>(0);
  const [impressoesTotal, setImpressoesTotal] = useState<number>(0);
  const [comentariosTotal, setComentariosTotal] = useState<number>(0);
  const [compartilhamentosTotal, setCompartilhamentosTotal] =
    useState<number>(0);
  const [likesTotal, setLikesTotal] = useState<number>(0);
  const [dislikesTotal, setDislikesTotal] = useState<number>(0);
  const [salvosTotal, setSalvosTotal] = useState<number>(0);
  const [retweetsTotal, setRetweetsTotal] = useState<number>(0);
  const [favoritosTotal, setFavoritosTotal] = useState<number>(0);
  const [twitter, setTwitter] = useState<number>(0);
  const [instagram, setInstagram] = useState<number>(0);
  const [tiktok, setTiktok] = useState<number>(0);
  const [youtube, setYoutube] = useState<number>(0);

  const [youtubeExiste, setYoutubeExiste] = useState<boolean>(false);
  const [tiktokExiste, setTiktokExiste] = useState<boolean>(false);
  const [instagramExiste, setInstagramExiste] = useState<boolean>(false);
  const [instagramFeedExiste, setInstagramFeedExiste] =
    useState<boolean>(false);
  const [instagramReelsExiste, setInstagramReelsExiste] =
    useState<boolean>(false);
  const [twitterExiste, setTwitterExiste] = useState<boolean>(false);

  const [basicData, setBasicData] = useState<basicDataProps>({
    labels: [],
    datasets: [],
  });

  const [basicOptions, setBasicOptions] = useState<any>({});

  ChartJS.register(ChartDataLabels);

  useEffect(() => {
    setaDados(publicacoes);
    getLightTheme();
  }, [publicacoes]);

  const { setaDados, getLightTheme } = FuncoesGraficoVisaoGeral({
    setBasicData,
    setAlcanceTotal,
    setComentariosTotal,
    setCompartilhamentosTotal,
    setDislikesTotal,
    setEngajamentoTotal,
    setFavoritosTotal,
    setImpressoesTotal,
    setInstagram,
    setLikesTotal,
    setRetweetsTotal,
    setSalvosTotal,
    setTiktok,
    setTwitter,
    setYoutube,
    setInstagramExiste,
    setInstagramFeedExiste,
    setInstagramReelsExiste,
    setTiktokExiste,
    setTwitterExiste,
    setYoutubeExiste,
    setBasicOptions,
  });

  return (
    <>
      <div className={styles.containerGraficoEDados}>
        <div className={styles.containerGrafico}>
          <CampoRedesSociais
            instagramExiste={instagramExiste}
            instagram={instagram}
            tiktokExiste={tiktokExiste}
            tiktok={tiktok}
            twitterExiste={twitterExiste}
            twitter={twitter}
            youtubeExiste={youtubeExiste}
            youtube={youtube}
          />
          <Chart
            id="divGraficoVisaoGeral"
            type="bar"
            data={basicData}
            options={basicOptions}
          />
        </div>
        <div className={styles.dadosResultados}>
          {(instagramExiste || tiktokExiste || youtubeExiste) && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-users"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Alcance</h1>
                <p>{format("#.###.##0,#0", alcanceTotal).replace(",00", "")}</p>
              </div>
            </div>
          )}

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-user-plus"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Impressões</h1>
              <p>
                {format("#.###.##0,#0", impressoes ? impressoes : impressoesTotal).replace(",00", "")}
              </p>
            </div>
          </div>

          {(instagramExiste || twitterExiste) && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-chart-line"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Engajamento</h1>
                <p>
                  {format("#.###.##0,#0", engajamentoTotal).replace(",00", "")}
                </p>
              </div>
            </div>
          )}

          {(instagramFeedExiste ||
            instagramReelsExiste ||
            twitter ||
            tiktok ||
            youtubeExiste) && (
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-comments"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Comentários</h1>
                  <p>
                    {format("#.###.##0,#0", comentariosTotal).replace(",00", "")}
                  </p>
                </div>
              </div>
            )}

          {(instagramExiste || tiktokExiste) && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-share-alt"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Compartilhamentos</h1>
                <p>
                  {format("#.###.##0,#0", compartilhamentosTotal).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

          {(instagramFeedExiste ||
            instagramReelsExiste ||
            tiktok ||
            youtubeExiste) && (
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-thumbs-up"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Likes</h1>
                  <p>{format("#.###.##0,#0", likesTotal).replace(",00", "")}</p>
                </div>
              </div>
            )}

          {youtubeExiste && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-thumbs-down"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Dislikes</h1>
                <p>
                  {format("#.###.##0,#0", dislikesTotal).replace(",00", "")}
                </p>
              </div>
            </div>
          )}

          {instagramFeedExiste || instagramReelsExiste || tiktok ? (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-bookmark"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Salvos</h1>
                <p>{format("#.###.##0,#0", salvosTotal).replace(",00", "")}</p>
              </div>
            </div>
          ) : (
            <></>
          )}

          {twitterExiste && (
            <div className={styles.dadoResultado}>
              <AiOutlineRetweet
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              />
              <div>
                <h1>Retweets</h1>
                <p>
                  {format("#.###.##0,#0", retweetsTotal).replace(",00", "")}
                </p>
              </div>
            </div>
          )}

          {twitterExiste && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-heart"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Favoritos</h1>
                <p>
                  {format("#.###.##0,#0", favoritosTotal).replace(",00", "")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
