import { SetStateAction, useState } from "react";

import styles from "./styles.module.css";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";
import { campanha } from "../../../services/campanha";
import { DateFormatter } from "../../../utils/DataFormatter";
import { TituloColuna } from "../../TituloColuna";
import { BotaoFiltro } from "../../Botoes/BotaoFiltro";
import { FiltrosTabelaCampanhasPorInfluenciador } from "../../Filtros/FiltroTabelaCampanhaPorInfluenciador";
import { BsRecordCircleFill } from "react-icons/bs";
import { CardCampanha } from "../../Cards/CardCampanha";
import { obterCredenciais } from "../../../utils/LocalStorage";

import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";

import { Avatar } from "primereact/avatar";
import { useNavigate } from "react-router-dom";

export interface TabelaListarCampanhasInfluenciadorProps {
  tituloTabela: string;
  tituloColuna: {
    nome: string;
    ordenacao: string;
  }[];
  listaDeCampanhas: campanha[];
  // paginacao: any;
  // seletorDeQuantidade: any;
  erro: ErroType;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  carregando: boolean;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  carregandoPesquisa: boolean;
  setNomeCampanhaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  myToast: React.MutableRefObject<any>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;
  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  setIdMarcaFiltro: React.Dispatch<SetStateAction<string>>;
  idMarcaFiltro: string;
  setIdAgenciaFiltro: React.Dispatch<SetStateAction<string>>;
  idAgenciaFiltro: string;
}

export const TabelaListarCampanhasInfluenciador = ({
  tituloTabela,
  tituloColuna,
  listaDeCampanhas,
  // paginacao,
  // seletorDeQuantidade,
  erro,
  carregando,
  carregandoPesquisa,
  setNomeCampanhaPesquisa,
  setRecarregar,
  tipoOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setIdMarcaFiltro,
  setIdAgenciaFiltro,
  idAgenciaFiltro,
  idMarcaFiltro,
}: TabelaListarCampanhasInfluenciadorProps) => {
  const [idCampanha, setIdCampanha] = useState<string>("");
  const [nomeCampanha, setNomeCampanha] = useState<string>("");

  const {
    credenciais: { id },
  } = obterCredenciais();

  const hoje = new Date().toISOString();

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.containerTabela}>
        <thead>
          <tr>
            <th className={styles.headerTabela}>
              <div className={styles.tituloTabela}>
                <h1>{tituloTabela}</h1>
              </div>

              <div className={styles.botoesIconeHeaderContainer}>
                <BotaoBusca
                  carregandoPesquisa={carregandoPesquisa}
                  onChange={(e) => setNomeCampanhaPesquisa(e.target.value)}
                />
                <BotaoFiltro>
                  <FiltrosTabelaCampanhasPorInfluenciador
                    idAgenciaFiltro={idAgenciaFiltro}
                    idMarcaFiltro={idMarcaFiltro}
                    setIdAgenciaFiltro={setIdAgenciaFiltro}
                    setIdMarcaFiltro={setIdMarcaFiltro}
                  />
                </BotaoFiltro>
              </div>
            </th>
          </tr>

          <tr className={styles.tituloColuna}>
            {tituloColuna.map((titulo) => {
              return (
                <TituloColuna
                  key={tituloColuna.indexOf(titulo)}
                  colunaIndex={tituloColuna.indexOf(titulo)}
                  tituloColuna={titulo.nome}
                  iconOrdenacao={[0, 3, 4, 5]}
                  tipoOrdenacao={tipoOrdenacao}
                  tituloOrdenacao={titulo.ordenacao}
                  setTipoOrdenacao={setTipoOrdenacao}
                  nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                  setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                  fontSize={"1.4rem"}
                />
              );
            })}
          </tr>
        </thead>

        <tbody
          id="corpoTabelaListarCampanhasInfluenciador"
          className={styles.corpoTabela}
        >
          {carregando ? (
            <Carregando
              listaUsuarios={listaDeCampanhas.length}
              spinner={true}
            />
          ) : (
            <>
              {erro.ativo ? (
                <ErroTabela erro={erro} recarregar={setRecarregar} />
              ) : (
                <>
                  {listaDeCampanhas.length === 0 ? (
                    <tr>
                      <td style={{ justifyContent: "center" }}>
                        Nenhum resultado a ser exibido.
                      </td>
                    </tr>
                  ) : (
                    listaDeCampanhas.map((campanha) => {
                      return (
                        <tr
                          onClick={() => {
                            {
                              setIdCampanha(campanha.id);
                              setNomeCampanha(campanha.nome);
                            }
                          }}
                        >
                          <td
                            className={styles.colunaNome}
                            onClick={() =>
                              navigate(
                                `/CampanhaDetalhesInfluenciador/${campanha.id
                                }/${campanha.tarefas.find(
                                  (tarefa) =>
                                    tarefa.influenciador.idCredenciais === id
                                )?.externalId
                                }`
                              )
                            }
                          >
                            <p title="Publicações">{campanha.nome}</p>
                          </td>

                          <td>
                            <div className={styles.celulaComImagem}>
                              {campanha.agencia && (
                                <Avatar
                                  label={campanha.agencia.nome.charAt(0)}
                                  shape="circle"
                                  size="xlarge"
                                  image={campanha.agencia.urlLogotipo}
                                  style={{
                                    textTransform: "uppercase",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                              <p>{campanha.agencia && campanha.agencia.nome}</p>
                            </div>
                          </td>
                          <td>
                            {campanha.marcas.map((marca) => {
                              return (
                                <div
                                  key={marca.id}
                                  className={styles.celulaComImagem}
                                >
                                  <Avatar
                                    label={marca.nome.charAt(0)}
                                    shape="circle"
                                    size="xlarge"
                                    image={marca.urlLogotipo}
                                    style={{
                                      textTransform: "uppercase",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <p>{marca.nome}</p>
                                </div>
                              );
                            })}
                          </td>

                          <td>{DateFormatter(campanha.dataInicio)}</td>
                          <td>{DateFormatter(campanha.dataFim)}</td>
                          <td>
                            {campanha.dataFim < hoje ? (
                              <div
                                className={`${styles.status} ${styles.statusEncerrado}`}
                              >
                                <BsRecordCircleFill
                                  className="iconeStatus"
                                  style={{
                                    color: "var(--cinza-400)",
                                    fontSize: "1rem",
                                  }}
                                />
                                Encerrada
                              </div>
                            ) : (
                              <div
                                className={`${styles.status} ${styles.statusAtivo}`}
                              >
                                <BsRecordCircleFill
                                  className="iconeStatus"
                                  style={{
                                    color: "var(--verde-400)",
                                    fontSize: "1rem",
                                  }}
                                />
                                Ativa
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </>
          )}
        </tbody>

        <div className={styles.listaCardsCampanhas}>
          {listaDeCampanhas.length > 0 ? (
            listaDeCampanhas.map((campanha, index) => {
              return (
                <CardCampanha
                  key={index}
                  campanha={campanha}
                  index={index}
                  onClick={() =>
                    navigate(
                      `/CampanhaDetalhesInfluenciador/${campanha.id}/${campanha.tarefas.find(
                        (tarefa) => tarefa.influenciador.idCredenciais === id
                      )?.externalId
                      }`
                    )
                  }
                />
              );
            })
          ) : (
            <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
          )}
        </div>
        <tfoot className={styles.footerTabela}>
          {/* <div className={styles.containerItensFooter}>
            <div className={styles.containerSelectNumPorPagina}>
              {seletorDeQuantidade}
            </div>
            {paginacao}
          </div> */}
        </tfoot>
      </div>
    </>
  );
};
