import { useEffect, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesConteudoMetricas } from "./functions";

import { CampoInformacoes } from "../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { PublicacoesProps } from "../../services/publicacao";
import { Carregando } from "../Carregando";
import { tipoOrdenacao } from "../../@types/ordenacao";
import { TabelaMetricasInstagram } from "../Tabelas/TabelaMetricasInstagram";
import { TabelaMetricasTikTok } from "../Tabelas/TabelaMetricasTikTok";
import { TabelaMetricasTwitter } from "../Tabelas/TabelaMetricasTwitter";
import { TabelaMetricasYoutube } from "../Tabelas/TabelaMetricasYoutube";

import { ErroType } from "../../@types/erro";

import { TabPanel, TabView } from "primereact/tabview";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";

export interface ConteudoMetricasProps {
  idCampanha: string;
  nomeInfluenciadorSelecionado: string;
  idInfluenciadorSelecionado: string;
  tabViewConteudosEMetricasIndice: number;
  setTabViewConteudosEMetricasIndice: React.Dispatch<
    React.SetStateAction<number>
  >;
  sessaoDestino: string;
  setSessaoDestino: React.Dispatch<React.SetStateAction<string>>;
}

export const ConteudoMetricas = ({
  idCampanha,
  nomeInfluenciadorSelecionado,
  idInfluenciadorSelecionado,
  tabViewConteudosEMetricasIndice,
  setTabViewConteudosEMetricasIndice,
  sessaoDestino,
  setSessaoDestino,
}: ConteudoMetricasProps) => {
  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);

  // ------------ Lista de Publicações -------------
  const [totalDePublicacoes, setTotalDePublicacoes] = useState<number>(0);
  const [listaDePublicacoes, setListaDePublicacoes] = useState<
    PublicacoesProps[]
  >([]);
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<
    "Id" | "DataFinalizacao" | "Status" | string
  >("Id");

  // ------------ Separadores de Publicações ------------
  const [listaDePublicacoesInstagram, setListaDePublicacoesInstagram] =
    useState<PublicacoesProps[]>([]);
  const [listaDePublicacoesTikTok, setListaDePublicacoesTikTok] = useState<
    PublicacoesProps[]
  >([]);
  const [listaDePublicacoesTwitter, setListaDePublicacoesTwitter] = useState<
    PublicacoesProps[]
  >([]);
  const [listaDePublicacoesYouTube, setListaDePublicacoesYouTube] = useState<
    PublicacoesProps[]
  >([]);

  const instagramPresente = listaDePublicacoesInstagram.length > 0;
  const tiktokPresente = listaDePublicacoesTikTok.length > 0;
  const twitterPresente = listaDePublicacoesTwitter.length > 0;
  const youTubePresente = listaDePublicacoesYouTube.length > 0;

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterPublicacoesTarefa } = FuncoesConteudoMetricas({
    idCampanha,
    idInfluenciadorSelecionado,
    listaDePublicacoesYouTube,
    setListaDePublicacoes,
    setListaDePublicacoesInstagram,
    setListaDePublicacoesTikTok,
    setListaDePublicacoesTwitter,
    setListaDePublicacoesYouTube,
    setTotalDePublicacoes,
    setCarregando,
    setErro,
    tipoOrdenacao,
    nomePropriedadeOrdenacao,
    numeroPagina: 1,
    tamanhoPagina: 412132,
    adicionarAListaEsperaRenovacaoToken,
  });

  useEffect(() => {
    obterPublicacoesTarefa();
  }, [idCampanha, idInfluenciadorSelecionado, recarregar]);

  return (
    <>
      <CampoInformacoes
        titulo={
          <>
            <a style={{ cursor: "pointer" }}>Conteúdo e Métricas</a>
          </>
        }
      >
        {carregando ? (
          <Carregando />
        ) : erro.ativo ? (
          <div className={styles.containerErro}>
            <h1>Ocorreu um erro carregando a página, tente novamente.</h1>
            <button
              onClick={() => setRecarregar(!recarregar)}
              className={styles.botaoIconeColunaAcoes}
            >
              <i
                className={"pi pi-refresh"}
                style={{ fontSize: "3rem" }}
                title={"Recarregar gráfico"}
              />
            </button>
          </div>
        ) : (
          <>
            <div className={styles.campoSelecaoDeRedeSocial}>
              {listaDePublicacoesInstagram.length === 0 &&
                listaDePublicacoesTikTok.length === 0 &&
                listaDePublicacoesTwitter.length === 0 &&
                listaDePublicacoesYouTube.length === 0 && (
                  <p className={styles.mensagemVazia}>
                    Nenhum conteúdo foi publicado por esse influenciador, nessa
                    campanha.
                  </p>
                )}
              <TabView
                activeIndex={
                  tabViewConteudosEMetricasIndice !== 0
                    ? tabViewConteudosEMetricasIndice
                    : instagramPresente
                    ? 0
                    : tiktokPresente
                    ? 1
                    : twitterPresente
                    ? 2
                    : 3
                }
                onTabChange={(e) => {
                  setTabViewConteudosEMetricasIndice(e.index);
                  setSessaoDestino("");
                }}
              >
                {instagramPresente && (
                  <TabPanel header="Instagram">
                    <TabelaMetricasInstagram
                      listaDePublicacoes={listaDePublicacoesInstagram}
                      nomeInfluenciadorSelecionado={
                        nomeInfluenciadorSelecionado
                      }
                      sessaoDestino={sessaoDestino}
                    />
                  </TabPanel>
                )}
                {tiktokPresente && (
                  <TabPanel header="TikTok">
                    <TabelaMetricasTikTok
                      listaDePublicacoes={listaDePublicacoesTikTok}
                      nomeInfluenciadorSelecionado={
                        nomeInfluenciadorSelecionado
                      }
                    />
                  </TabPanel>
                )}
                {twitterPresente && (
                  <TabPanel header="Twitter">
                    <TabelaMetricasTwitter
                      listaDePublicacoes={listaDePublicacoesTwitter}
                      nomeInfluenciadorSelecionado={
                        nomeInfluenciadorSelecionado
                      }
                    />
                  </TabPanel>
                )}
                {youTubePresente && (
                  <TabPanel header="YouTube">
                    <TabelaMetricasYoutube
                      listaDePublicacoes={listaDePublicacoesYouTube}
                      nomeInfluenciadorSelecionado={
                        nomeInfluenciadorSelecionado
                      }
                      sessaoDestino={sessaoDestino}
                    />
                  </TabPanel>
                )}
              </TabView>
            </div>
          </>
        )}
      </CampoInformacoes>
    </>
  );
};
