import React, { useContext, useRef, useState } from "react";

import styles from "./styles.module.css";
import "primeicons/primeicons.css";

import { FunctionsModalAcrescentarTarefaNaCampanha } from "./functions";

import { cadastroTarefasProps } from "../../../services/campanha";
import { Button } from "../../Botoes/Button";
import { Input } from "../../Input";
import { mascaraBRLInput } from "../../../utils/MascaraBRL";
import { Carregando } from "../../Carregando";
import { SelectInfluenciador } from "../../Select/SelectInfluenciador";
import { mascaraUSD } from "../../../utils/MarcaraUSD";
import { SelectPerfilInfluenciador } from "../../Select/SelectPerfilInfluenciador";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { ErroType } from "../../../@types/erro";

import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface modalmodalAcrescentarTarefaNaCampanhaProps {
  tarefa: cadastroTarefasProps[];
  setTarefa: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;
  importancia: any;
  recarregar: () => void;
}

export const ModalAcrescentarTarefaNaCampanha = ({
  tarefa,
  setTarefa,
  importancia,
  recarregar,
}: modalmodalAcrescentarTarefaNaCampanhaProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [valorContrato, setValorContrato] = useState<string>("");
  const [quantidadeTwitter, setQuantidadeTwitter] = useState<number>(0);
  const [quantidadeInstagramReels, setQuantidadeInstagramReels] =
    useState<number>(0);
  const [quantidadeInstagramFeed, setQuantidadeInstagramFeed] =
    useState<number>(0);
  const [quantidadeInstagramStories, setQuantidadeInstagramStories] =
    useState<number>(0);
  const [quantidadeYouTube, setQuantidadeYouTube] = useState<number>(0);
  const [quantidadeYouTubeShorts, setQuantidadeYouTubeShorts] =
    useState<number>(0);
  const [quantidadeTiktok, setQuantidadeTiktok] = useState<number>(0);

  const [idInfluenciador, setIdInfluenciador] = useState<string>("");
  const [
    perfilInfluenciadorNoFechamentoDoContrato,
    setPerfilInfluenciadorNoFechamentoDoContrato,
  ] = useState<string>("");
  const [nomeInfluenciador, setNomeInfluenciador] = useState<string>("");
  const [emailInfluenciador, setEmailInfluenciador] = useState<string>("");

  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  const myToast = useRef<any>(null);

  const { idCampanha } = useParams();

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { cadastrarTarefaNaCampanha } =
    FunctionsModalAcrescentarTarefaNaCampanha({
      idCampanha: idCampanha!,
      idInfluenciador,
      setIdInfluenciador,
      perfilInfluenciadorNoFechamentoDoContrato,
      setPerfilInfluenciadorNoFechamentoDoContrato,
      nomeInfluenciador,
      setNomeInfluenciador,
      emailInfluenciador,
      setEmailInfluenciador,
      valorContrato: mascaraUSD(valorContrato).replace(" ", ""),
      quantidadeTwitter,
      quantidadeInstagramReels,
      quantidadeInstagramFeed,
      quantidadeInstagramStories,
      quantidadeYouTube,
      quantidadeYouTubeShorts,
      quantidadeTiktok,
      tarefa,
      setTarefa,
      setErro,
      setModalAberto,
      myToast,
      setCarregando,
      recarregar,
      setValorContrato,
      adicionarAListaEsperaRenovacaoToken,
    });

  const { perfil } = obterCredenciais().credenciais;

  return (
    <div className={styles.campoCadastroTarefa}>
      <Inplace active={modalAberto} onToggle={(e) => setModalAberto(e.value)}>
        <InplaceDisplay>
          {(perfil[0] === "Administrador" || perfil[0] === "UsuarioNetcos") && (
            <Button
              importancia={importancia}
              onClick={() => setModalAberto(true)}
              title="Adicionar Influenciador"
              iconLeft={
                <i
                  className="pi pi-plus-circle"
                  style={{ fontSize: "2rem" }}
                ></i>
              }
            />
          )}
        </InplaceDisplay>
        <InplaceContent>
          <div
            className={styles.conteudoModalCadastroInfluenciador}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={styles.campoSelects}>
              <SelectInfluenciador
                idInfluenciador={idInfluenciador}
                setEmailInfluenciador={setEmailInfluenciador}
                setIdInfluenciador={setIdInfluenciador}
                setNomeInfluenciador={setNomeInfluenciador}
                setPerfilInfluenciador={
                  setPerfilInfluenciadorNoFechamentoDoContrato
                }
                nomeInfluenciador={nomeInfluenciador}
                perfilInfluenciador={perfilInfluenciadorNoFechamentoDoContrato}
                emailInfluenciador={emailInfluenciador}
              />

              <SelectPerfilInfluenciador
                setPerfil={setPerfilInfluenciadorNoFechamentoDoContrato}
                perfil={perfilInfluenciadorNoFechamentoDoContrato}
              />
            </div>

            <div className={styles.campoInputs}>
              <Input
                type="number"
                required
                min={0}
                placeholder="Twitter"
                title="Twitter"
                value={quantidadeTwitter !== 0 ? quantidadeTwitter : undefined}
                onChange={(e) => {
                  setQuantidadeTwitter(e.target.valueAsNumber);
                }}
              />

              <Input
                type="number"
                required
                min={0}
                placeholder="Reels"
                title="Instagram Reels"
                value={
                  quantidadeInstagramReels !== 0
                    ? quantidadeInstagramReels
                    : undefined
                }
                onChange={(e) => {
                  setQuantidadeInstagramReels(e.target.valueAsNumber);
                }}
              />

              <Input
                type="number"
                required
                min={0}
                placeholder="Feed"
                title="Instagram Feed"
                value={
                  quantidadeInstagramFeed !== 0
                    ? quantidadeInstagramFeed
                    : undefined
                }
                onChange={(e) => {
                  setQuantidadeInstagramFeed(e.target.valueAsNumber);
                }}
              />

              <Input
                type="number"
                required
                min={0}
                placeholder="Stories"
                title="Instagram Stories"
                value={
                  quantidadeInstagramStories !== 0
                    ? quantidadeInstagramStories
                    : undefined
                }
                onChange={(e) => {
                  setQuantidadeInstagramStories(e.target.valueAsNumber);
                }}
              />

              <Input
                type="number"
                required
                min={0}
                placeholder="YouTube"
                title="YouTube Video"
                value={quantidadeYouTube !== 0 ? quantidadeYouTube : undefined}
                onChange={(e) => {
                  setQuantidadeYouTube(e.target.valueAsNumber);
                }}
              />
              <Input
                type="number"
                required
                min={0}
                placeholder="Shorts"
                title="YouTube Shorts"
                value={
                  quantidadeYouTubeShorts !== 0
                    ? quantidadeYouTubeShorts
                    : undefined
                }
                onChange={(e) => {
                  setQuantidadeYouTubeShorts(e.target.valueAsNumber);
                }}
              />

              <Input
                type="number"
                required
                min={0}
                placeholder="Tiktok"
                title="TikTok"
                value={quantidadeTiktok !== 0 ? quantidadeTiktok : undefined}
                onChange={(e) => {
                  setQuantidadeTiktok(e.target.valueAsNumber);
                }}
              />

              <Input
                type="text"
                required
                min={0}
                placeholder="Valor"
                title="Valor do Contrato"
                maxLength={17}
                value={"R$ " + valorContrato}
                onChange={(e) => {
                  mascaraBRLInput(e.target.value, setValorContrato);
                }}
              />
            </div>

            {carregando ? (
              <Carregando />
            ) : (
              <div className={styles.campoBotoes}>
                <Button
                  importancia="secundario"
                  icon={
                    <i
                      className="pi pi-check"
                      style={{ fontSize: "1.6rem" }}
                    ></i>
                  }
                  onClick={() => {
                    cadastrarTarefaNaCampanha();
                  }}
                />
                <Button
                  importancia={importancia}
                  onClick={() => setModalAberto(!modalAberto)}
                  title=""
                  iconLeft={
                    <i
                      className="pi pi-times"
                      style={{ fontSize: "1.6rem" }}
                    ></i>
                  }
                />
              </div>
            )}

            <Toast ref={myToast} className="custom-toast" />
          </div>
        </InplaceContent>
      </Inplace>
      {erro.ativo && <p className={styles.mensagemErro}> {erro.mensagem}</p>}
    </div>
  );
};
