import { SetStateAction, useState } from "react";

import styles from "./styles.module.css";

import { BotaoBusca } from "../../Botoes/BotaoBusca";
import { BotaoImprimir } from "../../Botoes/BotaoImprimir";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";

import { ErroType } from "../../../@types/erro";

import { campanha } from "../../../services/campanha";
import { DateFormatter } from "../../../utils/DataFormatter";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { TituloColuna } from "../../TituloColuna";
import { BotaoFiltro } from "../../Botoes/BotaoFiltro";

import { Avatar } from "primereact/avatar";
import { useNavigate } from "react-router-dom";
import { FuncoesTabelaListarCampanhasAgencias } from "./functions";
import { mascaraBRLExibir } from "../../../utils/MascaraBRL";
import { BsRecordCircleFill } from "react-icons/bs";
import { FiltrosTabelaCampanhasPorAgencia } from "../../Filtros/FiltroTabelaCampanhaPorAgencia";
import { CardCampanha } from "../../Cards/CardCampanha";
import { IoTrendingDownSharp, IoTrendingUpSharp } from "react-icons/io5";
import { format } from "../../../utils/MascaraNumero/format";

export interface TabelaListarCampanhasAgenciaProps {
  tituloTabela: string;
  tituloColuna: {
    nome: string;
    ordenacao: string;
  }[];
  listaDeCampanhas: campanha[];
  // paginacao: any;
  // seletorDeQuantidade: any;
  erro: ErroType;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  carregando: boolean;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  carregandoPesquisa: boolean;
  setNomeCampanhaPesquisa: React.Dispatch<React.SetStateAction<string>>;
  setRecarregar: () => void;
  myToast: React.MutableRefObject<any>;
  tipoOrdenacao: tipoOrdenacao;
  setTipoOrdenacao: React.Dispatch<React.SetStateAction<tipoOrdenacao>>;

  nomePropriedadeOrdenacao: string;
  setNomePropriedadeOrdenacao: React.Dispatch<SetStateAction<string>>;
  setIdMarcaFiltro: React.Dispatch<SetStateAction<string>>;
  idMarcaFiltro: string;
  datas: Date | Date[] | undefined;
  setDatas: React.Dispatch<SetStateAction<Date | Date[] | undefined>>;
  statusCampanha: number | undefined;
  setStatusCampanha: React.Dispatch<number | undefined>;
}

export const TabelaListarCampanhasAgencias = ({
  tituloTabela,
  tituloColuna,
  listaDeCampanhas,
  // paginacao,
  // seletorDeQuantidade,
  erro,
  carregando,
  setCarregando,
  carregandoPesquisa,
  setNomeCampanhaPesquisa,
  setRecarregar,
  myToast,
  tipoOrdenacao,
  setTipoOrdenacao,
  nomePropriedadeOrdenacao,
  setNomePropriedadeOrdenacao,
  setIdMarcaFiltro,
  idMarcaFiltro,
  datas,
  setDatas,
  setStatusCampanha,
  statusCampanha,
}: TabelaListarCampanhasAgenciaProps) => {
  const [idCampanha, setIdCampanha] = useState<string>("");
  const [nomeCampanha, setNomeCampanha] = useState<string>("");
  const [dataInicioCampanha, setDataInicioCampanha] = useState<
    string | undefined
  >("");
  const hoje = new Date().toISOString();

  const navigate = useNavigate();

  const { criaPDFListaDeCampanhas } = FuncoesTabelaListarCampanhasAgencias();

  return (
    <table className={styles.containerTabela}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>{tituloTabela}</h1>
            </div>

            <div className={styles.botoesIconeHeaderContainer}>
              <BotaoBusca
                carregandoPesquisa={carregandoPesquisa}
                onChange={(e: any) => setNomeCampanhaPesquisa(e.target.value)}
              />
              <BotaoImprimir onClick={criaPDFListaDeCampanhas} />

              <BotaoFiltro>
                <FiltrosTabelaCampanhasPorAgencia
                  statusCampanha={statusCampanha}
                  setStatusCampanha={setStatusCampanha}
                  idMarcaFiltro={idMarcaFiltro}
                  setIdMarcaFiltro={setIdMarcaFiltro}
                  datas={datas}
                  setDatas={setDatas}
                />
              </BotaoFiltro>
            </div>
          </th>
        </tr>

        <tr className={styles.tituloColuna}>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                iconOrdenacao={[0, 2, 3, 4, 5, 6]}
                tipoOrdenacao={tipoOrdenacao}
                tituloOrdenacao={titulo.ordenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                fontSize={"1.4rem"}
              />
            );
          })}
        </tr>
      </thead>

      <tbody
        id="corpoTabelaListarCampanhasAgencia"
        className={styles.corpoTabela}
      >
        {carregando ? (
          <Carregando listaUsuarios={listaDeCampanhas.length} spinner={true} />
        ) : (
          <>
            {erro.ativo ? (
              <ErroTabela erro={erro} recarregar={setRecarregar} />
            ) : (
              <>
                {listaDeCampanhas.length === 0 ? (
                  <tr>
                    <td style={{ justifyContent: "center" }}>
                      Nenhum resultado a ser exibido.
                    </td>
                  </tr>
                ) : (
                  listaDeCampanhas.map((campanha) => {
                    return (
                      <tr
                        onClick={() => {
                          {
                            setIdCampanha(campanha.id);
                            setNomeCampanha(campanha.nome);
                          }
                        }}
                      >
                        <td
                          className={styles.colunaNome}
                          onClick={() =>
                            navigate(
                              `/CampanhaPorAgenciaDetalhes/${campanha.id}`
                            )
                          }
                        >
                          <p title={campanha.nome}>{campanha.nome}</p>
                        </td>
                        <td>
                          {campanha.marcas.map((marca) => {
                            return (
                              <div
                                key={marca.id}
                                className={styles.celulaComImagem}
                              >
                                <Avatar
                                  label={marca.nome.charAt(0)}
                                  shape="circle"
                                  size="xlarge"
                                  image={marca.urlLogotipo}
                                  style={{
                                    textTransform: "uppercase",
                                    borderRadius: "50%",
                                  }}
                                  title={marca.nome}
                                />
                                <p>{marca.nome}</p>
                              </div>
                            );
                          })}
                        </td>

                        <td>{DateFormatter(campanha.dataInicio)}</td>
                        <td>{DateFormatter(campanha.dataFim)}</td>
                        <td>
                          {format(
                            "#.###.##0,#0",
                            campanha.mediaTaxaEngajamento
                          )}{" "}
                          %
                          <div
                            title={
                              campanha.acimaMediaNetcos
                                ? "Acima da média Netcos"
                                : "Abaixo da média Netcos"
                            }
                            className={
                              campanha.acimaMediaNetcos
                                ? styles.containerIconPositivo
                                : styles.containerIconNegativo
                            }
                          >
                            {campanha.acimaMediaNetcos ? (
                              <IoTrendingUpSharp />
                            ) : (
                              <IoTrendingDownSharp />
                            )}
                          </div>
                        </td>
                        <td>
                          {format("R$ #.###.##0,#0", campanha.custoEngajamento)}
                        </td>
                        <td>
                          {campanha.dataCriacaoRelatorio !==
                          "0001-01-01T00:00:00" ? (
                            DateFormatter(campanha.dataCriacaoRelatorio)
                          ) : (
                            <p title="Relatório não gerado">Não gerado</p>
                          )}
                        </td>
                        <td>
                          {campanha.dataFim < hoje ? (
                            <div
                              className={`${styles.status} ${styles.statusEncerrado}`}
                            >
                              <BsRecordCircleFill
                                className="iconeStatus"
                                style={{
                                  color: "var(--cinza-400)",
                                  fontSize: "1rem",
                                }}
                              />
                              Encerrada
                            </div>
                          ) : (
                            <div
                              className={`${styles.status} ${styles.statusAtivo}`}
                            >
                              <BsRecordCircleFill
                                className="iconeStatus"
                                style={{
                                  color: "var(--verde-400)",
                                  fontSize: "1rem",
                                }}
                              />
                              Ativa
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </>
        )}
      </tbody>
      <div className={styles.listaCardsCampanhas}>
        {listaDeCampanhas.length > 0 ? (
          listaDeCampanhas.map((campanha, index) => {
            return (
              <CardCampanha
                campanha={campanha}
                onClick={() =>
                  navigate(`/CampanhaPorAgenciaDetalhes/${campanha.id}`)
                }
                index={index}
              />
            );
          })
        ) : (
          <p className={styles.campoVazio}>Nenhuma campanha cadastrada</p>
        )}
      </div>
      <tfoot className={styles.footerTabela}>
        {/* <div className={styles.containerItensFooter}>
          <div className={styles.containerSelectNumPorPagina}>
            {seletorDeQuantidade}
          </div>
          {paginacao}
        </div> */}
      </tfoot>
    </table>
  );
};
