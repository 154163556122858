import { ErroType } from "../../../@types/erro";
import { limpaLocalStorage, obterTokens } from "../../../utils/LocalStorage";
import {
  getUsuarioAgenciaTodosAgencia,
  getUsuarioAgenciaTodosNome,
  getUsuarioAgenciaTodosStatus,
  getUsuariosAgenciaTodos,
  listaUsuarioAgenciaProps,
  respostaGetUsuarioAgencia,
} from "../../../services/usuarioAgencia";
import { AxiosResponse } from "axios";
import { statusType } from "../../Select/SelectStatus";

export interface FuncoesListarUsuariosAgenciaProps {
  setListaUsuarios: React.Dispatch<listaUsuarioAgenciaProps[]>;
  setErro: React.Dispatch<ErroType>;
  setCarregando: React.Dispatch<boolean>;
  setCarregandoPesquisa: React.Dispatch<boolean>;
  setUsuariosTotais: React.Dispatch<number>;
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: string;
  propriedadeOrdenacao: string;
  nomePesquisa: string;
  idAgencia: string;
  status: statusType;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesListarUsuariosAgencia = ({
  setListaUsuarios,
  setErro,
  setCarregando,
  setUsuariosTotais,
  numeroPagina,
  tamanhoPagina,
  tipoOrdenacao,
  propriedadeOrdenacao,
  nomePesquisa,
  idAgencia,
  status,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesListarUsuariosAgenciaProps) => {
  function sucessoRequisicao({
    data,
  }: AxiosResponse<respostaGetUsuarioAgencia | any>) {
    setListaUsuarios(data.resultado ? data.resultado.usuarios : data.usuarios);
    setUsuariosTotais(
      data.resultado ? data.resultado.totalRegistros : data.totalRegistros
    );
  }

  function erroRequisicao() {
    setErro({
      ativo: true,
      mensagem: "Erro ao carregar a lista de Usuários de Agência.",
    });
  }

  function obterUsuarios() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);

    getUsuariosAgenciaTodos(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuariosAgenciaTodos(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  const obterUsuariosPorNome = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioAgenciaTodosNome(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      nomePesquisa
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioAgenciaTodosNome(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              nomePesquisa
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterUsuariosPorAgencia = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioAgenciaTodosAgencia(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      idAgencia
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioAgenciaTodosAgencia(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              idAgencia
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  const obterUsuariosPorStatus = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getUsuarioAgenciaTodosStatus(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      propriedadeOrdenacao,
      status
    )
      .then((res) => {
        sucessoRequisicao(res);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getUsuarioAgenciaTodosStatus(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              propriedadeOrdenacao,
              status
            )
              .then((res) => sucessoRequisicao(res))
              .catch(() => erroRequisicao());
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  };

  return {
    obterUsuarios,
    obterUsuariosPorNome,
    obterUsuariosPorAgencia,
    obterUsuariosPorStatus,
  };
};
