import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { ErroType } from '../../../@types/erro';

import styles from './styles.module.css';


export interface listaPerfisSelectProps {
  setPerfil?: React.Dispatch<React.SetStateAction<string>>,
  perfil?: string,
}

export const SelectPerfilInfluenciador = ({ setPerfil, perfil }: listaPerfisSelectProps) => {

  const perfis = [
    {
      nome: 'Macro Influenciador',
      value: 'MacroInfluenciador'
    },
    {
      nome: 'Micro Influenciador',
      value: 'MicroInfluenciador'
    },
    {
      nome: 'Hero',
      value: 'Hero'
    },
    {
      nome: 'Mid',
      value: 'Mid'
    }
  ]

  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [carregando, setCarregando] = useState<boolean>(false);

  return (
    erro.ativo ?
      <p style={{ zIndex: "1", position: "relative" }}>{erro.mensagem}</p>
      :
      <div className={styles.selectPerfilInfluenciador}>
        <Dropdown
          emptyMessage={carregando ? "Carregando..." : "Nenhum perfil encontrado"}
          placeholder={"Selecione um perfil"}
          id="select-perfil"
          name='perfil'
          value={perfil}
          options={perfis}
          onChange={(e) => setPerfil && setPerfil(e.target.value)}
          optionValue="value"
          optionLabel="nome"
          filterPlaceholder='Encontre um perfil'
          emptyFilterMessage={'Nenhum perfil com esses termos encontrado.'}
          showClear
          style={{
            width: "100%",
            background: "#ffffff60",
            backdropFilter: "blur(5px)",
            color: "#fff",
            outline: "none",
            boxShadow: "none",
            fontFamily: "Exo 2",
            height: '3.5rem',
          }}
          panelStyle={{
            background: '#ffffff60',
            backdropFilter: 'blur(5px)',
            fontSize: '1.2rem',
          }}
        />
      </div>
  )
}