import {
  dadosUsuarioProps,
  putUsuarioReativacao,
} from "../../../services/usuario";
import { obterTokens } from "../../../utils/LocalStorage";
import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";
import {
  getInfluenciadorProps,
  putInfluenciador,
} from "../../../services/influenciador";
import { ErroType } from "../../../@types/erro";

interface FunctionsTabelaListarInfluenciadorProps {
  idInfluenciador: string;
  nomeInfluenciador: string;
  emailInfluenciador: string;
  avatarInfluenciador: string | null;
  telefoneInfluenciador: string;
  perfilInfluenciador: string;
  linkInstagramInfluenciador: string;
  linkFacebookInfluenciador: string;
  linkTiktokInfluenciador: string;
  linkYouTubeInfluenciador: string;
  setInfluenciadorSelecionado: React.Dispatch<
    React.SetStateAction<getInfluenciadorProps | undefined>
  >;
  setModalEditarInfluenciador: React.Dispatch<React.SetStateAction<boolean>>;
  setModalRedefinicao: React.Dispatch<React.SetStateAction<boolean>>;
  setModalReenvioSenha: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAbertoDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: () => void;
  setCarregando: React.Dispatch<boolean>;
  myToast: React.MutableRefObject<any>;
  setErro: React.Dispatch<ErroType>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsTabelaListarInfluenciador = ({
  idInfluenciador,
  nomeInfluenciador,
  emailInfluenciador,
  avatarInfluenciador,
  telefoneInfluenciador,
  perfilInfluenciador,
  linkFacebookInfluenciador,
  linkInstagramInfluenciador,
  linkTiktokInfluenciador,
  linkYouTubeInfluenciador,
  setInfluenciadorSelecionado,
  setModalEditarInfluenciador,
  setModalRedefinicao,
  setModalReenvioSenha,
  setModalAbertoDesativacao,
  setCarregando,
  setRecarregar,
  adicionarAListaEsperaRenovacaoToken,
  myToast,
  setErro,
}: FunctionsTabelaListarInfluenciadorProps) => {
  function abriModalEditar(influenciador: getInfluenciadorProps) {
    setModalEditarInfluenciador(true);
    setInfluenciadorSelecionado(influenciador);
  }

  function abriModalRedefinicao(influenciador: getInfluenciadorProps) {
    setModalRedefinicao(true);
    setInfluenciadorSelecionado(influenciador);
  }

  function abriModalReenvioSenha(influenciador: getInfluenciadorProps) {
    setModalReenvioSenha(true);
    setInfluenciadorSelecionado(influenciador);
  }

  const sucessoRequisicao = () => {
    showToast(
      "success",
      "Desativação realizada com sucesso",
      "Influenciador desativado!",
      false
    );
  };

  const erroRequisicao = (err: any) => {
    const mensagem =
      err.response.data.codigo === 400
        ? "Não é possível desativar influenciador em campanha ativa"
        : "Não foi possível desativar o influenciador";
    const sticky = err.response.data.codigo === 400 ? true : false;
    showToast("error", "Desativação não realizada", mensagem, sticky);

    setModalAbertoDesativacao(false);
  };

  function desativarInfluenciador() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();

    setCarregando(true);

    putInfluenciador(
      token,
      idInfluenciador,
      nomeInfluenciador,
      null,
      telefoneInfluenciador,
      perfilInfluenciador,
      "Removido",
      linkFacebookInfluenciador,
      linkInstagramInfluenciador,
      linkTiktokInfluenciador,
      linkYouTubeInfluenciador
    )
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
        setModalAbertoDesativacao(false);
        setRecarregar();
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putInfluenciador(
                token,
                idInfluenciador,
                nomeInfluenciador,
                null,
                telefoneInfluenciador,
                perfilInfluenciador,
                "Removido",
                linkFacebookInfluenciador,
                linkInstagramInfluenciador,
                linkTiktokInfluenciador,
                linkYouTubeInfluenciador
              )
                .then(() => {
                  sucessoRequisicao();
                })
                .catch((err) => {
                  erroRequisicao(err);
                });
            },
            () => {
              setCarregando(false);
              setModalAbertoDesativacao(false);
              setRecarregar();
            }
          );
        } else {
          erroRequisicao(err);
          setCarregando(false);
        }
      });
  }

  function sucessoReativacao() {
    showToast(
      "success",
      "Reativação realizada com sucesso",
      "Usuário reativado!",
      false
    );
  }

  function falhaReativacao() {
    showToast(
      "error",
      "Reativação não realizada",
      "Não foi possível reativar o usuário",
      false
    );
  }

  function reativarUsuario() {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);

    let dadosUsuario: dadosUsuarioProps = {
      id: idInfluenciador,
      nome: nomeInfluenciador,
      imagemAvatar: avatarInfluenciador,
      status: "Ativo",
      telefone: telefoneInfluenciador,
      termosDeUsoAceitos: true,
    };

    const { token, tokenRenovacao } = obterTokens();
    putUsuarioReativacao(token, dadosUsuario)
      .then(() => {
        sucessoReativacao();
        setCarregando(false);
        setRecarregar();
        setModalAbertoDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await putUsuarioReativacao(token, dadosUsuario)
                .then(() => sucessoReativacao())
                .catch(() => falhaReativacao());
            },
            () => {
              setCarregando(false);
              setRecarregar();
              setModalAbertoDesativacao(false);
            }
          );
        } else {
          falhaReativacao();
          setCarregando(false);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    stickyValue: boolean
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
      sticky: stickyValue,
    });
  };

  function criaPDFListaDeInfluenciadores() {
    const minhaTabela = document.getElementById(
      "corpoTabelaListarInfluenciadores"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>Email</th><th>Telefone</th><th>Tipo de Perfil</th><th>Status</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }

  return {
    abriModalEditar,
    criaPDFListaDeInfluenciadores,
    abriModalRedefinicao,
    abriModalReenvioSenha,
    desativarInfluenciador,
    reativarUsuario,
  };
};
