import axios, { AxiosResponse } from "axios";
import { statusType } from "../components/Select/SelectStatus";
import { api } from "./api";

export interface influenciadorProps {
  nome: string;
  email: string;
  imagemAvatar: string;
  telefone: number;
  perfil?: string;
}

export interface getInfluenciadorResposta {
  codigo: number;
  totalRegistros: number;
  influenciadores: getInfluenciadorProps[];
}

export interface getInfluenciadorProps {
  idCredenciais: string;
  nome: string;
  email: string;
  telefone: number;
  status: string;
  perfil: string;
  urlAvatar: string;
  linkInstagram: string;
  linkTwitter: string;
  linkTiktok: string;
  linkYoutube: string;
  engajamentoHistorico: number;
}

export interface respostaPostInfluenciador {
  codigo: number;
  mensagem: string;
}

export const postInfluenciador = (
  token: string,
  nome: string,
  email: string,
  imagemAvatar: string,
  telefone: string,
  perfil: string,
  status: statusType,
  enviarNotificacaoDeCadastro: boolean
): Promise<respostaPostInfluenciador> => {
  const url = "/Influenciadores";

  const influenciador = {
    nome,
    email,
    imagemAvatar,
    telefone,
    perfil,
    status: status
  };

  return api.post(
    url,
    { influenciador, enviarNotificacaoDeCadastro },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getInfluenciadores = (
  token: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<getInfluenciadorResposta | any>> => {
  const url = `/Influenciadores/Todos?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getInfluenciadoresTodosNome = (
  token: string,
  nome: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<getInfluenciadorResposta | any>> => {
  const url = `/Influenciadores/Todos/Nome?Nome=${nome}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const putInfluenciador = (
  token: string,
  idCredenciais: string,
  nome: string | null,
  imagemAvatar: string | null,
  telefone: string,
  perfil: string | null,
  status: string | null,
  linkInstagram?: string | null,
  linkTwitter?: string | null,
  linkTiktok?: string | null,
  linkYouTube?: string | null
) => {
  const url = "/Influenciadores";

  const influenciador = {
    idCredenciais,
    nome,
    imagemAvatar,
    telefone,
    perfil,
    status,
    linkInstagram,
    linkTwitter,
    linkTiktok,
    linkYouTube,
  };

  return api.put(
    url,
    { influenciador },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postConvidarInfluenciador = (token: string, email: string) => {
  const url = "/Influenciadores/Convidar";

  return api.post(
    url,
    { email },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getInfluenciadoresTodosStatus = (
  token: string,
  status: string,
  numeroPagina: number,
  tamanhoPagina: number,
  tipoOrdenacao: string,
  nomePropriedadeOrdenacao: string
): Promise<AxiosResponse<getInfluenciadorResposta | any>> => {
  const url = `/Influenciadores/Todos/Status?Status=Ativo&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&TipoOrdenacao=${tipoOrdenacao}&NomePropriedadeOrdenacao=${nomePropriedadeOrdenacao}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export interface getInfluenciadorPorIdResposta {
  codigo: number;
  influenciador: getInfluenciadorProps;
}

export const getInfluenciadorPorId = (
  token: string,
  id: string
): Promise<AxiosResponse<getInfluenciadorPorIdResposta | any>> => {
  const url = `/Influenciadores/Id?Id=${id}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

interface getTokenLongaDuracaoResposta {
  codigo: number,
  resposta: string
}




export const getTokenDeLongaDuracao = (
  token: string,
  tokenAcesso: string,
  appId :string,
): Promise<AxiosResponse<getTokenLongaDuracaoResposta | any>> => {

  const url = `/Influenciadores/Meta/Token/LongaDuracao?TokenAcesso=${tokenAcesso}&AppId=${appId}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};