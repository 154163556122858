import { useRef, useContext, useState } from "react";

import { TabelaListarAgencias } from "../../../components/Tabelas/TabelaListarAgencias";
import { Toast } from "primereact/toast";

import { AgenciaContext } from "../../../context/AgenciaContext";

export const ListarAgencias = () => {
  const [nomeDaAgenciaPesquisa, setNomeDaAgenciaPesquisa] = useState("");

  const myToast = useRef<any>(null);

  const {
    carregandoListaDeAgencias,
    erroListaDeAgencia,
    listaDeAgencias,
    mudarNomeDaPropriedadeDeOrdenacao,
    mudarTipoOrdenacao,
    recarregarListaDeAgencias,
    nomeDaPropriedadeDeOrdenacao,
    tipoDeOrdenacao,
  } = useContext(AgenciaContext);

  const colunas = [
    {
      nome: "Nome",
      ordenacao: "nome",
    },
    {
      nome: "Campanhas",
      ordenacao: "TotalCampanhas",
    },
    {
      nome: "Usuários",
      ordenacao: "TotalUsuarios",
    },
    {
      nome: "Marcas associadas",
      ordenacao: "TotalMarcas",
    },
    {
      nome: "Status",
      ordenacao: "ativo",
    },
    {
      nome: "Ações",
      ordenacao: "",
    },
  ];

  return (
    <>
      <TabelaListarAgencias
        nomePropriedadeOrdenacao={nomeDaPropriedadeDeOrdenacao}
        tipoOrdenacao={tipoDeOrdenacao}
        setNomeDaAgenciaPesquisa={setNomeDaAgenciaPesquisa}
        carregando={carregandoListaDeAgencias}
        myToast={myToast}
        setTipoOrdenacao={mudarTipoOrdenacao}
        setNomePropriedadeOrdenacao={mudarNomeDaPropriedadeDeOrdenacao}
        setRecarregar={recarregarListaDeAgencias}
        erro={erroListaDeAgencia}
        tituloTabela={"Agências"}
        tituloColuna={colunas}
        listaDeAgencias={listaDeAgencias.filter((agencia) =>
          agencia.nome
            .toLowerCase()
            .includes(nomeDaAgenciaPesquisa.toLowerCase().trim())
        )}
      />
      <Toast ref={myToast} className="custom-toast" />
    </>
  );
};
