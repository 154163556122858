import { cadastroTarefasProps } from "../../../services/campanha";


export interface FuncoesCadastroTarefaProps {
  tarefas: cadastroTarefasProps[];
  setTarefas: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;
}

export const FuncoesCadastroTarefa = ({tarefas, setTarefas} : FuncoesCadastroTarefaProps) => {

  const removerTarefas = (
    indexDaTarefa: number,
  ) => {
    const novaListaDeTarefas = tarefas.filter(
      (tarefas, index) => indexDaTarefa !== index
    );
    setTarefas(novaListaDeTarefas);
  };
  
  return {
    removerTarefas,
  };
};