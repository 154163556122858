interface functionsListarTodosUsuariosProps {
  myToast: React.MutableRefObject<any>;
}

export const functionsListarTodosUsuarios = ({
  myToast,
}: functionsListarTodosUsuariosProps) => {
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
    stickyValue: boolean
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
      sticky: stickyValue,
    });
  };

  return {
    showToast,
  };
};
