import React, { useContext, useState } from "react";

import styles from "./styles.module.css";

import { FuncoesModalEditarCampanha } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { Input } from "../../Input";
import { ModalTeste } from "../ModalTeste";

import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

interface ModalEditarCampanhaProps {
  nomeAtualCampanha: string;
  dataInicioAtual: string;
  dataFimAtual: string;
  idCampanha: string;
  modalAberto: boolean;
  recarregar: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalEditarCampanha = ({
  nomeAtualCampanha,
  dataInicioAtual,
  dataFimAtual,
  idCampanha,
  modalAberto,
  recarregar,
  setModalAberto,
  setRecarregar,
}: ModalEditarCampanhaProps) => {
  const [nomeCampanha, setNomeCampanha] = useState<string>("");
  const [datas, setDatas] = useState<Date | Date[] | undefined>([new Date(dataInicioAtual), new Date(dataFimAtual)]);
  const [carregando, setCarregando] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { editarCampanha } = FuncoesModalEditarCampanha({
    idCampanha,
    nomeCampanha: nomeCampanha ? nomeCampanha : nomeAtualCampanha,
    setNomeCampanha,
    dataInicio: datas
      ? Array.isArray(datas)
        ? datas.length !== 0
          ? datas[0].toISOString()
          : dataInicioAtual
        : ""
      : "",
    dataFim: datas
      ? Array.isArray(datas)
        ? datas[1]
          ? datas[1].toISOString()
          : dataFimAtual
        : ""
      : "",
    setDatas,
    adicionarAListaEsperaRenovacaoToken,
    setCarregando,
    recarregar,
    setRecarregar,
    setModalAberto,
  });

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpa",
  });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Editar Campanha"}
    >
      <div
        className={styles.conteudoModalAtualizarCampanha}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <body>
          <div className={styles.containerInputs}>
            <Input
              required
              placeholder="Nome da Campanha"
              value={nomeCampanha ? nomeCampanha : nomeAtualCampanha}
              onChange={(e) => setNomeCampanha(e.target.value)}
            />

            <Calendar
              className={styles.calendario}
              dateFormat="dd/mm/yy"
              locale="pt-br"
              placeholder="Período da Campanha"
              selectionMode="range"
              value={datas}
              required
              onChange={(e) => setDatas(e.value)}
              showIcon
              style={{
                outline: "none",
                boxShadow: "none",
              }}
            />
          </div>
        </body>

        {carregando ? (
          <Carregando />
        ) : (
          <div className={styles.containerBotoesModal}>
            <Button
              type="button"
              importancia="terciario"
              title="Cancelar"
              onClick={() => setModalAberto(false)}
            />
            <Button
              importancia="secundario"
              title="Confirmar"
              onClick={() => editarCampanha()}
            />
          </div>
        )}
      </div>
    </ModalTeste>
  );
};
