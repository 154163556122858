import React from "react";
import { ErroType } from "../../../@types/erro";
import { cadastroTarefasProps, tipoPublicacaoProps } from "../../../services/campanha";

interface FunctionsModalCadastroTarefaProps {
  idInfluenciador: string;
  perfilInfluenciadorNoFechamentoDoContrato: string;
  nomeInfluenciador: string;
  emailInfluenciador: string;
  valorContrato: string;
  quantidadeTwitter: number;
  quantidadeInstagramReels: number;
  quantidadeInstagramFeed: number;
  quantidadeInstagramStories: number;
  quantidadeYouTube: number;
  quantidadeYouTubeShorts: number;
  quantidadeTiktok: number;
  tarefas: cadastroTarefasProps[];
  setTarefas: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FunctionsModalCadastroTarefa = ({
  idInfluenciador,
  perfilInfluenciadorNoFechamentoDoContrato,
  nomeInfluenciador,
  emailInfluenciador,
  valorContrato,
  quantidadeTwitter,
  quantidadeInstagramReels,
  quantidadeInstagramFeed,
  quantidadeInstagramStories,
  quantidadeYouTube,
  quantidadeYouTubeShorts,
  quantidadeTiktok,
  tarefas,
  setTarefas,
  setErro,
  setModalAberto
}: FunctionsModalCadastroTarefaProps) => {


  function criaPublicacoesECriaTarefa() {

    if (idInfluenciador === "" || perfilInfluenciadorNoFechamentoDoContrato === "" || valorContrato === "") {
      setErro({ ativo: true, mensagem: "Verifique se todos os campos foram preenchidos." });
    } else {
      setErro({ativo:false, mensagem: ""})
      let publicacoes: {
        numeroIdentificadorDePublicacao: number,
        tipoPublicacao: tipoPublicacaoProps,
        externalId: string,
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: Date,
        sentimento: string,
      }[] = [];

      let Resto: {
        externalId: string,
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: Date,
        sentimento: string
      }[] = [];

      for (let i = 0; i < quantidadeTwitter; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoTwitter", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeInstagramFeed; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoInstagramFeed", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeInstagramReels; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoInstagramReels", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeInstagramStories; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoInstagramStories", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeTiktok; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoTikTok", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeYouTube; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoYouTube", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }
      for (let i = 0; i < quantidadeYouTubeShorts; i++) {
        publicacoes.push({ numeroIdentificadorDePublicacao: (i + 1), tipoPublicacao: "PublicacaoYouTubeShorts", externalId: "", printsDeMetrica: [], printsDeSentimento: [], dataFinalizacao: new Date, sentimento: "Pendente" })
      }



      let valorContratoNumber = Number(valorContrato)

      setTarefas([...tarefas, {
        publicacoes,
        valorContrato: valorContratoNumber,
        idInfluenciador,
        perfilInfluenciadorNoFechamentoDoContrato,
        nomeInfluenciador,
        emailInfluenciador,
      }]);
      setModalAberto(false);
    }


  }

  return {
    criaPublicacoesECriaTarefa
  };
};
