import { ErroType } from "../../../../@types/erro";
import { PublicacoesProps } from "../../../../services/publicacao"
import { getTiktokEmbed } from "../../../../services/tiktok";


interface FuncoesPublicacaoIndividualTiktokProps {
    publicacao: PublicacoesProps;
    setHtmlEmbedTiktok: React.Dispatch<React.SetStateAction<string>>;
    setError: React.Dispatch<React.SetStateAction<ErroType>>;
    setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FuncoesPublicacaoIndividualTiktok = ({ publicacao, setHtmlEmbedTiktok, setError, setCarregando }: FuncoesPublicacaoIndividualTiktokProps) => {

    function pegaCodigoTiktokEBotaEmbed(urlPublicacao: string) {

        var id = urlPublicacao.match('vm.tiktok.com/([a-zA-Z0-9_-]+)&?') ? urlPublicacao.match('vm.tiktok.com/([a-zA-Z0-9_-]+)&?')![1] : "";
        
        const regex = /\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+)\b/gm;
        var match = regex.exec(urlPublicacao);

        return (match !== null ? "https://www.tiktok.com/embed/" + match[1] : `https://www.tiktok.com/embed/${id}`);
    }

    return {
        pegaCodigoTiktokEBotaEmbed
    }

}