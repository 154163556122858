import { BiTimeFive } from 'react-icons/bi';
import { converteSegundoParaTempoCompleto } from '../../../../utils/ConversorTempo';
import { format } from '../../../../utils/MascaraNumero/format';
import styles from './styles.module.css';

interface DadosLateraisYouTubeProps {
  viewsTotais: number;
  tempoReproducao: number;
  comentarios: number;
  likes: number;
  dislikes: number;
  impressoes: number;
  espectadores: number;
  taxaAprovacao: number;
  existeYouTubeVideo: boolean;
  existeYouTubeShorts: boolean;
}

export const DadosLateraisYouTube = ({
  viewsTotais,
  tempoReproducao,
  comentarios,
  likes,
  dislikes,
  impressoes,
  espectadores,
  existeYouTubeVideo,
  existeYouTubeShorts
}: DadosLateraisYouTubeProps) => {

  return (
    <div className={styles.dadosResultados}>

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-comments"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Comentários</h1>
            <p>{format("#.###.##0,#0", comentarios).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-thumbs-down"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Dislikes</h1>
            <p>{format("#.###.##0,#0", dislikes).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-user"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Espectadores</h1>
            <p>{format("#.###.##0,#0", espectadores).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-user-plus"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Impressões</h1>
            <p>{format("#.###.##0,#0", impressoes).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-thumbs-up"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Likes</h1>
            <p>{format("#.###.##0,#0", likes).replace(",00", "")}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-percentage"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Taxa de Aprovação</h1>
            <p>{format("#.###.##0,#0 %", (likes / (likes + dislikes) * 100))}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo) &&
        <div className={styles.dadoResultado}>
          <BiTimeFive
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          />
          <div>
            <h1>Tempo de Reprodução</h1>
            <p>{converteSegundoParaTempoCompleto(tempoReproducao)}</p>
          </div>
        </div>
      }

      {(existeYouTubeVideo || existeYouTubeShorts) &&
        <div className={styles.dadoResultado}>
          <i
            className="pi pi-eye"
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "var(--roxo-500)",
            }}
          ></i>
          <div>
            <h1>Views</h1>
            <p>{format("#.###.##0,#0", viewsTotais).replace(",00", "")}</p>
          </div>
        </div>
      }
    </div>
  )

}