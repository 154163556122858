import { useState, useEffect, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesTabelaBenchmarking } from "./functions";

import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";

import { ErroType } from "../../../@types/erro";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface TabelaBenchmarkingProps {
  tipoInfluenciador: string;
}

export const TabelaBenchmarking = ({
  tipoInfluenciador,
}: TabelaBenchmarkingProps) => {
  //TODO: Ajustar para a forma que vai receber os dados da média de cada tipo de publicação.

  const [mediaIgFeed, setMediaIgFeed] = useState<number>(0);
  const [mediaIgReels, setMediaIgReels] = useState<number>(0);
  const [mediaIgStories, setMediaIgStories] = useState<number>(0);
  const [mediaTwitter, setMediaTwitter] = useState<number>(0);
  const [mediaTikTok, setMediaTikTok] = useState<number>(0);
  const [mediaYouTubeVideo, setMediaYouTubeVideo] = useState<number>(0);
  const [mediaYouTubeShort, setMediaYouTubeShort] = useState<number>(0);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { pegaDadosDasTabelas } = FuncoesTabelaBenchmarking({
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    setMediaIgFeed,
    setMediaIgReels,
    setMediaIgStories,
    setMediaTikTok,
    setMediaTwitter,
    setMediaYouTubeShort,
    setMediaYouTubeVideo,
    tipoInfluenciador,
    setErro,
  });

  useEffect(() => {
    pegaDadosDasTabelas();
  }, [recarregar]);

  const tipoPublicacao = [
    { nome: "Instagram Feed", media: mediaIgFeed },
    { nome: "Instagram Reels", media: mediaIgReels },
    { nome: "Instagram Stories", media: mediaIgStories },
    { nome: "TikTok", media: mediaTikTok },
    { nome: "Twitter", media: mediaTwitter },
    { nome: "YouTube Short", media: mediaYouTubeShort },
    { nome: "YouTube Video", media: mediaYouTubeVideo },
  ];

  return (
    <table className={styles.tabelaBenchmarking}>
      <thead>
        <tr>
          <th className={styles.headerTabela}>Tipo</th>
          <th className={styles.headerTabela}>Excelente</th>
          <th className={styles.headerTabela}>Média</th>
          <th className={styles.headerTabela}>Abaixo</th>
        </tr>
      </thead>

      <tbody>
        {erro.ativo ? (
          <ErroTabela
            erro={erro}
            recarregar={() => {
              setRecarregar(!recarregar);
            }}
            // setRecarregar={setRecarregar}
          />
        ) : (
          <>
            {carregando ? (
              <Carregando spinner={true} />
            ) : (
              tipoPublicacao.map(({ nome, media }) => (
                <tr>
                  <td>{nome}</td>
                  <td>
                    {">"} {(media * 1.1).toFixed(2).replace(".", ",")} %
                  </td>
                  <td>{media.toFixed(2).replace(".", ",")} %</td>
                  <td>
                    {"<"} {(media * 0.9).toFixed(2).replace(".", ",")} %
                  </td>
                </tr>
              ))
            )}
          </>
        )}
      </tbody>
    </table>
  );
};
