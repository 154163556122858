import React from "react";

import styles from "./styles.module.css";

import { Button } from "../Botoes/Button";
import { ErroType } from "../../@types/erro";

export interface ErroTabelaProps {
  erro: ErroType;
  recarregar?: () => void;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErroTabela = ({
  recarregar,
  setRecarregar,
  erro,
}: ErroTabelaProps) => {
  return (
    <tr>
      <td>
        <div className={styles.containerErroTabela}>
          <p className={styles.mensagem}>{erro.mensagem}</p>
          <Button
            importancia="terciario"
            title={` Clique aqui ou tente novamente mais tarde`}
            icon={
              <i
                className="pi pi-refresh"
                style={{ fontSize: "1.8rem", paddingLeft: "1rem" }}
              ></i>
            }
            onClick={() =>
              (recarregar && recarregar()) ||
              (setRecarregar && setRecarregar((recarregar) => !recarregar))
            }
          />
        </div>
      </td>
    </tr>
  );
};
