import styles from "./styles.module.css";
import InnerHTML from "dangerously-set-html-content";

import { TbSticker } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";

import { PublicacoesProps } from "../../../../services/publicacao";
import { format } from "../../../../utils/MascaraNumero/format";
import { DateFormatter } from "../../../../utils/DataFormatter";
import { obterCredenciais } from "../../../../utils/LocalStorage";
import { CarrosselMetricas } from "../../../Carrossel/CarrosselMetricas";
import { FunctionsPublicacaoInstagram } from "./functions";
import { InstagramEmbed } from "react-social-media-embed";

interface PublicacaoInstagramProps {
  publicacao: PublicacoesProps;
}

export const PublicacaoInstagram = ({
  publicacao,
}: PublicacaoInstagramProps) => {
  const { createEmbedInstaMarkup } = FunctionsPublicacaoInstagram({
    link: publicacao.urlPublicacao!,
  });

  // const date: any = new Date(publicacao.dataFinalizacao);

  const perfil = obterCredenciais().credenciais.perfil[0];

  return (
    <div className={styles.container}>
      <div className={styles.tituloPublicacao}>
        {(perfil === "Administrador" || perfil === "UsuarioNetcos") &&
          `${publicacao.numeroIdentificadorDePublicacao} - `}
        {publicacao.tipo === "PublicacaoInstagramFeed" && "Feed - "}
        {publicacao.tipo === "PublicacaoInstagramReels" && "Reels - "}
        {publicacao.tipo === "PublicacaoInstagramStories" && "Story - "}
        Data da Publicação: {DateFormatter(publicacao.dataFinalizacao)}
      </div>
      <div className={styles.containerResultadoEPublicacoes}>
        <div className={styles.containerDadosResultados}>

          {publicacao.tipo === "PublicacaoInstagramReels" && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-eye"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Reproduções</h1>
                <p>
                  {format(
                    "#.###.##0,#0",
                    publicacao.quantidadeReproducoes
                  ).replace(",00", "")}
                </p>
              </div>
            </div>
          )}

          {(publicacao.tipo === "PublicacaoInstagramFeed" ||
            publicacao.tipo === "PublicacaoInstagramStories") && (
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-user-plus"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Impressões</h1>
                  <p>
                    {format("#.###.##0,#0", publicacao.impressoes).replace(
                      ",00",
                      ""
                    )}
                  </p>
                </div>
              </div>
            )}

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-users"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Alcance</h1>
              <p>
                {format("#.###.##0,#0", publicacao.alcance).replace(",00", "")}
              </p>
            </div>
          </div>

          {publicacao.tipo === "PublicacaoInstagramStories" && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-link"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Cliques no link</h1>
                <p>
                  {format("#.###.##0,#0", publicacao.cliquesLink).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

          {/* {(publicacao.tipo === "PublicacaoInstagramFeed" ||
            publicacao.tipo === "PublicacaoInstagramReels") && ( */}
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-thumbs-up"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Likes</h1>
                  <p>
                    {format("#.###.##0,#0", publicacao.likes).replace(",00", "")}
                  </p>
                </div>
              </div>
            {/* )} */}

          {publicacao.tipo === "PublicacaoInstagramStories" && (
            <div className={styles.dadoResultado}>
              <TbSticker className={styles.icons} />
              <div>
                <h1>Cliques no sticker</h1>
                <p>
                  {format("#.###.##0,#0", publicacao.cliquesStickers).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

          {publicacao.tipo === "PublicacaoInstagramStories" && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-comments"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Respostas</h1>
                <p>
                  {format("#.###.##0,#0", publicacao.respostas).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

          {(publicacao.tipo === "PublicacaoInstagramReels" ||
            publicacao.tipo === "PublicacaoInstagramFeed") && (
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-bookmark"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Salvos</h1>
                  <p>
                    {format("#.###.##0,#0", publicacao.salvos).replace(",00", "")}
                  </p>
                </div>
              </div>
            )}

          {(publicacao.tipo === "PublicacaoInstagramFeed" ||
            publicacao.tipo === "PublicacaoInstagramReels") && (
              <div className={styles.dadoResultado}>
                <i
                  className="pi pi-comments"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: "var(--roxo-500)",
                  }}
                ></i>
                <div>
                  <h1>Comentários</h1>
                  <p>
                    {format("#.###.##0,#0", publicacao.comentarios).replace(
                      ",00",
                      ""
                    )}
                  </p>
                </div>
              </div>
            )}

          <div className={styles.dadoResultado}>
            <i
              className="pi pi-share-alt"
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                color: "var(--roxo-500)",
              }}
            ></i>
            <div>
              <h1>Compartilhamentos</h1>
              <p>
                {format("#.###.##0,#0", publicacao.compartilhamentos).replace(
                  ",00",
                  ""
                )}
              </p>
            </div>
          </div>

          {publicacao.tipo === "PublicacaoInstagramFeed" && (
            <div className={styles.dadoResultado}>
              <CgProfile
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              />
              <div>
                <h1>Visitas ao perfil</h1>
                <p>
                  {format("#.###.##0,#0", publicacao.visitasAoPerfil).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

          {publicacao.tipo === "PublicacaoInstagramStories" && (
            <div className={styles.dadoResultado}>
              <i
                className="pi pi-home"
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  color: "var(--roxo-500)",
                }}
              ></i>
              <div>
                <h1>Visitas ao perfil</h1>
                <p>
                  {format("#.###.##0,#0", publicacao.visitasPerfil).replace(
                    ",00",
                    ""
                  )}
                </p>
              </div>
            </div>
          )}

        </div>
        <div className={`${styles.campoPublicacao} ${publicacao.tipo === "PublicacaoInstagramStories" && styles.campoPublicacaoStory}`}>
          {publicacao.tipo !== "PublicacaoInstagramStories" ?
            <div className={styles.campoEmbed}>
              <p className={styles.headerCampoEmbed}>
                Evidência da Publicação
              </p>
              <InstagramEmbed url={publicacao.urlPublicacao!} />

              {/* <InnerHTML html={createEmbedInstaMarkup().__html} /> */}

            </div>
            :
            (publicacao.midiasDeEvidencia?.length! > 0) &&
            <div className={styles.carrosselEvidencias}>
              <CarrosselMetricas
                titulo="Evidência da publicação"
                prints={publicacao.midiasDeEvidencia}
                exibicao
              />
            </div>
          }
          {
            (publicacao.printsDeMetrica?.length! > 0) &&
            <div className={styles.carrosselMetricas}>
              <CarrosselMetricas prints={publicacao.printsDeMetrica} exibicao />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
