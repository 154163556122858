import { campanha } from "../../../services/campanha";
import { somaMetrica } from "../../../utils/somaMetrica";

interface FuncoesTabelaComparativoRedesSociaisProps {
  campanha: campanha;

  setPublicacaoIgFeed: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoIgReels: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoIgStories: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoTwitter: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoTikTok: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoYouTubeVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicacaoYouTubeShort: React.Dispatch<React.SetStateAction<boolean>>;

  // Total de Posts
  setTotalIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setTotalIgReels: React.Dispatch<React.SetStateAction<number>>;
  setTotalIgStories: React.Dispatch<React.SetStateAction<number>>;
  setTotalTikTok: React.Dispatch<React.SetStateAction<number>>;
  setTotalTwitter: React.Dispatch<React.SetStateAction<number>>;
  setTotalYouTubeVideo: React.Dispatch<React.SetStateAction<number>>;
  setTotalYouTubeShort: React.Dispatch<React.SetStateAction<number>>;

  //Compartilhamentos
  setCompartilhamentosIgFeed: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosIgReels: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosIgStories: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosTikTok: React.Dispatch<React.SetStateAction<number>>;
}

export const FuncoesTabelaComparativoRedesSociais = ({
  campanha,
  setPublicacaoIgFeed,
  setPublicacaoIgReels,
  setPublicacaoIgStories,
  setPublicacaoTwitter,
  setPublicacaoTikTok,
  setPublicacaoYouTubeVideo,
  setPublicacaoYouTubeShort,
  setTotalIgFeed,
  setTotalIgReels,
  setTotalIgStories,
  setTotalTikTok,
  setTotalTwitter,
  setTotalYouTubeVideo,
  setTotalYouTubeShort,
  setCompartilhamentosIgFeed,
  setCompartilhamentosIgReels,
  setCompartilhamentosIgStories,
  setCompartilhamentosTikTok,
}: FuncoesTabelaComparativoRedesSociaisProps) => {
  function setaDadosDaTabela() {
    let totalIgFeed = 0;
    let totalIgReels = 0;
    let totalIgStories = 0;
    let totalTikTok = 0;
    let totalTwitter = 0;
    let totalYouTubeShort = 0;
    let totalYouTubeVideo = 0;

    let compartilhamentosIgFeed = 0;
    let compartilhamentosIgReels = 0;
    let compartilhamentosIgStories = 0;
    let compartilhamentosTikTok = 0;


    campanha.tarefas.map((tarefa) => {
      tarefa.publicacoes.map((publicacao) => {
        if (publicacao.status === "Aprovado") {
          switch (publicacao.tipo) {
            case "PublicacaoInstagramFeed":
              totalIgFeed = somaMetrica(totalIgFeed, 1);
              compartilhamentosIgFeed = somaMetrica(compartilhamentosIgFeed, publicacao.compartilhamentos);
              break;
            case "PublicacaoInstagramReels":
              totalIgReels = somaMetrica(totalIgReels, 1);
              compartilhamentosIgReels = somaMetrica(compartilhamentosIgReels, publicacao.compartilhamentos);
              break;
            case "PublicacaoInstagramStories":
              totalIgStories = somaMetrica(totalIgStories, 1);
              compartilhamentosIgStories = somaMetrica(compartilhamentosIgStories, publicacao.compartilhamentos);
              break;
            case "PublicacaoTikTok":
              totalTikTok = somaMetrica(totalTikTok, 1);
              compartilhamentosTikTok = somaMetrica(compartilhamentosTikTok, publicacao.compartilhamentos);
              break;
            case "PublicacaoTwitter":
              totalTwitter = somaMetrica(totalTwitter, 1);
              break;
            case "PublicacaoYouTube":
              totalYouTubeVideo = somaMetrica(totalYouTubeVideo, 1);
              break;
            case "PublicacaoYouTubeShorts":
              totalYouTubeShort = somaMetrica(totalYouTubeShort, 1);
              break;
          }
        }
      })
    })

    setPublicacaoIgFeed(totalIgFeed > 0);
    setPublicacaoIgReels(totalIgReels > 0)
    setPublicacaoIgStories(totalIgStories > 0);
    setPublicacaoTikTok(totalTikTok > 0);
    setPublicacaoTwitter(totalTwitter > 0);
    setPublicacaoYouTubeVideo(totalYouTubeVideo > 0);
    setPublicacaoYouTubeShort(totalYouTubeShort > 0);
    setTotalIgFeed(totalIgFeed);
    setTotalIgReels(totalIgReels);
    setTotalIgStories(totalIgStories);
    setTotalTikTok(totalTikTok);
    setTotalTwitter(totalTwitter);
    setTotalYouTubeVideo(totalYouTubeVideo);
    setTotalYouTubeShort(totalYouTubeShort);
    setCompartilhamentosIgFeed(compartilhamentosIgFeed);
    setCompartilhamentosIgReels(compartilhamentosIgReels);
    setCompartilhamentosIgStories(compartilhamentosIgStories);
    setCompartilhamentosTikTok(compartilhamentosTikTok);
  }

  const identificaTop3 = (metrica: number, array: number[]) => {
    let top3 = array.sort((a, b) => b-a).slice(0, 3)

    if (metrica === top3[0]) {return 'top1'}
    if (metrica === top3[1]) {return 'top2'}
    if (metrica === top3[2]) {return 'top3'}
  }


  return {
    setaDadosDaTabela, identificaTop3
  };
};
