import { LimparStates } from "./../../../utils/LimparStates/index";
import { obterTokens } from "./../../../utils/LocalStorage/index";
import { ErroType } from "../../../@types/erro";
import { useContext } from "react";
import { MostrarToastContext } from "../../../context/MostrarToast";
import {
  campanha,
  putCampanha,
  putCampanhaProps,
} from "../../../services/campanha";

interface FuncoesGraficoAdsOrganicosProps {
  campanha: campanha;
  taxaOutrasPublicacoes: number;
  setTaxaOutrasPublicacoes: React.Dispatch<React.SetStateAction<number>>;
  recarregar: () => void;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesGraficoAdsOrganicos = ({
  campanha,
  taxaOutrasPublicacoes,
  setTaxaOutrasPublicacoes,
  recarregar,
  setCarregando,
  setErro,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesGraficoAdsOrganicosProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisição = () => {
    mostrarToast(
      "success",
      "Atualizado com sucesso",
      "Valor incluído com sucesso!"
    );
    LimparStates([setTaxaOutrasPublicacoes]);

    recarregar();
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Valor não foi incluído."
    );
  };

  let divGrafico = document.getElementById("divGraficoAdsOrganicos");
  let grafico = divGrafico?.firstElementChild;
  grafico?.setAttribute("id", "graficoAdsOrganicos");

  var graficoAdsOrganicosId = <HTMLCanvasElement>(
    document.getElementById("graficoAdsOrganicos")
  );
  var graficoAdsOrganicosContexto = graficoAdsOrganicosId?.getContext("2d");

  var gradienteOutrasPublis = graficoAdsOrganicosContexto?.createLinearGradient(
    0,
    0,
    1000,
    0
  );
  gradienteOutrasPublis?.addColorStop(0.1, "#c1c1c6");
  gradienteOutrasPublis?.addColorStop(1, "#a0a0a0");

  var gradienteCampanha = graficoAdsOrganicosContexto?.createLinearGradient(
    0,
    0,
    1000,
    0
  );
  gradienteCampanha?.addColorStop(0.1, "#00AD2B");
  gradienteCampanha?.addColorStop(1, "#07886e");

  function adicionarTaxaEngajamentoOutrasPublicacoes() {
    setErro({ ativo: false, mensagem: "" });
    const { token, tokenRenovacao } = obterTokens();

    setCarregando(true);

    let campanhaAtualizada: putCampanhaProps = {
      id: campanha?.id,
      nome: campanha?.nome,
      dataInicio: campanha?.dataInicio,
      dataFim: campanha?.dataFim,
      comentarios: campanha?.comentarios,
      taxaEngajamentoOutrasPublicacoes: taxaOutrasPublicacoes,
    };

    putCampanha(token, campanhaAtualizada)
      .then(() => {
        sucessoRequisição();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putCampanha(token, campanhaAtualizada)
              .then(() => {
                sucessoRequisição();
              })
              .catch((err) => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  return {
    adicionarTaxaEngajamentoOutrasPublicacoes,
    gradienteCampanha,
    gradienteOutrasPublis,
  };
};
