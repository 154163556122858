import React from "react";
import { ErroType } from "../../../@types/erro";
import {
  cadastroTarefasProps,
  tipoPublicacaoProps,
} from "../../../services/campanha";
import { postTarefa } from "../../../services/tarefas";
import { obterTokens } from "../../../utils/LocalStorage";

interface FunctionsModalAcrescentarTarefaNaCampanhaProps {
  idCampanha: string;

  idInfluenciador: string;
  setIdInfluenciador: React.Dispatch<string>;
  perfilInfluenciadorNoFechamentoDoContrato: string;
  setPerfilInfluenciadorNoFechamentoDoContrato: React.Dispatch<string>;
  nomeInfluenciador: string;
  setNomeInfluenciador: React.Dispatch<string>;
  emailInfluenciador: string;
  setEmailInfluenciador: React.Dispatch<string>;

  valorContrato: string;
  setValorContrato: React.Dispatch<string>;
  quantidadeTwitter: number;
  quantidadeInstagramReels: number;
  quantidadeInstagramFeed: number;
  quantidadeInstagramStories: number;
  quantidadeYouTube: number;
  quantidadeYouTubeShorts: number;
  quantidadeTiktok: number;

  tarefa: cadastroTarefasProps[];
  setTarefa: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;

  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  recarregar: () => void;
  setCarregando: React.Dispatch<boolean>;

  setErro: React.Dispatch<React.SetStateAction<ErroType>>;

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;

  myToast: React.MutableRefObject<any>;
}

export const FunctionsModalAcrescentarTarefaNaCampanha = ({
  quantidadeTwitter,
  quantidadeInstagramReels,
  quantidadeInstagramFeed,
  quantidadeInstagramStories,
  quantidadeYouTube,
  quantidadeYouTubeShorts,
  quantidadeTiktok,
  setCarregando,
  myToast,
  idCampanha,
  recarregar,
  setValorContrato,
  valorContrato,
  idInfluenciador,
  perfilInfluenciadorNoFechamentoDoContrato,
  nomeInfluenciador,
  emailInfluenciador,
  setEmailInfluenciador,
  setIdInfluenciador,
  setNomeInfluenciador,
  setPerfilInfluenciadorNoFechamentoDoContrato,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsModalAcrescentarTarefaNaCampanhaProps) => {
  function setaPublicacoes() {
    let publicacoesArray: {
      numeroIdentificadorDePublicacao: number;
      tipoPublicacao: tipoPublicacaoProps;
      externalId: string;
      printsDeMetrica: [];
      printsDeSentimento: [];
      dataFinalizacao: Date;
      sentimento: string;
    }[] = [];

    for (let i = 0; i < quantidadeTwitter; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoTwitter",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramFeed; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoInstagramFeed",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramReels; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoInstagramReels",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramStories; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoInstagramStories",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeTiktok; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoTikTok",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeYouTube; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoYouTube",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeYouTubeShorts; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: i + 1,
        tipoPublicacao: "PublicacaoYouTubeShorts",
        externalId: "",
        printsDeMetrica: [],
        printsDeSentimento: [],
        dataFinalizacao: new Date(),
        sentimento: "Pendente",
      });
    }

    return publicacoesArray;
  }

  function cadastrarTarefaNaCampanha() {
    let tarefa: cadastroTarefasProps = {
      valorContrato: Number(valorContrato),
      publicacoes: setaPublicacoes(),
      idInfluenciador: idInfluenciador,
      perfilInfluenciadorNoFechamentoDoContrato:
        perfilInfluenciadorNoFechamentoDoContrato,
      nomeInfluenciador: nomeInfluenciador,
      emailInfluenciador: emailInfluenciador,
    };
    setCarregando(true);

    const { token, tokenRenovacao } = obterTokens();
    let tarefasArray: cadastroTarefasProps[] = [];
    tarefasArray.push(tarefa);

    const sucessoRequisicao = () => {
      showToast(
        "success",
        "cadastro realizado",
        "Tarefa cadastrada com sucesso!"
      );

      setValorContrato("");
      setIdInfluenciador("");
      setPerfilInfluenciadorNoFechamentoDoContrato("");
      setNomeInfluenciador("");
      setEmailInfluenciador("");
      tarefasArray = [];
      recarregar();
    };

    const erroRequisicao = () => {
      showToast(
        "error",
        "cadastro não realizado",
        "Não foi possível cadastrar a tarefa."
      );
    };

    postTarefa(token, idCampanha, tarefasArray)
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postTarefa(token, idCampanha, tarefasArray)
              .then(() => {
                sucessoRequisicao();
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    setaPublicacoes,
    cadastrarTarefaNaCampanha,
  };
};
