import { useContext } from "react";

import { Prints } from "../../../services/publicacao";
import { putTarefa } from "../../../services/tarefas";
import { obterTokens } from "../../../utils/LocalStorage";

interface FunctionsModalEditarValorDeContratoProps {
  idCampanha: string;
  idTarefa: string;
  printsDeSentimento: Prints[];
  valorContrato?: number;

  setCarregandoRequisicao: React.Dispatch<React.SetStateAction<boolean>>;
  recarregarTabela: () => void;
  fecharModal: () => void;
  mostrarToast: (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalEditarValorDeContrato = ({
  idCampanha,
  idTarefa,
  printsDeSentimento,
  valorContrato,
  mostrarToast,
  fecharModal,
  recarregarTabela,
  setCarregandoRequisicao,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsModalEditarValorDeContratoProps) => {
  function sucessoRequisicao(mensagem: string) {
    mostrarToast(
      "success",
      "Valor do contrato atualizado!",
      mensagem
    );
    fecharModal();
    recarregarTabela();
  }

  function erroRequisicao(mensagem: string) {
    mostrarToast(
      "error",
      "Erro ao atualizar o valor do contrato.",
      mensagem
    );
  }

  function editarValorDoContrato() {
    if (valorContrato) {
      setCarregandoRequisicao(true);
      const { token } = obterTokens();

      putTarefa(token, idCampanha, idTarefa, [], valorContrato)
        .then((res) => {
          sucessoRequisicao(res.data.mensagem);
          setCarregandoRequisicao(false);
        })
        .catch((err) => {
          if (err === "Error: Request failed with status code 401") {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await putTarefa(token, idCampanha, idTarefa, [], valorContrato)
                .then((res2) => {
                  sucessoRequisicao(res2.data.mensagem);
                })
                .catch((err2) => {
                  erroRequisicao(
                    err2.response.data.mensagem
                      ? err2.response.data.mensagem
                      : err2.response.data.message);
                });
            }, setCarregandoRequisicao);
          } else {
            erroRequisicao(
              err.response.data.mensagem
                ? err.response.data.mensagem
                : err.response.data.message);
            setCarregandoRequisicao(false);
          }
        });
    } else {
      mostrarToast("error", "Preencha todos os campos e tente novamente.", "");
    }
  }

  return { editarValorDoContrato };
};
