import { AxiosResponse } from "axios";
import { api } from "./api";

export interface getConverterImagemProps {
    código: number,
    base64: string
}

export const getConverterImagemUrlParaBase64 = (
    urlImagem: string
): Promise<AxiosResponse<getConverterImagemProps, any>> => {
    const url = `/ConverterImagem/UrlParaBase64?Url=${urlImagem}`;

    return api.get(url);
};