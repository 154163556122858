import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BotaoColunaAcoes } from "../../components/Botoes/BotaoColunaAcoes";
import { Carregando } from "../../components/Carregando";
import { TituloColuna } from "../../components/TituloColuna";
import { Documentos } from "../../services/documentosLegais";
import { DateFormatter } from "../../utils/DataFormatter";
import { FunctionTermosDeAceite } from "./functions";
import { tipoOrdenacao } from "../../@types/ordenacao";

import styles from "./styles.module.css";

export const TermosDeAceite = () => {
  
  const [termosDeUso, setTermosDeUso] = useState<Documentos[]>([]);
  const [politicaDePrivacidade, setPoliticaDePrivacidade] = useState<Documentos[]>([]);
  const [politicaDeConteudo, setPoliticaDeConteudo] = useState<Documentos[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [abrirModalDeslogar, setAbrirModalDeslogar] = useState<boolean>(false);
  const [tipoOrdenacao, setTipoOrdenacao] = useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<string>("Nome");

  const navigate = useNavigate();

  const myToast = useRef<any>(null);

  const { pegaDocumentosLegais } = FunctionTermosDeAceite({
    setPoliticaDeConteudo,
    setPoliticaDePrivacidade,
    setTermosDeUso,
    navigate,
    myToast,
    setAbrirModalDeslogar,
    setCarregando,
  });

  const tituloColuna = [
    {
      nome: "Tipo de Termo",
      ordenacao: "Tipo",
    },
    {
      nome: "Data de Upload",
      ordenacao: "Tipo",
    },
    {
      nome: "Ações",
      ordenacao: "Tipo",
    },
  ];

  useEffect(() => {
    pegaDocumentosLegais();
  }, []);

  return (
    <table
      id="TabelaListarTermosDeAceiteCliente"
      className={styles.containerTabela}
    >
      <thead>
        <tr>
          <th className={styles.headerTabela}>
            <div className={styles.tituloTabela}>
              <h1>Termos de Aceite</h1>
            </div>
          </th>
        </tr>

        <tr>
          {tituloColuna.map((titulo) => {
            return (
              <TituloColuna
                key={tituloColuna.indexOf(titulo)}
                colunaIndex={tituloColuna.indexOf(titulo)}
                tituloColuna={titulo.nome}
                tituloOrdenacao={titulo.ordenacao}
                iconOrdenacao={[]}
                tipoOrdenacao={tipoOrdenacao}
                setTipoOrdenacao={setTipoOrdenacao}
                nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
              />
            );
          })}
        </tr>
      </thead>

      <tbody
        id="corpoTabelaListarCampanhasInfluenciador"
        className={styles.corpoTabela}
      >
        {carregando ? (
          <Carregando listaUsuarios={3} spinner={true} />
        ) : (
          <>
            {termosDeUso.length === 0 &&
            politicaDeConteudo.length === 0 &&
            politicaDePrivacidade.length === 0 ? (
              <tr>
                <td colSpan={3} style={{ justifyContent: "center" }}>
                  Nenhum resultado a ser exibido.
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td className={styles.colunaNome}>Termos de Uso</td>
                  <td>{DateFormatter(termosDeUso[0].dataCriacao)}</td>
                  <td>
                    <BotaoColunaAcoes
                      status={""}
                      icone={"pi-file"}
                      titulo={"Ver documento"}
                      onClick={() => {
                        window.open(termosDeUso[0].urlArquivo);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.colunaNome}>Política de Privacidade</td>
                  <td>
                    <p>
                      {DateFormatter(politicaDePrivacidade[0].dataCriacao)}{" "}
                    </p>
                  </td>
                  <td>
                    <BotaoColunaAcoes
                      status={""}
                      icone={"pi-file"}
                      titulo={"Ver documento"}
                      onClick={() => {
                        window.open(politicaDePrivacidade[0].urlArquivo);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.colunaNome}>Política de Conteúdo</td>
                  <td>
                    <p>{DateFormatter(politicaDeConteudo[0].dataCriacao)} </p>
                  </td>
                  <td>
                    <BotaoColunaAcoes
                      status={""}
                      icone={"pi-file"}
                      titulo={"Ver documento"}
                      onClick={() => {
                        window.open(politicaDeConteudo[0].urlArquivo);
                      }}
                    />
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};
