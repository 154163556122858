import { Crop } from "react-image-crop/dist/types";
import { ErroType } from "../../../@types/erro";
import { getSenhaSolicitarRedefinicao } from "../../../services/usuario";

interface FunctionsBotaoUploadImagemProps {
    setImg: React.Dispatch<React.SetStateAction<string>>;
    setImagem: React.Dispatch<React.SetStateAction<string>>;
    previewImgRef: React.RefObject<HTMLImageElement>;
    completedCrop: Crop | undefined;
}

export const FunctionsBotaoUploadImagem = ({
    setImg,
    setImagem,
    previewImgRef,
    completedCrop
}: FunctionsBotaoUploadImagemProps) => {

    function enviarImagemRecortada() {
        // create a canvas element to draw the cropped image
        const canvas = document.createElement("canvas");

        // get the image element
        const image = previewImgRef.current;

        // draw the image on the canvas
        if (image) {
            const crop = completedCrop;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext("2d");
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop!.width * pixelRatio * scaleX;
            canvas.height = crop!.height * pixelRatio * scaleY;

            if (ctx) {
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.imageSmoothingQuality = "high";

                ctx.drawImage(
                    image,
                    crop!.x * scaleX,
                    crop!.y * scaleY,
                    crop!.width * scaleX,
                    crop!.height * scaleY,
                    0,
                    0,
                    crop!.width * scaleX,
                    crop!.height * scaleY
                );
            }

            const base64Image = canvas.toDataURL("image/png");

            setImagem(base64Image);
            setImg(base64Image);
        }
    }

    return {
        enviarImagemRecortada
    };
};
