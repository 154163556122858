import { TabPanel, TabView } from "primereact/tabview"
import React, { useContext, useState } from "react"
import styles from "./styles.module.css";
import { BotaoAtualizarTermos } from "../../../components/Botoes/BotaoAtualizarTermos"
import { CredenciaisContext } from "../../../context/CredenciaisContext"
import { TermosDeUsoListagem } from "../../../components/Documentos/TermosDeUsoListagem";
import { PoliticaDeConteudoListagem } from "../../../components/Documentos/PolíticaDeConteúdoListagem";
import { PoliticaDePrivacidadeListagem } from "../../../components/Documentos/PolíticaDePrivacidadeListagem";

export const DocumentosLegais = () => {

    let perfil = useContext(CredenciaisContext).credenciais.perfil[0];

    const [recarregarTudo, setRecarregarTudo] = useState<boolean>(false);

    return (
        <div>
            <div className={styles.containerTitulo}>
                <p className={styles.titulo}>
                    Documentos
                </p>
                {(perfil === "UsuarioNetcos" || perfil === "Administrador") &&
                    <BotaoAtualizarTermos
                        setRecarregarTudo={setRecarregarTudo}
                    />
                }
            </div>
            <div id={styles.containerListaDocumentos}>
                <TabView>
                    <TabPanel header="Termos de Uso">
                        <TermosDeUsoListagem 
                            recarregarTudo={recarregarTudo}
                        />
                    </TabPanel>
                    <TabPanel header="Política de Privacidade">
                        <PoliticaDePrivacidadeListagem 
                            recarregarTudo={recarregarTudo}
                        />
                    </TabPanel>
                    <TabPanel header="Política de Conteúdo">
                        <PoliticaDeConteudoListagem 
                            recarregarTudo={recarregarTudo}
                        />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}