import { Color,} from "../../../../utils/CSSGlobal/typeColors";
import styles from "./styles.module.css";

export interface CampoInformacoesProps {
  titulo?: any;
  subtitulo?: string;
  children?: React.ReactNode;
  corDeFundo?: Color
}
export const CampoInformacoes = ({
  titulo,
  subtitulo,
  children,
  corDeFundo
}: CampoInformacoesProps) => {

  return (
    <div className={styles.camposInformacoes} style={ { backgroundColor : corDeFundo ? corDeFundo : "" }}>
      {titulo && <div className={styles.titulos}>{titulo}</div>}
      {subtitulo && <p className={styles.subtitulos}>{subtitulo}</p>}
      <div className={styles.conteúdo}>{children}</div>
    </div>
  );
};
