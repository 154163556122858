import { useState } from 'react';

import styles from "./styles.module.css"

import { FunctionsModalRedefinicao } from './functions';

import { Button } from '../../Botoes/Button';
import { Input } from '../../Input';
import { ModalTeste } from '../ModalTeste';

import { ErroType } from '../../../@types/erro';


interface ModalRedefinicaoProps {
    modalAberto: boolean;
    setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
    setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
    emailPrevio?: string;
}


export const ModalRedefinicao = ({ modalAberto, setModalAberto, emailPrevio }: ModalRedefinicaoProps) => {
    const [email, setEmail] = useState<string>(emailPrevio ? emailPrevio : '');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [erro, setErro] = useState<ErroType>({
        ativo: false,
        mensagem: ''
    });
    const [sucesso, setSucesso] = useState<boolean>(false);

    const { solicitaRedefinicao } = FunctionsModalRedefinicao({ email, setCarregando, setSucesso, setEmail, setModalAberto, setErro });

    return (
        <ModalTeste modalAberto={modalAberto} setModalAberto={setModalAberto} titulo="Redefinição de Senha">
            <div
                onSubmit={(e) => e.preventDefault()}
                className={styles.conteudoModalRedefinicaoDeSenha}>
                {sucesso ? (
                    <p className={styles.mensagemSucesso}>
                        {emailPrevio ?
                            "Uma nova solicitação de redefinição de senha foi enviada ao seu email"
                            :
                            "Uma nova solicitação de redefinição de senha foi enviada a esse email"
                        }
                    </p>
                )
                    :
                    (
                        <div className={styles.conteudoInformacoesModalRedefinicao}>

                            <p className={styles.tituloModalRedefinicao}>
                                <p>Tem certeza que deseja redefinir a senha do usuário?</p>
                                {emailPrevio ? "O usuário receberá um email para realizar a sua troca de senha no email abaixo:" : "Insira o seu Email abaixo:"}
                            </p>
                            <div className={styles.containerInputEmail}>
                                {emailPrevio ?
                                    <p>{emailPrevio}</p>
                                    :
                                    <Input
                                        type='email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                }
                            </div>

                            {erro.ativo && <p className={styles.mensagemFalha}>{erro.mensagem}</p>}
                        </div>
                    )
                }
                {carregando ? <p>Carregando...</p> :
                    !sucesso &&
                    <div className={styles.containerBotoesModal}>
                        <Button
                            importancia="terciario"
                            type="button"
                            title="Cancelar"
                            onClick={() => setModalAberto(false)}
                        />
                        <Button importancia='secundario' title='Enviar' type='submit' onClick={solicitaRedefinicao} />
                    </div>
                }
            </div>
        </ModalTeste>
    );
};