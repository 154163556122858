import ImagemNetcosImpressao from "../../../assets/img/Netcos.png";

export const FuncoesTabelaListarCampanhasAgencias = () => {
  function criaPDFListaDeCampanhas() {
    const minhaTabela = document.getElementById(
      "corpoTabelaListarCampanhasAgencia"
    )!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
      style +
      "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
      style +
      "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(`<div><img src="${ImagemNetcosImpressao}"></img></div>`);
    win.document.write("<table>");

    win.document.write(
      "<tr><th>Nome</th><th>Marca</th><th>Data Início</th><th>Data Fim</th><th>Taxa de Engajamento</th><th>Custo por Engajamento</th><th>Data do Relatório</th><th>Status</th></tr>"
    ); //Titulos das Colunas

    win.document.write(minhaTabela);

    var removerDadosColunaAcoes =
      win.document.querySelectorAll(".colunaBotoesAcoes"); //REMOVE OS BOTOES DA COLUNA
    removerDadosColunaAcoes.forEach((colunaAcoes: any) => {
      colunaAcoes.remove();
    });

    var removerImagensLogotipos = win.document.querySelectorAll(".p-avatar"); //REMOVE AS IMAGENS DA COLUNA
    removerImagensLogotipos.forEach((imagem: any) => {
      imagem.remove();
    });

    var removerIconeStatus = win.document.querySelectorAll(".iconeStatus"); //REMOVE OS ICONES DO STATUS
    removerIconeStatus.forEach((icone: any) => {
      icone.remove();
    });

    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    setTimeout(function () {
      win.print(); // IMPRIME O CONTEUDO
    }, 1000);
    win.document.close(); // FECHA A JANELA
  }

  return {
    criaPDFListaDeCampanhas,
  };
};
