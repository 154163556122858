import { useState } from "react";

import styles from "./styles.module.css";

import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";

export interface ModalExpandirComentarioProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  id?: string;
  comentario: string;
}

export const ModalExpandirComentario = ({
  modalAberto,
  setModalAberto,
  comentario,
  id,
}: ModalExpandirComentarioProps) => {
  const [comentarios, setComentarios] = useState<any>(comentario);

  return (
    <div className={styles.modalCompletoEdicaoComentarioNetcos}>
      <Dialog
        className={styles.modalEdicaoComentarioNetcos}
        header={<h1>Comentários sobre a campanha</h1>}
        visible={modalAberto}
        onHide={() => setModalAberto(false)}
        position={"right"}
        draggable={false}
        resizable={false}
      >
        <>
          <div
            className={styles.conteudoModalAdicaoDeComentariosCampanhaOutros}
          >
            <Editor
              className={styles.editorComentario}
              value={comentarios}
              onTextChange={(e) => setComentarios(e.htmlValue)}
              style={{ height: "30rem" }}
            />
          </div>
        </>
      </Dialog>
    </div>
  );
};
