import { useState, createContext, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface DesconectarContextProps {
  desconectar: boolean;
  setDesconectar: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DesconectarContext = createContext<DesconectarContextProps>({
  desconectar: false,
  setDesconectar: () => {},
});

export const DesconectarProvider = ({ children }: Props) => {
  const [desconectar, setDesconectar] = useState<boolean>(false);

  return (
    <DesconectarContext.Provider value={{ desconectar, setDesconectar }}>
      {children}
    </DesconectarContext.Provider>
  );
};
