import { useContext } from "react";

import { putCampanha } from "../../../services/campanha";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsModalAdicaoDeComentariosCampanhaProps {
  id?: string;
  comentarios: string | null;
  setCarregando: React.Dispatch<boolean>;
  setModalAdicaoDeComentariosCampanhaAberto: React.Dispatch<boolean>;
  recarregar: () => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalAdicaoDeComentariosCampanha = ({
  comentarios,
  id,
  setCarregando,
  setModalAdicaoDeComentariosCampanhaAberto,
  recarregar,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsModalAdicaoDeComentariosCampanhaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  function sucessoRequisicao(mensagem: string) {
    mostrarToast(
      "success",
      "Comentário salvo",
      mensagem
    );
    setCarregando(false);
    setModalAdicaoDeComentariosCampanhaAberto(false);
    recarregar();
  }

  function erroRequisicao(mensagem: string) {
    mostrarToast(
      "error",
      "Comentário não realizado",
      mensagem
    );
  }

  function salvarComentarioDaCampanha() {
    setCarregando(true);

    const { token } = obterTokens();

    let comentarioLimpo = comentarios == "<p class=\"ql-align-justify\"><br></p>" ? "" : comentarios;

    putCampanha(token, {
      comentarios: comentarioLimpo ?? "",
      id: id ? id : "",
      dataFim: null,
      dataInicio: null,
      nome: null,
    })
      .then((res) => {
        sucessoRequisicao(res.data.mensagem);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putCampanha(token, {
              comentarios,
              id: id ? id : "",
              dataFim: null,
              dataInicio: null,
              nome: null,
            })
              .then((res2) => {
                sucessoRequisicao(res2.data.mensagem);
              })
              .catch((err2) => {
                erroRequisicao(
                  err2.response.data.mensagem
                    ? err2.response.data.mensagem
                    : err2.response.data.message);
              });
          }, setCarregando);
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message);
          setCarregando(false);
        }
      });
  }

  return { salvarComentarioDaCampanha };
};
