import { useEffect, useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

import { PublicacoesProps } from "../../../services/publicacao";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";
import { CampoInformacoes } from "../../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { TituloColuna } from "../../TituloColuna";
import { Carregando } from "../../Carregando";
import { ErroTabela } from "../../ErroTabela";

import styles from "./styles.module.css";
import { FuncoesTabelaMetricasTwitter } from "./functions";
import { format } from "../../../utils/MascaraNumero/format";
import { CarrosselMetricas } from "../../Carrossel/CarrosselMetricas";
import { RiBarChartFill } from "react-icons/ri";
import { AiOutlineRetweet } from "react-icons/ai";
import { DateFormatter } from "../../../utils/DataFormatter";
import { obterCredenciais } from "../../../utils/LocalStorage";
import { TwitterEmbed } from "react-social-media-embed";

<script async src="https://platform.twitter.com/widgets.js"></script>;

export interface TabelaMetricasTwitterProps {
  listaDePublicacoes: PublicacoesProps[];
  nomeInfluenciadorSelecionado: string;
}

export const TabelaMetricasTwitter = ({
  listaDePublicacoes,
  nomeInfluenciadorSelecionado,
}: TabelaMetricasTwitterProps) => {
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<
    "Id" | "DataFinalizacao" | "Status" | string
  >("Id");
  const [tipoOrdenacao, setTipoOrdenacao] =
    useState<tipoOrdenacao>("Ascending");

  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });

  let larguraPagina = window.innerWidth;

  const colunasTabela = [
    { nome: "Engajamento", ordenacao: "" },
    { nome: "Comentários", ordenacao: "" },
    { nome: "Favoritos", ordenacao: "" },
    { nome: "Retweets", ordenacao: "" },
    { nome: "Impressões", ordenacao: "" },
    { nome: "Respostas", ordenacao: "" },
    { nome: "Cliques Link", ordenacao: "" },
    { nome: "Cliques Hashtag", ordenacao: "" },
    { nome: "Detail Expands", ordenacao: "" },
  ];

  const [engajamento, setEngajamento] = useState<number>(0);
  const [comentarios, setComentarios] = useState<number>(0);
  const [favoritos, setFavoritos] = useState<number>(0);
  const [retweets, setRetweets] = useState<number>(0);
  const [impressoes, setImpressoes] = useState<number>(0);
  const [respostas, setRespostas] = useState<number>(0);
  const [cliquesLink, setCliquesLink] = useState<number>(0);
  const [cliquesHashtag, setCliquesHashtag] = useState<number>(0);
  const [detailExpands, setDetailExpands] = useState<number>(0);

  const perfil = obterCredenciais().credenciais.perfil[0];

  const { somaDadosPublicacaoTwitter, pegaCodigoTwitterERetornaEmbed } =
    FuncoesTabelaMetricasTwitter({
      setEngajamento,
      setComentarios,
      setFavoritos,
      setRetweets,
      setImpressoes,
      setRespostas,
      setCliquesLink,
      setCliquesHashtag,
      setDetailExpands,
    });

  useEffect(() => {
    somaDadosPublicacaoTwitter(listaDePublicacoes);
  }, []);

  return (
    <div className={styles.conteudoTotal}>
      <CampoInformacoes titulo={"Métricas"} corDeFundo={"var(--branco)"}>
        <table className={styles.containerTabela}>
          <thead>
            <tr>
              {colunasTabela.map((titulo) => {
                return (
                  <TituloColuna
                    key={colunasTabela.indexOf(titulo)}
                    colunaIndex={colunasTabela.indexOf(titulo)}
                    tituloColuna={titulo.nome}
                    tituloOrdenacao={titulo.ordenacao}
                    iconOrdenacao={[]}
                    tipoOrdenacao={tipoOrdenacao}
                    setTipoOrdenacao={setTipoOrdenacao}
                    nomePropriedadeOrdenacao={nomePropriedadeOrdenacao}
                    setNomePropriedadeOrdenacao={setNomePropriedadeOrdenacao}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody className={styles.corpoTabela}>
            {carregando ? (
              <Carregando
                listaUsuarios={listaDePublicacoes.length}
                spinner={true}
              />
            ) : (
              <>
                {erro.ativo ? (
                  <ErroTabela erro={erro} setRecarregar={setRecarregar} />
                ) : (
                  <>
                    {listaDePublicacoes && listaDePublicacoes.length === 0 ? (
                      <tr>
                        <td style={{ justifyContent: "center" }}>
                          Nenhum resultado a ser exibido.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          {format("#.###.##0,#0", engajamento).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", comentarios).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", favoritos).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", retweets).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", impressoes).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", respostas).replace(",00", "")}
                        </td>
                        <td>
                          {format("#.###.##0,#0", cliquesLink).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", cliquesHashtag).replace(
                            ",00",
                            ""
                          )}
                        </td>
                        <td>
                          {format("#.###.##0,#0", detailExpands).replace(
                            ",00",
                            ""
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            )}
          </tbody>
          <tfoot className={styles.footerTabela}></tfoot>
        </table>

        <div className={styles.containerLista}>
          <div>
            <p>Engajamento: </p>
            {engajamento}
          </div>
          <div>
            <p>Comentários: </p>
            {comentarios}
          </div>
          <div>
            <p>Favoritos: </p>
            {favoritos}
          </div>
          <div>
            <p>Retweets: </p>
            {retweets}
          </div>
          <div>
            <p>Impressões: </p>
            {impressoes}
          </div>
          <div>
            <p>Respostas: </p>
            {respostas}
          </div>
          <div>
            <p>Cliques Link: </p>
            {cliquesLink}
          </div>
          <div>
            <p>Cliques Hashtag: </p>
            {cliquesHashtag}
          </div>
          <div>
            <p>Detail Expands: </p>
            {detailExpands}
          </div>
        </div>
      </CampoInformacoes>
      <CampoInformacoes titulo={"Publicações"}>
        {/* <div className={styles.campoPublicacoes}> */}
        {listaDePublicacoes.map((publicacao) => {
          return (
            <div className={styles.containerPublicacao}>
              <div className={styles.tituloPublicacao}>
                {(perfil === "Administrador" || perfil === "UsuarioNetcos") &&
                  `${publicacao.numeroIdentificadorDePublicacao} - `}
                Data da Publicação: {DateFormatter(publicacao.dataFinalizacao)}
              </div>
              <div className={styles.containerResultadoEPublicacoes}>
                <div className={styles.containerDadosResultados}>
                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-eye"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Views</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.views).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-chart-line"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Engajamentos</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.engajamento).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-comments"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Comentários</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.comentarios).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-heart"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Favoritos</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.favoritos).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <AiOutlineRetweet
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    />
                    <div>
                      <h1>Retweets</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.retweets).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-user-plus"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Impressões</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.impressoes).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-reply"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Respostas</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.respostas).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-link"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Cliques no Link</h1>
                      <p>
                        {format("#.###.##0,#0", publicacao.cliquesLink).replace(
                          ",00",
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <i
                      className="pi pi-hashtag"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    ></i>
                    <div>
                      <h1>Cliques na Hashtag</h1>
                      <p>
                        {format(
                          "#.###.##0,#0",
                          publicacao.cliquesHashtag
                        ).replace(",00", "")}
                      </p>
                    </div>
                  </div>

                  <div className={styles.dadoResultado}>
                    <RiBarChartFill
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        color: "var(--roxo-500)",
                      }}
                    />
                    <div>
                      <h1>Detail Expands</h1>
                      <p>
                        {format(
                          "#.###.##0,#0",
                          publicacao.detailExpands
                        ).replace(",00", "")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className={styles.campoPublicacao}>
                  <div className={styles.campoEmbed}>
                    <>
                      <p className={styles.headerCampoEmbed}>
                        Evidência da Publicação
                      </p>
                      <TwitterEmbed url={publicacao.urlPublicacao!} />
                    </>
                  </div>
                  {/* <div className={styles.carrosselEvidencias}>
                    <CarrosselMetricas
                      titulo="Evidência da publicação"
                      prints={publicacao.midiasDeEvidencia}
                      exibicao
                    />
                  </div> */}

                  {publicacao.printsDeMetrica?.length! > 0 && (
                    <div className={styles.carrosselMetricas}>
                      <CarrosselMetricas
                        prints={publicacao.printsDeMetrica}
                        exibicao
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {/* </div> */}
      </CampoInformacoes>
    </div>
  );
};
