import { useContext } from "react";

import { MostrarToastContext } from "../../../context/MostrarToast";
import { Prints } from "../../../services/publicacao";
import { putTarefa } from "../../../services/tarefas";
import { obterCredenciais, obterTokens } from "../../../utils/LocalStorage";

interface FunctionsPostarPrintSentimentoProps {
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  // sentimento: string;
  // prints: File[];
  printsPositivos: File[];
  printsNeutros: File[];
  printsNegativos: File[];
  setPrintsPositivos: React.Dispatch<React.SetStateAction<File[]>>;
  setPrintsNeutros: React.Dispatch<React.SetStateAction<File[]>>;
  setPrintsNegativos: React.Dispatch<React.SetStateAction<File[]>>;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  printSentimento: Prints[];
  setPrintsSentimento: React.Dispatch<React.SetStateAction<Prints[]>>;
  idCampanha: string;
  idTarefa: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsPostarPrintSentimento = ({
  setCarregando,
  // sentimento,
  printsPositivos,
  printsNeutros,
  printsNegativos,
  setPrintsPositivos,
  setPrintsNeutros,
  setPrintsNegativos,
  setModalAberto,
  printSentimento,
  setPrintsSentimento,
  setRecarregar,
  idCampanha,
  idTarefa,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsPostarPrintSentimentoProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = (mensagem: string) => {
    setRecarregar((e) => !e);
    setPrintsPositivos([]);
    setPrintsNeutros([]);
    setPrintsNegativos([]);
    mostrarToast(
      "success",
      "Cadastro realizado",
      mensagem
    );
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Cadastro não realizado",
      mensagem
    );
  };

  const { credenciais } = obterCredenciais();

  const converteImgPara64 = (
    event: any,
    array: Prints[],
    sentimento: string
  ) => {
    var result: string = "";

    var reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
    reader.onloadend = function () {
      result = reader.result ? reader.result.toString() : "";
      array.push({
        idUploader: credenciais.id,
        imagem: result,
        sentimento: sentimento,
        dataUpload: new Date().toISOString(),
      });
    };
  };

  function mapeaObjeto(printsPut: File[], sentimento: string) {
    let arrayPrintSentimentos: Prints[] = [];

    for (let i = 0; i < printsPut.length; i++) {
      converteImgPara64(printsPut[i], arrayPrintSentimentos, sentimento);
    }

    return arrayPrintSentimentos;
  }

  function postarPrintRepercussao() {
    const { token, tokenRenovacao } = obterTokens();

    let printsAntigos = printSentimento;
    let publicacaoPositivaMapeada = mapeaObjeto(printsPositivos, "positivo");
    let publicacaoNeutraMapeada = mapeaObjeto(printsNeutros, "neutro");
    let publicacaoNegativaMapeada = mapeaObjeto(printsNegativos, "negativo");

    setCarregando(true);
    setTimeout(async () => {
      let transformarArrayParaEnviar = printsAntigos.map(async function (item) {
        return {
          idUploader: item.idUploader,
          dataUpload: item.dataUpload,
          sentimento: item.sentimento,
          imagem: item.urlImagem,
        };
      });

      const arrayFinalizada = await Promise.all(transformarArrayParaEnviar);

      let todasPublicacoes = [
        ...arrayFinalizada,
        ...publicacaoPositivaMapeada,
        ...publicacaoNeutraMapeada,
        ...publicacaoNegativaMapeada,
      ];
      setPrintsSentimento(todasPublicacoes);
      let publisParaEnvio = [
        ...publicacaoPositivaMapeada,
        ...publicacaoNeutraMapeada,
        ...publicacaoNegativaMapeada,
      ];

      putTarefa(token, idCampanha, idTarefa, publisParaEnvio)
        .then((res) => {
          sucessoRequisicao(res.data.mensagem);
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await putTarefa(token, idCampanha, idTarefa, publisParaEnvio)
                .then((res2) => {
                  sucessoRequisicao(res2.data.mensagem);
                })
                .catch((err2) => {
                  erroRequisicao(
                    err2.response.data.mensagem
                      ? err2.response.data.mensagem
                      : err2.response.data.message);
                });
            }, setCarregando);
          } else {
            erroRequisicao(
              err.response.data.mensagem
                ? err.response.data.mensagem
                : err.response.data.message);
            setCarregando(false);
          }
        });
    }, 1000);
  }

  const limpaImagens = () => {
    setPrintsPositivos([]);
    setPrintsNeutros([]);
    setPrintsNegativos([]);
  };

  return {
    postarPrintRepercussao,
    limpaImagens,
  };
};
