import { useRef } from "react";

import { functionsListarTodosUsuarios } from "./functions";

import { ListarInfluenciador } from "../ListarInfluenciador";
import { ListarUsuariosNetcos } from "../ListarUsuarioNetcos";
import { ListarUsuariosMarca } from "../ListarUsuariosMarca";
import { ListarUsuariosAgencia } from "../ListarUsuariosAgencia";

import "primereact/resources/themes/lara-light-teal/theme.css";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";

import styles from "./styles.module.css";

export const ListarTodosUsuarios = () => {
  const myToast = useRef<any>(null);

  const { showToast } = functionsListarTodosUsuarios({ myToast });

  return (
    <div id={styles.containerListaUsuarios}>
      <TabView>
        <TabPanel header="Marca">
          <ListarUsuariosMarca showToast={showToast} />
        </TabPanel>

        <TabPanel header="Influenciador">
          <ListarInfluenciador />
        </TabPanel>

        <TabPanel header="Agência">
          <ListarUsuariosAgencia />
        </TabPanel>

        <TabPanel header="NetCos">
          <ListarUsuariosNetcos />
        </TabPanel>
      </TabView>

      <Toast ref={myToast} className="custom-toast" />
    </div>
  );
};
