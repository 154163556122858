import { useRef } from "react";

import "./utils/CSSGlobal/global.css";
import "./App.css";

import { ModalDeslogar } from "./components/Modais/ModalDeslogar";

import { CredenciaisProvider } from "./context/CredenciaisContext";
import { MostrarToastProvider } from "./context/MostrarToast";
import { DesconectarProvider } from "./context/DesconectarContext";
import { AgenciaProvider } from "./context/AgenciaContext";
import { MarcaProvider } from "./context/MarcaContext";
import { RenovarTokenProvider } from "./context/RenovarTokenContext";

import { Rotas } from "./routes/Routes";

import { Toast } from "primereact/toast";

function App() {
  const myToast = useRef<any>(null);

  return (
    <div className="App">
      <MostrarToastProvider myToast={myToast}>
        <CredenciaisProvider>
          <DesconectarProvider>
            <RenovarTokenProvider>
              <AgenciaProvider>
                <MarcaProvider>
                  <Rotas />
                  <ModalDeslogar />
                </MarcaProvider>
              </AgenciaProvider>
            </RenovarTokenProvider>
          </DesconectarProvider>
        </CredenciaisProvider>
      </MostrarToastProvider>

      <Toast ref={myToast} className="custom-toast" />
    </div>
  );
}

export default App;
