import { cadastroTarefasProps } from "../../../services/campanha";
import { ModalCadastroTarefa } from "../../Modais/ModalCadastroTarefas";
import { mascaraBRLExibir } from "../../../utils/MascaraBRL";

import styles from "./styles.module.css";
import { FuncoesCadastroTarefa } from "./functions";
import { CardTarefaCampanha } from "../../Cards/CardTarefaCampanha";

export interface TabelaListarTarefasProps {
  tarefas: cadastroTarefasProps[];
  setTarefas: React.Dispatch<React.SetStateAction<cadastroTarefasProps[]>>;
}

export const TabelaListarTarefas = ({
  tarefas,
  setTarefas,
}: TabelaListarTarefasProps) => {
  const { removerTarefas } = FuncoesCadastroTarefa({ tarefas, setTarefas });

  return (
    <div className={styles.campoListarTarefas}>
      <div className={styles.containerListaTarefas}>
        <table className={styles.listaTarefas}>
          <thead>
            <tr className={styles.headerListaTarefas}>
              <th>Influenciador</th>
              <th>Email</th>
              <th>Twitter</th>
              <th>Reels</th>
              <th>Feed</th>
              <th>Stories</th>
              <th>YouTube</th>
              <th>Shorts</th>
              <th>Tiktok</th>
              <th>Valor</th>

              <th className={styles.colunaBotoes}></th>
            </tr>
          </thead>
          <tbody>
            {tarefas.length > 0 ? (
              tarefas.map(
                (
                  {
                    publicacoes,
                    nomeInfluenciador,
                    valorContrato,
                    emailInfluenciador,
                  },
                  index
                ) => {
                  return (
                    <tr key={index}>
                      <td>{nomeInfluenciador}</td>
                      <td>{emailInfluenciador}</td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) => x.tipoPublicacao === "PublicacaoTwitter"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) =>
                              x.tipoPublicacao === "PublicacaoInstagramReels"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) =>
                              x.tipoPublicacao === "PublicacaoInstagramFeed"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) =>
                              x.tipoPublicacao === "PublicacaoInstagramStories"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) => x.tipoPublicacao === "PublicacaoYouTube"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) =>
                              x.tipoPublicacao === "PublicacaoYouTubeShorts"
                          ).length
                        }
                      </td>
                      <td>
                        {
                          publicacoes.filter(
                            (x) => x.tipoPublicacao === "PublicacaoTikTok"
                          ).length
                        }
                      </td>
                      <td>R$ {mascaraBRLExibir(valorContrato.toFixed(2))}</td>
                      <td className={styles.colunaBotoes}>
                        <button
                          className={styles.botaoRemoverTarefa}
                          onClick={() => removerTarefas(index)}
                        >
                          <i className="pi pi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <p className={styles.campoVazio}>
                Nenhum(a) influenciador(a) na campanha
              </p>
            )}
          </tbody>
        </table>

        <div className={styles.listaCardsTarefas}>
          {tarefas.length > 0 ? (
            tarefas.map(
              (
                {
                  publicacoes,
                  nomeInfluenciador,
                  valorContrato,
                  emailInfluenciador,
                },
                index
              ) => {
                return (
                  <CardTarefaCampanha
                    publicacoes={publicacoes}
                    nomeInfluenciador={nomeInfluenciador}
                    valorContrato={valorContrato}
                    emailInfluenciador={emailInfluenciador}
                    index={index}
                    removerTarefas={removerTarefas}
                  />
                );
              }
            )
          ) : (
            <p className={styles.campoVazio}>
              Nenhum(a) influenciador(a) na campanha
            </p>
          )}
        </div>
      </div>
      <div className={styles.containerAdicionarInfluenciador}>
        <ModalCadastroTarefa
          tarefas={tarefas}
          setTarefas={setTarefas}
          importancia="secundario"
        />
      </div>
    </div>
  );
};
