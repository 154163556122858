import { useContext } from "react";
import { deletePublicacao } from "../../../services/publicacao";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FuncoesModalExclusaoPublicacaoProps {
  idCampanha: string;
  idPublicacao: string;
  myToast: React.MutableRefObject<any>;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesModalExclusaoPublicacao = ({
  setCarregando,
  setModalAberto,
  setRecarregar,
  myToast,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesModalExclusaoPublicacaoProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = (mensagem: string) => {
    mostrarToast(
      "success",
      "Exclusão realizada",
      mensagem
    );

    setRecarregar((recarregar) => !recarregar);
  };

  const erroRequisicao = (mensagem: string) => {
    mostrarToast(
      "error",
      "Exclusão não realizado",
      mensagem
    );
  };
  function deletarPublicacao(idCampanha: string, idPublicacao: string) {
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    deletePublicacao(token, idCampanha, idPublicacao)
      .then((res: any) => {
        sucessoRequisicao(res.data.mensagem);
        setModalAberto(false);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await deletePublicacao(token, idCampanha, idPublicacao)
              .then((res2: any) => {
                sucessoRequisicao(res2.data.mensagem);
                setModalAberto(false);
              })
              .catch((err2) => {
                erroRequisicao(err2.response.data.mensagem);
              });
          }, setCarregando);
        } else {
          erroRequisicao(err.response.data.mensagem);
          setCarregando(false);
        }
      });
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    deletarPublicacao,
  };
};
