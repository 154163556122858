import styles from "./styles.module.css";

export interface CarregandoProps {
    botao?: boolean;
    spinner?: boolean;
    listaUsuarios?: number;
}

export const Carregando = ({ spinner, listaUsuarios }: CarregandoProps) => {
    return (
        <>
            {
                spinner && spinner ?
                    <tr>
                        <td style={{ justifyContent: "center", height: listaUsuarios ? listaUsuarios <= 5 ? '35rem' : '70rem' : '7rem' }}>
                            <i
                                className="pi pi-spin pi-spinner"
                                style={{ fontSize: "2.5rem", paddingRight: "1rem", paddingLeft: "1rem" }}
                            />
                        </td>
                    </tr>
                    :
                    <span className={styles.containerCarregando}>
                        <span className={styles.limitadorAnimacao}>
                            <span className={styles.carregamento}></span>
                        </span>
                    </span>
            }
        </>
    )
};