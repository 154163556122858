import { useEffect, useState, useContext } from "react";

import styles from "./styles.module.css";

import { FuncoesRepercussao } from "./functions";

import { CampoInformacoes } from "../../pages/UsuarioNetcos/CampanhaDetalhes/CampoInformacoes";
import { Prints, PublicacoesProps } from "../../services/publicacao";
import { Button } from "../Botoes/Button";
import { CarrosselRepercussao } from "../Carrossel/CarrosselRepercussao";
import { ErroType } from "../../@types/erro";
import { ModalAdcRepercussão } from "../Modais/ModalAdcRepercussão";
import { Carregando } from "../Carregando";
import { GraficoRepercussao } from "../Graficos/GraficoRepercussao";
import { tarefaProps } from "../../services/tarefas";
import { obterCredenciais } from "../../utils/LocalStorage";

import { RenovarTokenContext } from "../../context/RenovarTokenContext";

export interface RepercussaoProps {
  idCampanha: string;
  idInfluenciadorSelecionado: string;
  tarefa: tarefaProps | undefined;
  publicacaoSelecionada: PublicacoesProps;
}

export const Repercussao = ({
  idCampanha,
  idInfluenciadorSelecionado,
  tarefa,
  publicacaoSelecionada,
}: RepercussaoProps) => {
  // ------------ Consts Gerais -------------

  const [carregando, setCarregando] = useState<boolean>(false);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [recarregar, setRecarregar] = useState<boolean>(false);
  const [modalAdcRepercussao, setModalAdcRepercussao] =
    useState<boolean>(false);

  // ------------ Lista de Publicações -------------

  const [printsSentimento, setPrintsSentimento] = useState<Prints[]>([
    {
      idUploader: "",
      dataUpload: new Date().toISOString(),
      urlImagem: "",
      sentimento: "",
    },
  ]);
  const [, setTotalDeTarefas] = useState<number>(0);
  const [idTarefa, setIdTarefa] = useState<string>("");

  const { perfil } = obterCredenciais().credenciais;

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const { obterTarefaEPrints } = FuncoesRepercussao({
    idCampanha,
    idInfluenciadorSelecionado,
    setPrintsSentimento,
    setIdTarefa,
    setTotalDeTarefas,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    setErro,
  });

  useEffect(() => {
    obterTarefaEPrints();
  }, [idCampanha, idInfluenciadorSelecionado]);

  useEffect(() => {
    setPrintsSentimento([]);
    obterTarefaEPrints();
  }, [recarregar]);

  return (
    <>
      <CampoInformacoes titulo="Repercussão">
        {carregando ? (
          <Carregando />
        ) : erro.ativo ? (
          <div className={styles.containerErro}>
            <h1>Ocorreu um erro carregando o gráfico, tente novamente.</h1>
            <button
              onClick={() => setRecarregar(!recarregar)}
              className={styles.botaoIconeColunaAcoes}
            >
              <i
                className={"pi pi-refresh"}
                style={{ fontSize: "3rem" }}
                title={"Recarregar gráfico"}
              />
            </button>
          </div>
        ) : (
          <div className={styles.campoRepercussoes}>
            {printsSentimento.length !== 0 && (
              <p className={styles.mensagem}>
                *Breve análise de comentários aleatoriamente selecionados.
              </p>
            )}
            <div className={styles.campoBotaoAddRepercussão}>
              {(perfil[0] === "UsuarioNetcos" ||
                perfil[0] === "Administrador") && (
                  <Button
                    title="Adicionar Repercussão"
                    importancia="secundario"
                    tooltip={"Adicionar prints de repercussão"}
                    onClick={() => {
                      setModalAdcRepercussao(true);
                    }}
                    iconLeft={
                      <i
                        className="pi pi-plus-circle"
                        style={{ fontSize: "2rem" }}
                      />
                    }
                  />
                )}
            </div>
            <div
              className={
                printsSentimento.length !== 0
                  ? `${styles.campoInformacoes}`
                  : `${styles.campoInformacoesSemGrafico}`
              }
            >
              <div className={styles.campoCardComentario}>
                <>
                  {printsSentimento.length !== 0 ? (
                    <CarrosselRepercussao
                      publicacao={publicacaoSelecionada}
                      idTarefa={idTarefa}
                      printSentimento={printsSentimento}
                      setPrintsSentimento={setPrintsSentimento}
                      setRecarregar={setRecarregar}
                    />
                  ) : perfil[0] === "UsuarioNetcos" ||
                    perfil[0] === "Administrador" ? (
                    <p className={styles.mensagemSemResultados}>
                      Nenhum print cadastrado até o momento. Clique no botão
                      acima para iniciar.
                    </p>
                  ) : (
                    <p className={styles.mensagemSemResultados}>
                      Os comentários serão analisados e compartilhados pela
                      NetCos em breve.
                    </p>
                  )}
                </>
              </div>
              {printsSentimento.length !== 0 && (
                <div className={styles.campoGraficoRepercussoes}>
                  <GraficoRepercussao printSentimento={printsSentimento} />
                </div>
              )}
            </div>
          </div>
        )}
      </CampoInformacoes>
      <ModalAdcRepercussão
        modalAberto={modalAdcRepercussao}
        setModalAberto={setModalAdcRepercussao}
        idTarefa={idTarefa}
        printSentimento={printsSentimento}
        setPrintsSentimento={setPrintsSentimento}
        setRecarregar={setRecarregar}
      />
    </>
  );
};
