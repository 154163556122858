import { Input } from "../../../Input";
import { InputMetrica } from "./inputs/InputMetrica";

interface InstagramStoriesProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  respostas: number;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  cliquesLink: number;
  setCliquesLink: React.Dispatch<React.SetStateAction<number>>;
  cliquesStickers: number;
  setCliquesStickers: React.Dispatch<React.SetStateAction<number>>;
  visitasPerfil: number;
  setVisitasPerfil: React.Dispatch<React.SetStateAction<number>>;
  visitasAoPerfil: number;
  setVisitasAoPerfil: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
}

export const InstagramStories = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  respostas,
  setRespostas,
  impressoes,
  setImpressoes,
  cliquesLink,
  setCliquesLink,
  cliquesStickers,
  setCliquesStickers,
  visitasPerfil,
  setVisitasAoPerfil,
  visitasAoPerfil,
  setVisitasPerfil,
  compartilhamentos,
  setCompartilhamentos,
  alcance,
  setAlcance,

}: InstagramStoriesProps) => {
  const listaMetricas = [
    { nome: "Alcance", metrica: { valor: alcance, dispatch: setAlcance } },
    { nome: "Impressões", metrica: { valor: impressoes, dispatch: setImpressoes } },
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Respostas", metrica: { valor: respostas, dispatch: setRespostas } },
    { nome: "Compartilhamentos", metrica: { valor: compartilhamentos, dispatch: setCompartilhamentos } },
    { nome: "Cliques no Link", metrica: { valor: cliquesLink, dispatch: setCliquesLink } },
    { nome: "Cliques nos Stickers", metrica: { valor: cliquesStickers, dispatch: setCliquesStickers } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })}

      <div>
        <Input
          type="number"
          min={-1}
          required
          disabled={aprovar}
          placeholder="Visitas ao Perfil"
          value={
            visitasAoPerfil !== -1
              ? visitasAoPerfil
              : visitasPerfil !== -1
                ? visitasPerfil
                : undefined
          }
          onChange={(e) => {
            setVisitasAoPerfil(e.target.valueAsNumber);
            setVisitasPerfil(e.target.valueAsNumber);
          }}
        />
      </div>
    </>
  )
}