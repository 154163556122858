import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";
import "primeicons/primeicons.css";

import { FunctionsModalAdicionarPublicacaoNaTarefa } from "./functions";

import { Button } from "../../Botoes/Button";
import { Input } from "../../Input";
import { Carregando } from "../../Carregando";
import { ErroType } from "../../../@types/erro";
import { publicacaoProps } from "../../../services/publicacao";

import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";

export interface modalAcrescentarPublicacaoNaTarefaProps {
  importancia: any;
  idTarefa: string;
  idInfluenciadorSelecionado: string
  setRecarregar: () => void;
  titulo?: string;
}

export const ModalAcrescentarPublicacaoNaTarefa = ({
  importancia,
  idTarefa,
  idInfluenciadorSelecionado,
  setRecarregar,
  titulo = "Adicionar Publicação",
}: modalAcrescentarPublicacaoNaTarefaProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [quantidadeTwitter, setQuantidadeTwitter] = useState<number>(0);
  const [quantidadeInstagramReels, setQuantidadeInstagramReels] =
    useState<number>(0);
  const [quantidadeInstagramFeed, setQuantidadeInstagramFeed] =
    useState<number>(0);
  const [quantidadeInstagramStories, setQuantidadeInstagramStories] =
    useState<number>(0);
  const [quantidadeYouTube, setQuantidadeYouTube] = useState<number>(0);
  const [quantidadeYouTubeShorts, setQuantidadeYouTubeShorts] =
    useState<number>(0);
  const [quantidadeTiktok, setQuantidadeTiktok] = useState<number>(0);
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const [publicacao, setPublicacao] = useState<publicacaoProps[]>([]);

  const { adicionarAListaEsperaRenovacaoToken } =
    useContext(RenovarTokenContext);

  const myToast = useRef<any>(null);
  const { idCampanha } = useParams();

  const { cadastrarPublicacaoNaTarefa, setaPublicacoes, obterQuantidadeDePublicacoesDoInflu } =
    FunctionsModalAdicionarPublicacaoNaTarefa({
      idCampanha: idCampanha!,
      idTarefa,
      idInfluenciadorSelecionado,
      quantidadeTwitter,
      quantidadeInstagramReels,
      quantidadeInstagramFeed,
      quantidadeInstagramStories,
      quantidadeYouTube,
      quantidadeYouTubeShorts,
      quantidadeTiktok,
      setQuantidadeTwitter,
      setQuantidadeInstagramReels,
      setQuantidadeInstagramFeed,
      setQuantidadeInstagramStories,
      setQuantidadeYouTube,
      setQuantidadeYouTubeShorts,
      setQuantidadeTiktok,
      setPublicacao,
      setErro,
      modalAberto,
      setModalAberto,
      myToast,
      setCarregando,
      adicionarAListaEsperaRenovacaoToken,
      setRecarregar,
    });

  useEffect(() => {
    obterQuantidadeDePublicacoesDoInflu();
  }, [setRecarregar])

  let novaPublicacao: any = setaPublicacoes();


  return (
    <div className={styles.campoCadastroTarefa}>
      <Inplace active={modalAberto} onToggle={(e) => setModalAberto(e.value)}>
        <InplaceDisplay>
          <Button
            importancia={importancia}
            onClick={() => setModalAberto(true)}
            title={titulo}
            iconLeft={
              <i className="pi pi-plus-circle" style={{ fontSize: "2rem" }}></i>
            }
          />
        </InplaceDisplay>
        <InplaceContent>
          <div
            className={styles.conteudoModalCadastroInfluenciador}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input
              type="number"
              required
              min={0}
              placeholder="Twitter"
              title="Twitter"
              value={quantidadeTwitter !== 0 ? quantidadeTwitter : undefined}
              onChange={(e) => {
                setQuantidadeTwitter(e.target.valueAsNumber);
              }}
            />

            <Input
              type="number"
              required
              min={0}
              placeholder="Reels"
              title="Instagram Reels"
              value={
                quantidadeInstagramReels !== 0
                  ? quantidadeInstagramReels
                  : undefined
              }
              onChange={(e) => {
                setQuantidadeInstagramReels(e.target.valueAsNumber);
              }}
            />

            <Input
              type="number"
              required
              min={0}
              placeholder="Feed"
              title="Instagram Feed"
              value={
                quantidadeInstagramFeed !== 0
                  ? quantidadeInstagramFeed
                  : undefined
              }
              onChange={(e) => {
                setQuantidadeInstagramFeed(e.target.valueAsNumber);
              }}
            />

            <Input
              type="number"
              required
              min={0}
              placeholder="Stories"
              title="Instagram Stories"
              value={
                quantidadeInstagramStories !== 0
                  ? quantidadeInstagramStories
                  : undefined
              }
              onChange={(e) => {
                setQuantidadeInstagramStories(e.target.valueAsNumber);
              }}
            />

            <Input
              type="number"
              required
              min={0}
              placeholder="YouTube"
              title="YouTube Video"
              value={quantidadeYouTube !== 0 ? quantidadeYouTube : undefined}
              onChange={(e) => {
                setQuantidadeYouTube(e.target.valueAsNumber);
              }}
            />
            <Input
              type="number"
              required
              min={0}
              placeholder="Shorts"
              title="YouTube Shorts"
              value={
                quantidadeYouTubeShorts !== 0
                  ? quantidadeYouTubeShorts
                  : undefined
              }
              onChange={(e) => {
                setQuantidadeYouTubeShorts(e.target.valueAsNumber);
              }}
            />

            <Input
              type="number"
              required
              min={0}
              placeholder="Tiktok"
              title="TikTok"
              value={quantidadeTiktok !== 0 ? quantidadeTiktok : undefined}
              onChange={(e) => {
                setQuantidadeTiktok(e.target.valueAsNumber);
              }}
            />

            {carregando ? (
              <Carregando />
            ) : (
              <div className={styles.campoBotoes}>
                <Button
                  importancia="secundario"
                  icon={
                    <i
                      className="pi pi-check"
                      style={{ fontSize: "1.6rem" }}
                    ></i>
                  }
                  onClick={() => {
                    cadastrarPublicacaoNaTarefa(novaPublicacao);
                  }}
                />
                <Button
                  importancia={importancia}
                  onClick={() => setModalAberto(!modalAberto)}
                  title=""
                  iconLeft={
                    <i
                      className="pi pi-times"
                      style={{ fontSize: "1.6rem" }}
                    ></i>
                  }
                />
              </div>
            )}

            <Toast ref={myToast} className="custom-toast" />
          </div>
        </InplaceContent>
      </Inplace>
      {erro.ativo && <p className={styles.mensagemErro}> {erro.mensagem}</p>}
    </div>
  );
};
