//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { tarefaProps } from "../../../../services/tarefas";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioGraficoComparativoEntreInfluenciadores } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";
import { campanha } from "../../../../services/campanha";
import QuickChart from 'quickchart-js';
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export async function gerarPaginaGraficoComparativoInfluenciadores(
  campanha: campanha,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {

  const template: Template = {
    schemas: [
      {
        GraficoComparativoInflus: {
          type: "image",
          position: {
            x: 9,
            y: 32
          },
          width: 319.1,
          height: 134.11
        }
      }
    ],
    basePdf: base64RelatorioGraficoComparativoEntreInfluenciadores,
  };

  var imagemMarca = !campanha?.marcas[0].urlLogotipo
    ? ""
    : await converterImagemParaBase64(
      campanha.marcas[0].urlLogotipo,
      setErro,
      setCarregando
    );
  if (imagemMarca !== "AxiosError") {

    let influenciadores = pegaInfluenciadores(
      campanha.tarefas
    );
    let engajamentoLinhaGenerator = pegaTaxaEngajamentoTarefa(
      campanha.tarefas
    );
    let engajamentoTotal = setaEngajamentoTotal(
      campanha.tarefas
    );
    let alcanceTotal = setaAlcanceTotal(
      campanha.tarefas
    );

    //Ordenando os dados para aparecerem em formato decrescente no gráfico
    const todosOsDados = [];

    for (let i = 0; i < influenciadores.length; ++i) {
      todosOsDados.push({
        label: influenciadores[i],
        dadosEngajamentoTotal: engajamentoTotal[i],
        dadosEngajamentoLinha: engajamentoLinhaGenerator[i],
        dadosAlcance: alcanceTotal[i],
      });
    }

    todosOsDados.sort(
      (a, b) => b.dadosEngajamentoTotal - a.dadosEngajamentoTotal
    );

    const influenciadoresOrdenados = todosOsDados.map((e) => e.label);
    const alcanceTotalOrdenado = todosOsDados.map(
      (e) => e.dadosAlcance
    );
    const engajamentoTotalOrdenado = todosOsDados.map(
      (e) => e.dadosEngajamentoTotal
    );
    const engajamentoLinhaOrdenado = todosOsDados.map(
      (e) => e.dadosEngajamentoLinha
    );

    ChartJS.register(ChartDataLabels);

    const graficoInfluenciadores = new QuickChart();

    graficoInfluenciadores.setConfig({
      type: "bar",
      data: {
        labels: influenciadoresOrdenados,
        datasets: [
          {
            type: "line",
            label: "Taxa de Engajamento na Campanha",
            borderColor: "#51C876",
            borderWidth: 3,
            yAxisID: "y1",
            fill: false,
            tension: 0.4,
            data: engajamentoLinhaOrdenado,
            pointStyle: "line",
            datalabels: {
              align: "center",
              anchor: "center",
            },
          },
          // ...

          {
            type: "bar",
            label: "Alcance",
            borderWidth: 0,
            yAxisID: "y",
            data: alcanceTotalOrdenado,
            pointStyle: "rect",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#fc887b", "#ffdd95"]),
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
          {
            type: "bar",
            label: "Engajamento Total",
            borderWidth: 0,
            yAxisID: "y",
            data: engajamentoTotalOrdenado,
            pointStyle: "rect",
            backgroundColor: QuickChart.getGradientFillHelper('vertical', ["#ffdd95", "#d0e6a5"]),
            datalabels: {
              align: "start",
              anchor: "end",
            },
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            backgroundColor: function (context: any) {
              return context.dataset.type === "line"
                ? context.dataset.borderColor
                : "#ffffff00";
            },
            borderRadius: 4,
            color: function (context: any) {
              return context.dataset.type === "line"
                ? "#fff"
                : context.datasetIndex === 2
                  ? "#242424"
                  : "#242424";
            },
            display: function (context: any) {
              return context.dataset.data[context.dataIndex] > 0;
            },
            formatter: function (value: number, context: any) {
              return context.dataset.type === "line"
                ? value.toFixed(2) + "%"
                : value < 1e3 ? value :
                  value >= 1e3 && value < 1e6 ? +(value / 1e3).toFixed(1) + "K" :
                    value >= 1e6 && value < 1e9 ? +(value / 1e6).toFixed(1) + "M" :
                      value >= 1e9 && value < 1e12 ? +(value / 1e9).toFixed(1) + "B" :
                        +(value / 1e12).toFixed(1) + "T";
            },
            font: {
              size: 14,
              weight: "bold",
            },
          },
        },
        scales: {
          //@ts-ignore
          yAxes: [
            {
              id: 'y',
              stacked: false,
              type: "logarithmic",
              display: true,
              position: "left",
              ticks: {
                min: 0,
                maxTicksLimit: 9,
                callback: (value: any) => {
                  return value < 1e3 ? value :
                    value >= 1e3 && value < 1e6 ? +(value / 1e3).toFixed(1) + "K" :
                      value >= 1e6 && value < 1e9 ? +(value / 1e6).toFixed(1) + "M" :
                        value >= 1e9 && value < 1e12 ? +(value / 1e9).toFixed(1) + "B" :
                          +(value / 1e12).toFixed(1) + "T";
                },
              },
            },
            {
              id: "y1",
              stacked: false,
              type: "linear",
              position: "right",
              ticks: {
                min: 0,
                max: 100,
                color: "#495057",
                callback: function (value: string) {
                  return value + "%";
                },
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ]
        },
      }
    });

    graficoInfluenciadores.setWidth(1200);
    graficoInfluenciadores.setHeight(500);

    graficoInfluenciadores.setBackgroundColor('transparent');

    const inputs = [
      {
        GraficoComparativoInflus: await converterImagemParaBase64(
          (await graficoInfluenciadores.getShortUrl()).toString(),
          // (await graficoInfluTeste.getBase64Image()),
          setErro,
          setCarregando
        ),
      },
    ];

    const font: Font = {
      exo: {
        data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
        fallback: true
      }
    };

    const plugins = { text, image };

    await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
      // Browser
      var blob2 = new Blob([pdf.buffer], { type: "application/pdf" });
      listaDePdfs.push(blob2);
    });
  } else {
    return "AxiosError";
  }

  function pegaInfluenciadores(tarefas: any[]) {
    let influenciadores: string[] = [];

    tarefas.map((tarefa) => {
      influenciadores.push(tarefa.influenciador.nome);
    });

    return influenciadores;
  }

  function pegaTaxaEngajamentoTarefa(tarefas: tarefaProps[]) {
    let taxaEngajamentoTarefa: number[] = [];

    tarefas.map((tarefa) => {
      taxaEngajamentoTarefa.push(tarefa.taxaEngajamento);
    });

    return taxaEngajamentoTarefa;
  }

  function setaEngajamentoTotal(tarefas: tarefaProps[]) {
    let engajamentoTotal: number[] = [];

    tarefas.map((tarefa) => {
      engajamentoTotal.push(tarefa.engajamentoTotalDaTarefa);
    });

    return engajamentoTotal;
  }
  function setaAlcanceTotal(tarefas: tarefaProps[]) {
    let alcanceTotal: number[] = [];

    tarefas.map((tarefa) => {
      alcanceTotal.push(tarefa.alcanceTotalDaTarefa);
    });

    return alcanceTotal;
  }

}
