import { useEffect, useState } from "react";
import { Button } from '../../components/Botoes/Button';
import { FunctionConfirmarEmail } from "./functions";
import { useNavigate } from "react-router-dom";
import { credenciais } from "../../services/usuario";
import { Carregando } from "../../components/Carregando";
import { Input } from "../../components/Input";
import { CriteriosSenha } from "../../utils/CriteriosSenha";
import { ErroType } from "../../@types/erro";
import logo from "../../assets/img/netcos-logo.png";
import styles from './styles.module.css'


export const ConfirmacaoDeEmail = () => {
    const [liConcordo, setLiConcordo] = useState<boolean>(false);

    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [carregandoConfirmacaoEmail, setCarregandoConfirmacaoEmail] = useState<boolean>(true);
    const [carregandoRenovacaoSenha, setCarregandoRenovacaoSenha] = useState<boolean>(false);
    const [carregandoTermos, setCarregandoTermos] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [error, setError] = useState<ErroType>({ ativo: false, mensagem: "" });
    const [verificaDocumento, setVerificaDocumento] = useState<boolean>(false);
    const [reenviar, setReenviar] = useState<boolean>(false);
    const [erroReenviar, setErroReenviar] = useState<boolean>(false);
    const [senhaAlterada, setSenhaAlterada] = useState<boolean>(false);
    const [tempoSessao, setTempoSessao] = useState<boolean>(false);
    const [erroSenhaAlterada, setErroSenhaAlterada] = useState<boolean>(false);
    const [erroConfirmacaoSenha, setErroConfirmacaoSenha] = useState<boolean>(false);
    const [credenciais, setCredenciais] = useState<credenciais>({
        celular: "",
        fotoUsuario: null,
        id: "",
        nome: "",
        perfil: [""],
        urlAvatar: null,
        userName: "",
        termosDeUsoAceitos: true
    });

    const [termosDeUsoURL, setTermosDeUsoURL] = useState<string>("");
    const [politicaDePrivacidadeURL, setPoliticaDePrivacidadeURL] = useState<string>("");
    const [politicaDeConteudoURL, setPoliticaDeConteudoURL] = useState<string>("");

    const [novaSenha, setNovaSenha] = useState<string>("");
    const [novaSenhaConfirmacao, setNovaSenhaConfirmacao] = useState<string>("");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const email = urlParams.get("Email")!;
    const token = urlParams.get("Token")!;
    const senha = urlParams.get("Verificador")!;
    const tipo = urlParams.get("Tipo")!;

    const navigate = useNavigate();

    const { confirmarEmail, reenviarEmail, gerarNovaSenha, pegaDocumentosLegais } = FunctionConfirmarEmail({
        email,
        token,
        tipo,
        setConfirmado,
        setCarregandoConfirmacaoEmail,
        setCarregandoRenovacaoSenha,
        setReenviar,
        setErro,
        credenciais,
        setCredenciais,
        senha: window.atob(senha),
        novaSenha,
        novaSenhaConfirmacao,
        setSenhaAlterada,
        setTempoSessao,
        setErroSenhaAlterada,
        setErroReenviar,
        setErroConfirmacaoSenha,
        setTermosDeUsoURL,
        setPoliticaDeConteudoURL,
        setPoliticaDePrivacidadeURL,
        navigate,
        setCarregandoTermos,
        setError,
        setVerificaDocumento
    });

    useEffect(() => {
        pegaDocumentosLegais();

    }, [])

    return (
        <>
            <div className={styles.telaConfirmarEmail}>
                {!confirmado ?
                    <div className={styles.boxConfirmarEmail}>

                        <div className={styles.termosConfirma}>
                            <div className={styles.termosConfirmaLabel}>
                                <>
                                    {carregandoTermos ?
                                        <Carregando />
                                        :
                                        verificaDocumento ?
                                            <>
                                                <input id="termos-uso" type="checkbox" onClick={() => { setLiConcordo(!liConcordo) }} />
                                                <label htmlFor="termos-uso">
                                                    Li e concordo com os <a target="_blank" href={termosDeUsoURL} rel="noreferrer">termos de uso</a>, <a target="_blank" href={politicaDePrivacidadeURL} rel="noreferrer">política de privacidade</a> e <a target="_blank" href={politicaDeConteudoURL} rel="noreferrer">política de conteúdo</a>.
                                                </label>
                                            </>
                                            :
                                            <h3> Ocorreu um erro, tente novamente mais tarde.</h3>}
                                </>
                            </div>
                            <Button title="Confirmar Email" importancia="primario" disabled={!liConcordo} onClick={confirmarEmail} />
                        </div>
                    </div>
                    :
                    erro ? (
                        reenviar ? (
                            <div className={styles.boxConfirmarEmail}>
                                <div>
                                    <h2 className={styles.boxHeader}>Um novo Email foi enviado!</h2>
                                    <h3 className={styles.boxDescricao}>
                                        Verifique sua caixa de entrada para tentar confirmar seu email novamente.
                                    </h3>
                                </div>
                            </div>
                        ) : (
                            erroReenviar ?
                                <div className={styles.boxConfirmarEmail}>

                                    <div>
                                        <h2>Ocorreu um erro inesperado.</h2>
                                        <h3 className={styles.boxDescricao}>
                                            Tente novamente em alguns momentos ou entre em contato conosco.
                                        </h3>
                                        <div className={styles.containerBotao}>
                                            <Button importancia="primario" title="Contato" onClick={() => window.location.replace("https://netcos.art.br/contact/")} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.boxConfirmarEmail}>

                                    <div>
                                        <h2>Ocorreu um erro com sua confirmação.</h2>
                                        <h3 className={styles.boxDescricao}>
                                            Clique no botão abaixo para reenviar o email de confirmação.
                                        </h3>
                                        <div className={styles.containerBotao}>
                                            <Button importancia="primario" title="Reenviar Email" onClick={reenviarEmail}></Button>
                                        </div>
                                    </div>
                                </div>
                        )
                    ) : carregandoConfirmacaoEmail ? (
                        <div className={styles.boxConfirmarEmail}>

                            <div>
                                <h2>Confirmando seu email...</h2>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.formNovaSenha}>
                            <header>
                                <div id={styles.containerLogo}>
                                    <img src={logo} alt="Logo NetCos" className={styles.logoLogin} />
                                    <p>NetCos</p>
                                </div>

                                <h1>Email confirmado</h1>
                                <p>Para seu primeiro acesso, crie sua senha.</p>
                            </header>

                            <div className={styles.containerInputs}>

                                <Input
                                    id="senha"
                                    type="password"
                                    placeholder="Senha"
                                    onChange={(e) => setNovaSenha(e.target.value)}
                                    senha
                                />

                                <Input
                                    id="senha-nova"
                                    type="password"
                                    placeholder="Confirme sua Senha"
                                    onChange={(e) => setNovaSenhaConfirmacao(e.target.value)}
                                    senha
                                />
                            </div>

                            <CriteriosSenha senha={novaSenha} />
                            {erroConfirmacaoSenha &&
                                <p className={styles.erroSenhaConfirmacao}>
                                    A senha e a confirmação devem ser iguais.
                                </p>
                            }

                            <div className={styles.containerBotao}>

                                {carregandoRenovacaoSenha ?
                                    <Carregando botao />
                                    :
                                    <Button
                                        title="Trocar Senha e Entrar"
                                        importancia="primario"
                                        onClick={gerarNovaSenha}
                                        icon={<i className="pi pi-arrow-right" style={{ fontSize: '1.5rem', padding: '0 0 0 1rem' }}></i>}
                                    />
                                }
                                {erroSenhaAlterada &&
                                    <p>Ocorreu um erro inesperado, tente novamente.</p>
                                }
                                {tempoSessao &&
                                    <p>
                                        O tempo de sessão expirou,
                                        <Button
                                            title="Recarregar"
                                            importancia="terciario"
                                            onClick={() => {
                                                window.location.reload()
                                            }}
                                        />
                                        a página e tente novamente.
                                    </p>
                                }
                            </div>
                        </div>
                    )}
            </div>
        </>
    )
}