import styles from "./styles.module.css";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";

import { ModalTeste } from "../ModalTeste";

export interface ModalDesativacaoProps {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  nomeItemParaDesativar: string;
  status?: string;
  onClick: ((e: any) => void) | undefined;
  carregando?: boolean;
  remocaoDaCampanha?: boolean;
}

export const ModalDesativacao = ({
  modalAberto,
  setModalAberto,
  nomeItemParaDesativar,
  status,
  onClick,
  carregando,
  remocaoDaCampanha,
}: ModalDesativacaoProps) => {
  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={
        (remocaoDaCampanha
          ? "Remover "
          : status !== "Removido"
          ? "Desativar "
          : "Reativar ") + nomeItemParaDesativar
      }
    >
      <div className={styles.conteudoModalDesativacao}>
        <div className={styles.conteudoInformacoesModalDesativacao}>
          {remocaoDaCampanha ? (
            <p className={styles.avisoConfirmacao}>
              Tem certeza que deseja remover {nomeItemParaDesativar}?
            </p>
          ) : (
            <>
              {status !== "Removido" && status !== "AguardandoRemocao" ? (
                <p className={styles.avisoConfirmacao}>
                  Tem certeza que deseja desativar {nomeItemParaDesativar}?
                </p>
              ) : (
                <p className={styles.avisoConfirmacao}>
                  Tem certeza que deseja reativar {nomeItemParaDesativar}?
                </p>
              )}
            </>
          )}
        </div>
        <div className={styles.containerBotoesModal}>
          {carregando ? (
            <Carregando />
          ) : (
            <>
              <Button
                importancia="terciario"
                type="button"
                title="Cancelar"
                onClick={() => setModalAberto(false)}
              />
              <Button
                importancia="primario"
                title="Confirmar"
                onClick={onClick}
              />
            </>
          )}
        </div>
      </div>
    </ModalTeste>
  );
};
