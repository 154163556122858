//@ts-ignore
import { generate } from "@pdfme/generator";
import { Font, Template } from "@pdfme/common";
import { text, image } from '@pdfme/schemas';
import { PublicacoesProps } from "../../../../services/publicacao";
import { tarefaProps } from "../../../../services/tarefas";
import { converterImagemParaBase64 } from "./functions";
import { base64RelatorioComentarios } from "./imagensRelatorioBase64";
import { ErroType } from "../../../../@types/erro";

export async function gerarRelatorioImagensComentarios(
  tarefa: tarefaProps,
  listaDePdfs: any,
  setErro: React.Dispatch<React.SetStateAction<ErroType>>,
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>
) {
  const template: Template = {
    schemas: [
      {
        NomeInflu: {
          type: "text",
          position: {
            x: 252.76,
            y: 1.85,
          },
          width: 75.73,
          height: 8.85,
          alignment: "center",
          fontSize: 17,
          fontName: "exo",
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ffffff",
        },
        ImagemComentario3: {
          type: "image",
          position: {
            x: 221.18,
            y: 35.88,
          },
          width: 80.76,
          height: 106.7,
        },
        ImagemComentario2: {
          type: "image",
          position: {
            x: 128.79,
            y: 35.83,
          },
          width: 80.76,
          height: 106.7,
        },
        ImagemComentario1: {
          type: "image",
          position: {
            x: 34.66,
            y: 35.51,
          },
          width: 80.76,
          height: 106.7,
        },
      },
    ],
    basePdf: base64RelatorioComentarios,
  };

  var printSentimento = tarefa.printsDeSentimento;

  const inputs = [
    {
      NomeInflu: tarefa.influenciador.nome.toString(),
      ImagemComentario3: !printSentimento
        ? ""
        : printSentimento[1]
          ? await converterImagemParaBase64(
            printSentimento[1].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
      ImagemComentario2: !printSentimento
        ? ""
        : printSentimento[0]
          ? await converterImagemParaBase64(
            printSentimento[0].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
      ImagemComentario1: !printSentimento
        ? ""
        : printSentimento[2]
          ? await converterImagemParaBase64(
            printSentimento[2].urlImagem!,
            setErro,
            setCarregando
          )
          : "",
    },
  ];

  if (
    inputs[0].ImagemComentario3 === "AxiosError" ||
    inputs[0].ImagemComentario2 === "AxiosError" ||
    inputs[0].ImagemComentario1 === "AxiosError"
  ) {
    return "AxiosError";
  }

  const font: Font = {
    exo: {
      data: await fetch('https://info4.com.br/Exo-font-regular.ttf').then((res) => res.arrayBuffer()),
      fallback: true
    }
  };

  const plugins = { text, image };

  await generate({ template, inputs, plugins, options: { font } }).then((pdf: any) => {
    // Browser
    var blob9 = new Blob([pdf.buffer], { type: "application/pdf" });
    listaDePdfs.push(blob9);
  });
}
