import { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FunctionLogin } from "./functions";

import "../../@types/png.d.ts";

import { Input } from "../../components/Input";
import { Button } from "../../components/Botoes/Button";
import { ModalRedefinicao } from "../../components/Modais/ModalRedefinicao";
import { Carregando } from "../../components/Carregando";

import { AgenciaContext } from "../../context/AgenciaContext";
import { MarcaContext } from "../../context/MarcaContext";

import logo from "../../assets/img/netcos-logo.png";
import styles from "./styles.module.css";

import { Toast } from "primereact/toast";

export const Login = () => {
  const [idCredenciais, setIdCredenciais] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [carregando, setCarregando] = useState(false);
  const [modalAberto, setModalAberto] = useState<boolean>(false);

  const { recarregarListaDeMarcas } = useContext(MarcaContext);
  const { recarregarListaDeAgencias } = useContext(AgenciaContext);

  function fazerRequisicoeDeAgenciaEMarca() {
    recarregarListaDeAgencias();
    recarregarListaDeMarcas();
  }

  const myToast = useRef<any>(null);

  const navigate = useNavigate();

  const { fazerLogin } = FunctionLogin({
    email,
    senha,
    setCarregando,
    myToast,
    idCredenciais,
    setIdCredenciais,
    navigate,
    fazerRequisicoeDeAgenciaEMarca,
  });

  return (
    <main className={styles.paginaLogin}>
      <form
        className={styles.formLogin}
        onSubmit={(e) => (e.preventDefault(), fazerLogin)}
      >
        <header>
          <div id={styles.containerLogo}>
            <img src={logo} alt="Logo NetCos" className={styles.logoLogin} />
            <p>NetCos</p>
          </div>

          <h1>Seja Bem-vindo(a)!</h1>
          <p>Entre com a sua conta para acessar nossa plataforma</p>
        </header>

        <div className={styles.containerInputs}>
          <Input
            id={styles.inputLoginEmail}
            type="email"
            required
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Input
            id={styles.inputLoginSenha}
            type={"password"}
            required
            value={senha}
            placeholder="Senha"
            onChange={(e) => setSenha(e.target.value)}
            senha={true}
          />
        </div>

        <div className={styles.containerBotoes}>
          <div className={styles.containerBotaoEntrar}>
            {carregando ? (
              <Carregando />
            ) : (
              <Button
                importancia="primario"
                type="submit"
                title="Entrar"
                onClick={fazerLogin}
                icon={
                  <i
                    className="pi pi-arrow-right"
                    style={{ fontSize: "1.5rem", padding: "0 0 0 1rem" }}
                  ></i>
                }
              />
            )}
          </div>

          <Button
            title="Esqueceu a Senha"
            importancia="terciario"
            onClick={(e) => setModalAberto(true)}
          />
        </div>
        {modalAberto && (
          <ModalRedefinicao
            modalAberto={modalAberto}
            setModalAberto={setModalAberto}
          />
        )}
      </form>
      <Toast ref={myToast} className="custom-toast" />
    </main>
  );
};
